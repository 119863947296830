import AllSubmissionLimits from "./components/AllSubmissionLimits";
import WorkingDays from "./components/WorkingDays";
import CustomSettings from "./components/CustomSettings";

export default (props) => {
  return {
    WorkingDays: <WorkingDays {...props} />,
    SubmissionLimit: <AllSubmissionLimits {...props} />,
    CustomSettings: <CustomSettings {...props} />,
  };
};
