/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./index.scss";
import InputWidget from "./InputWidget";
import { Grid, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  addSiblingChild,
  createRandomId,
  findBySectionId,
  findInputFromSection,
  setInputKey,
} from "./utils";
import { getValue } from "utils/GetObjectValues";

function scanForSectionByName(name, cloneManyEntities, result) {
  if (!cloneManyEntities) return;
  if (!Array.isArray(cloneManyEntities)) {
    for (const key of Object.keys(cloneManyEntities)) {
      if (key !== "randomId") {
        if (key === name) result.push(cloneManyEntities[key]);
        const res = scanForSectionByName(name, cloneManyEntities[key], result);
      }
    }
  } else {
    for (const entity of cloneManyEntities) {
      scanForSectionByName(name, entity, result);
    }
  }
}

const displayInputWidget = ({
  setInitialValues,
  input,
  formik,
  step,
  sectionIndex,
  groupIdx,
  index,
  isTransition,
  submission,
  sectionNameFlags,
  setSectionNameFlags,
  onValueChange,
  randomId,
  selectedOption,
  setSelectedOption,
}) => {
  const setOptions = (option) => {
    setSelectedOption({
      ...selectedOption,
      ...option,
    });
  };
  return (
    <React.Fragment key={index}>
      <InputWidget
        key={input.key}
        input={{ ...input, section: step?.objectName }}
        formik={formik}
        setInitialValues={setInitialValues}
        sectionIndex={sectionIndex}
        groupIdx={groupIdx}
        index={index}
        isTransition={isTransition}
        submissionValue={submission}
        sectionNameFlags={sectionNameFlags}
        setSectionNameFlags={setSectionNameFlags}
        stepId={step.id}
        onValueChange={onValueChange}
        uniqueNo={randomId}
        isMany={step.isMany}
        selectedOption={selectedOption}
        setSelectedOption={setOptions}
      ></InputWidget>
    </React.Fragment>
  );
};

export function GetStepContent({
  formik,
  activeStep,
  steps,
  subActiveStep,
  setInitialValues,
  childStep,
  numbering,
  isTransition,
  manyEntities,
  setManyEntities,
  elementsProtoType,
  companyAllowedSubmissionCount,
  submission,
  submissionId,
  sectionNameFlags,
  setSectionNameFlags,
  onValueChange,
  onSectionAdd,
  onSectionRemove,
  config,
  selectedOption,
  setSelectedOption,
}) {
  const cloneManyEntities = _.cloneDeep(manyEntities);
  const renderContent = (section, element, groupIdx) => {
    const displaySection = sectionNameFlags
      ? (Object.keys(sectionNameFlags).includes(`${section?.id}`) &&
          Object.values(sectionNameFlags[`${section?.id}`]).includes(true)) ||
        !Object.keys(sectionNameFlags).includes(`${section?.id}`)
      : true;

    let useNumbering = true;
    let headerStyle = {
      fontSize: 28,
      fontWeight: "bold",
      lineHeight: "34px",
      color: "black",
    };
    if ("style" in section && section?.style) {
      if ("numbering" in section.style) useNumbering = section.style.numbering;
      if ("fontSize" in section.style)
        headerStyle.fontSize = section.style.fontSize;
    }

    return (
      <>
        <Grid
          container
          spacing={3}
          style={{ padding: "16px", display: !displaySection && "none" }}
          className={`${isTransition ? "center-items" : ""} m-0`}
        >
          {/* display subsection name */}
          {(!isTransition || section.name) && (
            <Grid item xs={12} className=" pl-0">
              {/* FIXME: */}
              <h3 className="display-inline-block mb-0" style={headerStyle}>
                {numbering !== undefined &&
                useNumbering &&
                numbering?.sectionIndex
                  ? numbering?.sectionIndex + "."
                  : ""}
                {useNumbering && groupIdx !== undefined ? groupIdx + 1 : ""}{" "}
                {cloneManyEntities?.[section.name]?.[0]?.name
                  ? section.name +
                    " " +
                    cloneManyEntities?.[section.name]?.[0]?.name
                  : section.name}
              </h3>
            </Grid>
          )}
          {section.isMany
            ? renderManySteps(
                formik,
                section,
                groupIdx,
                setInitialValues,
                numbering?.sectionIndex,
                isTransition,
                element,
                sectionNameFlags,
                setSectionNameFlags,
                selectedOption,
                setSelectedOption
              )
            : // display inputs
              renderInputs({
                setInitialValues,
                formik,
                step: section,
                sectionIndex: numbering?.sectionIndex,
                groupIdx: groupIdx + 1,
                isTransition,
                element,
                submission,
                sectionNameFlags,
                setSectionNameFlags,
                onValueChange,
                selectedOption,
                setSelectedOption,
              })}
        </Grid>
      </>
    );
  };
  const renderManySteps = (
    formik,
    section,
    groupIdx,
    setInitialValues,
    sectionIndex,
    isTransition,
    element,
    sectionNameFlags,
    setSectionNameFlags,
    selectedOption,
    setSelectedOption
  ) => {
    const handleRemoveInstanceClick = (e, index) => {
      e.stopPropagation();
      element[section.name].splice(index, 1);
      setManyEntities(cloneManyEntities);
      let sibling_sections = section.sibling_sections;
      if (sibling_sections) {
        changeSiblingSectionsNumber(sibling_sections, false, index);
      }
      let sectionValues = getValue(formik.values, section.key);
      const beforeLastEle =
        element[section.name][element[section.name].length - 1];
      if (typeof sectionValues === "object")
        sectionValues = _.cloneDeep(sectionValues);

      sectionValues?.splice(index, 1);
      formik.setFieldValue(section.key, sectionValues);
      onSectionRemove(
        section,
        cloneManyEntities,
        setManyEntities,
        beforeLastEle.arrayIndexes[0],
        index
      );
      // decrease number property of specific section or group
    };
    const changeSiblingSectionsNumber = (
      sibling_sections,
      increase,
      index,
      randomId
    ) => {
      sibling_sections.forEach((siblingSection) => {
        const sectionCopy = findBySectionId(siblingSection.id, steps);
        if (!sectionCopy) throw new Error("Failed to find section!!");
        if (increase) {
          const result = [];
          scanForSectionByName(sectionCopy.name, cloneManyEntities, result);
          for (const item of result) {
            const objAdded = elementsProtoType[sectionCopy.name];
            if (randomId && sectionCopy.key === "submissions")
              objAdded.randomId = randomId;
            item.push(objAdded);
          }
        } else {
          const result = [];
          scanForSectionByName(sectionCopy.name, cloneManyEntities, result);
          for (const item of result) {
            item.splice(index, 1);
          }
        }
        setManyEntities(cloneManyEntities);
      });
    };
    const handleAddInstanceClick = (e) => {
      let randomId;

      if (section.key === "submissions") {
        randomId = createRandomId();
        const input = findInputFromSection(section, "randomId");
        const beforeLastEle =
          element[section.name][element[section.name].length - 1];

        const lastIndex = beforeLastEle.arrayIndexes.splice(-1, 1);
        formik.setFieldValue(
          setInputKey(input, [...beforeLastEle.arrayIndexes, lastIndex[0] + 1]),
          randomId
        );
      }
      e.stopPropagation();
      if (
        [null, undefined].includes(companyAllowedSubmissionCount) ||
        (companyAllowedSubmissionCount != null &&
          companyAllowedSubmissionCount >= element[section.name].length + 1)
      ) {
        if (onSectionAdd) {
          const beforeLastEle =
            element[section.name][element[section.name].length - 1];

          onSectionAdd(
            section,
            cloneManyEntities,
            setManyEntities,
            beforeLastEle.arrayIndexes[0]
          );
        }
        const objectAdded = elementsProtoType[section.name] || {};
        addSiblingChild(section, element, steps, randomId);
        if (randomId) objectAdded.randomId = randomId;
        element[section.name].push(objectAdded);
        element[section.name] = element[section.name].filter(
          (ele) => ele !== undefined
        );
        setManyEntities(cloneManyEntities);
        let sibling_sections = section.sibling_sections;
        if (sibling_sections) {
          changeSiblingSectionsNumber(sibling_sections, true, null, randomId);
        }
      } else {
        Swal.fire({
          title: "This procedure cannot be performed",
          text: "You have reached your requests limit today please try again tomorrow",
          icon: "error",
          dangerMode: true,
        });
      }
    };

    let useSeperator = true;
    let useNumbering = true;
    let headerStyle = {
      fontSize: 28,
      fontWeight: "bold",
      lineHeight: "34px",
      color: "black",
      width: "100%",
    };
    if ("style" in section && section.style) {
      if ("separators" in section.style)
        useSeperator = section.style.separators;
      if ("numbering" in section.style) useNumbering = section.style.numbering;
      if ("fontSize" in section.style)
        headerStyle.fontSize = section.style.fontSize;
    }
    const displayBtn = (section, property) => {
      if ("style" in section && section.style && section.style[property]) {
        return false;
      }

      return true;
    };

    return (
      <>
        {element[section.name] &&
          element[section.name].length > 0 &&
          element[section.name]?.map((ele, index) => {
            ele.arrayIndexes = element.arrayIndexes
              ? [...element.arrayIndexes, index]
              : [index];

            const finalName = () => {
              if (useNumbering) {
                if (element[section.name]?.[index]?.name) {
                  return (
                    section.name +
                    ` (${index + 1})` +
                    " " +
                    element[section.name]?.[index]?.name
                  );
                }
                return section.name + ` (${index + 1})`;
              } else {
                if (element[section.name]?.[index]?.name) {
                  return (
                    section.name + " " + element[section.name]?.[index]?.name
                  );
                }
                return section.name;
              }
            };
            return (
              <React.Fragment key={ele.randomId}>
                {index !== 0 && (
                  <>
                    {useSeperator && <hr />}
                    <p style={headerStyle}>{finalName()}</p>
                  </>
                )}

                {renderInputs({
                  setInitialValues,
                  formik,
                  step: section,
                  arrIndex: index,
                  sectionIndex,
                  groupIdx: groupIdx + 1,
                  isTransition,
                  element: ele,
                  sectionNameFlags,
                  setSectionNameFlags,
                  onValueChange,
                  selectedOption,
                  setSelectedOption,
                })}
                {displayBtn(section, "disableRemove") &&
                  index !== 0 &&
                  !section.main_section_id && (
                    <div className="w-100">
                      <Button
                        varient="text"
                        className="action-button"
                        onClick={(e) => handleRemoveInstanceClick(e, index)}
                      >
                        <span style={{ marginLeft: "10px" }}>{" Remove "}</span>
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={{ marginLeft: "32px", padding: "2px" }}
                          className="bg-color-primary"
                        />
                      </Button>
                    </div>
                  )}
                {displayBtn(section, "disableAdd") && (
                  <div className="w-100">
                    {!(submissionId && section.key === "submissions") &&
                      section.isMany &&
                      !section.main_section_id && (
                        <Button
                          variant="text"
                          className="action-button"
                          onClick={(e) =>
                            handleAddInstanceClick(
                              e,
                              index + 1,
                              sectionIndex,
                              finalName()
                            )
                          }
                        >
                          <span style={{ marginLeft: "10px" }}>
                            {/* TODO: need localization */}
                            {"Add"}
                          </span>
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ marginLeft: "32px", padding: "2px" }}
                            className="bg-color-primary"
                          />
                        </Button>
                      )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  function renderInputs({
    setInitialValues,
    formik,
    step,
    arrIndex,
    sectionIndex,
    groupIndex,
    isTransition,
    element,
    submission,
    sectionNameFlags,
    setSectionNameFlags,
    onValueChange,
    selectedOption,
    setSelectedOption,
  }) {
    return (
      <>
        {step.inputs?.map((input, index) => {
          return displayInputWidget({
            setInitialValues,
            input: { ...input, key: setInputKey(input, element?.arrayIndexes) },
            formik,
            step,
            sectionIndex,
            groupIdx: groupIndex,
            index,
            isTransition,
            submission,
            sectionNameFlags,
            setSectionNameFlags,
            onValueChange,
            randomId: element?.randomId
              ? element.randomId
              : config?.continue?.file_code
              ? config.continue.file_code
              : null,
            selectedOption,
            setSelectedOption,
          });
        })}

        {step.group && (
          <>
            {step.group.map((section, index) => (
              <React.Fragment key={section.id}>
                {renderContent(
                  section,
                  step.isMany ? element : element[step.name],
                  index
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  }
  // check wether the active step is a sub step or not {productInformation:[{ingredients:[{}]}]}
  if (!childStep) {
    return renderContent(steps[activeStep], cloneManyEntities);
  } else {
    return renderContent(
      steps[activeStep].step[subActiveStep],
      cloneManyEntities[steps[activeStep].name],
      subActiveStep
    );
  }
}
