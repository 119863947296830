import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Button, DialogTitle } from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import SingleSectionRenderer from "components/SingleSectionRenderer";
import Alert from "@material-ui/lab/Alert";
import { SyncLoader } from "react-spinners";
import * as Yup from "yup";
import { setSchema } from "utils/setValidationSchema";
import { useFormik } from "formik";
import _ from "lodash";

const GroupSectionModal = ({
  group,
  action,
  open,
  setOpen,
  submissionIds,
  onActionComplete,
}) => {
  const [section, setSection] = useState(null);
  const [errored, setErrored] = useState(false);
  const [validationSchema, setValidationSchema] = useState(null);

  const onSubmit = (values) => {
    async function runAction() {
      await client.post("submissiongroup/RunAction", {
        data: values,
        groupId: group.groupId,
        actionId: action.id,
        submissionIds,
      });
      setOpen(false);
      onActionComplete();
    }
    runAction();
  };

  const formik = useFormik({
    validationSchema,
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues: {},
    onSubmit,
  });

  useEffect(() => {
    if (group && !group.groupId) {
      setErrored(true);
      return;
    }
    async function findSection() {
      if (!_.isEmpty(action)) {
        const sectionData = await client.post("generic/find", {
          where: { id: action.attributes.sectionId },
          model: "FormSection",
          include: {
            association: "inputs",
            include: [
              {
                association: "dependency",
              },

              { association: "enums" },

              { association: "events" },
            ],
          },
        });
        if (sectionData.data.rows.length) {
          setSection(sectionData.data.rows[0]);

          setValidationSchema(
            Yup.object().shape(setSchema(sectionData.data.rows[0].inputs))
          );
        } else setErrored(true);
      }
    }
    findSection();
  }, [group, action]);
  // if (errored) return <Alert severity="error"> Something went wrong </Alert>;
  // if (!section || !validationSchema) return <SyncLoader />;

  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className="titleModal"> </span>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column align-items-center">
          {section && (
            <SingleSectionRenderer
              section={section}
              validationSchema={validationSchema}
              formik={formik}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ width: "200px",marginBottom:"60px" }}
            disabled={!formik.isValid || !formik.dirty}
            onClick={formik.submitForm}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GroupSectionModal;
