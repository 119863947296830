import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./style.css";
import { Tooltip } from "@mui/material";
const headers = [
  {
    name: "TradeCode",
    type: "number",
  },
  {
    name: "Trade_name",
    type: "string",
  },
  {
    name: "Applicant_id",
    type: "number",
  },
  {
    name: "Applicant_name",
    type: "string",
  },
  {
    name: "Drug_registration_date",
    type: "date",
  },
  {
    name: "Drug_registration_expiration_date",
    type: "date",
  },
  {
    name: "Drug_license_number",
    type: "number",
  },
  {
    name: "Dosage_form",
    type: "string",
  },
  {
    name: "Generics",
    type: "string",
  },
  {
    name: "Strength_value",
    type: "number",
  },
  {
    name: "Strength_unit",
    type: "string",
  },
  {
    name: "regstatus",
    type: "string",
  },
  {
    name: "LicHold",
    type: "string",
  },
  {
    name: "Type_of_license",
    type: "string",
  },
  {
    name: "APISupplier",
    type: "string",
  },
  {
    name: "tradecodelink",
    type: "string",
  },
  {
    name: "StorageSite",
    type: "string",
  },
  {
    name: "Packager",
    type: "string",
  },
  {
    name: "MarketType",
    type: "string",
  },
  {
    name: "Manufacturer",
    type: "string",
  },
  {
    name: "LicStatus",
    type: "string",
  },
];

const allColumns = headers.map((head, index) => {
  return {
    field: head.name,
    headerName: head.name,
    type: head.type,
    width: 150,
    align: "left",
    renderCell: (params) => {
      return (
        <Tooltip title={params.row[head.name]}>
          <span className="table-cell-trucate">{params.row[head.name]}</span>
        </Tooltip>
      );
    },
  };
});

const operator = {
  contains: "includes",
};

export default function DataGridDemo({ data }) {
  const [currentData, setCurrentData] = useState(data);
  const [allFliters, setAllFilters] = useState([]);

  // const filterAllData = (filterModel) => {
  //   const oldFilters = allFliters;
  //   oldFilters.push(filterModel);
  //   setAllFilters([...oldFilters]);

  //   console.log(
  //     oldFilters.map((c) => c.items),
  //     "sssssss"
  //   );

  //   const allData = data;

  //   // operatorValue
  // };

  return (
    <div style={{ height: "80vh", width: "100%", backgroundColor: "white" }}>
      <DataGrid
        // onFilterModelChange={(filterModel) => {
        //   filterAllData(filterModel);
        // }}
        className="table-grid"
        getRowId={(row) => row.TradeCode}
        rows={currentData}
        columns={allColumns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        // checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
