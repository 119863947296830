import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import clsx from "clsx";
import "./style.css";
import FilterModal from "components/filterComponents/FilterModal";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchWorklist } from "reducers/list";
const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    width: "92%",
    flex: 1,
    boxShadow: "none !important",
  },
  // iconButton: {
  //   padding: 10,
  // },
}));
const WorklistFilter = (props) => {
  let {
    filterLayout,
    currentTab,
    getWorklist,
    tasks,
    formId,
    model,
    setApplyFilter,
  } = props;
  const classes = useStyles();
  const FilterAltOutlinedIcon = createSvgIcon(
    <path
      d="M20 3H4a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L9 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 13 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L5 6.59V5h14.001l.002 1.583l-5.71 5.71z"
      fill="currentColor"
    />,
    "FilterAltOutlinedIcon"
  );
  const ClearFilterIcon = createSvgIcon(
    <React.Fragment>
      <path
        d="M20.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z"
        fill="currentColor"
      />
      <path
        d="M20 3H4a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L9 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 13 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L5 6.59V5h14.001l.002 1.583l-5.71 5.71z"
        fill="currentColor"
      />
    </React.Fragment>,
    "ClearFilterIcon"
  );
  const [openModal, setOpenModal] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchDataValue, setSearchDateValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const openStepInputs = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    if (tasks?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [tasks]);
  useEffect(() => {
    setSearchData([]);
    setSearchDateValue("");
  }, [currentTab]);

  const setSearchValue = (value) => {
    let filterObject = [];
    filterLayout = filterLayout.flat();
    let searchfield = filterLayout.filter((item) => item.search);
    if (value) {
      let values = searchfield.map((field) => {
        if (field.json) {
          return {
            field: field.key,
            value: `%${
              typeof value === "string" ? value.toLowerCase() : value
            }%`,
            operator: "like",
            json: true,
          };
        } else {
          return {
            field: field.key,
            value: `%${
              typeof value === "string" ? value.toLowerCase() : value
            }%`,
            operator: "like",
          };
        }
      });

      filterObject = [
        {
          operator: "or",

          value: values,
        },
      ];
    }

    setSearchData({ filters: filterObject });
  };
  let searchfield =
    filterLayout && filterLayout.flat().filter((item) => item?.search);
  const names = searchfield.map((field) => field.name);
  const applySearch = (event) => {
    event.preventDefault();
    setApplyFilter && setApplyFilter(false);
    getWorklist({ ...searchData, currentTab, form_id: formId })
      .then((res) => {
        setApplyFilter && setApplyFilter(true);
      })
      .catch((err) => {});
  };
  const handleClearFilter = () => {
    getWorklist({ currentTab, form_id: formId }).catch((err) => {});
  };

  return (
    <Grid
      container
      className="mt-1 mb-1"
      spacing={2}
      style={{ borderRadius: "0 20px 0 0" }}
    >
      <Grid item md={6} className="mt-1 ml-3">
        <div className={clsx("d-flex align-items-center w-100 search-bar-div")}>
          <Paper
            style={{ boxShadow: "none " }}
            component="form"
            className="w-100 search-bar"
          >
            <InputBase
              className={classes.input}
              placeholder={names.join(",")}
              value={searchDataValue}
              inputProps={{ "aria-label": "search..." }}
              onChange={(event) => {
                event.persist();
                setSearchDateValue(event.target.value);
                setSearchValue(event.target.value);
              }}
            />
            <IconButton
              type="submit"
              className={` p-0`}
              aria-label="search"
              onClick={(e) => applySearch(e)}
            >
              <SearchTwoToneIcon />
            </IconButton>
          </Paper>
        </div>
      </Grid>

      <Grid item md={5} className=" ml-5">
        <div className="d-flex align-end flex-wrap">
          <Button
            onClick={openStepInputs}
            className="m-2 btn-transparent search-btn btn-outlined"
          >
            <FilterAltOutlinedIcon className="filter-icon" />
            <span className="filter-txt ml-1"> Filter Results</span>
          </Button>
          {currentTab !== "Scientific Evalution" && (
            <Button onClick={handleClearFilter} className="m-2  clear-filter">
              <ClearFilterIcon className="filter-icon" />
              <span className="filter-txt ml-1"> Clear Filter</span>
            </Button>
          )}
        </div>
      </Grid>

      {openModal && (
        <FilterModal
          open={openModal}
          setOpen={setOpenModal}
          filterLayout={filterLayout}
          currentTab={currentTab}
          model={model}
          setApplyFilter={setApplyFilter}
        />
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    modelData: state.filterData.modelData,
    tasks: state.list.tasks,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (params) => dispatch(fetchWorklist(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorklistFilter);
