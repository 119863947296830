import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { TableBody, Button, Chip, TextField } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Input } from "@material-ui/core";
import AudioRecorder from "components/AudioRecorder";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import client from "api/apiAuth/guestClient";
import "./poca.css";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({
  tableData,
  setTableData,
  selectedTab,
  tableHeader,
  handleAcceptance,
  handleComment,
  currentUser,
  task,
  finalList,
}) {
  const classes = useStyles();

  let taskUsersId;
  const [indexComents, setIndexComments] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editName, setEditName] = useState(null);
  if (selectedTab) {
    taskUsersId = task.users.map((user) => user.id);
  }

  const getDecisionColor = (value) => {
    if (value == "accept") {
      return "green";
    } else if (value == "reject") {
      return "red";
    } else if (value == "hold") {
      return "yellow";
    }

    return "";
  };

  const handleAcceptEdit = async (index, item) => {
    if (
      index == editingId &&
      currentUser.roles.some((role) =>
        role.name.includes("Unit Manager Naming")
      ) &&
      editName !== item.name
    ) {
      let newName = editName;
      if (item.generic) {
        newName = newName.replace(`-${task.submission.org?.name}`, "");
        newName = `${newName}-${task.submission.org?.name}`;
      }
      let newDrugs = [...task.submission.values.naming.drugs];
      newDrugs.splice(index, 1, {
        ...newDrugs[index],
        name: newName,
        decision: "accept",
      });
      try {
        await client.post("submission/updateSubmissionValues", {
          submission_id: task.submission.id,
          task_id: task.id,
          inputs: [],
          submitFlag: false,
          submission_edit: {
            naming: {
              drugs: [...newDrugs],
              allDrugNames: [
                ...task.submission.values.naming.allDrugNames,
                newName,
              ],
            },
          },
        });

        setTableData(newDrugs);
      } catch (error) {
        console.log(error);
      }
      setEditName(null);
    }
    setEditingId(null);
  };
  const handleNameChange = (e, item) => {
    setEditName(e.target.value);
  };
  const handleEditName = (index, item) => {
    if (
      currentUser.roles.some((role) =>
        role.name.includes("Unit Manager Naming")
      ) &&
      task.step.id == 20
    ) {
      if (!(editingId == index)) {
        setEditingId(index);
        setEditName(item.name);
      } else {
        setEditingId(null);
      }
    }
  };
  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeader.map((header) => (
                <>
                  <TableCell>{header.name}</TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <>
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editingId == index ? (
                      <TextField
                        value={editName}
                        onChange={(e) => handleNameChange(e, item)}
                      />
                    ) : (
                      item.name
                    )}
                  </TableCell>
                  <TableCell>{item.arname}</TableCell>
                  <TableCell>
                    {item.recording && (
                      <AudioRecorder listenOnly audio={item.recording} />
                    )}
                  </TableCell>
                  {task.is_assigned &&
                    taskUsersId.includes(currentUser.id) &&
                    !currentUser.roles
                      .map((role) => role.name)
                      .includes("User") && (
                      <TableCell>
                        <Button
                          onClick={() => {
                            handleAcceptEdit(index, item);
                            setIndexComments([...indexComents, index]);
                            handleAcceptance(item.name, "accept", index);
                          }}
                          style={{ color: "white" }}
                          className={`review-icon ${
                            finalList &&
                            "allDecisions" in finalList[index] &&
                            finalList[index].allDecisions[
                              currentUser.roles.map((role) => role.name)
                            ] === "accept"
                              ? "green"
                              : "light-green"
                          }`}
                        >
                          <CheckIcon />
                        </Button>
                        <Button
                          style={{ color: "white" }}
                          onClick={() => {
                            setIndexComments([...indexComents, index]);
                            handleAcceptance(item.name, "reject", index);
                          }}
                          className={`review-icon ${
                            finalList &&
                            "allDecisions" in finalList[index] &&
                            finalList[index].allDecisions[
                              currentUser.roles.map((role) => role.name)
                            ] === "reject"
                              ? "red"
                              : finalList &&
                                "allDecisions" in finalList[index] &&
                                !finalList[index].allDecisions[
                                  currentUser.roles.map((role) => role.name)
                                ] &&
                                currentUser.roles.map((role) => role.name) ==
                                  "Unit Manager Naming" &&
                                finalList[index].decision === "reject"
                              ? "red"
                              : "light-red"
                          }`}
                        >
                          <ClearIcon />
                        </Button>
                        <Button
                          style={{ color: "white" }}
                          onClick={() => {
                            handleEditName(index, item);
                            setIndexComments([...indexComents, index]);
                            handleAcceptance(item.name, "hold", index);
                          }}
                          className={`review-icon ${
                            finalList &&
                            "allDecisions" in finalList[index] &&
                            finalList[index].allDecisions[
                              currentUser.roles.map((role) => role.name)
                            ] === "hold"
                              ? "yellow"
                              : "light-yellow"
                          }`}
                        >
                          <EditIcon />
                        </Button>
                      </TableCell>
                    )}
                  {currentUser.roles.some((role) => role.name != "User") &&
                  !task.submission.values?.line_extension ? (
                    <TableCell>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          const win = window.open(
                            `/showSimilarity/${item.name}`,
                            "_blank"
                          );
                          win.focus();
                        }}
                      >
                        Show
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
                {indexComents.includes(index) && (
                  <TableRow>
                    <TableCell colSpan={tableHeader.length}>
                      <Input
                        fullWidth
                        placeholder="write your comment..."
                        onChange={(e) => handleComment(e.target.value, index)}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!currentUser.roles.map((role) => role.name).includes("User") &&
                Object.keys(item).filter((key) => {
                  return key.includes("comment");
                }).length ? (
                  <TableRow>
                    <TableCell colSpan={tableHeader.length}>
                      {Object.keys(item)
                        .filter((key) => {
                          return key.includes("comment");
                        })
                        .map((comment) => {
                          return (
                            <p>
                              {comment} : {item[comment]}
                            </p>
                          );
                        })}
                    </TableCell>
                  </TableRow>
                ) : null}
                {!currentUser.roles.map((role) => role.name).includes("User") &&
                  item.allDecisions && (
                    <TableRow>
                      <TableCell colSpan={tableHeader.length}>
                        {Object.keys(item.allDecisions).map((role) => (
                          <Chip
                            style={{ marginRight: 10 }}
                            className={`chipFont ${getDecisionColor(
                              item.allDecisions[role]
                            )}`}
                            label={`${role}`}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                  )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
