import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
  Button,
  Card,
} from "@material-ui/core";
import AddDoctorModal from "./addEditModal";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import client from "api/apiAuth/guestClient";
const DoctorPanel = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableColumns = ["Index", "name", "email", "Actions"];

  const [isEdit, setIsEdit] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [currentDoctor, setCurrentDoctor] = useState({
    name: "",
    email: "",
  });
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleAddDoctorModal = () => {
    setIsEdit(false);
    setCurrentDoctor({
      ...currentDoctor,
      name: "",
      email: "",
    });

    setAddModalOpen(true);
  };
  const handleEditDoctorModal = (id) => {
    setIsEdit(true);
    doctors.find((doctor) => doctor.id == id);
    setCurrentDoctor({
      ...currentDoctor,
      ...doctors.find((doctor) => doctor.id == id),
    });
    setAddModalOpen(true);
  };
  const handleDeleteDoctorModal = (id) => {
    Swal.fire({
      title: "Are you sure you want to Delete Doctor? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        client.delete(`/doctor/destroy/${id}`).then((res) => {
          setDoctors(...[doctors.filter((doctor) => doctor.id != id)]);
        });
      }
    });
  };
  useEffect(() => {
    client
      .get("/doctor")
      .then((res) => setDoctors([...res.data.doctors]))
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Card
      style={{
        borderRadius: "0 20px 20px 20px",
        boxShadow: "none",
        border: "none",
      }}
    >
      <Grid style={{ textAlign: "right" }}>
        <Button
          style={{
            margin: "15px",
            fontSize: "1rem",
            color: "#FFF",
            backgroundColor: "#198754",
          }}
          onClick={() => handleAddDoctorModal()}
        >
          Add Doctor
        </Button>
      </Grid>
      <Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((columnHeader) => (
                  <TableCell
                    className="bg-color-darkBlue pt-2"
                    style={{ textAlign: "center" }}
                    key={columnHeader}
                  >
                    {columnHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {doctors.length ? (
                doctors.map((doctor, index) => (
                  <TableRow key={doctor.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {doctor.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {doctor.email}
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        style={{
                          color: "#FFF",
                          backgroundColor: "#0066cc",
                          // fontWeight: "bold",
                          padding: "10px 15px 10px 0px"
                        }}
                        onClick={(e) => handleEditDoctorModal(doctor.id)}
                      >
                        <EditIcon className="mx-2" /> Edit
                      </Button>
                      <Button
                        style={{
                          color: "#FFF",
                          backgroundColor: "#ff0000",
                          // fontWeight: "bold",
                          padding: "10px 15px 10px 0px"
                        }}
                        onClick={(e) => handleDeleteDoctorModal(doctor.id)}
                      >
                        <DeleteIcon className="mx-2" /> Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={tableColumns.length}>
                    <h5 className="mt-3">No Doctors</h5>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage=""
                  colSpan={5}
                  count={doctors.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ direction: "ltr" }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <AddDoctorModal
        open={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        isEdit={isEdit}
        doctors={doctors}
        setDoctors={setDoctors}
        currentDoctor={currentDoctor}
        setCurrentDoctor={setCurrentDoctor}
      />
    </Card>
  );
};

export default DoctorPanel;
