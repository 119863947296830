import {
    FETCH_TIMESLOTS_SCHEDULE_PENDING,
    FETCH_TIMESLOTS_SCHEDULE_FULFILLED,
    FETCH_TIMESLOTS_SCHEDULE_REJECTED
  } from './listActionTypes';
  
  const initialState = {
    error: null,
    schedule: [],
    scheduleCount: 0,
    loading: false
  };
  
  export default function reducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_TIMESLOTS_SCHEDULE_PENDING: {
        return {
          ...state,
          loading: true
        };
      }
      case FETCH_TIMESLOTS_SCHEDULE_FULFILLED: {
        return {
          ...state,
          loading: false,
          schedule: action.payload.data.schedule,
          scheduleCount: action.payload.data.scheduleCount
        };
      }
      case FETCH_TIMESLOTS_SCHEDULE_REJECTED: {
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      }
      default:
        return state;
    }
  }
  