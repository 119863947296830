import React, { useEffect, useState } from "react";

import { Grid, Card, ListItem, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useDropzone } from "react-dropzone";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { handleDocument } from "../../utils/uploadFile";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import client from "api/apiAuth/guestClient";
import _ from "lodash";
import "./style.css";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CameraModal from "components/CameraModal";
import { handleError } from "api/utilities";

const FileUploader = ({
  submission,
  input,
  getValue,
  formik,
  uploadFile,
  uniqueNo,
  // setSubmission,
}) => {
  const [files, setFiles] = useState([]);
  const [currentUploadedFiles, setCurrentUploadedFiles] = useState([]);
  const [rejectedFileErrorMessage, setRejectedFileErrorMessage] = useState([]);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: input.input_layout.acceptableExtensions,
    maxSize: 10000000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      acceptedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setCurrentUploadedFiles([...acceptedFiles]);
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          let errorMessage;
          if (err.code === "file-too-large") {
            errorMessage = "file is too large";
          }

          if (err.code === "file-invalid-type") {
            errorMessage = "please check files extensions";
          }
          setRejectedFileErrorMessage([
            ...rejectedFileErrorMessage,
            { fileName: file.file.name, message: errorMessage },
          ]);
        });
      });
    },
  });
  const removeFile = async (file) => {
    try {
      //find attachment in redux store
      let attachment = submission.attachments?.find(
        (attachment) => file.preview == attachment.metadata.preview
      );
      let avatarFd = attachment?.avatarFd;
      if (attachment) {
        //remove attachment file from backend
        await client.post(
          `attachment/deleteUnlinkedAttachment?avatarFd=${avatarFd}`
        );
        //remove attachment from redux store
        // setSubmission({
        //   ...submission,
        //   attachments: submission.attachments.filter(
        //     (attachment) => file.preview != attachment.metadata.preview
        //   ),
        // });
      }
      // remove attachment from its section in formik
      let filteredFiles = getValue(formik.values, input.key).filter((value) =>
        value.preview
          ? file.preview != value.preview
          : file.preview != value.metadata.preview
      );
      formik.setFieldValue(input.key, filteredFiles);
      setFiles(
        files.filter((value) =>
          value.preview
            ? file.preview != value.preview
            : file.preview != value.metadata.preview
        )
      );
    } catch (error) {
      handleError(error);
    }
  };

  let uploadedAttachments = getValue(formik.values, input.key) || [];
  const thumbs = uploadedAttachments.map((file, index) => {
    return file?.type?.includes("image") ? (
      <Grid item md={3} className="p-2" key={file.name + index}>
        <div className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden rounded-sm">
          <CloseIcon className="remove-img" onClick={() => removeFile(file)} />
          <img
            className="img-fluid img-fit-container rounded-sm"
            src={file?.pdfUrl ? file.pdfUrl : URL.createObjectURL(file)}
            alt={file.name}
          />
        </div>
      </Grid>
    ) : (
      file && (
        <ListItem
          className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
          key={file.name + index}
        >
          <span className="file-name">{file.name}</span>{" "}
          {file.size && (
            <span className="badge badge-pill bg-neutral-warning text-warning ">
              {file.size} bytes
            </span>
          )}
          <CloseIcon
            className="mr-1 remove-file"
            onClick={() => removeFile(file)}
          />
        </ListItem>
      )
    );
  });

  useEffect(() => {
    currentUploadedFiles.forEach((file, index) => {
      let documentName = input.key + `.${files.length + index}`,
        section = documentName.split(".")[0],
        sectionName = input.section,
        allFiles = currentUploadedFiles,
        document_id = input.itype !== "string" && input.id;
      uploadFile(input, file, formik, documentName, true, submission, uniqueNo);
      //add file to formik

      // let document = handleDocument(
      //   formik,
      //   section,
      //   sectionName,
      //   documentName,
      //   file,
      //   allFiles,
      //   document_id
      // );
      // //send file to server
      // if (document) {

      //   uploadFile(document).catch((error) => {
      //     formik.setFieldValue(documentName, "");
      //   });
      // }
    });
    setFiles([...files, ...currentUploadedFiles]);
  }, [currentUploadedFiles]);
  useEffect(
    () => () => {
      //create preview url from file
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  useEffect(() => {
    //add old attachments to files array
    !_.isEmpty(submission?.attachments) &&
      setFiles(
        submission.attachments.filter((attachment) =>
          attachment.name.includes(input.key)
        )
      );
  }, []);
  return (
    <Grid container>
      <Grid item sm={8} md={8}>
        <Card className="card-box mb-lg-0">
          <div>
            <div className="p-2 multi-upload-btn">
              <div
                {...getRootProps({ className: "dropzone-upload-wrapper" })}
                className="p-0"
              >
                <input
                  {...getInputProps()}
                  disabled={input.disabled}
                  onBlur={formik.handleBlur}
                  required={input.required}
                />
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                  {isDragAccept && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg">
                        <CheckIcon className="d-50" />
                      </div>
                      <div className="font-size-xs text-success">
                        We're ready to start!
                      </div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg">
                        <CloseTwoToneIcon className="d-50" />
                      </div>
                      <div className="font-size-xs text-danger">
                        These files are not images!
                      </div>
                    </div>
                  )}
                  {!isDragActive && (
                    <div>
                      <div
                        className="font-size-sm"
                        style={{ textAlign: "center", color: "#0066cc" }}
                      >
                        Upload Files
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {thumbs?.length > 0 && (
            <div className="card-footer p-3 ">
              <div>
                <div className="font-weight-bold mb-3 text-uppercase text-dark font-size-sm text-center">
                  Uploaded Files
                </div>
                <div>
                  <Alert severity="success" className="text-center mb-3">
                    You have uploaded <b>{thumbs?.length}</b> files!
                  </Alert>
                  <Grid container spacing={0}>
                    {thumbs}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </Card>
      </Grid>
      {/* <Grid item sm={1}>
        <Button
          className={`btn btn-primary btn-transition-none d-inline camera-button`}
          style={{
            borderRadius: "10% 0 0 10%",
            height: "40px",
            backgroundColor: "white",
            color: "#3c44b1",
            width: "40px",
          }}
          disableFocusRipple
          disableRipple
          disabled={input.disabled}
          onClick={() => setOpenCameraModal(true)}
        >
          <CameraAltIcon />
        </Button>
      </Grid> */}
      {/* camera modal */}
      <CameraModal
        open={openCameraModal}
        setOpen={setOpenCameraModal}
        documentType={"mutiple"}
        formik={formik}
        setCurrentUploadedFiles={setCurrentUploadedFiles}
      ></CameraModal>
      <div>
        {rejectedFileErrorMessage.length > 0 &&
          rejectedFileErrorMessage.map((file, index) => {
            return (
              <ListItem
                className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
                key={file.fileName + index}
              >
                <span className="text-danger">{file.message}</span>
                <span className="file-name text-danger">{file.fileName}</span>
              </ListItem>
            );
          })}
      </div>
    </Grid>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // uploadFile: (file) => dispatch(uploadFile(file)),
  };
};
export default connect(null, mapDispatchToProps)(FileUploader);
