import { createAction } from "@reduxjs/toolkit";
import client from "api/apiAuth/guestClient";

const {
  RUN_ACTIONS,
  GET_VALID_SUBMISSIONS,
  ADD_SUBMISSION_TO_GROUP,
} = require("./groupingActionTypes");

export const runActions = createAction(RUN_ACTIONS, function prepare(data) {
  return {
    payload: client.post("/submissiongroup/runaction", data),
  };
});

export const getValidSubmissions = createAction(
  GET_VALID_SUBMISSIONS,
  function prepare(params) {
    return {
      payload: client.get("/submissiongroup/getValidSubmissions", { params }),
    };
  }
);

export const addSubmissionsToGroup = createAction(
  ADD_SUBMISSION_TO_GROUP,
  function prepare(data) {
    return {
      payload: client.post("/submissiongroup/addSubmissionToGroup", data),
    };
  }
);
