export function getValue(obj, path, inputType) {
  if (path) {
    path = path.replace(/\[(\w+)\]/g, "$1"); // convert indexepaths to propertis
    path = path.replace(/^\./, ""); // strip a leading dot
    let value = path.split(".").reduce((o, i) => {
      if (o) {
        if (typeof o[i] !== "object") {
          if (inputType === "date") {
            return o[i]
              ? new Date(o[i]).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : o[i];
          } else if (inputType === "time") {
            if (o[i] && typeof o[i] === "string") {
              const parts = o[i].split(":");
              const lastPart = parts[2].match(/[*a-zA-Z]+/);
              return `${parts[0]}:${parts[1]} ${lastPart || ""}`;
            }
          } else if (inputType === "boolean") return o[i] ? "Yes" : "No";
          // else if (o[i] === false) o[i] = 'false';
        }
        if (o[i] === true || o[i] === false) return o[i].toString();
        return o[i];
      }
    }, obj);
    return value;
  }
}
