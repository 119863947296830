import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";

export const applyConstraintsOnActions = (sections, mapper) =>
  sections.map((section) => {
    if (section.actions) {
      return {
        ...section,
        actions: section.actions.map((action) => {
          if (action.constraints) {
            return {
              ...action,
              constraints: applyDetailsConstraints(action.constraints, mapper),
            };
          }
          return {
            ...action,
            constraints: {
              display: true,
              disable: false,
            },
          };
        }),
      };
    } else if (section.DocumentsWithAssociatedActions) {
      return {
        ...section,
        DocumentsWithAssociatedActions: {
          ...section.DocumentsWithAssociatedActions,
          actions: section.DocumentsWithAssociatedActions.actions.map(
            (action) => {
              if (action.constraints) {
                return {
                  ...action,
                  constraints: applyDetailsConstraints(
                    action.constraints,
                    mapper
                  ),
                };
              }
              return {
                ...action,
                constraints: {
                  display: true,
                  disable: false,
                },
              };
            }
          ),
        },
      };
    }
    return section;
  });

export const handleClick = (e, action, props, functionParameters) => {
  let attributes = {};
  if (action.onClick.functionAttributes)
    action.onClick.functionAttributes.forEach((attribute) => {
      // For params
      if (attribute.source === "functionParameters") {
        attributes[attribute.field] = functionParameters[attribute.field];
      } else {
        attributes[attribute.field] = attribute.value;
      }
    });
  props[action.onClick.functionName](attributes);
};
export const getConditionalClasses = (values, conditionalClasses) => {
  let classes = "";
  if (conditionalClasses) {
    conditionalClasses.forEach((conditionalClass) => {
      if (values[conditionalClass.field] === conditionalClass.value)
        classes += conditionalClass.className;
    });
  }
  return classes;
};

export const setValueForSelfConditions = (constraint, object) => {
  let newConditions = constraint.conditions.map((condition) => {
    if (condition.actualValue === "self")
      return {
        ...condition,
        actualValue: object[condition.key],
      };
    return condition;
  });
  return newConditions;
};
// export default functionsHandlers;
