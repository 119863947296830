import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import { setAllAttachments, setAttachmentReviewed } from "reducers/details";
import ViewFile from "../../ViewFile";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Document from "../../Document";
import { getValue } from "utils/GetObjectValues";
import { AccordionDetails, Button, ListItem } from "@material-ui/core";
import { GetApp, Close } from "@material-ui/icons/";
import { useDropzone } from "react-dropzone";
import { handleError } from "api/utilities";
import client from "api/apiAuth/guestClient";
import axios from "axios";
import FilesUpload from "./FilesUpload";
import FolderIcon from "@material-ui/icons/Folder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FolderTwoToneIcon from "@material-ui/icons/FolderTwoTone";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const SimpleAccordion = (props) => {
  let {
    doctorAttachments,
    attachmentReviewed,
    setAttachmentReviewed,
    allAttachments,
    setAllAttachments,
    selectedTab,
    initialValues,
    setInitialValues,
    schemaInputs,
    setSchemaInputs,
    formik,
    attachmentsUnderReview,
    task,
    submission,
    userPermission,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [currentUploadedFiles, setCurrentUploadedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [rejectedFileErrorMessage, setRejectedFileErrorMessage] = useState([]);
  const [openCameraModal, setOpenCameraModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxSize: 10000000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      acceptedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setCurrentUploadedFiles([...acceptedFiles]);
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          let errorMessage;
          if (err.code === "file-too-large") {
            errorMessage = "file is too large";
          }

          if (err.code === "file-invalid-type") {
            errorMessage = "please check files extensions";
          }
          setRejectedFileErrorMessage([
            ...rejectedFileErrorMessage,
            { fileName: file.file.name, message: errorMessage },
          ]);
        });
      });
    },
  });
  const classes = useStyles();

  React.useEffect(() => {
    // if (allAttachments) {
    //   let filteredAttachments = [];
    //   let clonedAttachments = _.cloneDeep(allAttachments);
    //   clonedAttachments.forEach((attachment) => {
    //     let foundAttachment = documents.attachments.find(
    //       (configAttachment) =>
    //         configAttachment.equivalent_key ===
    //           attachment.document.equivalent_key &&
    //         attachment.submission_modification_id
    //     );
    //     if (foundAttachment) {
    //       filteredAttachments.push({
    //         ...attachment,
    //         underRevision: foundAttachment.underRevision,
    //         majorReviewers: foundAttachment.majorReviewers,
    //         minorReviewers: foundAttachment.minorReviewers,
    //         passWithRejection: foundAttachment.passWithRejection,
    //         uploadedBy: foundAttachment.uploadedBy,
    //         info: foundAttachment.info,
    //       });
    //     }
    //   });
    //   setAttachments(filteredAttachments || []);
    // }
  }, [selectedTab, allAttachments]);
  function uploadFile(file, formik, documentName, isMultiple) {
    let formData = new FormData();
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const url = `attachment/uploadFile?documentName=&submission_id=${submission?.file_code}`;
    formData.append("avatar", file);
    client
      .post(url, formData, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgress(progress);
        },
      })
      .then((result) => {
        let documentData = {
          ...result.data.results,
          original_file_name: file.name,
          fileObject: file,
        };
      })
      .catch((error) => {
        console.error(error);
        setProgress(0);
      });
  }
  const handleDocumentChange = (event) => {
    event.persist();
    let documentName = event.target.name,
      file = event.target.files[0];
    uploadFile(file, formik, documentName);
    event.target.value = "";
  };
  const removeFile = async (file) => {
    try {
      //find attachment in redux store
      let attachment = submission.attachments?.find(
        (attachment) => file.preview == attachment.metadata.preview
      );
      let avatarFd = attachment?.avatarFd;
      if (attachment) {
        //remove attachment file from backend
        await client.post(
          `attachment/deleteUnlinkedAttachment?avatarFd=${avatarFd}`
        );
        //remove attachment from redux store
        // setSubmission({
        //   ...submission,
        //   attachments: submission.attachments.filter(
        //     (attachment) => file.preview != attachment.metadata.preview
        //   ),
        // });
      }
      // remove attachment from its section in formik
      // let filteredFiles = getValue(formik.values, input.key).filter((value) =>
      //   value.preview
      //     ? file.preview != value.preview
      //     : file.preview != value.metadata.preview
      // );
      // setFiles(
      //   files.filter((value) =>
      //     value.preview
      //       ? file.preview != value.preview
      //       : file.preview != value.metadata.preview
      //   )
      // );
    } catch (error) {
      handleError(error);
    }
  };
  const thumbs = uploadedFiles.map((file, index) => {
    return file?.type?.includes("image") ? (
      <Grid item md={3} className="p-2" key={file.name + index}>
        <div className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden rounded-sm">
          <Close className="remove-img" onClick={() => removeFile(file)} />
          <img
            className="img-fluid img-fit-container rounded-sm"
            src={file?.pdfUrl ? file.pdfUrl : URL.createObjectURL(file)}
            alt={file.name}
          />
        </div>
      </Grid>
    ) : (
      file && (
        <ListItem
          className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
          key={file.name + index}
        >
          <span className="file-name">{file.name}</span>{" "}
          {file.size && (
            <span className="badge badge-pill bg-neutral-warning text-warning ">
              {file.size} bytes
            </span>
          )}
          <Close
            className="mr-1 remove-file"
            onClick={() => removeFile(file)}
          />
        </ListItem>
      )
    );
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setSelectedDoctor(panel);
    setExpanded(isExpanded ? panel : false);
  };

  const getAttachments = () => {
    return (
      <div className={classes.root}>
        {Object.keys(doctorAttachments)?.map((doctor, index) => (
          <Accordion
            key={index}
            expanded={expanded === doctor}
            onChange={handleChange(doctor)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.heading}>
                  <div className="d-flex align-items-center">
                    <div className="d-70 rounded bg-neutral-first text-dark mr-3 text-center">
                      <FolderTwoToneIcon className="w-50 h-50" />
                    </div>
                    <div className="flex-grow-1 text-black-50">
                      <div className="text-black font-weight-bold">
                        {doctor}
                      </div>
                      <div>
                        <span className="font-weight-bold">
                          {doctorAttachments[doctor].length} files
                        </span>
                      </div>
                      {/* <div className="font-size-sm mt-2">
                        Last Updated:{" "}
                        <span className="text-black">moments ago</span>
                      </div> */}
                    </div>
                    <div className="font-size-sm opacity-5"></div>
                  </div>
                </Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails style={{ backgroundColor: "#FFF" }}>
              <Grid container>
                <Grid item container xs={12} style={{ marginTop: 20 }}>
                  {doctorAttachments[doctor]?.map((attachment, index) => (
                    <div style={{ width: "100%" }} key={index}>
                      <Document
                        attachment={attachment}
                        setFile={setFile}
                        setOpen={setOpen}
                        allAttachments={allAttachments}
                        setAllAttachments={setAllAttachments}
                        attachmentsUnderReview={attachmentsUnderReview}
                        attachmentReviewed={attachmentReviewed}
                        setAttachmentReviewed={setAttachmentReviewed}
                        selectedTab={selectedTab}
                        sectionId={1}
                        props={props}
                        initialValues={initialValues}
                        setInitialValues={setInitialValues}
                        schemaInputs={schemaInputs}
                        setSchemaInputs={setSchemaInputs}
                        formik={formik}
                      />
                    </div>
                  ))}
                </Grid>
                <Grid
                  xs={12}
                  item
                  container
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <FilesUpload
                    doctorAttachments={doctorAttachments[selectedDoctor]}
                    doctorName={selectedDoctor}
                    userPermission={userPermission}
                  />
                  {thumbs?.length > 0 && (
                    <div className="card-footer p-3 ">
                      <div>
                        <div className="font-weight-bold mb-3 text-uppercase text-dark font-size-sm text-center">
                          Uploaded Files
                        </div>
                        <div>
                          <Alert
                            severity="success"
                            className="text-center mb-3"
                          >
                            You have uploaded <b>{thumbs?.length}</b> files!
                          </Alert>
                          <Grid container spacing={0}>
                            {thumbs}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };
  return (
    <>
      <ViewFile file={file} open={open} setOpen={setOpen} />
      {getAttachments()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allAttachments: state.details.allAttachments,
    attachmentReviewed: state.details.attachmentReviewed,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
    submission: state.form.submission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllAttachments: (attachments) =>
      dispatch(setAllAttachments(attachments)),
    setAttachmentReviewed: (attachments) =>
      dispatch(setAttachmentReviewed(attachments)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SimpleAccordion);
