import React, { useState, useEffect } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import clsx from "clsx";
import { connect } from "react-redux";
import SpinnerLoader from "../../../../components/SpinnerLoader";
import Slots from "./TimeSLot/TimeSlot";
const SlotTime = (props) => {
  const { departments } = props;

  const [activeTab, setActiveTab] = useState(departments[0]);
  const handleMenu = (menu) => {
    if (activeTab.id !== menu.id) {
      setActiveTab({ id: menu.id, name: menu.name });
    }
  };
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12} className="mt-1">
          <List className="nav-tabs nav-tabs-primary d-flex p-0 tabs-list">
            {departments.map((menu) => {
              return (
                <ListItem
                  primary="Single-line item"
                  key={menu.id}
                  defaultValue={departments[0]}
                  button
                  disableRipple
                  selected={activeTab.id === menu.id}
                  className="m-0 text-center"
                  style={{ flexDirection: "column", flexWrap: "wrap" }}
                  onClick={() => {
                    handleMenu(menu);
                  }}
                >
                  {menu.name}
                </ListItem>
              );
            })}
          </List>

          <div
            className={clsx("tab-item-wrapper", {
              active: true,
            })}
            style={{ overflow: "hidden", borderRadius: "0 20px 20px 20px" }}
            index={0}
          >
            <Slots productType={activeTab.name} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    departments: state.auth.user.departments,
  };
};
export default connect(mapStateToProps)(SlotTime);
