import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Grid, Divider, TableCell } from "@material-ui/core";
import SimpleTable from "components/SimpleTable";
import client from "api/apiAuth/guestClient";

const GroupsTable = ({ task }) => {
  const [currentSubmission, setCurrentSubmission] = useState({});
  useEffect(() => {
    if (task)
      setCurrentSubmission({
        ...currentSubmission,
        ...task.submission,
      });
  }, [task]);
  let tableColumns = [
    {
      label: "Name",
      name: "name",
    },
    {
      label: "Committe Date",
      name: "values.start_date",
      type: "date",
      renderCell: function (params) {
        return (
          <TableCell key={params.cellIndex} className="text-center">
            <span>
              {(params?.row &&
                new Date(params.row.values.start_date).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                )) ||
                "---"}
            </span>
          </TableCell>
        );
      },
    },
    {
      label: "Committe Decision",
      name: "submissions[0].values.take_decision",
      renderCell: function (params) {
        return (
          <TableCell key={params.cellIndex} className="text-center">
            <span>
              {(currentSubmission?.values?.take_decision &&
                currentSubmission?.values?.take_decision) ||
                "---"}
            </span>
          </TableCell>
        );
      },
    },
    {
      label: "Technical Committe Decision",
      name: "submissions[0].values.technicalCommitteDecision",
      renderCell: function (params) {
        return (
          <TableCell key={params.cellIndex} className="text-center">
            <span>
              {(currentSubmission?.values?.technicalCommitteDecision &&
                currentSubmission?.values?.technicalCommitteDecision) ||
                "---"}
            </span>
          </TableCell>
        );
      },
    },
    {
      label: "Technical Committe Date",
      name: "submissions[0].values.committe_date",
      renderCell: function (params) {
        return (
          <TableCell key={params.cellIndex} className="text-center">
            <span>
              {(currentSubmission?.values?.committe_date &&
                new Date(
                  currentSubmission?.values?.committe_date
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })) ||
                "---"}
            </span>
          </TableCell>
        );
      },
    },
    {
      label: "Status",
      name: "status",
    },
  ];
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const handleRowClick = (e) => {
    history.push(`/SubmissionGroupDetails/Components/GroupDetails/${e.row.id}`);
  };
  useEffect(async () => {
    const response = await client.post("generic/find", {
      model: "SubmissionGroup",
      include: {
        association: "submissions",
        where: { id: task.submission.id },
      },
    });
    setGroups(response.data.rows);
  }, []);

  return (
    <Card className="card-box w-100 p-4 my-3">
      <Grid container>
        <Grid item xs={12}>
          <h3 className="details-header padding">Committee:</h3>
          <Divider />
          <SimpleTable
            columns={tableColumns}
            rows={groups}
            onRowClick={handleRowClick}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default GroupsTable;
