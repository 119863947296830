import { Grid } from "@material-ui/core";
import React from "react";

export default function ModificationAttribute({ type,value, name, size, padding }) {
  return (
    <>
      {value !== undefined && (
        <div style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={2}
            columns={12}
            style={{ paddingLeft: padding ? `${padding}px` : `15px` }}
          >
            <Grid item xs={size} className="title-label">
              {name}
            </Grid>
            <Grid item xs={6} className="data">
              {type === "date1" ? new Date(value).toLocaleDateString("en-US"): value}
            </Grid>
            <br />
          </Grid>
        </div>
      )}
    </>
  );
}
