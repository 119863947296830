import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { getValue } from "utils/GetObjectValues";
export default function ExportToExelFile({ data, exportedSheetName, columns }) {
  const [exportedData, setExportedData] = useState([]);
  useEffect(() => {
    data &&
      columns &&
      setExportedData([
        ...data.map((row) => {
          let filteredSubmission = {};
          if (columns.length > 0) {
            for (const col of columns) {
              filteredSubmission[col.name] = getValue(row, col.key);
            }
            return filteredSubmission;
          } else {
            return row;
          }
        }),
      ]);
  }, [data, columns]);
  document.querySelector("#exportToExcel")?.addEventListener("click", () => {
    Swal.fire({
      title: `Do you want to export table to Excel`,
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        const workSheet = XLSX.utils.json_to_sheet(exportedData);

        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "submissions");

        XLSX.write(workBook, { bookType: "xls", type: "buffer" });
        XLSX.write(workBook, { bookType: "xls", type: "binary" });
        XLSX.writeFile(workBook, `${exportedSheetName}.xls`);
      }
    });
  });
  return (
    <>
      <button id="exportToExcel" className="stepButton">
        Export To Excel
      </button>
    </>
  );
}
