import { TableFooter, TablePagination, TableRow } from '@material-ui/core'
import React from 'react'

const Footer = ({entities,rowsPerPage,page,handleChangePage,handleChangeRowsPerPage}) => {
  return (
    <TableFooter>
    <TableRow>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage=""
        colSpan={4}
        count={entities.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ direction: "ltr" }}
      />
    </TableRow>
  </TableFooter>
  )
}

export default Footer