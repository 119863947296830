import React, { useEffect, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import client from "../../api/apiAuth/guestClient";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  Select,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import { pdfjs } from "react-pdf";
import FileUploader from "components/FileUploader";
import { handleDocument } from "../../utils/uploadFile";
import { uploadFile } from "./utils";
import ErrorMessage from "../ErrorMessage";
import WysiwygEditor from "components/WysiwygEditor";
import { DatePicker } from "@material-ui/pickers";
import { format, add } from "date-fns";
import Selection from "components/FormsComponents/Selection";
import { applyInputConstraints } from "utils/LayoutConstraints/ConstraintsService";
import BooleanCheckbox from "../FormsComponents/BooleanCheckbox/BooleanCheckbox";
import { getOnchangeEvents } from "../../utils/FormUtils/Events/EventsHelperFunctions";
import AudioRecorder from "../AudioRecorder";
import UploadFile from "components/UploadFile";
import JoditEditorComponent from "./JoditEditor";
import MultiSelect from "components/FormsComponents/MultiSelect";
import { useLocation } from "react-router";
import Info from "components/FormsComponents/Info";
import { KeyboardDatePicker } from "@material-ui/pickers";
const getValue = (obj, path, inputType) => {
  if (path) {
    path = path.replace(/\[(\w+)\]/g, "$1"); // convert indexepaths to propertis
    path = path.replace(/^\./, ""); // strip a leading dot
    let value = path.split(".").reduce((o, i) => {
      if (o) {
        if (o[i] === true || o[i] === false) return o[i].toString();
        if (inputType === "date") {
          return o[i] && typeof o[i] !== "object"
            ? new Date(o[i]).toLocaleDateString("en-CA")
            : o[i];
        }
        return o[i];
      }
      return null;
    }, obj);
    return value;
  }
};

function InputWidget(props) {
  let {
    input,
    formik,
    enableSubmit,
    submission,
    sectionIndex,
    groupIdx,
    index,
    submissionValue,
    stepId,
    setSectionNameFlags,
    enableOnSubmit,
    onValueChange,
    requestsCancelToken,
    uniqueNo,
    isMany,
    selectedOption,
    setSelectedOption,
  } = props;
  const [options, setOptions] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState();
  const [newMultipleValue, setNewMultipleValue] = useState([]);
  const location = useLocation();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const inputValue = getValue(formik.values, input.key, input.itype);
  const inputError = getValue(formik.errors, input.key);
  const inputTouched = getValue(formik.touched, input.key);
  const inputLabel = `${
    sectionIndex !== undefined
      ? ` ${sectionIndex}.${groupIdx ? groupIdx + "." : ""}${index}`
      : ""
  } ${input.name}`;
  const checkConditionalProps = () => {
    const regex = /[.][0-9]*[.]/g;
    const numParts = input?.key?.match(regex);
    if (!_.isEmpty(input.dependency)) {
      const constraints = input.dependency.reduce((constraints, element) => {
        let newKey;
        const conditions = element.conditions.map((condition) => {
          newKey = isMany ? condition.key : condition.single_key;
          if (numParts)
            numParts.forEach((element) => {
              newKey = newKey.replace(".[i].", element);
            });
          return {
            ...condition,
            key: newKey,
            actualValue:
              getValue(formik.values, newKey) ||
              getValue(submissionValue, newKey),
          };
        });
        let result = {
          ...constraints,
          ...applyInputConstraints(
            {
              ...element,
              conditions,
            },
            "layout"
          ),
        };
        if (
          input.dependency.find((dependent) =>
            dependent.constraints?.validation?.hasOwnProperty("dependent")
          )
        ) {
          // constraints.required = true;
          //FIXME:
          constraints.display = true;

          let validation = {
            ...constraints,
            ...applyInputConstraints(
              {
                ...element,
                conditions,
              },
              "validation"
            ),
          };
          result = {
            ...result,
            ...validation,
          };
        }
        conditions.forEach((condition) => {
          let checkedKey = isMany ? condition.key : condition.single_key;
          if (
            element.constraints?.enumValues?.length &&
            condition.checkValue.includes(getValue(formik.values, checkedKey))
          ) {
            result = {
              ...result,
              //if enum value array
              // options: element.constraints?.enumValues.map((option) => {
              //   return {
              //     label: option,
              //   };
              // }),
              //if enum value object
              display: true,
              options: element.constraints?.options[
                getValue(formik.values, checkedKey)
              ].map((option) => {
                return {
                  label: option,
                };
              }),
            };
          }
          if (
            inputValue &&
            element.constraints?.enumValues?.length &&
            !element.constraints?.options[
              getValue(formik.values, checkedKey)
            ].includes(inputValue)
          ) {
            formik.setFieldValue(input.key, undefined);
          }
        });
        return result;
      }, {});
      if (!constraints.display) {
        if (input.itype === "document") {
          let fileRequest = requestsCancelToken.find(
            (request) =>
              request.url.includes(
                `attachment/uploadFile?documentName=${input.equivalent_key}`
              ) && request.key === input.key
          );
          if (fileRequest) {
            fileRequest?.token?.cancel(
              `uploading ${input.equivalent_key} file has been canceled  `
            );
          }
        }
      }

      if (!constraints.display && inputValue) {
        formik.setFieldValue(input.key, undefined);
      }
      return constraints;
    } else {
      return { disable: false, display: true };
    }
  };

  (function setInputProperties() {
    const inputLayoutProps = checkConditionalProps();
    Object.keys(inputLayoutProps).forEach((key) => {
      input[key] = inputLayoutProps[key];
    });
  })();

  useEffect(() => {
    setSectionNameFlags &&
      setSectionNameFlags((previousState) => {
        return {
          ...previousState,
          [stepId]: {
            ...previousState[stepId],
            [input.id]: input.display,
          },
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChangeMultiple = (event) => {
    setNewMultipleValue(event.target.value);
  };

  useEffect(() => {
    // handlePdfEditor();
  }, []);
  if ("display" in input && !input.display && inputValue) {
    formik.setFieldValue(input.key, undefined);
  }

  const inputs = {
    view_select: () => {
      async function loadOptions(search, loadedOptions) {
        let requestBody = {
          model: input.input_layout.view,
          limit: input.input_layout.limit,
          skip: loadedOptions.length,
          include: input.input_layout.association_info,
        };
        // if (input.input_layout.association_info) {
        //   requestBody.include = [...input.input_layout.association_info];
        // }
        if (search && search.length) {
          requestBody.where = {
            [input.input_layout.searchField]: {
              operator: "like",
              value: `%${search}%`,
            },
          };
        }

        const response = await client.post("/generic/find", requestBody);
        return {
          options: response.data.rows.map((item) => {
            return {
              value: item[input.input_layout.searchField],
              label: item[input.input_layout.optionLabel],
              id: item.id,
            };
          }),
          hasMore:
            loadedOptions.length + input.input_layout.limit <
            response.data.count,
        };
      }
      return (
        <>
          <AsyncPaginate
            placeholder={`${input.name} ${input.required ? "*" : ""}`}
            value={
              inputValue
                ? {
                    label: selectedOption?.[input.key] || inputValue,
                    value: inputValue,
                  }
                : ""
            }
            loadOptions={loadOptions}
            styles={{
              menu: (provided) => {
                return { ...provided, zIndex: 2 };
              },
            }}
            onChange={async (ev) => {
              if (ev.value) {
                await getOnchangeEvents(
                  { ...input, realKey: input?.key },
                  formik,
                  ev.value
                );
                ev.target = {
                  name: input.key,
                };
              }
              setSelectedOption({ [input.key]: ev.label });
              formik.setFieldValue(input.key, ev.value);
              if (onValueChange) onValueChange(input, ev.value, ev);
            }}
            onBlur={() => {
              formik.setFieldTouched(input.key, true);
            }}
          />

          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit || enableOnSubmit}
            errorMessage={inputError}
          />
        </>
      );
    },
    string: () => (
      <>
        <React.Fragment>
          {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={
                "heading-4 d-block mt-4 comment  input-label-font text-left"
              }
              style={{
                display: "block",
                ...input.input_layout?.labelStyle,
              }}
            >
              {inputLabel}
              {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <FormControl fullWidth>
            <Input
              fullWidth
              name={input.key}
              id={input.key}
              value={inputValue || undefined}
              className="input-border"
              disableUnderline
              placeholder={
                input.input_layout && input.input_layout.placeholder
                  ? `${input.input_layout.placeholder} ${
                      input.required ? "*" : ""
                    }`
                  : ""
              }
              inputProps={{
                className: `input ${input.disabled && "disabled"}`,
                disabled: input.disabled,
                "data-section": input.section,
                readOnly: input.input_layout.readOnly,
              }}
              onChange={(e) => {
                if (e.target.value !== "") formik.handleChange(e);
                else formik.setFieldValue(input.key, undefined);
                if (onValueChange) {
                  onValueChange(input, e.target.value, e);
                }
              }}
              onBlur={async (e) => {
                formik.handleBlur(e);
                if (e.target.value) {
                  await getOnchangeEvents(
                    { ...input, realKey: input?.key },
                    formik,
                    e.target.value
                  );
                }
              }}
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit || enableOnSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </React.Fragment>
      </>
    ),
    stringOutLine: () => (
      <>
        <React.Fragment>
          {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={"p-1 heading-4 input-label-font text-align-left"}
              style={{
                display: "block",
                ...input.input_layout?.labelStyle,
              }}
            >
              {inputLabel}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              name={input.key}
              id={input.key}
              value={inputValue || undefined}
              onChange={(ev) => {
                if (ev.target.value !== "") formik.handleChange(ev);
                else formik.setFieldValue(input.key, undefined);
                if (onValueChange) {
                  onValueChange(input, ev.target.value, ev);
                }
              }}
              onBlur={async (e) => {
                formik.handleBlur(e);
                if (e.target.value) {
                  await getOnchangeEvents(
                    { ...input, realKey: input?.key },
                    formik,
                    e.target.value
                  );
                }
              }}
              inputProps={{
                className: ` ${input.disabled && "disabled"}`,
                disabled: input.disabled,
                max: input?.validation?.max,
                "data-section": input.section,
              }}
              // label={input.name}
              label={input.required? `${input.name} *`:input.name}
              style={{
                display: input.disabled ? "none" : "block",
                paddingTop: "0px !important",
              }}
              // InputLabelProps={{
              //   style: { color: 'red' },
              // }}
              variant="outlined"
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </React.Fragment>
      </>
    ),
    pdfEditor: () => {
      return (
        <>
          <div>
            {input.name && input.input_layout.displayLabel !== "false" && (
              <span
                className={
                  "heading-4 d-block mt-4 comment  input-label-font text-left"
                }
                style={{
                  display: "block",
                  ...input.input_layout?.labelStyle,
                }}
              >
                {inputLabel}
                {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
                {/* {input.required && <span className="color-red">*</span>} */}
              </span>
            )}
            <JoditEditorComponent formik={formik} input={input} />
          </div>
        </>
      );
    },
    dateInspection: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={input.input_layout?.labelStyle}
          >
            {inputLabel}

            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          name={input.key}
          id={input.key}
          value={inputValue || null}
          minDate={new Date(input?.validation?.min).toString()}
          onChange={(val) => {
            formik
              .setFieldValue(input.key, new Date(val).toLocaleDateString())
              .then(() => formik.handleBlur({ target: { name: input.key } }));
            if (onValueChange) {
              onValueChange(input, new Date(val).toLocaleDateString(), null);
            }
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
        />

        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    number: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}

            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <FormControl fullWidth>
          <Input
            fullWidth
            name={input.key}
            id={input.key}
            className="input-border"
            value={inputValue || 0}
            type="number"
            disableUnderline
            inputProps={{
              className: `input ${input.disabled && "disabled"}`,
              min: 0,
              disabled: input.disabled,
              "data-section": input.section,
            }}
            style={{ display: input.disabled ? "none" : "block" }}
            onChange={(ev, value) => {
              formik.handleChange(ev, value);
              if (onValueChange) {
                onValueChange(input, ev.target.value, ev);
              }
            }}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit}
            errorMessage={inputError}
          />
        </FormControl>
      </>
    ),
    numberOutLine: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={"p-1 heading-4 input-label-font text-left"}
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <FormControl fullWidth>
          <TextField
            size="small"
            fullWidth
            name={input.key}
            id={input.key}
            value={inputValue || undefined}
            onChange={(ev, value) => {
              formik.handleChange(ev, value);
              if (onValueChange) {
                onValueChange(input, ev.target.value, ev);
              }
            }}
            onBlur={formik.handleBlur}
            inputProps={{
              className: `input ${input.disabled && "disabled"}`,
              min: 0,
              disabled: input.disabled,
              "data-section": input.section,
            }}
            label={input.name}
            style={{ display: input.disabled ? "none" : "block" }}
            type="number"
            variant="outlined"
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit}
            errorMessage={inputError}
          />
        </FormControl>
      </>
    ),
    date: () => {
      const inputProps = {};
      if (input.input_layout.minDateAfterToday) {
        inputProps.min = format(add(new Date(), { days: 0 }), "yyyy-MM-dd");
      }
      return (
        <>
          {/* {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={"heading-4 d-block mt-4 "}
              style={input.input_layout?.labelStyle}
            >
              {inputLabel}

              {input.required && <span className="color-red">*</span>}
            </span>
          )} */}
          <FormControl fullWidth>
            {/* <TextField
              label={input.name}
              variant="outlined"
              size="small"
              id="date"
              type="date"
              format="MM/dd/yyyy"
              hidden={input.input_layout.hidden}
              // mask="__/__/____"
              className=" justify-content-center"
              onChange={async (event) => {
                event &&
                  formik
                    .setFieldValue(
                      input.key,
                      event.target.value &&
                        new Date(event.target.value).toLocaleDateString()
                    )
                    .then(() =>
                      formik.handleBlur({
                        target: {
                          name: input.key,
                        },
                      })
                    );
                await getOnchangeEvents(
                  { ...input, realKey: input?.key },
                  formik,
                  event.target.value
                );
              }}
              inputProps={inputProps}
              InputLabelProps={{ shrink: true }}
              // InputProps={{
              // 	disableUnderline: true,
              // 	className: ` ${input.disabled && "disabled"}`,
              // 	disabled: input.disabled,
              // }}
              value={
                inputValue
                  ? new Date(
                      new Date(inputValue).getTime() -
                        new Date(inputValue).getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
            /> */}

            <KeyboardDatePicker
              autoOk
              variant="outlined"
              inputVariant="outlined"
              // label={input.name}              
              label={input.required? `${input.name} *`:input.name}
              size="small"
              format="dd/MM/yyyy"
              className=" justify-content-center"
              value={
                inputValue
                  ? new Date(
                      new Date(inputValue).getTime() -
                        new Date(inputValue).getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .split("T")[0]
                  : null
              }
              InputAdornmentProps={{ position: "end" }}
              onChange={async (date) => {
                date &&
                  formik
                    .setFieldValue(
                      input.key,
                      date && new Date(date).toISOString()
                    )
                    .then(() =>
                      formik.handleBlur({
                        target: {
                          name: input.key,
                        },
                      })
                    );
                await getOnchangeEvents(
                  { ...input, realKey: input?.key },
                  formik,
                  date
                );
              }}
            />
            {/* 
            <TextField
              label={input.name}
              variant="outlined"
              id="date"
              type="date"
              format="yyyy-MM-dd"
              inputProps={inputProps}
              className=" justify-content-center"
              value={
                inputValue
                  ? new Date(inputValue).toISOString().slice(0, 10)
                  : ""
              }
              onChange={async (event) => {
                event &&
                  formik
                    .setFieldValue(
                      input.key,
                      event.target.value &&
                        new Date(event.target.value).toLocaleDateString()
                    )
                    .then(() =>
                      formik.handleBlur({
                        target: {
                          name: input.key,
                        },
                      })
                    );
                await getOnchangeEvents(
                  { ...input, realKey: input?.key },
                  formik,
                  event.target.value
                );
              }}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </>
      );
    },
    radioSelect: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <RadioGroup
          className={"radio-select"}
          row
          name={input.key}
          id={input.key}
          data-section={input.section}
          value={inputValue || 0}
          onChange={(ev, value) => {
            const newValue =
              input.itype === "boolean" ? value === "true" : value;

            formik.handleChange({
              ...ev,
              target: { name: ev.target.name, value: newValue },
            });
            if (onValueChange) {
              onValueChange(input, newValue, ev);
            }
          }}
          onBlur={formik.handleBlur}
        >
          {input.itype === "enum"
            ? input.options?.map((option, i) => (
                <FormControlLabel
                  className={
                    inputValue === option.label
                      ? "checkBox-checked"
                      : "checkBox-unchecked"
                  }
                  key={option.id ? option.id : option.label}
                  value={option.label}
                  control={
                    <Radio
                      style={{
                        color: inputValue === option.label ? "#0066cc" : null,
                      }}
                    />
                  }
                  label={option.label}
                  disabled={input.disabled}
                />
              ))
            : [
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ].map((option, i) => (
                <FormControlLabel
                  key={i}
                  className={
                    inputValue === option.value
                      ? "checkBox-checked"
                      : "checkBox-unchecked"
                  }
                  value={option.value}
                  control={
                    <Radio
                      style={{
                        color: inputValue === option.value ? "#0066cc" : null,
                      }}
                    />
                  }
                  label={option.label}
                  disabled={input.disabled}
                />
              ))}
        </RadioGroup>
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    select: () => (
      <>
        <Selection
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={false}
          onValueChange={onValueChange}
        />
        {(inputTouched || enableSubmit || enableOnSubmit) && inputError ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    autoComplete: () => {
      async function loadOptions(search, loadedOptions) {
        let requestBody = {
          model: input.input_layout.model,
          limit: input.input_layout.limit,
          skip: loadedOptions.length,
          include: JSON.parse(input.input_layout.association_info),
        };
        // if (input.input_layout.association_info) {
        //   requestBody.include = [...input.input_layout.association_info];
        // }
        if (loadedOptions.length === 0 && inputValue) {
          requestBody.where = {
            id: {
              operator: "equal",
              value: inputValue,
            },
          };
        }
        if (search && search.length) {
          requestBody.where = {
            [input.input_layout.searchField]: {
              operator: "like",
              value: `%${search}%`,
            },
          };
        }

        const response = await client.post("/generic/find", requestBody);
        setOptions([...options, ...response.data.rows]);

        return {
          options: response.data.rows.map((item) => {
            return {
              value: item[input.input_layout.searchField],
              label: item[input.input_layout.optionLabel],
              id: item.id,
            };
          }),
          hasMore:
            loadedOptions.length + input.input_layout.limit <
            response.data.count,
        };
      }
      return (
        <>
          <AsyncPaginate
            placeholder={`${input.name} ${input.required ? "*" : ""}`}
            value={
              inputValue
                ? {
                    label:
                      selectedOption?.[input.key]?.label ||
                      (options &&
                        options.find((option) => option.id === inputValue)
                          ?.name),
                    value: options.find((option) => option.id === inputValue),
                  }
                : ""
            }
            loadOptions={loadOptions}
            styles={{
              menu: (provided) => {
                return { ...provided, zIndex: 2 };
              },
            }}
            defaultOptions
            onChange={async (ev) => {
              if (ev.value) {
                await getOnchangeEvents(
                  { ...input, realKey: input?.key },
                  formik,
                  ev.id
                );
              }
              ev.target = {
                name: input.key,
              };
              setSelectedOptionId(ev.id);
              setSelectedOption({
                [input.key]: { label: ev.label, id: ev.id },
              });
              formik.setFieldValue(input.key, ev.id);
              if (onValueChange) onValueChange(input, ev.id, ev);
            }}
            onBlur={() => {
              formik.setFieldTouched(input.key, true);
            }}
          />
          {input.input_layout.displaySelectedData === "true" &&
            (selectedOption?.[input.key]?.id || inputValue) && (
              <Info
                value={selectedOption?.[input.key]?.id || inputValue}
                options={options.length > 0 ? options : [options]}
                input={input}
              />
            )}
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit || enableOnSubmit}
            errorMessage={inputError}
          />
        </>
      );
    },
    selectWithLabel: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <Selection
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={false}
        />
        {(inputTouched || enableSubmit) && inputError ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    selectWithLoadMore: () => (
      <>
        <Selection
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={true}
          onValueChange={onValueChange}
        />
        {(inputTouched || enableSubmit) && inputError ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    document: () => (
      <UploadFile
        input={input}
        formik={formik}
        uniqueNo={uniqueNo}
        submissionValue={submissionValue}
        onValueChange={onValueChange}
        inputValue={inputValue}
        inputError={inputError}
        inputTouched={inputTouched}
      />
    ),
    multipleDocument: () => (
      <>
        {input.name && (
          <span
            className={"p-1 heading-4 input-label-font"}
            style={input.input_layout?.labelStyle}
          >
            {input.name}
            {input.required && <span className="color-red"> *</span>}
          </span>
        )}
        <div style={{ alignItems: "center" }}>
          <FileUploader
            submission={submission}
            handleDocument={handleDocument}
            uploadFile={uploadFile}
            input={input}
            getValue={getValue}
            formik={formik}
            uniqueNo={uniqueNo}

            // setSubmission={setSubmission}
          />
        </div>
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    checkbox: () => (
      <>
        {input.itype === "boolean" ? (
          <BooleanCheckbox input={input} formik={formik} getValue={getValue} />
        ) : (
          <FormControl
            component="fieldset"
            className="formControl"
            style={input.input_layout?.labelStyle}
          >
            <FormGroup>
              {input.options?.map((option) => {
                return (
                  <React.Fragment key={option.id}>
                    <FormControlLabel
                      className={`mb-3 ${
                        inputValue?.includes(option?.label)
                          ? "checkBox-checked"
                          : "checkBox-unchecked"
                      }`}
                      key={option.id}
                      control={
                        <Checkbox
                          style={
                            inputValue?.includes(option?.label)
                              ? {
                                  color: "#C7A058",
                                }
                              : null
                          }
                          name={input.key}
                          data-section={input.section}
                          onChange={(ev, value) => {
                            formik.handleChange(ev, value);
                            if (onValueChange) {
                              onValueChange(input, ev.target.value, value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          checked={inputValue?.includes(option.label) || false}
                          value={option.label}
                          disabled={input.disabled}
                        />
                      }
                      label={option.label}
                    />
                  </React.Fragment>
                );
              })}
            </FormGroup>
          </FormControl>
        )}

        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    normalCheckbox: () => (
      <>
        <FormControlLabel
          fullWidth
          className={` ${
            inputValue === "true" ? "checkBox-checked" : "checkBox-unchecked"
          }`}
          key={input.id}
          control={
            <Checkbox
              style={
                inputValue === "true"
                  ? {
                      color: "#0066cc",
                    }
                  : null
              }
              name={input.key}
              data-section={input.section}
              onChange={formik.handleChange}
              checked={
                getValue(formik.values, input.key) === "true" ? true : false
              }
              value={
                getValue(formik.values, input.key) === "true" ? true : false
              }
              disabled={input.disabled}
            />
          }
          label={input.name}
        />
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    multipleSelect: () => (
      <>
        <MultiSelect
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={false}
          onValueChange={onValueChange}
        />
        {inputError ? <div className="text-danger">{inputError}</div> : null}
      </>
    ),
    // {
    //   console.log({ options });
    //   loadOptions(input).then((res) => {
    //     setOptions(res.options);
    //   });
    //   return (
    //     <>
    //       <Select
    //         multiple
    //         value={newMultipleValue}
    //         onChange={(ev) => {
    //           handleChangeMultiple(ev);
    //           if (onValueChange) onValueChange(input, ev.value);
    //           formik.setFieldValue(input.key, ev.value);
    //         }}
    //         input={<Input />}
    //         renderValue={(selected) => selected.join(", ")}
    //         MenuProps={MenuProps}
    //       >
    //         {options &&
    //           options.map((option) => (
    //             <MenuItem key={option.id} value={option.value}>
    //               <Checkbox
    //                 checked={newMultipleValue.indexOf(option.value) > -1}
    //               />
    //               <ListItemText primary={option.value} />
    //             </MenuItem>
    //           ))}
    //       </Select>
    //       {/* <Select
    //         multiple
    //         value={newMultipleValue}
    //         renderValue={(selected) => selected.join(", ")}
    //         onChange={(ev) => {
    //           handleChangeMultiple(ev);
    //           if (onValueChange) onValueChange(input, ev.value);
    //           formik.setFieldValue(input.key, ev.value);
    //         }}
    //         style={{
    //           width: "100%",
    //           overflow: "hidden",
    //         }}
    //       >
    //         {options &&
    //           options.map((option) => (
    //             <MenuItem key={option.id} value={option.name}>
    //               {option.name}
    //             </MenuItem>
    //           ))}
    //       </Select> */}

    //       <ErrorMessage
    //         isTouched={inputTouched}
    //         enableSubmit={enableSubmit || enableOnSubmit}
    //         errorMessage={inputError}
    //       />
    //     </>
    //   );
    // },
    textEditor: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className="p-1 heading-4 d-block text-left"
            style={input.input_layout?.labelStyle}
          >
            {input.name}
            {input.required && <span className="color-red"> *</span>}
          </span>
        )}
        <WysiwygEditor formik={formik} input={input}></WysiwygEditor>

        {(inputTouched || enableSubmit) &&
        getValue(formik.errors, input.key, input.itype) ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    textArea: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{
              display: "block",
              ...input.input_layout?.labelStyle,
            }}
          >
            {inputLabel}
            {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <FormControl>
          <textarea
            name={input.key}
            value={inputValue || ""}
            data-section={input.section}
            onChange={(ev) => {
              formik.handleChange(ev);
              if (onValueChange) {
                onValueChange(input, ev.target.value, ev);
              }
            }}
            onBlur={formik.handleBlur}
            rows={15}
            cols={"125"}
            style={{
              width: "100%",
              border: " 1px solid #e4e5eb",
              height: "200px",
            }}
            disabled={input.disabled}
          />
        </FormControl>
        {(inputTouched || enableSubmit) &&
        getValue(formik.errors, input.key, input.itype) ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    label: () => (
      <>
        <React.Fragment>
          {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={"heading-4 mt-4 comment input-label-font text-left"}
              style={{
                ...input.input_layout?.labelStyle,
              }}
            >
              {` ${input.name}`}{" "}
              {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <FormControl fullWidth style={{ display: "inline" }}>
            <p
              className={"heading-4 mt-4 comment  text-left"}
              style={{ display: "inline" }}
            >
              {" "}
              {inputValue}{" "}
            </p>
          </FormControl>
        </React.Fragment>
      </>
    ),
    blank: () => <></>,
    voice: () => {
      async function uploadAudio(data) {
        if (onValueChange) {
          onValueChange(input, data, null);
        }
        const formData = new FormData();
        formData.append(
          "avatar",
          new File([data.blob], "record.webm", {
            type: data.blob.type,
          })
        );

        const result = await client.post(
          `attachment/uploadFile?documentName=${input.equivalent_key}`,
          formData
        );
        if (!input.input_layout.skipFormik)
          formik.setFieldValue(input.key, {
            ...result.data.results,
            input_id: input.id,
            name: input.key,
          });
      }
      return <AudioRecorder onAudioRecord={uploadAudio} />;
    },
  };
  return (
    <React.Fragment key={input.key}>
      {input.display === true && (
        <Grid
          item
          md={input.input_layout?.gridSize}
          lg={input.input_layout?.gridSizelg}
          sm={input.input_layout ? input.input_layout.gridSizeSM : 6}
          xs={12}
          style={input.input_layout || {}}
          className="pl-0"
        >
          <div>{inputs[String(input.input_layout?.type)]()}</div>
        </Grid>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    enableSubmit: state.form.enableSubmit,
    fileUploadError: state.form.fileUploadError,
    submission: state.form.submission,
    user: state.auth.user,
    requestsCancelToken: state.general.requestsCancelToken,
  };
};

export default connect(mapStateToProps, null)(InputWidget);
