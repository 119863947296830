import {
  Card,
  Grid,
  CardContent,
  Button,
  ListItem,
  List,
  Checkbox,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { Subtitles } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "./style.css";
import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { handleError } from "api/utilities";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { getValue } from "utils/GetObjectValues";

const SubmissionLimit = ({ productName, companyLimit }) => {
  let [weekDays, setWeekDays] = useState([]);
  let [initialValues, setInitialValues] = useState({});

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      submissionLimit: Yup.array()
        .of(
          Yup.object().shape({
            checked: Yup.boolean().nullable(),
            day: Yup.string().when("checked", {
              is: true,
              then: Yup.string().required("day is required"),
              otherwise: Yup.string().notRequired().nullable(),
            }),
            product_type: Yup.string().when("checked", {
              is: true,
              then: Yup.string().required("product_type is required"),
              otherwise: Yup.string().notRequired().nullable(),
            }),
            submissions_limit: Yup.number().when("checked", {
              is: true,
              then: Yup.number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: Yup.number().notRequired().nullable(),
            }),
          })
        )
        .required(),
    }),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      submitSubmissionLimit();
    },
  });
  useEffect(() => {
    let getWorkingDays = () => {
      client
        .get("SubmissionsLimit/getDaysLimitation", {
          params: {
            product_type: productName,
          },
        })
        .then((result) => {
          let { Limitation } = result.data;
          setWeekDays(Limitation);
          setInitialValues({ submissionLimit: Limitation });
        });
    };
    getWorkingDays();
  }, [productName]);

  const handleDayChange = (index, value) => {
    formik.setFieldValue(`submissionLimit.${index}.isDirty`, true);
    let changedDay = formik.values.submissionLimit[index];
    if (value === false) {
      formik.setFieldValue(`submissionLimit.${index}.submissions_limit`, null);
      setWeekDays(
        weekDays.map((day) => {
          if (changedDay.day === day.day) return { ...day, disabled: true };
          else return day;
        })
      );
    } else {
      setWeekDays(
        weekDays.map((day) => {
          if (changedDay.day === day.day) return { ...day, disabled: false };
          else return day;
        })
      );
    }
  };
  const submitSubmissionLimit = () => {
    Swal.fire({
      title: "Are you sure you want to set submission limit?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(function (result) {
      if (result.isConfirmed) {
        client
          .post(
            "SubmissionsLimit/saveSubmissionLimit",
            formik.values.submissionLimit.filter(
              (value) =>
                value.hasOwnProperty("isDirty") && value.isDirty === true
            )
          )
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: "Settings submitted successfully",
              icon: "success",
              timer: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            handleError(error);
          });
      }
    });
  };
  return (
    <FormikProvider value={formik}>
      <Card style={{ borderRadius: "0 20px 20px 20px" }}>
        <CardContent className="pb-1 font-weight-bold">
          <h6 className="mb-4 ml-3 mt-3">
            <b>
              <Subtitles /> Maximum Submissions Per Day
            </b>
          </h6>
          <Grid container className="ml-3 mb-3">
            <Grid item md={3}>
              <h6
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "18px",
                  lineHeight: "24px",
                }}
              >
                Select work days:
              </h6>
            </Grid>
            <Grid item md={7}>
              <h6
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "18px",
                  lineHeight: "24px",
                }}
              >
                Submissions Limit Per Day:
              </h6>
            </Grid>
            <Grid item md={12}>
              <List className="pt-1 ml-1">
                {weekDays.map((dayRow, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item md={12}>
                        <ListItem className="pl-0 pt-0">
                          <Grid item container md={3}>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              className="p-0"
                              checked={
                                eval(
                                  getValue(
                                    formik.values,
                                    `submissionLimit.${index}.checked`
                                  )
                                ) || false
                              }
                              onBlur={(e) => {
                                formik.handleBlur(e);
                              }}
                              name={`submissionLimit.${index}.checked`}
                              onChange={(ev, value) => {
                                formik.handleChange(ev, value);
                                handleDayChange(index, value);
                              }}
                              style={{
                                color: "#181E4B",
                                transform: "scale(.7)",
                              }}
                            />
                            <ListItemText
                              primary={
                                <b
                                  className={
                                    eval(
                                      getValue(
                                        formik.values,
                                        `submissionLimit.${index}.checked`
                                      )
                                    )
                                      ? "checked"
                                      : "unChecked"
                                  }
                                  style={{ fontSize: "18px" }}
                                >
                                  {dayRow.day}
                                </b>
                              }
                            />
                          </Grid>
                          <Grid item container md={8}>
                            <Grid item md={5}>
                              <span
                                htmlFor="from"
                                className="mr-2 "
                                style={{
                                  fontFamily: "Montserrat",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  color: "#D1D1D1",
                                }}
                              >
                                Limitation
                              </span>
                              <TextField
                                type="number"
                                placeholder="limit"
                                name={`submissionLimit.${index}.submissions_limit`}
                                value={
                                  getValue(
                                    formik.values,
                                    `submissionLimit.${index}.submissions_limit`
                                  ) == null
                                    ? ""
                                    : getValue(
                                        formik.values,
                                        `submissionLimit.${index}.submissions_limit`
                                      )
                                }
                                disabled={
                                  getValue(
                                    formik.values,
                                    `submissionLimit.${index}.checked`
                                  ) === "false" || dayRow.disabled
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                }}
                                onChange={(ev, value) => {
                                  formik.handleChange(ev, value);
                                  handleDayChange(index, value);
                                }}
                                style={{ width: "133px" }}
                                size="small"
                                className="mb-0"
                                variant="outlined"
                              ></TextField>

                              {getValue(
                                formik.touched,
                                `submissionLimit.${index}.submissions_limit`
                              ) && (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "14px" }}
                                >
                                  {getValue(
                                    formik.errors,
                                    `submissionLimit.${index}.submissions_limit`
                                  )}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </ListItem>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
            </Grid>
            <Grid item md={8}></Grid>
            <Grid item md={12} className="mt-3 mb-3">
              <Button
                className="mx-1 px-5 py-2"
                size="large"
                color="primary"
                variant="contained"
                style={{ borderRadius: "5px" }}
                onClick={(e) => {
                  formik.handleSubmit(e);
                }}
                type="submit"
                disabled={
                  companyLimit === undefined || companyLimit === 0
                    ? true
                    : false
                }
              >
                <span className="btn-wrapper--label ml-2 font-size">
                  Submit
                </span>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormikProvider>
  );
};

export default SubmissionLimit;
