const config = (mapper) => {
  return {
    Distributor: {
      // constraints: {
      // display: {
      //   conditions: [
      //     // {
      //     //   checkValue: [2, 18, 21, 25, 26, 27, 36, 5, 11, 24, 6, 22, 38, 39],
      //     //   operator: "reverse includes",
      //     //   functionName: "applyArrayMethods",
      //     //   actualValue: mapper.transition_id,
      //     // },
      //     // {
      //     //   key: "name",
      //     //   checkValue: "Distributor",
      //     //   operator: "some",
      //     //   functionName: "applyArrayMethods",
      //     //   actualValue: mapper.user.roles,
      //     // },
      //   ],
      //   // conditionsEquation: "$0 || $1",
      // },
      // },
    },
    Manager: {},
    Reviewer: {},
    "Team Leader": {},
    "Inspection Manager": {},
    "Junior Inspector": {},
  };
};
export default config;
