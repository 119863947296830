import DocumentContent from "pages/EdaDetails/components/DocumentContent";
import Archive from "pages/EdaDetails/components/Archive";
import SectionContent from "pages/EdaDetails/components/SectionContent";
import PocaTest from "pages/EdaDetails/components/PocaTest";
import Modification from "pages/EdaDetails/components/Modifications";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
const config = (mapper) => {
  return {
    actions: [
      {
        gridSize: 12,
        label: "Save",
        icon: InsertDriveFileOutlinedIcon,
        classes: "save-btn mr-4 float-right",
        onClick: {
          functionName: "handleSubmit",
        },
        tabs: [1, 2, 3, 4, 6, 7, 8],
        constraints: {
          display: {
            conditions: [
              //$0
              {
                key: "name",
                checkValue: "User",
                operator: "some",
                functionName: "applyArrayMethods",
                actualValue: mapper.currentUser.roles,
              },
              //$1

              {
                checkValue: true,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task.step.edit_form,
              },
              //$2

              {
                checkValue: true,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task.is_assigned,
              },
              //$3

              {
                key: "id",
                checkValue: mapper.currentUser?.id,
                operator: "find",
                functionName: "applyArrayMethods",
                actualValue: mapper.taskUsers,
              },
              //$4

              {
                key: "name",
                checkValue: ["Admin"],
                operator: "compare2Arrays",
                functionName: "applyArrayMethods",
                actualValue: mapper.currentUser.roles,
              },
            ],
            conditionsEquation: "($0 && $1 && $2 && $3)||($2 && $4 && $3)",
          },
        },
      },
    ],
    tabs: [
      {
        title: "Event Details",
        tabContent: ["Attributes"],
        underRevision: false,
        component: SectionContent,
        id: 1,
      },
      {
        title: "Add Engagement",
        type: "ConditionalForm",
        underRevision: true,
        tabContent: ["Documents"],
        id: 2,
        component: Modification,
      },
      {
        title: "Event Archive",
        type: "ConditionalForm",
        tabContent: ["Documents"],
        underRevision: true,
        userPermission: ["Financial", "Admin"],
        id: 3,
        component: Archive,
      },
    ],
  };
};

export { config as detailsTabsConfig };
