import { createReducer } from "@reduxjs/toolkit";
import {
  GET_TASK_DETAILS_PENDING,
  GET_TASK_DETAILS_FULFILLED,
  GET_TASK_DETAILS_REJECTED,
  GET_TIMELINE_PENDING,
  GET_TIMELINE_FULFILLED,
  GET_TIMELINE_REJECTED,
  SAVE_CARD_ATTACHMENTS,
  GET_TASK_USERS_PENDING,
  GET_TASK_USERS_FULFILLED,
  GET_TASK_USERS_REJECTED,
  HANDLE_TASK_ASSIGNMENT_PENDING,
  HANDLE_TASK_ASSIGNMENT_FULFILLED,
  HANDLE_TASK_ASSIGNMENT_REJECTED,
  SET_ALL_ATTACHMENTS,
  SET_ATTACHMENT_REVIEWED,
  GET_AVAILABLE_SLOTS_PENDING,
  GET_AVAILABLE_SLOTS_FULFILLED,
  GET_AVAILABLE_SLOTS_REJECTED,
  RESERVE_SLOT_PENDING,
  RESERVE_SLOT_FULFILLED,
  RESERVE_SLOT_REJECTED,
  SUBMIT_TASK_PENDING,
  SUBMIT_TASK_FULFILLED,
  SUBMIT_TASK_REJECTED,
  UPLOAD_ATTACHMENTS,
  SEND_REQUEST_TO_MANAGER,
  SAVE_SUBMISSION_VALUES_PENDING,
  SAVE_SUBMISSION_VALUES_FULFILLED,
  SAVE_SUBMISSION_VALUES_REJECTED,
  GET_ATTACHMENTS_PENDING,
  GET_ATTACHMENTS_FULFILLED,
  GET_ATTACHMENTS_REJECTED,
  SAVE_NAMING_FULFILLED,
  SET_TASK_DETAILS,
} from "./detailsActionTypes";
import _ from "lodash";
import sanitizeObject from "../../utils/SanitizeObject";
const initialState = {
  error: null,
  task: null,
  loading: false,
  taskLoading: false,
  attachments: [],
  success: null,
  timelineTasks: [],
  commentTasks: [],
  users: [],
  allAttachments: [],
  uploadedAttachments: {},
  attachmentsUnderReview: [],
  availableSlots: [],
  slotsLoader: false,
  saveSlotSuccess: false,
  sendRequestSlot: null,
  saveSubmissionsValuesLoader: false,
  attachmentsLoader: false,
  databaseReviewedAttachment: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(GET_TASK_DETAILS_PENDING, (state, action) => {
    state.taskLoading = true;
    state.success = false;
  });
  builder.addCase(GET_TASK_DETAILS_FULFILLED, (state, action) => {
    state.task = sanitizeObject(action.payload.data.task);
    state.success = true;
    state.taskLoading = false;
  });
  builder.addCase(SAVE_NAMING_FULFILLED, (state, action) => {
    state.task.submission = {
      ...state.task.submission,
      attachments: state.allAttachments,
      ...action.payload.data,
    };
  });
  builder.addCase(GET_TASK_DETAILS_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.taskLoading = false;
  });

  builder.addCase(SUBMIT_TASK_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(SUBMIT_TASK_FULFILLED, (state, action) => {
    state.task = action.payload.data.task;
    state.success = true;
  });
  builder.addCase(SUBMIT_TASK_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.loading = false;
    state.success = false;
  });

  builder.addCase(GET_TIMELINE_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(GET_TIMELINE_FULFILLED, (state, action) => {
    state.timelineTasks = action.payload.data.timelineTasks;
    state.commentTasks = action.payload.data.commentTasks;

    state.loading = false;
    state.success = true;
  });
  builder.addCase(GET_TIMELINE_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.loading = false;
  });

  builder.addCase(SAVE_CARD_ATTACHMENTS, (state, action) => {
    state.attachments = !_.isEmpty(action.payload.attachment)
      ? [
          ...state.attachments.filter(
            (attach) => action.payload.attachment.id !== attach.id
          ),
          action.payload.attachment,
        ]
      : [...state.attachments];
  });
  //get users
  builder.addCase(GET_TASK_USERS_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(GET_TASK_USERS_FULFILLED, (state, action) => {
    state.users = action.payload.data;
    state.success = true;
    state.loading = false;
  });
  builder.addCase(GET_TASK_USERS_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.loading = false;
  });
  //handle task  assignment
  builder.addCase(HANDLE_TASK_ASSIGNMENT_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(HANDLE_TASK_ASSIGNMENT_FULFILLED, (state, action) => {
    state.success = true;
    state.loading = false;
  });
  builder.addCase(HANDLE_TASK_ASSIGNMENT_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.loading = false;
  });

  builder.addCase(SET_ALL_ATTACHMENTS, (state, action) => {
    state.success = true;
    state.allAttachments = [...action.payload];
  });
  builder.addCase(SET_ATTACHMENT_REVIEWED, (state, action) => {
    state.success = true;
    state.attachmentsUnderReview = [...action.payload];
  });

  builder.addCase(GET_AVAILABLE_SLOTS_PENDING, (state, action) => {
    state.slotsLoader = true;
  });
  builder.addCase(GET_AVAILABLE_SLOTS_FULFILLED, (state, action) => {
    state.availableSlots = action.payload.data;
    state.slotsLoader = false;
  });
  builder.addCase(GET_AVAILABLE_SLOTS_REJECTED, (state, action) => {
    state.slotsLoader = false;
  });

  builder.addCase(RESERVE_SLOT_PENDING, (state, action) => {
    state.slotsLoader = true;
    state.saveSlotSuccess = false;
  });
  builder.addCase(RESERVE_SLOT_FULFILLED, (state, action) => {
    state.availableSlots = [];
    state.saveSlotSuccess = true;
    state.slotsLoader = false;
  });
  builder.addCase(SEND_REQUEST_TO_MANAGER, (state, action) => {
    state.sendRequestSlot = true;
  });
  builder.addCase(UPLOAD_ATTACHMENTS, (state, action) => {
    state.success = true;
    state.uploadedAttachments = {
      ...state.uploadedAttachments,
      ...action.payload,
    };
  });
  builder.addCase(RESERVE_SLOT_REJECTED, (state, action) => {
    state.slotsLoader = false;
  });

  builder.addCase(SAVE_SUBMISSION_VALUES_PENDING, (state, action) => {
    state.saveSubmissionsValuesLoader = true;
  });
  builder.addCase(SAVE_SUBMISSION_VALUES_FULFILLED, (state, action) => {
    state.saveSubmissionsValuesLoader = false;
  });
  builder.addCase(SAVE_SUBMISSION_VALUES_REJECTED, (state, action) => {
    state.saveSubmissionsValuesLoader = false;
  });

  builder.addCase(GET_ATTACHMENTS_PENDING, (state, action) => {
    state.attachmentsLoader = true;
  });
  builder.addCase(GET_ATTACHMENTS_FULFILLED, (state, action) => {
    state.attachmentsLoader = false;
    state.databaseReviewedAttachment = action.payload.data.attachments;
  });
  builder.addCase(GET_ATTACHMENTS_REJECTED, (state, action) => {
    state.attachmentsLoader = false;
  });
  builder.addCase(SET_TASK_DETAILS, (state, action) => {
    state.task = { ...action.payload };
  });
});
export default reducer;
