import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogTitle,
  FormControl,
  TextareaAutosize,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as customComponent from "customComponent/index";
import { GetStepContent } from "components/FormSteps/getStepContent";
import client from "api/apiAuth/guestClient";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { setSchema } from "utils/setValidationSchema";
import _ from "lodash";
import { enableSubmitButton } from "reducers/form/formActions";
import { getTaskDetails } from "reducers/details/detailsAction";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getValue } from "utils/GetObjectValues";

const StepInputsModal = ({
  open,
  setOpen,
  section,
  submission,
  initialValues,
  task,
  setInputflag,
  enableSubmitButton,
  getTaskDetails,
  newTask,
}) => {
  const [loading, setLoading] = useState(false);
  const [sectionNameFlags, setSectionNameFlags] = useState({});
  const [errorMessage, setErrorMesssage] = useState("");
  const [comment, setComment] = useState("");
  const params = useParams();
  const formik = useFormik({
    validationSchema: Yup.object().shape(setSchema(section.inputs)),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitTask();
    },
  });
  let history = useHistory();
  useEffect(() => {
    setErrorMesssage("");
  }, [formik.values]);
  useEffect(() => {
    if (!_.isEmpty(formik.errors)) {
      setInputflag(false);
    }
  }, [formik.errors]);
  const checkAllApprover = async (formikValues) => {
    // getTaskDetails(params.id);
    let { data } = await client.post("generic/find", {
      where: { id: params.taskId },
      model: "Task",
      include: [
        {
          association: "submission",
          include: [{ association: "attachments" }],
        },
        {
          association: "submissionModification",
        },
      ],
    });
    let { values, status } = data.rows[0].submission;
    let { submissionModification } = data.rows[0];
    let first_approver, second_approver;

    if (values && status === "Pending") {
      first_approver =
        getValue(values, "first_approver_decision") ||
        getValue(formikValues, "first_approver_decision");
      second_approver =
        getValue(values, "second_approver_decision") ||
        getValue(formikValues, "second_approver_decision");
    }
    if (submissionModification && status === "Modification") {
      first_approver =
        getValue(
          submissionModification.values,
          "first_approver_decision_modification"
        ) || getValue(formikValues, "first_approver_decision_modification");
      second_approver =
        getValue(
          submissionModification.values,
          "second_approver_decision_modification"
        ) || getValue(formikValues, "second_approver_decision_modification");
    }
    if (formikValues) {
      formik.values = {
        ...formikValues,
        [`${Object.keys(formikValues)[0]}_date`]: new Date().toLocaleString(),
      };
    }
    return first_approver !== undefined && second_approver !== undefined
      ? true
      : false;
  };
  const handleSubmitTask = async () => {
    try {
      if (!_.isEmpty(formik.values)) {
        setErrorMesssage("");
        setLoading(true);
        let submitFlag = await checkAllApprover(formik.values);

        let rules = [];
        task.step.groups.forEach((group) => {
          rules.push(group.rules);
        });
        rules = rules.flat();
        await client.post("submission/updateSubmissionValues", {
          submission_id: submission.id,
          section_id: section.id,
          rules: rules,
          task_id: task.id,
          submission_edit: formik.values,
          submitFlag: submitFlag,
          comment: comment,
        });
        setInputflag(true);
        // getTaskDetails(params.id);
        history.push("/");

        setLoading(false);
        setOpen(false);
      } else {
        setErrorMesssage("Please fill data before submit");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const renderCustomComponents = (componentName, task, section) => {
    const Component = customComponent[componentName];
    return (
      <div>
        {Component ? (
          <Component
            task={task}
            section={section}
            formik={formik}
            loadMore={true}
          />
        ) : (
          ""
        )}
      </div>
    );
  };
  const handleSetComment = (e) => {
    setComment(e.target.value);
  };
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
        setInputflag(false);
      }}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className="titleModal">{section.name}</span>
      </DialogTitle>
      <DialogContent>
        {"type" in section.style && section.style.type === "component" ? (
          renderCustomComponents(section.style.componentName, task, section)
        ) : (
          <>
            <Grid style={{ padding: "0px", justifyContent: "flex-start" }}>
              {GetStepContent({
                formik,
                activeStep: 0,
                steps: [section],
                sectionNameFlags,
                submission,
                setSectionNameFlags,
              })}
            </Grid>
            {errorMessage && (
              <div className="text-danger ml-3 mb-2">
                <small>{errorMessage}</small>
              </div>
            )}
            <span className="heading-4 d-block mt-4 comment">
              Write Your Comment
            </span>
            <FormControl>
              <TextareaAutosize
                value={comment}
                onChange={handleSetComment}
                minRows={"5"}
                cols={"125"}
                className="txt-area"
              />
            </FormControl>
            <Grid
              style={{
                display: "flex",
                alignContent: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "5%",
              }}
            >
              <Button
                className="mx-1 px-5 py-2"
                size="large"
                color="primary"
                variant="contained"
                style={{ borderRadius: "5px" }}
                onClick={(e) => {
                  enableSubmitButton();
                  formik.handleSubmit(e);
                }}
                disabled={
                  (!_.isEmpty(formik.values) &&
                    section.inputs.some((e) => !e.disabled) &&
                    !(formik.dirty && formik.isValid)) ||
                  loading
                }
              >
                <span className="btn-wrapper--label ml-2 font-size">
                  Confirm
                </span>
                {loading && (
                  <ClipLoader color={"var(--primary)"} loading={true} />
                )}
              </Button>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    newTask: state.details.task,
    currentUser: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    enableSubmitButton: () => dispatch(enableSubmitButton()),
    getTaskDetails: (submission_id) =>
      dispatch(getTaskDetails({ submission_id, task_status: "Pending" })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepInputsModal);
