import React, { useEffect, useRef, useState } from "react";
import { GetApp, Close, Check, CloseTwoTone, Save } from "@material-ui/icons/";
import { Button, Card, Grid, ListItem } from "@material-ui/core";
import axios from "axios";
import client from "api/apiAuth/guestClient";
import Alert from "@material-ui/lab/Alert";
import { useDropzone } from "react-dropzone";
import { handleError } from "api/utilities";
import { useParams } from "react-router";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { getTaskDetails } from "reducers/details/detailsAction";
const FilesUpload = (props) => {
  let {
    doctorAttachments,
    doctorName,
    userPermission,
    getTaskDetails,
    currentUser,
    task,
  } = props;
  const [files, setFiles] = useState([]);
  const [currentUploadedFiles, setCurrentUploadedFiles] = useState([]);
  const [rejectedFileErrorMessage, setRejectedFileErrorMessage] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const saveFiles = async (event) => {
    event.persist();
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const url = `attachment/uploadMultipleFiles?documentName=&submission_id=${params.id}&doctor_name=${doctorName}&dr_submission_id=${doctorAttachments[0].dr_submission_id}`;

    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
    }
    setLoading(true);

    client
      .post(url, formData, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgress(progress);
        },
      })
      .then((result) => {
        if (result.status === 200) {
          getTaskDetails(params.id);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/*,audio/*,video/*,.pdf",
    maxSize: 10000000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      acceptedFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      setCurrentUploadedFiles([...acceptedFiles]);
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          let errorMessage;
          if (err.code === "file-too-large") {
            errorMessage = "file is too large";
          }

          if (err.code === "file-invalid-type") {
            errorMessage = "please check files extensions";
          }
          setRejectedFileErrorMessage([
            ...rejectedFileErrorMessage,
            { fileName: file.file.name, message: errorMessage },
          ]);
        });
      });
    },
  });
  const removeFile = async (file) => {
    try {
      setFiles(
        files.filter((value) =>
          value.preview
            ? file.preview !== value.preview
            : file.preview !== value.metadata.preview
        )
      );
    } catch (error) {
      handleError(error);
    }
  };
  let uploadedAttachments = files || [];
  const thumbs = uploadedAttachments.map((file, index) => {
    return file?.type?.includes("image") ? (
      <Grid item md={3} className="p-2" key={file.name + index}>
        <div className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden rounded-sm">
          <Close className="remove-img" onClick={() => removeFile(file)} />
          <img
            className="img-fluid img-fit-container rounded-sm"
            src={file?.pdfUrl ? file.pdfUrl : URL.createObjectURL(file)}
            alt={file.name}
          />
        </div>
      </Grid>
    ) : (
      file && (
        <ListItem
          className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
          key={file.name + index}
        >
          <span className="file-name">{file.name}</span>{" "}
          {file.size && (
            <span className="badge badge-pill bg-neutral-warning text-warning ">
              {file.size} bytes
            </span>
          )}
          <Close
            className="mr-1 remove-file"
            onClick={() => removeFile(file)}
          />
        </ListItem>
      )
    );
  });
  useEffect(() => {
    currentUploadedFiles.forEach((file, index) => {});
    setFiles([...files, ...currentUploadedFiles]);
  }, [currentUploadedFiles]);
  return (
    <Grid container>
      {(currentUser.roles.some((r) => userPermission.includes(r.name)) ||
        task.submission.user.id === currentUser.id) && (
        <Grid item sm={8} md={8}>
          <div>
            <div className="p-2">
              <div
                {...getRootProps({ className: "dropzone-upload-wrapper" })}
                className="p-0"
              >
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                  {isDragAccept && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg">
                        <Check className="d-50" />
                      </div>
                      <div className="font-size-xs text-success">
                        We're ready to start!
                      </div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg">
                        <CloseTwoTone className="d-50" />
                      </div>
                      <div className="font-size-xs text-danger">
                        These files are not images!
                      </div>
                    </div>
                  )}
                  {!isDragActive && (
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="btn-transition btn-md btn-outlined multi-upload-btn"
                        startIcon={<GetApp />}
                        disableFocusRipple
                        disableRipple
                      >
                        Upload Files
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {thumbs?.length > 0 && (
            <div className="card-footer p-3 ">
              <div>
                <div className="font-weight-bold mb-3 text-uppercase text-dark font-size-sm text-center">
                  Uploaded Files
                </div>
                <div>
                  <Alert severity="success" className="text-center mb-3">
                    You have uploaded <b>{thumbs?.length}</b> files!
                  </Alert>
                  <Grid container spacing={0}>
                    {thumbs}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </Grid>
      )}
      <Grid item sm={8} md={8}>
        {files.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "50%",
              }}
              startIcon={<Save />}
              onClick={saveFiles}
              disabled={loading}
            >
              Save
              {loading && (
                <ClipLoader color={"var(--primary)"} loading={true} />
              )}
            </Button>
          </div>
        )}
      </Grid>
      <div>
        {rejectedFileErrorMessage.length > 0 &&
          rejectedFileErrorMessage.map((file, index) => {
            return (
              <ListItem
                className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
                key={file.fileName + index}
              >
                <span className="text-danger">{file.message}</span>
                <span className="file-name text-danger">{file.fileName}</span>
              </ListItem>
            );
          })}
      </div>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    task: state.details.task,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTaskDetails: (submission_id) =>
      dispatch(getTaskDetails({ submission_id, task_status: "Pending" })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilesUpload);
