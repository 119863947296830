import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { GetStepContent } from "components/FormSteps/getStepContent";

const SingleSectionRenderer = (props) => {
  const [sectionNameFlags, setSectionNameFlags] = useState({});

  const stepContentConfig = {
    formik: props.formik,
    activeStep: 0,
    steps: [props.section],
    sectionNameFlags,
    setSectionNameFlags,
  };

  if ("submission" in props) stepContentConfig.submission = props.submission;

  return (
    <Grid style={{ padding: "0px", justifyContent: "flex-start" }}>
      {GetStepContent(stepContentConfig)}
    </Grid>
  );
};

export default SingleSectionRenderer;
