import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableContainer,
  Paper,
  Card,
  Button,
} from "@material-ui/core";

import EditEntityModal from "./Modals/Create_Edit/EditEntityModal";
import CreateEntityModal from "./Modals/Create_Edit/EditEntityModal";
import {
  fetchUserData,
  fetchEntityDataAux1,
  fetchEntityDataAux2,
  fetchEntityDataAux3,
  fetchModelData,
} from "reducers/list";
import PreviewSignature from "./previewSignatureModal";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { getValue } from "utils/GetObjectValues";
import { fetchFilterData, fetchWorklistLayout } from "reducers/list";

import client from "api/apiAuth/guestClient";
import SearchBar from "./SearchBar";
import Footer from "./Table/Footer";
import Body from "./Table/Body";
import Header from "./Table/Header";
import { findUsersOrgs, findUsersRoles } from "./Utils/queries";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import newUser from "../../../assets/images/new_user-2.svg";
import newEntity from "../../../assets/images/general_add.svg";
import { deleteEntity } from "./Modals/Delete/DeleteUserAction";
const AdminControlPanel = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [entities, setEntities] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [signatureUser, setSignatureUser] = useState(null);
  const [signature, setSignature] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [editEntity, setEditEntity] = useState(null);
  // const listData = useSelector((state) => state.layout?.columns)
  const params = useParams();
  const entity = params.entity;
  //redux-toolkit convention
  const dispatch = useDispatch();
  const userModelData = useSelector((state) => state.data.User);
  const modelData = useSelector((state) => state.data.Model);
  const entityData = entity === "users" ? userModelData : modelData;
  const entityDataAux1 = useSelector((state) => state.data.EntityDataAux1);
  const entityDataAux2 = useSelector((state) => state.data.EntityDataAux2);
  const entityDataAux3 = useSelector((state) => state.data.EntityDataAux3);
  const listData = useSelector((state) => state.layout?.columns);
  const model = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )?.flatMap((a) => a[entity]?.modelName)[0];
  const createInstance = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )?.flatMap((a) => a[entity]?.createInstance)[0];
  const modelQuery = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )?.flatMap((a) => a[entity]?.modelQuery)[0];
  const entityDataAux1Query = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )?.flatMap((a) => a[entity]?.entityDataAux1)[0];
  const entityDataAux2Query = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )?.flatMap((a) => a[entity]?.entityDataAux2)[0];
  const entityDataAux3Query = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )?.flatMap((a) => a[entity]?.entityDataAux3)[0];
  const auxDataKeys = useSelector((state) =>
    state.layout?.columns?.Admin?.filter((A) => A[entity])
  )
    ?.flatMap((a) => a?.[entity]?.Columns)
    .filter((dk) => dk.data)
    .map((k) => k?.key);
  const createIcon = entity === "users" ? newUser : newEntity;
  useEffect(() => {
    // dispatch(fetchFilterData({ table: "adminPanel" }))
    dispatch(fetchWorklistLayout({ table: "adminPanel" }));
    if (entity === "users") {
      // dispatch reducers
      dispatch(fetchUserData());
    } else if (modelQuery) {
      dispatch(fetchModelData(modelQuery));
    }
    if (entityDataAux1Query) dispatch(fetchEntityDataAux1(entityDataAux1Query));
    if (entityDataAux2Query) dispatch(fetchEntityDataAux2(entityDataAux2Query));
    if (entityDataAux3Query) dispatch(fetchEntityDataAux3(entityDataAux3Query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);
  useEffect(() => {
    if (entityData) {
      setEntities(entityData.sort((a, b) => a.id - b.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityData]);
  // handle user edit
  const handleEditEntity = (index) => {
    setEditEntity({ index, [entity]: entities[index] });
    setEditModalOpen(true);
  };
  const handleCreateEntity = (index) => {
    setEditEntity(null);
    setCreateModalOpen(true);
  };

  const onEntityCreated = (an_entity, id) => {
    const newEntities = [...entities];
    newEntities.push({ ...an_entity, id });
    setEntities(newEntities);
  };
  const onEntityEdited = (index, an_entity) => {
    const newEntities = [...entities];
    newEntities[index] = { ...an_entity };
    setEntities(newEntities);
    setEditEntity(null);
  };

  // Delete User handlers
  const onEntityDeleted = (id) => {
    const newEntities = [...entities].filter((entity) => entity.id !== id);
    setEntities(newEntities);
  };
  const DeleteEntity = (index) => {
    dispatch(deleteEntity(index, entities, entity, model, onEntityDeleted));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewSignature = (index, name, key) => {
    setSignatureUser({ index, entity: entities[index] });
    setSignature({ name, key });
    setModalPreviewOpen(true);
  };

  // const handleSearch = (value) => {
  //   let filterObject = [];
  //   filterLayout = filterLayout.flat();
  //   let searchfield = filterLayout.filter((item) => item.search);
  //   if (value) {
  //     let values = searchfield.map((field) => {
  //       return {
  //         field: field.key,
  //          operator: field.operator,
  //         value: `%${value}%`,
  //         json: field.json,
  //       };
  //     });
  //     filterObject = [
  //       {
  //         operator: "and",
  //         value: values,
  //       },
  //     ];
  //   }
  //   setSearchData({ filters: filterObject });
  // };

  const handleSearch = (value) => {
    let fieldName = entity
      ? listData?.Admin?.filter((hd) => hd[entity]).flatMap((header) =>
          header[entity]?.Columns?.filter((head) => head.searchField === true)
        )[0].key
      : "name";
    let filterObject = [];
    if (value) {
      filterObject = [
        {
          operator: "and",
          value: [
            {
              field: `${model}.${fieldName}`,
              value: `%${value}%`,
              operator: "like",
            },
          ],
        },
      ];
    }
    setSearchData({
      filters: filterObject,
    });
  };

  const applySearch = async (event) => {
    event.preventDefault();
    if (searchData?.filters?.length > 0) {
      client
        .post("/user/findUser", {
          ...searchData,
          model: model,
        })
        .then((res) => {
          if (res.data.responseData) {
            setEntities([...res.data.responseData]);
            setPage(0);
          } else {
            setEntities([]);
          }
        });
    } else {
      setEntities(entityData);
    }
  };
  const exportToExcel = (e, data, exportedSheetName, columns) => {
    Swal.fire({
      title: `Do you want to export table to Excel`,
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        let exportedData = [];
        exportedData.push(
          ...data.map((row) => {
            let filteredSubmission = {};
            if (columns.length > 0) {
              for (const col of columns) {
                filteredSubmission[col.name] = getValue(row, col.key);
              }
              return filteredSubmission;
            } else {
              return row;
            }
          })
        );
        const workSheet = XLSX.utils.json_to_sheet(exportedData);

        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "submissions");

        XLSX.write(workBook, { bookType: "xls", type: "buffer" });
        XLSX.write(workBook, { bookType: "xls", type: "binary" });
        XLSX.writeFile(workBook, `${exportedSheetName}.xls`);
      }
    });
  };
  return (
    <>
      <Card
        style={{
          borderRadius: "0 20px 20px 20px",
          boxShadow: "none",
          border: "none",
        }}
      >
        <SearchBar
          handleSearch={handleSearch}
          applySearch={applySearch}
          fieldName={
            listData?.Admin?.filter((hd) => hd[entity]).flatMap((header) =>
              header[entity]?.Columns?.filter(
                (head) => head.searchField === true
              )
            )[0].key
          }
        />
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: "flex-end", marginBottom: "5px" }}
        >
          <Button
            id="exportToExcel"
            onClick={(e) =>
              exportToExcel(
                e,
                entities,
                model,
                listData?.Admin?.find((hd) => hd[entity])[entity].Columns
              )
            }
            variant="contained"
            color="primary"
          >
            Export To Excel
          </Button>
        </div>
        <div style={{ borderRadius: "20px", paddingTop: "20px" }}>
          <Grid className="d-md-flex justify-content-md-end m-0">
            {createInstance && (
              <Button
                onClick={handleCreateEntity}
                size="medium"
                variant="contained"
                color="secondary"
                className="mx-3  rounded"
              >
                {/* 
              <img
                  alt="..."
                  align="center"
                  style={{ width: "30px"}}
                  src={ createIcon }
                  /> */}
                <span>
                  <b style={{ fontSize: "16px" }}>
                    {" "}
                    &nbsp; Create New {model}{" "}
                  </b>
                </span>
              </Button>
            )}
          </Grid>
          <Grid item md={12} lg={12} xs={12} className="mt-3 ml-3 mr-3">
            <TableContainer component={Paper}>
              <Table className="table text-nowrap mb-0 tabelStyle">
                <Header entity={entity} />
                <Body
                  entities={entities}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  viewSignature={viewSignature}
                  handleEditEntity={handleEditEntity}
                  DeleteEntity={DeleteEntity}
                  entity={entity}
                />
                <Footer
                  entities={entities}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Table>
            </TableContainer>
            <PreviewSignature
              open={modalPreviewOpen}
              setOpen={setModalPreviewOpen}
              data={signatureUser}
              signatures={signature}
            />
            <EditEntityModal
              open={editModalOpen}
              setModalOpen={setEditModalOpen}
              entityDataAux1={entityDataAux1}
              entityDataAux2={entityDataAux2}
              entityDataAux3={entityDataAux3}
              auxDataKeys={auxDataKeys}
              isEdit={true}
              entity={editEntity ? editEntity[entity] : null}
              entityIndex={editEntity?.index}
              onEntityEdited={onEntityEdited}
              setEditEntity={setEditEntity}
              model={model}
              entityName={entity}
              entities={entities}
            />
            <CreateEntityModal
              open={createModalOpen}
              setModalOpen={setCreateModalOpen}
              entityDataAux1={entityDataAux1}
              entityDataAux2={entityDataAux2}
              entityDataAux3={entityDataAux3}
              auxDataKeys={auxDataKeys}
              isEdit={false}
              onEntityCreated={onEntityCreated}
              model={model}
              entityName={entity}
              entities={entities}
            />
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default AdminControlPanel;
