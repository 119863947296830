import { useMemo, useState } from "react";
import {
  Checkbox,
  ListItem,
  Grid,
  ListItemText,
  List,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { getValue } from "utils/GetObjectValues";

const days = [
  "Saturday",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];
const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function SubmissionDate({ data, formik }) {
  const handleChange = (day, ev) => {
    if (ev.target.value == false)
      formik.setFieldValue(`submissionLimit.${day}.limit`, null);
  };
  const getContent = useMemo(() => {
    let content;
    let limitName;
    switch (data.period) {
      case "foreachDay":
        content = (
          <Grid item md={12}>
            <List className="pt-1 ml-1">
              {days.map((dayRow, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item md={12}>
                      <ListItem className="pl-0 pt-0">
                        <Grid item container md={3}>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            className="p-0"
                            checked={
                              eval(
                                getValue(
                                  formik.values,
                                  `submissionLimit.${dayRow}.checked`
                                )
                              ) || false
                            }
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            name={`submissionLimit.${dayRow}.checked`}
                            onChange={(ev, value) => {
                              formik.handleChange(ev, value);
                              handleChange(dayRow, ev);
                            }}
                            style={{
                              color: "#181E4B",
                              transform: "scale(.7)",
                            }}
                          />
                          <ListItemText
                            primary={
                              <b
                                className={
                                  eval(
                                    getValue(
                                      formik.values,
                                      `submissionLimit.${dayRow}.checked`
                                    )
                                  )
                                    ? "checked"
                                    : "unChecked"
                                }
                                style={{ fontSize: "18px" }}
                              >
                                {dayRow}
                              </b>
                            }
                          />
                        </Grid>
                        <Grid item container md={8}>
                          <Grid item md={5}>
                            <span
                              htmlFor="from"
                              className="mr-2 "
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#D1D1D1",
                              }}
                            >
                              Limitation
                            </span>
                            <TextField
                              type="number"
                              placeholder="limit"
                              name={`submissionLimit.${dayRow}.limit`}
                              value={
                                getValue(
                                  formik.values,
                                  `submissionLimit.${dayRow}.limit`
                                ) == null
                                  ? ""
                                  : getValue(
                                      formik.values,
                                      `submissionLimit.${dayRow}.limit`
                                    )
                              }
                              disabled={
                                getValue(
                                  formik.values,
                                  `submissionLimit.${dayRow}.checked`
                                ) === "false" ||
                                getValue(
                                  formik.values,
                                  `submissionLimit.${dayRow}.checked`
                                ) == undefined
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                              }}
                              onChange={(ev, value) => {
                                formik.handleChange(ev, value);
                              }}
                              style={{ width: "133px" }}
                              size="small"
                              className="mb-0"
                              variant="outlined"
                            ></TextField>

                            {getValue(
                              formik.touched,
                              `submissionLimit.${dayRow}.limit`
                            ) && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                {getValue(
                                  formik.errors,
                                  `submissionLimit.${dayRow}.limit`
                                )}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Grid>
                  </Grid>
                );
              })}
            </List>
          </Grid>
        );
        return content;
      case "daily":
        limitName = "submissionLimit";
        content = (
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="limit/day"
                  variant="outlined"
                  type={"number"}
                  name={limitName}
                  value={getValue(formik.values, limitName)}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        );
        return content;
      case "monthly":
        limitName = "submissionLimit";
        content = (
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="limit/month"
                  variant="outlined"
                  type={"number"}
                  name={limitName}
                  value={getValue(formik.values, limitName)}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        );
        return content;
      case "weekly":
        limitName = "submissionLimit";
        content = (
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="limit/week"
                  variant="outlined"
                  type={"number"}
                  name={limitName}
                  value={getValue(formik.values, limitName)}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        );
        return content;
      default:
        break;
    }
  }, [formik]);
  return <>{getContent}</>;
}
