/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import bioInLogo from "assets/images/bio-inn.png";
import edaLogo from "assets/images/Image 1.png";
import "./index.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import config from "../index.js";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const DistributionCPMP = ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  form,
  user,
  setCheckListFormFlag,
}) => {
  const [imageSources, setImageSources] = useState({});
  useEffect(() => {
    setCheckListFormFlag(false);
    setValues(config(letter, formValues, "checklistCPMP"));
  }, []);
  let [initialValues, setInitialValues] = useState({});

  const checkListSchema = {
    productName: Yup.string().required("Required"),
    productType: Yup.string().required("Required"),
    Manufacturer: Yup.string().required("Required"),
    batchNumber: Yup.string().required("Required"),
    unique_identifier: Yup.string().required("Required"),
    registration_number: Yup.string().required("Required"),
    No_distributed_samples: Yup.string().required("Required"),
  };
  const checkListForm = useFormik({
    validationSchema: Yup.object().shape(checkListSchema),
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    setCheckListFormFlag(checkListForm.dirty && checkListForm.isValid);
  }, [checkListForm]);
  function toDataURL(sources) {
    Object.entries(sources).forEach(([key, src]) => {
      let image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        setImageSources((oldState) => ({ ...oldState, [key]: dataURL }));
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src =
          "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }
    });
  }

  useEffect(() => {
    toDataURL({ edaLogo, bioInLogo });
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    checkListForm.handleChange(event);

    setValues({
      ...values,
      [name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
    if (_.isEmpty(checkListForm.errors) && !_.isEmpty(values)) {
      setCheckListFormFlag(true);
    } else {
      setCheckListFormFlag(false);
    }
  };
  useEffect(() => {
    const distributionCPMP = document.getElementById("distributionFormCPMP");
    const radioButtons = distributionCPMP?.querySelectorAll(
      'input[type="radio"]:checked'
    );
    radioButtons.forEach((input) => {
      input.setAttribute("checked", "checked");
    });
    setDivRef((divRefs) => ({
      ...divRefs,
      distributionFormCPMP: distributionCPMP,
    }));
  }, [values, formValues]);
  return (
    <div
      style={{
        padding: "20px",
        zoom: 0.88,
      }}
      id="distributionFormCPMP"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            CA of Biological and Innovative products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            and Clinical Studies
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            GA of Biological Product
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Lot Release Administration
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          {Object.values(imageSources)?.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              style={{ width: "150px", height: "100px" }}
              alt="bio-inn"
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هـيـئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الحيوية والمبتكرة
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            والدراسات الإكلينيكية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات الحيوية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            إدارة الافراج عن التشغيلات
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <td>
              <span
                style={{
                  fontSize: "14pt",
                  fontFamily: ["Arial", "sans-serif"],
                }}
              >
                Lot Release administration
              </span>
            </td>

            <td>
              <label
                htmlFor="scales"
                style={{
                  fontSize: "14pt",
                  fontFamily: ["Arial", "sans-serif"],
                }}
              >
                Unit:
              </label>
              <span
                style={{
                  fontSize: "14pt",
                  fontFamily: ["Arial", "sans-serif"],
                }}
              >
                complaints and post-marketing program samples
              </span>
            </td>
          </tr>
        </thead>
      </table>
      <div
        style={{
          marginTop: "10px",
          border: "3px solid 	#101010",
          padding: "10px",
        }}
      >
        <b style={{ color: "#101010" }}>
          Risk-based PMP samples distribution Form
        </b>
      </div>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <table style={{ width: "100%" }}>
          <tbody style={{ border: "none", borderCollapse: "collapse" }}>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Distribution Lab:</span>
              </td>
              <td className="table-text-style">
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="ChemicalLab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.ChemicalLab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Chemical Lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="BacterialLab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.BacterialLab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Bacterial Lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Bioassay_lab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Bioassay_lab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Bioassay Lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Viral_lab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Viral_lab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Viral lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Plasma_derivatives_lab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Plasma_derivatives_lab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Plasma derivatives lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Biotechnology_lab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Biotechnology_lab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Biotechnology lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="chromatography_separation_lab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.chromatography_separation_lab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">chromatography separation lab</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Extract_and_allergen_evaluation_lab"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Extract_and_allergen_evaluation_lab}
                  onChange={handleChange}
                />
                <label htmlFor="horns">
                  Extract and allergen evaluation lab
                </label>
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Product Name:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.productName}
                  name="productName"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Product Type:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.productType}
                  name="productType"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Manufacturer /Agent:</span>
              </td>
              <td className="table-text-style">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.Manufacturer}
                  name="Manufacturer"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Batch No.: </span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.batchNumber}
                  name="batchNumber"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Unique Identifier: </span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.unique_identifier}
                  name="unique_identifier"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Registration number:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.registration_number}
                  name="registration_number"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>
                  Sample distributed for testing:
                </span>
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>
                  No. of distributed samples:
                </span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.No_distributed_samples}
                  name="No_distributed_samples"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Container type:</span>
              </td>
              <td className="table-text-style">
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="vial"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.vial}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Vial</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="ampoule"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.ampoule}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Ampoule</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="prefilled_syringe"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.prefilled_syringe}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Prefilled Syringe</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Powder"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Powder}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Powder</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Bottle"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Bottle}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Bottle</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Powder_and_solvent"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Powder_and_solvent}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Powder and solvent</label>
                <input
                  key={Math.random()}
                  type="checkbox"
                  name="Other"
                  style={{
                    accentColor: "#DDD",
                    marginRight: "5px",
                    marginLeft: "5px",
                    height: "15px",
                    width: "15px",
                  }}
                  checked={values.Other}
                  onChange={handleChange}
                />
                <label htmlFor="horns">Other</label>
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Volume of container:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.Volume_of_container}
                  name="Volume_of_container"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>No. of doses:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.No_of_doses}
                  name="No_of_doses"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Tests to be done:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.Tests_to_be_done}
                  name="Tests_to_be_done"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td className="table-text-style" style={{ width: "60%" }}>
                    <span style={{ display: "block" }}>Remarks:</span>
                    <textarea
                      value={values.remarks}
                      name="remarks"
                      placeholder=".............................................................................................................."
                      rows="2"
                      style={{
                        minWidth: "90%",
                        minHeight: "30px",
                        marginTop: "5px",
                        border: "none",
                      }}
                      onChange={handleChange}
                    ></textarea>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                    >
                      Filled by the lab:
                    </span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <input
                      key={Math.random()}
                      type="checkbox"
                      name="capable"
                      style={{
                        accentColor: "#DDD",
                        marginRight: "5px",
                        marginLeft: "5px",
                        height: "15px",
                        width: "15px",
                      }}
                      checked={values.capable}
                      onChange={handleChange}
                    />
                    <label htmlFor="horns">Lab is capable for testing</label>
                  </td>
                  <td>
                    <input
                      key={Math.random()}
                      type="checkbox"
                      name="non_capable"
                      style={{
                        accentColor: "#DDD",
                        marginRight: "5px",
                        marginLeft: "100px",
                        height: "15px",
                        width: "15px",
                      }}
                      checked={values.non_capable}
                      onChange={handleChange}
                    />
                    <label htmlFor="horns">Lab is non- capable testing</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ marginTop: "20px" }}>Remarks:</span>
                    <input
                      value={values.remarks3}
                      name="remarks3"
                      placeholder=".................................................................."
                      style={{
                        width: "80%",
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: "500" }}>Received by:</span>
                    <input
                      value={values.received_by}
                      name="received_by"
                      placeholder="............................."
                      style={{
                        width: "77%",
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <span>Date: </span>
                    <span>{new Date().toLocaleString()}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: "500", display: "inline-flex" }}>
                      Serial number:
                    </span>
                    <input
                      value={values.serial_number}
                      name="serial_number"
                      placeholder="............................."
                      style={{
                        width: "73%",
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: "500" }}>Technical manger:</span>
                    <input
                      value={values.technical_manger}
                      name="technical_manger"
                      placeholder="............................."
                      style={{
                        width: "50%",
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <span>Date: </span>
                    <span>{new Date().toLocaleString()}</span>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionCPMP;
