import React, { useEffect, useState } from "react";
import * as certificates from "certificates/index";
import client from "api/apiAuth/guestClient";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@material-ui/core";
import clsx from "clsx";
import * as Yup from "yup";
import { connect } from "react-redux";
import { setSchema } from "utils/setValidationSchema";
import { useFormik } from "formik";
import SingleSectionRenderer from "components/SingleSectionRenderer";
import _ from "lodash";
import { runActions } from "reducers/grouping";
import { ClipLoader } from "react-spinners";
const DocumentGeneration = ({
  action,
  open,
  setOpen,
  group,
  runActions,
  submissionIds,
  user,
  onActionComplete,
}) => {
  const [certificateActiveTab, setCertificateActiveTab] = useState(1);
  const [values, setValues] = useState({});
  let [divRefs, setDivRefs] = useState(null);
  const [section, setSection] = useState(null);
  const [errored, setErrored] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [validationSchema, setValidationSchema] = useState(null);
  const [loading, setLoading] = useState(false);
  const submission = group.submissions.find(
    (sub) => sub.id === submissionIds[0]
  );
  const onSubmit = (data) => {
    let meta;
    let documentValues, documentDom;
    if (divRefs && "documentValues" in divRefs) {
      ({ documentValues, ...documentDom } = divRefs);
    } else if (divRefs) {
      ({ ...documentDom } = divRefs);
    }
    if (documentDom && !_.isEmpty(documentDom)) {
      Object.values(documentDom).forEach((divRef) => {
        const el = divRef?.querySelectorAll("textarea");
        const radioButtons = divRef?.querySelectorAll(
          'input[type="radio"][name="sampling"]:checked'
        );

        let selectInput = divRef?.querySelector("select");
        if (selectInput) {
          selectInput.options[selectInput.selectedIndex].setAttribute(
            "selected",
            "selected"
          );
        }
        if (radioButtons) {
          divRef
            ?.querySelectorAll('input[type="radio"][name="sampling"]:checked')
            .forEach((input) => {
              input.setAttribute("checked", "checked");
            });
        }
        if (el) {
          el.forEach((item) => {
            item.style.borderColor = "Transparent";
            item.style.borderStyle = "none";
            item.style.resize = " none";
          });
        }
      });
      meta = Object.fromEntries(
        Object.entries(documentDom).map(([key, value]) => [
          key,
          value.innerHTML,
        ])
      );
    }
    setSubmitFlag(true);
    setLoading(true);
    runActions({
      submissionIds,
      groupId: group.groupId,
      actionId: action.id,
      data: {
        meta,
        user,
        values: {
          ...values,
          actionId: action.id,
          groupId: group.groupId,
          stepId: group.groupType.start_step,
        },
      },
      stepId: group.groupType.start_step,
    })
      .then((res) => {
        if (res.value.data.message === "Success") {
          setLoading(false);
          setOpen(false);
          onActionComplete();
        }
      })
      .catch((error) => {
        setLoading(false);
        setSubmitFlag(false);
      });
  };
  const formik = useFormik({
    validationSchema,
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues: {},
    onSubmit,
  });
  useEffect(() => {
    if (!action.attributes?.sectionId) {
      setErrored(true);
      return;
    }
    async function findSection() {
      const sectionData = await client.post("generic/find", {
        where: { id: action.attributes.sectionId },
        model: "FormSection",
        include: {
          association: "inputs",
          include: [
            {
              association: "dependency",
            },

            { association: "enums" },

            { association: "events" },
          ],
        },
      });

      if (sectionData.data.rows.length) {
        setSection(sectionData.data.rows[0]);
        setValidationSchema(
          Yup.object().shape(setSchema(sectionData.data.rows[0].inputs))
        );
      } else setErrored(true);
    }
    findSection();
  }, [action]);

  const renderDocumentGeneration = (templateName) => {
    const Template = certificates[action.attributes.letter.template];
    return (
      <div
        className={clsx("tab-item-wrapper", {
          active: certificateActiveTab === 1,
        })}
        index={0}
      >
        <Template
          drug={submission.drugs}
          letter={submission}
          values={values}
          group={group}
          setValues={setValues}
          formValues={formik.values}
          divRef={(element) => {
            return (divRefs[action.attributes.letter.template] = element);
          }}
          form={formik}
          setDivRef={setDivRefs}
        />
      </div>
    );
  };
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "90%",
        margin: "auto",
        height: "90%",
      }}
    >
      <DialogTitle className="bg-color-darkBlue">
        <span className="titleModal text-white">{action?.title}</span>
      </DialogTitle>
      <DialogContent style={{ height: "50%" }}>
        {section && (
          <SingleSectionRenderer
            section={section}
            validationSchema={validationSchema}
            formik={formik}
          />
        )}
        {renderDocumentGeneration(action?.attributes?.letter?.template)}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "200px" }}
          disabled={
            ((!formik.isValid || !formik.dirty || submitFlag) && section) ||
            loading
          }
          onClick={formik.submitForm}
        >
          Submit
          {loading && <ClipLoader color={"var(--primary)"} loading={true} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runActions: (data) => dispatch(runActions(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentGeneration);
