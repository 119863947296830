import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  CardActions,
  TextareaAutosize,
  MenuItem,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import SpinnerLoader from "components/SpinnerLoader";
import { getFormLayout, setFormName } from "reducers/form/formActions";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import FormSteps from "components/FormSteps";
import { getModificationSchema } from "utils/setValidationSchema";
import _ from "lodash";
const {
  tryGetConfig,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/FormStepsConfig`);

const Modifications = (props) => {
  let { getLayout, form_layout_loading, form } = props;
  const [modificationId, setModificationId] = useState(1);
  const [allModification, setAllModification] = useState([]);
  const [validationSchema, setValidationSchema] = useState(null);
  const [submissionId, setSubmissionId] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [hasForm, setHasForm] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [message, setMessage] = useState("");
  const usetyles = makeStyles({
    list: {
      overflowY: "auto",
      margin: 0,
      padding: 0,
      listStyle: "none",
      height: "100%",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D5DBE0",
        borderRadius: 5,
      },
    },
  });
  const classes = usetyles();

  const target_modification = allModification.find(
    (mod) => mod.id === modificationId
  );

  const getModificationForm = async () => {
    if (!allModification.length) return;
    if (target_modification.form_id) {
      setHasForm(true);
      getLayout({ formId: target_modification.form_id });
    }
  };

  const getInitialValues = async () => {
    const response = await client.get(
      "submission/getInitialDataForModification",
      {
        params: {
          submission_code: submissionId,
          modification_id: modificationId,
        },
      }
    );
    setInitialValues(response.data);
  };
  useEffect(() => {
    if (!_.isEmpty(initialValues)) {
      let eventEndDay = new Date(initialValues.event_date_from);

      if (eventEndDay >= new Date()) {
        setLoading(true);
      } else {
        setLoading(false);
        setMessage("Event Finished");
      }
    } else {
      setLoading(false);
      setMessage("No Event Data");
    }
  }, [initialValues]);
  const getAllModifications = async () => {
    const { data } = await client.get("modification/getAllModifications");
    setAllModification(data.AllModifications);
  };

  const submitModification = async () => {
    const response = await client.post("workflow/modifySubmission", {
      submission_id: submissionId,
      modification_id: modificationId,
      data: {},
    });
    Swal.fire({
      title: "Your modification has been sent successfully!",
      html: "",
      confirmButtonColor: "#0066cc",
      confirmButtonText: "Ok",
    }).then(() => history.push("/"));
  };

  useEffect(() => {
    getAllModifications();
  }, []);

  useEffect(() => {
    getModificationForm();
  }, [allModification, modificationId]);

  useEffect(() => {
    getInitialValues();
  }, [modificationId]);

  useEffect(() => {
    if (form) {
      setValidationSchema(getModificationSchema(form, false));
    }
  }, [form]);
  const handleSetComment = (e) => {
    setComment(e.target.value);
  };
  let configuration = {};
  if (target_modification?.form_id)
    configuration = tryGetConfig(target_modification.form_id);

  return (
    <Card
      className="card-box mb-spacing-6-x2"
      style={{
        borderRadius: "20px 20px 20px 20px",
        boxShadow: "none",
        border: "none",
        marginTop: "-4px",
        backgroundColor: "#f4f5fd",
      }}
    >
      <CardContent className="pb-1 font-weight-bold">
        <h6 className="mb-4 ml-3 mt-3">
          <b>Modifications:</b>
        </h6>
        <Grid
          container
          style={{ justifyContent: "space-around" }}
          className="ml-3 mb-3"
        >
          <Grid item md={5}>
            <TextField
              fullWidth
              required
              id="outlined-required"
              label="Application"
              variant="outlined"
              onChange={(e) => {
                setSubmissionId(e.target.value);
              }}
              onBlur={getInitialValues}
            />
          </Grid>

          <Grid item md={5} className="ml-3 mb-3">
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel id="select-entries-label">Modifications</InputLabel>
              <Select
                labelId="select-entries-label"
                id="select-entries"
                value={modificationId}
                onChange={(e) => {
                  setModificationId(e.target.value);
                }}
                label="Modifications"
              >
                {allModification.map((modification) => {
                  return (
                    <MenuItem
                      className="mx-2"
                      value={modification.id}
                      key={modification.id}
                    >
                      {modification.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {/* <InputLabel>Modifications</InputLabel>
              <Select
                native
                value={modificationId}
                onChange={(e) => {
                  setModificationId(e.target.value);
                }}
                label="Modifications"
              >
                <option aria-label="None" value="" />
                {allModification.map((modification) => {
                  return (
                    <option value={modification.id} key={modification.id}>
                      {modification.name}
                    </option>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        {allModification?.find((mod) => mod.id == modificationId)?.form_id && (
          <Grid container>
            {form_layout_loading ? (
              <SpinnerLoader />
            ) : !loading ? (
              <Card sx={{ minWidth: 275 }}>
                <CardContent>{message}</CardContent>
              </Card>
            ) : (
              <FormSteps
                form={form.sections}
                validationSchema={validationSchema}
                initialValues={initialValues}
                config={{
                  showDraftButton: false,
                  modification: { modificationId, submissionId, comment },
                  ...configuration,
                }}
                history={history}
                setInitialData={
                  allModification?.find((mod) => mod.id == modificationId)
                    ?.setInitialData
                }
              ></FormSteps>
            )}
          </Grid>
        )}

        <span className="heading-4 d-block mt-4 comment">
          Write Your Comment
        </span>
        <FormControl>
          <TextareaAutosize
            value={comment}
            onChange={handleSetComment}
            minRows={"5"}
            cols={"125"}
            className="txt-area"
          />
        </FormControl>
      </CardContent>
      <CardActions>
        {!hasForm && (
          <Button
            variant="contained"
            color="primary"
            className="m-5"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to submit your application? ",
                showConfirmButton: true,
                titleHeight: 200,
                showCancelButton: true,
                confirmButtonClass: "submit",
                cancelButtonClass: "cancel",
                titleClass: "fontSize",
                customClass: "swal-wide",
                confirmButtonText: "Submit",
                cancelButtonText: "Cancel",
                closeOnConfirm: false,
              }).then(function (isConfirm) {
                submitModification();
              });
            }}
          >
            Submit
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    allNotifications: state.socket.messages,
    form: state.form.form,
    form_layout_loading: state.form.form_layout_loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(getFormLayout(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modifications);
