import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import imag from "../../assets/images/Checklist.jpg";
import Swal from "sweetalert2";
import "./index.scss";
import {
  Grid,
  Button,
  CircularProgress,
  FormControl,
  TextareaAutosize,
} from "@material-ui/core";
import { RenderStepper } from "../Stepper/index";
import { connect } from "react-redux";
import {
  enableSubmitButton,
  saveDraft,
  startEngine,
  submitUpdateRequestTask,
  updateSubmission,
} from "reducers/form/formActions";
import { connectSocket } from "reducers/socket";
import client from "api/apiAuth/guestClient";
import _ from "lodash";
import sanitizeObject from "utils/SanitizeObject";
import convertHtmlToFile from "utils/convertHtmlToFile";
import {
  createRandomId,
  setInputKey,
  setSections,
  ConstructFormErrors,
} from "./utils";
import { getValue } from "utils/GetObjectValues";
import { GetStepContent } from "./getStepContent";
import { getAllowedSubmissionCount } from "reducers/settings/settingsActions";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { ClipLoader } from "react-spinners";
import SenitizeObjectNull from "utils/SenitizeObjectNull";
const {
  messages,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/allMesaages`);

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(15),
    marginRight: theme.spacing(1),
    border: "2px solid #0066cc",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      border: "2px solid #0066cc",
    },
  },

  actionsContainer: {
    marginBottom: theme.spacing(4),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

// Render each input according to its widget type
const FormSteps = (props) => {
  const {
    form,
    formId,
    validationSchema,
    submit,
    draftId,
    draftValues,
    submissionValues,
    history,
    initialValues,
    setInitialValues,
    companyAllowedSubmissionCount,
    getAllowedSubmissionCount,
    user,
    draftValidationSchema,
    requestsNo,
    inputEvents,
    submissionId,
    config,
    submitUpdateTask,
    continueValues,
    setInitialData,
    connectSocket,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [subActiveStep, setSubActiveStep] = useState(0);
  const [childStep, setChildStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [comment, setComment] = useState("");
  const [sectionNameFlags, setSectionNameFlags] = useState({});
  const [numbering, setNumbering] = useState({
    sectionIndex: 1,
    childSectionIndex: 1,
  });
  const [yupSchema, setYupSchema] = useState(
    Yup.object().shape(validationSchema)
  );
  const [elementsProtoType, setElementsProtoType] = useState({});
  const [manyEntities, setManyEntities] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const _isMounted = useRef(true); // Initial value _isMounted = true

  const classes = useStyles();
  let formik;
  formik = useFormik({
    validationSchema: yupSchema,
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    setYupSchema(Yup.object().shape(validationSchema));
  }, [validationSchema]);
  const setProtoTypeInitialValues = (obj, initialValues, sections) => {
    sections.forEach((section, i) => {
      const isSubSections = [section.group, section.step].some((e) =>
        Array.isArray(e)
      );
      Object.entries(initialValues).forEach(([key, value]) => {
        if (typeof value === "object") {
          if (section.key === key) {
            if (Array.isArray(value)) {
              if (key === "submissions") {
                obj[section.name] = value.map((e, i) => {
                  formik.setFieldValue(
                    setInputKey(
                      {
                        multi_key: "submissions.[i].randomId",
                        key: "randomId",
                      },
                      [i]
                    ),
                    e.file_code
                  );
                  return {
                    randomId: e.file_code,
                  };
                });
              } else {
                obj[section.name] = Array.from(
                  { length: value.length },
                  () => ({})
                );
              }
              if (isSubSections) {
                obj[section.name].forEach((ele, i) => {
                  setProtoTypeInitialValues(obj[section.name][i], value[i], [
                    ...(section.group || []),
                    ...(section.step || []),
                  ]);
                });
              }
            } else {
              if (!obj[section.name]) {
                if (section.isMany) obj[section.name] = [{}];
                else obj[section.name] = {};
                setProtoTypeInitialValues(obj[section.name], value, [
                  ...(section.group || []),
                  ...(section.step || []),
                ]);
              }
            }
          } else if (section.key === "self") {
            if (!obj[section.name]) {
              if (section.isMany) obj[section.name] = [{}];
              else obj[section.name] = {};
              setProtoTypeInitialValues(obj[section.name], initialValues, [
                ...(section.group || []),
                ...(section.step || []),
              ]);
            }
          } else {
            if (!obj[section.name]) {
              if (section.isMany) {
                if (obj && obj.length > 0) {
                  obj.map((v, index) => {
                    obj[index][section.name] = [{}];
                  });
                } else {
                  obj[section.name] = [{}];
                }
              } else obj[section.name] = {};
              setProtoTypeInitialValues(obj[section.name], initialValues, [
                ...(section.group || []),
                ...(section.step || []),
              ]);
            }
          }
        } else {
          if (!obj[section.name]) {
            if (section.isMany) obj[section.name] = [{}];
            else obj[section.name] = {};
            setProtoTypeInitialValues(obj[section.name], initialValues, [
              ...(section.group || []),
              ...(section.step || []),
            ]);
          }
        }
      });
    });
  };

  const setObjInArr = (obj, sections, randomId) => {
    sections.forEach((section) => {
      const isSubSections = [section.group, section.step].some((e) =>
        Array.isArray(e)
      );
      if (section.isMany) {
        const addedObj = {};
        if (section.key === "submissions") addedObj.randomId = randomId;
        obj[section.name] = [addedObj];

        if (isSubSections)
          obj[section.name].forEach((ele) =>
            setObjInArr(ele, [
              ...(section.group || []),
              ...(section.step || []),
            ])
          );
      } else {
        const addedObj = {};
        if (section.key === "submissions") addedObj.randomId = randomId;
        obj[section.name] = addedObj;
        isSubSections &&
          setObjInArr(
            obj[section.name],
            [...(section.group || []), ...(section.step || [])],
            randomId
          );
      }
    });
  };

  let protoTypeObj = {};
  const setProtoType = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== "randomId") {
        protoTypeObj[key] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        setProtoType(protoTypeObj[key]);
      }
    });
    setElementsProtoType(protoTypeObj);
  };
  useEffect(() => {
    if (form) {
      setSections(form, _isMounted, setSteps, inputEvents, formik);
    }

    let sections = _.cloneDeep(form),
      obj = {};
    if (_.isEmpty(initialValues)) {
      const randomId = createRandomId();
      if (!config?.continue?.sub_id) {
        if (submissionId)
          formik.setFieldValue(
            setInputKey({
              multi_key: "submissions.[i].randomId",
              key: "randomId",
            }),
            initialValues.file_code
          );
        else
          formik.setFieldValue(
            setInputKey(
              { multi_key: "submissions.[i].randomId", key: "randomId" },
              [0]
            ),
            randomId
          );
      }
      setObjInArr(obj, sections, randomId);
      setProtoType(obj);
    } else if (!_.isEmpty(initialValues) && form) {
      if (setInitialData) {
        setObjInArr(obj, sections, null);
        setProtoType(obj);
      } else {
        setProtoTypeInitialValues(obj, initialValues, form);
      }
    }

    if (continueValues) {
      sections.forEach((section) => {
        if (section.key) {
          const sectionValues = getValue(continueValues, section.key);
          if (sectionValues && sectionValues.length) {
            obj[section.name] = Array.apply(null, {
              length: sectionValues.length,
            }).map((cur, index) => {
              const clone = _.cloneDeep(obj[section.name][0]);
              return {
                ...clone,
                name: sectionValues[index][section.style["join_name"]],
              };
            });
          }
        }
      });
    }
    setManyEntities(obj);
  }, [form, initialValues]);

  useEffect(() => {
    _isMounted.current = true;
    getAllowedSubmissionCount({ user_id: user.id });

    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (draftValues && draftId)
      setInitialValues(SenitizeObjectNull(draftValues));
  }, [draftValues]);
  useEffect(() => {
    if (submissionValues && submissionId) {
      setInitialValues(SenitizeObjectNull(submissionValues));
      connectSocket();
    }
    // else if (submissionValues && !draftId) setInitialValues({});
  }, [submissionValues, submissionId]);
  const handleSetComment = (e) => {
    setComment(e.target.value);
  };
  useEffect(() => {
    if (steps.length && !_.isEmpty(initialValues)) {
      const newSteps = _.cloneDeep(steps);
      for (const step of newSteps) {
        const currentStepName = step?.key;
        if (initialValues?.hasOwnProperty(currentStepName)) {
          step.number = initialValues[currentStepName].length;
        }
      }
      setSteps(newSteps);
    }
  }, [initialValues]);

  useEffect(() => {
    if ("onManyEntityChangeEvents" in config)
      for (const ev of config.onManyEntityChangeEvents) {
        ev({ manyEntities, setManyEntities });
      }
  }, [manyEntities]);

  let onValueChange = null;
  if ("onValueChangeEvents" in config)
    onValueChange = (input, newValue, event) => {
      for (const valueEvent of config.onValueChangeEvents)
        valueEvent(input, newValue, event, formik);
    };

  let onSectionAdd = null;
  if ("onAddSectionEvents" in config)
    onSectionAdd = (
      section,
      cloneManyEntities,
      setManyEntities,
      parentIndex
    ) => {
      for (const sectionAddEvent of config.onAddSectionEvents) {
        sectionAddEvent({
          section,
          cloneManyEntities,
          formik,
          setManyEntities,
          parentIndex,
        });
      }
    };

  let onSectionRemove = null;
  if ("onRemoveSectionEvents" in config)
    onSectionRemove = (
      section,
      cloneManyEntities,
      setManyEntities,
      parentIndex,
      index
    ) => {
      for (const sectionRemoveEvent of config.onRemoveSectionEvents)
        sectionRemoveEvent({
          section,
          cloneManyEntities,
          formik,
          setManyEntities,
          parentIndex,
          index,
        });
    };

  const handleNext = async (e, sections) => {
    window.scrollTo(0, 0);
    e.stopPropagation();
    if (activeStep >= sections.length - 1) {
      let errorsObject = false;
      try {
        yupSchema.validateSync(formik.values, {
          context: formik.values,
          abortEarly: false,
        });
      } catch (err) {
        errorsObject = err;
      }

      if (errorsObject) {
        enableSubmitButton();
        Swal.fire({
          title: "The following fields are either required or invalid:",
          html: `${ConstructFormErrors(errorsObject).join("<br><br>")}`,
          icon: "error",
          confirmButtonColor: "#d36467",
          confirmButtonText: "Try Again",
          width: "50%",
        });
      } else {
        handleSubmit();
      }
      return;
    }

    if (sections[activeStep].step) {
      if (subActiveStep === sections[activeStep].step.length - 1) {
        handelActiveStep(activeStep + 1, 0, false);
      } else {
        handelActiveStep(activeStep, subActiveStep + 1, true);
      }
    } else {
      handelActiveStep(activeStep + 1, 0, false);
    }
  };

  const handleBack = (sections) => {
    window.scrollTo(0, 0);
    if (sections[activeStep]?.step) {
      if (subActiveStep === 0) {
        handelActiveStep(activeStep - 1, 0, false);
      } else {
        handelActiveStep(activeStep, subActiveStep - 1, true);
      }
    } else {
      handelActiveStep(activeStep - 1, 0, false);
    }
  };

  const handleSubmit = () => {
    if (requestsNo > 0) {
      Swal.fire({
        title: "Please wait ",
        text: `Please wait while some files are uploaded`,
        icon: "info",
        confirmButtonColor: "#d36467",
        dangerMode: true,
      });
      return;
    }

    Swal.fire({
      title: "Are you sure you want to submit your application? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        let { attachments, ...values } = formik.values;
        let SubmissionData = sanitizeObject(values);
        const finalData = await convertHtmlToFile(SubmissionData);
        if (config && "modification" in config) {
          if (!config.modification.submissionId) {
            Swal.fire({
              title: "Please enter a submission id",
              html: "",
              confirmButtonText: "Ok",
            });
            return;
          }
          async function submitModification() {
            await client.post("workflow/modifySubmission", {
              submission_id: config.modification.submissionId,
              modification_id: config.modification.modificationId,
              data: { ...SubmissionData, comment: config.modification.comment },
            });
            Swal.fire({
              title: "Your modification has been sent successfully!",
              html: "",
              imageUrl: imag,
              imageWidth: 250,
              imageHeight: 200,
              imageAlt: "Custom image",
              confirmButtonColor: "#0066cc",
              confirmButtonText: "Ok",
            }).then(() => history.push("/"));
          }
          submitModification();
        } else {
          let promise = null;
          if ("continue" in config) {
            promise = submit({
              ...SubmissionData,
              form_id: parseInt(formId),
              uniqueId: draftId,
              is_draft: false,
              sub_id: config?.continue?.sub_id,
            });
          } else if (submissionId) {
            promise = submitUpdateTask({
              ...SubmissionData,
              comment,
              form_id: parseInt(formId),
              sub_id: config?.continue?.sub_id,
              id: submissionId,
              is_edit: true,
            });
          } else {
            promise = submit({
              ...finalData,
              form_id: parseInt(formId),
              uniqueId: draftId,
              is_draft: false,
              sub_id: config?.continue?.sub_id,
            });
          }
          setLoading(true);
          promise
            .then((res) => {
              Swal.fire({
                title: "Your application has been sent successfully!",
                html: messages.submitMessageFun(res.value.data.duration),
                imageUrl: imag,
                imageWidth: 250,
                imageHeight: 200,
                imageAlt: "Custom image",
                confirmButtonColor: "#0066cc",
                confirmButtonText: "Ok",
              }).then(() => {
                setLoading(false);
                return history.push("/");
              });
            })
            .catch((err) => {
              setLoading(false);
              console.log("err", err);
            });
        }
      }
    });
  };
  const handleDraft = async () => {
    try {
      const draftValues = sanitizeObject(formik.values);
      if (_.isEmpty(draftValues)) {
        Swal.fire("You have to fill data to be saved in the draft");
        return;
      }
      Swal.fire({
        title: "Are you sure you want to save the application as a draft? ",
        showConfirmButton: true,
        titleHeight: 200,
        showCancelButton: true,
        confirmButtonClass: "submit",
        cancelButtonClass: "cancel",
        titleClass: "fontSize",
        customClass: "swal-wide",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        closeOnConfirm: false,
      }).then(async function ({ isConfirmed }) {
        if (isConfirmed) {
          try {
            const valid = await Yup.object()
              .shape(draftValidationSchema)
              .validate(draftValues);
            const finalData = await convertHtmlToFile(draftValues);
            if (!_.isEmpty(valid)) {
              submit({
                ...finalData,
                form_id: parseInt(formId),
                is_draft: true,
                uniqueId: draftId,
              })
                .then((res) => {
                  Swal.fire({
                    title: "Succeeded",
                    text: "Your application has been saved as a draft successfully",
                    icon: "success",
                    timer: 3000,
                  }).then(() => history.push("/"));
                })
                .catch((err) => {
                  console.log("err", err);
                  // history.push("/");
                });
            }
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Invalid Data",
              text: `Please review submitted data and try again`,
              icon: "error",
              confirmButtonColor: "#d36467",
              confirmButtonText: "Try Again",
              dangerMode: true,
            });
          }
        }
      });
    } catch (err) {
      console.log(err);
      Swal.fire("There is something went wrong", "error");
    }
  };
  const handelActiveStep = (stepValue, subStepValue, type) => {
    setActiveStep(stepValue);
    setSubActiveStep(subStepValue);
    setChildStep(type);
    setNumbering({
      sectionIndex: stepValue + 1,
    });
  };

  return (
    <Grid container className="root bg-color-gray">
      <FormikProvider value={formik}>
        <Grid item sm={2} md={2} className="stepper">
          <RenderStepper
            steps={steps}
            activeStep={activeStep}
            subActiveStep={subActiveStep}
            handelActiveStep={handelActiveStep}
          ></RenderStepper>
        </Grid>
        {steps.length > 0 && (
          <Grid
            item
            sm={10}
            md={10}
            container
            className="form-style pt-0 pr-4 pl-4"
          >
            {!submissionId ? (
              config && "showDraftButton" in config ? (
                config.showDraftButton && (
                  <Grid item xs={12} className="mt-2">
                    <Button
                      variant="outlined"
                      color="primary"
                      className="save-btn mr-4 float-right"
                      onClick={(e) => handleDraft()}
                    >
                      <InsertDriveFileOutlinedIcon />
                      Save as draft
                    </Button>
                  </Grid>
                )
              ) : (
                <Grid item xs={12} className="mt-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="save-btn mr-4 float-right"
                    onClick={(e) => handleDraft()}
                  >
                    <InsertDriveFileOutlinedIcon />
                    Save as draft
                  </Button>
                </Grid>
              )
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              {GetStepContent({
                formik,
                activeStep,
                steps,
                subActiveStep,
                setInitialValues,
                childStep,
                numbering,
                manyEntities,
                setManyEntities,
                elementsProtoType,
                companyAllowedSubmissionCount,
                draftId,
                submissionId,
                sectionNameFlags,
                setSectionNameFlags,
                onValueChange,
                onSectionAdd,
                onSectionRemove,
                config,
                selectedOption,
                setSelectedOption,
              })}
            </Grid>
            {submissionId && (
              <>
                <span className="heading-4 d-block mt-4 comment">
                  Write Your Comment
                </span>
                <FormControl>
                  <TextareaAutosize
                    value={comment}
                    onChange={handleSetComment}
                    minRows={"5"}
                    cols={"125"}
                    className="txt-area"
                  />
                </FormControl>
              </>
            )}

            <Grid item xs={12}>
              <div className={classes.actionsContainer}>
                <Grid container className="m-2" spacing={1}>
                  {activeStep !== 0 && (
                    <Grid item xs={2} md={2} lg={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleBack(steps)}
                        className={`${classes.button} float-right`}
                      >
                        <div className="mr-1">
                          <b>Back</b>
                        </div>
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={2} md={2} lg={2} className="text-left">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleNext(e, steps)}
                      className={`${classes.button} float-left`}
                      disabled={loading}
                    >
                      <b>
                        {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </b>
                      {loading && (
                        <CircularProgress
                          size={35}
                          style={{ margin: "0px 0px 0px -20px" }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </FormikProvider>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    submission: state.form.submission,
    draftValues: state.form.draftValues,
    submissionValues: state.form.submissionValues,
    enableSubmit: state.form.enableSubmit,
    companyAllowedSubmissionCount:
      state.settings.settings.companyAllowedSubmissionCount,
    user: state.auth.user,
    requestsNo: state.general.requestsNo,
    inputEvents: state.form.inputEvents,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submit: (submission) => dispatch(startEngine(submission)),
    submitUpdateTask: (submission) =>
      dispatch(submitUpdateRequestTask(submission)),
    connectSocket: (eventName, message) =>
      dispatch(connectSocket(eventName, message)),
    saveDraft: (submission) => dispatch(saveDraft(submission)),
    enableSubmitButton: () => dispatch(enableSubmitButton()),
    updateSubmission: (submission) => dispatch(updateSubmission(submission)),
    getAllowedSubmissionCount: (params) =>
      dispatch(getAllowedSubmissionCount(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSteps);
