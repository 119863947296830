import { getUrl, handleError } from "../utilities";
import reduxStore from "../../reducers/store";
import {
  DecreaseRequestsNo,
  IncreaseRequestsNo,
} from "reducers/general/generalActions";
const { dispatch } = reduxStore;
export const reqInterceptor = {
  resolve: (config) => {
    // console.log(
    //   `%c ${config.method.toUpperCase()} - ${getUrl(config)}:`,
    //   "color: #0086b3; font-weight: bold",
    //   config
    // );
    if (getUrl(config).includes("uploadFile")) {
      dispatch(IncreaseRequestsNo());
    }
    return config;
  },
  reject: (error) => {
    handleError(error);
    Promise.reject(error);
  },
};
export const resInterceptor = {
  resolve: async (response) => {
    // console.log(
    //   `%c ${response.status} - ${getUrl(response.config)}:`,
    //   "color: #008000; font-weight: bold",
    //   response
    // );
    if (getUrl(response.config).includes("uploadFile")) {
      dispatch(DecreaseRequestsNo());
    }
    return response;
  },
  reject: (error) => {
    // console.log(
    //   `%c ${error.response && error.response.status} - ${
    //     error.response && getUrl(error.response.config)
    //   }:`,
    //   "color: #a71d5d; font-weight: bold",
    //   error.response
    // );
    console.log(error)
    // if(error.message){

    //   if (error.message.includes("attachment/uploadFile")) {
    //     dispatch(DecreaseRequestsNo());
    //     return Promise.reject(error)
    //   }
    // }
    handleError(error);
    return Promise.reject(error);
  },
};
