const initialValues = (letter, values, editValues) => {
  return {
    acceptNameLetter: () => {
      return {
        state: {},
        type: "const",
      };
    },
    veterinaryPharmaceuticals: () => {
      return {
        state: {
          shared: () => {
            return {
              firstText:
                "إجتمعت اللجنة العلمية المتخصصة للأدوية البيطرية و إضافات الأعلاف المشكلة بقرار السيد الأستاذ الدكتور رئيس هيئة الدواء المصرية رقم 31 لسنه2022 وذلك في تمام الساعة التاسعة صباحا يوم الاثنين الموافق ../../..... بمقر هيئة الدواء المصرية 21 أ عبد العزيز آل سعود المنيل القاهرة. و بحضور كل من السادة الآتي أسماؤهم :-. ",
              firstList: [
                "د. ماجدة مصطفي",
                "د.ياسمين محمد فهمي",
                "د.سماح سلامة",
              ],
              secondList: [
                "أ.د/ميرفت محمود كامل",
                "أ.د. أحمد محمد جلال",
                "أ.د. هشام صلاح عبد الحسيب",
                "أ.د. يوسف إبراهيم يوسف",
                "د. محمد احمد الشريف",
              ],
              secText:
                "و بدأت اللجنة أعمالها بالنظر في جدول العمل المقدم اليها من هيئة الدواء المصرية على النحو التالي:-",
              secListItem:
                "أولا : عرض محضر اجتماع اللجنة السابق بجلسة .../.../...... وقررت اللجنة التصديق عليه.",
              secList: [
                " ثانيا : عرض المستحضرات المحلية والمستوردة الجديدة .",
                "ثالثا : عرض المستحضرات المحلية والمستوردة إعادة التسجيل.",
                "رابعاً: المراسلات",
              ],
              segnName1: "د.حسن القرنفيلى",
              segnName2: "د.سماح سلامه",
              segnName3: "أ.د. حنان أمين رزق",
            };
          },
        },
        type: "shared",
      };
    },
    minutesOfMeeting: () => {
      return {
        state: {
          shared: () => {
            return {
              firstText:
                "إجتمعت اللجنة العلمية المتخصصة للأدوية البيطرية و إضافات الأعلاف المشكلة بقرار السيد الأستاذ الدكتور رئيس هيئة الدواء المصرية رقم 31 لسنه2022 وذلك في تمام الساعة التاسعة صباحا يوم الاثنين الموافق ../../..... بمقر هيئة الدواء المصرية 21 أ عبد العزيز آل سعود المنيل القاهرة. و بحضور كل من السادة الآتي أسماؤهم :-. ",
              firstList: [
                "د. ماجدة مصطفي",
                "د.ياسمين محمد فهمي",
                "د.سماح سلامة",
              ],
              secondList: [
                "أ.د/ميرفت محمود كامل",
                "أ.د. أحمد محمد جلال",
                "أ.د. هشام صلاح عبد الحسيب",
                "أ.د. يوسف إبراهيم يوسف",
                "د. محمد احمد الشريف",
              ],
              secText:
                "و بدأت اللجنة أعمالها بالنظر في جدول العمل المقدم اليها من هيئة الدواء المصرية على النحو التالي:-",
              secListItem:
                "أولا : عرض محضر اجتماع اللجنة السابق بجلسة .../.../...... وقررت اللجنة التصديق عليه.",
              secList: [
                " ثانيا : عرض المستحضرات المحلية والمستوردة الجديدة .",
                "ثالثا : عرض المستحضرات المحلية والمستوردة إعادة التسجيل.",
                "رابعاً: المراسلات",
              ],
              segnName1: "د.حسن القرنفيلى",
              segnName2: "د.سماح سلامه",
              segnName3: "أ.د. حنان أمين رزق",
            };
          },
        },
        type: "shared",
      };
    },
    scientificCommitteeApprovalLetter: () => {
      return {
        state: {
          shared: () => {
            return {
              companyManagerName: letter.org.ArabicName || "",
              tableComment:
                editValues?.tableComment || letter.values.temp_comment || "",
              firstList: [
                "صورة مبلغة لإدارة الثبات لإستلام ملف دراسة الثبات الخاص بالمستحضر.",
                "صورة مبلغة للإدارة المركزية للرقابة الدوائية لإستلام العينات الخاصة بالمستحضر وذلك للتحليل.",
                "علما بان هذه الموافقة لا تعتبر موافقة نهائية على التسجيل و إنما تخضع للمراجعة و التعديل من الادارة المركزية للمستحضرات الصيدلية حتى الحصول على إخطار التسجيل النهائي .",
                "صورة مبلغة للإدارة المركزية للرعاية الصيدلية لإعتماد النشرة العلمية الخاصة بالمستحضر.",
                "الموافقة سارية لمدة ثلاثة أعوام من تاريخ الموافقة علي أن تستكمل جميع إجراءات التسجيل في خلال هذة الفترة ويعتبر طلب التسجيل لاغياً بإنتهاء صلاحية الموافقة.",
                "مرفق صورة من البيانات العلمية الاولية",
              ],
              segnName1: "د. ماجدة مصطفى",
              segnName2: "د. حسن القرنفيلى",
              segnName3: "د. سماح سلامه",
            };
          },
        },
        type: "shared",
      };
    },
    refusalOfTechnicalCommittee: () => {
      return {
        state: {
          shared: () => {
            return {
              tableComment: letter.values.take_decision,
              segnName1: "د. ماجدة مصطفى",
              segnName2: "د. حسن القرنفيلى",
              segnName3: "د. سماح سلامه",
            };
          },
        },
        type: "shared",
      };
    },
    hardfilesApprovalLetter: () => {
      return {
        state: {
          shared: () => {
            return {
              firstList: [
                "يتم إلغاء تسجيل المستحضر اذا لم يتم الانتاج خلال 3 أعوام من تاريخ إصدار إخطار التسجيل.",
                "يتم إخطار الإدارة المركزية للمستحضرات الصيدلية في حالة حدوث أي تغيير في البيانات المذكورة في الإخطار خلال مدة التسجيل.",
                "تتعهد الشركة بإبلاغ مركز اليقظة الدوائية المصرى عن أي آثار عكسية خطيرة يتم رصدها عن هذا المستحضر خلال 15 يوم ،   كما تتعهد الشركة بتقديم تقرير Periodic Safety Update Report وفقاٌ للقواعد، والا يوقف تسجيل المستحضر.",
              ],
              approvedPack:
                editValues?.approvedPack || letter.values.approvedPack || "",
              license_holder:
                editValues?.license_holder ||
                letter.values.license_holder ||
                "",
              physicalCharacters:
                editValues?.physicalCharacters ||
                letter.values.physicalCharacters ||
                "",
              shelfLife: editValues?.shelfLife || letter.values.shelfLife || "",
              storageCondition:
                editValues?.storageCondition ||
                letter.values.storageCondition ||
                "",
              storageSite:
                editValues?.storageSite || letter.values.storageSite || "",
              segnName1: "د. ياسمين عبد السميع",
              segnName2: " د. ميريام ماهر",
              segnName3: "د. سماح سلامه",
              segnName4: "أ.د. حنان أمين رزق",
              arabicTitle: "إخطار تسجيل - مستحضر صيدلى بيطرى",
              englishTitle:
                "Registration License for Veterinary Pharmaceutical Product",
              body: "ايماء إلي الطلب المقدم من سيادتكم لتسجيل المستحضر المذكور أدناه ، نفيد سيادتكم علما بأن اللجنة الفنية لمراقبة الأدوية بجلسة {0} قد قررت الموافقة على تسجيل هذا المستحضر.",
            };
          },
        },
        type: "shared",
      };
    },
    productDataReport: () => {
      return {
        state: {},
        shared: () => {
          return {
            tableComment:
              editValues?.tableComment || letter.values.temp_comment || "",
          };
        },
        type: "const",
      };
    },
    letters: () => {
      return {
        state: {
          shared: () => {
            return {
              tableComment:
                editValues?.tableComment || letter.values.temp_comment || "",
              tableInputs: [
                {
                  title: "Product Name:",
                  name: "Product Name",
                  value: letter.values.trade_name,
                  isRequired: false,
                  display: true,
                },
                {
                  title: "Composition :",
                  name: "Composition",
                  value: letter.drugs
                    .map((drug) =>
                      drug.ingredients.map(
                        (ingredient) =>
                          `${ingredient.active_ingredient} ${
                            ingredient.strength && ingredient.strength
                          } ${ingredient.unit && ingredient.unit} ${
                            ingredient.salts && ingredient.salts.length
                              ? `(${ingredient.salts.map(
                                  (salt) =>
                                    `${salt.name} ${salt.unit && salt.unit} ${
                                      salt.strength && salt.strength
                                    }`
                                )})`
                              : ""
                          }`
                      )
                    )
                    .join(", "),
                  isRequired: false,
                  display: true,
                },
                {
                  title: "Dosage form:",
                  name: "Dosage form",
                  value: letter.drugs.map((drug) => drug.dosage_form).join(","),
                  isRequired: false,
                  display: true,
                },
                {
                  title: "Pack(volume):",
                  name: "Pack(volume)",
                  value: letter.drugs.generics,
                  isRequired: false,
                  display:
                    values.is_injection &&
                    (letter.values.registration_type !== "Imported" ||
                      letter.values.registration_type !== "Imported Bulk")
                      ? true
                      : false,
                },
                {
                  title: "Manufacturer:",
                  name: "Manufacturer",
                  value: `${letter.values.manufacturer_name},${
                    letter.values.manufacturer_name_egypt
                      ? letter.values.manufacturer_name_egypt
                      : ""
                  } ,${
                    letter.values.manufacturer_primary_packaging
                      ? letter.values.manufacturer_primary_packaging
                      : ""
                  } ${
                    letter.values.manufacturer_secondary_packaging
                      ? letter.values.manufacturer_secondary_packaging
                      : ""
                  } ${
                    letter.values.manufacturing_accessories
                      ? letter.values.manufacturing_accessories
                      : ""
                  } ${
                    letter.values.manufacturing_product
                      ? letter.values.manufacturing_product
                      : ""
                  }`,
                  isRequired: false,
                  display:
                    letter.values.registration_type === "Imported" ||
                    letter.values.registration_type === "Imported Bulk" ||
                    letter.values.registration_type === "Under License"
                      ? true
                      : false,
                },
                {
                  title: "license holder:",
                  name: "license holder",
                  value: letter.values.license_holder,
                  isRequired: false,
                  display:
                    letter.values.registration_type === "Imported" ||
                    letter.values.registration_type === "Imported Bulk"
                      ? true
                      : false,
                },
                {
                  title: "Applicant:",
                  name: "Applicant",
                  value:
                    letter.org.ArabicName || editValues?.tableInputs[6].value,
                  isRequired: false,
                  display: true,
                },
                {
                  title: "Type of markting: ",
                  name: "Type of marketing: ",
                  value: letter.values.marketing_type,
                  isRequired: false,
                  display: true,
                },
                {
                  title: "Reference and reference country:",
                  name: "Reference",
                  value: letter.drugs
                    .map(
                      (drug) => `${drug.reference}, ${drug.reference_country}`
                    )
                    .join(","),
                  isRequired: false,
                  display: true,
                },
              ],
            };
          },
        },
        type: "shared",
      };
    },
  };
};
export { initialValues as initialValuesOfCertificates };
