import { Grid } from "@material-ui/core";
import React from "react";
import { getValue } from "utils/GetObjectValues";
import * as customComponent from "customComponent/index";
function setInputKey(input, element) {
  const inputKey = input.key;
  return typeof element != "undefined"
    ? inputKey.replaceAll("[i]", `[${element}]`)
    : input.key;
}
function setInputKeyWithChild(input, parentIndex, childIndex) {
  const inputKey = input.key;
  let replaced;
  if (typeof parentIndex != "undefined" && typeof childIndex != "undefined") {
    replaced = inputKey.replace("[i]", `[${parentIndex}]`);
    return replaced.replaceAll("[i]", `[${childIndex}]`);
  } else {
    return input.key;
  }
}
function Attributes({
  attribute,
  task,
  groupIndex,
  group,
  childIndex,
  allProps,
}) {
  let value;
  let newKeys;
  if (group) {
    if (childIndex) {
      newKeys = setInputKeyWithChild(attribute, groupIndex, childIndex);
    } else {
      newKeys = setInputKey(attribute, groupIndex);
    }
  } else {
    newKeys = attribute.key;
  }
  value = getValue(task, newKeys, attribute.type);
  const renderCustomComponents = (componentName, task) => {
    const Component = customComponent[componentName];
    return (
      <div>
        {Component ? <Component task={task} allProps={allProps} /> : ""}
      </div>
    );
  };
  return (
    <>
      {attribute.type !== "component"
        ? value !== undefined && (
            <div>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={2}
                columns={12}
              >
                <Grid
                  item
                  xs={attribute.style.gridSize}
                  className="title-label"
                >
                  <span style={{ ...attribute.style.labelInlineStyle }}>
                    {attribute.label}
                  </span>
                </Grid>
                <Grid item xs={6} className="data">
                  <span style={{ ...attribute.style.valueInlineStyle }}>
                    {value}
                  </span>
                </Grid>
                <br />
              </Grid>
            </div>
          )
        : renderCustomComponents(attribute.componentName, task)}
    </>
  );
}

export default Attributes;
