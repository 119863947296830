import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormLabel,
  TextField,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
export default function AddVacationModal(props) {
  const {
    open,
    setAddModalOpen,
    isEdit,
    currentVacation,
    vacations,
    setVacations,
    setCurrentVacation,
  } = props;
  const [isDisable, setIsDisable] = useState(true);
  const [validationErrors, setValidationErrors] = useState({
    titleError: null,
    startDateError: null,
    endDateError: null,
  });
  const handleClose = () => {
    setAddModalOpen(false);
  };
  useEffect(() => {
    setValidationErrors({
      titleError: currentVacation.title ? null : "Title is required",
      startDateError: currentVacation.start_date
        ? null
        : "Start date is requried",
      endDateError: currentVacation.end_date ? null : "End date is requried",
    });
    if (
      currentVacation.title &&
      currentVacation.start_date &&
      currentVacation.end_date
    ) {
      setIsDisable(false);
    } else setIsDisable(true);
  }, [currentVacation]);
  const handleSumit = () => {
    const body = {
      title: currentVacation.title,
      start_date: currentVacation.start_date,
      end_date: currentVacation.end_date,
    };
    if (isEdit) {
      client
        .put(`/calendar/update/${currentVacation?.id}`, body)
        .then((res) => {
          Swal.fire({
            text: "Vacation is Updated Successfully",
            icon: "success",
            confirmButtonColor: "#0000ff",
            confirmButton: "btn btn-success",
          });
          setVacations(
            ...[
              vacations.filter((vacation) => {
                if (vacation.id == currentVacation.id) {
                  vacation.title = body.title;
                  vacation.start_date = body.start_date;
                  vacation.end_date = body.end_date;
                }
                return vacation;
              }),
            ]
          );
          vacations.find(
            (vac) =>
              vac.id == currentVacation.id && {
                ...vac,
                ...body,
              }
          );
          setAddModalOpen(false);
        });
    } else {
      client.post("/calendar/create", body).then((res) => {
        setVacations([...vacations, res.data.vacation]);
        Swal.fire({
          text: "Vacation is Added Successfully",
          icon: "success",
          confirmButtonColor: "#0000ff",
          confirmButton: "btn btn-success",
        });
        setAddModalOpen(false);
      });
    }
  };
  return (
    <div>
      <Dialog open={open} maxWidth={800} onClose={handleClose}>
        <DialogTitle className="text-center">
          {isEdit ? "update" : "Add"} Vacation
        </DialogTitle>

        <DialogContent>
          <FormLabel className="mt-2">title</FormLabel>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            type="text"
            fullWidth
            variant="standard"
            value={currentVacation.title}
            onChange={(e) =>
              setCurrentVacation({
                ...currentVacation,
                title: e.target.value,
              })
            }
          />
          <p className="text-danger">{validationErrors.titleError}</p>
          <FormLabel className="mt-2">Start Date</FormLabel>
          <TextField
            autoFocus
            margin="dense"
            id="start_date"
            type="date"
            fullWidth
            variant="standard"
            value={
              currentVacation.start_date
                ? new Date(currentVacation.start_date)
                    .toISOString()
                    .substring(0, 10)
                : currentVacation.start_date
            }
            onChange={(e) =>
              setCurrentVacation({
                ...currentVacation,
                start_date: e.target.value,
              })
            }
          />
          <p className="text-danger">{validationErrors.startDateError}</p>
          <FormLabel className="mt-2">End Date</FormLabel>

          <TextField
            autoFocus
            margin="dense"
            id="end_date"
            type="date"
            fullWidth
            variant="standard"
            value={
              currentVacation.end_date
                ? new Date(currentVacation.end_date)
                    .toISOString()
                    .substring(0, 10)
                : currentVacation.end_date
            }
            onChange={(e) =>
              setCurrentVacation({
                ...currentVacation,
                end_date: e.target.value,
              })
            }
          />
          <p className="text-danger">{validationErrors.endDateError}</p>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isDisable}
            className={
              isEdit ? "btn btn-primary fw-bold" : "btn btn-success fw-bold"
            }
            onClick={(e) => {
              handleSumit();
            }}
          >
            {isEdit ? "Update" : "Add"}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
