import { createReducer } from "@reduxjs/toolkit";
import {
  RUN_ACTIONS_FULFILLED,
  RUN_ACTIONS_PENDING,
  RUN_ACTIONS_REJECTED,
  GET_VALID_SUBMISSIONS_PENDING,
  GET_VALID_SUBMISSIONS_FULFILLED,
  GET_VALID_SUBMISSIONS_REJECTED,
  ADD_SUBMISSION_TO_GROUP_PENDING,
  ADD_SUBMISSION_TO_GROUP_FULFILLED,
  ADD_SUBMISSION_TO_GROUP_REJECTED,
} from "./groupingActionTypes";

const initialState = {
  error: false,
  loading: false,
  validSubmissions: [],
  validSubmissionsCount: 0,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(RUN_ACTIONS_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(RUN_ACTIONS_FULFILLED, (state, action) => {
    state.loading = false;
  });

  builder.addCase(RUN_ACTIONS_REJECTED, (state, action) => {
    state.loading = false;
  });

  builder.addCase(GET_VALID_SUBMISSIONS_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(GET_VALID_SUBMISSIONS_FULFILLED, (state, action) => {
    state.loading = false;
    state.validSubmissions = action.payload.data.submissions;
    state.validSubmissionsCount = action.payload.data.count;
  });

  builder.addCase(GET_VALID_SUBMISSIONS_REJECTED, (state, action) => {
    state.loading = false;
  });

  builder.addCase(ADD_SUBMISSION_TO_GROUP_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(ADD_SUBMISSION_TO_GROUP_FULFILLED, (state, action) => {
    state.loading = false;
    state.validSubmissions = action.payload.data.submissions;
    state.validSubmissionsCount = action.payload.data.count;
  });

  builder.addCase(ADD_SUBMISSION_TO_GROUP_REJECTED, (state, action) => {
    state.loading = false;
  });
});

export default reducer;
