const blacklist_names = [
  "clear",
  "nil",
  "stop",
  "strong",
  "best",
  "high",
  "zero",
  "king",
  "super",
  "max",
  "hi",
  "well",
  "mega",
  "energy",
  "power",
  "miracle",
  "magic",
  "free",
  "all",
  "pure",
  "mex",
  "oral",
  "tab",
  "mix",
  "ject",
  "powder",
  "feed",
  "solution",
  "aqua",
  "liquid",
  "liqui",
  "spot",
  "injection",
  "premix",
  "fast",
  "safe",
  "mega",
  "off",
  "smart",
  "wealth",
  "stop",
  "fortif",
  "hero",
  "more",
  "fort",
  "huge",
  "tab",
  "drench",
  "suspension",
  "tablet",
  "inj",
  "soluble",
  "granules",
  "drench",
  "bravo",
  "great",
  "gran",
  "sol",
  "liqi",
  "safe",
  "extra",
  "forte",
  "plus",
  "polv",
  "Pulv",
  "fed",
  "jec",
  "susp",
  "supra",
  "best",
  "miracle",
];

const invalidStartingList = ["ora", "oro"];

// const blacklist_names_extra_ingredient = ["extra", "forte", "plus", "co"];

function constructBlacklist(isNew) {
  if (isNew) return [...blacklist_names];
  return blacklist_names;
}

function nameStartsWithOrIsBlacklistedToken(name, blacklist, errorObject) {
  for (const token of blacklist) {
    if (name.toLowerCase().includes(token.toLowerCase())) {
      errorObject.message = `Drug name: ${name} cannot contains with the following token: ${token}`;
      return true;
    }
  }
}

function nameStartsWithOnly(name, blacklist, errorObject) {
  for (const token of blacklist) {
    if (name.toLowerCase().startsWith(token.toLowerCase())) {
      errorObject.message = `Drug name: ${name} cannot starts with the following token: ${token}`;
      return true;
    }
  }
}

function nameEndsWithBlacklistenToken(name, blacklist, errorObject) {
  for (const token of blacklist) {
    if (name.toLowerCase().endsWith(token.toLowerCase())) {
      errorObject.message = `Drug name: ${name} cannot end with the following token: ${token}`;
      return true;
    }
  }
}
function nameContainsOnlyLetters(name, errorObject) {
  if (
    !/^[a-zA-Z]+( [0-9]+){0,1}((\%){0,1}|((\/|\^)[1-9]{1}[0-9]*){0,1}(\%){0,1})$/.test(
      name
    )
  ) {
    errorObject.message = `Drug name: ${name} must contains only letters or letter space number`;
    return true;
  }
}
export function validateName(name, isNew = true, errorObject) {
  const blacklist = constructBlacklist(isNew);

  // for (const token of name.split(" "))
  if (
    nameStartsWithOrIsBlacklistedToken(name, blacklist, errorObject) ||
    nameEndsWithBlacklistenToken(name, blacklist, errorObject) ||
    nameContainsOnlyLetters(name, errorObject) ||
    nameStartsWithOnly(name, invalidStartingList, errorObject)
  )
    return false;
  return true;
}
