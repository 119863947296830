export const RUN_ACTIONS = "RUN_ACTIONS";
export const RUN_ACTIONS_PENDING = "RUN_ACTIONS_PENDING";
export const RUN_ACTIONS_FULFILLED = "RUN_ACTIONS_FULFILLED";
export const RUN_ACTIONS_REJECTED = "RUN_ACTIONS_REJECTED";

export const GET_VALID_SUBMISSIONS = "GET_VALID_SUBMISSIONS";
export const GET_VALID_SUBMISSIONS_PENDING = "GET_VALID_SUBMISSIONS_PENDING";
export const GET_VALID_SUBMISSIONS_FULFILLED =
  "GET_VALID_SUBMISSIONS_FULFILLED";
export const GET_VALID_SUBMISSIONS_REJECTED = "GET_VALID_SUBMISSIONS_REJECTED";

export const ADD_SUBMISSION_TO_GROUP = "ADD_SUBMISSION_TO_GROUP";
export const ADD_SUBMISSION_TO_GROUP_PENDING =
  "ADD_SUBMISSION_TO_GROUP_PENDING";
export const ADD_SUBMISSION_TO_GROUP_FULFILLED =
  "ADD_SUBMISSION_TO_GROUP_FULFILLED";
export const ADD_SUBMISSION_TO_GROUP_REJECTED =
  "ADD_SUBMISSION_TO_GROUP_REJECTED";

export const REMOVE_SUBMISSION_FROM_GROUP = "REMOVE_SUBMISSION_FROM_GROUP";
export const REMOVE_SUBMISSION_FROM_GROUP_PENDING =
  "REMOVE_SUBMISSION_FROM_GROUP_PENDING";
export const REMOVE_SUBMISSION_FROM_GROUP_FULFILLED =
  "REMOVE_SUBMISSION_FROM_GROUP_FULFILLED";
export const REMOVE_SUBMISSION_FROM_GROUP_REJECTED =
  "REMOVE_SUBMISSION_FROM_GROUP_REJECTED";
