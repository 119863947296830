import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";

export async function warnUserIfTokenExpires(eventName, params) {
  const response = await client.get("auth/userTokenExpired");
  if (response.data.status === "valid") return;
  Swal.fire({
    title:
      "Your session has expired. Please, logout and relogin in order to continue creating submissions.",
    showConfirmButton: true,
    confirmButtonText: "Ok",
    icon: "warning",
  }).then(() => {
    params.history.push("/worklist");
  });
}
