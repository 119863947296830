import React, { useEffect, useState } from "react";
import imag from "assets/images/evers.png";
import { runActions } from "reducers/grouping";
import { connect } from "react-redux";
import _ from "lodash";
import * as certificates from "certificates/index";
import clsx from "clsx";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
// eslint-disable-next-line import/no-anonymous-default-export
const GenerateDocumentForGroup = ({
  group,
  runActions,
  action,
  open,
  setOpen,
  user,
  onActionComplete,
  submissionIds,
}) => {
  const [certificateActiveTab, setCertificateActiveTab] = useState(1);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  let [divRefs, setDivRefs] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const onSubmit = (event) => {
    let meta;
    let documentValues, documentDom;
    if (divRefs && "documentValues" in divRefs) {
      ({ documentValues, ...documentDom } = divRefs);
    } else if (divRefs) {
      ({ ...documentDom } = divRefs);
    }
    if (documentDom && !_.isEmpty(documentDom)) {
      Object.values(documentDom).forEach((divRef) => {
        const el = divRef?.querySelectorAll("textarea");
        var element = document.getElementById("header");
        if (element) element.parentNode.removeChild(element);
        const radioButtons = divRef?.querySelectorAll(
          'input[type="radio"][name="sampling"]:checked'
        );

        let selectInput = divRef?.querySelector("select");
        if (selectInput) {
          selectInput.options[selectInput.selectedIndex].setAttribute(
            "selected",
            "selected"
          );
        }
        if (radioButtons) {
          divRef
            ?.querySelectorAll('input[type="radio"][name="sampling"]:checked')
            .forEach((input) => {
              input.setAttribute("checked", "checked");
            });
        }
        if (el) {
          el.forEach((item) => {
            item.style.borderColor = "Transparent";
            item.style.borderStyle = "none";
            item.style.resize = " none";
          });
        }
      });

      meta = Object.fromEntries(
        Object.entries(documentDom).map(([key, value]) => [
          key,
          value.innerHTML,
        ])
      );
    }
    setSubmitFlag(true);
    setLoading(true);
    runActions({
      groupId: group.groupId,
      submissionId: group.submissions.map((submission) => submission.id),
      actionId: action.id,
      data: {
        meta,
        user,
        values: {
          ...values,
          actionId: action.id,
          groupId: group.groupId,
          stepId: group.groupType.start_step,
        },
      },
      stepId: group.groupType.start_step,
      isSubmissionAction: true,
    })
      .then((res) => {
        if (res.value.data.message === "Success") {
          setLoading(false);
          setOpen(false);

          onActionComplete();
        }
      })
      .catch((error) => {
        setLoading(false);
        setSubmitFlag(false);
      });
  };
  const renderDocumentGeneration = (templateName) => {
    const Template = certificates[templateName];
    return (
      <div
        className={clsx("tab-item-wrapper", {
          active: certificateActiveTab === 1,
        })}
        index={0}
      >
        <Template
          letter={group.submissions}
          groupValues={group?.values}
          values={values}
          setValues={setValues}
          divRef={(element) => {
            return (divRefs[templateName] = element);
          }}
          setDivRef={setDivRefs}
        />
      </div>
    );
  };
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "90%",
        margin: "auto",
        height: "90%",
      }}
    >
      <DialogTitle className="bg-color-darkBlue">
        <span className="titleModal text-white">{action?.title}</span>
      </DialogTitle>
      <DialogContent style={{ height: "50%" }}>
        {renderDocumentGeneration(action?.attributes?.letter?.template)}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "200px" }}
          disabled={submitFlag || loading}
          onClick={onSubmit}
        >
          Submit
          {loading && <ClipLoader color={"var(--primary)"} loading={true} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runActions: (data) => dispatch(runActions(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateDocumentForGroup);
