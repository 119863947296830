import React, { useState } from "react";

import clsx from "clsx";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { getValue } from "utils/GetObjectValues";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  Grid,
  InputLabel,
  InputAdornment,
  Card,
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  TextField,
  FormControl,
  Select,
  TablePagination,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";

import Trend from "react-trend";

import FilterListTwoToneIcon from "@material-ui/icons/FilterListTwoTone";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import SaveTwoToneIcon from "@material-ui/icons/SaveTwoTone";

import ArrowDownwardTwoToneIcon from "@material-ui/icons/ArrowDownwardTwoTone";
import ArrowUpwardTwoToneIcon from "@material-ui/icons/ArrowUpwardTwoTone";
import RadioButtonUncheckedTwoToneIcon from "@material-ui/icons/RadioButtonUncheckedTwoTone";
import RadioButtonCheckedTwoTone from "@material-ui/icons/RadioButtonCheckedTwoTone";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faUserDoctor } from "@fortawesome/free-solid-svg-icons";
library.add(fab, faUserDoctor);
export default function TableStatistic({
  tableName,
  tableData,
  headers,
  endPointName,
  handlePagination,
  count,
  setSkip,
  handleFilterData,
  stateName,
  filters,
  setFilters,
  countState,
  handleResetFilterData,
  search,
  setSearch,
  handleSearchData,
  keySearch,
  rowPerPage,
  setRowPerPage,
  handleRowPerPage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
    setFilters([]);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [status, setStatus] = useState("");

  const handleStatus = (event, value, endPointName) => {
    setStatus(event.target.value);
    addFilterData(
      `${filterData[endPointName].names[type].toLowerCase()}`,
      `%${typeof value === "string" ? value.toLowerCase() : value}%`,
      "like"
    );
  };

  const [type, setType] = useState("");

  const handleType = (event) => {
    setType(event.target.value);
  };

  const [searchOpen, setSearchOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => {
    setSearch(...searchData);
    setSearchOpen(false);
  };
  const [page, setPage] = useState(0);

  const handleChange = (
    event,
    value,
    endPointName,
    stateName,
    setRowPerPage
  ) => {
    handlePagination(
      endPointName,
      value,
      rowPerPage,
      stateName,
      filters,
      searchData,
      setRowPerPage
    );
    setPage(value);
  };
  const addFilterData = (field, value, operator) => {
    let filterObject;
    let filterIndex;

    filterIndex = filters.findIndex((filter) => filter.field === field);
    filterObject = {
      field: field,
      value: typeof value === "string" ? value.toLowerCase() : value,
      operator: operator,
    };

    let newFilters = [...filters];
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObject;
    } else {
      newFilters.push(filterObject);
    }
    setFilters([...newFilters]);
  };
  let filterData = {
    getAllDoctorStatistic: {
      names: ["name", "Specialty", "Segmentation"],
      type: ["text", "text", "text"],
    },
    getAllProductStatistic: {
      names: ["name"],
      type: ["text"],
    },
    getAllDiseaseAreaStatistic: {
      names: ["name"],
      type: ["text"],
    },
    getAllEvents: {
      names: ["name"],
      type: ["text"],
    },
  };
  const getFilterData = (e, endPointName, stateName, countState) => {
    handleFilterData(
      filters,
      endPointName,
      stateName,
      null,
      countState,
      rowPerPage
    );
    setAnchorElFilter(null);
  };
  const cancelFilter = (e, endPointName, stateName, countState) => {
    e.preventDefault();
    setFilters([]);
    handleResetFilterData([], endPointName, stateName, null, countState);
    setAnchorElFilter(null);
  };
  const [searchDataValue, setSearchDateValue] = useState("");
  const [dateValues, setDateValues] = useState({
    from: "",
    to: "",
  });
  let DatesValue = [];
  var obj = {};
  const handleFilterDate = (field, filterValue, operator, endPointName) => {
    obj = { ...dateValues, [field]: filterValue };

    DatesValue = Object.values(obj);
    setDateValues(obj);
    filters.push({
      field: "event_date_from",
      value: DatesValue,
      operator: "between",
    });
    addFilterData("event_date_from", DatesValue, "between");

    //
  };
  const setSearchValue = (value, keySearch) => {
    let filterObject = [];
    if (value) {
      filterObject.push({
        field: keySearch,
        value: `%${typeof value === "string" ? value.toLowerCase() : value}%`,
        operator: "like",
      });
    }

    setSearchData([...filterObject]);
  };
  const handleSearch = (
    e,
    endPointName,
    stateName,
    countState,
    setRowPerPage
  ) => {
    setPage(0);
    handleSearchData(
      searchData,
      endPointName,
      stateName,
      null,
      countState,
      setRowPerPage
    );
  };
  const exportToExcel = (e, data, exportedSheetName, columns) => {
    Swal.fire({
      title: `Do you want to export table to Excel`,
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        let exportedData = [];
        exportedData.push(
          ...data.map((row) => {
            let filteredSubmission = {};
            if (columns.length > 0) {
              for (const col of columns) {
                filteredSubmission[col.name] = getValue(row, col.key);
              }
              return filteredSubmission;
            } else {
              return row;
            }
          })
        );

        const workSheet = XLSX.utils.json_to_sheet(exportedData);

        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "submissions");

        XLSX.write(workBook, { bookType: "xls", type: "buffer" });
        XLSX.write(workBook, { bookType: "xls", type: "binary" });
        XLSX.writeFile(workBook, `${exportedSheetName}.xls`);
      }
    });
  };
  //   setSearchData({ filters: filterObject });
  // };
  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-0 text-black">
              {tableName}
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <Button
                onClick={handleClickFilter}
                variant="text"
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill"
              >
                <FilterListTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorElFilter}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElFilter)}
                classes={{ list: "p-0" }}
                onClose={handleCloseFilter}
              >
                <div className="dropdown-menu-xxl overflow-hidden p-0">
                  <div className="p-3">
                    <Grid
                      container
                      className="dateStyle"
                      style={{ width: "98%" }}
                    >
                      <Grid item xs={6}>
                        <span className="spanStyle">From</span>
                        <TextField
                          id="date"
                          type="date"
                          defaultValue={new Date()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event, date) => {
                            event.persist();

                            handleFilterDate(
                              "from",
                              event.target.value,
                              "from",
                              endPointName
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <span className="spanStyle">To</span>
                        <TextField
                          id="date"
                          type="date"
                          defaultValue={new Date()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event, date) => {
                            event.persist();
                            let currentDate = new Date(event.target.value);
                            currentDate.setDate(currentDate.getDate());
                            handleFilterDate(
                              "to",
                              currentDate.toISOString(),
                              "to",
                              endPointName
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="divider" />
                  <div className="p-3 text-center bg-secondary">
                    <Button
                      className="btn-primary"
                      size="small"
                      onClick={(e) =>
                        getFilterData(e, endPointName, stateName, countState)
                      }
                    >
                      Filter results
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <List
                          component="div"
                          className="nav-neutral-danger flex-column p-0"
                        >
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            onClick={(e) =>
                              cancelFilter(
                                e,
                                endPointName,
                                stateName,
                                countState
                              )
                            }
                          >
                            <DeleteTwoToneIcon className="mr-2" />
                            <span>Reset Filter</span>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Menu>
            </div>

            <div>
              <Button
                onClick={handleClick}
                variant="text"
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill"
              >
                <SettingsTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                classes={{ list: "p-0" }}
                onClose={handleClose}
              >
                <div className="dropdown-menu-lg overflow-hidden p-0">
                  <div className="font-weight-bold px-4 pt-3">Results</div>
                  <List
                    component="div"
                    className="nav-neutral-first nav-pills-rounded flex-column p-2"
                  >
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setRowPerPage(10);
                        setSkip(0);
                        setPage(0);
                        handleRowPerPage(
                          endPointName,
                          1,
                          10,
                          stateName,
                          filters,
                          searchData,
                          setRowPerPage
                        );
                      }}
                    >
                      <div className="mr-2">
                        {rowPerPage === 10 ? (
                          <RadioButtonCheckedTwoTone />
                        ) : (
                          <RadioButtonUncheckedTwoToneIcon />
                        )}
                      </div>
                      <span className="font-size-md">
                        <b>10</b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setRowPerPage(20);
                        setSkip(0);
                        setPage(0);
                        handleRowPerPage(
                          endPointName,
                          1,
                          20,
                          stateName,
                          filters,
                          searchData,
                          setRowPerPage
                        );
                      }}
                    >
                      <div className="mr-2">
                        {rowPerPage === 20 ? (
                          <RadioButtonCheckedTwoTone />
                        ) : (
                          <RadioButtonUncheckedTwoToneIcon />
                        )}
                      </div>
                      <span className="font-size-md">
                        <b>20</b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setRowPerPage(30);
                        setSkip(0);
                        setPage(0);
                        handleRowPerPage(
                          endPointName,
                          1,
                          30,
                          stateName,
                          filters,
                          searchData,
                          setRowPerPage
                        );
                      }}
                    >
                      <div className="mr-2">
                        {rowPerPage === 30 ? (
                          <RadioButtonCheckedTwoTone />
                        ) : (
                          <RadioButtonUncheckedTwoToneIcon />
                        )}
                      </div>
                      <span className="font-size-md">
                        <b>30</b> results per page
                      </span>
                    </ListItem>
                  </List>
                  <div className="divider" />
                </div>
              </Menu>
            </div>
          </div>
        </div>

        <div className="divider" />
        <div className="px-4 py-3">
          <div
            className="d-flex align-items-center"
            style={{ justifyContent: "flex-end", marginBottom: "5px" }}
          >
            <Button
              id="exportToExcel"
              onClick={(e) => exportToExcel(e, tableData, tableName, headers)}
              variant="contained"
              color="primary"
            >
              Export To Excel
            </Button>
          </div>
          <div
            className={clsx(
              "search-wrapper search-wrapper--alternate search-wrapper--grow",
              { "is-active": searchOpen }
            )}
          >
            <TextField
              variant="outlined"
              size="small"
              id="input-with-icon-textfield22-2"
              onFocus={openSearch}
              onBlur={closeSearch}
              style={{ cursor: "pointer" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={headers[0].name}
              onChange={(event) => {
                event.persist();
                setSearchDateValue(event.target.value);
                setSearchValue(event.target.value, keySearch);
              }}
              onClick={(e) =>
                handleSearch(
                  e,
                  endPointName,
                  stateName,
                  countState,
                  setRowPerPage
                )
              }
            />
          </div>
        </div>
        <div className="divider" />
        <div className="pt-4 px-4">
          <Table className="table table-alternate-spaced  mb-0">
            <thead className="bg-white font-size-sm text-uppercase">
              <tr>
                {headers.map((header) => (
                  <th className="bg-white align-items-center">{header.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => (
                <tr>
                  {headers.map((header) => (
                    <td className="px-4">
                      <div className="d-flex align-items-center">
                        {header.icon && (
                          <div className="d-30 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-primary">
                            <FontAwesomeIcon icon={["fas", header.icon]} />
                          </div>
                        )}
                        <div>
                          <div
                            className={`${
                              header.icon ? "font-weight-bold" : ""
                            }`}
                          >
                            {row[header.key] || 0}
                          </div>
                          <div className="opacity-7">
                            {row[header?.otherKey]}
                          </div>
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}

              <tr className="divider"></tr>
            </tbody>
          </Table>
        </div>
        {/* <div className="p-4 d-flex justify-content-center">
          <Pagination
            className="pagination-primary"
            count={Math.ceil(count / rowPerPage)}
            page={page}
            onChange={(e, value) =>
              handleChange(e, value, endPointName, stateName)
            }
          />
        </div> */}
        <div
          className="p-4 d-flex "
          style={{ justifyContent: "flex-end", borderRadius: "20px" }}
        >
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={(e, value) =>
              handleChange(e, value, endPointName, stateName, setRowPerPage)
            }
            rowsPerPage={rowPerPage}
            labelRowsPerPage={""}
          />
        </div>
      </Card>
    </>
  );
}
