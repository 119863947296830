/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  Card,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "../../style.scss";
import StepInputs from "../StepInputs";
import { handleClick } from "../../utils";
import AssignModal from "../AssignModal";
import {
  handleTaskAssignment,
  setTaskDetails,
} from "reducers/details/detailsAction";
import { getUsers } from "reducers/details/detailsAction";
import { getValue } from "utils/GetObjectValues";
import { connect } from "react-redux";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core/styles";
import CardInfo from "components/CardInfo";
import client from "api/apiAuth/guestClient";
import { Switch } from "@mui/material";

const submissionInfoConfig = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/submissionInfo`);
const SubmissionInfo = (props) => {
  const {
    task,
    partIndex,
    currentUser,
    takeActionFlag,
    handleTaskAssignment,
    users,
    getUsers,
    attachmentsUnderReview,
    getTaskDetails,
    getTimeline,
    uploadedAttachments,
    saveSubmissionsValuesLoader,
    setTaskDetails,
  } = props;
  const [userIsWorking, setUserIsWorking] = useState(task.is_user_working);

  const CustomButton = withStyles({
    root: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
  })(Button);

  const TextOnlyTooltip = withStyles({
    tooltip: {
      color: "black",
      backgroundColor: "white",
    },
  })(Tooltip);

  const [selectedUser, setSelectedUser] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stepInputFlag, setStepInputFlag] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [componentSections, setComponentsSections] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const history = useHistory();
  const qrCodeRef = useRef();
  const actionsProperties = {
    ...props,
    handleAssignClick: () => {
      props.setModalInfo({
        question: "Do you want to assign yourself  this request?",
        message: "If you assign yourself on it, you should review it",
      });
      props.setConfirmationModal(true);
    },
    toggleUserWorkingFlag: async () => {
      try {
        setTaskDetails({ ...task, is_user_working: !userIsWorking });
        setUserIsWorking(!userIsWorking);
        await client.patch(`generic/update?model=Task&id=${task.id}`, {
          attributes: { is_user_working: !userIsWorking },
          where: { id: task.id },
        });
      } catch (error) {
        setUserIsWorking(!userIsWorking);
      }
    },
    openTakeActionModal: function () {
      props.openModal({ open: true });
    },
    openEditForm: function () {
      history.push(
        `/programs/${task.submission.form_id}/submissions/${task.submission.id}/edit`
      );
    },
    openSlotModal: function () {
      props.setSlotsModal(true);
    },
    formRedirect: () => {
      history.push(
        `/programs/${task.submission.workflow.id + 1}/${task.submission.id}`
      );
    },
    dequeueSubmission: async () => {
      try {
        await client.post("/submission/dequeueSubmissions", {
          selectedRows: [{ taskId: task.id }],
        });
        Swal.fire({
          title: "Done",
          icon: "success",
          timer: 3000,
        }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        Swal.fire({
          title: "error",
          text: "server error",
          icon: "error",
          timer: 3000,
        });
      }
    },
    handleRedirect: () => {
      history.push(`/drugnames/${task.submission.id}/${task.id}`);
    },
    downloadQrCode: () => {
      qrCodeRef.current.click();
    },
    redirectToSoftFilesForm: () => {
      history.push(`/programs/2/${task.submission.id}`);
    },
    redirectToHardFilesForm: () => {
      history.push(`/programs/3/${task.submission.id}`);
    },
  };
  const OpenModal = () => {
    setOpenModal(true);
  };
  const handleAssign = () => {
    setLoading(true);
    handleTaskAssignment({
      filter: {
        step_id: task?.step.id,
        submission_id: task?.submission.id,
      },
      currentUser,
      user_id: selectedUser,
      submission_id: task?.submission.id,
    }).then(() => {
      Swal.fire({
        title: "Done",
        text: "The task was assigned successfully",
        icon: "success",
        timer: 3000,
      }).then(() => {
        setOpenModal(false);
        setLoading(false);
        getTaskDetails({ submission_id: task?.submission.id });
        getTimeline({ submission_id: task?.submission.id });
      });
    });
  };
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };
  useEffect(() => {
    if (task) {
      let filteredUploadedAttachments = Object.entries(uploadedAttachments);
      filteredUploadedAttachments = filteredUploadedAttachments.filter(
        ([key, value]) => (value ? !("document" in value) : false)
      );
      filteredUploadedAttachments = Object.fromEntries(
        filteredUploadedAttachments
      );
      let configs = submissionInfoConfig
        .config({
          task: {
            ...task,
            submission: {
              ...task.submission,
              attachments: task.submission.attachments?.filter(
                (attachment) => !attachment.document.isComment
              ),
            },
          },
          currentUser: currentUser,
          taskUsers: task?.users,
          takeActionFlag,
          attachmentsUnderReview,
          uploadedAttachments: filteredUploadedAttachments,
          stepInputFlag: stepInputFlag,
          saveSubmissionsValuesLoader,
        })
        .sections.map((section) => {
          section.actions = section.actions.map((action) => {
            if (action.constraints) {
              return {
                ...action,
                constraints: applyDetailsConstraints(action.constraints),
              };
            } else {
              return action;
            }
          });
          section.attributes = section.attributes.map((attribute) => {
            if (attribute.constraints) {
              return {
                ...attribute,
                constraints: applyDetailsConstraints(attribute.constraints),
              };
            } else {
              return attribute;
            }
          });
          return section;
        });
      setComponentsSections(configs);
    }
  }, [
    task,
    attachmentsUnderReview,
    takeActionFlag,
    stepInputFlag,
    uploadedAttachments,
    saveSubmissionsValuesLoader,
  ]);
  useEffect(() => {
    if (
      task?.task_status === "Pending" &&
      task?.submission.status === "Pending" &&
      task?.step?.assignees?.length > 0
    )
      getUsers({
        assignees: task.step.assignees,
        submission_id: task.submission.id,
        step_id: task.step.id,
      });
    //get All user with the same role
    "users" in task && getAllUsers(2, task);
    if (task && task?.submission?.attachments)
      setQrCode(
        task.submission.attachments.find(
          (attachment) => attachment.document.id === 117
        )
      );
  }, [task]);
  const getAllUsers = async (roleId) => {
    let { first_approver, second_approver } = task.submission.values;
    const res = await client.get("workflow/getMultiDistributedUsers", {
      params: {
        include: {
          association: "roles",
          where: {
            id: roleId,
          },
        },
        where: {
          id: { operator: "not in", value: [first_approver, second_approver] },
        },
        model: "User",
      },
    });
    setAllUsers(res.data.result);
  };
  const actions = {
    assignUserSameRole: (action) => {
      // getAllUsers(action.roleId, task);
      return (
        <>
          {task && "users" in task && (
            <Grid
              item
              sm={action.gridSize}
              xs={12}
              container
              spacing={2}
              align="left"
              className="mt-1"
            >
              <AssignModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                users={
                  allUsers
                  // task?.is_assigned
                  //   ? users.filter((user) => user.id !== task.users[0].id)
                  //   : users
                }
                task={task}
                selectedUser={selectedUser}
                handleChange={handleChange}
                handleAssign={handleAssign}
                loading={loading}
                setLoading={setLoading}
              />

              {task &&
                "users" in task &&
                task.users?.length > 0 &&
                task.users.some((e) => e.id === currentUser.id) && (
                  <Grid item sm={6}>
                    <Button
                      className="assignButton btn-transition-none mb-4  btn-lg md-4 float-left"
                      variant="contained"
                      onClick={OpenModal}
                    >
                      {task.users.some((e) => e.id === currentUser.id)
                        ? "Reassign"
                        : "Assign"}
                    </Button>
                  </Grid>
                )}
            </Grid>
          )}
        </>
      );
    },
    assign: (action) => (
      <>
        <Grid
          item
          sm={action.gridSize}
          xs={12}
          container
          spacing={2}
          align="left"
          className="mt-1"
        >
          <AssignModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            users={
              users
              // task?.is_assigned
              //   ? users.filter((user) => user.id !== task.users[0].id)
              //   : users
            }
            task={task}
            selectedUser={selectedUser}
            handleChange={handleChange}
            handleAssign={handleAssign}
          />
          <Grid item sm={6}>
            <Button
              className="assignButton btn-transition-none mb-4  btn-lg md-4 float-left"
              variant="contained"
              onClick={OpenModal}
            >
              {task.is_assigned ? "Reassign" : "Assign"}
            </Button>
          </Grid>
        </Grid>
      </>
    ),
    userWorking: () => (
      <Grid
        item
        xs={12}
        container
        align="left"
        className="mr-4"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <FormControlLabel
          control={
            <Switch
              className="switch-medium  toggle-switch-success"
              checked={userIsWorking}
              onChange={() => actionsProperties.toggleUserWorkingFlag()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
        />
      </Grid>
    ),
  };
  return (
    <Card key={partIndex} className="card-box">
      {componentSections?.map((section) => (
        <Grid item xs={12} className="contentInfo" key={section.id}>
          <Grid container className="containerStyle p-2" spacing={2}>
            {/* basic Information attributes */}
            {task && <CardInfo attributes={section.attributes} data={task} />}
            {<StepInputs task={task} setStepInputFlag={setStepInputFlag} />}

            {/* basic Information actions */}
            {section?.actions.map((action, index) => {
              let ButtonIcon = action.icon;
              let finalDisabledReasons =
                action.constraints.finalDisabledReasons;
              let disabledReason;
              if (finalDisabledReasons.length) {
                disabledReason = finalDisabledReasons[0];
              }
              return (
                action.constraints.display &&
                (action.type !== "component" ? (
                  <Grid
                    item
                    md={action.gridSize || 2}
                    xs={2}
                    key={index}
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextOnlyTooltip
                      title={action.constraints.disable ? disabledReason : ""}
                    >
                      <CustomButton
                        xs={3}
                        className={action.classes}
                        disabled={action.constraints.disable}
                        onClick={(e) =>
                          handleClick(e, action, actionsProperties)
                        }
                      >
                        {action.label}
                        {action.icon && <ButtonIcon />}
                      </CustomButton>
                    </TextOnlyTooltip>
                    <a
                      ref={qrCodeRef}
                      className="d-none"
                      href={`${process.env.REACT_APP_SERVER_URL}attachment/download?id=${qrCode?.id}`}
                      download
                    />
                  </Grid>
                ) : (
                  <React.Fragment key={index}>
                    {actions[action.name](action)}
                  </React.Fragment>
                ))
              );
            })}
            {task?.customComponents?.map((comp) => (
              <Grid
                item
                md={2}
                xs={2}
                key={comp.url}
                style={{
                  paddingTop: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  xs={3}
                  variant="contained"
                  className="assignButton"
                  onClick={() =>
                    history.push(
                      `/${comp.url}/${task.submission.id}/${task.id}`
                    )
                  }
                >
                  {comp.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    users: state.details.users,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
    uploadedAttachments: state.details.uploadedAttachments,
    saveSubmissionsValuesLoader: state.details.saveSubmissionsValuesLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
    getUsers: (params) => dispatch(getUsers(params)),
    setTaskDetails: (task) => dispatch(setTaskDetails(task)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionInfo);
