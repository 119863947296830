import React from "react";
import { Grid, Paper, InputBase, IconButton } from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
const SearchBar = ({ handleSearch, applySearch, fieldName }) => {
  const useStyles = makeStyles((theme) => ({
    input: {
      marginLeft: theme.spacing(1),
      width: "92%",
      flex: 1,
      boxShadow: "none !important",
    },
  }));
  const classes = useStyles();

  return (
    <Grid item md={11} lg={12} xs={11} className="mt-3 ml-3 mr-3">
      <div className={clsx("d-flex align-items-center search-bar-div")}>
        <Paper
          style={{ boxShadow: "none ", width: "50%" }}
          component="form"
          className=" search-bar d-flex"
        >
          <InputBase
            className={classes.input}
            placeholder={fieldName}
            inputProps={{ "aria-label": "search..." }}
            onChange={(event) => {
              event.persist();
              handleSearch(event.target.value);
            }}
          />
          <IconButton
            type="submit"
            aria-label="search"
            onClick={(e) => applySearch(e)}
          >
            <SearchTwoToneIcon />
          </IconButton>
        </Paper>
      </div>
    </Grid>
  );
};

export default SearchBar;
