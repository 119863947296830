import React, { useState, useEffect } from "react";
import { Card, Grid, Divider, CardHeader } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./index.module.css";
import client from "api/apiAuth/guestClient";
import DonutsChart from "../../components/Dashboard/DonutsChart";
import SpinnerLoader from "components/SpinnerLoader";
import { connect } from "react-redux";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faList } from "@fortawesome/free-solid-svg-icons";
import TableStatistic from "./tableStatistic";
import Chart from "react-apexcharts";
import ChartApex from "./chartApex";
const useStyles = makeStyles({
  rootCard: {
    backgroundColor: "#F4F9FF",
    width: " 87%",
    boxShadow: "none !important",
    height: "100%",
  },
});
library.add(fab, faCheckSquare, faList);
const Dashboard = ({ forms }) => {
  const classes = useStyles();
  const [statCards, setStatCards] = useState([]);
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState([]);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [countProduct, setCountProduct] = useState(0);
  const [countDiseaseArea, setCountDiseaseArea] = useState(0);
  const [countEvent, setCountEvent] = useState(0);
  const [statDoctors, setStatDoctors] = useState([]);
  const [statProducts, setStatProducts] = useState([]);
  const [statDiseaseAreas, setStatDiseaseAreas] = useState([]);
  const [statEvents, setStatEvents] = useState([]);
  const [statTopDiseaseEngagements, setStatTopDiseaseEngagements] = useState(
    []
  );
  const [rowPerPageDoctor, setRowPerPageDoctor] = useState(10);
  const [rowPerPageProduct, setRowPerPageProduct] = useState(10);
  const [rowPerPageDiseaseArea, setRowPerPageDiseaseArea] = useState(10);
  const [rowPerPageEvent, setRowPerPageEvent] = useState(10);
  const [statTopDoctorsEngagements, setStatTopDoctorsEngagements] = useState(
    []
  );
  const [statTopDiseaseSpending, setStatTopDiseaseSpending] = useState([]);
  const [statTopDoctorsSpending, setStatTopDoctorsSpending] = useState([]);
  const [programsStatistics, setProgramsStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    const fetchStatistics = async () => {
      try {
        const response = await client.get("/dashboard/getStatistics");

        if (isMounted) {
          setStatCards(response.data);
          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const fetchDoctorStatistics = async () => {
      try {
        const response = await client.get("/dashboard/getAllDoctorStatistic", {
          params: {
            limit: 10,
            skip: skip,
          },
        });
        if (isMounted) {
          setStatDoctors(response.data.data);
          setCount(response.data.count);
          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const fetchProductStatistics = async () => {
      try {
        const response = await client.get("/dashboard/getAllProductStatistic", {
          params: {
            limit: 10,
            skip: skip,
          },
        });
        if (isMounted) {
          setStatProducts(response.data.data);
          setCountProduct(response.data.count);
          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const fetchDiseaseAreaStatistics = async () => {
      try {
        const response = await client.get(
          "/dashboard/getAllDiseaseAreaStatistic",
          {
            params: {
              limit: 10,
              skip: skip,
            },
          }
        );
        if (isMounted) {
          setStatDiseaseAreas(response.data.data);
          setCountDiseaseArea(response.data.count);
          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const fetchEventStatistics = async () => {
      try {
        const response = await client.get("/dashboard/getAllEvents", {
          params: {
            limit: 10,
            skip: skip,
          },
        });
        if (isMounted) {
          setStatEvents(response.data.data);
          setCountEvent(response.data.count);
          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const fetchTop5DiseaseArea = async () => {
      try {
        const response = await client.get("/dashboard/getTopDiseaseArea", {
          params: {
            limit: 10,
            skip: skip,
          },
        });
        if (isMounted) {
          setStatTopDiseaseEngagements(response.data.Top5Engagement);
          setStatTopDiseaseSpending(response.data.Top5Spending);

          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const fetchTop5Doctor = async () => {
      try {
        const response = await client.get("/dashboard/getTopDoctors", {
          params: {
            limit: 10,
            skip: skip,
          },
        });
        if (isMounted) {
          setStatTopDoctorsEngagements(response.data.Top5Engagement);
          setStatTopDoctorsSpending(response.data.Top5Spending);

          // setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchStatistics();
    fetchDoctorStatistics();
    fetchProductStatistics();
    fetchDiseaseAreaStatistics();
    fetchEventStatistics();
    fetchTop5DiseaseArea();
    fetchTop5Doctor();
    return () => {
      isMounted = false;
    };
  }, []);

  const renderStatCards = (cards) => {
    return cards.map((card, i) => (
      <Grid item xl={4} md={4}>
        <Card className="card-box border-0 shadow-first-sm p-4 mb-2 mr-2">
          <div className="d-flex align-items-center">
            <div className="d-40 btn-icon rounded-circle bg-first text-white text-center font-size-lg mr-3">
              {/* <FontAwesomeIcon icon="fa-solid fa-keyboard" /> */}
              <FontAwesomeIcon icon={["fas", card?.icon]} />
            </div>
            <div className="text-black-50">{card.title}</div>
          </div>
          <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
            <div>{card[card.title]}</div>
          </div>
        </Card>
      </Grid>
    ));
  };
  let doctorHeader = [
    {
      name: "name",
      key: "name",
      otherKey: "specialty",
      icon: "user-doctor",
    },
    {
      name: "segmentation",
      key: "segmentation",
    },
    {
      name: "Engagements no.",
      key: "noEngagements",
    },
    {
      name: "Events no.",
      key: "noEvents",
    },
    {
      name: "Total Spending",
      key: "totalSpending",
    },
  ];
  let productHeader = [
    {
      name: "name",
      key: "name",
    },
    {
      name: "Engagements no.",
      key: "noEngagements",
    },
    {
      name: "Events no.",
      key: "noEvents",
    },
    {
      name: "Total Spending",
      key: "totalSpending",
    },
  ];
  let diseaseAreaHeader = [
    {
      name: "name",
      key: "name",
    },
    {
      name: "Engagements no.",
      key: "noEngagements",
    },
    {
      name: "Events no.",
      key: "noEvents",
    },
    {
      name: "Total Spending",
      key: "totalSpending",
    },
  ];
  let eventHeader = [
    {
      name: "Event ID",
      key: "event_id",
    },
    {
      name: "Event Name",
      key: "submissionName",
    },
    {
      name: "Engagements no.",
      key: "noEngagements",
    },
    {
      name: "Total Spending",
      key: "totalSpending",
    },
  ];
  const handleRowPerPage = async (
    endPointName,
    value,
    rowPerPageNumber,
    stateName,
    filterData,
    searchData,
    rowPerPageName
  ) => {
    try {
      const response = await client.get(`/dashboard/${endPointName}`, {
        params: {
          limit: rowPerPageNumber,
          skip: skip,
          where: { search: [...searchData], filters: [...filterData] },
        },
      });
      //  setSkip(rowPerPageNumber * value - 10);
      stateName(response.data.data);
      rowPerPageName(rowPerPageNumber);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePagination = async (
    endPointName,
    value,
    rowPerPageNumber,
    stateName,
    filterData,
    searchData,
    rowPerPageName
  ) => {
    try {
      const response = await client.get(`/dashboard/${endPointName}`, {
        params: {
          limit: rowPerPageNumber,
          skip: skip ? rowPerPageNumber * value : skip + 10,
          where: { search: [...searchData], filters: [...filterData] },
        },
      });
      setSkip(rowPerPageNumber * value);
      stateName(response.data.data);
      rowPerPageName(rowPerPageNumber);
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilterData = async (
    filtersData,
    endPointName,
    stateName,
    field,
    countState,
    rowPerPageNumber
  ) => {
    try {
      const response = await client.get(`/dashboard/${endPointName}`, {
        params: {
          where: { filters: [...filtersData] },
          limit: rowPerPageNumber,
          skip: 0,
        },
      });

      setSkip(skip ? rowPerPageNumber * skip : skip + 10);
      if (field) {
        stateName(response.data[field]);
      } else {
        stateName(response.data.data);
        countState(response.data.count);
      }
      // setStatDoctors(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSearchData = async (
    filtersData,
    endPointName,
    stateName,
    field,
    countState,
    setRowPerPage
  ) => {
    try {
      const response = await client.get(`/dashboard/${endPointName}`, {
        params: {
          where: { search: [...filtersData] },
          limit: 10,
          skip: 0,
        },
      });
      setRowPerPage(10);
      setSkip(0);
      if (field) {
        stateName(response.data[field]);
      } else {
        stateName(response.data.data);
        countState(response.data.count);
      }
      // setStatDoctors(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleResetFilterData = async (
    filtersData,
    endPointName,
    stateName,
    field,
    countState
  ) => {
    try {
      const response = await client.get(`/dashboard/${endPointName}`, {
        params: {
          limit: 10,
          skip: 0,
          where: { filters: [...filtersData] },
        },
      });
      if (field) {
        stateName(response.data[field]);
      } else {
        stateName(response.data.data);
        countState(response.data.count);
      }
      // setStatDoctors(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <Grid>
          <div className="mb-spacing-6">
            <Grid container style={{ justifyContent: "space-between" }}>
              {renderStatCards(statCards)}
            </Grid>
          </div>
          <Grid container spacing={6}>
            <Grid item lg={6}>
              <ChartApex
                xaxis={statTopDiseaseEngagements}
                keyName={"noEngagements"}
                headerName={"Top 5 disease areas reference by engagements"}
                keyXaxis={"name"}
                endPointName={"getTopDiseaseArea"}
                handleFilterData={handleFilterData}
                filters={filters}
                setFilters={setFilters}
                stateName={setStatTopDiseaseEngagements}
                field={"Top5Engagement"}
                handleResetFilterData={handleResetFilterData}
              />

              <ChartApex
                xaxis={statTopDiseaseSpending}
                keyName={"totalSpending"}
                headerName={"Top 5 disease areas reference by spending"}
                keyXaxis={"name"}
                endPointName={"getTopDiseaseArea"}
                handleFilterData={handleFilterData}
                filters={filters}
                setFilters={setFilters}
                stateName={setStatTopDiseaseSpending}
                field={"Top5Spending"}
                handleResetFilterData={handleResetFilterData}
              />
            </Grid>
            <Grid item lg={6}>
              <ChartApex
                xaxis={statTopDoctorsEngagements}
                keyName={"noEngagements"}
                headerName={"Top 5 doctors reference by engagements"}
                keyXaxis={"doctor.name"}
                endPointName={"getTopDoctors"}
                handleFilterData={handleFilterData}
                filters={filters}
                setFilters={setFilters}
                stateName={setStatTopDoctorsEngagements}
                field={"Top5Engagement"}
                handleResetFilterData={handleResetFilterData}
              />

              <ChartApex
                xaxis={statTopDoctorsSpending}
                keyName={"totalSpending"}
                headerName={"Top 5 doctors reference by spending"}
                keyXaxis={"doctor.name"}
                endPointName={"getTopDoctors"}
                handleFilterData={handleFilterData}
                filters={filters}
                setFilters={setFilters}
                stateName={setStatTopDoctorsSpending}
                field={"Top5Spending"}
                handleResetFilterData={handleResetFilterData}
              />
            </Grid>
          </Grid>
          <Grid>
            <TableStatistic
              tableName={"Doctor Statistic"}
              tableData={statDoctors}
              headers={doctorHeader}
              endPointName={"getAllDoctorStatistic"}
              handlePagination={handlePagination}
              count={count}
              setSkip={setSkip}
              handleFilterData={handleFilterData}
              stateName={setStatDoctors}
              filters={filters}
              setFilters={setFilters}
              countState={setCount}
              handleResetFilterData={handleResetFilterData}
              search={search}
              setSearch={setSearch}
              handleSearchData={handleSearchData}
              keySearch={"name"}
              rowPerPage={rowPerPageDoctor}
              setRowPerPage={setRowPerPageDoctor}
              handleRowPerPage={handleRowPerPage}
            />
          </Grid>
          <Grid>
            <TableStatistic
              tableName={"Product Statistic"}
              tableData={statProducts}
              headers={productHeader}
              endPointName={"getAllProductStatistic"}
              handlePagination={handlePagination}
              count={countProduct}
              setSkip={setSkip}
              handleFilterData={handleFilterData}
              stateName={setStatProducts}
              filters={filters}
              setFilters={setFilters}
              countState={setCountProduct}
              handleResetFilterData={handleResetFilterData}
              search={search}
              setSearch={setSearch}
              handleSearchData={handleSearchData}
              keySearch={"name"}
              rowPerPage={rowPerPageProduct}
              setRowPerPage={setRowPerPageProduct}
              handleRowPerPage={handleRowPerPage}
            />
          </Grid>
          <Grid>
            <TableStatistic
              tableName={"DiseaseArea Statistic"}
              tableData={statDiseaseAreas}
              headers={diseaseAreaHeader}
              endPointName={"getAllDiseaseAreaStatistic"}
              handlePagination={handlePagination}
              count={countDiseaseArea}
              setSkip={setSkip}
              handleFilterData={handleFilterData}
              stateName={setStatDiseaseAreas}
              filters={filters}
              setFilters={setFilters}
              countState={setCountDiseaseArea}
              handleResetFilterData={handleResetFilterData}
              search={search}
              setSearch={setSearch}
              handleSearchData={handleSearchData}
              keySearch={"name"}
              rowPerPage={rowPerPageDiseaseArea}
              setRowPerPage={setRowPerPageDiseaseArea}
              handleRowPerPage={handleRowPerPage}
            />
          </Grid>
          <Grid>
            <TableStatistic
              tableName={"Events Statistic"}
              tableData={statEvents}
              headers={eventHeader}
              endPointName={"getAllEvents"}
              handlePagination={handlePagination}
              count={countEvent}
              setSkip={setSkip}
              handleFilterData={handleFilterData}
              stateName={setStatEvents}
              filters={filters}
              setFilters={setFilters}
              countState={setCountEvent}
              handleResetFilterData={handleResetFilterData}
              search={search}
              setSearch={setSearch}
              handleSearchData={handleSearchData}
              keySearch={"file_code"}
              rowPerPage={rowPerPageEvent}
              setRowPerPage={setRowPerPageEvent}
              handleRowPerPage={handleRowPerPage}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
  };
};
export default connect(mapStateToProps, null)(Dashboard);
