import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Grid, Card } from "@material-ui/core";
import lodash from "lodash";
import BasicTable from "./pocaTable";

import config from "./config";
import { saveNamingValuesAndSubmit } from "../../../../reducers/details/detailsAction";
import { handleClick } from "pages/EdaDetails/utils";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import Swal from "sweetalert2";
import client from "api/apiAuth/guestClient";
const rolesLevel = ["Junior Naming", "Senior Naming", "Unit Manager Naming"];

function PocaTest({ task, selectedTab, currentUser, saveNaming }) {
  const [tableData, setTableData] = useState(
    task.submission.values.naming.drugs
  );
  const [tableHeader, setTableHeader] = useState([]);
  const [actions, setActions] = useState([]);

  let allConfigs;
  const mapper = {
    currentUser,
    taskUsers: task?.users,
    task,
    selectedTab,
  };
  allConfigs = config(mapper).find((tab) => tab.tabId === selectedTab?.id);
  const [finalList, setFinalList] = useState(tableData);

  useEffect(() => {
    async function fetchRecordingsForTableData() {
      const tableDataClone = lodash.cloneDeep(tableData);
      for (let i = 0; i < tableDataClone.length; i++) {
        if (tableDataClone[i].recording) {
          const download = await client.get("attachment/getFile", {
            params: { id: tableDataClone[i].recording },
            responseType: "arraybuffer",
          });
          tableDataClone[i].recording = window.URL.createObjectURL(
            new Blob([download.data], {
              type: "audio/webm;codecs:vp9",
            })
          );
        }
      }
      setTableData(tableDataClone);
    }
    fetchRecordingsForTableData();
  }, []);
  useEffect(() => {
    setActions(
      allConfigs.sections[0].actions.map((action) => {
        if (action.constraints) {
          return {
            ...action,
            constraints: applyDetailsConstraints(action.constraints),
          };
        } else {
          return {
            ...action,
            constraints: { display: true, disable: false },
          };
        }
      })
    );
  }, [selectedTab]);

  const actionProperties = {
    saveNamingValues: async () => {
      let reviewerList = finalList.filter((item) => typeof item == "object");
      const drug = reviewerList.map((item) => {
        const cpy = lodash.cloneDeep(item);
        cpy.allDecisions = {
          ...cpy.allDecisions,
          [currentUser.roles[0].name]: cpy.decision ? cpy.decision : false,
        };
        delete cpy.recording;
        cpy.decision = "reject";
        return cpy;
      });

      try {
        await saveNaming({
          submissionId: task.submission.id,
          drug: drug,
          taskId: task.id,
          transitionId: task.step.transitions[0].id,
          saveOnly: true,
        });
        Swal.fire({
          text: "Decision Saved Successfully",
          icon: "success",
        });
      } catch (error) {
        new Swal({
          title: "server error",
          text: "server error",
          icon: "error",
          dangerMode: true,
        });
      }
    },
  };

  useEffect(() => {
    setTableHeader(allConfigs.sections[1].tableHeader);
  }, []);

  const handleAcceptance = (value, opinion, index) => {
    const newArray = [...finalList];
    newArray[index] = {
      ...newArray[index],
      decision: opinion,
      finalDecision: opinion,
      allDecisions: {
        ...tableData[index].allDecisions,
        [currentUser.roles[0].name]: opinion,
      },
    };
    setFinalList(newArray);
  };

  useEffect(() => {
    setFinalList(tableData);
  }, [tableData]);

  const handleComment = (comment, index) => {
    const newArray = [...finalList];
    newArray[index] = {
      ...newArray[index],
      [`${currentUser.roles[0].name}_comment`]: comment,
    };
    setFinalList([...newArray]);
  };

  let newTableHeader = tableHeader.filter((header) => {
    if (
      task.submission.values?.line_extension &&
      header.name == "Similarity Score"
    ) {
      return false;
    }
    if ("targetUsers" in header) {
      return (
        header.targetUsers.map((user) => user.id).includes(currentUser.id) &&
        task.is_assigned &&
        (header.exceptRole
          ? !currentUser.roles
              .map((role) => role.name)
              .includes(header.exceptRole)
          : true)
      );
    } else {
      if (header.exceptRole) {
        return !currentUser.roles
          .map((role) => role.name)
          .includes(header.exceptRole);
      } else {
        return true;
      }
    }
  });

  return (
    <Grid container spacing={6}>
      <Grid item container style={{ direction: "rtl", padding: 0 }}>
        <Grid xs={6} md={3} style={{ textAlign: "center" }}>
          {actions?.map((action, index) => {
            return (
              <React.Fragment key={index}>
                {action.constraints?.display && (
                  <Button
                    className="mx-1 px-4 py-2"
                    size="large"
                    color="primary"
                    variant="contained"
                    style={{ border: "none" }}
                    disabled={action.constraints.disable}
                    onClick={(e) =>
                      handleClick(e, action, actionProperties, {})
                    }
                  >
                    <span className="btn-wrapper--label font-size">
                      {action.label}
                    </span>
                  </Button>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>

      <Grid item container>
        <Card style={{ width: "100%", border: "none", padding: 0 }}>
          <Grid item xs={12}>
            <h6 style={{ padding: 10 }} className="color-gold">
              {allConfigs.sections[0].title}
            </h6>
          </Grid>
          <Grid item xs={12}>
            <BasicTable
              setTableData={setTableData}
              tableData={tableData}
              tableHeader={newTableHeader}
              selectedTab={selectedTab}
              handleAcceptance={handleAcceptance}
              handleComment={handleComment}
              currentUser={currentUser}
              task={task}
              finalList={finalList}
            />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    task: state.details.task,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveNaming: (data) => {
      dispatch(saveNamingValuesAndSubmit(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PocaTest);
