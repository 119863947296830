import {
  CHECK_LOGIN,
  LOGIN,
  LOGOUT,
  SET_IS_LOGGED_IN,
  SET_LOADING,
  // SET_USER
} from "./authActionTypes";
import { createAction } from "@reduxjs/toolkit";
import client from "api/apiAuth/guestClient";

export const setIsLoggedIn = createAction(
  SET_IS_LOGGED_IN,
  function prepare(isLoggedin) {
    return {
      payload: {
        isLoggedin,
      },
    };
  }
);

export const setLoading = createAction(SET_LOADING, function prepare(loading) {
  return {
    payload: {
      loading,
    },
  };
});

export const checkLogin = createAction(CHECK_LOGIN, function prepare() {
  return {
    payload: client.get("/auth/checklogin", {
      withCredentials: true,
    }),
  };
});

export const login = createAction(LOGIN, function prepare(data) {
  return {
    payload: client.post("/auth/login", data, { withCredentials: true }),
  };
});
export const googleLogin = createAction(LOGIN, function prepare(data) {
  return {
    payload: client.post("/auth/googleLogin", data, { withCredentials: true }),
  };
});
export const logout = createAction(LOGOUT, function prepare() {
  return {
    payload: client.post(`/auth/logout`, null, {
      withCredentials: true,
    }),
  };
});

// export const setUser = createAction(SET_USER, function prepare(user) {
//   return {
//     payload: {
//       user
//     }
//   };
// });
