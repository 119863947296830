import React, { useRef, useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
// import { Camera } from 'react-camera-pro';
import { Grid, Button } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { handleDocument } from "../../utils/uploadFile";
import { uploadFile } from "reducers/form/formActions";
import { connect } from "react-redux";
import { getValue } from "utils/GetObjectValues";

import Webcam from "react-webcam";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import "./style.css";
function CameraModal({
  open,
  setOpen,
  formik,
  name,
  document,
  Section,
  uploadFile,
  setCurrentUploadedFiles,
  documentType,
}) {
  const toggle = () => setOpen(!open);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const camera = useRef(null);
  const videoConstraints = {
    // width: 1280,
    // height: 720,
    facingMode: "environment",
  };
  const handleCameraUpload = (e) => {
    e.persist();
    if (documentType == "single") {
      let documentName = name,
        section = documentName.split(".")[0],
        sectionName = Section,
        allFiles = [file],
        document_id = parseInt(document);

      let uploadedDocument = handleDocument(
        formik,
        section,
        sectionName,
        documentName,
        file,
        allFiles,
        document_id
      );

      setTimeout(() => {
        formik.setFieldTouched(documentName, true, true).then((err) => {
          if (!getValue(err, documentName)) {
            uploadFile(uploadedDocument).catch((error) => {
              formik.setFieldValue(documentName, "");
            });
          } else {
            formik.setFieldValue(documentName, "");
          }
        });
      }, 0);
    } else {
      setCurrentUploadedFiles([file]);
    }
    toggle();
  };
  useEffect(() => {
    image &&
      fetch(image).then(async (res) => {
        let imageBlob = await res.blob();
        let imageType = imageBlob.type;
        let imageExtension = imageType.split("/")[1];
        let imageFile = new File(
          [imageBlob],
          `image${new Date().getTime()}.${imageExtension}`,
          { type: imageType }
        );
        Object.assign(imageFile, {
          preview: URL.createObjectURL(imageFile),
        });
        setFile(imageFile);
      });
  }, [image]);
  return (
    <Dialog
      open={open}
      onClose={toggle}
      classes={{ paper: "shadow-sm-dark rounded-sm" }}
      fullWidth
      style={{ width: "100vw", height: "100vh" }}
    >
      <div
        className="text-center "
        style={{ height: image ? "100%" : "100vh" }}
      >
        <Grid container style={{ width: "100%", height: "100%" }}>
          <Grid item md={12}>
            <div className="close-icon">
              <CloseOutlinedIcon onClick={() => setOpen(false)} />
            </div>
          </Grid>
          {!image ? (
            <Grid item md={12} style={{ width: "100%", height: "100%" }}>
              <>
                <div style={{ width: "100%", height: "100%" }}>
                  <Webcam
                    audio={false}
                    height={"100%"}
                    ref={camera}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    onUserMediaError={() => setError(true)}
                    videoConstraints={videoConstraints}
                  />
                </div>
                {!error && (
                  <Button
                    className={`btn btn-primary btn-transition-none d-inline `}
                    style={{
                      borderRadius: "50%",
                      bottom: "0",
                      position: "absolute",
                      left: "45%",
                    }}
                    onClick={() => setImage(camera.current.getScreenshot())}
                  >
                    <CameraAltIcon />
                  </Button>
                )}
              </>
            </Grid>
          ) : (
            <Grid item md={12}>
              <>
                <div className="mt-2">
                  <img src={image} alt="Taken photo" />
                </div>
                <div className="mt-2">
                  <Button
                    className="btn btn-transition-none ml-3"
                    style={{ backgroundColor: "#171a46", color: "white" }}
                    variant="contained"
                    onClick={(e) => handleCameraUpload(e)}
                  >
                    حفظ
                  </Button>
                  <Button
                    className={`btn btn-primary btn-transition-none d-inline `}
                    style={{
                      borderRadius: "50%",
                    }}
                    onClick={() => setImage(null)}
                  >
                    <CameraAltIcon />
                  </Button>
                </div>
              </>
            </Grid>
          )}
        </Grid>
      </div>
    </Dialog>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (file) => dispatch(uploadFile(file)),
  };
};
export default connect(null, mapDispatchToProps)(CameraModal);
