import { createReducer } from "@reduxjs/toolkit";
import {
  CHECK_LOGIN_FULFILLED,
  CHECK_LOGIN_PENDING,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT_FULFILLED,
  LOGOUT_PENDING,
  LOGOUT_REJECTED,
  SET_IS_LOGGED_IN,
  SET_LOADING,
  SET_USER,
} from "./authActionTypes";

let currentUser = localStorage.getItem("user");
const initialState = {
  isLoggedin: false,
  loading: false,
  user: null,
  redirectUrl: "",
  loginChecked: false,
  currentlyLogout: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(SET_IS_LOGGED_IN, (state, action) => {
    state.isLoggedin = action.payload.isLoggedin;
  });
  builder.addCase(SET_LOADING, (state, action) => {
    state.loading = action.payload.loading;
  });
  builder.addCase(CHECK_LOGIN_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(CHECK_LOGIN_FULFILLED, (state, action) => {
    state.loading = false;
    state.isLoggedin = action.payload.data.loggedin;
    state.user = action.payload.data.loggedin ? state.user : null;
    state.loginChecked = true;
    // !state.isLoggedin && localStorage.removeItem('user');
  });

  builder.addCase(LOGIN_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(LOGIN_FULFILLED, (state, action) => {
    state.loading = false;
    state.isLoggedin = true;
    state.user = action.payload.data.user;
    state.redirectUrl = action.payload.data.url;
    state.currentlyLogout = false;
  });

  builder.addCase(LOGIN_REJECTED, (state, action) => {
    state.loading = false;
  });

  builder.addCase(LOGOUT_PENDING, (state, action) => {
    state.loading = true;
    state.redirectUrl = false;
  });
  builder.addCase(LOGOUT_FULFILLED, (state, action) => {
    state.loading = false;
    state.isLoggedin = false;
    state.user = null;
    state.redirectUrl = action.payload.data.url;
    state.currentlyLogout = true;
  });

  builder.addCase(LOGOUT_REJECTED, (state, action) => {
    state.loading = false;
    state.currentlyLogout = false;
  });
});

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_IS_LOGGED_IN:
//       return {
//         ...state,
//         isLoggedin: action.payload.isLoggedin
//       };

//     default:
//       return state;
//   }
// };
export default reducer;
