import React from "react";
import lodash from "lodash";
import ModificationAttribute from "../../Modifications/ModificationAttribute/ModificationAttribute";
import * as customComponent from "customComponent/index";

export default function SectionContentRenderer({ task, section, allProps }) {
  const renderCustomComponents = (componentName, task, value, filterKey) => {
    const Component = customComponent[componentName];
    return (
      <div>
        {Component ? (
          <Component
            task={task}
            allProps={allProps}
            value={value}
            filterKey={filterKey}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const getAttributes = (attr, values, padding) => {
    if (attr.attributes) {
      if (attr.isMany) {
        let currentSubObject = values[attr.key];
        if (attr.directKey)
          currentSubObject = lodash.get(values, attr.directKey);
        let arr = [];
        if (!currentSubObject) return arr;
        for (let i = 0; i < currentSubObject.length; i++) {
          arr.push(
            <>
              {attr.name && (
                <h6
                  style={{
                    fontSize: 18,
                    marginBottom: 20,
                    paddingLeft: `${padding}px`,
                  }}
                  className="details-header padding"
                >
                  {i + 1}. {attr.name}
                </h6>
              )}
              {attr.attributes.map((att) =>
                getAttributes(att, currentSubObject[i], padding + 15)
              )}
            </>
          );
        }
        return arr;
      } else {
        return (
          <>
            {attr.name && (
              <h6
                style={{
                  fontSize: 18,
                  marginBottom: 20,
                  paddingLeft: `${padding}px`,
                }}
                className="details-header padding"
              >
                {attr.name}
              </h6>
            )}
            {attr.attributes.map((att) =>
              getAttributes(att, values[attr.key], padding + 15)
            )}
          </>
        );
      }
    } else {
      if (attr.directKey) {
        return (
          <ModificationAttribute
            type={attr.type}
            name={attr.name}
            value={lodash.get(values, attr.directKey)[attr.key]}
            size={attr.style.gridSize}
            padding={padding}
          />
        );
      } else {
        return (
          <>
            {attr.type != "component" ? (
              <ModificationAttribute
                type={attr.type}
                name={attr.label}
                value={values[attr.key]}
                size={attr.style.gridSize}
                padding={padding}
              />
            ) : (
              renderCustomComponents(
                attr.componentName,
                task,
                values[attr.key],
                attr.filterKey
              )
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <h6 className="details-header padding">{section.title}</h6>
      <React.Fragment>
        {section?.attributes?.map((attr) => {
          return getAttributes(attr, task, 0);
        })}
      </React.Fragment>
    </>
  );
}
