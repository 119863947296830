import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
  Button,
  Card,
} from "@material-ui/core";
import AddVacationModal from "./addEditModal";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import client from "api/apiAuth/guestClient";
export default function VacationPanel() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableColumns = ["Index", "Title", "From", "To", "Actions"];
  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const [isEdit, setIsEdit] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [vacations, setVacations] = useState([]);
  const [currentVacation, setCurrentVacation] = useState({
    title: "",
    start_date: "",
    end_date: "",
  });
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleAddVacationModal = () => {
    setIsEdit(false);
    setCurrentVacation({
      ...currentVacation,
      title: "",
      start_date: "",
      end_date: "",
    });

    setAddModalOpen(true);
  };
  const handleEditVacationModal = (id) => {
    setIsEdit(true);
    vacations.find((vacation) => vacation.id == id);
    setCurrentVacation({
      ...currentVacation,
      ...vacations.find((vacation) => vacation.id == id),
    });
    setAddModalOpen(true);
  };
  const handleDeleteVacationModal = (id) => {
    Swal.fire({
      title: "Are you sure you want to Delete Vacation? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        client.delete(`/calendar/destroy/${id}`).then((res) => {
          setVacations(...[vacations.filter((vac) => vac.id != id)]);
        });
      }
    });
  };
  useEffect(() => {
    client
      .get("/calendar")
      .then((res) => setVacations([...res.data.vacations]))
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Card
      style={{
        borderRadius: "0 20px 20px 20px",
        boxShadow: "none",
        border: "none",
      }}
    >
      <Grid style={{ textAlign: "right" }}>
        <Button
          style={{
            margin: "15px",
            fontSize: "1.1rem",
            color: "#FFF",
            backgroundColor: "#198754",
          }}
          onClick={() => handleAddVacationModal()}
        >
          Add Vacation
        </Button>
      </Grid>
      <Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((columnHeader) => (
                  <TableCell
                    className="bg-color-darkBlue pt-2"
                    style={{ textAlign: "center" }}
                    key={columnHeader}
                  >
                    {columnHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {vacations.length ? (
                vacations.map((vacation, index) => (
                  <TableRow key={vacation.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {vacation.title}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {new Date(vacation.start_date).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {new Date(vacation.end_date).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        style={{
                          color: "#FFF",
                          backgroundColor: "#0000ff",
                          fontWeight: "bold",
                        }}
                        onClick={(e) => handleEditVacationModal(vacation.id)}
                      >
                        <EditIcon className="mx-2" /> Edit
                      </Button>
                      <Button
                        style={{
                          color: "#FFF",
                          backgroundColor: "#ff0000",
                          fontWeight: "bold",
                        }}
                        onClick={(e) => handleDeleteVacationModal(vacation.id)}
                      >
                        <DeleteIcon className="mx-2" /> Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={tableColumns.length}>
                    <h5 className="mt-3">No Vacations</h5>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage=""
                  colSpan={5}
                  count={vacations.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ direction: "ltr" }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <AddVacationModal
        open={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        isEdit={isEdit}
        vacations={vacations}
        setVacations={setVacations}
        currentVacation={currentVacation}
        setCurrentVacation={setCurrentVacation}
      />
    </Card>
  );
}
