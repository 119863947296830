/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.scss";
import imag from "../../assets/images/bio-inn.png";
import config from "../index.js";

const ReviewerReport = ({
  letter,
  setDivRef,
  values,
  setValues,
  formValues,
  form,
}) => {
  useEffect(() => {
    setValues(config(letter, formValues, "ReviewerReport"));
  }, []);
  const [imageSrc, setImageSrc] = useState("");
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (values) {
      if (letter?.drugs?.MarketingAuthorizationHolder) {
        form.setFieldValue(
          "marketing_authorization",
          letter?.drugs?.MarketingAuthorizationHolder
        );
      }
      if (letter?.drugs?.Manufacturer_Responsible_for_Primary_packaging) {
        form.setFieldValue(
          "manufacturer_responsible",
          letter.drugs.Manufacturer_Responsible_for_Primary_packaging
        );
      }
      if (letter?.drugs.Manufacturer_of_finished_product) {
        form.setFieldValue(
          "manufacturer_product",
          letter?.drugs.Manufacturer_of_finished_product
        );
      }
    }
  }, [values, formValues]);
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }
  toDataURL(imag);
  useEffect(() => {
    const reviewer_report = document.getElementById("reviewer");
    reviewer_report.classList.add("myDiv");
    setDivRef((divRefs) => ({ ...divRefs, reviewer_report }));
  }, [values, formValues]);
  return (
    <div style={{ padding: "20px", zoom: 0.7, color: "black" }} id="reviewer">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            CA of Biological and Innovative products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            and Clinical Studies
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            GA of Biological Product
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Lot Release Administration
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "200px" }} alt="bio-inn" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هـيـئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الحيوية والمبتكرة
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            والدراسات الإكلينيكية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات الحيوية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            إدارة الافراج عن التشغيلات
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />

      <div
        className="row"
        style={{
          backgroundColor: "lightgrey",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <span style={{ color: "black" }}>Batch Review Report for</span>

        <span style={{ marginLeft: "10px" }}>{letter.values.product_type}</span>
      </div>
      <div style={{ width: "100%", textAlign: "initial" }}>
        <p style={{ marginLeft: "10px", marginTop: "10px" }}>
          <b style={{ fontSize: "24px" }}>1. Product Information:</b>{" "}
        </p>
      </div>
      <div></div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table
              style={{
                textAlign: "left",
                marginTop: "15px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td className="table-border">
                    <b>Product name:</b>
                  </td>
                  <td className="table-border">{letter?.drugs?.name || ""} </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Product type:</b>
                  </td>
                  <td className="table-border">{letter.drugs?.generics}</td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Type of container/doses:</b>
                  </td>
                  <td className="table-border">
                    {letter?.values?.dosage_type || ""}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Batch No.:</b>
                  </td>
                  <td className="table-border">{letter.values.batch_number}</td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Batch no. of diluents: (if present)</b>
                  </td>
                  <td className="table-border">
                    {letter.values.diluent_batch_number ? (
                      <> {letter.values.diluent_batch_number}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.diluent_batch_number || ""}
                        name="diluent_batch_number"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Manufacturer </b>
                  </td>
                  <td className="table-border">
                    {letter.drugs.Manufacturer ? (
                      <>{letter.drugs.Manufacturer}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.Manufacturer || ""}
                        name="Manufacturer"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Agent:</b>
                  </td>
                  <td className="table-border">
                    {letter.drugs.Applicant ? (
                      <>{letter.drugs.Applicant}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.agent || ""}
                        name="agent"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Country of origin:</b>
                  </td>
                  <td className="table-border">
                    {letter.drugs.CountryOfOrigin ? (
                      <> {letter.drugs.CountryOfOrigin}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.CountryOfOrigin || ""}
                        name="CountryOfOrigin"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Manufacturing date:</b>
                  </td>
                  <td className="table-border">
                    {letter.values.manufacture_date ? (
                      <>{letter.values.manufacture_date}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.manufacture_date || ""}
                        name="manufacture_date"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Expiry date:</b>
                  </td>
                  <td className="table-border">
                    {letter.values.expiry_date ? (
                      <>{letter.values.expiry_date}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.expiry_date || ""}
                        name="expiry_date"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Shelf life</b>
                  </td>
                  <td className="table-border">
                    {letter?.drugs?.hasOwnProperty("shelf_life") ? (
                      <>{letter?.drugs?.shelf_life}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.shelf_life || ""}
                        name="shelf_life"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>Registration no.:</b>
                  </td>
                  <td className="table-border">
                    {letter?.drugs?.license_number ? (
                      <>{letter?.drugs?.license_number}</>
                    ) : (
                      <input
                        style={{ border: "none" }}
                        className="w-100"
                        type="text"
                        value={values.license_number || ""}
                        name="license_number"
                        onChange={handleChange}
                      ></input>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ width: "100%", textAlign: "initial" }}>
              <p style={{ marginLeft: "10px", marginTop: "10px" }}>
                <b style={{ fontSize: "24px" }}>2. Site of manufacturer:</b>
              </p>
            </div>
            <table
              style={{
                textAlign: "left",
                marginTop: "15px",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td className="table-border2">
                    <b>Marketing authorization holder:</b>
                  </td>
                  <td className="table-border">
                    {letter?.drugs?.MarketingAuthorizationHolder ? (
                      <>{letter?.drugs.MarketingAuthorizationHolder}</>
                    ) : (
                      <>
                        <textarea
                          style={{ border: "none" }}
                          className="w-100"
                          type="text"
                          value={
                            values.marketing_authorization ||
                            letter?.drugs.MarketingAuthorizationHolder ||
                            ""
                          }
                          name="marketing_authorization"
                          onChange={(e) => {
                            form.handleChange(e);
                            handleChange(e);
                          }}
                          onBlur={form.handleBlur}
                        ></textarea>
                        {form.errors["marketing_authorization"] ? (
                          <div className="text-danger">
                            {form.errors["marketing_authorization"]}
                          </div>
                        ) : null}
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border2">
                    <b>Manufacturer of finished product:</b>
                  </td>
                  <td className="table-border">
                    {letter?.drugs.Manufacturer_of_finished_product ? (
                      <>{letter?.drugs.Manufacturer_of_finished_product}</>
                    ) : (
                      <>
                        <textarea
                          style={{ border: "none" }}
                          type="text"
                          className="w-100"
                          defaultValue={
                            letter.drugs.Manufacturer_of_finished_product
                          }
                          value={
                            values.manufacturer_product ||
                            letter.drugs.Manufacturer_of_finished_product ||
                            ""
                          }
                          name="manufacturer_product"
                          onChange={(e) => {
                            form.handleChange(e);
                            handleChange(e);
                          }}
                          onBlur={form.handleBlur}
                        ></textarea>
                        {form.errors["manufacturer_product"] ? (
                          <div className="text-danger">
                            {form.errors["manufacturer_product"]}
                          </div>
                        ) : null}
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-border2">
                    <b>Manufacturer responsible for packaging and labeling:</b>
                  </td>
                  <td className="table-border">
                    {letter?.drugs
                      .Manufacturer_Responsible_for_Primary_packaging ? (
                      <>
                        {
                          letter.drugs
                            .Manufacturer_Responsible_for_Primary_packaging
                        }
                      </>
                    ) : (
                      <>
                        <textarea
                          style={{ border: "none" }}
                          type="text"
                          className="w-100"
                          value={
                            values.manufacturer_responsible ||
                            letter?.drugs
                              .Manufacturer_Responsible_for_Primary_packaging ||
                            ""
                          }
                          name="manufacturer_responsible"
                          onChange={(e) => {
                            form.handleChange(e);
                            handleChange(e);
                          }}
                          onBlur={form.handleBlur}
                        ></textarea>
                        {form.errors["manufacturer_responsible"] ? (
                          <div className="text-danger">
                            {form.errors["manufacturer_responsible"]}
                          </div>
                        ) : null}
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ width: "100%", textAlign: "initial" }}>
              <p style={{ marginLeft: "10px", marginTop: "10px" }}>
                <b style={{ fontSize: "24px" }}>
                  3. Conclusion of relevant documents evaluation process:
                </b>
              </p>
            </div>
            <textarea
              value={values.conclusion || ""}
              name="conclusion"
              placeholder="Conclusion of relevant documents evaluation process..."
              rows="4"
              style={{ minWidth: "100%", minHeight: "30px", marginTop: "5px" }}
              onChange={handleChange}
            ></textarea>
            <div style={{ width: "100%", textAlign: "initial" }}>
              <p style={{ marginLeft: "10px", marginTop: "10px" }}>
                <b style={{ fontSize: "24px" }}>
                  4. Sampling determination/ Number :
                </b>
              </p>
            </div>
            <table
              style={{
                textAlign: "left",
                marginTop: "15px",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="sampling"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      id="full sampling"
                      value="full sampling"
                      onChange={handleChange}
                    />
                    full sampling
                  </td>
                  <td className="table-border">
                    <input
                      style={{ border: "none" }}
                      type="text"
                      value={values.full_sampling_value || ""}
                      name="full_sampling_value"
                      onChange={handleChange}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="sampling"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      id="reduced sampling"
                      value="reduced sampling"
                      onChange={handleChange}
                    />
                    reduced sampling
                  </td>
                  <td className="table-border">
                    <input
                      style={{ border: "none" }}
                      type="text"
                      value={values.reduced_sampling_value || ""}
                      name="reduced_sampling_value"
                      onChange={handleChange}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="sampling"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      id="No sampling"
                      value="No sampling"
                      onChange={handleChange}
                    />
                    No sampling
                  </td>
                  <td className="table-border">N.A</td>
                </tr>
              </tbody>
            </table>

            <div style={{ textAlign: "center" }} className="m-5">
              <span>Review report no </span>
              {letter.values.reviewer_report_number ? (
                <span>{letter.values.reviewer_report_number}</span>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="........"
                    value={values.reviewer_report_number}
                    name="reviewer_report_number"
                    onChange={(e) => {
                      form.handleChange(e);
                      handleChange(e);
                    }}
                    onBlur={form.handleBlur}
                  ></input>
                  {form.touched["reviewer_report_number"] &&
                  form.errors["reviewer_report_number"] ? (
                    <div className="text-danger">
                      {form.errors["reviewer_report_number"]}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewerReport;
