import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
const {
  routes,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/Routes/leftHeaderRoutes`);

const HeaderLeftBox = ({ user }) => {
  const location = useLocation();

  const getHeader = () => {
    const currentRoute = routes.find((route) =>
      matchPath(location.pathname, route)
    );
    const headerIcon = currentRoute?.icon;
    return headerIcon ? (
      <>
        {headerIcon}
        {currentRoute?.key}
      </>
    ) : (
      currentRoute?.key || null
    );
  };
  return (
    <>
      <div>
        <h5 className="mt-2 mb-0 ml-4 primary">{getHeader()}</h5>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, null)(HeaderLeftBox);
