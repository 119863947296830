import store from "reducers";
import client from "api/apiAuth/guestClient";
import {
  SEND,
  SEND_FAIL,
  SEND_SUCCESS,
  RECEIVE,
  RECEIVE_SUCCESS,
  RECEIVE_FAIL,
  CONNECT,
  CONNECT_SUCCESS,
  CONNECT_FAIL,
  NOTIFICATION_MASSEGE,
  EDIT,
  EDIT_FAIL,
  EDIT_SUCCESS,
  GET_NOTIFICATIONS,
  IS_EDIT,
} from "./actionTypes";

export function send(eventName, message) {
  return {
    type: "socket",
    types: [SEND, SEND_SUCCESS, SEND_FAIL],
    promise: (socket) => socket.emit(eventName, message),
  };
}

export function receive(eventName) {
  return {
    type: "socket",
    types: [RECEIVE, RECEIVE_SUCCESS, RECEIVE_FAIL],
    promise: (socket) => {
      // socket.emit("join", store.getState().auth.user.id);
      return socket.on(eventName, (response) => {
        store.getState().auth.user.id === socket.user.id &&
          store.dispatch(receiveMessage(response));
        return response;
      });
    },
  };
}
export function editSubmission(eventName) {
  return {
    type: "socket",
    types: [EDIT, EDIT_SUCCESS, EDIT_FAIL],
    promise: (socket) => {
      // socket.emit("join", store.getState().auth.user.id);
      return socket.on(eventName, (response) => {
        store.getState().auth.user.id === socket.user.id &&
          store.dispatch(isEdit(response));
        return response;
      });
    },
  };
}

export const getNotifications = function (params) {
  return {
    type: GET_NOTIFICATIONS,
    payload: client.get("generic/find", { params }),
  };
};
export function receiveMessage(message) {
  return {
    type: NOTIFICATION_MASSEGE,
    payload: message,
  };
}
export function isEdit(message) {
  console.log({ message });
  return {
    type: IS_EDIT,
    payload: message,
  };
}
export function connectSocket(user) {
  return {
    type: "socket",
    types: [CONNECT, CONNECT_SUCCESS, CONNECT_FAIL],
    promise: (socket) => {
      return socket.connect(user);
    },
  };
}
