import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import InputWidget from "components/FormSteps/InputWidget";
import { useFormik } from "formik";
import SpinnerLoader from "components/SpinnerLoader";
import _, { isNull } from "lodash";
import { useParams } from "react-router-dom";
import { validateName as validateDrugName } from "./utils/DrugNameValidationUtils";

const MAX_TRIALS = 4;
const MIN_NAME_LEN = 2;
const HOLD_STEPS = [
  { id: 13, transition_id: 13 },
  { id: 20, transition_id: 24 },
];

const NamingDrugNamesTable = (props) => {
  const { task } = props;
  const [minTradeCount, setMinTradeCount] = useState(5);
  const [inputs, setInputs] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);
  const [rowCount, setRowCount] = useState(5);
  const [oldDrugsLength, setOldDrugsLength] = useState(0);
  const [submissionFileCode, setSubmissionFileCode] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [genericNamePosition, setGenericNamePosition] = useState(null);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const [currentTrialCount, setCurrentTrialCount] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [allDrugNames, setAllDrugNames] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [thresHold, setThresHold] = useState(60);
  const [isLineExtension, setIsLineExtension] = useState(false);
  const [isRealLineExtension, setIsRealLineExtension] = useState(false);

  const [similarityArrayLoading, setSimilarityArrayLoading] = useState([]);

  const [declaration, setDeclaration] = useState({
    exists: false,
    company: "",
    newIngredient: "",
    existingIngredient: "",
  });
  const params = useParams();

  const handleSubmitTask = async () => {
    if (currentTrialCount === 0) {
      toggleModal();
      return;
    }
    submitTask();
  };

  const submitTask = async () => {
    const drugs = _.cloneDeep(formik.values.drugs);
    const filteredList = [];
    for (const drug of drugs) {
      if (!drug || !("name" in drug) || drug?.name.length < MIN_NAME_LEN)
        continue;
      delete drug.acceptance;
      delete drug.similarity;
      filteredList.push(drug);
    }

    let namingValues = {
      naming: {
        drugs: filteredList,
        commercial_record: formik.values.commercial_record,
        extra_attachments: formik.values.extra_attachments,
        naming_comment_input: formik.values.naming_comment_input,
      },
    };

    if (formik.values.naming_extra_fees_1) {
      namingValues = {
        naming: {
          ...namingValues.naming,
          naming_extra_fees_1: {
            ...formik.values.naming_extra_fees_1,
            naming_extra_fees_1_receipt: {
              receipt_number: formik.values.extra_fees1_receipt_number,
              receipt_group: formik.values.extra_fees1_receipt_group,
            },
          },
        },
      };
    }
    if (formik.values.naming_extra_fees_2) {
      namingValues = {
        naming: {
          ...namingValues.naming,
          naming_extra_fees_2: {
            ...formik.values.naming_extra_fees_2,
            naming_extra_fees_2_receipt: {
              receipt_number: formik.values.extra_fees2_receipt_number,
              receipt_group: formik.values.extra_fees2_receipt_group,
            },
          },
        },
      };
    }
    if (isLineExtension) {
      namingValues.old_trade_name = formik.values.old_trade_name;
      namingValues.naming_Form_type = formik.values.naming_Form_type;
    }
    // if (declaration.exists) {
    namingValues.naming.declaration = _.clone(declaration);
    // }
    await client.post("submission/submitDrugNames", {
      submission_id: params.submissionId,
      task_id: params.taskId,
      submission_edit: namingValues,
      submitFlag: true,
    });
    window.location.pathname = "worklist";
  };

  const updatePocaScoreForIndex = async (index, newValue) => {
    if (index === 0) {
      return;
    }

    setEnableSubmitButton(false);
    const result = await client.post("algorithm/runAlgorithm", {
      drugName: newValue,
      algorithmName: "getPocaScore",
    });
    const resultLength = result.data.finalResult.length;
    // const newArray = similarityArrayLoading;
    setSimilarityArrayLoading((oldArray) => {
      const itemIndex = oldArray.indexOf(index);
      oldArray.splice(itemIndex, 1);

      return [...oldArray];
    });
    formik.setFieldValue("renDomId", Math.random() * 10);
    formik.setFieldValue(
      `drugs[${index}].similarity`,
      resultLength ? `${result.data.finalResult[0].mergedScore}%` : "0%"
    );
    if (resultLength) {
      if (result.data.finalResult[0].mergedScore >= thresHold)
        formik.setFieldValue(`drugs[${index}].acceptance`, "Initial Rejection");
      else
        formik.setFieldValue(
          `drugs[${index}].acceptance`,
          "Initial Acceptance"
        );
    } else
      formik.setFieldValue(`drugs[${index}].acceptance`, "Initial Acceptance");
  };

  const uploadAudio = async (input, data, index) => {
    const formData = new FormData();
    formData.append(
      "avatar",
      new File([data.blob], "record.webm", {
        type: data.blob.type,
      })
    );

    const result = await client.post(
      `attachment/uploadFile?documentName=${input.equivalent_key}&submission_id=${params.submissionId}`,
      formData
    );
    formik.setFieldValue(`drugs[${index}].recording`, result.data.results);
  };

  const addRow = () => setRowCount(rowCount + 1);
  const removeRow = () => {
    const { drugs } = formik.values;
    if (drugs) {
      if (drugs.length === rowCount) drugs.pop();
      formik.setFieldValue("drugs", drugs);
    }
    setRowCount(rowCount - 1);
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const generateRows = () => {
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <TableRow key={i}>
          {!isLineExtension ? (
            <TableCell>{i === 0 ? "Generic" : "Trade Name"}</TableCell>
          ) : (
            <TableCell>New Trade Name</TableCell>
          )}
          <TableCell>
            <TextField
              value={formik.values?.drugs?.[i]?.name || null}
              variant="outlined"
              size="small"
              error={_.get(formik.errors, `drugs[${i}].name`) ? true : false}
              onChange={(ev) => {
                if (ev.target.value.length) {
                  formik.setFieldValue(`drugs[${i}].name`, ev.target.value);

                  handleArFieldError(i);

                  if (i === 0 && !isLineExtension)
                    formik.setFieldValue(`drugs[${i}].generic`, true);
                } else {
                  formik.setFieldValue(`drugs[${i}].name`, undefined);
                  formik.setFieldValue(`drugs[${i}].similarity`, undefined);
                  formik.setFieldValue(`drugs[${i}].acceptance`, undefined);
                  formik.setFieldValue(`drugs[${i}].generic`, undefined);
                }
              }}
              placeholder={`Drug Name`}
              disabled={
                similarityArrayLoading.includes(i) ||
                ["accept", "reject"].includes(
                  formik.values?.drugs?.[i]?.finalDecision
                )
              }
              onBlur={(ev) => {
                if (ev.target.value && !isLineExtension) {
                  if (i >= 1) {
                    setSimilarityArrayLoading((oldArray) => [...oldArray, i]);
                  }
                  updatePocaScoreForIndex(i, ev.target.value);
                }
              }}
              helperText={_.get(formik.errors, `drugs[${i}].name`)}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={formik.values?.drugs?.[i]?.arname || null}
              disabled={["accept", "reject"].includes(
                formik.values?.drugs?.[i]?.finalDecision
              )}
              variant="outlined"
              size="small"
              error={_.get(formik.errors, `drugs[${i}].arname`) ? true : false}
              placeholder={`Arabic Drug Name`}
              onChange={(ev) => {
                formik.setFieldValue(
                  `drugs[${i}].arname`,
                  ev.target.value.length ? ev.target.value : undefined
                );
              }}
              helperText={_.get(formik.errors, `drugs[${i}].arname`)}
            />
          </TableCell>
          <TableCell>
            {similarityArrayLoading.includes(i) ? (
              <CircularProgress />
            ) : (
              _.get(formik.values, `drugs[${i}].similarity`)
            )}
          </TableCell>
          <TableCell>
            {_.get(formik.values, `drugs[${i}].acceptance`)}
          </TableCell>
          <TableCell>
            <InputWidget
              formik={formik}
              input={inputs.recording}
              onValueChange={(input, data) => uploadAudio(input, data, i)}
            />
          </TableCell>
        </TableRow>
      );

      // Generic name position
      if (
        !isLineExtension &&
        i === 0 &&
        formik.values.drugs &&
        formik.values.drugs[0] &&
        formik.values.drugs[0].name &&
        formik.values.drugs[0].name.length
      ) {
        rows.push(
          <TableRow key={"genericnameposition"}>
            <TableCell colSpan={6}>
              <div className="d-flex">
                <div className="mt-2">
                  <span>Company name position:</span>
                </div>
                <div className="ml-2">
                  <RadioGroup
                    row
                    value={genericNamePosition}
                    onChange={(ev, value) => {
                      setGenericNamePosition(value);
                      formik.setFieldValue(`drugs[0].position`, value);
                    }}
                  >
                    <FormControlLabel
                      label={"Beside drug name"}
                      value={"Beside drug name"}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      label={"Below drug name"}
                      value={"Below drug name"}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </div>
              </div>
            </TableCell>
          </TableRow>
        );
      }
    }
    return rows;
  };
  const formik = useFormik({
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitTask,
    initialValues,
  });

  useEffect(() => {
    async function doNetworkRequests() {
      const result = await client.post("generic/find", {
        where: { id: [98, 58, 50, 43, 44, 124, 125, 126, 127, 150, 151] },
        model: "Input",
      });
      const extraInputs = {
        commercial: result.data.rows.find((item) => item.id === 58),
        recording: result.data.rows.find((item) => item.id === 50),
        extra_attachments: result.data.rows.find((item) => item.id === 150),
        naming_comment_input: result.data.rows.find((item) => item.id === 151),
        old_trade_name: result.data.rows.find((item) => item.id === 98),
      };

      const result1 = await client.post("generic/find", {
        where: { id: params.submissionId },
        model: "Submission",
        include: [
          { association: "org" },
          {
            association: "attachments",
            include: [
              { association: "document" },
              {
                association: "receipt",
              },
            ],
            required: false,
          },
        ],
      });
      const thresholdRow = await client.post("generic/find", {
        where: { key: "threshold" },
        model: "SystemSetting",
      });
      if (thresholdRow) {
        setThresHold(thresholdRow.data.rows[0].value);
      }
      const { blockingCount, line_extension, naming_Form_type } =
        result1.data.rows[0].values;
      if (line_extension || naming_Form_type) {
        setIsLineExtension(true);
        setIsRealLineExtension(true);
      }
      const trialCount = blockingCount ? blockingCount : 0;

      let keys = [
        {
          key: "commercial_record",
          condition: blockingCount == 0 || !blockingCount,
        },
        { key: "naming_extra_fees_1", condition: trialCount === 2 },
        { key: "naming_extra_fees_2", condition: trialCount === 3 },
        { key: "extra_attachments" },
      ];
      // if (blockingCount == 0 || !blockingCount) {
      let oldAttachments = result1.data.rows[0].attachments.reduce(
        (previousValue, currentValue) => {
          if (
            keys.find(
              (element) =>
                element.key === currentValue.document.key &&
                (element.condition || !currentValue.is_reviewed)
            )
          ) {
            return {
              [currentValue.document.key]: currentValue,
              ...previousValue,
            };
          } else {
            return previousValue;
          }
        },
        {}
      );

      let oldDrugs;

      if ("naming" in result1.data.rows[0].values) {
        const { allDrugNames } = result1.data.rows[0].values.naming;
        const finalNames = allDrugNames.map(
          (name) => name.split(`-${result1.data.rows[0].org.name}`)[0]
        );
        oldDrugs = _.cloneDeep(result1.data.rows[0].values.naming.drugs);
        setAllDrugNames(finalNames);
      }
      if (oldDrugs) {
        oldDrugs = oldDrugs.map((drug) => {
          if (drug.generic) {
            drug.name = drug.name.replace(
              `-${result1.data.rows[0].org.name}`,
              ""
            );
          }
          return drug;
        });
        if (!oldDrugs[0].generic && !line_extension && !naming_Form_type) {
          oldDrugs.unshift(undefined);
        } else {
          setGenericNamePosition(oldDrugs[0].position);
        }
        setRowCount(oldDrugs.length);
        setOldDrugsLength(oldDrugs.length);
        setInitialValues({ ...oldAttachments, drugs: oldDrugs });
      } else {
        setInitialValues({
          ...oldAttachments,
        });
      }

      if (result1.data.rows[0].org.name) {
        setDeclaration({
          ...declaration,
          company: result1.data.rows[0].org.name,
        });
      }

      if (trialCount >= 0) {
        extraInputs.extra_fees1 = result.data.rows.find(
          (item) => item.id === 43
        );
        extraInputs.extra_fees1_receipt_number = result.data.rows.find(
          (item) => item.id === 124
        );
        extraInputs.extra_fees1_receipt_group = result.data.rows.find(
          (item) => item.id === 125
        );
        extraInputs.extra_fees2 = result.data.rows.find(
          (item) => item.id === 44
        );
        extraInputs.extra_fees2_receipt_number = result.data.rows.find(
          (item) => item.id === 126
        );
        extraInputs.extra_fees2_receipt_group = result.data.rows.find(
          (item) => item.id === 127
        );
      }
      setCurrentTrialCount(trialCount);
      setInputs(extraInputs);
      setSubmissionFileCode(result1.data.rows[0].file_code);
      setOrganizationName(result1.data.rows[0].org.name);
      setFetchingData(false);
    }
    doNetworkRequests();
  }, []);

  const handleArFieldError = (index) => {
    if (!formik.values?.drugs?.[index]?.arname?.length) {
      const formikErrors = _.cloneDeep(formik.errors);
      _.set(
        formikErrors,
        `drugs[${index}].arname`,

        `Arabic Name is Required`
      );
      formik.setErrors(formikErrors);
    }
  };

  useEffect(() => {
    if (isLineExtension) {
      setRowCount(1);
      setMinTradeCount(1);
    } else {
      if (oldDrugsLength) {
        setRowCount(oldDrugsLength);
      } else {
        setRowCount(5);
      }
      setMinTradeCount(5);
    }
  }, [isLineExtension]);

  useEffect(() => {
    let shouldEnableSubmitButton = true;
    if (formik.values && "drugs" in formik.values) {
      shouldEnableSubmitButton =
        shouldEnableSubmitButton &&
        !formik.values.drugs.some(
          (drug) =>
            (drug &&
              "acceptance" in drug &&
              drug.acceptance.includes("Rejection")) ||
            false
        );

      let genericFilled = false;
      const formikErrors = _.cloneDeep(formik.errors);

      if (!isLineExtension) {
        genericFilled =
          formik.values.drugs[0] &&
          "name" in formik.values.drugs[0] &&
          formik.values.drugs[0].name.length >= MIN_NAME_LEN &&
          "arname" in formik.values.drugs[0] &&
          formik.values.drugs[0].arname.length >= MIN_NAME_LEN;
        delete formikErrors["old_trade_name"];
      } else {
        if (
          !("old_trade_name" in formik.values) ||
          !formik.values["old_trade_name"]
        ) {
          _.set(formikErrors, `old_trade_name`, "This old name required");
          shouldEnableSubmitButton = false;
        }
      }

      let filledDrugCount = 0;
      let filledArDrugCount = 0;

      const allNames = [];
      const allArNames = [];

      for (let i = 0; i < formik.values.drugs.length; i++) {
        const drug = formik.values.drugs[i];
        if (drug && "name" in drug) {
          if (drug.name.length >= MIN_NAME_LEN) {
            filledDrugCount += 1;
            _.set(formikErrors, `drugs[${i}].name`, undefined);
            if (allNames.includes(drug.name) && !drug.finalDecision) {
              _.set(
                formikErrors,
                `drugs[${i}].name`,
                "This name has been repeated before"
              );

              shouldEnableSubmitButton = false;
            } else allNames.push(drug.name);

            const errorObject = {};
            if (!("generic" in drug && drug.generic)) {
              if (
                !(
                  task &&
                  "line_extension" in task.submission.values &&
                  task.submission.values.line_extension
                )
              ) {
                if (!isLineExtension) {
                  if (!validateDrugName(drug.name, true, errorObject)) {
                    _.set(
                      formikErrors,
                      `drugs[${i}].name`,
                      errorObject.message
                    );
                    shouldEnableSubmitButton = false;
                  }
                }
              }
            }

            if (drug.generic && !drug.position) {
              _.set(formikErrors, `drugs[${i}].name`, "Please select position");

              shouldEnableSubmitButton = false;
            }
            if (
              allDrugNames.includes(drug.name) &&
              !["accept", "reject"].includes(drug.finalDecision)
            ) {
              _.set(
                formikErrors,
                `drugs[${i}].name`,
                "This name has been rejected. Type another name"
              );
              shouldEnableSubmitButton = false;
            }
          } else {
            _.set(
              formikErrors,
              `drugs[${i}].name`,
              `Name must be more than ${MIN_NAME_LEN} characters`
            );
            _.set(formikErrors, `drugs[${i}].arname`, undefined);
          }
        }

        ////validate arabic name////////////////
        if (drug && "arname" in drug) {
          if (drug.arname.length >= MIN_NAME_LEN) {
            _.set(formikErrors, `drugs[${i}].arname`, undefined);
            if (drug.arname) {
              if (
                !!/[^\u0600-\u06FF\u0020-\u0040\u005B-\u0060\u007B-\u007E]/.test(
                  drug.arname
                )
              ) {
                _.set(
                  formikErrors,
                  `drugs[${i}].arname`,
                  "This name must be in arabic letter format"
                );
              }
            }
            if (allArNames.includes(drug.arname) && !drug.finalDecision) {
              _.set(
                formikErrors,
                `drugs[${i}].arname`,
                "This name has been repeated before"
              );

              shouldEnableSubmitButton = false;
            } else {
              allArNames.push(drug.arname);
              filledArDrugCount = filledArDrugCount + 1;
            }
          } else {
            _.set(
              formikErrors,
              `drugs[${i}].arname`,
              `Arabic Name must be more than ${MIN_NAME_LEN} characters`
            );
          }
        }
      }
      shouldEnableSubmitButton =
        shouldEnableSubmitButton &&
        (genericFilled ||
          (filledDrugCount >= minTradeCount &&
            filledArDrugCount >= minTradeCount));

      formik.setErrors(formikErrors);
    } else shouldEnableSubmitButton = false;

    if (currentTrialCount === 0) {
      shouldEnableSubmitButton =
        shouldEnableSubmitButton &&
        "commercial_record" in formik.values &&
        formik.values["commercial_record"];
    }

    if (currentTrialCount === 2) {
      shouldEnableSubmitButton =
        shouldEnableSubmitButton &&
        "naming_extra_fees_1" in formik.values &&
        ("extra_fees1_receipt_number" in formik.values ||
          ("receipt" in formik.values.naming_extra_fees_1 &&
            "receipt_number" in formik.values?.naming_extra_fees_1.receipt)) &&
        ("extra_fees1_receipt_group" in formik.values ||
          ("receipt" in formik.values.naming_extra_fees_1 &&
            "receipt_group" in formik.values?.naming_extra_fees_1.receipt));
    }

    if (currentTrialCount === 3) {
      shouldEnableSubmitButton =
        shouldEnableSubmitButton &&
        "naming_extra_fees_2" in formik.values &&
        ("extra_fees2_receipt_number" in formik.values ||
          ("receipt" in formik.values.naming_extra_fees_2 &&
            "receipt_number" in formik.values?.naming_extra_fees_2.receipt)) &&
        ("extra_fees2_receipt_group" in formik.values ||
          ("receipt" in formik.values.naming_extra_fees_2 &&
            "receipt_group" in formik.values?.naming_extra_fees_2.receipt));
      if (
        "naming_extra_fees_1" in formik.values &&
        (!formik.values["naming_extra_fees_1"]?.is_reviewed ||
          formik.values["naming_extra_fees_1"]) &&
        (!("extra_fees1_receipt_number" in formik.values) ||
          !("extra_fees1_receipt_group" in formik.values))
      ) {
        shouldEnableSubmitButton = false;
      }
    }
    let keys = [
      "commercial_record",
      "naming_extra_fees_1",
      "naming_extra_fees_2",
    ];
    for (let key of keys) {
      if (key in formik.values) {
        shouldEnableSubmitButton =
          shouldEnableSubmitButton &&
          ("is_reviewed" in formik.values[key]
            ? formik.values[key].is_reviewed
            : true);
      }
    }
    setEnableSubmitButton(shouldEnableSubmitButton);
  }, [formik.values, rowCount]);

  const isPreviousStepHold =
    task?.last_task &&
    HOLD_STEPS.some(
      (step) =>
        step.id === task.last_task.step.id &&
        step.transition_id === task.last_task.transition_id
    );

  if (fetchingData) return <SpinnerLoader />;
  return (
    <Grid
      container
      justifyContent="center"
      style={{ backgroundColor: "white", height: "80%" }}
    >
      <Grid
        item
        container
        xs={7}
        className="text-center mt-3 mb-2"
        alignItems="flex-start"
      >
        <div className="font-weight-bold " style={{ fontSize: "28px" }}>
          Naming Process
        </div>
      </Grid>
      <Grid
        item
        xs={4}
        className="text-center"
        style={{
          marginRight: "2rem",
          marginTop: "10px",
        }}
      >
        <Alert severity="info">
          <p>- You need to insert at least 1 generic or 5 trade names</p>
          <p style={{ textAlign: "left" }}>
            - You have {MAX_TRIALS - currentTrialCount} trials left
          </p>
        </Alert>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={3}
        className="text-center"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          marginLeft: "2rem",
        }}
      >
        {!isRealLineExtension && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p>Choose Naming Form</p>
            <div>
              <RadioGroup
                row
                value={`${isLineExtension}`}
                onChange={(ev, value) => {
                  setIsLineExtension(JSON.parse(value));
                  formik.setFieldValue(`naming_Form_type`, JSON.parse(value));
                  if (formik.values?.drugs?.[0]) {
                    if (!JSON.parse(value)) {
                      formik.setFieldValue(`drugs[0].generic`, true);
                    } else {
                      formik.setFieldValue(`drugs[0].generic`, false);
                    }
                  }
                }}
              >
                <FormControlLabel
                  disabled={isPreviousStepHold}
                  label={"Normal Form"}
                  value={`false`}
                  control={<Radio />}
                />
                <FormControlLabel
                  disabled={isPreviousStepHold}
                  label={"Line Extension Form"}
                  value={`true`}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        )}

        {(currentTrialCount == 0 && !formik.values?.["commercial_record"]) ||
        (currentTrialCount <= 3 &&
          formik.values?.["commercial_record"] &&
          !formik.values?.["commercial_record"]?.is_reviewed) ? (
          <InputWidget
            formik={formik}
            input={inputs.commercial}
            submissionValue={{ file_code: submissionFileCode }}
          />
        ) : null}
        {(currentTrialCount === 2 && !formik.values?.["naming_extra_fees_1"]) ||
        (currentTrialCount <= 3 &&
          formik.values?.["naming_extra_fees_1"] &&
          !formik.values?.["naming_extra_fees_1"]?.is_reviewed) ? (
          <>
            <InputWidget
              formik={formik}
              input={inputs.extra_fees1}
              submissionValue={{ file_code: submissionFileCode }}
            />
            <Grid
              item
              container
              xs={12}
              className="text-center mt-2"
              justifyContent="left"
              alignItems="left"
              spacing={2}
            >
              <InputWidget
                formik={formik}
                input={inputs.extra_fees1_receipt_number}
                submissionValue={{ file_code: submissionFileCode }}
              />
              <InputWidget
                formik={formik}
                input={inputs.extra_fees1_receipt_group}
                submissionValue={{ file_code: submissionFileCode }}
              />
            </Grid>
          </>
        ) : null}
        {(currentTrialCount === 3 && !formik.values?.["naming_extra_fees_2"]) ||
        (currentTrialCount <= 3 &&
          formik.values?.["naming_extra_fees_2"] &&
          !formik.values?.["naming_extra_fees_2"]?.is_reviewed) ? (
          <>
            <InputWidget
              formik={formik}
              input={inputs.extra_fees2}
              submissionValue={{ file_code: submissionFileCode }}
            />
            <Grid
              item
              container
              xs={12}
              className="text-center"
              justifyContent="left"
              alignItems="left"
              spacing={2}
            >
              <InputWidget
                formik={formik}
                input={inputs.extra_fees2_receipt_number}
                submissionValue={{ file_code: submissionFileCode }}
              />
              <InputWidget
                formik={formik}
                input={inputs.extra_fees2_receipt_group}
                submissionValue={{ file_code: submissionFileCode }}
              />
            </Grid>
          </>
        ) : null}
        <InputWidget
          formik={formik}
          input={inputs.extra_attachments}
          submissionValue={{ file_code: submissionFileCode }}
        />

        <InputWidget
          formik={formik}
          input={inputs.naming_comment_input}
          submissionValue={{ file_code: submissionFileCode }}
        />
        {isLineExtension && (
          <InputWidget
            formik={formik}
            input={inputs.old_trade_name}
            submissionValue={{ file_code: submissionFileCode }}
          />
        )}
      </Grid>

      <Grid item xs={12} className="mt-3">
        <TableContainer
          component={Paper}
          style={{ maxWidth: "95%", margin: "0 auto", boxShadow: "none" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Drug Type</TableCell>
                <TableCell>Drug Name</TableCell>
                <TableCell>Arabic Drug Name</TableCell>
                <TableCell>Similarity</TableCell>
                <TableCell>Acceptance</TableCell>
                <TableCell>Recording</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{generateRows()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        item
        xs={12}
        container
        className="text-center mt-2 mb-2"
        justifyContent="center"
        alignItems="center"
      >
        {!isLineExtension && (
          <>
            <Button
              className="btn-transition-none btn-lg "
              variant="contained"
              onClick={addRow}
              disabled={rowCount === 20 || isPreviousStepHold}
              style={{ backgroundColor: "#358d38", color: "white" }}
            >
              Add Name
            </Button>
            <Button
              className="btn-transition-none btn-lg ml-3 btn-danger"
              variant="contained"
              disabled={rowCount === 1 || isPreviousStepHold}
              onClick={removeRow}
            >
              Remove Name
            </Button>
          </>
        )}
        <Button
          className="btn-transition-none btn-lg ml-3"
          variant="contained"
          color="primary"
          disabled={!enableSubmitButton || similarityArrayLoading.length}
          onClick={handleSubmitTask}
        >
          Submit
        </Button>
      </Grid>
      <Dialog open={modalOpen} onClose={toggleModal} maxWidth="lg" fullWidth>
        <DialogTitle className="text-center" style={{ background: "#F4F5FD" }}>
          Declaration Form
        </DialogTitle>
        <DialogContent style={{ background: "#F4F5FD" }}>
          <div
            style={{
              borderLeft: "solid 5px #0066cc",
              fontSize: "24px",
              backgroundColor: "white",
              height: "100px",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="ml-3">
              <InfoIcon style={{ color: "#0066cc", fontSize: "40px" }} />
            </div>
            <div style={{ fontWeight: "bold" }}>
              Does the company have a similar product?
            </div>
            <div className="mr-3">
              <Button
                variant="outlined"
                className="mr-4"
                style={
                  declaration.exists
                    ? {
                        background: "#0066cc",
                        borderColor: "#0066cc",
                        color: "white",
                      }
                    : { borderColor: "#201F2E", color: "black" }
                }
                onClick={() => {
                  setDeclaration({ ...declaration, exists: true });
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                style={
                  !declaration.exists
                    ? {
                        background: "#0066cc",
                        borderColor: "#0066cc",
                        color: "white",
                      }
                    : { borderColor: "#201F2E", color: "black" }
                }
                onClick={() => {
                  setDeclaration({ ...declaration, exists: false });
                }}
              >
                No
              </Button>
            </div>
          </div>
          {declaration.exists ? (
            <Grid
              style={{
                fontSize: "16px",
                backgroundColor: "white",
              }}
              className="mt-3 p-3"
              container
            >
              <Grid item xs={12}>
                <p>Dear EDA,</p>
              </Grid>
              <Grid
                item
                xs={12}
                className="d-flex flex-wrap"
                style={{ gap: "1rem" }}
              >
                <div>
                  <p>As</p>
                </div>
                <div>
                  <TextField
                    placeholder="Company Name"
                    value={declaration.company}
                  />
                </div>
                <div>
                  <p>
                    , we confirm that our company has an existing product
                    containing
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                className="d-flex flex-wrap"
                style={{ gap: "1rem" }}
              >
                <div>
                  <p>the same active ingredient as</p>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <TextField
                    placeholder="New active ingredient name"
                    fullWidth
                    onChange={(ev) => {
                      setDeclaration({
                        ...declaration,
                        newIngredient: ev.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className="d-flex" style={{ gap: "1rem" }}>
                <div>
                  <p>the product we already have is</p>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <TextField
                    placeholder="Existing active ingredient name"
                    fullWidth
                    onChange={(ev) => {
                      setDeclaration({
                        ...declaration,
                        existingIngredient: ev.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className="mt-3">
                <span>Best Wishes,</span>
              </Grid>
              <Grid item xs={12}>
                <span
                  style={{ color: "gray", opacity: "70%", fontSize: "14px" }}
                >
                  Electronic signature
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid
              style={{
                fontSize: "16px",
                backgroundColor: "white",
              }}
              className="mt-3 p-3"
              container
            >
              <Grid item xs={12}>
                <p>Dear EDA,</p>
              </Grid>
              <Grid
                item
                xs={12}
                className="d-flex flex-wrap"
                style={{ gap: "1rem" }}
              >
                <div>
                  <p>As</p>
                </div>
                <div>
                  <TextField
                    placeholder="Company Name"
                    value={declaration.company}
                  />
                </div>
                <div>
                  <p>
                    , we guarantee that our company doesn't have any products
                    containing
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                className="d-flex flex-wrap"
                style={{ gap: "1rem" }}
              >
                <div>
                  <p>the following active ingredient:</p>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <TextField
                    placeholder="Active ingredient name"
                    fullWidth
                    onChange={(ev) => {
                      setDeclaration({
                        ...declaration,
                        newIngredient: ev.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className="mt-3">
                <span>Best Wishes,</span>
              </Grid>
              <Grid item xs={12}>
                <span
                  style={{ color: "gray", opacity: "70%", fontSize: "14px" }}
                >
                  Electronic signature
                </span>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ background: "#F4F5FD" }}>
          <Button onClick={submitTask} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    task: state.details.task,
  };
};
export default connect(mapStateToProps, null)(NamingDrugNamesTable);
