import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import { fetchWorklistLayout } from "reducers/list";
import { getValidSubmissions, runActions } from "reducers/grouping";
import Table from "components/Table";

const AddSubmissionsModal = function ({
  open,
  setOpen,
  group,
  columns,
  getLayout,
  getValidSubmissions,
  submissions,
  count,
  addToGroup,
  removeFromGroup,
  addSubmissionsToGroup,
  addedSubmissions,
  setAddedSubmissions,
}) {
  const [layout, setLayout] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    if (open) {
      getLayout({ table: "addSubmissionToGroup" });
    } else {
      setAddedSubmissions([]);
    }
  }, [open]);

  useEffect(() => {
    if (open) setLayout(columns);
  }, [columns, open]);
  useEffect(() => {
    if (group?.groupId)
      getValidSubmissions({ groupId: group.groupId, page, limit });
  }, [group, page, limit]);

  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "90%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle className="bg-color-darkBlue">
        <span className="titleModal text-white">Add Submissions</span>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            container
            xs={3}
            justifyContent="flex-end"
            className="mb-3"
          >
            <Grid container item xs={5} justifyContent="flex-end">
              <Button
                variant="outlined"
                className="btn btn-secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid container item xs={5} justifyContent="flex-end">
              <Button
                variant="outlined"
                className="btn btn-primary"
                onClick={addSubmissionsToGroup}
                disabled={addedSubmissions.length == 0}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              className="mt-5"
              layoutStructure={layout}
              setLayoutStructure={setLayout}
              layoutData={submissions}
              currentTab={0}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              // setSort={setSort}
              tasksCount={count}
              // setSubmitTaskLoader={setSubmitTaskLoader}
              page={page}
              propsFunctions={{ addToGroup, removeFromGroup }}
            />{" "}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  columns: state.layout.addSubmissionToGroup,
  submissions: state.grouping.validSubmissions,
  count: state.grouping.validSubmissionsCount,
});

const mapDispatchToProps = (dispatch) => ({
  getLayout: (params) => dispatch(fetchWorklistLayout(params)),
  runActions: (data) => dispatch(runActions(data)),
  getValidSubmissions: (params) => dispatch(getValidSubmissions(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubmissionsModal);
