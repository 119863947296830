import {
  SEND,
  RECEIVE,
  EDIT,
  NOTIFICATION_MASSEGE,
  GET_NOTIFICATIONS_FULFILLED,
  EDIT_FAIL,
  EDIT_SUCCESS,
  IS_EDIT,
} from "./actionTypes";
import _ from "lodash";
const initialState = {
  messages: [],
  edit: { is_edit: false, submission_id: "" },
};

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case SEND: {
      return {
        ...state,
        isSending: true,
      };
    }
    case RECEIVE: {
      return {
        ...state,
        isRecieved: true,
      };
    }
    case EDIT: {
      return {
        ...state,
        isRecieved: true,
      };
    }
    case IS_EDIT: {
      return {
        ...state,
        edit: { is_edit: true, submission_id: action.payload.submission_id },
      };
    }
    case NOTIFICATION_MASSEGE: {
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    }
    case GET_NOTIFICATIONS_FULFILLED: {
      return {
        ...state,
        messages: _.orderBy(
          action.payload.data.rows.flatMap((messages) => messages),
          ["id"],
          ["desc"]
        ),
      };
    }
    default: {
      return state;
    }
  }
}
