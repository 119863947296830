import React, { useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Input,
  Grid,
  TextField,
} from "@material-ui/core";
import _ from "lodash";
import "./style.css";
import Autocomplete from "@material-ui/lab/Autocomplete";

const FilterInput = ({ column, options, setitem, filters, setFilters }) => {
  const [checkedValues, setCheckedValues] = useState([]);
  const [dateValues, setDateValues] = useState({
    from: "",
    to: "",
  });
  const [dateFromAndToValues, setDateFromAndToValues] = useState({});
  options = options && options.map((option) => option.name);
  const handleChange = (field, event, value, operator) => {
    addFilterData(field, event, value, operator);
  };

  const addFilterData = (field, event, value, operator, key) => {
    let filterObject;
    let filterIndex;
    if (field.json) {
      filterIndex = filters.findIndex((filter) => filter.field === field.key);
      filterObject = {
        field: field.key,
        value: typeof value === "string" ? value.toLowerCase() : value,
        operator: operator,
        json: true,
      };
    } else if (key !== undefined) {
      filterIndex = filters.findIndex((filter) => filter.field === key);
      filterObject = {
        field: key,
        value: typeof value === "string" ? value.toLowerCase() : value,
        operator: operator,
      };
    } else {
      filterIndex = filters.findIndex((filter) => filter.field === field.key);
      filterObject = {
        field: field.key,
        value: typeof value === "string" ? value.toLowerCase() : value,
        operator: operator,
      };
    }
    let newFilters = [...filters];
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObject;
    } else {
      newFilters.push(filterObject);
    }
    setFilters([...newFilters]);
  };
  const handleCheck = (field, event, item, value) => {
    if (item) {
      let newValue = [];
      if (value) {
        newValue = [...checkedValues, item];
        setCheckedValues(newValue);
      } else {
        newValue = checkedValues.filter((value) => value !== item);
        setCheckedValues(newValue);
      }
      addFilterData(field, event, newValue, "in");
    }
  };
  let DatesValue = [];
  var obj = {};
  const handelDateChange = (field, event, Date, key) => {
    obj = { ...dateValues, [key]: Date };
    DatesValue = Object.values(obj);
    setDateValues(obj);
    addFilterData(field, event, DatesValue, "between");
  };
  const handleDateFromAndTo = (field, event, Date, key) => {
    // obj = { ...dateFromAndToValues, [key]: Date };
    // DatesValue = Object.values(obj);
    // console.log({ DatesValue });
    // setDateFromAndToValues(obj);
    addFilterData(field, event, Date, "eq", key);
  };
  const inputs = {
    number: (
      <>
        <Grid className="NumberStyle">
          <span className="spanStyle">{column.name}</span>
          <Input
            style={{ width: "30px" }}
            className="inputStyle"
            name={column.key}
            id={column.key}
            type="number"
            inputProps={{ min: 0 }}
            onChange={(event) => {
              addFilterData(column, event, event.target.value);
            }}
          />
        </Grid>
      </>
    ),
    text: (
      <>
        <Grid container style={{ width: "98%" }}>
          <TextField
            fullWidth
            id="fullWidth"
            variant="outlined"
            label={column.name}
            name={column.key}
            type="text"
            onChange={(event) => {
              addFilterData(
                column,
                event,
                `%${event.target.value.toLowerCase()}%`,
                "like"
              );
            }}
          />
          {/* <span className="spanStyle">{}</span>
          <Input
            className="inputStyle"
            name={column.key}
            id={column.key}
            type="text"
            onChange={(event) => {
              addFilterData(column, event, event.target.value);
            }}
          /> */}
        </Grid>
      </>
    ),
    date: (
      <Grid container className="dateStyle" style={{ width: "98%" }}>
        <Grid item xs={6}>
          <span className="spanStyle">From</span>
          <TextField
            id="date"
            type="date"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();

              handelDateChange(column, event, event.target.value, "from");
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <span className="spanStyle">To</span>
          <TextField
            id="date"
            type="date"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();
              let currentDate = new Date(event.target.value);
              currentDate.setDate(currentDate.getDate());
              handelDateChange(column, event, currentDate.toISOString(), "to");
            }}
          />
        </Grid>
      </Grid>
    ),
    dateIncludeToday: (
      <Grid container className="dateStyle" style={{ width: "98%" }}>
        <Grid item xs={6}>
          <span className="spanStyle">From</span>
          <TextField
            id="date"
            type="date"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();

              handelDateChange(column, event, event.target.value, "from");
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <span className="spanStyle">To</span>
          <TextField
            id="date"
            type="date"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();
              let currentDate = new Date(event.target.value);
              currentDate.setDate(currentDate.getDate() + 1);
              handelDateChange(column, event, currentDate.toISOString(), "to");
            }}
          />
        </Grid>
      </Grid>
    ),
    dateFromAndTo: (
      <Grid container className="dateStyle" style={{ width: "98%" }}>
        <Grid item xs={6}>
          <span className="spanStyle">From</span>
          <TextField
            id="date"
            type="date"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();

              handleDateFromAndTo(
                column,
                event,
                event.target.value,
                column.keyFrom
              );
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <span className="spanStyle">To</span>
          <TextField
            id="date"
            type="date"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();
              let currentDate = new Date(event.target.value);
              currentDate.setDate(currentDate.getDate());
              handleDateFromAndTo(
                column,
                event,
                currentDate.toISOString(),
                column.keyTo
              );
            }}
          />
        </Grid>
      </Grid>
    ),
    enum: (
      <Grid
        container
        className="dateStyle"
        style={{ width: "95%", height: "100%" }}
      >
        {column &&
          column.enum &&
          column.enum.map((item, index) => (
            <Grid key={index} container>
              <FormControlLabel
                xs={12}
                control={
                  <Checkbox
                    onChange={(event, value) => {
                      event.persist();
                      handleCheck(column, event, event.target.name, value);
                    }}
                    color="default"
                    name={item}
                  />
                }
                label={item}
              />
            </Grid>
          ))}
      </Grid>
    ),
    string: (
      <Autocomplete
        style={{ width: "95%" }}
        multiple
        id="tags-outlined"
        options={options ? options : []}
        renderOption={(o) => o}
        getOptionLabel={(o) => (typeof o === "string" ? o : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={column.name}
            placeholder="search"
          />
        )}
        onChange={(event, value) => handleChange(column, event, value)}
      />
    ),
  };
  return (
    <React.Fragment key={column.key}>
      <Grid item style={column.input_layout || {}} className="pl-0">
        <div style={{ marginTop: "4%", marginLeft: "2%" }}>
          {inputs[String(column.inputLayout?.filterType)]}
        </div>
      </Grid>
    </React.Fragment>
  );
};
export default FilterInput;
