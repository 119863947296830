import React from "react";
import PdfViewer from "components/PdfViewer";
import ImageViewer from "components/ImageViewer";
import { apiConfig } from "api/utilities";

function ViewFile({ file, open, setOpen }) {
  return (
    <>
      {/* FIXME:reverse the condition to view all images extensions */}
      {file.extension === "jpeg" || file.extension === "png" ? (
        <ImageViewer
          serverImgSrc={`${apiConfig.baseUrl}attachment/preview?filePath=${file.avatarFd}`}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        <PdfViewer
          file={`${apiConfig.baseUrl}attachment/preview?filePath=${file.avatarFd}`}
          modal={open}
          setModal={setOpen}
        ></PdfViewer>
      )}
    </>
  );
}

export default ViewFile;
