import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Card,
  Button,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import client from "api/apiAuth/guestClient";
import { apiConfig } from "api/utilities";

function FileHistory({ openFileHistory, setOpenFileHistory, fileHistory }) {
  const [files, setFiles] = useState([]);
  const getFileHistory = async () => {
    let { data } = await client.get("/attachment/getFileHistory", {
      params: { attachment: fileHistory.id },
    });
    setFiles(
      data.attachments.filter(
        (attachment) => attachment.avatarFd != fileHistory.avatarFd
      )
    );
  };
  useEffect(() => {
    getFileHistory();
  }, [fileHistory]);
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={openFileHistory}
      onClose={() => setOpenFileHistory(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className=" font-weight-bold titleModal">{"File History"}</span>
      </DialogTitle>
      <DialogContent>
        <Grid style={{ padding: "0px", justifyContent: "flex-start" }}>
          <Card className="card-box bg-secondary">
            <div className="card-header-alt d-flex justify-content-between p-4">
              <div>
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                  {fileHistory?.document?.name_en}
                </h6>
              </div>
            </div>
            <div className="px-3 pb-3">
              <div className="scroll-area rounded bg-white shadow-overflow">
                <PerfectScrollbar>
                  <div className="p-3">
                    {files.length > 0 &&
                      files.map((file, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="">
                                  <p className="text-black">
                                    {file?.user?.username || "Missing User"}
                                  </p>
                                </div>
                                <small className="d-flex  align-items-center">
                                  <span className="pl-2 ">
                                    {new Date(
                                      file.createdAt
                                    ).toLocaleDateString("en-GB")}
                                  </span>
                                </small>
                              </div>
                              <div>
                                <a
                                  href={`${apiConfig.baseUrl}${
                                    file.avatarFd?.split("assets/")[1]
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    disableFocusRipple
                                    disableRipple
                                    className="btn btn-outline-primary btn-transition-none d-inline "
                                  >
                                    view file
                                  </Button>
                                </a>
                              </div>
                            </div>
                            <div className="divider my-3" />
                          </React.Fragment>
                        );
                      })}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </Card>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default FileHistory;
