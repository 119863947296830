import { Dialog, DialogTitle, Box, DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Draggable from "react-draggable";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import "./index.css";
function ImageViewer({ file, open, setOpen, local, serverImgSrc, URL }) {
  let [imgSrc, setImgSrc] = useState("");
  const zoomStep = 1;
  const maxScale = 5;
  const minScale = 1;
  const defaultScale = minScale;
  const [scale, setScale] = useState(defaultScale);
  const [version, setVersion] = useState(0);
  const zoomIn = () => {
    const newScale = scale + zoomStep;
    return newScale <= maxScale ? setScale(newScale) : setScale(maxScale);
  };
  const zoomOut = () => {
    const newScale = scale - zoomStep;
    return (
      newScale >= minScale ? setScale(newScale) : setScale(defaultScale),
      setVersion(version + 1)
    );
  };

  useEffect(() => {
    if (open && file && !file?.URL && !URL) {
      // let reader = new FileReader();
      // let url = reader.readAsDataURL(file);
      // console.log("FILEEE :", file, url);
      // reader.onloadend = function (e) {
      setImgSrc(file);
      // return;
      // };
    } else {
      ((open && file) || (open && URL)) && setImgSrc(file?.URL || URL);
    }
  }, [file, open]);

  const toggle = () => setOpen(!open);
  const onClose = () => setOpen(false);
  const isDraggable = scale > 1;
  return (
    <Dialog
      open={open}
      onClose={toggle}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      classes={{ paper: "shadow-sm-dark rounded-sm" }}
    >
      <DialogTitle id="id">
        <Box
          alignItems="center"
          spacing={2}
          columns={12}
          style={{
            height: "20px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "space-between",
          }}
        >
          <Box style={{ direction: "ltr" }} xs={6}>
            <ZoomInIcon onClick={zoomIn} />
            <ZoomOutIcon onClick={zoomOut} />
          </Box>

          <Box style={{ direction: "rtl" }} xs={6}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {local ? (
        <DialogContent className="imagesBox">
          <Draggable disabled={!isDraggable} key={version}>
            <div style={isDraggable ? { cursor: "move" } : null}>
              <img
                style={{
                  transform: `scale(${scale})`,
                }}
                draggable="false"
                src={imgSrc}
                alt="..."
              />
            </div>
          </Draggable>
        </DialogContent>
      ) : (
        <DialogContent className="imagesBox">
          <Draggable disabled={!isDraggable} key={version}>
            <div style={isDraggable ? { cursor: "move" } : null}>
              <img
                style={{
                  transform: `scale(${scale})`,
                }}
                draggable="false"
                src={serverImgSrc}
                alt="..."
              />
            </div>
          </Draggable>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default ImageViewer;
