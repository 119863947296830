export const config = (mapper) => [
  {
    tabId: 1,
    sections: [
      {
        id: 1,
        title: "Event Info",
        attributes: [
          // {
          //   label: "Event Number",
          //   type: "string",
          //   key: "submission.values.VEEVA_No",
          //   style: {
          //     gridSize: 4,
          //     display: true,
          //   },
          // },
          {
            label: "Event Name",
            type: "string",
            key: "submission.values.event_name",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Event Type",
            type: "string",
            key: "submission.values.event_type",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Event Objective",
            type: "string",
            key: "submission.values.event_objective",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Event Date From",
            type: "string",
            key: "submission.values.Event_Date_From",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Event Date To",
            type: "string",
            key: "submission.values.Event_Date_To",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Venue",
            type: "string",
            key: "submission.values.venue",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Product",
            type: "string",
            key: "submission.values.Product",
            style: {
              gridSize: 4,
              display: true,
            },
          },
        ],
      },
      {
        id: 2,
        title: "Invitees",
        style: { gridSize: 12, display: true },
        useNewRenderer: true,
        attributes: [
          {
            label: "HCPs",
            type: "string",
            key: "doctors",
            isMany: true,
            directKey: "submission.doctor_submissions",
            style: {
              gridSize: 4,
              display: true,
            },
            attributes: [
              {
                name: "HCP Info",
                type: "string",
                key: "doctor",
                style: {
                  gridSize: 4,
                  display: true,
                },
                attributes: [
                  {
                    label: "HCP Name",
                    type: "string",
                    key: "name",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "HCP Email",
                    type: "string",
                    key: "email",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                ],
              },

              {
                label: "Travel Distance From",
                type: "string",
                key: "travel_distance_from",
                style: {
                  gridSize: 4,
                  display: true,
                },
              },
              {
                label: "Travel Distance To",
                type: "string",
                key: "travel_distance_to",
                style: {
                  gridSize: 4,
                  display: true,
                },
              },
              {
                label: "HCP FMV",
                type: "string",
                key: "doctor_fmv",
                style: {
                  gridSize: 4,
                  display: true,
                },
              },

              {
                label: "HCP Locality",
                type: "string",
                key: "hcp_locality",
                style: {
                  gridSize: 4,
                  display: true,
                },
              },
              {
                label: "HCP Country",
                type: "string",
                key: "hcp_country",
                style: {
                  gridSize: 4,
                  display: true,
                },
              },
              {
                label: "Total Package",
                type: "string",
                key: "doctor_package",
                style: {
                  gridSize: 4,
                  display: true,
                },
              },
              {
                label: "",
                key: "id",
                filterKey: "dr_submission_id",
                style: {
                  gridSize: 3,
                },
                type: "component",
                componentName: "AttrWithAttachment",
              },
              {
                name: "Engagements Info",
                type: "string",
                key: "engagements",
                isMany: true,
                style: {
                  gridSize: 4,
                  display: true,
                },
                attributes: [
                  {
                    label: "Veeva Code",
                    type: "string",
                    key: "veeva_code",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "HCP Role",
                    type: "string",
                    key: "doctor_role",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Symposium Date",
                    type: "date1",
                    key: "symposium_date",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Talk Title",
                    type: "string",
                    key: "talk_title",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Talk Objective",
                    type: "string",
                    key: "talk_objective",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Talk Time in Mins",
                    type: "string",
                    key: "talk_time",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Presentation Type",
                    type: "string",
                    key: "presentation_type",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Disease Area",
                    type: "string",
                    key: "disease_area",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                  },
                  {
                    label: "Activity type",
                    type: "string",
                    key: "active_type_table",
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                    attributes: [
                      {
                        label: "Activity type",
                        type: "string",
                        key: "name",
                        style: {
                          gridSize: 4,
                          display: true,
                        },
                      },
                    ],
                  },
                  {
                    name: "Product Info",
                    type: "string",
                    key: "product",
                    isMany: true,
                    style: {
                      gridSize: 4,
                      display: true,
                    },
                    attributes: [
                      {
                        label: "name",
                        type: "string",
                        key: "name",
                        style: {
                          gridSize: 4,
                          display: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      // 	id: 2,
      // 	title: "Add Invitee",
      // 	useNewRenderer: true,
      // 	style: { gridSize: 12, display: true },
      // 	attributes: [
      // 		{
      // 			name: "Product",
      // 			type: "string",
      // 			key: "dosage_form",
      // 			directKey: "submission.drugs[0]",
      // 			style: { gridSize: 4 },
      // 		},
      // 		{
      // 			name: "Reference (for each product)",
      // 			key: "reference",
      // 			directKey: "submission.drugs[0]",
      // 			style: { gridSize: 4 },
      // 			type: "string",
      // 		},
      // 		{
      // 			name: "Reference Country",
      // 			key: "reference_country",
      // 			directKey: "submission.drugs[0]",
      // 			style: { gridSize: 4 },
      // 			type: "string",
      // 		},
      // 		{
      // 			title: "Ingredients",
      // 			name: "Ingredients",
      // 			key: "ingredients",
      // 			directKey: "submission.drugs[0].ingredients",
      // 			style: { gridSize: 12, display: true },
      // 			type: "string",
      // 			isMany: true,
      // 			attributes: [
      // 				{
      // 					label: "Active Ingredient",
      // 					type: "string",
      // 					key: "active_ingredient",
      // 					style: {
      // 						gridSize: 4,
      // 					},
      // 				},
      // 				{
      // 					label: "Unit/Strength",
      // 					type: "string",
      // 					key: "full_strength",
      // 					style: {
      // 						gridSize: 4,
      // 					},
      // 				},
      // 				{
      // 					title: "Salts",
      // 					name: "Salts",
      // 					key: "salts",
      // 					style: { gridSize: 12, display: true },
      // 					type: "string",
      // 					isMany: true,
      // 					attributes: [
      // 						{
      // 							label: "Name",
      // 							type: "string",
      // 							key: "name",
      // 							style: {
      // 								gridSize: 4,
      // 							},
      // 						},
      // 						{
      // 							label: "Unit/Strength",
      // 							type: "string",
      // 							key: "full_strength",
      // 							style: {
      // 								gridSize: 4,
      // 							},
      // 						},
      // 					],
      // 				},

      // 				{
      // 					title: "Suppliers",
      // 					name: "Suppliers",
      // 					key: "suppliers",
      // 					style: { gridSize: 12, display: true },
      // 					type: "string",
      // 					isMany: true,
      // 					attributes: [
      // 						{
      // 							label: "Name",
      // 							type: "string",
      // 							key: "name",
      // 							style: {
      // 								gridSize: 4,
      // 							},
      // 						},
      // 						{
      // 							label: "",
      // 							key: "attachment_id",
      // 							style: {
      // 								gridSize: 4,
      // 							},
      // 							type: "component",
      // 							componentName: "AttrWithAttachment",
      // 						},
      // 					],
      // 				},
      // 			],
      // 		},
      // 	],
      // },
    ],
  },
];
