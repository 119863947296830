import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import {
  getTaskDetails,
  assignTask,
  handleTaskAssignment,
  setAllAttachments,
  getTimeline,
} from "reducers/details/detailsAction";
import { connectSocket, editSubmission } from "reducers/socket";
import ConfirmModal from "components/ConfirmModal";
import FormModal from "components/FormModal";
import Alert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  // faCross,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import DetailsConfig from "utils/DetailsConfig/DetailsConfig";
import Slot from "components/TimeSlots";
import { Collapse, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import { SyncLoader } from "react-spinners";
import BlockUi from "react-block-ui";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import SocketClient from "socket/socketClient";

const EdaDetails = (props) => {
  const submission_id = props.match.params.id;
  const task_id = props.match.params.taskId;
  const {
    getTaskDetails,
    getTimeline,
    task,
    currentUser,
    assignTask,
    setAllAttachments,
    taskLoading,
    connectSocket,
    editSubmission,
    edit,
  } = props;

  const [detailsParts, setDetailsParts] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [takeActionFlag, setTakeActionFlag] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    question: "",
    message: "",
  });
  let history = useHistory();
  const [formModal, setFormModal] = useState(false);
  const [slotsModal, setSlotsModal] = useState(false);
  const [notification, setNotification] = useState(false);
  useEffect(() => {
    connectSocket(currentUser);

    if (
      task &&
      task.submission.status === "Modification" &&
      task_id !== undefined
    ) {
      getTaskDetails({ submission_id, task_id });
    } else {
      getTaskDetails({ submission_id });
    }
    getTimeline({ submission_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  editSubmission("editSubmission");
  const handleAssignment = () => {
    assignTask({
      submission_id: submission_id,
      task_status: "Pending",
      step_id: task.step.id,
    }).then((res) => {
      getTaskDetails({ submission_id });
      getTimeline({ submission_id });
    });
  };
  useEffect(() => {
    edit.is_edit &&
      history.location.pathname.startsWith(`/details/${edit.submission_id}`) &&
      Swal.fire({
        text: "Event has been edited, please reload to see the update.",
        icon: "warning",
        // confirmButtonColor: "#d79862",
        dangerMode: true,
        confirmButtonText: `Reload this page `,
        confirmButtonColor: "#0066cc",
      }).then(function (result) {
        window.location.reload();
      });
  }, [edit]);
  // Set a dismissable alert notifying the user of a previous reviewer's comment
  const setAlert = () => {
    return (
      <Collapse in={notification} style={{ width: "100%" }}>
        <Alert
          className="my-4"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setNotification(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="warning"
        >
          <h5> Previous comment</h5>
          <b>{task?.last_task?.comment}</b>
        </Alert>
      </Collapse>
    );
  };

  // Create messages components from submission values
  const constructMessages = () => {
    if (!task || !("details_page_messages" in task.submission.values))
      return null;
    const messages = [];
    for (const message of task.submission.values["details_page_messages"]) {
      if (message.type === "AlertMessageSuccess") {
        messages.push(
          <Alert icon={false} className="mb-4" severity="success">
            <div className="d-flex align-items-center align-content-center">
              <span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white rounded-sm text-success">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span>
                <strong className="d-block">Success!</strong> This request have
                been completed!
              </span>
            </div>
          </Alert>
        );
      } else if (message.type === "AlertMessageError") {
      } else if (message.type === "AlertMessageSuccessNoIcon") {
        messages.push(
          <Alert icon={false} className="mb-4" severity="success">
            <div className="d-flex align-items-center align-content-center">
              <span
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></span>
            </div>
          </Alert>
        );
      } else if (message.type === "AlertMessageErrorNoIcon") {
        messages.push(
          <Alert icon={false} className="mb-4" severity="error">
            <div className="d-flex align-items-center align-content-center">
              <span
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></span>
            </div>
          </Alert>
        );
      } else if (message.type === "AlertMessageWarning") {
        messages.push(
          <Alert icon={false} className="mb-4" severity="warning">
            <div className="d-flex align-items-center align-content-center">
              <span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white rounded-sm text-warning">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </span>
              <span
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></span>
            </div>
          </Alert>
        );
      } else if (message.type === "AlertMessageWarningNoIcon") {
        messages.push(
          <Alert icon={false} className="mb-4" severity="warning">
            <div className="d-flex align-items-center align-content-center">
              <span
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></span>
            </div>
          </Alert>
        );
      }
    }
    return messages;
  };
  useEffect(() => {
    setTakeActionFlag(false);
    if (task) {
      task?.submission["attachments"] &&
        setAllAttachments(task?.submission["attachments"]);
      const config = DetailsConfig(mapper);
      if (
        !task.step.edit_form ||
        (task.step.edit_form &&
          task.submission.attachments
            .filter((attachment) => !attachment.is_reviewed)
            .every((attachment) => attachment.task_id == task.id))
      ) {
        setTakeActionFlag(true);
      }
      setDetailsParts(config);
    }
    setNotification(!!task?.last_task?.comment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);
  const openModal = ({ open }) => {
    setFormModal(open);
  };

  let mapper = {
    task,
    setModalInfo: setModalInfo,
    openModal: openModal,
    setSlotsModal: setSlotsModal,
    setConfirmationModal: setConfirmationModal,
    taskUsers: task?.users,
    submission_id,
    currentUser,
    takeActionFlag,
    setTakeActionFlag,
  };
  let alertComp = null;
  if (task?.submission.status === "Released") {
    alertComp = (
      <Alert icon={false} className="mb-4" severity="success">
        <div className="d-flex align-items-center align-content-center">
          <span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white rounded-sm text-success">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <span>
            <strong className="d-block">Success!</strong> This request have been
            completed!
          </span>
        </div>
      </Alert>
    );
  }
  return (
    <BlockUi
      className="block-loading-overlay-light"
      tag="div"
      blocking={taskLoading || !task}
      loader={<SyncLoader color="#4f55a28f" loading={taskLoading} />}
    >
      {alertComp}
      {constructMessages()}
      {task?.users?.some((user) => user.id === currentUser.id) &&
      task.is_assigned
        ? setAlert()
        : null}
      {detailsParts
        .filter((part) => {
          if (!part.constraints) return true;
          let result = applyDetailsConstraints(part.constraints);
          return result.display;
        })
        .map((part, partIndex) => {
          let PartComponent = part.component;
          let componentProps = {};
          part.componentProps.forEach((element) => {
            componentProps = { ...componentProps, [element]: mapper[element] };
          });
          return (
            <PartComponent
              part={part}
              {...componentProps}
              partIndex={partIndex}
              key={partIndex}
              getTaskDetails={getTaskDetails}
              getTimeline={getTimeline}
            />
          );
        })}

      <ConfirmModal
        modal={confirmationModal}
        setModal={setConfirmationModal}
        info={modalInfo}
        callBack={handleAssignment}
      ></ConfirmModal>
      {formModal && (
        <FormModal
          open={formModal}
          setOpen={setFormModal}
          transitions={task?.step.transitions}
          step_id={task?.step.id}
          edit_form={task?.step.edit_form}
          submission={task?.submission}
          getTaskDetails={getTaskDetails}
          getTimeline={getTimeline}
          task_id={task?.id}
        />
      )}
      {slotsModal && (
        <Slot open={slotsModal} setOpen={setSlotsModal} task={task} />
      )}
    </BlockUi>
  );
};
const mapStateToProps = (state) => {
  return {
    task: state.details.task,
    taskLoading: state.details.taskLoading,
    currentUser: state.auth.user,
    edit: state.socket.edit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllAttachments: (attachments) =>
      dispatch(setAllAttachments(attachments)),
    assignTask: (body) => dispatch(assignTask(body)),
    connectSocket: (user) => dispatch(connectSocket(user)),
    getTaskDetails: (info) =>
      dispatch(getTaskDetails({ ...info, task_status: "Pending" })),
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
    getTimeline: (params) => dispatch(getTimeline(params)),
    editSubmission: (eventName, message) =>
      dispatch(editSubmission(eventName, message)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EdaDetails);
