import axios from "axios";

import client from "api/apiAuth/guestClient";
import { SetRequestsTokens } from "reducers/general/generalActions";

function uploadFile(file, documentName,setProgress,dispatch) {
    let formData = new FormData();
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    formData.append("image", file);

    dispatch(SetRequestsTokens({
      url: `generic/uploadFile?documentName=${documentName}`,
      key: documentName,
      token: source,
    }))
    
    return client.post(
      `generic/uploadFile?documentName=${documentName}`,
      formData,
      {
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgress(progress);
        },
      }
    );
  }
  export default uploadFile