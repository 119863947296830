import React from "react";

export default function ErrorMessage({
  isTouched,
  errorMessage,
  enableSubmit,
}) {
  return (isTouched || enableSubmit) && errorMessage ? (
    <div className="text-danger" style={{ fontWeight: 400 }}>
      {typeof errorMessage === "object"
        ? Object.entries(errorMessage).map(([key, value]) => (
            <div>
              {key}: {value}
            </div>
          ))
        : errorMessage}
    </div>
  ) : null;
}
