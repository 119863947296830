import {
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Table,
  Modal,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import client from "api/apiAuth/guestClient";
import SpinnerLoader from "components/SpinnerLoader";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function PocaShowPage({ match }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const { drugName } = match.params;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([40, 100]);
  const [phoneticScore, setPhoneticScore] = useState([0, 100]);
  const [orthographicScore, setOrthographicScore] = useState([0, 100]);
  const [similarDrugs, setSimilarDrugs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getThresholds = async () => {
    const thresholds = await client.post("generic/find", {
      where: { key: ["threshold", "max_threshold"] },
      model: "SystemSetting",
    });

    const minThreshold = thresholds.data.rows.find(
      (cur) => cur.key == "threshold"
    );
    const maxThreshold = thresholds.data.rows.find(
      (cur) => cur.key == "max_threshold"
    );

    setValue([minThreshold.value * 1, maxThreshold.value * 1]);
  };

  useEffect(() => {
    getThresholds();

    if (drugName) {
      showSimilarityList(false);
    }
  }, []);

  const handleChange = (event, pocaType, currentField) => {
    const mapper = {
      mergedScore: [value, setValue],
      phoneticScore: [phoneticScore, setPhoneticScore],
      orthographicScore: [orthographicScore, setOrthographicScore],
    };
    if (event.target.value < 0 || event.target.value > 100) {
      return;
    }
    let newValue = [...mapper[currentField][0]];
    if (event.target.value == "") {
      newValue[pocaType] = 0;
    } else {
      newValue[pocaType] = event.target.value * 1;
    }
    mapper[currentField][1](newValue);
  };

  const handleRange = (state, setState) => {
    let newRange = [...state];
    if (newRange[0] >= newRange[1]) {
      const temp = newRange[0];
      newRange[0] = newRange[1];
      newRange[1] = temp;
      setState([...newRange]);
    }

    return newRange;
  };

  const showSimilarityList = (filterAllScores) => {
    let AllScores = [];
    let body;
    setIsLoading(true);
    setOpen(false);
    if (filterAllScores) {
      const allValues = [
        [value, setValue],
        [orthographicScore, setOrthographicScore],
        [phoneticScore, setPhoneticScore],
      ];
      for (let cur of allValues) {
        const newVal = handleRange(cur[0], cur[1]);
        AllScores.push(newVal);
      }

      body = {
        drugName,
        minThreshold: AllScores[0][0],
        maxThreshold: AllScores[0][1],
        orthographicScore: AllScores[1],
        phoneticScore: AllScores[2],
        algorithmName: "getPocaScore",
        filterAllScores,
      };
    } else {
      const newVal = handleRange(value, setValue);
      body = {
        drugName,
        minThreshold: newVal[0],
        maxThreshold: newVal[1],
        algorithmName: "getPocaScore",
      };
    }
    // let newRange = [...value];
    // if (newRange[0] >= newRange[1]) {
    //   const temp = newRange[0];
    //   newRange[0] = newRange[1];
    //   newRange[1] = temp;
    //   setValue([...newRange]);
    // }

    async function fetchSimilarDrugs() {
      const result = await client.post("algorithm/runAlgorithm", body);

      setIsLoading(false);
      setSimilarDrugs(result.data.finalResult);
    }
    fetchSimilarDrugs();
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={modalStyle} className={classes.paper}>
          <Grid container spacing={2}>
            {[
              ["mergedScore", value],
              ["phoneticScore", phoneticScore],
              ["orthographicScore", orthographicScore],
            ].map((cur) => (
              <>
                <Grid item xs={12}>
                  <Typography variant="p">{cur[0]}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    label={"From"}
                    onChange={(e) => handleChange(e, 0, cur[0])}
                    disabled={isLoading}
                    value={cur[1][0]}
                    variant="outlined"
                    type={"number"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    label="To"
                    onChange={(e) => handleChange(e, 1, cur[0])}
                    disabled={isLoading}
                    value={cur[1][1]}
                    variant="outlined"
                    type={"number"}
                  />
                </Grid>
              </>
            ))}
          </Grid>
          <div style={{ padding: 20 }}>
            <Button
              style={{ marginLeft: "auto" }}
              className="assignButton"
              variant="contained"
              onClick={() => showSimilarityList(true)}
            >
              filter
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          background: "rgba(0,0,0,.08)",
          padding: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>Similarity List</h2>
        {!isLoading && (
          <IconButton onClick={() => setOpen(true)}>
            <SettingsIcon />
          </IconButton>
        )}
      </div>

      {isLoading ? (
        <div style={{ width: "100%" }}>
          <SpinnerLoader />
        </div>
      ) : (
        <>
          <div style={{ minWidth: 700 }}>
            {
              <div>
                {similarDrugs.length ? (
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none" }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Drug Name</TableCell>
                          <TableCell>Composition</TableCell>
                          <TableCell>Merged Score</TableCell>
                          <TableCell>Phonetical Score</TableCell>
                          <TableCell>orthographical Score</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {similarDrugs.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.drugName}</TableCell>
                            <TableCell>{item.composition}</TableCell>
                            <TableCell>{item.mergedScore} %</TableCell>
                            <TableCell>{item.phoneticScore} %</TableCell>
                            <TableCell>{item.orthographicScore} %</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <p>There is no similarity higher than threshold</p>
                )}
              </div>
            }
          </div>
        </>
      )}
    </>
  );
}
