import React, { useState, useEffect } from "react";
import DocumentGeneration from "./DocumentGeneration";
import SendEmail from "./sendEmail";
import AssignDoctorModal from "./assignDoctorModal";
import GroupSectionModal from "./GroupSectionModal";
import GenerateDocumentForGroup from "./generateDocumentForGroup";
import ViewFile from "../../EdaDetails/components/ViewFile";
import FormModal from "components/FormModal";

const CustomActionRenderer = ({
  open,
  setOpen,
  group,
  action,
  submissionIds,
  onActionComplete,
  runActions,
  getTaskDetails,
  getTimeline,
  task_id,
  submission,
  edit_form,
  step_id,
  transitions,
}) => {
  const runAferTransitionAction = () => {
    runActions({
      groupId: group.groupId,
      submissionIds,
      actionId: action.id,
    }).then((res) => onActionComplete());
  };
  const [attachment, setAttachment] = useState(null);
  useEffect(() => {
    open &&
      group &&
      group.submissions.forEach((sub) => {
        if (submissionIds && sub.id == submissionIds[0]) {
          sub.attachments.forEach((attach) => {
            attach.document.key == action.attributes.fileKey &&
              setAttachment({ ...attachment, ...attach });
          });
        }
      });
  }, [open]);
  if (!open) return null;

  if (action.attributes.type === "DocumentGeneration") {
    return (
      <DocumentGeneration
        action={action}
        setOpen={setOpen}
        open={open}
        group={group}
        submissionIds={submissionIds}
        onActionComplete={onActionComplete}
      />
    );
  } else if (action.attributes.type === "SendEmail") {
    return (
      <SendEmail
        action={action}
        setOpen={setOpen}
        open={open}
        group={group}
        submissionIds={submissionIds}
        onActionComplete={onActionComplete}
      />
    );
  } else if (action.attributes.type === "GroupSectionModal") {
    return (
      <GroupSectionModal
        action={action}
        setOpen={setOpen}
        open={open}
        group={group}
        submissionIds={submissionIds}
        onActionComplete={onActionComplete}
      />
    );
  } else if (action.attributes.type === "GenerateDocumentForGroup") {
    return (
      <GenerateDocumentForGroup
        action={action}
        setOpen={setOpen}
        open={open}
        group={group}
        submissionIds={submissionIds}
        onActionComplete={onActionComplete}
      />
    );
  } else if (action.attributes.type === "ViewFile") {
    return (
      attachment && <ViewFile file={attachment} open={open} setOpen={setOpen} />
    );
  } else if (action.attributes.type === "AssignDoctorModal") {
    return (
      <AssignDoctorModal
        action={action}
        setOpen={setOpen}
        open={open}
        group={group}
        submissionIds={submissionIds}
        onActionComplete={onActionComplete}
      />
    );
  } else if (action.attributes.type === "transitionModal") {
    return (
      <FormModal
        setOpen={setOpen}
        open={open}
        transitions={transitions}
        submission={submission}
        getTaskDetails={getTaskDetails}
        getTimeline={getTimeline}
        edit_form={edit_form}
        task_id={task_id}
        step_id={step_id}
        runAferTransitionAction={runAferTransitionAction}
      />
    );
  }
  return null;
};

export default CustomActionRenderer;
