const conditionals = require("../conditionals/submissionInfo");

export const config = (mapper) => {
  return {
    sections: [
      {
        id: 1,
        attributes: [
          {
            name: "ID",
            key: "submission.file_code",
            type: "integer",
            style: { gridSize: 1, display: true },
          },
          {
            name: "Application Date",
            key: "submission.createdAt",
            type: "date",
            style: { gridSize: 2, display: true },
          },
          {
            name: "Status",
            key: "submission.status",
            type: "string",
            style: { gridSize: 1, display: true },
            contentClass: `${mapper.task?.submission?.status} w-159`,
          },
          {
            name: "Current Step",
            key: "step.name",
            type: "string",
            style: { gridSize: 2, display: true },
          },
        ],
        actions: [
          {
            gridSize: 3,
            classes: "takeActionButton",
            onClick: {
              functionName: "handleAssignClick",
            },
            label: "Assign myself",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "notEqual",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser?.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    checkValue: mapper.task.submission.status,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: "inQueue",
                  },
                  {
                    key: "id",
                    checkValue: mapper.task?.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [30, 31],
                  },
                ],
                conditionsEquation: "$0 && $1 && !$2 && !$3",
              },
            },
          },

          {
            gridSize: 4,
            classes: "takeActionButton takeActionButton--lg",
            onClick: {
              functionName: "openEditForm",
            },
            label: "Update Event Form",
            constraints: {
              display: {
                conditions: [
                  // {
                  //   key: "id",
                  //   checkValue: mapper.currentUser.id,
                  //   operator: "find",
                  //   functionName: "applyArrayMethods",
                  //   actualValue: mapper.taskUsers,
                  // },
                  // {
                  // 	key: "id",
                  // 	checkValue: mapper.task.step.id,
                  // 	operator: "equal",
                  // 	functionName: "applyMathOperators",
                  // 	actualValue: 513,
                  // },

                  // {
                  //   key: "id",
                  //   checkValue: 6,
                  //   operator: "equal",
                  //   functionName: "applyMathOperators",
                  //   actualValue: mapper.currentUser.id,
                  // },
                  {
                    key: "name",
                    checkValue: ["Admin"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: "Modification",
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.submission.status,
                  },
                  {
                    checkValue: new Date(new Date().setHours(0, 0, 0, 0)),
                    operator: "lessThanOrEq",
                    functionName: "applyMathOperators",
                    actualValue: new Date(new Date( mapper.task?.submission.values.event_date_to).setHours(0,0,0,0))
                   
                    // actualValue: new Date(
                    //   new Date(
                    //     mapper.task?.submission.values.event_date_from
                    //   ).setDate(
                    //     new Date(
                    //       mapper.task?.submission.values.event_date_from
                    //     ).getDate() - 1
                    //   )
                    // ),
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser?.id,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper?.task?.submission?.user?.id,
                  },
                ],
                conditionsEquation: "($0) || (!$1 && $2 && $3) ",
              },
            },
          },

          {
            gridSize: 3,
            classes: "dequeueActionBtn",
            onClick: {
              functionName: "dequeueSubmission",
            },
            label: "get out of queue",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: mapper.task.submission.status,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: "inQueue",
                  },
                  {
                    key: "name",
                    checkValue: mapper.task.step?.queue?.assignee?.role,
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "$0 && $1 ",
              },
            },
          },

          {
            gridSize: 3,
            classes: "takeActionButton",
            onClick: {
              functionName: "openTakeActionModal",
            },
            label: "Take action",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task.step.id,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: 5,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [3, 7],
                  },
                  {
                    checkValue: mapper.task.submission.status,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: "inQueue",
                  },
                  {
                    checkValue: mapper.task.task_status,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: "Pending",
                  },
                  // {
                  //   checkValue:
                  //     mapper.task.submission.values.values
                  //       .second_approver_decision,
                  //   operator: "equal",
                  //   functionName: "applyMathOperators",
                  //   actualValue: "Pending",
                  // },
                ],
                conditionsEquation: "($0 && $1 && !$2 && !$3) && !$4 && $5",
              },
              disable: {
                conditions: [
                  {
                    conditionalFunction: function () {
                      return conditionals.disableTakeActionWhen(mapper);
                    },
                  },
                ],
                conditionsEquation: "$0",
              },
            },
          },
          {
            gridSize: 3,
            classes: "takeActionButton",
            onClick: {
              functionName: "handleRedirect",
            },
            label: "Start Naming",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task.step.id,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: 11,
                  },
                ],
                conditionsEquation: "$0 && $1 && $2",
              },
            },
          },
          {
            gridSize: 3,
            classes: "takeActionButton takeActionButton--lg",
            onClick: {
              functionName: "redirectToSoftFilesForm",
            },
            label: "Start Soft Files",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task.step.id,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: 15,
                  },
                ],
                conditionsEquation: "$0 && $1 && $2",
              },
            },
          },
          {
            gridSize: 3,
            type: "component",
            name: "assignUserSameRole",
            roleId: 2,
            constraints: {
              display: {
                conditions: [
                  //0
                  {
                    key: "id",
                    checkValue: mapper.task?.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [3],
                  },
                  //$1
                  {
                    key: "name",
                    checkValue: "Approver",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  //$2
                  {
                    key: "id",
                    checkValue: mapper.task?.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [4],
                  },
                  //$3
                  {
                    key: "name",
                    checkValue: "Approver",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  //$4
                  {
                    key: "second_approver_decision",
                    checkValue: "second_approver_decision",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.task.submission.values,
                  },
                  //$5
                  {
                    checkValue: mapper.currentUser.id,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.submission.values.second_approver,
                  },
                  //$6
                  {
                    key: "first_approver_decision",
                    checkValue: "first_approver_decision",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.task.submission.values,
                  },
                  //$7
                  {
                    checkValue: mapper.currentUser.id,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.submission.values.first_approver,
                  },
                ],
                conditionsEquation:
                  "(!(($4 && $5)|| ($6 && $7) ) &&($0 && $1) || ( $2 && $3)  )",
              },
            },
          },
          // {
          //   gridSize: 12,
          //   type: "component",
          //   name: "userWorking",
          //   constraints: {
          //     display: {
          //       conditions: [
          //         {
          //           checkValue: true,
          //           operator: "equal",
          //           functionName: "applyMathOperators",
          //           actualValue: mapper.task.is_assigned,
          //         },
          //         {
          //           key: "id",
          //           checkValue: mapper.currentUser.id,
          //           operator: "find",
          //           functionName: "applyArrayMethods",
          //           actualValue: mapper.taskUsers,
          //         },
          //         {
          //           checkValue: mapper.task.submission.status,
          //           operator: "notEqual",
          //           functionName: "applyMathOperators",
          //           actualValue: "inQueue",
          //         },
          //       ],
          //       conditionsEquation: "$0 && $1 && $2",
          //     },
          //   },
          // },
          {
            gridSize: 3,
            classes: "takeActionButton takeActionButton--lg",
            onClick: {
              functionName: "redirectToHardFilesForm",
            },
            label: "Start Hard Files",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [38, 75],
                  },
                ],
                conditionsEquation: "$0 && $1 && $2",
              },
            },
          },
          // {
          //   gridSize: 3,
          //   type: "component",
          //   name: "assign",
          //   constraints: {
          //     display: {
          //       conditions: [
          //         {
          //           key: "id",
          //           checkValue: mapper.currentUser.id,
          //           operator: "find",
          //           functionName: "applyArrayMethods",
          //           actualValue: mapper.taskUsers,
          //         },
          //         {
          //           key: "id",
          //           checkValue: mapper.task.step.id,
          //           operator: "equal",
          //           functionName: "applyMathOperators",
          //           actualValue: 10,
          //         },
          //       ],
          //       conditionsEquation: " $0 && ($1 || $2)",
          //     },
          //   },
          // },
        ],
      },
    ],
  };
};
