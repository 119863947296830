import { Grid } from "@material-ui/core";
import React from "react";
const Declaration = ({ task }) => {
  const declaration = task?.submission?.values?.naming?.declaration;
  return declaration ? (
    <>
      {declaration.exists ? (
        <Grid
          style={{
            fontSize: "16px",
            backgroundColor: "white",
          }}
          className="mt-3 p-3"
          container
        >
          <Grid item xs={12}>
            <p>Dear EDA,</p>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex flex-wrap"
            style={{ gap: "1rem" }}
          >
            <div>
              <p>As</p>
            </div>
            <div>
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  color: "gray",
                }}
              >
                {declaration.company}
              </span>
            </div>
            <div>
              <p>
                , we confirm that our company has an existing product containing
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex flex-wrap"
            style={{ gap: "1rem" }}
          >
            <div>
              <p>the same active ingredient as</p>
            </div>
            <div style={{ flexGrow: "1" }}>
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  color: "gray",
                }}
              >
                {declaration.newIngredient}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} className="d-flex" style={{ gap: "1rem" }}>
            <div>
              <p>the product we already have is</p>
            </div>
            <div style={{ flexGrow: "1" }}>
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  color: "gray",
                }}
              >
                {declaration.existingIngredient}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} className="mt-3">
            <span>Best Wishes,</span>
          </Grid>
          <Grid item xs={12}>
            <span style={{ color: "gray", opacity: "70%", fontSize: "14px" }}>
              Electronic signature
            </span>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item lg={12}>
            <h6>Dear EDA,</h6>
          </Grid>
          <Grid item lg={12}>
            <p style={{ display: "inline" }}>As</p>{" "}
            <span
              style={{
                textDecoration: "underline",
                fontSize: "16px",
                color: "gray",
              }}
            >
              {declaration.company}
            </span>
            <p style={{ display: "inline" }}>
              , we guarantee that our company doesn't have any products
              containing
            </p>
          </Grid>
          <Grid item lg={12}>
            <p style={{ display: "inline" }}>
              the following active ingredient:
            </p>
            <span
              style={{
                textDecoration: "underline",
                fontSize: "16px",
                color: "gray",
              }}
            >
              {declaration.newIngredient}
            </span>
          </Grid>
          <Grid item xs={12} className="mt-3">
            <span>Best Wishes,</span>
          </Grid>
          <Grid item xs={12}>
            <span style={{ color: "gray", opacity: "70%", fontSize: "14px" }}>
              Electronic signature
            </span>
          </Grid>
        </Grid>
      )}
    </>
  ) : (
    <></>
  );
};

export default Declaration;
