import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { VpnKey, Done, Clear } from "@material-ui/icons";
import EditInput from "./EditInput";
import { getValue } from "utils/GetObjectValues";
const ChangePassModal = ({ open, setOpen, handleChangePassword, formik,setInputEntity,isEdit}) => {


  const [submitPassFlag, setSubmitPassFlag] = useState(false);

  useEffect(() => {
    if (!getValue(formik.errors, "newPassword") && 
    !getValue(formik.errors, "newPasswordConfirmation") && 
    getValue(formik.values, "newPassword") && 
    getValue(formik.values, "newPasswordConfirmation"))
    {
      setSubmitPassFlag(true)
    } else {
      setSubmitPassFlag(false)
    }
  }, [formik.errors,formik.values])

  const handleCloseModel = () => {
    formik.resetForm();
    setSubmitPassFlag(false);
    setOpen(false);
  };
  const onChangePassword = () => {
    setInputEntity(inputEntity => ({
      ...inputEntity,
      password:getValue(formik.values, "newPasswordConfirmation")
    }))
    setOpen(false);
    formik.resetForm();

  }
  return (
    <>
      <Grid item sm={6}>
        Password:
      </Grid>
      <Grid item sm={6} style={{ display: "contents" }}>
        <Grid item sm={6}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            component="span"
            startIcon={<VpnKey />}
            onClick={handleChangePassword}
          >
            {isEdit ? "Change Password" : "Enter a password"}
          </Button>
          <Dialog fullWidth open={open} onClose={handleCloseModel}>
            <DialogTitle className="text-center">{"Change Password"}</DialogTitle>
            <DialogContent className="p-5">
              <Grid container spacing={4}>
                <EditInput
                  title={isEdit? "New Password:" : "Password"}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  name="newPassword"
                />

                {getValue(formik.values, "newPassword") && (
                  <div
                    className={
                      getValue(formik.errors, "newPassword")
                        ? "text-danger small"
                        : "text-success small"
                    }
                  >
                    {getValue(formik.errors, "newPassword")
                      ? <>
                        <Clear />
                        {getValue(formik.errors, "newPassword")}
                      </>
                      : <> <Done /> Your Password has a strong pattern ! Good To GO!</>
                    }
                  </div>
                )}

                <EditInput
                  title="Confirm Password:"
                  value={formik.values.newPasswordConfirmation}
                  onChange={formik.handleChange}
                  name="newPasswordConfirmation"
                />

                {getValue(formik.values, "newPasswordConfirmation") && (
                  <div
                    className={
                      getValue(formik.errors, "newPasswordConfirmation")
                        ? "text-danger small"
                        : "text-success small"
                    }
                  >
                    {getValue(formik.errors, "newPasswordConfirmation")
                      ? <>
                        <Clear />
                        {getValue(formik.errors, "newPasswordConfirmation")}
                      </>
                      : <> <Done /> Your Password is matching </>
                    }
                  </div>
                )}

              </Grid>
            </DialogContent>
            <DialogActions>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center"
              >
                <Button
                  onClick={handleCloseModel}
                  color="secondary"
                  size="medium"
                  variant="contained"
                  className="ml-4 mx-4"
                  style={{ backgroundColor: "#0b0b0c" }}
                >
                  Back
                </Button>
                <Button
                  onClick={onChangePassword}
                  color="primary"
                  size="medium"
                  disabled={!submitPassFlag}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassModal;
