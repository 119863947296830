import React, { useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import SimpleAccordion from "./accordion";
import SettingsIcon from "@material-ui/icons/Settings";
import client from "api/apiAuth/guestClient";
const SysSettings = ({ match }) => {
  const [allWorkFlowsSettings, setAllWorkFlowsSettings] = useState({});

  const getSettingWorkFlows = async () => {
    const path = match.path;
    const type = path.split("/")[2];
    const response = await client.get("settings/getSysSettingWorkFlows", {
      params: {
        type,
      },
    });
    setAllWorkFlowsSettings(response.data.allWorkFlows);
  };
  useEffect(() => {
    getSettingWorkFlows();
  }, []);

  return (
    <Card
      className="card-box mb-spacing-6-x2"
      style={{
        borderRadius: "20px 20px 20px 20px",
        boxShadow: "none",
        border: "none",
        marginTop: "-4px",
      }}
    >
      <div
        style={{
          padding: "10px 30px",
          backgroundColor: "#171c30",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>SETTINGS</h3>
        <div style={{ color: "white" }}>
          <SettingsIcon style={{ width: 45, height: 45 }} />
        </div>
      </div>
      {Object.keys(allWorkFlowsSettings).map((workFlow, index) => {
        return (
          <React.Fragment key={index}>
            <SimpleAccordion
              title={workFlow}
              workflow={allWorkFlowsSettings[workFlow]}
            />
          </React.Fragment>
        );
      })}
    </Card>
  );
};

export default SysSettings;
