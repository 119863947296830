import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { connect } from "react-redux";
import { FormikProvider, useFormik } from "formik";
import SubmissionLimit from "./SubmitionLimitForProduct/index";
import * as Yup from "yup";
import { getValue } from "utils/GetObjectValues";
import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { handleError } from "api/utilities";
import { getLimitPerCompany } from "reducers/settings/settingsActions";

const SubmissionLimitForProduct = (props) => {
  const { departments, limitPerCompany, getLimitPerCompany } = props;
  const [activeTab, setActiveTab] = useState(departments[0]);
  const [initialValues, setInitialValues] = useState([]);
  const handleMenu = (menu) => {
    if (activeTab.id !== menu.id) {
      setActiveTab({ id: menu.id, name: menu.name });
    }
  };
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      limitPerCompany: Yup.number().nullable(),
    }),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmissionLimitPerCompany();
    },
  });
  const handleSubmissionLimitPerCompany = () => {
    Swal.fire({
      title: "Are you sure you want to set submission hours?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(function (result) {
      if (result.isConfirmed) {
        client
          .post("/settings/saveSubmissionLimit", {
            submission_limit_per_company: formik.values.limitPerCompany,
          })
          .then(() => {
            Swal.fire({
              title: "Success",
              text: "Settings submitted successfully",
              icon: "success",
              timer: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            handleError(error);
          });
      }
    });
  };
  useEffect(() => {
    setInitialValues({
      limitPerCompany: limitPerCompany,
    });
  }, [limitPerCompany]);
  useEffect(() => {
    getLimitPerCompany();
  }, []);
  return (
    <>
      <Grid container>
        <FormikProvider value={formik}>
          <Grid item lg={12} md={12} xs={12}>
            <Card className="p-4" style={{ boxShadow: "none" }}>
              <span>
                <p
                  htmlFor="from"
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    // fontWeight: "600",
                    lineHeight: "17px",
                  }}
                  className="mr-2"
                >
                  <b> Submission limit per company per day</b>
                </p>
                <TextField
                  type="number"
                  placeholder="limit"
                  name="limitPerCompany"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: -1,
                    },
                  }}
                  value={
                    [null, undefined].includes(
                      getValue(formik.values, "limitPerCompany")
                    )
                      ? ""
                      : getValue(formik.values, "limitPerCompany")
                  }
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  style={{ width: "27%", backgroundColor: "white" }}
                  size="small"
                  className="mb-0"
                  variant="outlined"
                ></TextField>
              </span>

              <Button
                className="mx-1 px-5 py-2 ml-5"
                size="large"
                color="primary"
                variant="contained"
                style={{ borderRadius: "5px" }}
                type="submit"
                disabled={
                  getValue(formik.values, "limitPerCompany") === undefined
                    ? true
                    : false
                }
                onClick={(e) => {
                  formik.handleSubmit(e);
                }}
              >
                <span className="btn-wrapper--label ml-2 font-size">
                  Submit
                </span>
              </Button>

              <Grid item lg={12} md={12} xs={12} className="mb-4">
                {(getValue(formik.touched, `limitPerCompany`) ||
                  getValue(formik.errors, `limitPerCompany`)) && (
                  <div className="text-danger">
                    {getValue(formik.errors, `limitPerCompany`)}
                  </div>
                )}
              </Grid>
            </Card>
          </Grid>
        </FormikProvider>
        <Divider />
        <Grid item lg={12} md={12} xs={12} className="mt-4">
          <List className="nav-tabs nav-tabs-primary d-flex p-0 tabs-list">
            {departments.map((menu) => {
              return (
                <ListItem
                  primary="Single-line item"
                  key={menu.id}
                  defaultValue={departments[0]}
                  button
                  disableRipple
                  selected={activeTab.id === menu.id}
                  className="m-0 text-center"
                  style={{ flexDirection: "column", flexWrap: "wrap" }}
                  onClick={() => {
                    handleMenu(menu);
                  }}
                >
                  {menu.name}
                </ListItem>
              );
            })}
          </List>

          <div
            className={clsx("tab-item-wrapper", {
              active: true,
            })}
            style={{ overflow: "hidden", borderRadius: "0 20px 20px 20px" }}
            index={0}
          >
            <SubmissionLimit
              productName={activeTab.name}
              companyLimit={getValue(formik.values, "limitPerCompany")}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    departments: state.auth.user.departments,
    limitPerCompany: state.settings.settings.submissionLimitPerCompany,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLimitPerCompany: () => dispatch(getLimitPerCompany()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionLimitForProduct);
