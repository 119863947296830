import React, { useState, useEffect, user } from "react";
import { Grid, StylesProvider, jssPreset, Card } from "@material-ui/core";
import "./style.css";
import { create } from "jss";
import rtl from "jss-rtl";
import { connect } from "react-redux";
import FormSteps from "components/FormSteps";
import getSchema from "utils/setValidationSchema";

import {
  getDraft,
  getFormLayout,
  setFormName,
  getSubmission,
} from "reducers/form/formActions";
import { useLocation } from "react-router-dom";
import SpinnerLoader from "components/SpinnerLoader";
import client from "api/apiAuth/guestClient";
import { Alert } from "@material-ui/lab";
import UseIsMount from "hooks/UseIsMount";
import { fireEvent } from "utils/we-flow/EventSystem";
import Swal from "sweetalert2";
const availableRoutes = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/availableRoutes.json`);

const {
  tryGetConfig,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/FormStepsConfig`);

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
function CreateSubmission(props) {
  const {
    getLayout,
    getDraft,
    getSubmission,
    form,
    match,
    history,
    form_layout_loading,
    setFormName,
    error,
    user,
  } = props;
  const [validationSchema, setValidationSchema] = useState({});
  const [draftValidationSchema, setDraftvalidationSchema] = useState({});
  // state of confirm modal
  const { formId, id, submissionId } = match.params;
  const [initialValues, setInitialValues] = useState({});
  const [remainingTime, setRemainingTime] = useState();
  const { search } = useLocation();

  const isMount = UseIsMount();
  const sub_id =
    search.includes("submission_id") && search?.split("?")[1]?.split("=")[1];

  const getRemainingTime = async () => {
    let { data } = await client.get(`/submission/getRemainingTime`, {
      params: { sub_id, formId },
    });
    setRemainingTime(data.remaining_time);
  };
  useEffect(() => {
    if (sub_id) {
      getRemainingTime();
    }
    setInitialValues({});
    setFormName("");
    fireEvent("OnCreateSubmissionPageLoad", { history });
  }, []);
  const [finishStatus, _setfinishStatus] = useState(false);
  const finishStatusRef = React.useRef();
  const setfinishStatus = (data) => {
    finishStatusRef.current = data;
    _setfinishStatus(data);
  };
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatusRef.current) {
      Swal.fire({
        text: "Are you sure you want to leave? All provided data will be lost.",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
        confirmButtonColor: "#0066cc",
        denyButtonColor: "#25305338",
      }).then(function (result) {
        if (result.isConfirmed) {
          setfinishStatus(true);
          // your logic
          history.push("/");
        } else {
          window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false);
        }
      });
    }
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    if (form) {
      setValidationSchema(getSchema(false));
      setDraftvalidationSchema(getSchema(true));
    }
  }, [form]);
  useEffect(() => {
    if (id) getDraft({ id, formId });
  }, [id]);

  let exceedError = [
    "You have exceeded the limit for submitting",
    "receipt number and receipt group must be unique",
  ];
  useEffect(() => {
    return;
    // eslint-disable-next-line no-unreachable
    for (const token of exceedError) {
      if (!isMount && error && !error.message?.includes(token)) {
        if (!isMount) {
          if (user.roles[0].name in availableRoutes)
            history.push(availableRoutes[user.roles[0].name].default);
          else history.push(availableRoutes["shared"].default);
        }
      }
    }
  }, [error.modalButtonClicked]);

  useEffect(() => {
    if (submissionId) {
      getLayout({ formId, isEdit: true });
      getSubmission({ submissionId, formId });
    } else {
      getLayout({ formId });
    }
  }, [submissionId]);
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };
  return (
    <StylesProvider jss={jss}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} className="form-container ">
          {remainingTime ? (
            <Alert style={{ marginBottom: 5 }} severity="warning">
              The remaining time is {remainingTime}
            </Alert>
          ) : null}
          <Card className="card-box form-container">
            <div className="card-body p-0 ">
              {form_layout_loading ? (
                <SpinnerLoader />
              ) : (
                <>
                  <FormSteps
                    form={form.sections}
                    formId={formId}
                    draftId={id}
                    validationSchema={validationSchema}
                    draftValidationSchema={draftValidationSchema}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    submissionId={submissionId}
                    history={history}
                    config={tryGetConfig(formId)}
                  ></FormSteps>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </StylesProvider>
  );
}
const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    form_layout_loading: state.form.form_layout_loading,
    error: state.error,
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(getFormLayout(params)),
    getDraft: (params) => dispatch(getDraft(params)),
    getSubmission: (params) => dispatch(getSubmission(params)),
    setFormName: (name) => dispatch(setFormName(name)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateSubmission);
