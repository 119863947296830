import React from 'react';

import ReactQuill from 'react-quill';
import './style.css';
import { Field } from 'formik';

export default function WysiwygEditor({ formik, input }) {
  let modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ direction: 'rtl' }],
      ['clean']
    ]
  };

  return (
    <>
      <Field name={input.key} onBlur={formik.handleBlur}>
        {({ field }) => (
          <ReactQuill
            theme="snow"
            value={field.value || ''}
            modules={modules}
            onChange={field.onChange(field.name)}
            // value={getValue(formik.values, input.key)}
            data-section={input.section}
            disabled={input.disabled}
          />
        )}
      </Field>
    </>
  );
}
