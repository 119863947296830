import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";

import { ThemeProvider } from "@material-ui/styles";

// Layout Blueprints

import { LeftSidebar, MinimalLayout } from "./layout-blueprints";

// Example Pages

import PageError404 from "./example-pages/PageError404";
import PageError500 from "./example-pages/PageError500";
import PageError505 from "./example-pages/PageError505";

// project pages
import WorkList from "./pages/WorkList";
import Login from "pages/Login/Login";

import PrivateRoute from "utils/PrivateRoute";
import CreateSubmission from "pages/CreateSubmission";
import ContinueSubmission from "pages/ContinueSubmission";
import ModificationRequests from "pages/ModificationRequests";
import Programs from "pages/Programs";
import Dashboard from "pages/Dashboard";
import { connect } from "react-redux";
import { setIsLoggedIn, logout } from "reducers/auth";
//rtl
import MuiTheme from "./theme";
import EdaDetails from "pages/EdaDetails";
import TimeSlot from "pages/Settings/TimeSlot/TimeSlotForProduct/index";
import SubmissionLimit from "pages/Settings/SubmissionLimit";
import GroupDetails from "pages/SubmissionGroupDetails/Components/GroupDetails";
import CommittePage from "pages/SubmissionGroupDetails/Components/CommittePage";
import TimeSlotsSchedule from "pages/TimeSlotsSchedule";
import AdminPanel from "pages/AdminPanel/AdminPanel/index";
// import UserControlPanel from "pages/UserControlPanel/index";
import Drafts from "pages/Drafts";
//import Settings from "pages/Settings";
import TableRule from "pages/TableRule";
import Landing from "pages/Landing";
import Notifications from "pages/Notifications/index";
import Modifications from "./pages/Modifications";
import SysSettings from "./pages/SysSettings";
import TesterPlayground from "./pages/TesterPlayground";
import QueueSetting from "pages/QueueSetting";
import VacationPanel from "pages/Vacations/index";
import doctorPanel from "pages/Doctors/index";
import PocaShowPage from "./pages/PocaShowPage/PocaShowPage";
import modificationsRequestTable from "pages/ModificationRequests/modificationsRequestTable";
// example pages

let privateRoutes = [];
let routes = [];
try {
  const customRoutes = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/Routes/Routes`);
  ({ privateRoutes, routes } = customRoutes);
} catch (err) {}
const availableRoutes = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/availableRoutes.json`);

const Test = lazy(() => import("./components/test/Test"));

const Routes = ({ auth, logout, isLoggedin }) => {
  const location = useLocation();
  const { user } = auth;
  useEffect(() => {
    if (isLoggedin && (!user || user === undefined || user === null)) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin]);
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);

      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
              style={{ height: "100%" }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to={"/WorkList"} />

            <Route
              path={[
                "/WorkList",
                "/programs",
                "/programs/:formId/:id?",
                "/details/:id",
                "/details/:id/:taskId",
                "/drafts",
                "/dashboard",
                "/users",
                "/admin",
                "/edaDetails",
                "/settings/timeSlot",
                "/settings/SubmissionLimit",
                "/timeSlots/schedule",
                "/users",
                "/settings",
                "/tablerule/:submissionId",
                "/notifications",
                "/scientificCommittee/:id",
                "/scientificCommittees",
                "/Modifications",
                "/systemSytings/normal",
                "/systemSytings/fast",
                "/systemSytings/re_reg",
                "/tester",
                "/SubmissionGroupDetails/Components/GroupDetails/:id",
                "/SubmissionGroupDetails/Components/GroupPage",
                "/systemSytings/queue",
                "/modificationRequest",
                "/vacations",
                "/doctors",
                "/showSimilarity/:drugName",
                "/modificationsRequestData",
                ...routes,
              ]}
            >
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    {/* our routes */}
                    <Route path="/Test" component={Test} />

                    <PrivateRoute
                      exact
                      path="/workList/:form_id?"
                      component={WorkList}
                    />

                    <PrivateRoute
                      exact
                      path="/details/:id"
                      component={EdaDetails}
                    />
                    <PrivateRoute
                      exact
                      path="/details/:id/:taskId"
                      component={EdaDetails}
                    />
                    {/* <PrivateRoute path="/edaDetails" component={EdaDetails} /> */}
                    <PrivateRoute
                      path="/settings/timeSlot"
                      component={TimeSlot}
                    />
                    <PrivateRoute
                      path="/settings/SubmissionLimit"
                      component={SubmissionLimit}
                    />
                    <PrivateRoute
                      path="/SubmissionGroupDetails/Components/GroupDetails/:id"
                      component={GroupDetails}
                    />
                    <PrivateRoute
                      exact
                      path="/showSimilarity/:drugName"
                      component={PocaShowPage}
                    />

                    <PrivateRoute
                      path="/SubmissionGroupDetails/Components/GroupPage"
                      component={CommittePage}
                    />
                    <PrivateRoute
                      exact
                      path="/programs/:formId/draft/:id"
                      component={CreateSubmission}
                    />
                    <PrivateRoute
                      exact
                      path="/programs/:formId"
                      component={CreateSubmission}
                    />

                    <PrivateRoute
                      exact
                      path="/programs/:formId/:id"
                      component={ContinueSubmission}
                    />
                    <PrivateRoute
                      exact
                      path="/modificationRequest"
                      component={ModificationRequests}
                    />
                    <PrivateRoute
                      exact
                      path="/notifications"
                      component={Notifications}
                    />
                    <PrivateRoute
                      exact
                      path="/Modifications"
                      component={Modifications}
                    />
                    <PrivateRoute
                      exact
                      path="/systemSytings/normal"
                      component={SysSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/systemSytings/fast"
                      component={SysSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/systemSytings/re_reg"
                      component={SysSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/systemSytings/queue"
                      component={QueueSetting}
                    />
                    <PrivateRoute
                      exact
                      path="/programs/:formId/submissions/:submissionId/edit"
                      component={CreateSubmission}
                    />
                    <PrivateRoute
                      exact
                      path="/timeSlots/schedule"
                      component={TimeSlotsSchedule}
                    />

                    <PrivateRoute exact path="/programs" component={Programs} />
                    <PrivateRoute exact path="/drafts" component={Drafts} />
                    <PrivateRoute
                      exact
                      path="/modificationsRequestData"
                      component={modificationsRequestTable}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/:entity"
                      component={AdminPanel}
                    />
                    {/* <PrivateRoute
                      exact
                      path="/users"
                      component={UserControlPanel}
                    /> */}
                    <PrivateRoute
                      exact
                      path="/tablerule/:submissionId"
                      component={TableRule}
                    />
                    <PrivateRoute path="/tester" component={TesterPlayground} />
                    <PrivateRoute path="/vacations" component={VacationPanel} />
                    <PrivateRoute path="/doctors" component={doctorPanel} />
                    {privateRoutes}
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                "/PageError404",
                "/PageError500",
                "/PageError505",
                "/login",
                "/landing",
              ]}
            >
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                    style={{ height: "100%" }}
                  >
                    <Route path="/PageError404" component={PageError404} />
                    <Route path="/PageError500" component={PageError500} />
                    <Route path="/PageError505" component={PageError505} />
                    {!auth.isLoggedin ? (
                      <Route path="/login" component={Login} />
                    ) : (
                      <Redirect
                        to={auth.redirectUrl ? auth.redirectUrl : "/workList"}
                      />
                    )}
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};
function mapStateToProps(state) {
  return { auth: state.auth, isLoggedin: state.auth.isLoggedin };
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    setIsLoggedIn: () => dispatch(setIsLoggedIn()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
