import React from "react";
import { getValue } from "utils/GetObjectValues";
import { Grid } from "@material-ui/core";
const CardInfo = ({ data, attributes }) => {
  return (
    <>
      {attributes.map((column, index) => (
        <Grid
          item
          key={index}
          xs={12}
          md={column.style.gridSize}
          style={{
            display: column?.hasOwnProperty("constraints")
              ? column.constraints.display
                ? true
                : "none"
              : true,
          }}
        >
          <Grid className="listTitle">{column.name} </Grid>
          <span
            style={{ padding: "0 4px 1px 4px" }}
            className={`listTitleDate ${column.classes ? column.classes : ""}`}
          >
            <b className={`${column?.contentClass}`}>
              {getValue(data, column.key, column.type)}{" "}
            </b>
          </span>
        </Grid>
      ))}
    </>
  );
};

export default CardInfo;
