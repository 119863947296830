import * as allIcons from "@material-ui/icons";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import EditIcon from "@material-ui/icons/Edit";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FastForwardIcon from "@material-ui/icons/FastForward";
import RepeatIcon from "@material-ui/icons/Repeat";
import PeopleIcon from "@material-ui/icons/People";
import StorageIcon from "@material-ui/icons/Storage";
import LocalHospital from "@material-ui/icons/LocalHospital";

import { Drafts } from "@material-ui/icons";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import HomeIcon from "@material-ui/icons/Home";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import client from "api/apiAuth/guestClient";
import * as iconss from "./assets/customSvgMuiIcons";

const getModificationRequests = async (roles) => {
  const res = await client.get("modification/getMyWaitionModifications", {
    params: {
      roles: [...roles],
    },
  });
  return res.data.result.count;
};
export const sideMenuItems = (labels, urls, icons) => {
  const iconz = { ...iconss };
  return {
    User: [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: allIcons["HomeOutlined"],
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: allIcons["ListAlt"],
          },
          {
            label: "Notifications",
            url: "/notifications",
            Icon: NotificationsIcon,
          },
          {
            label: "New Event",
            url: "/programs/1",
            Icon: LibraryBooksTwoToneIcon,
          },
          {
            label: "Draft",
            url: "/drafts",
            Icon: Drafts,
          },
          {
            label: "New Engagement",
            url: "/Modifications",
            Icon: EditIcon,
          },
          {
            label: "Engagements",
            url: "/modificationRequest",
            Icon: StorageIcon,
            // badge: await getModificationRequests([
            //   "Scientific Evaluation Unit Junior",
            // ]),
          },
          {
            label: "Dashboard",
            url: "/dashboard",
            Icon: StorageIcon,
            // badge: await getModificationRequests([
            //   "Scientific Evaluation Unit Junior",
            // ]),
          },
          // {
          //   label: "All Modifications",
          //   url: "/modificationsRequestData",
          //   Icon: StorageIcon,
          //   badge: await getModificationRequests([
          //     "Scientific Evaluation Unit Junior",
          //   ]),
          // },
        ],
        main: " ",
      },
    ],
    Financial: [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: allIcons["HomeOutlined"],
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: allIcons["ListAlt"],
          },
          {
            label: "Notifications",
            url: "/notifications",
            Icon: NotificationsIcon,
          },
        ],
        main: " ",
      },
    ],
    Approver: [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: allIcons["HomeOutlined"],
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: allIcons["ListAlt"],
          },
          {
            label: "Notifications",
            url: "/notifications",
            Icon: NotificationsIcon,
          },
          {
            label: "New Event",
            url: "/programs/1",
            Icon: LibraryBooksTwoToneIcon,
          },
          {
            label: "Engagement Request",
            url: "/modificationsRequestData",
            Icon: StorageIcon,
            // badge: await getModificationRequests([
            //   "Scientific Evaluation Unit Junior",
            // ]),
          },
          {
            label: "Draft",
            url: "/drafts",
            Icon: Drafts,
          },
          {
            label: "New Engagement",
            url: "/Modifications",
            Icon: EditIcon,
          },
          {
            label: "Engagements",
            url: "/modificationRequest",
            Icon: StorageIcon,
            // badge: await getModificationRequests([
            //   "Scientific Evaluation Unit Junior",
            // ]),
          },
        ],
        main: " ",
      },
    ],

    Admin: [
      {
        links: labels?.map((item, index) => ({
          key: index,
          label: item,
          url: urls[index],
          Icon: iconz?.[icons?.[index]]
            ? iconz?.[icons?.[index]]
            : iconz.Default,
        })),

        main: " ",
      },
    ],
  };
};
