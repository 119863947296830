import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Popover,
  Divider,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { apiConfig } from "api/utilities";
const PreviewSignature = ({ open, setOpen, data, signatures }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageArabic, setImageArabic] = useState(null);
  const handleCloseModel = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openImg = Boolean(anchorEl);
  const signature = openImg ? "simple-popover" : undefined;
  console.log(data?.entity?.[signatures?.key])
  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleCloseModel}>
      <DialogTitle className="text-center">{"Signature"}</DialogTitle>
      <DialogContent className="py-5 px-5">
        <Grid container>
          <Grid item sm={4}>
            {signatures?.name}
          </Grid>
          <Grid item sm={4} style={{ display: "contents" }}>
            <Grid item sm={4} className="px-4">
              <Button
                aria-describedby={signature}
                size="medium"
                variant="contained"
                color="primary"
                component="span"
                startIcon={<ImageIcon />}
                onClick={(e) => handleClick(e, data?.entity?.[signatures?.key])}
              >
                {signatures?.name}
              </Button>
              <Popover
                id={signature}
                open={openImg}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div>
                  <Divider />
                  <img
                    src={`${apiConfig.baseUrl}${
                      `attachment/downloadPath?path=${data?.entity?.[signatures?.key]}`
                    }`}
                    ref={(el) => {
                      setImageArabic(el);
                    }}
                    alt="..."
                  />
                </div>
                {imageArabic && imageArabic.naturalHeight > 0 && (
                  <span>
                    {imageArabic.naturalHeight}x{imageArabic.naturalWidth}
                  </span>
                )}
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <Button
            onClick={handleCloseModel}
            color="secondary"
            size="medium"
            variant="contained"
            className="ml-4 mx-4"
            style={{ backgroundColor: "#0b0b0c" }}
          >
            Back
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewSignature;
