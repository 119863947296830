export const ModificationConfigs = {
  config: (mapper) => [
    {
      tabId: 2,
      sections: [
        {
          title: "",
          id: 1,
        },
      ],
    },
  ],
};
