import merge from "deepmerge";
export { default as release } from "./release/index";
export { default as reviewer } from "./reviewer/index";
export { default as checklist_samples } from "./checklist_samples/index";
export { default as productDetailsReport } from "./productDetailsReport/index";
const {
  initialValuesOfCertificates,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/certificates/initialValuesOfCertificates`);
const config = (letter, values, templateName, editValues) => {
  const initialAttrsValue = initialValuesOfCertificates(
    letter,
    values,
    editValues
  )[templateName]();

  if (initialAttrsValue.type === "const") {
    return initialAttrsValue.state;
  }
  if (initialAttrsValue.type === "shared") {
    return initialAttrsValue.state["shared"]();
  }
  return merge(
    initialAttrsValue.state["shared"](),
    initialAttrsValue.state[letter.values.product_type]()
  );
};

export default config;
export { default as checklist_cpmp } from "./checklist_cpmp/index";
export { default as distributionFormCPMP } from "./distributionFormCPMP/index";
export { default as acceptNameLetter } from "./acceptNameLetter/index";
export { default as sampleAnalysisReport } from "./sampleAnalysisReport/index";
export { default as minutesOfMeeting } from "./minutesOfMeeting/index";
export { default as veterinaryPharmaceuticals } from "./veterinaryPharmaceuticals/index";
export { default as scientificCommitteeApprovalLetter } from "./scientificCommitteeApprovalLetter";
export { default as refusalOfTechnicalCommittee } from "./refusalOfTechnicalCommittee";
export { default as hardfilesApprovalLetter } from "./hardfilesApprovalLetter";
