import CustomComps from "../components";
import PrivateRoute from "../../../utils/PrivateRoute";

const { NamingDrugNamesTable, BlankPage, SearchPage } = CustomComps;

export const privateRoutes = [
  <PrivateRoute
    exact
    path="/drugnames/:submissionId/:taskId"
    component={NamingDrugNamesTable}
    key={"drugnames"}
  />,
  <PrivateRoute exact path="/blank" component={BlankPage} key={"blank"} />,
  <PrivateRoute
    exact
    path="/drugSearch"
    component={SearchPage}
    key={"search"}
  />,
];

export const routes = [
  "/drugnames/:submissionId/:taskId",
  "/blank",
  "/drugSearch",
];
