import React, { useEffect, useState } from "react";
import imag from "../../assets/images/evers.png";
import config from "../index.js";
import client from "api/apiAuth/guestClient";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  groupValues,
}) => {
  let submissions = letter;
  const [groupData, setGroupData] = useState(null);
  useEffect(async () => {
    setValues(config(letter, formValues, "veterinaryPharmaceuticals"));
    const response = await client.post("generic/find", {
      model: "SubmissionGroup",
      include: {
        association: "submissions",
        where: { id: letter.id },
      },
      limit: 1,
      order: [["createdAt", "DESC"]],
    });
    setGroupData(response.data.rows[0]);
  }, []);
  // useEffect(() => {
  //   console.log("VVV: ", values);
  //   setValues({
  //     ...config(letter, values, "minutesOfMeeting"),
  //   });
  // }, [values]);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  toDataURL(imag);
  useEffect(() => {
    const veterinaryPharmaceuticals = document.getElementById(
      "veterinaryPharmaceuticals"
    );
    setDivRef({ ...divRef, veterinaryPharmaceuticals, documentValues: values });
  }, [values]);
  return (
    <div style={{ zoom: 0.7, color: "black" }} id="veterinaryPharmaceuticals">
      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "16pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            General Directorate
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Of Veterinary Products
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            الإدارة العامة للمستحضرات البيطرية
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        className="row"
        style={{
          // backgroundColor: "lightgrey",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <h4 style={{ textDecoration: "underline" }}>
          <input type="text" defaultValue="Code: 0000 / 2021" />
        </h4>
      </div>

      <div
        style={{
          display: "flex",
          direction: "rtl",
          flexDirection: "column",
          alignContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "2%",
        }}
      >
        <h4
          style={{
            textDecoration: "underline",
            direction: "rtl",
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            alignItems: "flex-start",
            fontSize: "16pt",
          }}
        >
          كشف بأسماء المستحضرات الصيدلية البيطرية المرفوعة للعرض على اللجنة
          الفنية لمراقبة الأدوية بجلسة
          {groupData
            ? ` ${new Date(groupData.values.start_date).toLocaleDateString(
                "ar-EG"
              )} `
            : "??"}
          للحصول على إخطار تسجيل نهائى :
        </h4>
        <br />

        <table
          style={{
            fontFamily: "arial, sans-serif",
            border: "1px solid 	#101010",
            borderCollapse: "collapse",
            width: "100%",
            direction: "ltr",
          }}
        >
          <tr>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              .No
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Product Name
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Dosage Form
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Active Ingredient(s) & Strength(s)
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Company
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Type of Reg.
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Scientific Committee Decision
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Stability Result & Date of committee approval
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Reference
            </td>
            <td
              style={{
                border: "1px solid 	#101010",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Technical Committee Decision
            </td>
          </tr>
          {submissions &&
            submissions.map((sub, index) => {
              return (
                <>
                  <tr>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.file_code}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.values.trade_name}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.drugs.map((drug) => drug.dosage_form).join(",")}
                    </td>

                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.drugs
                        .map((drug) =>
                          drug.ingredients.map(
                            (ingredient) =>
                              `${ingredient.active_ingredient} ${
                                ingredient.strength && ingredient.strength
                              } ${ingredient.unit && ingredient.unit} ${
                                ingredient.salts &&
                                `(${ingredient.salts.map(
                                  (salt) =>
                                    `${salt.name} ${salt.unit && salt.unit} ${
                                      salt.strength && salt.strength
                                    }`
                                )})`
                              }`
                          )
                        )
                        .join(", ")}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.org.name}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.values.registration_type}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.values.CommitteeDecisiis}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {`${sub.values.stability_result}, ${new Date(
                        sub.values.stability_committe_approval_date
                      ).toLocaleDateString("ar-EG")} `}
                    </td>
                    <td
                      style={{
                        border: "1px solid 	#101010",
                        fontSize: "16px",
                      }}
                    >
                      {sub.drugs
                        .map(
                          (drug) =>
                            `${drug.reference} ${drug.reference_country}`
                        )
                        .join(",")}
                    </td>
                  </tr>
                  {sub.values.committee_comment && (
                    <tr style={{ marginTop: "10%", height: "100px" }}>
                      <td
                        style={{
                          border: "1px solid #101010",
                          fontSize: "16px",
                          lineHeight: "25px",
                          wordBreak: "break-all",
                          padding: "5px",
                        }}
                        colspan="10"
                      >
                        <span>Committee Comment: </span>
                        {sub.values.committee_comment}
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
        </table>
      </div>

      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
};
