/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import bioInLogo from "assets/images/bio-inn.png";
import edaLogo from "assets/images/Image 1.png";
import "./index.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import config from "../index.js";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const SampleAnalysisReport = ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  form,
  user,
  setCheckListFormFlag,
}) => {
  const [imageSources, setImageSources] = useState({});
  useEffect(() => {
    setCheckListFormFlag(false);
    setValues(config(letter, formValues, "checklistCPMP"));
  }, []);
  let [initialValues, setInitialValues] = useState({});

  const checkListSchema = {
    productName: Yup.string().required("Required"),
    productType: Yup.string().required("Required"),
    Manufacturer: Yup.string().required("Required"),
    batchNumber: Yup.string().required("Required"),
    type_of_container_doses: Yup.string().required("Required"),
    registration_no: Yup.string().required("Required"),
    batch_no_of_diluents: Yup.string().required("Required"),
    sampling_site: Yup.string().required("Required"),
    manufacturing_date: Yup.string().required("Required"),
    expiry_date: Yup.string().required("Required"),
    shelf_life: Yup.string().required("Required"),
  };
  const checkListForm = useFormik({
    validationSchema: Yup.object().shape(checkListSchema),
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    setCheckListFormFlag(checkListForm.dirty && checkListForm.isValid);
  }, [checkListForm]);
  function toDataURL(sources) {
    Object.entries(sources).forEach(([key, src]) => {
      let image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        setImageSources((oldState) => ({ ...oldState, [key]: dataURL }));
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src =
          "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }
    });
  }

  useEffect(() => {
    toDataURL({ edaLogo, bioInLogo });
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    checkListForm.handleChange(event);
    setValues({
      ...values,
      [name]: value,
    });
    if (_.isEmpty(checkListForm.errors) && !_.isEmpty(values)) {
      setCheckListFormFlag(true);
    } else {
      setCheckListFormFlag(false);
    }
  };
  useEffect(() => {
    const sampleAnalysisReport = document.getElementById(
      "sampleAnalysisReport"
    );
    const radioButtons = sampleAnalysisReport?.querySelectorAll(
      'input[type="radio"]:checked'
    );
    radioButtons.forEach((input) => {
      input.setAttribute("checked", "checked");
    });
    setDivRef((divRefs) => ({ ...divRefs, sampleAnalysisReport }));
  }, [values, formValues]);
  return (
    <div
      style={{
        padding: "20px",
        zoom: 0.88,
      }}
      id="sampleAnalysisReport"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            CA of Biological and Innovative products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            and Clinical Studies
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            GA of Biological Product
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Lot Release Administration
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          {Object.values(imageSources)?.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              style={{ width: "150px", height: "100px" }}
              alt="bio-inn"
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هـيـئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الحيوية والمبتكرة
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            والدراسات الإكلينيكية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات الحيوية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            إدارة الافراج عن التشغيلات
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        style={{
          marginTop: "10px",
          padding: "10px",
        }}
      >
        <b style={{ color: "#101010" }}>
          Risk Based PMP Sample Analysis Report
        </b>
      </div>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <table style={{ width: "100%" }}>
          <tbody
            style={{ border: "1px solid 	#101010", borderCollapse: "collapse" }}
          >
            <tr>
              <td
                className="table-border-no-width table-border-no-width"
                style={{ width: "30%" }}
              >
                <span style={{ fontWeight: "500" }}>Product Name:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  value={values.productName}
                  name="productName"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Product Type:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  value={values.productType}
                  name="productType"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>
                  Type of container/doses:
                </span>
              </td>
              <td className="table-border-no-width">
                <input
                  value={values.type_of_container_doses}
                  name="type_of_container_doses"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Batch No.: </span>
              </td>
              <td className="table-border-no-width">
                <input
                  value={values.batchNumber}
                  name="batchNumber"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>
                  Batch no. of diluents:
                </span>
              </td>
              <td className="table-border-no-width">
                <input
                  value={values.batch_no_of_diluents}
                  name="batch_no_of_diluents"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Manufacturer:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.Manufacturer}
                  name="Manufacturer"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Sampling site:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.sampling_site}
                  name="sampling_site"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Manufacturing date:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.manufacturing_date}
                  name="manufacturing_date"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Expiry date:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.expiry_date}
                  name="expiry_date"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Shelf life:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.shelf_life}
                  name="shelf_life"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-border-no-width" style={{ width: "30%" }}>
                <span style={{ fontWeight: "500" }}>Registration no.:</span>
              </td>
              <td className="table-border-no-width">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.registration_no}
                  name="registration_no"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td style={{ width: "60%", border: "none" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "400",
                        fontFamily: ["Arial", "sans-serif"],
                      }}
                    >
                      Regarding the previously mentioned product batch collected
                      through the risk - based post-market monitoring program
                      has subjected to laboratory testing and found that it
                      complies with the specifications mentioned in marketing
                      authorization file and the regulations of the risk - based
                      post market monitoring program at the Egyptian drug
                      authority.
                    </p>
                  </td>
                </tr>
              </thead>
            </table>
            <div style={{ textAlign: "center" }} className="m-5">
              <span>Report no.: </span>
              {letter.values.cpmp_number ? (
                <span>{letter.values.cpmp_number}</span>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="....................."
                    value={formValues.cpmp_number}
                    name="cpmp_number"
                    onChange={(e) => {
                      form.handleChange(e);
                    }}
                    style={{ border: "none" }}
                    onBlur={form.handleBlur}
                  ></input>
                  {form.touched["cpmp_number"] && form.errors["cpmp_number"] ? (
                    <div className="text-danger">
                      {form.errors["cpmp_number"]}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleAnalysisReport;
