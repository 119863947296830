const ALL_EVENTS = {};
const SystemActions = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/SystemActions`);

export const registerForEvent = (eventName, eventFunction) => {
  if (!(eventName in ALL_EVENTS)) ALL_EVENTS[eventName] = [];
  ALL_EVENTS[eventName].push(eventFunction);
};

export const fireEvent = async (eventName, eventParams) => {
  if (!(eventName in ALL_EVENTS)) return;
  for (const eventFunction of ALL_EVENTS[eventName])
    await eventFunction(eventName, eventParams);
};

export const unregisterFromEvent = (eventName, eventFunction) => {
  if (!(eventName in ALL_EVENTS)) return;
  ALL_EVENTS[eventName] = ALL_EVENTS[eventName].filter(
    (evFunction) => evFunction !== eventFunction
  );
};

export const registerEventsFromBackend = (data) => {
  for (const item of data) {
    registerForEvent(item[0], SystemActions[item[1]]);
  }
};
