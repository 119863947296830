import * as React from "react";
import { connect } from "react-redux";
import SimpleAccordion from "./Accordion/index";
const {
  ModificationConfigs,
} = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/ModificationConfigs`);
const Modification = ({
  selectedTab,
  task,
  index,
  initialValues,
  setInitialValues,
  schemaInputs,
  setSchemaInputs,
  formik,
}) => {
  let sections =
    selectedTab &&
    ModificationConfigs.config({ task }).find(
      (tab) => tab.tabId === selectedTab.id
    ).sections;

  return (
    <>
      {sections?.map((section, sectionIndex) => {
        return (
          <React.Fragment key={sectionIndex}>
            {section.title && (
              <h3 className="details-header padding">
                {`${selectedTab?.id}.${section.id}${section.title}`}
              </h3>
            )}
            <div>
              <SimpleAccordion
                submissionModifications={
                  task.submission.status === "Modification"
                    ? task.submission.submissionModifications.filter(
                        (modification) => modification.task_id === task.id
                      )
                    : task.submission.submissionModifications
                }
                selectedTab={selectedTab}
                task={task}
                index={index}
                sectionId={section.id}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                schemaInputs={schemaInputs}
                setSchemaInputs={setSchemaInputs}
                formik={formik}
              />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    task: state.details.task,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Modification);
