import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fontawesome from "@fortawesome/fontawesome";
import { Box, Badge, Menu, Button, Divider } from "@material-ui/core";
import { faEllipsisH, faUser } from "@fortawesome/free-solid-svg-icons";

// import avatar2 from '../../assets/images/zakat.jpg';
import { connect } from "react-redux";

fontawesome.library.add(faEllipsisH, faUser);

const SidebarUserbox = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="app-sidebar--userbox">
        <Box className="card-tr-actions">
          <Button
            variant="text"
            onClick={handleClick}
            className="ml-2 p-0 d-30 border-0 btn-transition-none text-white-50"
            disableRipple
          >
            <FontAwesomeIcon icon={faEllipsisH} className="font-size-lg" />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            classes={{ list: "p-0" }}
            onClose={handleClose}
          >
            <div className="dropdown-menu-lg overflow-hidden p-0">
              <div className="align-box-row align-items-center p-3">
                <div className="avatar-icon-wrapper avatar-icon-sm">
                  <div className="avatar-icon rounded-circle">
                    {/* <img alt="..." src={avatar2} /> */}
                  </div>
                </div>
                <div className="pl-2">
                  <span className="font-weight-bold d-block">
                    {user?.username}
                  </span>
                  <div className="badge badge-success border-0">Active</div>
                </div>
              </div>
              <Divider className="w-100" />
              <div className="d-flex py-3 justify-content-center">
                <div className="d-flex align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon={faUser}
                      className="font-size-xxl text-success"
                    />
                  </div>
                </div>
              </div>
              <Divider className="w-100" />
              <div className="d-block rounded-bottom py-3 text-center"></div>
            </div>
          </Menu>
        </Box>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: "bg-success badge-circle" }}
          >
            <div className="avatar-icon rounded-circle">
              {/* <img alt="..." src={avatar2} /> */}
            </div>
          </Badge>
        </div>
        <div className="my-3 userbox-details">
          <span>{user?.username}</span>
          <small className="d-block text-white-50">{user?.email}</small>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return { user: state.auth.user };
};
export default connect(mapStateToProps)(SidebarUserbox);
