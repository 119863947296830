import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first"></div>
        <div className="app-footer--second" style={{ margin: '0 auto' }}>
          <span style={{ display: 'inline' }}>Everest Minds © </span>
          <a
            href="https://www.everestminds.com/"
            target="_blank"
            title="everest minds"
            rel="noopener noreferrer">
            everestminds.com
          </a>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.theme.footerShadow,
  footerBgTransparent: state.theme.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
