import React from "react";
import "../../style.scss";
const DetailsContent = ({
  task,
  selectedTab,
  initialValues,
  setInitialValues,
  schemaInputs,
  setSchemaInputs,
  formik,
}) => {
  let ContentComponent = selectedTab.component;
  return (
    <>
      <div className="details-content pl-4 pr-4">
        <h2 className={"details-section-header"}>
          {`${selectedTab?.id}.${selectedTab?.title} `}
        </h2>
        <React.Fragment>
          <ContentComponent
            task={task}
            selectedTab={selectedTab}
            attachments={task.submission.attachments}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            schemaInputs={schemaInputs}
            setSchemaInputs={setSchemaInputs}
            formik={formik}
          />
        </React.Fragment>
      </div>
    </>
  );
};

export default DetailsContent;
