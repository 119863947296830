import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Checkbox,
  Grid,
  Button,
  Popover,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  Chip,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import SimpleModal from "components/SimpleModal";
import DocumentGeneration from "pages/SubmissionGroupDetails/Components/DocumentGeneration";
import { handleError } from "api/utilities";
import GroupSectionModal from "pages/SubmissionGroupDetails/Components/GroupSectionModal";
import ModificationDetailsPop from "pages/EdaDetails/components/Modifications/ModificationDetails/ModificationDetailsPop";
import VisibilityIcon from "@material-ui/icons/Visibility";
const redirectToEdit = (e, row, history) => {
  e.stopPropagation();
  history.push(`/programs/${row.form_id}/submissions/${row.id}/edit`);
};

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: "#0066cc",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
  },
  gold: {
    backgroundColor: "#0066cc",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  root: {
    "& MuiPaper-root MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded": {
      width: "500px",
    },
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D5DBE0",
      borderRadius: 5,
    },
  },
}));

function ColumnsRepresentation({
  cellType,
  index,
  value,
  color,
  history,
  row,
  // setSubmitTaskLoader,
  column,
  selectedRows,
  setSelectedRows,
  setSelectedCheckbox,
  SelectedCheckbox,
  rowIndex,
  currentTab,
  styleClass,
  submissionAction,
  setCheckedSubmissions,
  checkedSubmissions,
  group,
  propsFunctions,
  runActionFlag,
  setRunActionFlag,
  setCheckTab,
}) {
  const [checked, setChecked] = useState();
  const [toggleButton, setToggleButton] = useState(false);
  const [changedButtonsProps, setChangedButtonProperty] = useState({
    text: column.normal?.text,
    color: column.normal?.color,
    function: column.normal?.function,
  });
  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  useEffect(() => {
    setChangedButtonProperty(column.normal);
  }, [column]);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [allData, setAllData] = useState([]);
  const [selectedModification, setSelectedModification] = useState();
  const [openModalModification, setOpenModalModification] = useState(false);
  const open1 = Boolean(anchorEl1);
  const classes = useStyles();
  const checked1 = selectedRows?.map((cur) => cur.taskId).includes(row.taskId);
  const getSubmissionModifications = async () => {
    const res = await client.get("/modification/getAllModifications");
    // const res = await client.get("/generic/find", {
    //   params: {
    //     model: "Submission",
    //     include: [
    //       {
    //         sameRole: "responsableRole",
    //         association: "modifications",
    //         through: {
    //           where: {
    //             status: "Waiting",
    //           },
    //         },
    //         required: true,
    //       },
    //     ],
    //     raw: true,
    //   },
    // });
    setAllData(res.data.result);
  };
  useEffect(() => {
    setChecked(checkedSubmissions?.some((id) => id === row.id));
    // getSubmissionModifications();
  }, []);

  useEffect(() => {
    toggleButton
      ? setChangedButtonProperty(column.toggle)
      : setChangedButtonProperty(column.normal);
  }, [toggleButton]);

  const [documentGenerationModalOpen, setDocumentGenerationModalOpen] =
    useState(false);
  const [sectionModalOpen, setSectionModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const handleClick1 = (event) => {
    event.stopPropagation();
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = (event) => {
    setAnchorEl1(null);
  };

  const handleClickChangedButton = (event, row) => {
    event.stopPropagation();
    setToggleButton(!toggleButton);
    propsFunctions[changedButtonsProps.function](row);
  };
  const handleClickCheckBox = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setChecked(event.target.checked);
    setCheckedSubmissions((checkedSubmissions) => [
      ...checkedSubmissions,
      row.id,
    ]);
  };
  const deleteSubmissionFromGroup = async (event, row) => {
    Swal.fire({
      title: "Are you sure you want to delete the Submission from this group? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        await client
          .post("submissiongroup/deleteSubmissionFromGroup", {
            group_id: row.GroupSubmissions.group_id,
            submission_id: row.GroupSubmissions.submission_id,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title: "Success",
                text: "submission deleted from group successfully",
                icon: "success",
                timer: 3000,
              }).then(() => window.location.reload());
            }
          })
          .catch((error) => {
            console.log(error);
            handleError(error);
          });
      }
    });
  };
  const toggleCheckboxValue = (e, selectedIndex, selectedTask, taskStep) => {
    e.stopPropagation();
    let selectedTasks = [...selectedRows];
    if (SelectedCheckbox[selectedIndex]) {
      //when uncheck row,remove it from selected rows
      selectedTasks = selectedTasks.filter(
        (task) => task.taskId !== selectedTask
      );
      setSelectedRows([...selectedTasks]);
    } else {
      setCheckTab(currentTab);
      selectedTasks.push({ taskId: selectedTask, taskStepId: taskStep });
      setSelectedRows([...selectedTasks]);
      // setOpenModal(true);
    }
    setSelectedCheckbox([
      ...SelectedCheckbox.map((item, checkboxIndex) =>
        checkboxIndex === selectedIndex ? !item : item
      ),
    ]);
  };
  const hendleDocumentGeneration = (event, action) => {
    event.stopPropagation();
    setDocumentGenerationModalOpen(true);
    setSelectedAction(action);
  };
  const handleViewModification = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedModification(row.modifications);
    setOpenModalModification(true);
  };
  const cells = (cellType) => {
    if (
      (value == undefined || value == null) &&
      ![
        "changedButton",
        "checkIcon",
        "approveIcon",
        "checkBox",
        "editIcon",
        "viewModification",
      ].includes(cellType)
    ) {
      return (
        <TableCell key={index}>
          <div
            className={`d-flex  ${color ? color : ""} ${
              row["status"] === value || row["remaining.number"] === value
                ? row.task_status
                : ""
            }`}
          >
            <div className="align-center">---</div>
          </div>
        </TableCell>
      );
    }
    if (cellType == "array") {
      return (
        <TableCell key={index}>
          <div
            className={`d-flex  ${color ? color : ""} ${
              row["status"] === value || row["remaining.number"] === value
                ? row.task_status
                : ""
            }`}
          >
            <div className="align-center">{value?.join("-")}</div>
          </div>
        </TableCell>
      );
    } else if (cellType == "text") {
      return (
        <TableCell key={index}>
          <div
            className={`d-flex  ${color ? color : ""} ${
              row["status"] === value || row["remaining.number"] === value
                ? row.task_status || row["status"]
                : ""
            }`}
          >
            <div className="align-center">{value}</div>
          </div>
        </TableCell>
      );
    } else if (cellType == "flag") {
      return (
        <TableCell key={index}>
          <div
            className={`d-flex  ${color ? color : ""} ${
              row["status"] === value || row["remaining.number"] === value
                ? row.task_status
                : ""
            }`}
          >
            <div className="align-center">
              {value ? (
                <Chip
                  style={{ background: "#4caf50", color: "white" }}
                  label="True"
                />
              ) : (
                <Chip
                  style={{ background: "#d32f2f", color: "white" }}
                  label="False"
                />
              )}
            </div>
          </div>
        </TableCell>
      );
    } else if (cellType == "documentStatus") {
      return (
        <TableCell key={index}>
          <div
            className={`d-flex  ${color ? color : ""} ${
              value === "true" ? "Completed" : "Rejected"
            }`}
          >
            <div className="align-center">
              {value === "true" ? "Added" : "Not Added"}
            </div>
          </div>
        </TableCell>
      );
    } else if (cellType == "number") {
      return (
        <TableCell key={index}>
          <div className={`d-flex  ${color ? color : ""}`}>
            <div className="align-center">#{value}</div>
          </div>
        </TableCell>
      );
    } else if (cellType == "image") {
      return (
        <TableCell key={index} className="text-center">
          <div
            className="avatar-icon-wrapper avatar-icon-sm"
            title="Lili Pemberton"
          >
            <div className="avatar-icon">
              <img alt="..." src={value} />
            </div>
          </div>
        </TableCell>
      );
    } else if (cellType == "badge") {
      return (
        <TableCell key={index} className="text-center">
          <span
            className={`badge light bg-${styleClass} opacity-75 text-light`}
          >
            {value}
          </span>
        </TableCell>
      );
    } else if (cellType == "date") {
      return (
        <TableCell key={index} className="text-center">
          <div className="align-center">
            {new Date(value).toLocaleDateString("en-US", DATE_OPTIONS)}
          </div>
        </TableCell>
      );
    } else if (cellType == "dateTime") {
      return (
        <TableCell key={index} className="text-center">
          <div className="align-center">
            {new Date(value).toLocaleTimeString("en-US", DATE_OPTIONS)}
          </div>
        </TableCell>
      );
    } else if (cellType == "editIcon") {
      return (
        <TableCell
          className="text-center"
          key={index}
          onClick={(e) => redirectToEdit(e, row, history)}
        >
          <EditIcon className="edit-icon" />
        </TableCell>
      );
    } else if (cellType == "actions") {
      return (
        <>
          <TableCell className="text-center" key={index}>
            <div className="d-flex align-items-center popover-header-wrapper">
              <span className="pr-2">
                <IconButton onClick={handleClick1}>
                  <MoreHorizIcon color="primary" />
                </IconButton>
              </span>
              <Popover
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  position: "absolute",
                  maxWidth: "700px",
                }}
                disableScrollLock={false}
              >
                <div className="popover-custom-lg">
                  <Divider />
                  <Paper
                    className={classes.list}
                    style={{
                      minHeight: 200,
                      maxHeight: 400,
                      width: "380px",
                      overflow: "auto",
                    }}
                  >
                    <list>
                      {submissionAction &&
                        submissionAction.map((action, index) =>
                          action.attributes.type === "DocumentGeneration"
                            ? row.availableActionId === action.id && (
                                <React.Fragment key={index}>
                                  <ListItem
                                    className="notificationItem"
                                    button
                                    onClick={(e) =>
                                      hendleDocumentGeneration(
                                        e,
                                        action,
                                        submissionAction
                                      )
                                    }
                                  >
                                    <ListItemText primary={action.title} />
                                  </ListItem>
                                </React.Fragment>
                              )
                            : row.availableActionId === action.id && (
                                <React.Fragment key={index}>
                                  <ListItem
                                    className="notificationItem"
                                    button
                                    onClick={(e) => {
                                      setSelectedAction(action);
                                      setSectionModalOpen(true);
                                    }}
                                  >
                                    <ListItemText primary={action.title} />
                                  </ListItem>
                                </React.Fragment>
                              )
                        )}
                      <ListItem
                        className="notificationItem"
                        button
                        onClick={(e) => deleteSubmissionFromGroup(e, row)}
                      >
                        <ListItemText primary={"Delete Submission"} />
                      </ListItem>
                    </list>
                  </Paper>
                </div>
              </Popover>
            </div>
          </TableCell>
          <DocumentGeneration
            action={selectedAction}
            open={documentGenerationModalOpen}
            setOpen={setDocumentGenerationModalOpen}
            group={group}
            submission={row}
            runActionFlag={runActionFlag}
            setRunActionFlag={setRunActionFlag}
          />

          <GroupSectionModal
            group={group}
            sectionAction={selectedAction}
            setOpen={setSectionModalOpen}
            open={sectionModalOpen}
            runActionFlag={runActionFlag}
            setRunActionFlag={setRunActionFlag}
            submissionId={row.id}
          />
        </>
      );
    } else if (cellType == "checkIcon") {
      return (
        <TableCell className="text-center" key={index}>
          <Checkbox
            // name={}
            checked={SelectedCheckbox[rowIndex] || checked1 || false}
            onClick={(e) =>
              toggleCheckboxValue(
                e,
                rowIndex,
                row.taskId || row.id,
                row?.step_id
              )
            }
          />
          {openModal && [0, 10, 11].includes(currentTab) && (
            <SimpleModal
              open={openModal}
              setOpen={setOpenModal}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              taskId={row.taskId || row.id}
            />
          )}
        </TableCell>
      );
    } else if (cellType == "approveIcon") {
      return (
        <TableCell
          className="text-center"
          key={index}
          // onClick={(e) => approveTask(e, row, history, setSubmitTaskLoader)}
        >
          <CheckIcon />
        </TableCell>
      );
    } else if (cellType == "checkBox") {
      return (
        <TableCell
          className="text-center"
          key={index}
          // onClick={(e) => approveTask(e, row, history, setSubmitTaskLoader)}
        >
          <Checkbox checked={checked} onClick={handleClickCheckBox} />
        </TableCell>
      );
    } else if (cellType == "changedButton") {
      return (
        <TableCell className="text-center" key={index}>
          <Button
            onClick={(event) => handleClickChangedButton(event, row)}
            color={changedButtonsProps?.color}
          >
            {changedButtonsProps?.text}
          </Button>
        </TableCell>
      );
    } else if (cellType == "viewModification") {
      return (
        <TableCell className="text-center" key={index}>
          <Button
            className="m-2 btn-transparent search-btn btn-outlined"
            onClick={(event) => handleViewModification(event, row)}
          >
            <VisibilityIcon />
          </Button>
        </TableCell>
      );
    }
  };

  return (
    <>
      {cells(cellType)}
      {openModalModification && (
        <ModificationDetailsPop
          open={openModalModification}
          setOpen={setOpenModalModification}
          values={selectedModification.values}
          modification_id={selectedModification.id}
        />
      )}
    </>
  );
}
export default ColumnsRepresentation;
