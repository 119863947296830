import React, { useEffect, useState } from "react";
import lodash from "lodash";
import imag from "../../assets/images/evers.png";
import footerImage from "../../assets/images/evers-letter-footer.png";
import config from "../index.js";
import client from "api/apiAuth/guestClient";
import { apiConfig } from "api/utilities";
import NoSignatureImage from "../../assets/images/nosignature.jpg";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  editValues,
}) => {
  let submissions = letter;
  const [groupData, setGroupData] = useState(null);
  const [generalManagerSignature, setGeneralManagerSignature] = useState(null);
  const [
    scientificCommitteeManagerSignature,
    setScientificCommitteeManagerSignature,
  ] = useState(null);
  const [regulatoryManagerSignature, setRegulatoryManagerSignature] =
    useState(null);
  const [technicalCommitteeSignature, setTechnicalCommitteeSignature] =
    useState(null);
  useEffect(async () => {
    const urlCreator = window.URL || window.webkitURL;
    setValues(
      config(letter, formValues, "hardfilesApprovalLetter", editValues)
    );
    const response = await client.post("generic/find", {
      model: "SubmissionGroup",
      include: [
        {
          association: "groupType",
        },
        {
          association: "submissions",
          where: { id: letter.id },
        },
      ],
      limit: 1,
      order: [["createdAt", "DESC"]],
    });

    setGroupData(
      response.data.rows.find(
        (data) => data.groupType.name === "Hard File Technical Committee"
      )
    );
    const users = await client.post("generic/find", {
      model: "User",
      include: {
        association: "roles",
        where: {
          name: [
            "Scientific Evaluation Unit Manager",
            "General Manager",
            "Regulatory Manager",
            "Technical Committee Rep",
          ],
        },
      },
    });

    const generalManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "General Manager" })
    );
    if (generalManager && generalManager.signatureEnglish) {
      const generalManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: generalManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([generalManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setGeneralManagerSignature);
    } else toDataURLWithCallback(NoSignatureImage, setGeneralManagerSignature);

    const scientificCommitteeManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Scientific Evaluation Unit Manager" })
    );
    if (
      scientificCommitteeManager &&
      scientificCommitteeManager.signatureEnglish
    ) {
      const scientificCommitteeManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: scientificCommitteeManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([scientificCommitteeManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setScientificCommitteeManagerSignature);
    } else
      toDataURLWithCallback(
        NoSignatureImage,
        setScientificCommitteeManagerSignature
      );

    const regulatoryManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Regulatory Manager" })
    );
    if (regulatoryManager && regulatoryManager.signatureEnglish) {
      const regulatoryManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: regulatoryManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([regulatoryManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setRegulatoryManagerSignature);
    } else
      toDataURLWithCallback(NoSignatureImage, setRegulatoryManagerSignature);

    const technicalCommitteeRep = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Technical Committee Rep" })
    );
    if (technicalCommitteeRep && technicalCommitteeRep.signatureEnglish) {
      const technicalCommitteeRepSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: technicalCommitteeRep.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([technicalCommitteeRepSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setTechnicalCommitteeSignature);
    } else
      toDataURLWithCallback(NoSignatureImage, setTechnicalCommitteeSignature);
  }, []);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  function toDataURLWithCallback(src, callback) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  toDataURL(imag);
  useEffect(() => {
    const hardfilesApprovalLetter = document.getElementById(
      "hardfilesApprovalLetter"
    );
    setDivRef({ ...divRef, hardfilesApprovalLetter, documentValues: values });
  }, [values]);
  return (
    <div
      style={{ padding: "20px", zoom: 0.7, color: "black" }}
      id="hardfilesApprovalLetter"
    >
      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات البيطرية
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
          Code No. FM-RVD-11
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            General Administration of
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11=pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Veterinary Pharmaceuticals
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        className="row d-flex justify-content-between"
        style={{
          width: "100%",
        }}
      >
        <div style={{ display: "flex" }}>
          <h4
            style={{
              textDecoration: "underline",
              fontSize: "20px",
              margin: "0",
            }}
          >
            Serial:
          </h4>
          <input
            type="text"
            style={{
              border: "none",
              width: "15%",
              fontSize: "20px",
              lineHeight: "0.8px",
            }}
            name="serialNumber"
            placeholder="............."
            value={values.serialNumber}
            onChange={handleChange}
          ></input>
          <h4
            style={{
              textDecoration: "underline",
              fontSize: "20px",
              margin: "0",
            }}
          >
            /{new Date().getFullYear()}
          </h4>
        </div>
      </div>

      <div
        className="row"
        style={{
          textDecoration: "underline",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <h4 style={{ margin: "0" }}>{values.arabicTitle}</h4>
        <h4 style={{ margin: "0" }}>{values.englishTitle}</h4>
      </div>

      <div
        className="row"
        style={{ display: "flex", textDecoration: "underline" }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "12pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          خاص بإدارة التسجيل
        </span>
      </div>

      <div
        className="row"
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          السيد الدكتور/ رئيـس مجلس إدارة شركة
        </span>
        <input
          type="text"
          style={{
            border: "none",
            width: "15%",
            direction: "rtl",
            fontSize: "20px",
          }}
          name="companyManagerName"
          placeholder="............."
          value={values.companyManagerName}
          onChange={handleChange}
          className="mr-2"
        ></input>
      </div>

      <div
        className="row mt-3"
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          تحية طيبــة وبعد ...
        </span>
      </div>

      <div className="row">
        <p
          lang="AR-SA"
          style={{
            fontSize: "13pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
            marginBottom: "0px",
          }}
        >
          {values?.body?.replace(
            "{0}",
            groupData
              ? ` ${new Date(groupData.values.start_date).toLocaleDateString(
                  "ar-EG",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                )} `
              : "??"
          )}
        </p>
      </div>

      <table
        className="sc-table"
        style={{
          width: "100%",
          border: "solid black 1px",
          cursor: "default",
          borderCollapse: "collapse",
          fontSize: "16px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Trade Name:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              {letter.values.trade_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Dosage Form:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              {letter.drugs[0].dosage_form}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Active Ingredient(s) & Strength(s)
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              {letter.drugs[0].ingredients
                .map(
                  (ingredient) =>
                    `${ingredient.active_ingredient} ${ingredient.full_strength}`
                )
                .join(" / ")}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Physical Characters:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              <input
                type="text"
                style={{
                  border: "none",
                  fontSize: "20px",
                  textAlign: "initial",
                  width: "100%",
                }}
                name="physicalCharacters"
                placeholder="............."
                value={values.physicalCharacters}
                onChange={handleChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Shelf Life:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              <input
                type="text"
                style={{
                  border: "none",
                  fontSize: "20px",
                  textAlign: "initial",
                  width: "100%",
                }}
                name="shelfLife"
                placeholder="............."
                value={values.shelfLife}
                onChange={handleChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Storage Condition:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              <input
                type="text"
                style={{
                  border: "none",
                  fontSize: "20px",
                  textAlign: "initial",
                  width: "100%",
                }}
                name="storageCondition"
                placeholder="............."
                value={values.storageCondition}
                onChange={handleChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Approved Pack:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              <input
                type="text"
                style={{
                  border: "none",
                  fontSize: "20px",
                  textAlign: "initial",
                  width: "100%",
                }}
                name="approvedPack"
                placeholder="............."
                value={values.approvedPack}
                onChange={handleChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
                width: "25%",
              }}
            >
              Type of Registration:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
                width: "25%",
              }}
            >
              {letter.values.registration_type}
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
                width: "25%",
              }}
            >
              Type of Marekting:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
                width: "25%",
              }}
            >
              {letter.values.marketing_type}
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="row"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              fontWeight: "600",
            }}
          >
            Reg. No:
          </span>
          <input
            type="text"
            style={{ border: "none", width: "25%", fontSize: "16px" }}
            name="regNumber"
            placeholder="............."
            value={values.regNumber}
            onChange={handleChange}
          ></input>
        </div>
        <div>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              fontWeight: "600",
            }}
          >
            Invalidation Date:
          </span>
          <input
            type="text"
            style={{ border: "none", width: "25%", fontSize: "16px" }}
            name="invalidationDate"
            placeholder="............."
            value={values.invalidationDate}
            onChange={handleChange}
          ></input>
        </div>
      </div>

      <table
        className="sc-table"
        style={{
          width: "100%",
          border: "solid black 1px",
          cursor: "default",
          borderCollapse: "collapse",
          fontSize: "16px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              License Holder:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              {letter.values.license_holder || (
                <input
                  type="text"
                  style={{
                    border: "none",
                    fontSize: "20px",
                    textAlign: "initial",
                    width: "100%",
                  }}
                  name="license_holder"
                  placeholder="............."
                  value={values.license_holder}
                  onChange={handleChange}
                ></input>
              )}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Manufacturer:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              {letter.values.manufacturer_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Storage Site:
            </td>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              {letter.values.license_holder || (
                <input
                  type="text"
                  style={{
                    border: "none",
                    fontSize: "20px",
                    textAlign: "initial",
                    width: "100%",
                  }}
                  name="storageSite"
                  placeholder="............."
                  value={values.storageSite}
                  onChange={handleChange}
                ></input>
              )}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              Manufacturer(s) of Active Raw Material(s):
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "solid black 1px",
                cursor: "default",
                borderCollapse: "collapse",
              }}
              colSpan="3"
            >
              <textarea
                style={{
                  width: "100%",
                  fontFamily: ["Arial", "sans-serif"],
                  fontWeight: "500",
                  fontSize: "16pt",
                  direction: "rtl",
                  border: "none",
                }}
                rows={1}
                value={values.manufacturerActiveRawMaterial}
                name="manufacturerActiveRawMaterial"
                autoCorrect="off"
                onChange={handleChange}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="row"
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "13pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          ملحوظة:
        </span>
      </div>

      <ul
        style={{
          direction: "rtl",
          textAlign: "right",
          marginTop: "1rem",
        }}
        contentEditable="true"
        suppressContentEditableWarning={true}
      >
        {values &&
          values.firstList?.map((name, index) => (
            <li
              style={{ direction: "rtl" }}
              contentEditable="true"
              suppressContentEditableWarning={true}
            >
              {name}
            </li>
          ))}
      </ul>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          marginTop: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 600,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginLeft: "20px",
            }}
          >
            يعتمد،،
            <br /> رئيس الإدارة المركزية
            <br /> للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName4}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "600",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير عام الإدارة العامة
            <br /> للمستحضرات البيطرية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName3}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير إدارة الشئون التنظيمية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName2}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير وحدة الفحص
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName1}
          </span>
        </div>
      </div>

      <div style={{ display: "d-flex", maginTop: "1.5rem" }}>
        <img
          src={generalManagerSignature}
          style={{ width: "25%", height: "100px", border: "none" }}
        />
        <img
          src={scientificCommitteeManagerSignature}
          style={{ width: "25%", height: "100px", border: "none" }}
        />
        <img
          src={regulatoryManagerSignature}
          style={{ width: "25%", height: "100px", border: "none" }}
        />
        <img
          src={technicalCommitteeSignature}
          style={{ width: "25%", height: "100px", border: "none" }}
        />
      </div>

      <img src={footerImage} className="w-100" />
    </div>
  );
};
