/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import imag from "../../assets/images/evers.png";
import config from "../index.js";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
}) => {
  useEffect(() => {
    setValues(config(letter, formValues, "acceptNameLetter"));
  }, []);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  toDataURL(imag);
  useEffect(() => {
    const reviewer = document.getElementById("acceptNameLetter");
    setDivRef({ ...divRef, reviewer, documentValues: values });
  }, [values]);
  return (
    <div
      style={{ padding: "20px", zoom: 0.7, color: "black" }}
      id="acceptNameLetter"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات البيطرية
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
          Code No. FM-RVD-27
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            General Administration of
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Veterinary Pharmaceuticals
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        className="row"
        style={{
          // backgroundColor: "lightgrey",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <h4>Code: 0000/ 2021</h4>
      </div>
      <div
        className="row"
        style={{
          display: "inline-flex",
          width: "100%",
          direction: "rtl",
        }}
      >
        <h4 style={{ margin: 0 }}>السيد الدكتور / رئيس مجلس إدارة شركة</h4>
      </div>
      <div
        className="row"
        style={{
          width: "100%",
        }}
      >
        <h6 style={{ textAlign: "center" }}>
          <span>،،،</span>
          تحية طيبة وبعد
        </h6>
      </div>
      <div
        className="row"
        style={{
          display: "inline-flex",
          width: "100%",
          direction: "rtl",
        }}
      >
        <h4>بخصوص إختيار إسم للمستحضرالصيدلى المقدم للتسجيل</h4>
      </div>
      <table
        style={{
          textAlign: "left",
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td
              colspan="1"
              style={{
                width: "20%",
                padding: 5,
                textAlign: "left",
                width: "24%",
                fontWeight: 300,
                border: "1px solid black",
              }}
            >
              <h6>Generic Name</h6>
            </td>
            <td
              colspan="3"
              style={{
                width: "60%",
                padding: 5,
                textAlign: "left",
                width: "24%",
                fontWeight: 300,
                border: "1px solid black",
              }}
            >
              <input
                style={{ border: "none" }}
                type="text"
                value={values.marketing_authorization}
                name="marketing_authorization"
                onChange={handleChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 5,
                textAlign: "left",
                width: "24%",
                fontWeight: 300,
                border: "1px solid black",
              }}
            >
              <h6>Strength</h6>
            </td>
            <td
              style={{
                padding: 5,
                textAlign: "left",
                width: "24%",
                fontWeight: 300,
                border: "1px solid black",
              }}
              colspan="3"
            >
              {/* <input
                style={{ border: "none" }}
                type="text"
                value={values.manufacturer_product}
                name="manufacturer_product"
                onChange={handleChange}
              ></input> */}

              <h4>{letter.drugs[0].ingredients[0].strength}</h4>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 5,
                textAlign: "left",
                width: "24%",
                fontWeight: 300,
                border: "1px solid black",
              }}
            >
              <h6>Dosage Form</h6>
            </td>
            <td
              style={{
                padding: 5,
                textAlign: "left",
                width: "24%",
                fontWeight: 300,
                border: "1px solid black",
              }}
              colspan="3"
            >
              <h4>{letter.drugs[0].dosage_form}</h4>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="row"
        style={{
          display: "inline-flex",
          width: "100%",
          direction: "rtl",
          flexWrap: "wrap",
        }}
      >
        <h4 style={{ direction: "rtl" }}>
          نحيط سيادتكم علما بأن الإسم الذى تم إختياره هو :
        </h4>

        <h4 style={{ direction: "initial" }}>
          {letter.values.naming.drugs.filter(
            (item) => item.finalDecision == true
          ).length
            ? letter.values.naming.drugs.filter(
                (item) => item.finalDecision == true
              )[0]["name"]
            : "no drug selected"}
        </h4>
      </div>
      <h1 style={{ fontWeight: 700, textAlign: "center", margin: 0 }}>
        TEMPLATE
      </h1>
      <h6 style={{ textAlign: "left" }}>ملحوظة :</h6>

      <div
        className="row"
        style={{
          display: "inline-flex",
          width: "100%",
        }}
      >
        <h4>
          على الشركة إرسال طلب لتحديد موعد لتسليم الملف العلمي عبر البريد
          الاليكترونى خلال شهر من تاريخ إصدار الموافقة على اسم المستحضر والا
          يعتبر طلب الإستعلام لاغى وذلك طبقاً لإجراءات التسجيل التي تم البدء
          بالعمل بها بتاريخ 3/1/2021.
        </h4>
      </div>

      <div
        className="row"
        style={{
          width: "100%",
        }}
      >
        <h4 style={{ textAlign: "center" }}>
          <span>،،،،،</span>
          وتفضلوا بقبول وافر الإحترام
        </h4>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "14pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            مديرعام الإدارة العامة للمستحضرات البيطرية
          </span>
          <span
            lang="AR-SA"
            style={{
              marginTop: 20,
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
            }}
          >
            د.سماح سلامة
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "14pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            مدير إدارة الشئون التنظيمية
          </span>
          <span
            lang="AR-SA"
            style={{
              marginTop: 20,
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
            }}
          >
            د. ميريام ماهر
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "14pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            مدير وحدة الأسماء
          </span>
          <span
            lang="AR-SA"
            style={{
              marginTop: 20,
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
            }}
          >
            د.سالم عبد المنعم سالم
          </span>
        </div>
      </div>

      <h6
        style={{
          textAlign: "center",
        }}
      >
        www.eda.mohp.gov.eg
      </h6>

      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h6 style={{ textAlign: "left" }}>
            21 Abd-Elaziz Al So'od st. Manial El-Roda ,cairo ,Egypt
          </h6>
          <h6 style={{ textAlign: "left" }}>
            Tel.& Fax:+202-23610497 / +202-23610498
          </h6>
        </div>
        <div>
          <h6 style={{ textAlign: "right" }}>
            <span>عبدالعزيز آل سعود -منيل الرضة - القاهرة - مصر</span>
            <span style={{ visibility: "hidden" }}>a</span>

            <span>21</span>
          </h6>
          <h6 style={{ textAlign: "right" }}>
            تليفون وفاكس : +202-23610497 / +202-23610498
          </h6>
        </div>
      </div>
    </div>
  );
};
