//0 ---> My Assigned Applications
//1 ----> Not assigned Applications
//2 ----> Ongoing Applications
//3 ----> Completed Applications

const menuObj = {
  shared: [],
  User: [
    {
      id: 6,
      name: "Drafts",
    },
  ],
  Financial: [],
  Admin: [],
  Approver: [
    {
      id: 6,
      name: "Drafts",
    },
  ],
};
export default menuObj;
