import React from 'react'
import { SvgIcon } from "@material-ui/core";

const DefaultEntity = () => {
  return (
    <SvgIcon     viewBox="0 0 60 60"
    style={{
      enableBackground: "new 0 0 60 60",
    }}>
      <path
      style={{
        fill: "#424a60",
      }}
      d="M24 35v-9.75h.034a2.244 2.244 0 0 0-.034.375c0-2.437 3.862-4.552 9.534-5.625H3.608A3.608 3.608 0 0 0 0 23.608v11.783A3.608 3.608 0 0 0 3.608 39H24v-4z"
    />
    <path
      style={{
        fill: "#556080",
      }}
      d="M24.034 53H24V39H3.608A3.608 3.608 0 0 0 0 42.608v11.783A3.608 3.608 0 0 0 3.608 58h28.718c-4.725-1.069-7.948-2.897-8.292-5zM54.392 20H3.608A3.609 3.609 0 0 1 0 16.392V4.608A3.609 3.609 0 0 1 3.608 1h50.783A3.609 3.609 0 0 1 58 4.608v11.783A3.609 3.609 0 0 1 54.392 20z"
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={9.5}
      cy={10.5}
      r={3.5}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={49}
      cy={9}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={45}
      cy={9}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={51}
      cy={12}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={47}
      cy={12}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={41}
      cy={9}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={43}
      cy={12}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={37}
      cy={9}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={39}
      cy={12}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={33}
      cy={9}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={35}
      cy={12}
      r={1}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={9.5}
      cy={29.5}
      r={3.5}
    />
    <circle
      style={{
        fill: "#7383bf",
      }}
      cx={9.5}
      cy={48.5}
      r={3.5}
    />
    <path
      style={{
        fill: "#1a9172",
      }}
      d="M42 48.75c-9.941 0-18-2.854-18-6.375V53h.034c.548 3.346 8.381 6 17.966 6s17.418-2.654 17.966-6H60V42.375c0 3.521-8.059 6.375-18 6.375zM24 42v.375c0-.126.013-.251.034-.375H24zM59.966 42c.021.124.034.249.034.375V42h-.034z"
    />
    <path
      style={{
        fill: "#25ae88",
      }}
      d="M42 38c-9.941 0-18-2.854-18-6.375V42.75h.034c.548 3.346 8.381 6 17.966 6s17.418-2.654 17.966-6H60V31.625C60 35.146 51.941 38 42 38zM24 31.25v.375c0-.126.013-.251.034-.375H24zM59.966 31.25c.021.124.034.249.034.375v-.375h-.034z"
    />
    <ellipse
      style={{
        fill: "#88c057",
      }}
      cx={42}
      cy={21.375}
      rx={18}
      ry={6.375}
    />
    <path
      style={{
        fill: "#61b872",
      }}
      d="M42 27.75c-9.941 0-18-2.854-18-6.375V32h.034c.548 3.346 8.381 6 17.966 6s17.418-2.654 17.966-6H60V21.375c0 3.521-8.059 6.375-18 6.375zM24 21v.375c0-.126.013-.251.034-.375H24zM59.966 21c.021.124.034.249.034.375V21h-.034z"
    />
    </SvgIcon>
  )
}

export default DefaultEntity