import client from "api/apiAuth/guestClient";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ModificationAttribute from "../ModificationAttribute/ModificationAttribute";

const modificationDetailsConfig = require(`../../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/ModificationDetailsConfig`);
export default function ModificationDetails({ values, modification_id, task }) {
  const [doctor, setDoctor] = useState({});

  const getDoctorData = async (doctor_id) => {
    const { data } = await client.get("generic/find", {
      params: {
        model: "Doctor",
        filter: { id: doctor_id },
        include: [
          {
            association: "specialty",
          },
          {
            association: "segmentation",
          },
        ],
      },
    });

    let doctors = [];
    const engagements = values.invitees
      ? values.invitees.reduce(
          (group, arr) => {
            const { engagement, doctor } = arr;
            let doctorData =
              data.rows.length && data.rows.find((data) => data.id === doctor);

            group[doctorData.name] = group[doctorData.name] ?? [];

            group[doctorData.name].push(...engagement);

            return group;
          },

          {}
        )
      : data.rows.length && data.rows.find((data) => data.id === values.doctor);

    for (let index = 0; index < data.rows.length; index++) {
      const doctor = data.rows[index];
      if (values.invitees && values.invitees.length > 0) {
        doctors.push({
          ...doctor,
          engagements: engagements[doctor.name],
          doctor_package: engagements.doctor_package,
          fmv: engagements.fmv,
          hcp_locality: engagements.hcp_locality,
          travel_distance: engagements.travel_distance,
        });
      } else {
        doctors.push(doctor);
      }
    }
    let engagementsData = [];
    for (let index = 0; index < values.engagement?.length; index++) {
      const engagement = values.engagement[index];
      const { data } = await client.get("generic/find", {
        params: {
          model: "ActiveType",
          filter: { id: engagement.active_type },
        },
      });
      let active_type =
        data.rows.length &&
        data.rows.find((data) => data.id === engagement.active_type);
      engagementsData.push({ ...engagement, active_type });
    }

    setDoctor({ ...values, doctors, engagementsData });
  };
  useEffect(() => {
    if (values.doctors) getDoctorData(values.doctors);
  }, [values.doctors]);

  useEffect(() => {
    let doctors = [];
    if (values.invitees && values.invitees.length > 0) {
      doctors = values.invitees.map((invitee) => invitee.doctor);
    }

    getDoctorData(doctors);
  }, [values.invitees]);
  const modification = modificationDetailsConfig
    .config({ doctor })
    .find((mod) => mod.modification_id == modification_id);
  const getAttributes = (attr, values) => {
    if (attr.attributes) {
      if (attr.isMany) {
        let arr = [];
        for (let i = 0; i < values[attr.key].length; i++) {
          arr.push(
            <>
              {attr.name && (
                <h6
                  style={{ fontSize: 18, marginBottom: 20 }}
                  className="details-header padding"
                >
                  {i + 1}. {attr.name}
                </h6>
              )}
              {attr.attributes.map((att) =>
                getAttributes(att, values[attr.key][i], true)
              )}
            </>
          );
        }
        return arr;
      } else {
        return (
          <>
            {attr.name && (
              <h6
                style={{ fontSize: 18, marginBottom: 20 }}
                className="details-header padding"
              >
                {attr.name}
              </h6>
            )}
            {attr.attributes.map((att) => getAttributes(att, values[attr.key]))}
          </>
        );
      }
    } else {
      if (attr.parent) {
        return (
          <ModificationAttribute
            type={attr.type}
            name={attr.name}
            value={values[attr.parent][attr.key]}
            size={attr.style.gridSize}
          />
        );
      } else {
        return (
          <ModificationAttribute
            type={attr.type}
            name={attr.label}
            value={values[attr.key]}
            size={attr.style.gridSize}
          />
        );
      }
    }
  };

  return (
    <>
      <React.Fragment>
        {/* {modification?.attributes?.map((attr) => {
          return getAttributes(attr, values);
        })} */}
        {!_.isEmpty(doctor) &&
          modification?.attributes?.map((attr) => {
            return getAttributes(attr, doctor);
          })}
      </React.Fragment>
    </>
  );
}
