export const findUsers = {
    model: "User",
    include: [
      {
        association: "departments",
      },
      {
        association: "roles",
      },
      {
        association: "staff_profile",
      },
      {
        association: "org",
      },
    ],
    where: {
      org_id: { operator: "not equal", value: 8 },
      id: { include: "roles", operator: "not equal", value: "1" },

    },
  };
  // model: "Role",
  // include: [
  //   {
  //     required:true,
  //     association: "org_types",
  //   },
  // ],
  // where: {
  //   "$org_types.name$":{
  //     operator: "equal", value: "management"
  //   }
  // }

  export const findUsersRoles = {
    model: "Role",
    where: {
      name: { operator: "not in", value: ["Distributor", "admin"] },
    },
  }
  export const findUsersOrgs = {
    model: "Organization"
  }
