import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Grid,
  Popover,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./style.scss";
import _ from "lodash";
import ViewFile from "../ViewFile";
import config from "./config";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";

const Comments = ({ comments, allAttachments, user }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [popoverContent, setPopoverContent] = useState([]);
  const [timelineUsers, setTimelineUsers] = useState([]);
  const handleClickPopover4 = (event, userComments) => {
    setAnchorEl4(event.currentTarget);
    setPopoverContent(userComments);
  };
  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const handleClosePopover4 = () => {
    setAnchorEl4(null);
  };
  const [anchorEl4, setAnchorEl4] = useState(null);
  const open4 = Boolean(anchorEl4);
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    if (comments?.length) {
      let tasks = [];
      comments.forEach((task) => {
        let clonedTask = _.cloneDeep(task);
        let attachments =
          (allAttachments.length > 0 &&
            allAttachments.filter(
              (attachment) =>
                attachment.task_id == clonedTask.id &&
                attachment?.document.isComment
            )) ||
          [];
        clonedTask.attachments = attachments;
        if (attachments.length > 0 || task?.comments?.length > 0) {
          // clonedTask.user = task.users[0];
          let { users, comments } = task;
          const newComments = comments
            .map((item) => {
              if (
                item.comment &&
                users.find((elem) => elem.id === item.user_id)
              )
                return {
                  ...item,
                  user: users.find((elem) => elem.id === item.user_id),
                };
            })
            .filter((item) => item);
          clonedTask.comments = newComments;
          clonedTask.step = task.step;
          clonedTask.user = newComments.map((comment) => comment.user);
          tasks.push(clonedTask);
        }
      });
      let result = tasks.reduce(function (previousValue, currentValue) {
        let { comments } = currentValue;
        comments.map((comment) => {
          if (
            comment.comment &&
            !(
              previousValue[comment.user?.username] &&
              previousValue[comment.user?.username].some(
                (el) =>
                  el.user_id === comment.user_id &&
                  el.task_id === comment.task_id
              )
            )
          ) {
            previousValue[comment.user?.username] =
              previousValue[comment.user?.username] || [];

            previousValue[comment.user?.username].push({
              ...currentValue,
              ...comment,
            });
          }
        });
        Object.keys(previousValue).forEach(
          (key) => previousValue[key] === undefined && delete previousValue[key]
        );
        return previousValue;
      }, {});
      Object.keys(result).forEach((element) => {
        result[element] = result[element].filter((task) => {
          let constraintsResult = [];
          user.roles.forEach((role) => {
            let configs = config(task)[role.name];
            if (!_.isEmpty(configs) && configs.constraints)
              constraintsResult.push(
                applyDetailsConstraints(configs.constraints)
              );
            else {
              constraintsResult.push({ display: true });
            }
          });

          if (
            constraintsResult.some((constraint) => constraint.display == true)
          ) {
            return task;
          } else {
            return false;
          }
        });
      });
      setTimelineUsers(result);
    }
  }, [comments, allAttachments]);
  return (
    <>
      {!_.isEmpty(timelineUsers) && (
        <div className="accordion mb-spacing-6-x2">
          <Card className={clsx("card-box ", { "panel-open": isOpen })}>
            <Card>
              <div className="card-header">
                <div className="panel-title">
                  <div className="accordion-toggle">
                    <Button
                      variant="text"
                      size="large"
                      className="btn-link d-flex align-items-center justify-content-between btn-transition-none"
                      onClick={toggle}
                    >
                      <span className="comments-title">Comments</span>
                      {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </Button>
                  </div>
                </div>
              </div>
              <Collapse in={isOpen} className="content">
                <Grid container>
                  {Object.keys(timelineUsers).map((username, index) => {
                    return (
                      timelineUsers[username].length > 0 && (
                        <Grid
                          item
                          container
                          md={6}
                          className="mt-3 "
                          key={index}
                        >
                          <Grid item md={5} className="ml-3">
                            <p className="username-title mb-0">{username}</p>
                            {timelineUsers[username][0].user.roles.map(
                              (role, roleIndex) => (
                                <p className="role-title" key={roleIndex}>
                                  {role.name}
                                </p>
                              )
                            )}
                          </Grid>
                          <Grid item md={6}>
                            <Button
                              className="m-2 comments-btn pt-0 pb-0 pl-2 pr-1"
                              onClick={(e) =>
                                handleClickPopover4(e, timelineUsers[username])
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 512 512"
                                className="mr-2"
                              >
                                <path
                                  d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2l-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29c7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1l-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160s-93.3 160-208 160z"
                                  fill="currentColor"
                                />
                              </svg>
                              <span>
                                <b>Comments</b> (
                                {timelineUsers[username].length})
                                <ExpandMoreIcon />
                              </span>
                            </Button>
                            <Popover
                              open={open4}
                              anchorEl={anchorEl4}
                              classes={{
                                paper:
                                  "popover-custom-wrapper rounded popover-custom-md bg-second text-white ",
                              }}
                              onClose={handleClosePopover4}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              PaperProps={{
                                style: {
                                  width: "40%",
                                  borderRadius: "5px !important",
                                },
                              }}
                            >
                              <Box className="p-4">
                                {popoverContent.map((task, index) => {
                                  return (
                                    <>
                                      {(task.comment ||
                                        task.attachments.length > 0) && (
                                        <>
                                          <div className="d-flex justify-content-between">
                                            <h6 className="step-font">
                                              <b>Step: {task.step.name}</b>
                                            </h6>
                                            <h6 className="step-font">
                                              <b>
                                                Target: {task.comment_target}
                                              </b>
                                            </h6>
                                          </div>
                                          <p className="comment-font">
                                            {task.comment}
                                          </p>
                                          <div>
                                            {task.attachments.map(
                                              (attachment) => (
                                                <Button
                                                  className="m-2 btn-neutral-primary file-color"
                                                  onClick={() => {
                                                    setFile(attachment);
                                                    setOpen(true);
                                                  }}
                                                >
                                                  View File
                                                </Button>
                                              )
                                            )}
                                          </div>
                                          <ViewFile
                                            file={file}
                                            open={open}
                                            setOpen={setOpen}
                                          />
                                          <span className="date-font mb-2">
                                            {new Date(
                                              task.createdAt
                                            ).toLocaleTimeString(
                                              "en-US",
                                              DATE_OPTIONS
                                            )}
                                          </span>
                                          {index !=
                                            popoverContent.length - 1 && (
                                            <Divider
                                              style={{ background: "#7A81A1" }}
                                              className="m-3"
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </Box>
                            </Popover>
                          </Grid>
                        </Grid>
                      )
                    );
                  })}
                </Grid>
              </Collapse>
            </Card>
          </Card>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    comments: state.details.commentTasks,
    allAttachments: state.details.allAttachments,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(Comments);
