/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import bioInLogo from "assets/images/bio-inn.png";
import edaLogo from "assets/images/Image 1.png";
import footer from "assets/images/footer.png";
import "./index.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import config from "../index.js";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const CheckListCPMP = ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  form,
  user,
  setCheckListFormFlag,
}) => {
  const [imageSources, setImageSources] = useState({});
  const [footerImageSources, setFooterImageSource] = useState({});
  useEffect(() => {
    setCheckListFormFlag(false);
    setValues(config(letter, formValues, "checklistCPMP"));
  }, []);
  let [initialValues, setInitialValues] = useState({});

  const checkListSchema = {
    row2: Yup.string().required("Required"),
    row3: Yup.string().required("Required"),
    row4: Yup.string().required("Required"),
    row5: Yup.string().required("Required"),
    row6: Yup.string().required("Required"),
    row7: Yup.string().required("Required"),
    row8: Yup.string().required("Required"),
    row9: Yup.string().required("Required"),
    row10: Yup.string().required("Required"),
  };
  const checkListForm = useFormik({
    validationSchema: Yup.object().shape(checkListSchema),

    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    setCheckListFormFlag(checkListForm.dirty && checkListForm.isValid);
  }, [checkListForm]);
  function toDataURL(sources) {
    Object.entries(sources).forEach(([key, src]) => {
      let image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        if (key === "footer") {
          setFooterImageSource({ [key]: dataURL });
          return;
        } else {
          setImageSources((oldState) => ({ ...oldState, [key]: dataURL }));
        }
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src =
          "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }
    });
  }

  useEffect(() => {
    toDataURL({ edaLogo, bioInLogo });
    toDataURL({ footer });
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    checkListForm.handleChange(event);
    setValues({
      ...values,
      [name]: value,
    });
    if (_.isEmpty(checkListForm.errors) && !_.isEmpty(values)) {
      setCheckListFormFlag(true);
    } else {
      setCheckListFormFlag(false);
    }
  };
  useEffect(() => {
    const checklistCPMP = document.getElementById("checklist_cpmp");
    const radioButtons = checklistCPMP?.querySelectorAll(
      'input[type="radio"]:checked'
    );
    radioButtons.forEach((input) => {
      input.setAttribute("checked", "checked");
    });
    setDivRef((divRefs) => ({ ...divRefs, checklist_cpmp: checklistCPMP }));
  }, [values, formValues]);
  return (
    <div
      style={{
        padding: "20px",
        zoom: 0.88,
      }}
      id="checklist_cpmp"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            CA of Biological and Innovative products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            and Clinical Studies
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            GA of Biological Product
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Lot Release Administration
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          {Object.values(imageSources)?.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              style={{ width: "150px", height: "100px" }}
              alt="bio-inn"
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هـيـئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الحيوية والمبتكرة
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            والدراسات الإكلينيكية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات الحيوية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            إدارة الافراج عن التشغيلات
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <td>
              <span
                style={{
                  fontSize: "14pt",
                  fontFamily: ["Arial", "sans-serif"],
                }}
              >
                Lot Release administration
              </span>
            </td>

            <td>
              <label
                htmlFor="scales"
                style={{
                  fontSize: "14pt",
                  fontFamily: ["Arial", "sans-serif"],
                }}
              >
                Unit:
              </label>
              <span
                style={{
                  fontSize: "14pt",
                  fontFamily: ["Arial", "sans-serif"],
                }}
              >
                complaints and post-marketing program samples
              </span>
            </td>
          </tr>
        </thead>
      </table>
      <div
        style={{
          marginTop: "10px",
          border: "3px solid 	#101010",
          padding: "10px",
        }}
      >
        <b style={{ color: "#101010" }}>
          Checklist for receiving risk-based PMP samples
        </b>
      </div>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <table style={{ width: "100%" }}>
          <tbody style={{ border: "none", borderCollapse: "collapse" }}>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Name of product:</span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.productName}
                  name="productName"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Batch number(s): </span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.batchNumber}
                  name="batchNumber"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Manufacture:</span>
              </td>
              <td className="table-text-style">
                <input
                  className="input-style"
                  type="text"
                  placeholder="............................."
                  value={values.Manufacturer}
                  name="Manufacturer"
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Sampling Site: </span>
              </td>

              <td className="table-text-style">
                <input
                  value={values.sampling_site}
                  name="sampling_site"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Unique Identifier: </span>
              </td>
              <td className="table-text-style">
                <input
                  value={values.unique_identifier}
                  name="unique_identifier"
                  placeholder="............................."
                  style={{
                    width: "90%",
                    minWidth: "30%",
                    minHeight: "30px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table
              style={{
                textAlign: "left",
                marginTop: "15px",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <td className="table-header">S.N</td>
                  <td className="table-header">Item</td>
                  <td className="table-header">Accepted</td>
                  <td className="table-header">Not Accepted</td>
                  <td className="table-header">Remarks</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-border">
                    <b>1.</b>
                  </td>
                  <td className="table-border">
                    <span>Request for samples testing</span>
                  </td>

                  <td className="table-border">
                    <input
                      type="radio"
                      name="row1"
                      id="Accepted1"
                      value="Accepted1"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="row1"
                      id="NotAccepted1"
                      value="NotAccepted1"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks1}
                      name="Remarks1"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <b>2.</b>
                  </td>
                  <td className="table-border">
                    <span>Proof of payment of the sample testing fee</span>
                  </td>

                  <td className="table-border">
                    <input
                      type="radio"
                      name="row2"
                      id="Accepted2"
                      value="Accepted2"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="row2"
                      id="NotAccepted2"
                      value="NotAccepted2"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks2}
                      name="Remarks2"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.</b>
                  </td>
                  <td className="table-border">
                    <span style={{ fontWeight: "bold" }}>
                      Sample screening at reception:
                    </span>
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.1</b>
                  </td>
                  <td className="table-border">
                    <span>Seal</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted3"
                      type="radio"
                      name="row3"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted3"
                      type="radio"
                      name="row3"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks3}
                      name="Remarks3"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.2</b>
                  </td>
                  <td className="table-border">
                    <span>Cooling status at reception</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted4"
                      type="radio"
                      name="row4"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted4"
                      type="radio"
                      name="row4"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks4}
                      name="Remarks4"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.3</b>
                  </td>
                  <td className="table-border">
                    <span>No. of samples received</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted5"
                      type="radio"
                      name="row5"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted5"
                      type="radio"
                      name="row5"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks5}
                      name="Remarks5"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.4</b>
                  </td>
                  <td className="table-border">
                    <span>Solvent</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted6"
                      type="radio"
                      name="row6"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted6"
                      type="radio"
                      name="row6"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks6}
                      name="Remarks6"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.5</b>
                  </td>
                  <td className="table-border">
                    <span>Shape of the package</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted7"
                      type="radio"
                      name="row7"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted7"
                      type="radio"
                      name="row7"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks7}
                      name="Remarks7"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.6</b>
                  </td>
                  <td className="table-border">
                    <span>Leaflet & label</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted8"
                      type="radio"
                      name="row8"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted8"
                      type="radio"
                      name="row8"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks8}
                      name="Remarks8"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.7</b>
                  </td>
                  <td className="table-border">
                    <span>Manufacture date</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted9"
                      type="radio"
                      name="row9"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted9"
                      type="radio"
                      name="row9"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks9}
                      name="Remarks9"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.8</b>
                  </td>
                  <td className="table-border">
                    <span>Expiry date</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Accepted10"
                      type="radio"
                      name="row10"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="NotAccepted10"
                      type="radio"
                      name="row10"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks10}
                      name="Remarks10"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td className="table-text-style" style={{ width: "60%" }}>
                    <span style={{ display: "block" }}>Remarks:</span>
                    <textarea
                      value={values.remarks}
                      name="remarks"
                      placeholder="............................."
                      rows="2"
                      style={{
                        minWidth: "90%",
                        minHeight: "30px",
                        marginTop: "5px",
                      }}
                      onChange={handleChange}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      Received By:{" "}
                    </span>
                    <span>{user.username}</span>
                  </td>
                  <td>
                    <span>Date: </span>
                    <span>{new Date().toLocaleString()}</span>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div className="row">
        <img
          src={footerImageSources["footer"]}
          style={{ width: "90%" }}
          alt="bio-inn footer"
        />
      </div>
    </div>
  );
};

export default CheckListCPMP;
