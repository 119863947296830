import React from "react";
import { Grid } from "@material-ui/core";
import "./index.scss";

const Info = ({ value, options, input }) => {
  const isObject = (variable) => {
    return (
      typeof variable === "object" &&
      !Array.isArray(variable) &&
      variable !== null
    );
  };
  let isArrayOfObject = options.every((option) => isObject(option));
  let selectedValue;
  if (isArrayOfObject) {
    selectedValue = options.find((option) => option.id === value);
  } else {
    selectedValue = value;
  }

  return (
    <div variant="outlined" sx={{ flexGrow: 1 }} className="CardDetail">
      <span className="card-title">{input.name} Information</span>

      {selectedValue &&
        (isObject(selectedValue) ? (
          Object.entries(selectedValue).map(([key, value], index) => {
            return (
              <div key={index}>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                  columns={12}
                >
                  <Grid item xs={6} className="lebal">
                    {key}
                  </Grid>
                  <Grid item xs={6} className="data">
                    {isObject(value) ? value.name : value}
                  </Grid>
                  <br />
                </Grid>
              </div>
            );
          })
        ) : (
          <div>{selectedValue}</div>
        ))}
    </div>
  );
};
export default Info;
