import React, { useEffect } from "react";

import Program from "../../components/Program";
import fontawesome from "@fortawesome/fontawesome";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

import {
  faHandHoldingHeart,
  faHandsHelping,
  faGrinBeam,
  faLifeRing,
  faDollarSign,
  faPencilAlt,
  faChild,
  faFemale,
  faWheelchair,
  faHouseUser,
  faTasks,
  faPlaneArrival,
  faMapMarkerAlt,
  faBoltLightning,
  faFileCirclePlus,
  faArrowsSpin,
} from "@fortawesome/free-solid-svg-icons";
import { fetchForms } from "reducers/list";
import { getAllowedSubmissionCount } from "reducers/settings/settingsActions";

fontawesome.library.add(
  faHandHoldingHeart,
  faHandsHelping,
  faGrinBeam,
  faLifeRing,
  faDollarSign,
  faPencilAlt,
  faChild,
  faFemale,
  faWheelchair,
  faHouseUser,
  faTasks,
  faPlaneArrival,
  faMapMarkerAlt,
  faBoltLightning,
  faFileCirclePlus,
  faArrowsSpin
);

function Programs({ forms, fetchForms, getAllowedSubmissionCount, user }) {
  useEffect(() => {
    fetchForms({
      model: "Form",
      include: {
        association: "roles",
        where: {
          name: user.roles[0].name,
        },
      },
      // where: {
      //   role_name: { operator: "contains", value: [user.roles[0].name] },
      // },
    }).catch((err) => {
      console.log(err);
    });
    getAllowedSubmissionCount({ user_id: user.id });
  }, [user]);
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <Grid item xl={10} className="p-5">
          {/* FIXME:needs localization */}
          <h2 style={{ textAlign: "center", color: "#171c30 " }}>
            {"Please select the request type"}
          </h2>
        </Grid>
        <Grid
          item
          xl={10}
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {forms?.map((form) => (
            <Program form={form} key={form.id} />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchForms: (params) => dispatch(fetchForms(params)),
    getAllowedSubmissionCount: (params) =>
      dispatch(getAllowedSubmissionCount(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Programs);
