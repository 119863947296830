import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SubmissionType({ data, formik, handleTypeChange }) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">
          Allowed Types
        </InputLabel>

        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formik.values.allowed_type || ""}
          label="Allowed Types"
          name="allowed_type"
          onChange={(e) => {
            formik.handleChange(e);
            handleTypeChange(e.target.value);
          }}
        >
          {data?.allowed_types.types?.map((value, index) => (
            <MenuItem value={value} key={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
