import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Attributes from "../Attributes";
import { Divider } from "@material-ui/core";
import NewRenderer from "./NewRenderer/SectionContentRenderer";
const sectionContentConfig = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/sectionContent`);

const getAttributes = (
  task,
  section,
  selectedTab,
  groupIndex,
  group,
  childIndex,
  allProps
) => {
  const renderChildSection = ({ section, task, groupIndex, selectedTab }) => {
    if (section?.child?.useNewRenderer)
      return <NewRenderer task={task} section={section.child} />;

    return task.submission[section.group][groupIndex][section.child.group].map(
      (elements, childIndex) =>
        getAttributes(
          task,
          section.child,
          selectedTab,
          groupIndex,
          section.child.group,
          childIndex
        )
    );
  };
  return (
    <React.Fragment
      key={`${groupIndex}${typeof childIndex != "undefined" ? childIndex : 0}`}
    >
      {section.title &&
        (typeof childIndex != "undefined" ? (
          <h6
            style={{ paddingLeft: 15 }}
            className="details-header padding"
          >{`${selectedTab?.id}.${section.id}.${childIndex + 1} ${
            section.title
          }`}</h6>
        ) : (
          <>
            <h3 className="details-header padding">{`${selectedTab?.id}.${section.id} ${section.title}`}</h3>
          </>
        ))}
      {section?.attributes?.map((attribute, index) => {
        return (
          <React.Fragment key={index}>
            <Grid
              style={
                typeof childIndex != "undefined" ? { paddingLeft: 15 } : {}
              }
            >
              <Attributes
                attribute={attribute}
                task={task}
                groupIndex={groupIndex}
                group={group}
                childIndex={typeof childIndex != "undefined" ? childIndex : 0}
                allProps={allProps}
              />
            </Grid>
          </React.Fragment>
        );
      })}
      <Divider style={{ marginTop: "32px", marginBottom: "32px" }} />
      {section.child &&
        renderChildSection({ section, task, groupIndex, selectedTab })}
    </React.Fragment>
  );
};
function SectionContent({ task, selectedTab, ...props }) {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const tab = sectionContentConfig
      .config({})
      .find((tab) => tab.tabId === selectedTab.id);
    setSections(tab.sections);
  }, [selectedTab]);
  return (
    <React.Fragment>
      {sections.map((section, index) =>
        section.useNewRenderer ? (
          <NewRenderer section={section} task={task} allProps={props} />
        ) : (
          section?.attributes &&
          (section.group
            ? task.submission[section.group].map((elements, groupIndex) =>
                getAttributes(
                  task,
                  section,
                  selectedTab,
                  groupIndex,
                  section.group,
                  null,
                  props
                )
              )
            : getAttributes(task, section, selectedTab, index))
        )
      )}
    </React.Fragment>
  );
}

export default SectionContent;
