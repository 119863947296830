export {default as Drug} from './Drug.js';
export {default as Hospital} from './Hospital.js';
export {default as User} from './User.js';
export {default as Default} from './DefaultEntity.js';


  /**---------------------------------------------------------------------
  |  customSvgMuiIcons
  |
  |  Purpose:  importing custom SVG Icons as React Component using Mui
  |
  |
  |  How to:
  |      - Download SVG img from https://www.svgrepo.com/
  |       - Check its code using VScode or any other text editor 
  |       - copy its code and paste it in the playground of react-SVGR @https://react-svgr.com/playground/
  |       - it will automatically generate a react component
  |       - import SvgIcon from @material-ui/core
  |       - copy and paste the generated react component into the your code 
  |       - dont forget to adjust the viewbox attribute in the SvgIcon tag to adjust to the svg size
  *-------------------------------------------------------------------*/