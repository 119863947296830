import React, { useEffect, useState } from "react";
import client from "api/apiAuth/guestClient";
import { SyncLoader } from "react-spinners";
import { Grid, Select, MenuItem, Button, TableCell } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";

function transformColumn(column) {
  const columnDescription = {
    field: column.name,
    headerName: column.name,
    type: column.type,
    align: "center",
    editable: true,
    flex: 1,
  };

  if (column.type === "date") {
    columnDescription.type = "dateTime";
    columnDescription.valueGetter = ({ value }) => value && new Date(value);
  }

  return columnDescription;
}

const TesterPlayground = (props) => {
  const [systemTables, setSystemTables] = useState(null);
  const [selectedSystemIndex, setSelectedSystemIndex] = useState(0);
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    async function doNetworkRequests() {
      const response = await client.post("generic/tester", {
        action: "getAllTables",
      });
      setSystemTables(response.data);
      const tableDataResponse = await client.post("generic/tester", {
        action: "getDataFor",
        model: response.data[0].name,
      });
      setTableData(tableDataResponse.data);
    }
    doNetworkRequests();
  }, []);

  const fetchDataForTable = async (index) => {
    const tableDataResponse = await client.post("generic/tester", {
      action: "getDataFor",
      model: systemTables[index].name,
    });
    setTableData(tableDataResponse.data);
  };

  if (!systemTables || !tableData) return <SyncLoader />;

  return (
    <div className="w-100">
      <hr />
      <h1>Tester Playground</h1>
      <Grid container>
        <Grid xs={12} item>
          <h3>Tester Actions:</h3>
          <div className="d-flex justify-content-around">
            <Button
              variant="contained"
              style={{ backgroundColor: "red", color: "white" }}
              size="large"
              onClick={() => {
                client.post("generic/tester", { action: "performDBReset" });
              }}
            >
              DB RESET
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "green", color: "white" }}
              size="large"
              onClick={() => {
                client.post("generic/tester", { action: "simulateCronJob" });
              }}
            >
              Simulate Cron Job
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "green", color: "white" }}
              size="large"
              onClick={() => {
                client.post("generic/tester", { action: "queueCronJob" });
              }}
            >
              Calling queue cron job
            </Button>
          </div>
          <hr />
        </Grid>
        <Grid xs={12} item>
          <h3>Select Table:</h3>
          <Select
            value={systemTables[selectedSystemIndex].name}
            onChange={(event) => {
              for (let i = 0; i < systemTables.length; i++)
                if (systemTables[i].name === event.target.value) {
                  setSelectedSystemIndex(i);
                  fetchDataForTable(i);
                  return;
                }
            }}
          >
            {systemTables.map((item) => (
              <MenuItem value={item.name} key={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <hr />
        </Grid>
        <Grid xs={12} item>
          <h3>Table Data:</h3>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                className="table-grid"
                rows={tableData}
                columns={systemTables[selectedSystemIndex].columns.map(
                  transformColumn
                )}
                autoHeight
                pageSize={100}
                // checkboxSelection
                disableSelectionOnClick
                processRowUpdate={(newRow) => {
                  client.post("generic/tester", {
                    action: "updateThis",
                    model: systemTables[selectedSystemIndex].name,
                    data: newRow,
                  });
                  return newRow;
                }}
                onProcessRowUpdateError={() => {}}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TesterPlayground;
