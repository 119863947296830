import React, { useState, useEffect } from "react";
import SysInputs from "./SysInputs";

import { Grid, Button } from "@material-ui/core";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider, setIn } from "formik";

import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { getSettingValidationSchema } from "utils/setValidationSchema";

const CustomSettings = ({ id }) => {
  const [validationSchema, setValidationSchema] = useState(null);
  const [allInputs, setAllInputs] = useState();
  const [initialValues, setInitialValues] = useState({});
  let formik;
  formik = useFormik({
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  let history = useHistory();

  const getSettingInputs = async () => {
    const response = await client.get("settings/getSysSettingInputs", {
      params: {
        workflow_id: id,
      },
    });
    if (response.data.inputs) {
      const allInputs = response.data.inputs;
      setValidationSchema(getSettingValidationSchema(allInputs, false));
      setAllInputs(allInputs);
      let values = {};
      allInputs.forEach((input) => {
        values[input.key] = input.value;
      });
      setInitialValues(values);
    }
  };

  useEffect(() => {
    getSettingInputs();
  }, []);

  const submitSettings = async () => {
    const res = await formik.validateForm();

    if (!_.isEmpty(res) || _.isEmpty(formik.values)) {
      Swal.fire({
        title: "fields are either required or invalid",
        icon: "error",
        confirmButtonColor: "#d36467",
        confirmButtonText: "Try Again",
        width: "50%",
      });
    } else {
      client
        .post("/settings/submitSettings", {
          values: formik.values,
        })
        .then(() => {
          Swal.fire({
            title: "Your Settings has been saved successfully!",
            imageWidth: 250,
            imageHeight: 200,
            imageAlt: "Custom image",
            confirmButtonColor: "#0066cc",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return (
    <>
      <h6
        style={{
          paddingBottom: 15,
          textShadow: "2px 2px 8px rgba(0,0,0,.2)",
          color: "#0066cc",
        }}
      >
        Other Settings :
      </h6>
      <Grid container>
        {allInputs ? (
          <>
            <FormikProvider value={formik}>
              <Grid
                container
                spacing={4}
                style={{ justifyContent: "space-between" }}
              >
                {allInputs.map((input, index) => (
                  <React.Fragment key={index}>
                    <SysInputs formik={formik} input={input} />
                  </React.Fragment>
                ))}
              </Grid>
            </FormikProvider>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="primary"
                className="m-5"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to submit your Settings? ",
                    showConfirmButton: true,
                    titleHeight: 200,
                    showCancelButton: true,
                    confirmButtonClass: "submit",
                    cancelButtonClass: "cancel",
                    titleClass: "fontSize",
                    customClass: "swal-wide",
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                    closeOnConfirm: false,
                  }).then(function (isConfirm) {
                    submitSettings();
                  });
                }}
              >
                Submit
              </Button>
            </div>
          </>
        ) : (
          <div>
            <p>Loading</p>
          </div>
        )}
      </Grid>
    </>
  );
};

export default CustomSettings;
