import React from "react";

import { Grid } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";

import styles from "./index.module.css";
import logo from "../../assets/images/logo.png";
const {
  infoText,
  primaryTitle,
  secondaryTitle,
  loginEDAText,
  loginCompanyText,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/pages/landing`);

const Landing = ({ location }) => {
  const history = useHistory();

  const originalPath = location.state?.from;

  const goToCompanyLogin = () => {
    history.push({ pathname: "/login/company", state: { from: originalPath } });
  };

  const goToEDALogin = () => {
    history.push({ pathname: "/login/eda", state: { from: originalPath } });
  };

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        lg={6}
        md={12}
        className={`${styles.container__textblock} d-flex flex-column align-items-center`}
      >
        <div
          className={`${styles.container__textblock__logoblock} d-flex flex-column justify-content-center p-lg-5`}
        >
          <img src={logo} className={styles.container__textblock__logo} />
        </div>

        <div
          className={`${styles.container__textblock__text} d-flex flex-column align-items-center`}
        >
          <h2 className={`w-50 ${styles.container__title1}`}>{primaryTitle}</h2>
          <h2 className={styles.container__title2}>{secondaryTitle}</h2>

          <h2 className={`${styles.container__infotext}`}>{infoText}</h2>
        </div>

        <div
          className={`${styles.container__footer} d-flex flex-column align-items-center justify-content-end`}
        ></div>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        className={`${styles.container__buttonsblock} d-flex flex-column align-items-center justify-content-center`}
      >
        <div
          className={`${styles.container__buttonsblock__buttons} d-flex align-items-center justify-content-around`}
          onClick={goToEDALogin}
          id="loginEda"
        >
          <div>
            <span>{loginEDAText}</span>
          </div>
          <div>
            <ChevronRight />
          </div>
        </div>
        <div
          className={`${styles.container__buttonsblock__buttons} mt-4 d-flex align-items-center justify-content-around`}
          onClick={goToCompanyLogin}
          id="loginCompany"
        >
          <div>
            <span>{loginCompanyText}</span>
          </div>
          <div>
            <ChevronRight />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Landing;
