import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import "./style.css";
import ExportToExel from "../../../components/ExportToExcel";
import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TablePagination,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PerfectScrollbar from "react-perfect-scrollbar";
const useStyles = makeStyles({
  root: {
    "& .MuiButtonBase-root": {
      transform: "TranslateY(0px) !important",
    },
  },
});

const SimilarSubmissionsTable = (props) => {
  const classes = useStyles();
  const [similarSubmisisons, setSimilarSubmisisons] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchSimilarSubmissions = async () => {
    const response = await client.post("generic/find", {
      model: "AllDrugsData",
      where: {
        Applicant_id: {
          operator: "equal",
          value: props.task.submission.org.id,
        },
        MarketType: {
          operator: "equal",
          value: props.task.submission.values.marketing_type,
        },
        Type_of_license: {
          operator: "equal",
          value: props.task.submission.values.registration_type,
        },
      },
    });
    setSimilarSubmisisons(response.data.rows);
  };

  useEffect(() => {
    fetchSimilarSubmissions();
  }, [props.task?.id]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid
      container
      item
      xs={props.part.style.attributes.GridSize}
      key={props.partIndex}
      className={props.part.style.classes}
    >
      <Accordion
        className="w-100"
        style={{ color: "#0066cc" }}
        classes={{ root: classes.root }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="customSummary"
          style={{ backgroundColor: "#FAF0E0" }}
        >
          <span className="tab-card-title" style={{ color: "#0066cc" }}>
            Similar Submissions
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#FAF0E0" }}>
          <Grid container>
            <Grid item xs={12} className="my-1" style={{ direction: "rtl" }}>
              <ExportToExel
                data={similarSubmisisons}
                columns={[]}
                exportedSheetName={`similarSubmisionsTableFor_${props?.task?.submission?.file_code}`}
              />
            </Grid>
            <Grid item xs={12}>
              <PerfectScrollbar
                options={{
                  suppressScrollY: true,
                  useBothWheelAxes: true,
                }}
                className="displayScroll"
              >
                <Card className="card-box">
                  <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
                    <TableHead className="font-size-sm">
                      <TableRow>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Trade Code
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Marketing Type
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Registration Type
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Status
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Active ingredients
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Dosage Form
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Trade Name
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Drug License Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {similarSubmisisons?.length > 0 &&
                        (rowsPerPage > 0
                          ? similarSubmisisons.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : similarSubmisisons
                        ).map((submission, index) => (
                          <TableRow key={index}>
                            <TableCell className="text-center">
                              <p>{submission.TradeCode}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.MarketType}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.Type_of_license}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.regstatus}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.Generics}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.Dosage_form}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.Trade_name}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{submission.Drug_license_number}</p>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Card>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={similarSubmisisons.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={""}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </PerfectScrollbar>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SimilarSubmissionsTable;
