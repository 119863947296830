import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
const availableRoutes = require(`../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/availableRoutes.json`);
// import { logout } from '../reducers/auth';

const PrivateRoute = ({ auth, user, component: Component, ...rest }) => {
  let { loading, currentlyLogout } = auth;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loading) {
          if (!user) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: currentlyLogout ? undefined : props.location },
                }}
              />
            );
          }
          // Authorized so return component
          if (
            availableRoutes["shared"].availableRoutes.includes(rest.path) ||
            user?.roles.some((role) => {
              return availableRoutes[role.name].availableRoutes?.includes(
                rest.path
              );
            })
          ) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: availableRoutes[user?.roles[0].name].default,
                  state: { from: props.location },
                }}
              />
            );
          }
        } else {
          return "";
        }
      }}
    />
  );
};
function mapStateToProps(state) {
  return { auth: state.auth, user: state.auth.user };
}
const composedHOC = compose(connect(mapStateToProps, null))(PrivateRoute);
export default composedHOC;
