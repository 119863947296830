import lodash from "lodash";

function takeActionFlagIsFalse(mapper) {
  return !mapper.takeActionFlag;
}

function currentUserIsCompany(mapper) {
  return mapper.currentUser.roles.some((role) => role.name === "User");
}

function isStepEditForm(mapper) {
  return mapper.task.step.edit_form;
}

function getUnreviewedAttachments(mapper) {
  if ("submissionModifications" in mapper.task.submission) {
    for (const modification of mapper.task.submission.submissionModifications) {
      if (
        modification.status === "Pending" &&
        modification.task_id === mapper.task.id
      ) {
        return mapper.attachmentsUnderReview.filter(
          (attachment) =>
            attachment.submission_modification_id === modification.id
        );
      }
    }
  }

  return mapper.attachmentsUnderReview.filter(
    (attachment) => !attachment.is_reviewed
  );
}

function allRejectedAttachmentsUploaded(mapper) {
  for (const attachment of getUnreviewedAttachments(mapper))
    if (!(attachment.document.equivalent_key in mapper.uploadedAttachments))
      return false;
  return true;
}

function isLoading(mapper) {
  return mapper.saveSubmissionsValuesLoader;
}

function currentStepIdIs(mapper, stepId) {
  return mapper.task.step.id === stepId;
}

function oneNameIsAcceptedInDecisions(mapper) {
  return (
    mapper.task.submission.values?.naming?.drugs?.filter(
      (c) => c.decision == true
    ).length === 1
  );
}

function oneNameIsAcceptedInFinalDecisions(mapper) {
  return (
    mapper.task.submission.values?.naming?.drugs?.filter(
      (c) => c.finalDecision == "accept"
    ).length === 1
  );
}

function submissionIsModification(mapper) {
  return mapper.task.submission.status === "Modification";
}

function checkNamingDecisionForTakeAction(mapper) {
  if ([12, 13].includes(mapper.task.step.id)) {
    const decisions = [];
    for (let att of mapper.attachmentsUnderReview) {
      if (att.attachment_values?.decisions) {
        decisions.push(...att.attachment_values?.decisions);
      }
    }
    const reviewerIds = decisions.map((decision) => decision.user_id);
    return reviewerIds.includes(mapper.currentUser.id);
  } else {
    return true;
  }
}

function roleHasReviewedAllDrugs(mapper, roleName) {
  return mapper.task.submission.values?.naming?.drugs?.every(
    (drug) => "allDecisions" in drug && roleName in drug.allDecisions
  );
}

function keyExistsInSubmissionValues(mapper, key) {
  if (lodash.get(mapper.task.submission, key)) return true;
  return false;
}

function stepInputFlagIsTrue(mapper) {
  return mapper.stepInputFlag;
}

function disableTakeActionWhen(mapper) {
  if (isLoading(mapper)) return true;

  if (
    isStepEditForm(mapper) &&
    currentUserIsCompany(mapper) &&
    !allRejectedAttachmentsUploaded(mapper)
  )
    return true;

  if (
    currentStepIdIs(mapper, 60) &&
    !oneNameIsAcceptedInDecisions(mapper) &&
    !oneNameIsAcceptedInFinalDecisions(mapper)
  )
    return true;

  if (
    currentStepIdIs(mapper, 12) &&
    !(
      roleHasReviewedAllDrugs(mapper, "Junior Naming") &&
      checkNamingDecisionForTakeAction(mapper)
    )
  )
    return true;

  if (
    currentStepIdIs(mapper, 13) &&
    !(
      roleHasReviewedAllDrugs(mapper, "Senior Naming") &&
      checkNamingDecisionForTakeAction(mapper)
    )
  )
    return true;

  if (
    (currentStepIdIs(mapper, 20) || currentStepIdIs(mapper, 60)) &&
    !roleHasReviewedAllDrugs(mapper, "Unit Manager Naming")
  )
    return true;

  if (
    (currentStepIdIs(mapper, 35) || currentStepIdIs(mapper, 46)) &&
    !stepInputFlagIsTrue(mapper) &&
    !keyExistsInSubmissionValues(mapper, "committe_date")
  )
    return true;

  return false;
}

export { disableTakeActionWhen };
