import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { SyncLoader } from "react-spinners";
import client from "api/apiAuth/guestClient";
import TableTabs from "components/TableTabs";
import TableView from "components/Table";
import { fetchWorklistLayout, fetchFilterData } from "reducers/list";
import { connect } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import Swal from "sweetalert2";
import lodash from "lodash";

function doNothing() {}

function prepareGroupValuesForBackend(groupValues, currentGroupType) {
  const preparedObject = { ...groupValues };
  if (currentGroupType && currentGroupType.config.inputs) {
    for (const input of currentGroupType.config.inputs) {
      if (input.type === "date") {
        lodash.set(
          preparedObject,
          input.key,
          lodash.get(preparedObject, input.key).toString()
        );
      }
    }
  }
  return preparedObject;
}

const CommittePage = (props) => {
  let { columns, getLayout, user, getFilterData, filterData, groups } = props;
  const [groupData, setGroupData] = useState([]);
  const [groupTypes, setGroupTypes] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [currentTab, setCurrentTab] = useState(null);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [selectedGroupType, setSelectedGroupType] = useState(null);
  const [groupName, setGroupName] = useState("Enter Committe Name");
  const [groupValues, setGroupValues] = useState({});
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const fetchGroups = async (gotoPage) => {
    if (groupTypes?.find((group) => group.name === currentTab)) {
      const response = await client.post("generic/find", {
        include: [
          {
            association: "groupType",
            where: {
              id: groupTypes?.find((group) => group.name === currentTab).id,
            },
          },
          {
            association: "submissions",
          },
        ],
        model: "SubmissionGroup",
        limit,
        skip: parseInt(gotoPage * limit) || 0,
        order: [["createdAt", "DESC"]],
      });

      setTotalRowsCount(response.data.count);
      setLayoutData(
        response.data.rows.map((group) => {
          return { ...group, submissionCount: group.submissions.length };
        })
      );
    }
  };

  useEffect(() => {
    getLayout({ table: "groupPage" });
    getFilterData({ table: "groupPage" }).catch((err) => console.error(err));
    const fetchGroupTypes = async () => {
      const response = await client.post("generic/find", {
        include: [
          {
            association: "roles",
            through: { attributes: [] },
            where: { name: user.roles.map((role) => role.name) },
          },
        ],
        model: "GroupType",
      });

      setGroupTypes(response.data.rows);
      if (response.data.rows.length) {
        setCurrentTab(response.data.rows[0].name);
        setSelectedGroupType(response.data.rows[0].name);
      }
      setLoadingData(false);
    };
    fetchGroupTypes();
  }, []);

  useEffect(() => {
    if (groupTypes.length) {
      setPage(0);
      fetchGroups(0);
    }
  }, [groupTypes, currentTab]);

  useEffect(() => {
    const currentGroupType = groupTypes.find(
      (groupType) => groupType.name === selectedGroupType
    );
    const newData = {};
    if (currentGroupType && currentGroupType.config.inputs) {
      for (const input of currentGroupType.config.inputs) {
        if (input.type === "date")
          lodash.set(newData, input.key, new Date().toString());
      }
    }
    setGroupValues(newData);
  }, [selectedGroupType]);

  useEffect(() => {
    if (groupTypes.length) fetchGroups(page);
  }, [page]);

  const toggleModal = () => {
    setShouldOpenModal(!shouldOpenModal);
  };
  const onSelectGroupType = (ev, th) => {
    setSelectedGroupType(ev.target.value);
  };
  const onGroupNameChange = (ev) => {
    setGroupName(ev.target.value);
  };
  const createNewGroup = async () => {
    try {
      const selectedGroup = groupTypes.find(
        (groupType) => groupType.name === selectedGroupType
      );
      const response = await client.post("submissionGroup/createCommitte", {
        name: groupName,
        group_type_id: selectedGroup.id,
        start_date: groupValues.start_date ? groupValues.start_date : null,
        values: prepareGroupValuesForBackend(groupValues, selectedGroup),
      });
      toggleModal();
      Swal.fire({
        title: "Committe Created",
        text: "Your Committee has been succesfully created",
        icon: "success",
        confirmButtonColor: "#0066cc",
      });
      if (
        selectedGroup.id ===
        groupTypes?.find((group) => group.name === currentTab).id
      )
        fetchGroups(0);
    } catch (error) {}
  };
  const renderInputs = () => {
    const currentGroupType = groupTypes.find(
      (groupType) => groupType.name === selectedGroupType
    );
    const renderedInputs = [];
    if (currentGroupType && currentGroupType.config.inputs) {
      for (const input of currentGroupType.config.inputs) {
        if (input.type === "date") {
          renderedInputs.push(
            <Grid xs={6} item container className="mt-3" key={input.key}>
              <Grid item xs={6}>
                <span>{input.label}:</span>
              </Grid>
              <Grid item xs={6} className="align-items-center d-flex">
                <DatePicker
                  autoOk
                  openTo="date"
                  key={input.key}
                  value={
                    lodash.get(groupValues, input.key)?.toString() ||
                    new Date().toString()
                  }
                  onChange={(val) => {
                    setGroupValues(
                      lodash.set(
                        lodash.clone(groupValues),
                        input.key,
                        new Date(val)
                      )
                    );
                  }}
                />
              </Grid>
            </Grid>
          );
        }
      }
    }
    return renderedInputs;
  };

  let renderCreateButton = false;
  for (const groupType of groupTypes) {
    for (const role of user.roles) {
      if (groupType.config.rolesAllowedToCreate.includes(role.name)) {
        renderCreateButton = true;
        break;
      }
    }
  }
  useEffect(() => {
    groups &&
      setLayoutData(
        groups.map((group) => {
          return { ...group, submissionCount: group?.submissions?.length };
        })
      );
  }, [groups]);
  useEffect(() => {
    setFilterColumns(filterData);
  }, [filterData]);
  if (loadingData) return <SyncLoader />;

  return (
    <Grid container item xs={12} md={12}>
      {renderCreateButton && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            marginRight: "10px",
            marginTop: "10px",
          }}
        >
          <Button className="btn btn-primary" onClick={toggleModal}>
            Create Committee
          </Button>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <TableTabs
            setFilters={setFilters}
            setSort={doNothing}
            setCurrentTab={setCurrentTab}
            setLayoutData={doNothing}
            currentTab={currentTab}
            menus={groupTypes.map((groupType, index) => {
              return { name: groupType.name, id: groupType.name };
            })}
          />
          <div
            className={clsx("tab-item-wrapper", {
              active: true,
            })}
            style={{ overflow: "hidden", borderRadius: "0 20px 0 0" }}
            index={0}
          >
            <TableView
              className="mt-4"
              layoutStructure={columns}
              layoutData={layoutData}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              tasksCount={totalRowsCount}
              currentTab={currentTab}
              page={page}
              groupIngTab={true}
              filterColumns={filterColumns}
              model={"groupPage"}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        classes={{ paper: "modal-content" }}
        fullWidth
        maxWidth="lg"
        open={shouldOpenModal}
        onClose={() => setShouldOpenModal(false)}
        aria-labelledby="form-dialog-title2"
        style={{
          borderRadius: "6px",
          width: "90%",
          margin: "auto",
          height: "120%",
        }}
      >
        <DialogTitle className="bg-color-darkBlue">
          <span className="titleModal text-white">Create New Committe</span>
        </DialogTitle>
        <DialogContent>
          <Grid container className="p-5">
            <Grid item xs={5}>
              <FormControl variant="outlined" className="w-100">
                <InputLabel
                  id="group-category-select-outlined-label"
                  style={{ color: "#BD9049" }}
                >
                  Committe Category
                </InputLabel>
                <Select
                  labelId="group-category-select-outlined-label"
                  id="group-category-select-outlined"
                  value={selectedGroupType}
                  onChange={onSelectGroupType}
                  label="Committe Category"
                >
                  {groupTypes.map((groupType) => (
                    <MenuItem value={groupType.name} key={groupType.name}>
                      {groupType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6}>
              <TextField
                label="Committe Name"
                variant="outlined"
                defaultValue={groupName}
                onChange={onGroupNameChange}
                className="w-100"
                InputLabelProps={{ style: { color: "#BD9049" } }}
              />
            </Grid>
            {renderInputs()}
            <Grid item xs={12} className="justify-content-end d-flex mt-4">
              <Button
                color="primary"
                variant="outlined"
                style={{ width: "120px", fontWeight: "bold" }}
                onClick={toggleModal}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary ml-3"
                style={{ width: "120px" }}
                onClick={createNewGroup}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    columns: state.layout.columns,
    user: state.auth.user,
    filterData: state.filterData.filterData,
    groups: state.list?.tasks,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(fetchWorklistLayout(params)),
    getFilterData: (params) => dispatch(fetchFilterData(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommittePage);
