import {
  Card,
  Grid,
  Button,
  ListItem,
  List,
  Checkbox,
  ListItemText,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import "./style.css";
import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { handleError } from "api/utilities";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { getValue } from "utils/GetObjectValues";
import {
  parseWorkingDays,
  formatWorkingDays,
  validateWorkingDays,
} from "./utils";

const TimeSlot = ({ productType }) => {
  let [initialValues, setInitialValues] = useState({});
  let [weekDays, setWeekDays] = useState([]);
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      workingDays: Yup.mixed().required(),
      slotDuration: Yup.number().required().notOneOf([0]),
    }),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const errs = validateWorkingDays(values.workingDays, values.slotDuration);
      if (_.isEmpty(errs)) submitWorkingDays();
      else formik.setErrors(errs);
    },
  });
  let getWorkingDays = async () => {
    let result = await client.get("timeslots/getworkingdays", {
      params: {
        productType,
      },
    });

    let { workingDays } = result.data;
    workingDays = parseWorkingDays(workingDays);
    setWeekDays(workingDays);
    let slotDuration = workingDays[0].duration || 0;
    return { workingDays, slotDuration };
  };

  useEffect(() => {
    const setFormikValues = () => {
      getWorkingDays().then((result) => {
        formik.setFieldValue("workingDays", result?.workingDays);
        formik.setFieldValue("slotDuration", result?.slotDuration);
      });
    };
    setFormikValues();
  }, [productType]);
  useEffect(() => {
    const setFormikValues = () => {
      getWorkingDays().then((result) => setInitialValues(result));
    };
    setFormikValues();
  }, []);
  const submitWorkingDays = () => {
    Swal.fire({
      title: "Are you sure you want to set submission hours?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(function (result) {
      if (result.isConfirmed) {
        let values = _.cloneDeep(formik.values.workingDays).filter(function (
          element
        ) {
          return element !== undefined;
        });
        values = formatWorkingDays(values);
        values = values.map((workingDay) => {
          workingDay.duration = formik.values.slotDuration;
          workingDay.product_type = productType;
          return workingDay;
        });
        client
          .post("timeslots/saveWorkingDays", {
            timelineSlots: values,
          })
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: "Settings submitted successfully",
              icon: "success",
              timer: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            handleError(error);
          });
      }
    });
  };
  const handleChange = (dayRow, index, value) => {
    if (value) {
      formik.setFieldValue(`workingDays.${index}.day`, dayRow.day);
    } else {
      let values = formik.values.workingDays.map(
        (workingDay, workingDayIndex) => {
          if (index == workingDayIndex) {
            return { ...workingDay, checked: value };
          } else {
            return workingDay;
          }
        }
      );
      formik.setFieldValue("workingDays", values);
    }
  };
  return (
    <Card className="card-border">
      <FormikProvider value={formik}>
        <div className="table-container mt-3">
          <h6 className="mb-4 ml-3 mt-3">
            <b>
              <AccessTimeIcon /> Create Time Slot Availability
            </b>
          </h6>
          <Grid container className="ml-3 mb-3">
            <Grid item md={3}>
              <h6 className="label-font">Select work days:</h6>
            </Grid>
            <Grid item md={7}>
              <h6 className="label-font">Select working hours:</h6>
            </Grid>
            <Grid item md={12}>
              <List className="pt-1 ml-2">
                {weekDays.map((dayRow, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item md={12}>
                        <ListItem className="pl-0 pt-0">
                          <Grid item container md={3}>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              className="p-0"
                              name={`workingDays.${index}.checked`}
                              onChange={(ev, value) => {
                                formik.handleChange(ev, value);
                                handleChange(dayRow, index, value);
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                              }}
                              checked={
                                eval(
                                  getValue(
                                    formik.values,
                                    `workingDays.${index}.checked`
                                  )
                                ) || false
                              }
                              style={{
                                color: "#181E4B",
                                transform: "scale(.7)",
                              }}
                            />
                            <ListItemText
                              primary={
                                <b
                                  className={
                                    eval(
                                      getValue(
                                        formik.values,
                                        `workingDays.${index}.checked`
                                      )
                                    )
                                      ? "checked"
                                      : "unChecked"
                                  }
                                  style={{ fontSize: "18px" }}
                                >
                                  {dayRow.day}
                                </b>
                              }
                            />
                          </Grid>
                          <Grid item container md={8}>
                            <Grid item md={5}>
                              <span htmlFor="from" className="mr-2 span-font">
                                From
                              </span>
                              <KeyboardTimePicker
                                autoOk
                                placeholder="09:00 AM"
                                value={
                                  getValue(
                                    formik.values,
                                    `workingDays.${index}.startTime`
                                  ) || new Date()
                                }
                                variant="inline"
                                onChange={(date) => {
                                  formik.setFieldValue(
                                    `workingDays.${index}.startTime`,
                                    date
                                  );
                                }}
                              />
                              <div className="text-danger">
                                {getValue(
                                  formik.errors,
                                  `workingDays.${index}.startTime`
                                )}
                              </div>
                            </Grid>

                            <Grid item md={5}>
                              <span className="mr-2 span-font" htmlFor="to">
                                to
                              </span>
                              <KeyboardTimePicker
                                autoOk
                                placeholder="05:00 PM"
                                value={
                                  getValue(
                                    formik.values,
                                    `workingDays.${index}.endTime`
                                  ) || new Date()
                                }
                                variant="inline"
                                onChange={(date) => {
                                  formik.setFieldValue(
                                    `workingDays.${index}.endTime`,
                                    date
                                  );
                                }}
                              />
                              {getValue(
                                formik.touched,
                                `workingDays.${index}.endTime`
                              ) && (
                                <div className="text-danger">
                                  {getValue(
                                    formik.errors,
                                    `workingDays.${index}.endTime`
                                  )}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </ListItem>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
            </Grid>
            <Grid item md={3} className="pb-0">
              <h6 className="mb-0 label-font p-2">Slot Duration:</h6>
            </Grid>
            <Grid item md={8}>
              <TextField
                type="number"
                placeholder="mins"
                name="slotDuration"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
                value={getValue(formik.values, "slotDuration") || ""}
                style={{ width: "133px" }}
                size="small"
                className="mb-0"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Time</InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                  },
                }}
              ></TextField>
              {getValue(formik.touched, `slotDuration`) && (
                <div className="text-danger">
                  {getValue(formik.errors, `slotDuration`)}
                </div>
              )}
            </Grid>
            <Grid item md={12} className="mt-3 mb-3">
              <Button
                className="mx-1 px-5 py-2"
                size="large"
                color="primary"
                variant="contained"
                style={{ borderRadius: "5px" }}
                disabled={
                  formik?.values?.workingDays?.filter(
                    (element) => element.checked !== false
                  ).length === 0
                }
                type="submit"
                onClick={(e) => {
                  formik.handleSubmit(e);
                }}
              >
                <span className="btn-wrapper--label ml-2 font-size">
                  Submit
                </span>
              </Button>
            </Grid>
          </Grid>
        </div>
      </FormikProvider>
    </Card>
  );
};

export default TimeSlot;
