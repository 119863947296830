import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../style.scss";
import { setAllAttachments, setAttachmentReviewed } from "reducers/details";
import _ from "lodash";
import SimpleAccordion from "./Accordion/index";
const documentsConfig = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/documents`);
const Archive = (props) => {
  let {
    attachmentsUnderReview,
    setAttachmentReviewed,
    allAttachments,
    setAllAttachments,
    selectedTab,
    sectionId,
    initialValues,
    setInitialValues,
    schemaInputs,
    setSchemaInputs,
    formik,
    task,
  } = props;
  const [attachments, setAttachments] = useState([]);
  const [doctorAttachments, setDoctorAttachments] = useState({});

  useEffect(() => {
    if (allAttachments) {
      let clonedAttachments = _.cloneDeep(task.submission.doctor_submissions);
      const groupArrayObject = clonedAttachments.reduce(
        (group, arr) => {
          const { attachments, doctor,id:dr_submission_id } = arr;

          group[doctor.name] = group[doctor.name] ?? [];

          group[doctor.name].push(...attachments);
          if(attachments.length) attachments[0].dr_submission_id = dr_submission_id

          return group;
        },

        {}
      );
      setDoctorAttachments(groupArrayObject);

      //   setNotification(filteredAttachments.length ? false : true);
      setAttachments(clonedAttachments || []);
    }
  }, [selectedTab, task, allAttachments]);

  return (
    <>
      <React.Fragment key={selectedTab.id}>
        <div>
          <SimpleAccordion
            doctorAttachments={doctorAttachments}
            selectedTab={selectedTab}
            task={task}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            schemaInputs={schemaInputs}
            setSchemaInputs={setSchemaInputs}
            formik={formik}
            userPermission={selectedTab.userPermission}
          />
        </div>
      </React.Fragment>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    allAttachments: state.details.allAttachments,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllAttachments: (attachments) =>
      dispatch(setAllAttachments(attachments)),
    setAttachmentReviewed: (attachments) =>
      dispatch(setAttachmentReviewed(attachments)),
  };
};

export default connect(mapStateToProps)(Archive);
