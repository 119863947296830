import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import _ from "lodash";
import {
  fetchWorklist,
  fetchWorklistLayout,
  fetchFilterData,
} from "reducers/list";
import { connect } from "react-redux";
import TableWithTabsAndFilters from "components/TableWithTabsAndFilters";
const menuObj = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/drafts/tabs`).default;

const getDefaultTab = (user) => {
  return menuObj[user.roles[0].name][0].id;
};
function Drafts(props) {
  let {
    getWorklist,
    tasks,
    tasksTotalCount,
    getLayout,
    columns,
    user,
    getFilterData,
    filterData,
  } = props;
  const [layoutStructure, setLayoutStructure] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const [filterColumns, setFilterColumns] = useState([]);
  const [menus, setMenus] = useState(menuObj.shared);
  const [currentTab, setCurrentTab] = useState(getDefaultTab(user));
  useEffect(() => {
    getLayout({ table: "drafts" }).catch((err) => {});
    getFilterData({ table: "drafts" }).catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchCounts = async () => {
      let extraTabs = [];
      user.roles.forEach((role) => {
        menuObj[role.name].forEach((obj) => {
          if (!extraTabs.some((tab) => tab.id == obj.id)) {
            extraTabs.push(obj);
          }
        });
      });

      const newMenus = [...menus, ...extraTabs];
      for (const menu of newMenus) {
        if (isMounted) {
          try {
            const res = await client.get("tasks/find", {
              params: {
                page: 0,
                limit: 1,
                currentTab: menu.id,
              },
            });
            menu.badge = res.data.count;
          } catch (error) {
            console.log(error);
            menu.badge = 0;
          }
        }
      }
      if (!_.isEmpty(user)) {
        setMenus(newMenus);
        setCurrentTab(newMenus[0].id);
      }
    };
    fetchCounts().catch((err) => {});
    return () => {
      isMounted = false;
    };
  }, [user]);

  useEffect(() => {
    setLayoutStructure(columns);
    setLayoutData(tasks);
    setFilterColumns(filterData);
  }, [tasks, columns, filterData]);

  return (
    <TableWithTabsAndFilters
      menus={menus}
      totalCount={tasksTotalCount}
      layoutStructure={layoutStructure}
      setLayoutStructure={setLayoutStructure}
      layoutData={layoutData}
      setLayoutData={setLayoutData}
      filterColumns={filterColumns}
      getTableData={getWorklist}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      redirectKey={"id"}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    tasks: state.list?.tasks,
    tasksTotalCount: state.list?.tasksCount,
    user: state.auth.user,
    currentUser: state.auth.user,
    columns: state.layout.columns,
    filterData: state.filterData.filterData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (params) => dispatch(fetchWorklist(params)),
    getLayout: (params) => dispatch(fetchWorklistLayout(params)),
    getFilterData: (params) => dispatch(fetchFilterData(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Drafts);
