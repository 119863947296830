/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import bioInLogo from "assets/images/bio-inn.png";
import edaLogo from "assets/images/Image 1.png";
import "./index.scss";
import config from "../index.js";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
const Release = ({
  drug,
  letter,
  setDivRef,
  values,
  setValues,
  formValues,
  form,
}) => {
  const prevCertificateNoRef = useRef();

  useEffect(() => {
    prevCertificateNoRef.current = {
      certificate_number: formValues.certificate_number,
      manufacturer_address: formValues.manufacturer_address,
      storage_condition: formValues.storage_condition,
    };
  });
  // useEffect(() => {
  //   setValues(config(letter, formValues, "letters"));
  // }, []);
  const prevNo = prevCertificateNoRef.current;
  useEffect(() => {
    const {
      certificate_number,
      manufacturer_address,
      storage_condition,
      ...rest
    } = formValues;
    if (
      _.isEqual(prevNo, {
        certificate_number,
        manufacturer_address,
        storage_condition,
      })
    ) {
      setValues({
        ...config(letter, rest, "letters"),
      });
    }
  }, [formValues]);

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };

  const [imageSources, setImageSources] = useState({});

  function toDataURL(sources) {
    Object.entries(sources).forEach(([key, src]) => {
      let image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        setImageSources((oldState) => ({ ...oldState, [key]: dataURL }));
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src =
          "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }
    });
  }

  useEffect(() => {
    toDataURL({ edaLogo, bioInLogo });
  }, []);
  useEffect(() => {
    const release = document.getElementById("release");
    setDivRef((divRefs) => ({ ...divRefs, release }));
    values.tableInputs?.map(
      (input) =>
        values[input.name] &&
        input.isRequired &&
        form.setFieldValue(input.name, values[input.name])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, formValues]);
  return (
    <div
      style={{
        padding: "20px",
        zoom: 0.88,
      }}
      id="release"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            CA of Biological and Innovative products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            and Clinical Studies
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            GA of Biological Product
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Lot Release Administration
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          {Object.values(imageSources)?.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              style={{ width: "150px", height: "100px" }}
              alt="bio-inn"
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هـيـئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الحيوية والمبتكرة
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            والدراسات الإكلينيكية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات الحيوية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            إدارة الافراج عن التشغيلات
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <h2>{values.header} </h2>
      <div style={{ width: "90%" }}>
        <p style={{ marginLeft: "10px" }}>
          <b>The following Biological Product:</b> {drug.name || null}
        </p>
        <div style={{ marginLeft: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span style={{ textAlign: "left", marginLeft: "10px" }}>
              <b>Produced by:</b> {letter?.org?.name || null}
            </span>
            <div
              style={{
                textAlign: "left",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <label htmlFor="in" style={{ display: "inline-block" }}>
                In:{" "}
              </label>
              {letter?.drugs?.CountryOfOrigin || (
                <input
                  type="text"
                  style={{
                    border: "none",
                    display: "inline-block",
                    height: "fit-content",
                  }}
                  name="in"
                  value={values.in}
                  placeholder="................."
                  onChange={handleChange}
                  // onBlur={form.handleBlur}
                />
              )}
            </div>
          </div>
          <p>
            This Product is manufactured in accordance with requirements
            htmlFor=" Good manufacturing practices htmlFor=" Biological
            substances
          </p>
        </div>
      </div>
      <div>
        <div>
          <input
            key={Math.random()}
            type="checkbox"
            name="registered"
            checked={values.registered}
            onChange={handleChange}
          />

          <label htmlFor="scales">
            Registared product ,licensing file No. (EGY/JUN/2010/0005/01)
          </label>
        </div>

        <div>
          <input
            key={Math.random()}
            type="checkbox"
            name="notRegistered"
            checked={values.notRegistered}
            onChange={handleChange}
          />
          <label htmlFor="horns">Non Registared product</label>
        </div>

        <div>
          <input
            key={Math.random()}
            type="checkbox"
            name="eau"
            checked={values.eau}
            onChange={handleChange}
          />
          <label htmlFor="horns">EAU</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table
              style={{
                textAlign: "left",
                marginTop: "15px",
              }}
            >
              <tbody>
                {values.tableInputs?.map((input) => (
                  <tr key={input.name}>
                    <td className="table-border">
                      <b>{input.title}</b>
                    </td>
                    <td className="table-border">
                      {input.value ? (
                        <>{input.value}</>
                      ) : (
                        <>
                          <input
                            type="text"
                            style={{ border: "none" }}
                            name={input.name}
                            value={
                              formValues[input.name] ||
                              letter.drugs[input.name] ||
                              values[input.name]
                            }
                            placeholder="................."
                            onChange={(e) => {
                              handleChange(e);
                              input.isRequired && form.handleChange(e);
                            }}
                            onBlur={(e) =>
                              input.isRequired && form.handleBlur(e)
                            }
                          />
                          {form.errors[input.name] ? (
                            <div className="text-danger">
                              {form.errors[input.name]}
                            </div>
                          ) : null}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p
              style={{ textAlign: "left", fontFamily: ["Arial", "sans-serif"] }}
            >
              This examination is based on the review of the relevant documents,
              the appropriate control ‎laboratory tests performed by the
              responsible lab on the received samples from the Biological
              ‎product Inspection department at the General administration of
              pharmaceutical inspection - Egyptian ‎Drug Authority, and as
              indicated in the marketing authorization application.
            </p>
            <p>
              <textarea
                style={{
                  width: "100%",
                  fontFamily: ["Arial", "sans-serif"],
                  fontSize: "9.6 px",
                }}
                rows={7}
                value={values?.descriptionText}
                name="descriptionText"
                autoCorrect="off"
                onChange={handleChange}
              />
            </p>
            {/* <div style={{ textAlign: "center" }}> */}
            {/* FIXME: */}
            Certificate Number :
            {letter.values.certificate_number ? (
              <span>{letter.values.certificate_number}</span>
            ) : (
              <>
                <b>
                  <input
                    type="text"
                    name="certificate_number"
                    value={formValues.certificate_number}
                    onChange={(e) => {
                      form.handleChange(e);
                    }}
                    onBlur={form.handleBlur}
                  />
                </b>
                {form.touched["certificate_number"] &&
                form.errors["certificate_number"] ? (
                  <div className="text-danger">
                    {form.errors["certificate_number"]}
                  </div>
                ) : null}
              </>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>

      {/* <footer dir="rtl" className="text-right">
        <dl dir="rtl">
          <dt>:العنوان</dt>
          <dd>51 شارع وزارة الزراعة-العجوزة-الجيزة</dd>
          <dt>:البريد الاليكترونى</dt>
          <dd>
            المكتب الفنى
            <a dir="ltr" href="http://bio.tech@edaegypt.gov.eg">
              bio.tech@edaegypt.gov.eg
            </a>
            المكتب الادارى
            <a dir="ltr" href="http://bio.admin@edaegypt.gov.eg">
              bio.admin@edaegypt.gov.eg
            </a>
          </dd>

          <dt>:التيليفون</dt>
          <dd>0237484988</dd>
        </dl>
      </footer> */}
    </div>
  );
};
export default Release;
