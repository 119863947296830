import EventsService from "utils/FormUtils/Events/EventsService";
import _, { forEach } from "lodash";

export const excuteEvents = async (input, event, attributes, inputKey) => {
  let result;
  for (const eventFunction of event.functions) {
    if (result) {
      result = await EventsService[eventFunction](
        { [eventFunction]: attributes[eventFunction], ...attributes },
        input,
        event,
        result,
        attributes.formik,
        inputKey
      );
    } else {
      result = await EventsService[eventFunction](
        { [eventFunction]: attributes[eventFunction], ...attributes },
        input,
        event,
        {},
        attributes.formik,
        inputKey
      );
    }
  }
  return result;
};
export const getInitialEvents = async (input, formik) => {
  let event = input?.events?.find((event) => event.trigger == "onInit");
  const newEvent = _.cloneDeep(event);

  if (newEvent) {
    newEvent.attributes.formik = formik;
    return await excuteEvents(input, newEvent, newEvent.attributes);
  }
};
export const getLoadMoreEvents = async (input) => {
  let event = input?.events?.find((event) => event.trigger == "onLoadMore");
  if (event) {
    return await excuteEvents(input, event, event.attributes);
  }
};

export const getOnchangeEvents = async (input, formik, value, inputKey) => {
  let events = input?.events?.filter((event) => event.trigger === "onChange");
  events.map(async (event) => {
    let newEvent = _.cloneDeep(event);
    if (newEvent) {
      newEvent.attributes.formik = formik;
      newEvent.attributes.value = value;
      return await excuteEvents(input, newEvent, newEvent.attributes, inputKey);
    }
  });
};
