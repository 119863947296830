import React, { useEffect, useState } from "react";
import {
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Paper,
  Grid,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import client from "api/apiAuth/guestClient";
import InputWidget from "components/FormSteps/InputWidget";
import ErrorMessage from "../../components/ErrorMessage";
import { setSchema } from "../../utils/setValidationSchema";
import Swal from "sweetalert2";
import SpinnerLoader from "components/SpinnerLoader";

// const getValue = (obj, path, inputType) => {
//   if (path) {
//     path = path.replace(/\[(\w+)\]/g, "$1"); // convert indexepaths to propertis
//     path = path.replace(/^\./, ""); // strip a leading dot
//     let value = path.split(".").reduce((o, i) => {
//       if (o) {
//         if (o[i] === true || o[i] === false) return o[i].toString();
//         if (inputType === "date") {
//           return o[i] && typeof o[i] !== "object"
//             ? new Date(o[i]).toLocaleDateString("en-CA")
//             : o[i];
//         }
//         return o[i];
//       }
//       return null;
//     }, obj);
//     return value;
//   }
// };

const data = {
  drug: [
    {
      type: "Generic",
      name: "s",
      similarity: "80%",
      acceptance: "accepted",
    },
    {
      name: "sasa",
      similarity: "80%",
      acceptance: "reject",
      type: "Generic",
    },
    {
      type: "Generic",
    },
    {
      type: "Trade Name",
    },
    {
      type: "Generic",
    },
  ],
};

yup.addMethod(yup.array, "growing", function (message) {
  return this.test("growing", message, function (values) {
    const len = values.length;
    for (let i = 0; i < len; i++) {
      if (i === 0) continue;
      if (values.map((value) => value.name).includes(values[i].name)) {
        return this.createError({
          path: `drug[${i}].name`,
          message: "Should be not equal previous name",
        });
      }
    }
    return true;
  });
});

yup.addMethod(yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const shc = yup.object().shape({
  drug: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Required"),
      })
    )
    .unique("unique name", (a) => a.name),
  // .growing(),
});

export default function TableRule({ match }) {
  const [validationSchema, setvalidationSchema] = useState({});
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState();
  const [enableOnSubmit, setEnableOnSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [task, setTask] = useState({});
  const [tableInputs, setTableInputs] = useState([]);
  const [commentInputs, setCommentInputs] = useState();
  const [additionInputs, setAdditionInputs] = useState([]);
  const [outTableInputs, setOutTableInputs] = useState([]);
  const [mainObj, setMainObj] = useState();
  const [sectionNameFlags, setSectionNameFlags] = useState({});
  const [rowCount, setRowCount] = useState(1);
  const [fileCode, setFileCode] = useState(null);
  useEffect(() => {
    async function retreiveSubmissionData() {
      const response = await client.get("/tasks/taskDetails", {
        params: {
          submission_id: match.params.submissionId,
          task_status: "Pending",
        },
      });
      setTask(response.data.task);
      setMainObj(
        response.data.task.step.groups[0].rules[0].attributes.mainObject
      );
      setTableInputs(
        response.data.task.step.sections[0].inputs.filter(
          (input) =>
            !input.key.includes("comment") &&
            !response.data.task.step.sections[0].additionInputs.includes(
              input.id
            ) &&
            !response.data.task.step.sections[0].outTableInputs.includes(
              input.id
            )
        )
      );
      setCommentInputs(
        response.data.task.step.sections[0].inputs.filter((input) =>
          input.key.includes("comment")
        )
      );

      setAdditionInputs(
        response.data.task.step.sections[0].inputs.filter((input) =>
          response.data.task.step.sections[0].additionInputs.includes(input.id)
        )
      );
      setOutTableInputs(
        response.data.task.step.sections[0].inputs.filter((input) =>
          response.data.task.step.sections[0].outTableInputs.includes(input.id)
        )
      );
      setMinLimit(response.data.task.step.sections[0].min);
      setRowCount(response.data.task.step.sections[0].min);
      setMaxLimit(
        response.data.task.step.groups[0].rules[0].attributes.validation.max
      );
      setvalidationSchema(
        setSchema(response.data.task.step.sections[0].inputs)
      );
      setFileCode(response.data.task.submission.file_code);
    }
    retreiveSubmissionData();
  }, []);

  // task.submission.step.sections[0]."drug"
  const formik = useFormik({
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    validationSchema: yup.object().shape(validationSchema),
    // validationSchema: shc,
    initialValues: task?.submission?.values?.[mainObj]
      ? {
          [mainObj]: task.submission.values[mainObj],
        }
      : {},

    onSubmit: () => {
      handleSubmitTask();
    },
  });

  const rows = [];
  for (let i = 0; i < rowCount; i++) {
    rows.push(
      <>
        <TableRow key={i}>
          {tableInputs.map((input, index) => (
            <TableCell key={input.key}>
              <InputWidget
                formik={formik}
                input={{
                  ...input,
                  key: input.key.replace("[i]", `${i}`),
                }}
                enableOnSubmit={enableOnSubmit}
                setSectionNameFlags={setSectionNameFlags}
                uniqueNo={fileCode}
              />
            </TableCell>
          ))}
        </TableRow>

        {commentInputs && (
          <TableRow>
            {commentInputs.map((input) => {
              return (
                <TableCell
                  colSpan={Math.floor(
                    tableInputs.length / commentInputs.length
                  )}
                  key={`${input.id - i}`}
                >
                  {input.name}
                  <InputWidget
                    formik={formik}
                    input={{
                      ...input,
                      key: input.key.replace("[i]", `${i}`),
                    }}
                    enableOnSubmit={enableOnSubmit}
                    setSectionNameFlags={setSectionNameFlags}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        )}
      </>
    );
  }

  const handleSubmitTask = async () => {
    const busyRows = formik.values[mainObj].filter((row) => {
      return row != undefined;
    });

    try {
      if (busyRows.length <= maxLimit && busyRows.length >= minLimit) {
        await client.post("submission/saveAllDrugsNames", {
          submissionId: task.submission.id,
          [mainObj]: formik.values[mainObj],
        });
        let rules = [];
        task.step.groups.forEach((group) => {
          rules.push(group.rules);
        });
        rules = rules.flat();
        await client.post("submission/updateSubmissionValues", {
          submission_id: task.submission.id,
          section_id: task.step.sections[0].id,
          rules: rules,
          task_id: task.id,
          submission_edit: formik.values,
          submitFlag: true,
        });

        window.location.pathname = `details/${task.submission.id}`;
      } else {
        Swal.fire({
          title: `min limit is ${minLimit} and max is ${maxLimit}`,
          // text: 'لقد تم إعادة التعيين بنجاح ',
          icon: "error",
          timer: 2000,
        });
      }
    } catch (error) {
      // Swal.fire({
      //   title: `${error.response.date.error}`,
      //   // text: 'لقد تم إعادة التعيين بنجاح ',
      //   icon: "error",
      //   timer: 2000,
      // });
    }
  };
  const handleModalClose = async () => {
    setModalOpen(false);
  };

  const handleSub = (e) => {
    setEnableOnSubmit(true);
    formik.handleSubmit(e);
  };

  if (!task) return <SpinnerLoader />;

  return (
    <>
      {additionInputs.length || outTableInputs.length ? (
        <Grid container justifyContent="center" style={{ marginBottom: 10 }}>
          <Grid container item xs={10} md={9} spacing={2}>
            {additionInputs.length
              ? additionInputs.map((input) => {
                  return (
                    <InputWidget
                      formik={formik}
                      input={{
                        ...input,
                        key: input.key,
                      }}
                      enableOnSubmit={enableOnSubmit}
                      setSectionNameFlags={setSectionNameFlags}
                    />
                  );
                })
              : null}
            {outTableInputs.length
              ? outTableInputs.map((input) => {
                  return (
                    <InputWidget
                      formik={formik}
                      input={{
                        ...input,
                        key: input.key,
                      }}
                      enableOnSubmit={enableOnSubmit}
                      setSectionNameFlags={setSectionNameFlags}
                      uniqueNo={fileCode}
                    />
                  );
                })
              : null}
          </Grid>
        </Grid>
      ) : null}
      <TableContainer
        component={Paper}
        style={{ maxWidth: "95%", margin: "0 auto" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableInputs.map((input) => (
                <TableCell key={input.key}>{input.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableInputs.length &&
              formik.errors["drug"] &&
              typeof formik.errors["drug"] == "string" && (
                <TableRow
                  style={{
                    backgroundColor: "#af0707",
                  }}
                >
                  <TableCell
                    style={{ color: "white", textAlign: "center" }}
                    colSpan={tableInputs.length}
                  >
                    you have to fill inputs
                  </TableCell>
                </TableRow>
              )}
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid spacing={4} style={{ textAlign: "center", padding: 10 }}>
        <Button
          className="btn-transition-none btn-lg"
          variant="contained"
          onClick={() => {
            rowCount < maxLimit && setRowCount(rowCount + 1);
          }}
          color="secondary"
        >
          ADD Row
        </Button>

        <Button
          className="btn-transition-none btn-lg"
          variant="contained"
          onClick={() => {
            rowCount > minLimit && setRowCount(rowCount - 1);
          }}
          color="secondary"
          style={{ margin: "0 5px" }}
        >
          Remove Row
        </Button>
        <Button
          className="btn-transition-none btn-lg"
          variant="contained"
          onClick={(e) => {
            Object.keys(task).length && "blockingData" in task.step
              ? setModalOpen(true)
              : handleSub(e);
          }}
          color="primary"
        >
          Submit
        </Button>
      </Grid>

      {Object.keys(task).length && "blockingData" in task.step ? (
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please, ensure correctness of suggested names list and voice
              recordings before submitting. You have{" "}
              <strong>
                {task.step.blockingData.blockingCounter -
                  task.submission.values.blockingCount}
              </strong>{" "}
              tries left.
              <br />
              <br />
              {task.submission.values.blockingCount >=
                task.step.blockingData.blockingCounter &&
                "You have exceeded maximum number of free trials. Please upload extra fees receipt code."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="primary">
              Go Back
            </Button>
            <Button
              onClick={(e) => {
                setEnableOnSubmit(true);
                formik.handleSubmit(e);
              }}
              color="primary"
              disabled={
                task.submission.values.blockingCount >=
                task.step.blockingData.blockingCounter
              }
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}
