import React, { useState, useEffect } from "react";

import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import InputWidget from "components/FormSteps/InputWidget";
import { useFormik } from "formik";
import * as Yup from "yup";
import client from "api/apiAuth/guestClient";
import { enableSubmitButton } from "reducers/form/formActions";
import Swal from "sweetalert2";

const getSteps = async (setSteps) => {
  let { data } = await client.get("/queue/getsteps");
  setSteps(data.steps);
};
const getUsers = async (setAssignees) => {
  let { data } = await client.get("/queue/getusers");
  setAssignees(data.assignees);
};

function CreateQueue({ open, setOpen, enableSubmitButton, values, isEdit }) {
  let [steps, setSteps] = useState([]);
  let [assignees, setAssignees] = useState([]);
  let [initialValues, setInitialValues] = useState({});
  const handleSubmitTask = async (values) => {
    try {
      if (isEdit) {
        await client.post("queue/update", {
          queueId: initialValues.id,
          values: {
            criteria: { submission_limit: values["submission_limit"] },
          },
        });
        setOpen(false);
        Swal.fire({
          title: "Succeeded",
          text: "Queue data updated successfully",
          icon: "success",
          timer: 3000,
        });
      } else {
        await client.post("queue/savequeue", {
          values: {
            ...values,
            criteria: { submission_limit: values["submission_limit"] },
          },
        });
        setOpen(false);
        Swal.fire({
          title: "Succeeded",
          text: "Queue data saved successfully",
          icon: "success",
          timer: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  let [inputs, setInputs] = useState([
    {
      itype: "enum",
      name: "Step",
      required: true,
      key: "step_id",
      input_layout: {
        gridSize: 12,
        type: "selectWithLabel",
        labelStyle: { fontWeight: 700 },
        displayLabel: "true",
      },
      order: 1,
      editable: false,
    },
    {
      itype: "date",
      name: "Start Date",
      required: true,
      key: "start_date",
      input_layout: {
        gridSize: 6,
        type: "date",
        labelStyle: { fontWeight: 700 },
        displayLabel: "true",
      },
      order: 2,
      editable: false,
    },
    {
      itype: "date",
      name: "End Date",
      required: true,
      key: "end_date",
      input_layout: {
        gridSize: 6,
        type: "date",
        labelStyle: { fontWeight: 700 },
        displayLabel: "true",
      },
      order: 3,
      editable: false,
    },
    {
      itype: "number",
      name: "Step Limit",
      required: true,
      key: "submission_limit",
      input_layout: {
        gridSize: 12,
        type: "number",
        labelStyle: { fontWeight: 700 },
        displayLabel: "true",
      },
      order: 4,
      editable: true,
    },
    {
      itype: "enum",
      name: "Queue Admin",
      key: "assignee_id",
      required: true,
      input_layout: {
        gridSize: 12,
        type: "selectWithLabel",
        labelStyle: { fontWeight: 700 },
        displayLabel: "true",
      },
      order: 5,
      editable: false,
    },
  ]);

  useEffect(() => {
    getSteps(setSteps);
    getUsers(setAssignees);
  }, []);
  useEffect(() => {
    setInitialValues(values);
  }, [values]);
  useEffect(() => {
    if (steps.length) {
      setInputs(
        inputs.map((input) => {
          if (input.key === "step_id") {
            input.options = steps;
          }
          return input;
        })
      );
    }
  }, [steps]);
  useEffect(() => {
    if (assignees.length) {
      setInputs(
        inputs.map((input) => {
          if (input.key === "assignee_id") {
            input.options = assignees.map((assignee) => {
              assignee.name = assignee.role;
              delete assignee.role;
              return assignee;
            });
          }
          return input;
        })
      );
    }
  }, [assignees]);
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      step_id: Yup.number().required(),
      start_date: Yup.date().required(),
      end_date: Yup.date().required(),
      submission_limit: Yup.number().required(),
      assignee_id: Yup.number().required(),
    }),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues:
      initialValues && !_.isEmpty(initialValues)
        ? {
            ...initialValues,
            [Object.keys(initialValues.criteria)[0]]:
              initialValues.criteria[Object.keys(initialValues.criteria)[0]],
          }
        : {},
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitTask(values);
    },
  });

  return (
    <>
      <Dialog
        classes={{ paper: "modal-content" }}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title2"
        style={{ borderRadius: "6px", width: "85%", margin: "auto" }}
      >
        <DialogContent className="p-0">
          <div>
            <div className="text-center pb-5 pl-5 pr-5 pb-4 pt-4">
              <div className="card-header d-block bg-white pt-4 pb-4">
                <div className="text-primary text-center h3 mb-5">
                  <p className="modal-title">
                    <strong>Create Queue</strong>
                  </p>
                </div>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid container item md={11} spacing={2}>
                    {steps?.length > 0 &&
                      assignees?.length > 0 &&
                      inputs.every((input) => {
                        if (input.itype == "enum") {
                          if (input.options) return true;
                          else return false;
                        } else {
                          return true;
                        }
                      }) &&
                      inputs.map((input) => {
                        return (
                          <InputWidget
                            formik={formik}
                            input={
                              isEdit
                                ? input.editable
                                  ? input
                                  : { ...input, disabled: true }
                                : input
                            }
                          />
                        );
                      })}
                    <Grid item md={12}>
                      <Button
                        className="mx-1 px-5 py-2"
                        size="large"
                        color="primary"
                        variant="contained"
                        style={{ borderRadius: "5px" }}
                        onClick={(e) => {
                          enableSubmitButton();
                          formik.handleSubmit(e);
                        }}
                        disabled={!(formik.dirty && formik.isValid)}
                      >
                        <span className="btn-wrapper--label ml-2 font-size">
                          {isEdit ? "Update" : "Submit"}
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    attachmentsUnderReview: state.details.attachmentsUnderReview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    enableSubmitButton: () => dispatch(enableSubmitButton()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateQueue);
