import _ from "lodash";
import { setEventsInputs } from "reducers/form/formActions";
import { setAvailableSubmissionsCount } from "reducers/settings/settingsActions";
import Swal from "sweetalert2";
import store from "../../../reducers/store";
import "./style.css";
const { default: client } = require("api/apiAuth/guestClient");
const { getValue } = require("../../GetObjectValues");

export const replaceSpanContent = (htmlString, spanId, newContent) => {
  // Create a regular expression to match the specified span
  var regex = new RegExp(
		"<span\\s+([^>]*\\s+)?id=['\"]" + spanId + "['\"]([^>]*)>(.*?)<\\/span>",
		"gi"
	);

  // Replace the matched span with the new content
  var modifiedHtml = htmlString.replace(
		regex,
		'<span $1id="' + spanId + '"$2>' + newContent + "</span>"
	);

  return modifiedHtml;
};

export const getActualName = (html, id) => {
  const indexOfActualName = html.indexOf(id);
  const restOfTemp = html.slice(indexOfActualName + id.length);
  const indexOfStartName = indexOfActualName + id.length;
  const indexOfEndName = restOfTemp.indexOf("</span");
  const oldName = html.slice(
    indexOfStartName,
    indexOfStartName + indexOfEndName
  );

  return oldName;
};
export const getActualNameEnded = (html, id, endedTag) => {
  const indexOfActualName = html.indexOf(id);
  const restOfTemp = html.slice(indexOfActualName + id.length);
  const indexOfStartName = indexOfActualName + id.length;
  const indexOfEndName = restOfTemp.indexOf(endedTag);
  const oldName = html.slice(
    indexOfStartName,
    indexOfStartName + indexOfEndName
  );

  return oldName;
};
export const setRoleInsidePdf = (
  currentDoctorIndex,
  currentengagementIndex,
  formik,
  html,
  attributes
) => {
  const dRoleId = `id="engagement_${currentengagementIndex + 1}_role">`;
  const oldDocRole = getActualName(html, dRoleId);
  if (oldDocRole) {
    const indexOfRole = html.indexOf(dRoleId);
    let newHtml = ` ${html.slice(0, indexOfRole + dRoleId.length)}${
      attributes.value
    }${html.slice(indexOfRole + dRoleId.length + oldDocRole.length)}`;
    formik.setFieldValue(
      `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
      newHtml
    );
  }
};

export const changeDoctorInfoPdf = async (
  currentDoctorIndex,
  formik,
  newHtml,
  attributes
) => {
  let { data } = await client.get("generic/find", {
    params: {
      model: "Doctor",
      filter: { id: attributes.value },
      include: [
        {
          association: "specialty",
        },
        {
          association: "segmentation",
        },
      ],
    },
  });
  const drNameId = 'id="dr_name_id">';
  const oldDocName = getActualName(newHtml, drNameId);

  if (oldDocName) {
    newHtml = newHtml.replaceAll(oldDocName, data.rows[0].name);
  }

  const drSpecialtyId = 'id="dr_specialty">';
  const oldDrSpecialty = getActualName(newHtml, drSpecialtyId);

  if (oldDrSpecialty) {
    newHtml = newHtml.replaceAll(
      oldDrSpecialty,
      data.rows[0].specialty.name
        ? data.rows[0].specialty.name
        : "(-insert doctor specialty-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      drSpecialtyId,
      `${drSpecialtyId} ${
        data.rows[0].specialty.name
          ? data.rows[0].specialty.name
          : "(-insert doctor specialty-)"
      }`
    );
  }
  // const drProfessionId = 'id="dr_profession">';
  // const oldDrProfession = getActualName(newHtml, drProfessionId);

  // if (oldDrProfession) {
  //   // newHtml = newHtml.replaceAll(oldDrProfession, data.rows[0].profession);

  // }

  const drHospitalName = 'id="dr_hospital_name">';
  const oldHospitalName = getActualName(newHtml, drHospitalName);

  if (oldHospitalName) {
    newHtml = newHtml.replaceAll(
      oldHospitalName,
      data.rows[0].institution
        ? data.rows[0].institution
        : "(-insert hospital name-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      drHospitalName,
      `${drHospitalName} ${
        data.rows[0].institution
          ? data.rows[0].institution
          : "(-insert hospital name-)"
      }`
    );
  }

  const hospitalAddress = 'id="hospitl_address">';

  const oldHospitalAddress = getActualName(newHtml, hospitalAddress);

  if (oldHospitalAddress) {
    newHtml = newHtml.replaceAll(
      oldHospitalAddress,
      data.rows[0].address
        ? data.rows[0].address
        : "(-insert hospital address-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      hospitalAddress,
      `${hospitalAddress} ${
        data.rows[0].address
          ? data.rows[0].address
          : "(-insert hospital address-)"
      }`
    );
  }

  const dr_acc_num = 'id="dr_acc_num">';

  const oldAccNum = getActualName(newHtml, dr_acc_num);

  if (oldAccNum) {
    newHtml = newHtml.replaceAll(
      oldAccNum,
      data.rows[0].bank_account
        ? data.rows[0].bank_account
        : "(-insert doctor bank account-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      dr_acc_num,
      `${dr_acc_num} ${
        data.rows[0].bank_account
          ? data.rows[0].bank_account
          : "(-insert doctor bank account-)"
      }`
    );
  }

  const dr_swift_code = 'id="dr_swift_code">';

  const oldSwift = getActualName(newHtml, dr_swift_code);

  if (oldSwift) {
    newHtml = newHtml.replaceAll(
      oldSwift,
      data.rows[0].swift
        ? data.rows[0].swift
        : "(-please insert doctor swift code-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      dr_swift_code,
      `${dr_swift_code} ${
        data.rows[0].swift
          ? data.rows[0].swift
          : "(-please insert doctor swift code-)"
      }`
    );
  }

  const dr_Iban = 'id="dr_iBan">';

  const oldIBan = getActualName(newHtml, dr_Iban);

  if (oldIBan) {
    newHtml = newHtml.replaceAll(
      oldIBan,
      data.rows[0].iban ? data.rows[0].iban : "(-please insert doctor Iban-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      dr_Iban,
      `${dr_Iban} ${
        data.rows[0].iban ? data.rows[0].iban : "(-please insert doctor Iban-)"
      }`
    );
  }

  const dr_vendor_no = 'id="vendor_number">';

  const oldVendorNo = getActualName(newHtml, dr_vendor_no);

  if (oldVendorNo) {
    newHtml = newHtml.replaceAll(
      oldVendorNo,
      data.rows[0].Vendor
        ? data.rows[0].Vendor
        : "(-please insert doctor vendor number-)"
    );
  } else {
    newHtml = newHtml.replaceAll(
      dr_vendor_no,
      `${dr_vendor_no} ${
        data.rows[0].Vendor
          ? data.rows[0].Vendor
          : "(-please insert doctor vendor number-)"
      }`
    );
  }

  formik.setFieldValue(
    `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
    newHtml
  );
};
export const getActualSection = (html, id, endSection) => {
  const indexOfActualName = id && html.indexOf(id);
  if (indexOfActualName < 0) {
    return null;
  }
  const restOfTemp = html.slice(indexOfActualName + id.length);
  const indexOfStartName = indexOfActualName;
  const indexOfEndName = restOfTemp.indexOf(endSection);
  const oldSection = html.slice(
    indexOfStartName,
    indexOfStartName + indexOfEndName + 40
  );

  return oldSection;
};
export const getActualNameWithOutClose = (html, id) => {
  const indexOfActualName = html.indexOf(id);
  let restOfTemp = html.slice(indexOfActualName + id.length);
  const indexOfStartName = indexOfActualName + id.length;
  const indexOfEndName = restOfTemp.indexOf("</span");
  const oldName = html.slice(
    indexOfStartName,
    indexOfStartName + indexOfEndName
  );
  return oldName;
};
const events = {
  fetchFromApi: async (attributes) => {
    let { model, limit, skip, filter, include } = attributes["fetchFromApi"];

    let { data } = await client.get("generic/find", {
      params: { model, limit, skip, filter, include },
    });
    return data;
  },
  fetchFromPoca: async (attributes, input, event, previousResult, formik) => {
    const result = await client.post("algorithm/applaySpacificAlgorithm", {
      drugName: getValue(formik.values, input.key),
      algorithmName: "getPocaScore",
    });
    return {
      [input.key]: {
        similarity: result.data.finalResult.length
          ? `${result.data.finalResult[0].mergedScore}%`
          : "0%",
        acceptance: result.data.finalResult.length
          ? "Initital Rejection"
          : "Initial Acceptance",
        similarityList: result.data.finalResult,
      },
    };
  },
  getDoctorOptions: (attributes, input, event, previousResult) => {
    const clonedInput = _.cloneDeep(input);
    if (
      attributes.formik &&
      attributes.formik.initialValues[event.attributes.key]
    ) {
      clonedInput.options = [
        ...clonedInput.options,
        ...attributes.formik.initialValues[event.attributes.key],
      ];
    }
    store.dispatch(setEventsInputs(clonedInput));
    return clonedInput;
  },
  getEventsDoctor: async (attributes, input, event, previousResult) => {
    let { data } = await client.get("generic/find", {
      params: {
        model: "DoctorsSubmissions",
        filter: { doctor_id: attributes.value },
        include: [
          {
            association: "submission",
            where: {
              draft_id: null,
              // event_date_to: {
              //   operator: "gt",
              //   value: `${new Date(`
              //     ${new Date().getFullYear() - 1}/12/31
              //   `).toLocaleDateString()}`,
              // },
            },
          },
          {
            association: "doctor",
          },
          {
            association: "engagements",
          },
        ],
      },
    });
    let submissions =
      data.count > 0
        ? data.rows.filter(
            (e) =>
              new Date(e.submission.event_date_to) >
              new Date(`${new Date().getFullYear() - 1}/12/31`)
          )
        : [];
    if (submissions.length > 0) {
      const list = [];

      for (const [i, sub] of submissions.entries()) {
        list.push(<li>{sub.submission.file_code}</li>);
      }

      let listContainer = document.createElement("div");
      listContainer.style.fontFamily = "Montserrat";
      let listElement = document.createElement("ol");
      listElement.style.fontFamily = "Montserrat";
      listElement.style.display = "flex";
      listElement.style.flexDirection = "column";
      listElement.style.alignItems = "flex-start";

      // Make the list item
      let listItem = document.createElement("li");
      listItem.style.fontFamily = "Montserrat";
      listItem.style.cursor = "pointer";
      listItem.classList.add("ListPOPItemClass");
      listItem.textContent = `Event Name ${submissions[0].submission.values.event_name}, Event id: ${submissions[0].submission.file_code} with a total of ${submissions[0].engagements.length} engagements that costs ${submissions[0].doctor_package} EGP.`;
      listItem.onclick = function () {
        window.location.assign(`/details/${submissions[0].submission.id}`);
      };
      listElement.appendChild(listItem);
      listContainer.appendChild(listElement);
      for (let i = 1; i < submissions.length; i++) {
        listItem = document.createElement("li");
        listElement.appendChild(listItem);
        listItem.style.fontFamily = "Montserrat";
        listItem.style.cursor = "pointer";
        listItem.classList.add("ListPOPItemClass");
        listItem.textContent = `Event Name ${submissions[i].submission.values.event_name}, Event id: ${submissions[i].submission.file_code} with a total of ${submissions[i].engagements.length} engagements that costs ${submissions[i].doctor_package} EGP.`;

        listItem.onclick = function () {
          window.location.assign(`/details/${submissions[i].submission.id}`);
        };
        // .push(`/details/${submissions[i].submission.id}}`);
      }

      Swal.fire({
        title: `This doctor have been already involved in the following events as a ${
          submissions[0].doctor.type
        } HCP during this year ${new Date().getFullYear()} :`,
        html: listContainer,
        icon: "info",
        confirmButtonColor: "#0066cc",
        dangerMode: true,
        customClass: "swal-wide-size",
      });
    }
  },
  changeDoctorEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    const currentDoctorIndex = input.realKey.split(".")[1] * 1;

    let newHtml =
      formik.values.invitees[currentDoctorIndex].doctor_engagements_file;

    changeDoctorInfoPdf(currentDoctorIndex, formik, newHtml, attributes);
  },
  changeStartDateEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let newHtml;
    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventDateId = 'id="event_start_date">';
          const oldEventDate = getActualName(engagementHtml, eventDateId);
          if (oldEventDate) {
            newHtml = replaceSpanContent(
              engagementHtml,
              `event_start_date`,
              new Date(attributes.value).toISOString().split("T")[0]
            );
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },

  changeEndDateEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let newHtml;
    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventDateId = 'id="event_end_date">';
          const oldEventDate = getActualName(engagementHtml, eventDateId);
          if (oldEventDate) {
            newHtml = replaceSpanContent(
              engagementHtml,
              `event_end_date`,
              new Date(attributes.value).toISOString().split("T")[0]
            );
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },

  changeSymposiumDateEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    const currentDoctorIndex = input.realKey.split(".")[1] * 1;
    const currentEngegementIndex = input.realKey.split(".")[3] * 1;

    const engagementHtml =
      formik.values.invitees[currentDoctorIndex].doctor_engagements_file;
    const symposiumDateId = `id="engagement_${
      currentEngegementIndex + 1
    }_symposium_date">`;
    const oldSymposiumDate = getActualName(engagementHtml, symposiumDateId);
    if (oldSymposiumDate) {
      let newHtml = replaceSpanContent(
        engagementHtml,
        `engagement_${currentEngegementIndex + 1}_symposium_date`,
        new Date(attributes.value).toISOString().split("T")[0]
      );
      // const indexOfSymposiumDate = engagementHtml.indexOf(symposiumDateId);

      // let newHtml = ` ${engagementHtml.slice(
      //   0,
      //   indexOfSymposiumDate + symposiumDateId.length
      // )}${
      //   new Date(attributes.value).toISOString().split("T")[0]
      // }${engagementHtml.slice(
      //   indexOfSymposiumDate + symposiumDateId.length + oldSymposiumDate.length
      // )}`;

      formik.setFieldValue(
        `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
        newHtml
      );
    }
  },
  changeEventObjEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let newHtml;
    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventObjId = 'id="event_objective">';
          const oldEventObj = getActualName(engagementHtml, eventObjId);
          if (oldEventObj) {
            newHtml = engagementHtml.replaceAll(oldEventObj, attributes.value);
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },

  changeDoctorRoleEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    const currentDoctorIndex = input.realKey.split(".")[1] * 1;
    const currentEngegementIndex = input.realKey.split(".")[3] * 1;
    const engagementHtml =
      formik.values.invitees[currentDoctorIndex].doctor_engagements_file;

    setRoleInsidePdf(
      currentDoctorIndex,
      currentEngegementIndex,
      formik,
      engagementHtml,
      attributes
    );
  },

  changeDoctorTalkTitleEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    const currentDoctorIndex = input.realKey.split(".")[1] * 1;
    const currentEngegementIndex = input.realKey.split(".")[3] * 1;

    const engagementHtml =
      formik.values.invitees[currentDoctorIndex].doctor_engagements_file;
    const dTitleId = `id="engagement_${currentEngegementIndex + 1}_talk_title"`;
    const oldTitle = getActualNameWithOutClose(engagementHtml, dTitleId);
    if (oldTitle) {
      const indexOfTitle = engagementHtml.indexOf(dTitleId);
      let newHtml = ` ${engagementHtml.slice(
        0,
        indexOfTitle + dTitleId.length
      )}    >${attributes.value}${engagementHtml.slice(
        indexOfTitle + dTitleId.length + oldTitle.length
      )}`;
      formik.setFieldValue(
        `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
        newHtml
      );
    }
  },
  changeEventNameEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let newHtml;
    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventNameId = 'id="engegement_event_name">';
          const oldEventName = getActualName(engagementHtml, eventNameId);
          if (oldEventName) {
            newHtml = engagementHtml.replaceAll(oldEventName, attributes.value);
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }

          // const { data } = await client.get(
          // 	"Submission/getNextSubmissionNumber"
          // );
          // const serialNumberId = 'id="event_code">';
          // const oldSerialNumber = getActualName(engagementHtml, serialNumberId);
          // if (oldSerialNumber) {
          // 	newHtml = engagementHtml.replaceAll(oldSerialNumber, data);
          // }

          // formik.setFieldValue(
          // 	`invitees.[${currentDoctorIndex}].doctor_engagements_file`,
          // 	newHtml
          // );
        }
      }
    }
  },

  changeHcpCountryEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let newHtml;
    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const hcpCountryId = 'id="hcp_country">';
          const oldHcpCountry = getActualName(engagementHtml, hcpCountryId);

          if (oldHcpCountry) {
            newHtml = engagementHtml.replaceAll(
              oldHcpCountry,
              `${attributes.value} with`
            );
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },

  changeVenueEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventVenueId = 'id="engegement_venue">';
          const oldEventVenue = getActualName(engagementHtml, eventVenueId);
          if (oldEventVenue) {
            let newHtml = engagementHtml.replaceAll(
              oldEventVenue,
              attributes.value
            );
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },

  changeFirstApproverEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    const { data } = await client.get("generic/find", {
      params: {
        model: "User",
        filter: {
          id: attributes.value,
        },
      },
    });

    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventApprover1Id = 'id="approver1">';
          const oldEventApprover1 = getActualName(
            engagementHtml,
            eventApprover1Id
          );
          if (oldEventApprover1) {
            let newHtml = engagementHtml.replaceAll(
              oldEventApprover1,
              `${data.rows[0].first_name} ${data.rows[0].last_name}`
            );
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },

  changeSecondApproverEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    const { data } = await client.get("generic/find", {
      params: {
        model: "User",
        filter: {
          id: attributes.value,
        },
      },
    });

    if (!_.isEmpty(formik.values?.invitees)) {
      for (let currentDoctorIndex in formik.values?.invitees) {
        const engagementHtml =
          formik.values?.invitees?.[currentDoctorIndex]
            ?.doctor_engagements_file;
        if (engagementHtml) {
          const eventApprover2Id = 'id="approver2">';
          const oldEventApprover2 = getActualName(
            engagementHtml,
            eventApprover2Id
          );
          if (oldEventApprover2) {
            let newHtml = engagementHtml.replaceAll(
              oldEventApprover2,
              `${data.rows[0].first_name} ${data.rows[0].last_name}`
            );
            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
        }
      }
    }
  },
  changeActiveTypeEngagementsPdf: async (
    attributes,
    input,
    event,
    previousResult,
    formik,
    inputKey
  ) => {
    let level_1, level_2;
    const currentDoctorIndex = input.realKey.split(".")[1] * 1;
    const currentEngegementIndex = input.realKey.split(".")[3] * 1;
    if (!_.isEmpty(formik.values?.invitees)) {
      // for (let currentDoctorIndex in formik.values?.invitees) {
      const engagementHtml =
        formik.values?.invitees?.[currentDoctorIndex]?.doctor_engagements_file;
      if (engagementHtml) {
        if (input.id === 44) {
          level_2 = attributes.value;
          let allProduct = [];
          formik.values?.invitees?.[currentDoctorIndex]?.engagement?.map(
            (eng, index) => {
              eng.product &&
                eng.product.length > 0 &&
                allProduct.push(
                  ...eng.product?.map((pr) => {
                    if (index === currentEngegementIndex)
                      return {
                        ...pr,
                        active_type: attributes.value,
                      };
                    else {
                      return {
                        ...pr,
                        active_type: eng.active_type,
                      };
                    }
                  })
                );
            }
          );
          level_1 = allProduct;
        }
        if (input.id === 7) {
          if (
            !formik.values?.invitees?.[currentDoctorIndex]?.engagement?.[
              currentEngegementIndex
            ]?.active_type
          ) {
            return;
          }
          let allProduct = [];

          // attributes.value.value.map((eng) => {
          // allProduct.push({
          //   ...eng,
          //   active_type:
          //     formik.values?.invitees?.[currentDoctorIndex]?.engagement?.[
          //       currentEngegementIndex
          //     ].active_type,
          // });

          allProduct.push(
            ...attributes.value.value.map((pr) => {
              return {
                ...pr,
                active_type:
                  formik.values?.invitees?.[currentDoctorIndex]?.engagement?.[
                    currentEngegementIndex
                  ].active_type,
              };
            })
          );
          for (
            let index = 0;
            index <
            formik.values?.invitees?.[currentDoctorIndex]?.engagement.length;
            index++
          ) {
            const element =
              formik.values?.invitees?.[currentDoctorIndex]?.engagement[index];
            if (index !== currentEngegementIndex) {
              allProduct.push(
                ...element.product.map((pr) => {
                  return {
                    ...pr,
                    active_type: element.active_type,
                  };
                })
              );
            }
          }

          level_1 = allProduct;
          level_2 =
            formik.values?.invitees?.[currentDoctorIndex]?.engagement?.[
              currentEngegementIndex
            ].active_type;
        }

        let ProductsData = [];
        let newHtml = engagementHtml;

        if (level_1.length > 0) {
          for (let index = 0; index < level_1.length; index++) {
            const product = level_1[index];
            const { data } = await client.get("generic/find", {
              params: {
                model: "WBS",
                filter: { level_1: product.id, level_2: product.active_type },
              },
            });

            ProductsData.push(...data.rows);

            if (!(data.rows[0].WBS_CODE && data.rows[0].CC_CODE)) {
              Swal.fire({
                title: "info",
                text: "This product doesn't have a WBS_code nor CC_CODE which may casue an issue to the contract",
                icon: "info",
                confirmButtonColor: "#0066cc",
              });
            }
          }
          let lastIndex = 2;
          do {
            let oldSection = newHtml.indexOf(
              `<tr class="c26" id="product_${lastIndex}">`
            );
            if (oldSection > -1) {
              let closingTag = newHtml.indexOf("</tr>", oldSection) + 5;
              newHtml =
                newHtml.slice(0, oldSection) + newHtml.slice(closingTag + 1);
            } else break;
            lastIndex += 1;
          } while (true);
          if (ProductsData.length > 1) {
            for (let index = 1; index < ProductsData.length; index++) {
              const product = ProductsData[index];

              let section_product_id = `<tr class="c26" id="product_${index}">`;
              const sectionHtmlProduct = getActualSection(
                newHtml,
                section_product_id,
                "</tr>"
              );
              let sectionProductIndex = newHtml.indexOf(sectionHtmlProduct);
              let newProductSection = sectionHtmlProduct.replace(
                `product_${index}`,
                `product_${index + 1}`
              );
              newProductSection = newProductSection.replace(
                `product_${index}_WBS_CODE`,
                `product_${index + 1}_WBS_CODE`
              );
              newProductSection = newProductSection.replace(
                `product_${index}_CC_CODE`,
                `product_${index + 1}_CC_CODE`
              );
              newProductSection = newProductSection.replace(
                `product_${index}_WBS_Name`,
                `product_${index + 1}_WBS_Name`
              );
              newHtml = `${newHtml.slice(
                0,
                sectionProductIndex + sectionHtmlProduct.length
              )}${newProductSection} ${newHtml.slice(
                sectionProductIndex + sectionHtmlProduct.length
              )}`;
            }
          }

          for (let index = 0; index < ProductsData.length; index++) {
            const product = ProductsData[index];

            const WBS_CODE_Id = `id="product_${index + 1}_WBS_CODE"`;
            const old_WBS_CODE_Id = getActualName(newHtml, WBS_CODE_Id);

            const WBS_Name = `id="product_${index + 1}_WBS_Name"`;
            const old_WBS_Name_Id = getActualName(newHtml, WBS_Name);
            const WBS_CC_CODE = `id="product_${index + 1}_CC_CODE"`;
            const old_CC_CODE_Id = getActualName(newHtml, WBS_CC_CODE);
            if (old_WBS_CODE_Id && old_WBS_Name_Id) {
              const indexOfTitle = newHtml.indexOf(WBS_CODE_Id);
              newHtml = `${newHtml.slice(
                0,
                indexOfTitle + WBS_CODE_Id.length
              )}    >${product.WBS_CODE}${newHtml.slice(
                indexOfTitle + WBS_CODE_Id.length + old_WBS_CODE_Id.length
              )}`;

              const indexOfNameTitle = newHtml.indexOf(WBS_Name);

              newHtml = `${newHtml.slice(
                0,
                indexOfNameTitle + WBS_Name.length
              )}    >${product.WBS_Name}${newHtml.slice(
                indexOfNameTitle + WBS_Name.length + old_WBS_Name_Id.length
              )}`;
              const indexOfCcCodeTitle = newHtml.indexOf(WBS_CC_CODE);
              newHtml = ` ${newHtml.slice(
                0,
                indexOfCcCodeTitle + WBS_CC_CODE.length
              )}    >${product.CC_CODE}${newHtml.slice(
                indexOfCcCodeTitle + WBS_CC_CODE.length + old_CC_CODE_Id.length
              )}`;
            }

            formik.setFieldValue(
              `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
              newHtml
            );
          }
          // let wbsCode = ProductsData.map((p) => p.WBS_CODE).join(",");
          // let wbsName = ProductsData.map((p) => p.WBS_Name).join(",");
          // let ccCode = ProductsData.map((p) => p.CC_CODE).join(",");

          // const WBS_CODE_Id = `id="product_${
          //   currentEngegementIndex + 1
          // }_WBS_CODE"`;
          // const old_WBS_CODE_Id = getActualName(engagementHtml, WBS_CODE_Id);
          // const WBS_Name = `id="product_${
          //   currentEngegementIndex + 1
          // }_WBS_Name"`;
          // const old_WBS_Name_Id = getActualName(engagementHtml, WBS_Name);
          // const WBS_CC_CODE = `id="product_${
          //   currentEngegementIndex + 1
          // }_CC_CODE"`;
          // const old_CC_CODE_Id = getActualName(engagementHtml, WBS_CC_CODE);

          // if (old_WBS_CODE_Id && old_WBS_Name_Id) {
          //   const indexOfTitle = engagementHtml.indexOf(WBS_CODE_Id);
          //   let newHtml = `${engagementHtml.slice(
          //     0,
          //     indexOfTitle + WBS_CODE_Id.length
          //   )}    >${wbsCode}${engagementHtml.slice(
          //     indexOfTitle + WBS_CODE_Id.length + old_WBS_CODE_Id.length
          //   )}`;

          //   const indexOfNameTitle = newHtml.indexOf(WBS_Name);

          //   newHtml = ` ${newHtml.slice(
          //     0,
          //     indexOfNameTitle + WBS_Name.length
          //   )}    >${wbsName}${newHtml.slice(
          //     indexOfNameTitle + WBS_Name.length + old_WBS_Name_Id.length
          //   )}`;
          //   const indexOfCcCodeTitle = newHtml.indexOf(WBS_CC_CODE);
          //   newHtml = ` ${newHtml.slice(
          //     0,
          //     indexOfCcCodeTitle + WBS_CC_CODE.length
          //   )}    >${ccCode}${newHtml.slice(
          //     indexOfCcCodeTitle + WBS_CC_CODE.length + old_CC_CODE_Id.length
          //   )}`;
          //   formik.setFieldValue(
          //     `invitees.[${currentDoctorIndex}].doctor_engagements_file`,
          //     newHtml
          //   );
        }
        // if (old_WBS_Name_Id) {
        //   const indexOfTitle = engagementHtml.indexOf(WBS_Name);
        //   let newHtml = ` ${engagementHtml.slice(
        //     0,
        //     indexOfTitle + WBS_Name.length
        //   )}    >${ProductsData.map((p) => p.WBS_Name).join(
        //     ","
        //   )}${engagementHtml.slice(
        //     indexOfTitle + WBS_Name.length + WBS_Name.length
        //   )}`;

        // }
        // }
      }
      // }
    }
  },

  setFieldValue: (attributes, input, event, previousResult, formik) => {
    attributes["setFieldValue"].fields.forEach((field) => {
      attributes.formik.setFieldValue(
        input.key.replace(
          input.key.split(".")[input.key.split(".").length - 1],
          field
        ),
        previousResult[input.key][field]
      );
    });
  },
  fetchSelectionOptions: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let { rows, count } = previousResult || { rows: [], count: 0 };
    const clonedInput = _.cloneDeep(input);
    clonedInput.options = [...clonedInput.options, ...rows];

    let skip = clonedInput?.optionsParams?.skip
      ? attributes["fetchSelectionOptions"]?.limit +
        clonedInput?.optionsParams?.skip
      : attributes["fetchSelectionOptions"]?.limit;
    clonedInput.optionsParams = { count, skip };
    clonedInput.events = clonedInput.events.map((inputEvent) => {
      if (inputEvent.id == event.id) {
        inputEvent.attributes["fetchFromApi"] = {
          ...inputEvent.attributes["fetchFromApi"],
          skip,
        };
      }
      return inputEvent;
    });
    store.dispatch(setEventsInputs(clonedInput));

    return clonedInput;
  },
  checkLimitation: async (attributes, input, event, previousResult) => {
    try {
      let { data } = await client.get("SubmissionsLimit/checkDayLimit");
      let AvailableSubmissionsCount = [];
      let storeSubmissionsCount =
        store.getState().settings.settings.availableSubmissionsCount;
      input.options = input.options.map(
        (obj) => data.find((o) => o.label === obj.label) || obj
      );
      let count;
      data.forEach((limit) => {
        let storedSubmissionLimit = storeSubmissionsCount.find(
          (submissionCount) => limit.label in submissionCount
        );
        if (storedSubmissionLimit?.[limit.label] == 0) {
          input.options = input.options.map((option) => {
            if (option.label == limit.label) {
              option = {
                ...option,
                disabled: true,
                message: "You have exceeded the submission limit",
              };
            }
            return option;
          });
        }

        if (limit.limitCount === "unlimited") {
          AvailableSubmissionsCount.push({
            [limit.label]: "unlimited",
            originalLimitCount: "unlimited",
          });
        } else if (
          storedSubmissionLimit?.["originalLimitCount"] > limit.limitCount
        ) {
          count =
            storedSubmissionLimit["originalLimitCount"] - limit.limitCount;
          AvailableSubmissionsCount.push({
            [limit.label]: limit.limitCount - count,
            originalLimitCount: limit.limitCount - count,
          });
        } else if (
          storedSubmissionLimit?.["originalLimitCount"] == limit.limitCount &&
          storedSubmissionLimit?.[limit.label] <
            storedSubmissionLimit?.["originalLimitCount"]
        ) {
          AvailableSubmissionsCount.push({
            [limit.label]: storedSubmissionLimit?.[limit.label],
            originalLimitCount: limit.limitCount,
          });
        } else {
          AvailableSubmissionsCount.push({
            [limit.label]: limit.limitCount,
            originalLimitCount: limit.limitCount,
          });
        }
      });
      store.dispatch(setAvailableSubmissionsCount(AvailableSubmissionsCount));

      return input;
    } catch (error) {
      console.error("error: ", error);
    }
  },
  checkRealTimeLimitation: async (attributes, input, event, previousResult) => {
    try {
      let productType = attributes.value;
      let availableSubmissionsCount =
        store.getState().settings.settings.availableSubmissionsCount;
      availableSubmissionsCount = _.cloneDeep(availableSubmissionsCount).map(
        (limitCount) => {
          if (productType in limitCount) {
            if (![0, "unlimited"].includes(limitCount[productType])) {
              limitCount[productType] -= 1;
            }
          }
          return limitCount;
        }
      );

      store.dispatch(setAvailableSubmissionsCount(availableSubmissionsCount));
      // return input;
    } catch (error) {
      console.error("error: ", error);
    }
  },
};
export default events;
