import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
function WhenTaskNotInModification(mapper) {
  if ("submissionModifications" in mapper.task.submission) {
    for (const modification of mapper.task.submission.submissionModifications)
      if (modification.status === "Pending") return false;
  }
  return true;
}

export const documentConfig = {
  config: (mapper) => {
    return [
      {
        tabId: 2,
        sectionId: 1,
        actions: [
          {
            gridSize: 4,
            label: "View File",
            classes: "btn btn-primary btn-transition-none d-inline view-button",
            onClick: {
              functionName: "viewFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "is_reviewed",
                    checkValue: false,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.is_reviewed,
                  },
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1 && $2 && $3)",
              },
            },
          },
          {
            gridSize: 4,
            label: "View History",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "viewHistory",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "!$0",
              },
            },
          },

          {
            classes: "review-icon ",
            label: "",
            icon: CheckIcon,
            conditionalClasses: [
              { className: "green", field: "is_reviewed", value: true },
              { className: "light-green", field: "is_reviewed", value: null },
              {
                className: "light-green",
                field: "is_reviewed",
                value: false,
              },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", source: "static", value: true },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "(!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
          {
            classes: "review-icon ",
            conditionalClasses: [
              { className: "light-red", field: "is_reviewed", value: null },
              {
                className: "light-red",
                field: "is_reviewed",
                value: true,
              },
              { className: "red", field: "is_reviewed", value: false },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", value: false, source: "static" },
              ],
            },
            label: "",
            icon: ClearIcon,
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "(!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
          {
            gridSize: 12,
            type: "label",
            label: mapper.attachment?.attachment_values?.decisions
              ? mapper.attachment.attachment_values?.decisions?.find(
                  (decision) =>
                    decision.roles.some(
                      (role) => role.name === "Junior Reviewer"
                    )
                )?.username
              : "",
            classes: `decisions ${
              mapper.attachment?.attachment_values?.decisions
                ? mapper.attachment.attachment_values?.decisions?.find(
                    (decision) =>
                      decision.roles.some(
                        (role) => role.name === "Junior Reviewer"
                      )
                  )?.is_reviewed
                  ? "green"
                  : "red"
                : ""
            }`,
            conditionalClasses: [],

            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "attachment_values",
                    checkValue: "attachment_values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment,
                  },
                ],
                conditionsEquation: "$0&&$1",
              },
              disable: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["Junior Reviewer", "Unit Manager Reviewing"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },

                  {
                    operator: "checkEmpty",
                    functionName: "applyObjectMethods",
                    actualValue:
                      mapper.attachment.attachment_values?.decisions?.find(
                        (decision) =>
                          decision.roles.some(
                            (role) => role.name === "Junior Reviewer"
                          )
                      ),
                  },
                ],
                conditionsEquation: "$0 && !$1 ",
              },
            },
          },
          {
            gridSize: 12,
            type: "label",
            label: mapper.attachment?.attachment_values?.decisions
              ? mapper.attachment.attachment_values?.decisions?.find(
                  (decision) =>
                    decision.roles.some(
                      (role) => role.name === "Unit Manager Reviewing"
                    )
                )?.username
              : "",
            classes: `decisions ${
              mapper.attachment?.attachment_values?.decisions
                ? mapper.attachment.attachment_values?.decisions?.find(
                    (decision) =>
                      decision.roles.some(
                        (role) => role.name === "Unit Manager Reviewing"
                      )
                  )?.is_reviewed
                  ? "green"
                  : "red"
                : ""
            }`,
            conditionalClasses: [],

            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "attachment_values",
                    checkValue: "attachment_values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment,
                  },
                ],
                conditionsEquation: "$0&&$1",
              },
              disable: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["Junior Reviewer", "Unit Manager Reviewing"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },

                  {
                    operator: "checkEmpty",
                    functionName: "applyObjectMethods",
                    actualValue:
                      mapper.attachment.attachment_values?.decisions?.find(
                        (decision) =>
                          decision.roles.some(
                            (role) => role.name === "Unit Manager Reviewing"
                          )
                      ),
                  },
                ],
                conditionsEquation: "$0 && !$1 ",
              },
            },
          },
        ],
      },
      {
        tabId: 3,
        sectionId: 1,
        actions: [
          {
            gridSize: 4,
            label: "View File",
            classes: "btn btn-primary btn-transition-none d-inline view-button",
            onClick: {
              functionName: "viewFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "is_reviewed",
                    checkValue: false,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.is_reviewed,
                  },
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1 && $2 && $3)",
              },
            },
          },
          {
            gridSize: 4,
            label: "View History",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "viewHistory",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: null,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.document,
                  },
                ],
                conditionsEquation: "$0 && !$1",
              },
            },
          },
          {
            gridSize: 4,
            label: "Delete File",
            classes:
              "btn btn-danger  btn-transition-none d-inline delete-button ml-2",
            onClick: {
              functionName: "deleteFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User", "Financial"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: null,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.document,
                  },
                  {
                    key: "name",
                    checkValue: ["User", "Financial"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "logs",
                    checkValue: "logs",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment,
                  },
                  {
                    key: "id",
                    checkValue: null,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.attachment?.logs?.map(
                      (user) => user.user_id
                    ),
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.attachment?.logs?.map(
                      (user) => user.user_id
                    ),
                  },
                ],
                conditionsEquation: "$0 && $1 && $2 && ($3 && !$4 && $5) ",
              },
            },
          },
          {
            gridSize: 4,
            label: "",
            classes: "btn-primary btn-transition-none  view-button ml-2",
            icon: GetAppIcon,
            type: "download",
            onClick: {
              functionName: "downloadFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "Financial",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "$0 ",
              },
            },
          },
        ],
      },
      {
        tabId: 4,
        sectionId: 1,
        actions: [
          {
            gridSize: 4,
            label: "View File",
            classes: "btn btn-primary btn-transition-none d-inline view-button",
            onClick: {
              functionName: "viewFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "is_reviewed",
                    checkValue: false,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.is_reviewed,
                  },
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1 && $2 && $3)",
              },
            },
          },
          {
            gridSize: 4,
            label: "View History",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "viewHistory",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "!$0",
              },
            },
          },
          {
            classes: "review-icon ",
            label: "",
            icon: CheckIcon,
            conditionalClasses: [
              { className: "green", field: "is_reviewed", value: true },
              { className: "light-green", field: "is_reviewed", value: null },
              {
                className: "light-green",
                field: "is_reviewed",
                value: false,
              },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", source: "static", value: true },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "(!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
          {
            classes: "review-icon ",
            conditionalClasses: [
              { className: "light-red", field: "is_reviewed", value: null },
              {
                className: "light-red",
                field: "is_reviewed",
                value: true,
              },
              { className: "red", field: "is_reviewed", value: false },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", value: false, source: "static" },
              ],
            },
            label: "",
            icon: ClearIcon,
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "(!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
        ],
      },

      {
        tabId: 8,
        sectionId: 1,
        actions: [
          {
            gridSize: 4,
            label: "View File",
            classes: "btn btn-primary btn-transition-none d-inline view-button",
            onClick: {
              functionName: "viewFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1 && $2 && $3)",
              },
            },
          },
          {
            gridSize: 4,
            label: "View History",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "viewHistory",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "!$0",
              },
            },
          },
          {
            gridSize: 4,
            label: "Edit Document",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "editDocument",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "(!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
        ],
      },

      {
        tabId: 6,
        sectionId: 1,
        actions: [
          {
            gridSize: 4,
            label: "View File",
            classes: "btn btn-primary btn-transition-none d-inline view-button",
            onClick: {
              functionName: "viewFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "is_reviewed",
                    checkValue: false,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.is_reviewed,
                  },
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1 && $2 && $3)",
              },
            },
          },
          {
            gridSize: 4,
            label: "View History",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "viewHistory",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "!$0",
              },
            },
          },
          {
            classes: "review-icon ",
            label: "",
            icon: CheckIcon,
            conditionalClasses: [
              { className: "green", field: "is_reviewed", value: true },
              {
                className: "light-green",
                field: "is_reviewed",
                value: false,
              },
              {
                className: "light-green",
                field: "is_reviewed",
                value: null,
              },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", source: "static", value: true },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task?.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [71],
                  },
                ],
                conditionsEquation: "!$3 && (!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
          {
            classes: "review-icon ",
            conditionalClasses: [
              {
                className: "light-red",
                field: "is_reviewed",
                value: true,
              },
              { className: "red", field: "is_reviewed", value: false },
              {
                className: "light-red",
                field: "is_reviewed",
                value: null,
              },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", value: false, source: "static" },
              ],
            },
            label: "",
            icon: ClearIcon,
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "id",
                    checkValue: mapper.task?.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [71],
                  },
                ],
                conditionsEquation: "!$3 && (!$0 && $1) || (!$0 && $2)",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },

          {
            gridSize: 12,
            type: "label",
            label: mapper.attachment?.attachment_values?.decisions
              ? mapper.attachment.attachment_values?.decisions?.find(
                  (decision) =>
                    decision.roles.some(
                      (role) => role.name === "Junior Soft Files"
                    )
                )?.username
              : "",
            classes: `decisions ${
              mapper.attachment?.attachment_values?.decisions
                ? mapper.attachment.attachment_values?.decisions?.find(
                    (decision) =>
                      decision.roles.some(
                        (role) => role.name === "Junior Soft Files"
                      )
                  )?.is_reviewed
                  ? "green"
                  : "red"
                : ""
            }`,
            conditionalClasses: [],

            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "attachment_values",
                    checkValue: "attachment_values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment,
                  },
                ],
                conditionsEquation: "$0&&$1",
              },
              disable: {
                conditions: [
                  {
                    key: "name",
                    checkValue: [
                      "Junior Soft Files",
                      "Reception Manager Soft Files",
                      "Technical Affairs Soft Files",
                    ],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    operator: "checkEmpty",
                    functionName: "applyObjectMethods",
                    actualValue:
                      mapper.attachment.attachment_values?.decisions?.find(
                        (decision) =>
                          decision.roles.some(
                            (role) => role.name === "Junior Soft Files"
                          )
                      ),
                  },
                ],
                conditionsEquation: "$0 && !$1",
              },
            },
          },
          {
            gridSize: 12,
            type: "label",
            label: mapper.attachment?.attachment_values?.decisions
              ? mapper.attachment.attachment_values?.decisions?.find(
                  (decision) =>
                    decision.roles.some(
                      (role) => role.name === "Reception Manager Soft Files"
                    )
                )?.username
              : "",
            classes: `decisions ${
              mapper.attachment?.attachment_values?.decisions
                ? mapper.attachment.attachment_values?.decisions?.find(
                    (decision) =>
                      decision.roles.some(
                        (role) => role.name === "Reception Manager Soft Files"
                      )
                  )?.is_reviewed
                  ? "green"
                  : "red"
                : ""
            }`,
            conditionalClasses: [],

            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "attachment_values",
                    checkValue: "attachment_values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment,
                  },
                ],
                conditionsEquation: "$0&&$1",
              },
              disable: {
                conditions: [
                  {
                    key: "name",
                    checkValue: [
                      "Junior Soft Files",
                      "Reception Manager Soft Files",
                      "Technical Affairs Soft Files",
                    ],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    operator: "checkEmpty",
                    functionName: "applyObjectMethods",
                    actualValue:
                      mapper.attachment.attachment_values?.decisions?.find(
                        (decision) =>
                          decision.roles.some(
                            (role) =>
                              role.name === "Reception Manager Soft Files"
                          )
                      ),
                  },
                ],
                conditionsEquation: "$0 && !$1",
              },
            },
          },
        ],
      },
      {
        tabId: 7,
        sectionId: 1,
        actions: [
          {
            gridSize: 4,
            label: "View File",
            classes: "btn btn-primary btn-transition-none d-inline view-button",
            onClick: {
              functionName: "viewFile",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "is_reviewed",
                    checkValue: false,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.is_reviewed,
                  },
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    checkValue: "hf_approval_letter",
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper?.attachment?.document?.key,
                  },
                  {
                    checkValue: 52,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper?.task?.step?.id,
                  },
                  {
                    checkValue: 53,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper?.task?.step?.id,
                  },
                ],
                conditionsEquation:
                  "!($0 && $1 && $2 && $3) && !($1 && $4 && ($5||!$6))",
              },
            },
          },
          {
            gridSize: 4,
            label: "View History",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "viewHistory",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: ["User"],
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "!$0",
              },
            },
          },
          {
            gridSize: 4,
            label: "Update Contract",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            type: "regenerate_file",
            letter: "Doctor_Engagement",
          },
          {
            gridSize: 4,
            label: "Edit Document",
            classes:
              "btn btn-primary btn-transition-none d-inline view-button ml-2",
            onClick: {
              functionName: "editDocument",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    checkValue: null,
                    operator: "notEqual",
                    functionName: "applyMathOperators",
                    actualValue: mapper.attachment.attachment_values,
                  },
                  {
                    key: "attachment_values",
                    checkValue: "attachment_values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment,
                  },
                  {
                    operator: "checkEmpty",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment.attachment_values,
                  },
                  {
                    key: "values",
                    checkValue: "values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment.attachment_values,
                  },
                ],
                conditionsEquation:
                  "(($4 && !$5 && $6 ) &&( (!$0 && $1) || (!$0 && $2))) ",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
          {
            classes: "review-icon ",
            label: "",
            icon: CheckIcon,
            conditionalClasses: [
              { className: "green", field: "is_reviewed", value: true },
              {
                className: "light-green",
                field: "is_reviewed",
                value: false,
              },
              {
                className: "light-green",
                field: "is_reviewed",
                value: null,
              },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", source: "static", value: true },
              ],
            },
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "values",
                    checkValue: "values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment.attachment_values,
                  },
                ],
                conditionsEquation: "((!$0 && $1) || (!$0 && $2))&&!$3 ",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
          {
            classes: "review-icon ",
            conditionalClasses: [
              {
                className: "light-red",
                field: "is_reviewed",
                value: true,
              },
              { className: "red", field: "is_reviewed", value: false },
              {
                className: "light-red",
                field: "is_reviewed",
                value: null,
              },
            ],
            onClick: {
              functionName: "handleReviewAttachments",
              functionAttributes: [
                { field: "file", source: "functionParameters" },
                { field: "approve", value: false, source: "static" },
              ],
            },
            label: "",
            icon: ClearIcon,
            constraints: {
              display: {
                conditions: [
                  {
                    key: "name",
                    checkValue: "User",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.majorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "name",
                    checkValue: mapper.attachment.minorReviewers,
                    operator: "compare2Arrays",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  {
                    key: "values",
                    checkValue: "values",
                    operator: "find",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.attachment.attachment_values,
                  },
                ],
                conditionsEquation: "((!$0 && $1) || (!$0 && $2))&&!$3",
              },
              disable: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "!($0 && $1)",
              },
            },
          },
        ],
      },
    ];
  },
};
