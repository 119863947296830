import React, { useEffect, useState } from "react";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { getValue } from "utils/GetObjectValues";
import { useLocation } from "react-router";
import client from "api/apiAuth/guestClient";

const {
  fillCustomData,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/utils`);

const copyStringToClipboard = function (str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation",
];
const inspectionMergeFields = ["InspectionCompleteDate", "InspectionEventType"];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
};
const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = "Merge field: ";
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(
        createOptionGroupElement(facilityMergeFields, "Facility")
      );
      selectElement.appendChild(
        createOptionGroupElement(inspectionMergeFields, "Inspection")
      );
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      return divElement;
    },
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    },
  },
];

const editorConfig = {
  controls: {
    font: {
      list: {
        Montserrat: "Montserrat",
      },
    },
  },
  readonly: true,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  height: 842,
};

export default function JoditEditorComponent({ formik, input }) {
  const [data, setData] = useState();
  const location = useLocation();
  const handleEditorChange = (value) => {
    if (
      getValue(formik.values, input.key) &&
      !getValue(formik.values, input.key).endsWith(".txt")
    ) {
      formik.setFieldValue(input.key, value);
      setData(value);
    }
  };

  const getHtmlData = async () => {
    const result = await client.get("attachment/getPdfEditorHtml", {
      params: {
        file_name: input.input_layout.file_name,
      },
    });

    return result.data.html;
  };
  const handleInitialView = async (input) => {
    let newHtml = await getHtmlData();
    newHtml = await fillCustomData(input, newHtml, formik, true);
    formik.setFieldValue(input.key, newHtml);
  };

  const handlePdfEditor = async () => {
    if (
      location.pathname.split("/")[location.pathname.split("/").length - 1] ==
        "edit" ||
      location.pathname.split("/")[location.pathname.split("/").length - 2] ==
        "draft"
    ) {
      if (
        getValue(formik.values, input.key) &&
        getValue(formik.values, input.key).endsWith(".txt")
      ) {
        const res = await client.get("attachment/preview", {
          params: {
            filePath: getValue(formik.values, input.key),
          },
        });
        let newHtml = res.data;
        newHtml = await fillCustomData(input, newHtml, formik, location.pathname.split("/")[location.pathname.split("/").length - 1] ==
        "edit" ? false : true);
        formik.setFieldValue(input.key, newHtml);
      } else if (!getValue(formik.values, input.key)) {
        await handleInitialView(input);
      }
    } else if (!getValue(formik.values, input.key)) {
      await handleInitialView(input);
    }
  };

  useEffect(() => {
    handlePdfEditor();

    // if (!getValue(formik.values, input.key).endsWith(".txt")) {
    //   formik.setFieldValue(input.key, getValue(formik.values, input.key));
    // }
  }, []);
  return (
    <div
      style={{
        margin: "0 auto",
        width: "100%",
      }}
    >
      <JoditEditor
        value={getValue(formik.values, input.key)}
        config={editorConfig}
        // onChange={(value) => handleEditorChange(value)}
      />
    </div>
  );
}
