import React from 'react'
import { SvgIcon } from "@material-ui/core";

const Hospital = () => {
  return (
    <SvgIcon viewBox="0 0 450 450">
      <path
        style={{ fill: "#b4bdbf" }}
        d="M460 280v160H0V280c0-5.523 4.477-10 10-10h440c5.523 0 10 4.477 10 10z"
      />
      <path
        style={{ fill: "#9da7a8" }}
        d="m400 270-30 170v20H90v-20L60 270h340z"
      />
      <path
        style={{ fill: "#bec8cc" }}
        d="M370 100v360H90V100c0-5.523 4.477-10 10-10h260c5.523 0 10 4.477 10 10z"
      />
      <path
        style={{ fill: "#a2a9aa" }}
        d="M160 200h30v30h-30v-30zM215 200h30v30h-30v-30zM300 200v30h-30v-30h30zM160 260h30v30h-30v-30zM215 260h30v30h-30v-30zM270 260h30v30h-30v-30z"
      />
      <path
        style={{ fill: "#939e9d" }}
        d="M300 460H160V320h140v140z"
      />
      <path
        style={{ fill: "#8a9695" }}
        d="M300 460h-70V320h70v140z"
      />
      <path
        style={{ fill: "#9da7a8" }}
        d="M460 450c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10v-20h460v20z"
      />
      <path
        style={{ fill: "#b4bdbf" }}
        d="M319.444 90c-4.975 44.998-43.119 80-89.444 80s-84.469-35.002-89.444-80h178.888z"
      />
      <path
        style={{ fill: "#daede3" }}
        d="M230 160c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z"
      />
      <path
        style={{ fill: "#c6524a" }}
        d="M275 80c0 8.284-6.716 15-15 15h-15v15c0 8.284-6.716 15-15 15s-15-6.716-15-15V95h-15c-8.284 0-15-6.716-15-15s6.716-15 15-15h15V50c0-8.284 6.716-15 15-15s15 6.716 15 15v15h15c8.284 0 15 6.716 15 15z"
      />
      <path
        style={{ fill: "#d3625f" }}
        d="M215 95.23V95h-15c-8.284 0-15-6.716-15-15s6.716-15 15-15h15V50c0-8.284 6.716-15 15-15s15 6.716 15 15v15h.23L215 95.23z"
      />
    </SvgIcon>
  )
}

export default Hospital