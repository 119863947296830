import SimilarDrugsTable from "./SimilarDrugsTable";
import NamingDrugNamesTable from "./NamingDrugNamesTable";
import BlankPage from "./BlankPage";
import AddNewDrugName from "./AddNewDrugName";
import SimilarSubmissionsTable from "./SimilarSubmissionsTable";
import SimilarActivIngredientsDrugsTable from "./SimilarActiveIngredientTable";
import SearchPage from "./SearchPage";
export default {
  SimilarDrugsTable,
  NamingDrugNamesTable,
  BlankPage,
  AddNewDrugName,
  SimilarSubmissionsTable,
  SearchPage,
  SimilarActivIngredientsDrugsTable,
};
