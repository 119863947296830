import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function SimpleDialog(props) {
  const { open, setOpen, selectedRows, setSelectedRows, taskId } = props;
  const [comment, setComment] = useState('');
  const handleComment = (taskComment) => {
    setSelectedRows([
      ...selectedRows.map((row) => {
        if (row.taskId == taskId) {
          row.comment = taskComment;
        }
        return row;
      })
    ]);
    setOpen(false);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    handleComment('');
  };
  const handleSubmit = (e) => {
    e.stopPropagation();
    handleComment(comment);
  };
  const handleChange = (e) => {
    e.stopPropagation();
    setComment(e.target.value);
  };
  return (
    <Dialog
      classes={{ paper: 'modal-content rounded-lg modal-dark' }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="form-dialog-title">
      <DialogContent className="p-4">
        <DialogContentText style={{ color: 'black' }}>
          اكتب تعليقا
        </DialogContentText>
        {/* <DialogContentText className="mb-0"> */}
        <TextField
          variant="outlined"
          size="small"
          autoFocus
          margin="dense"
          id="input-with-icon-textfield134"
          label="التعليق"
          fullWidth
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleChange(e)}
        />
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          onClick={(e) => handleClose(e)}
          variant="text"
          className="shadow-none  ml-auto">
          الغاء
        </Button>
        <Button
          onClick={(e) => handleSubmit(e)}
          className="btn-primary shadow-none ">
          حفظ
        </Button>
      </DialogActions>
    </Dialog>
  );
}
