import { TableCell, Checkbox, TablePagination } from "@material-ui/core";

export function renderCell(params) {
  const {
    row,
    column,
    cellIndex,
    selectableRows,
    selectedRows,
    setSelectedRows,
    rowIndex,
  } = params;
  if (selectableRows && cellIndex === 0)
    return (
      <TableCell key={cellIndex} className="text-center">
        <Checkbox
          onChange={(event) => {
            if (event.target.checked)
              addSelectedRow(row, selectedRows, setSelectedRows, rowIndex);
            else removeSelectedRow(selectedRows, setSelectedRows, rowIndex);
          }}
        />
      </TableCell>
    );
  if (column.renderCell) return column.renderCell({ column, row, cellIndex });

  return createCellBasedOnType(row, column, cellIndex);
}

export function renderPagination(
  currentPage,
  onPageChange,
  paginationSettings
) {
  if (!paginationSettings) return null;

  if (!paginationSettings.total) {
    console.log("ERR: PAGINATION REQUIRES TOTAL COUNT");
    return null;
  }

  const rowsPerPage = tryGetRowsPerPage(paginationSettings);

  return (
    <TablePagination
      page={currentPage}
      count={paginationSettings.total}
      rowsPerPage={rowsPerPage}
      onPageChange={(ev, newPage) => onPageChange(newPage)}
    />
  );
}

export function selectRows(rows, page, paginationSettings) {
  if (!paginationSettings) return rows;

  const rowsPerPage = tryGetRowsPerPage(paginationSettings);

  return rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
}

function tryGetRowsPerPage(paginationSettings) {
  return "rowsPerPage" in paginationSettings
    ? paginationSettings.rowsPerPage
    : 10;
}

function addSelectedRow(row, selectedRows, setSelectedRows, rowIndex) {
  setSelectedRows([{ rowIndex, row }, ...selectedRows]);
}

function removeSelectedRow(selectedRows, setSelectedRows, rowIndex) {
  setSelectedRows(selectedRows.filter((item) => item.rowIndex !== rowIndex));
}

function createCellBasedOnType(row, column, cellIndex) {
  if (column.type === "date") {
    return (
      <TableCell key={cellIndex} className="text-center">
        <span>
          {new Date(row[column.name]).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      </TableCell>
    );
  }
  return (
    <TableCell key={cellIndex} className="text-center">
      <span>{row[column.name]}</span>
    </TableCell>
  );
}
