import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "../../style.scss";
import { Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 360,
    padding: 20,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    fontWeight: "bold",
  },
}));

export default function DetailsTabsHeaders({ tabs, setSelectedTab }) {
  const classes = useStyles();
  const [tabOpen, setTabOpen] = useState([]);
  const handleCollapse = (tab, index) => {
    setSelectedTab(tab);
    let allTabs = tabOpen.map((tab, tabIndex) =>
      tabIndex == index ? true : false
    );
    setTabOpen(allTabs);
  };
  useEffect(() => {
    if (tabs) {
      let allTabs = new Array(tabs.length).fill(false);
      allTabs[0] = true;
      setTabOpen(allTabs);
    }
  }, [tabs]);
  return (
    <Card className="card-box">
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={`${classes.root} details-sidebar`}
      >
        {tabs?.map((tab, index) => (
          <div key={index}>
            <ListItem
              key={tab.id}
              className={` sections text-center ${
                tabOpen[index] ? "color-gold" : "color-gray"
              }`}
              button
              onClick={() => {
                handleCollapse(tab, index);
              }}
            >
              <ListItemText
                classes={{
                  primary: tabOpen[index] ? classes.listItemText : null,
                }}
                primary={tab.title}
              />
            </ListItem>
            <hr style={{ width: "80%" }} />
          </div>
        ))}
      </List>
    </Card>
  );
}
