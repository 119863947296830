import React, { useEffect, useState } from "react";
import {
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  List,
  Paper,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useStaticState, Calendar } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { makeStyles } from "@material-ui/styles";
import "./index.scss";
import { connect } from "react-redux";
import {
  getAvailableSlots,
  reserveSlot,
  submitTask,
  sendNotification,
} from "reducers/details";
import _, { isEmpty } from "lodash";
import BlockUi from "react-block-ui";
import { SyncLoader } from "react-spinners";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import { setSchema } from "utils/setValidationSchema";
import { GetStepContent } from "components/FormSteps/getStepContent";
import { getValue } from "utils/GetObjectValues";

const useStyles = makeStyles({
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D5DBE0",
      borderRadius: 5,
    },
  },
});

function Slot({
  open,
  setOpen,
  availableSlots,
  task,
  slotsLoader,
  getAvailableSlots,
  reserveSlot,
  saveSlotSuccess,
  submitTask,
  taskDetails,
  sendNotification,
  currentUser,
}) {
  const [date, setDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [validationSchema, setvalidationSchema] = useState({});
  const [withinTime, setWithinTime] = useState(true);
  const [rejectionTransition, setRejectionTransition] = useState();
  useEffect(() => {
    const todayDate = new Date();
    if (task)
      setRejectionTransition(
        task.step?.transitions.find((transition) => transition.is_rejection)
      );
    setWithinTime(todayDate > new Date(task?.remaining.due_date));
  }, [task]);

  useEffect(() => {
    setvalidationSchema(
      setSchema(
        rejectionTransition?.sections?.map((section) => section.inputs).flat()
      )
    );
  }, [rejectionTransition]);

  const formik = useFormik({
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues: {},
  });
  const handleDateChange = (date) => {
    setDate(date);
    setSelectedTimeSlot({});
    getAvailableSlots({
      date: date,
      product_type: taskDetails.submission.values.product_type,
    });
  };
  const handleSlotSelect = (e, slot) => {
    e.preventDefault();
    setSelectedTimeSlot(slot);
  };
  // you can past mostly all available props, like minDate, maxDate, autoOk and so on
  const { pickerProps } = useStaticState({
    value: date,
    onChange: handleDateChange,
    classes: { root: "bold" },
  });
  const matches = useMediaQuery("(max-width:600px)");

  const classes = useStyles();

  useEffect(() => {
    if (saveSlotSuccess) {
      submitTask({
        tasks: [{ taskId: task?.id }],
        transition_id: task.step?.transitions[0]?.id,
      }).then(() => {
        setOpen(false);
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSlotSuccess]);

  useEffect(() => {
    if (taskDetails && open)
      getAvailableSlots({
        date: new Date(),
        product_type: taskDetails?.submission?.values.product_type,
      });
    setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails, open]);

  const handleSubmit = () => {
    reserveSlot({
      date,
      ...selectedTimeSlot,
      submissionId: task.submission.id,
      departmentName: task.submission.values.product_type,
      currentUser,
    });
  };

  const handleSubmitRejection = () => {
    submitTask({
      tasks: [{ taskId: task?.id, submission_edit: formik.values }],
      submission_id: task.submission.id,
      form_id: task.submission.form_id,
      transition_id: rejectionTransition.id,
    }).then(() => window.location.reload());
  };

  const handleSendRequest = () => {
    sendNotification({
      submissionId: task.submission.id,
      username: currentUser.username,
      include: [
        {
          association: "roles",
          where: {
            name: ["Manager", "Team Leader"],
          },
        },
        {
          association: "departments",
          where: {
            name: task.submission.values.product_type,
          },
        },
      ],
      message: `Request Additional Slots in ${date.toLocaleDateString()}`,
      date: new Date().toLocaleString(),
    }).then(() => {
      setOpen(false);
      Swal.fire({
        title: "Request sent, check again in few hours",
        text: "",
        icon: "success",
        confirmButtonColor: "#0066cc",
      });
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="md"
        style={{
          borderRadius: "6px",
          width: matches ? "100vw" : "45vw",
          height: "80vh",
          margin: "auto",
        }}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title2"
      >
        <DialogTitle classes={{ root: "dialog-title" }}>
          <DateRangeIcon classes={{ root: "icon-dialog-title" }} />
          Samples Submission Date
        </DialogTitle>
        <BlockUi
          className="block-loading-overlay-light"
          tag="div"
          blocking={slotsLoader}
          loader={<SyncLoader color="#4f55a28f" loading={slotsLoader} />}
        >
          {!withinTime ? (
            <DialogContent className="py-3">
              <Typography>
                Reserve a date and time slot where you are going to come and \n
                submit the lab samples..
              </Typography>
              <Grid container spacing={4} className="pt-3">
                <Grid item xs={12} sm={6}>
                  <Paper style={{ overflow: "auto" }} className={classes.list}>
                    <Calendar
                      {...pickerProps}
                      disablePast={true}
                      maxDate={
                        new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={5} className={"mt-2"} align="center">
                  <center>
                    <b>Time</b>
                    <Paper className={classes.list + " time-list"}>
                      <List
                        className={classes.list}
                        style={{ maxHeight: "50", overflow: "auto" }}
                        sx={{
                          "& ul": { padding: 0 },
                        }}
                      >
                        {Array.isArray(availableSlots) &&
                        availableSlots.length > 0 ? (
                          availableSlots?.map((slot) => (
                            <ListItem
                              key={slot.slot_start}
                              className="my-1 time-list-item"
                              onClick={(e) => handleSlotSelect(e, slot)}
                            >
                              {selectedTimeSlot.slot_start ===
                              slot.slot_start ? (
                                <span className="pb-1">&#11044; &nbsp; </span>
                              ) : (
                                <span className="pb-1">&#9711; &nbsp; </span>
                              )}
                              <span>{` ${getValue(
                                slot,
                                "slot_start",
                                "time"
                              )} : ${getValue(
                                slot,
                                "slot_end",
                                "time"
                              )}`}</span>
                            </ListItem>
                          ))
                        ) : (
                          <small>
                            "There is not available slots for this date"
                          </small>
                        )}
                      </List>
                    </Paper>
                  </center>
                </Grid>
              </Grid>
              {_.isEmpty(availableSlots) && (
                <Button
                  className="mx-3 my-3 float-center px-5 py-2"
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: "5px" }}
                  endIcon={<SendIcon />}
                  onClick={handleSendRequest}
                >
                  Request Additional Slots
                </Button>
              )}
              <Button
                className="mx-3 my-3 float-right px-5 py-2"
                size="large"
                color="primary"
                variant="contained"
                disabled={isEmpty(selectedTimeSlot)}
                style={{ borderRadius: "5px" }}
                onClick={handleSubmit}
              >
                <span className="btn-wrapper--label ml-2 font-size">
                  Confirm Dates
                </span>
              </Button>
            </DialogContent>
          ) : (
            rejectionTransition && (
              <DialogContent className="py-3">
                <Typography>
                  You have exceeded the allowed time to reserve an appointment
                  for submitting the lab samples, Please fill the following data
                  to restart the Inspection process.
                </Typography>
                <React.Fragment>
                  {GetStepContent({
                    formik,
                    activeStep: 0,
                    steps: rejectionTransition?.sections,
                    submission: task?.submission,
                  })}
                </React.Fragment>
                <Button
                  className="mx-3 my-3 float-right px-5 py-2"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={!formik.dirty || (formik.dirty && !formik.isValid)}
                  style={{ borderRadius: "5px" }}
                  onClick={handleSubmitRejection}
                >
                  <span className="btn-wrapper--label ml-2 font-size">
                    {rejectionTransition.name}
                  </span>
                </Button>
              </DialogContent>
            )
          )}
        </BlockUi>
      </Dialog>
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      availableSlots: state.details.availableSlots,
      slotsLoader: state.details.slotsLoader,
      saveSlotSuccess: state.details.saveSlotSuccess,
      taskDetails: state.details.task,
      currentUser: state.auth.user,
    };
  },
  mapDispatchToProps = (dispatch) => {
    return {
      getAvailableSlots: (data) => dispatch(getAvailableSlots(data)),
      reserveSlot: (data) => dispatch(reserveSlot(data)),
      sendNotification: (data) => dispatch(sendNotification(data)),
      submitTask: (data) => dispatch(submitTask(data)),
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(Slot);
