import { Grid } from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import { useState, useEffect } from "react";

import GridDataTable from "./utils/DataGrid";
import SpinnerLoader from "../../../components/SpinnerLoader";

const SearchPage = () => {
  const [allDrugsData, setAllDrugsData] = useState([]);

  async function fetchAllDrugsData() {
    const response = await client.post("generic/find", {
      model: "AllDrugsData",
      // limit: 8000,
      // skip: activePage * rowsPerPage,
      filters: [],
    });
    setAllDrugsData(response.data.rows);
  }

  useEffect(() => {
    fetchAllDrugsData();
  }, []);

  if (!allDrugsData.length) {
    return (
      <Grid item lg={12} md={12} xs={12}>
        <SpinnerLoader style={{ height: "300px" }} />
        <div style={{ textAlign: "center" }}>
          <small>Please wait</small>
        </div>
      </Grid>
    );
  }
  return <GridDataTable data={allDrugsData} />;
};

export default SearchPage;
