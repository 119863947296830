import client from "api/apiAuth/guestClient";
const convertHtmlToFile = async (obj) => {
  const setFile = async (value) => {
    const file = new File([value], "foo.txt", {
      type: "text/plain",
    });

    const formData = new FormData();
    formData.append("txtFile", file);

    const res = await client.post("attachment/uploadTxtFile", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  };

  for (let key in obj) {
    if (typeof obj[key] == "object") {
      if (Array.isArray(typeof obj[key])) {
        for (let item of typeof obj[key]) {
          if (typeof item == "object") {
            await convertHtmlToFile(item);
          }
        }
      } else {
        await convertHtmlToFile(obj[key]);
      }
    } else {
      if (
        typeof obj[key] == "string" &&
        (obj[key].includes("<html>") ||
          obj[key].includes("<p>") ||
          obj[key].includes("<span>"))
      ) {
        obj[key] = await setFile(obj[key]);
      }
    }
  }

  return obj;
};

export default convertHtmlToFile;
