import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  Checkbox,
  ListItemText,
  Input,
  Chip,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Info from "../Info";
import {
  getLoadMoreEvents,
  getOnchangeEvents,
} from "utils/FormUtils/Events/EventsHelperFunctions";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  customOutline: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
  },
}));

const MultiSelection = ({
  input,
  formik,
  inputValue,
  loadMore,
  onValueChange,
  inputEvents,
}) => {
  const [options, setOptions] = useState([]);
  const [uniqueOptions, setUniqueOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickOutSide, setClickOutSide] = useState(false);
  const [newMultipleValue, setNewMultipleValue] = useState([]);
  const findEventInput = () => inputEvents.find((e) => e.id === input.id);
  const classes = useStyles();
  const theme = useTheme();
  const onLoadMore = async (e) => {
    e.stopPropagation();
    setOpen(true);
    const eventInput = findEventInput();
    if (eventInput) await getLoadMoreEvents(eventInput);
  };
  const getInitialOptions = async () => {
    if (input?.itype === "enum" && input.options?.length === 0) {
      input.options = [
        { label: "Yes", value: "true", id: "Yes" },
        { label: "No", value: "false", id: "No" },
      ];
    }
    const eventInput = findEventInput();
    setOptions(eventInput ? eventInput.options : input?.options);
  };
  useEffect(() => {
    getInitialOptions();
  }, []);
  const handleChangeMultiple = (event, value) => {
    formik.setFieldValue(input.key, value);
    setNewMultipleValue({ ...newMultipleValue, value });
    const eventInput = findEventInput();

    if (eventInput)
      getOnchangeEvents(
        { ...eventInput, realKey: input?.key },
        formik,
        {
          ...newMultipleValue,
          value,
        },
        input?.key
      ).then(() => {
        setOptions(eventInput?.options);
      });
    if (onValueChange) {
      onValueChange(input, value, event);
    }
  };
  useEffect(() => {
    newMultipleValue.length > 0 &&
      formik.setFieldValue(input.key, newMultipleValue);
  }, [newMultipleValue]);

  useEffect(() => {
    const eventInput = findEventInput();
    setOptions(eventInput ? eventInput?.options : input?.options);
  }, [input, inputEvents]);
  useEffect(() => {
    options.length > 0 &&
      setUniqueOptions(
        (options.some((o) => "id" in o) && _.uniqBy(options, "id")) || options
      );
  }, [options]);
  return (
    <>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        {/* <Autocomplete
          
          id="tags-outlined"
          options={uniqueOptions ? uniqueOptions : []}
          renderOption={(o) => o.name}
          getOptionLabel={(o) => {
            return typeof o.name === "string" ? o.name : "";
          }}
          value={inputValue}
          getOptionSelected={(option, value) => option?.id === value?.id}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              label={input.name}
              required={input.required}
              placeholder="search"
            />
          )}
          onChange={(event, value) => {
          
            handleChangeMultiple(event, value);
            if (onValueChange) onValueChange(input, value);
          }}
          // ListboxProps={{
          //   style: {
          //     height: "20px",
          //   },
          // }}
        /> */}
        <Autocomplete
          multiple
          id="tags-outlined"
          options={uniqueOptions ? uniqueOptions : [{ id: "", name: "" }]}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          value={inputValue ? inputValue : []}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              label={input.name}
              required={input.required}
              placeholder="search"
            />
          )}
          onChange={(event, value) => {
            handleChangeMultiple(event, value);
            if (onValueChange) onValueChange(input, value);
          }}
          onBlur={formik.handleBlur}
        />
      </FormControl>
    </>
  );
};
const mapStateToProps = (state) => ({ inputEvents: state.form.inputEvents });
export default connect(mapStateToProps)(MultiSelection);
