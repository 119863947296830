import React from 'react'
import { SvgIcon } from "@material-ui/core";

const User = () => {
  return (
    <SvgIcon viewBox="0 0 58 58"     style={{
        enableBackground: "new 0 0 58 58",
      }}>
      <path
      style={{
        fill: "purple",
      }}
      d="m39.566 45.283-9.552-4.776A3.644 3.644 0 0 1 28 37.248V33.5c.268-.305.576-.698.904-1.162a21.964 21.964 0 0 0 2.969-5.984C33.098 25.977 34 24.845 34 23.5v-4c0-.88-.391-1.667-1-2.217V11.5s1.187-9-11-9c-12.188 0-11 9-11 9v5.783c-.609.55-1 1.337-1 2.217v4a3 3 0 0 0 1.383 2.517C12.382 30.369 15 33.5 15 33.5v3.655c0 1.333-.728 2.56-1.899 3.198L4.18 45.22A8.02 8.02 0 0 0 0 52.261V55.5h44v-3.043a8.022 8.022 0 0 0-4.434-7.174z"
    />
    <path
      style={{
        fill: "brown",
      }}
      d="m54.07 46.444-9.774-4.233c-.535-.267-.971-.836-1.277-1.453a.842.842 0 0 1 .758-1.212h6.883s2.524.242 4.471-.594c1.14-.49 1.533-1.921.82-2.936-2.085-2.969-6.396-9.958-6.535-17.177 0 0-.239-11.112-11.202-11.202-2.187.018-3.97.476-5.438 1.188.376 1.499.224 2.675.224 2.675v5.783c.609.55 1 1.337 1 2.217v4c0 1.345-.902 2.477-2.127 2.854a21.992 21.992 0 0 1-2.969 5.984c-.328.464-.636.857-.904 1.162v3.748c0 .853.299 1.659.818 2.297h2.751c.687 0 .99.868.451 1.293a21.54 21.54 0 0 1-.53.406l8.077 4.038A8.026 8.026 0 0 1 44 52.457V55.5h14v-2.697a7.108 7.108 0 0 0-3.93-6.359z"
    />
    </SvgIcon>
  )
}

export default User