import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import "./style.css";
import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PerfectScrollbar from "react-perfect-scrollbar";
const useStyles = makeStyles({
  root: {
    "& .MuiButtonBase-root": {
      transform: "TranslateY(0px) !important",
    },
  },
});

const SimilarActivIngredientsDrugsTable = (props) => {
  const classes = useStyles();
  const [similarDrugs, setSimilarDrugs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    async function fetchSimilarDrugs() {
      let genericId = [];
      for (const drug of props.task.submission.drugs) {
        for (const ingredient of drug.ingredients) {
          let { data } = await client.post("generic/find", {
            model: "GenericsView",
            where: {
              GenericName: {
                operator: "equal",
                value: ingredient.active_ingredient,
              },
            },
          });
          genericId.push(data.rows.map((ing) => ing.GenericName_ID));
        }
        genericId = genericId.sort((a, b) => a - b);
        console.log({ genericId });
        genericId = genericId.join(",");

        const drugSimilar = await client.post("generic/find", {
          model: "AllDrugsData",
          where: {
            Applicant_id: {
              operator: "equal",
              value: props.task.submission.org.id,
            },
            genericid: {
              operator: "equal",
              value: genericId,
            },
          },
        });
        setSimilarDrugs(drugSimilar.data.rows);
      }
    }

    fetchSimilarDrugs();
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid
      container
      item
      xs={props.part.style.attributes.GridSize}
      key={props.partIndex}
      className={props.part.style.classes}
    >
      <Accordion
        className="w-100"
        style={{ color: "#0066cc" }}
        classes={{ root: classes.root }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="customSummary"
          style={{ backgroundColor: "#FAF0E0" }}
        >
          <span className="tab-card-title" style={{ color: "#0066cc" }}>
            {similarDrugs.length
              ? `Similar Active Ingredients Drugs (${similarDrugs.length})`
              : "Similar Active Ingredients Drugs"}
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#FAF0E0" }}>
          <Grid container>
            <Grid item xs={12}>
              <PerfectScrollbar
                options={{
                  suppressScrollY: true,
                  useBothWheelAxes: true,
                }}
                className="displayScroll"
              >
                <Card className="card-box">
                  <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
                    <TableHead className="font-size-sm">
                      <TableRow>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Trade Code
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Trade Name
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Generics
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Status
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Dosage Form
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Registration
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Company Name
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          License Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {similarDrugs?.length > 0 &&
                        (rowsPerPage > 0
                          ? similarDrugs.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : similarDrugs
                        ).map((drug, index) => (
                          <TableRow key={drug.id}>
                            <TableCell className="text-center">
                              <p>{drug.TradeCode}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug?.Trade_name}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug?.Generics}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug?.regstatus}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Dosage_form}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Type_of_license}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Applicant_name}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.LicStatus}</p>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Card>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={similarDrugs.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={""}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </PerfectScrollbar>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SimilarActivIngredientsDrugsTable;
