import React, { useEffect } from "react";
import { Button, withWidth } from "@material-ui/core";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout, setIsLoggedIn } from "../../reducers/auth";
import _ from "lodash";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
const SidebarFooter = ({ user, redirectUrl, logout, clearStore }) => {
  let history = useHistory();
  const LogOutIcon = createSvgIcon(
    <React.Fragment>
      <path
        d="M11.4995 21.9999H15.4995C15.7755 21.9999 15.9995 21.7759 15.9995 21.4999C15.9995 21.2239 15.7755 20.9999 15.4995 20.9999H11.4995C10.6725 20.9999 9.99951 20.3269 9.99951 19.4999V14.4999C9.99951 14.2239 9.77551 13.9999 9.49951 13.9999C9.22351 13.9999 8.99951 14.2239 8.99951 14.4999V19.4999C8.99951 20.8779 10.1205 21.9999 11.4995 21.9999Z"
        fill="white"
        stroke="white"
        strokeWidth="0.342858"
      />
      <path
        d="M1.50049 11.9999H11.5005C11.7765 11.9999 12.0005 11.7759 12.0005 11.4999C12.0005 11.2239 11.7765 10.9999 11.5005 10.9999H1.50049C1.22449 10.9999 1.00049 11.2239 1.00049 11.4999C1.00049 11.7759 1.22449 11.9999 1.50049 11.9999Z"
        fill="white"
        stroke="white"
        strokeWidth="0.342858"
      />
      <path
        d="M16.9995 25C17.2195 25 17.4345 24.963 17.6375 24.891L23.6275 22.894C24.4475 22.621 24.9995 21.859 24.9995 21V3C24.9995 1.897 24.1025 1 22.9995 1C22.7775 1 22.5615 1.037 22.3605 1.11L16.3715 3.106C15.5515 3.378 14.9995 4.14 14.9995 5V23C14.9995 24.103 15.8965 25 16.9995 25ZM22.9995 2C23.5515 2 23.9995 2.449 23.9995 3V21C23.9995 21.428 23.7235 21.808 23.3115 21.946L17.3115 23.946C16.6555 24.179 15.9995 23.654 15.9995 23V5C15.9995 4.571 16.2755 4.191 16.6875 4.055L22.6875 2.055C22.7905 2.018 22.8955 2 22.9995 2V2Z"
        fill="white"
        stroke="white"
        strokeWidth="1"
      />
      <path
        d="M9.49951 9C9.77551 9 9.99951 8.776 9.99951 8.5V3.5C9.99951 2.673 10.6725 2 11.4995 2H22.9995C23.2755 2 23.4995 1.776 23.4995 1.5C23.4995 1.224 23.2755 1 22.9995 1H11.4995C10.1205 1 8.99951 2.122 8.99951 3.5V8.5C8.99951 8.776 9.22351 9 9.49951 9Z"
        fill="white"
        stroke="white"
        strokeWidth="0.342858"
      />
      <path
        d="M4.35766 14.857C4.48566 14.857 4.61366 14.808 4.71166 14.711C4.90666 14.516 4.90666 14.199 4.71166 14.004L2.20852 11.5009L4.71166 8.99774C4.90666 8.80274 4.90666 8.48574 4.71166 8.29074C4.51666 8.09574 4.19966 8.09574 4.00466 8.29074L1.14752 11.1479C0.952516 11.3429 0.952516 11.6599 1.14752 11.8549L4.00466 14.712C4.10166 14.808 4.22966 14.857 4.35766 14.857Z"
        fill="white"
        stroke="white"
        strokeWidth="0.342858"
      />
    </React.Fragment>,
    "LogOutIcon"
  );

  const logoutHandler = async () => {
    Swal.fire({
      text: "Do you want to logout?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: "#0066cc",
      denyButtonColor: "#25305338",
    }).then(function (result) {
      if (result.isConfirmed) {
        logout().then(() => {
          localStorage.removeItem("user");
          clearStore();
          // history.push(redirectUrl);
        });
      }
    });
  };

  return (
    <>
      <div
        className="app-sidebar--footer"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Button onClick={logoutHandler}>
          <LogOutIcon />
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    redirectUrl: state.auth.redirectUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    clearStore: () => dispatch({ type: "RESET_STORE", payload: null }),
    setIsLoggedIn: (data) => dispatch(setIsLoggedIn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFooter);
