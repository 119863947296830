import React, { useState, useEffect } from 'react';

import { Doughnut } from 'react-chartjs-2';

export default function DonutsChart({ submissions }) {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }
    ]
  });
  let initialLabels = [
      'الطلبات المعلقه',
      'الطلبات المرفوضه',
      'الطلبات المكتمله',
      'الطلبات المستحقة'
    ],
    backgroundColor = ['#ff9800', '#f44336', '#135313', '#4caf50'],
    submissionCount = [],
    statusTranslation = {
      Pending: { ArabicTranslation: 'الطلبات المعلقه' },
      Rejected: { ArabicTranslation: 'الطلبات المرفوضه' },
      Finished: { ArabicTranslation: 'الطلبات المنتهيه' },
      Deserved: { ArabicTranslation: 'الطلبات المستحقة' }
    };
  useEffect(() => {
    initialLabels.forEach((label) => {
      let submission = submissions?.find(
        (submission) =>
          statusTranslation[submission.status] &&
          statusTranslation[submission.status]['ArabicTranslation'] == label
      );
      if (submission) {
        submissionCount.push(submission.count);
      } else {
        submissionCount.push(0);
      }
    });

    let datasets = [
      {
        data: submissionCount,
        backgroundColor,
        hoverBackgroundColor: backgroundColor
      }
    ];
    setData({ labels: initialLabels, datasets });
  }, [submissions]);
  return (
    <>
      <Doughnut data={data} />
    </>
  );
}
