import React, { useEffect, useState } from "react";
import "./index.scss";
import imag from "../../assets/images/evers.png";
import config from "../index.js";
const ProductDataReport = ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  formik,
  group,
  editValues,
}) => {
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }
  useEffect(() => {
    setValues({
      ...config(letter, formValues, "letters", editValues),
    });
  }, []);
  toDataURL(imag);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };

  useEffect(() => {
    setValues({
      ...config(letter, formValues, "letters"),
    });
  }, [formValues]);
  useEffect(() => {
    const productDetailsReport = document.getElementById(
      "productDetailsReport"
    );
    setDivRef((divRefs) => ({ productDetailsReport, documentValues: values }));
    values.tableInputs?.map(
      (input) =>
        values[input.name] &&
        input.isRequired &&
        formik.setFieldValue(input.name, values[input.name])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, formValues]);
  return (
    <div
      style={{ padding: "20px", zoom: 0.7, color: "black" }}
      id="productDetailsReport"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            General Directorate of
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Veterinary Products
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامه للمستحضرات البيطريه
          </span>
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontWeight: "900",
            marginLeft: "40px",
            marginTop: "10px",
          }}
        >
          <p
            style={{
              width: "30%",
              border: " 2px solid",
              textAlign: "center",
            }}
          >
            Code No. FM-RVD-28
          </p>
        </div>
        <hr
          style={{
            border: 0,
            clear: "both",
            display: "block",
            width: "100%",
            backgroundColor: "#000000",
            height: "1px",
          }}
        />
      </div>
      <div
        className="row"
        style={{
          // backgroundColor: "lightgrey",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <h4 style={{ textDecoration: "underline" }}>
          Code: {letter.values.reg_number} / {new Date().getFullYear()}
        </h4>
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <table
          id="tableOfData"
          style={{
            textAlign: "left",
            marginTop: "15px",
          }}
        >
          <tbody>
            {values.tableInputs?.map(
              (input) =>
                input?.display && (
                  <tr key={input.name}>
                    <td className="">
                      <b>{input.title}</b>
                    </td>
                    <td className="mx-3">
                      {input.value ? (
                        <>{input.value}</>
                      ) : (
                        <>
                          <input
                            type="text"
                            style={{ border: "none" }}
                            name={input.name}
                            value={
                              formValues[input.name] ||
                              letter.drugs[input.name] ||
                              values[input.name]
                            }
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <div className="w-100 border-1">
        <textarea
          style={{
            width: "100%",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "500",
            fontSize: "16pt",
            direction: "rtl",
            border: "none",
          }}
          rows={3}
          value={values.tableComment}
          name="tableComment"
          autoCorrect="off"
          onChange={handleChange}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "start" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
            alignContent: "flex-start",
            flexWrap: "wrap",
            marginTop: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "14pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            مدير وحدة التقييم العلمى
          </span>
          <span
            lang="AR-SA"
            style={{
              marginTop: 20,
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
            }}
          >
            د. ماجدة مصطفي
          </span>
        </div>
      </div>

      <span
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          direction: "rtl",
          fontWeight: 900,
          alignContent: "flex-start",
          flexWrap: "wrap",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        قرار اللجنة العلمية :
      </span>

      <textarea
        value={values.decisions}
        name="decisions"
        rows="2"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          direction: "rtl",
          fontWeight: 500,
          alignContent: "flex-start",
          flexWrap: "wrap",
          minWidth: "90%",
          minHeight: "30px",
          marginTop: "5px",
          border: "none",
          width: "100%",
          fontFamily: ["Arial", "sans-serif"],
          fontSize: "7 px",
        }}
        onChange={handleChange}
      ></textarea>
      <div
        style={{
          width: "100%",
          direction: "rtl",
        }}
      >
        <table
          style={{
            textAlign: "initial",
            marginTop: "15px",
            direction: "rtl",
            border: "1px solid",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "initial",
                  marginTop: "15px",
                  direction: "rtl",
                  borderLeft: "1px solid",
                }}
              >
                <b>الفاحص:</b>
              </td>
              <td
                style={{
                  textAlign: "initial",
                  marginTop: "15px",
                  direction: "rtl",
                }}
              >
                <input
                  type="text"
                  style={{
                    border: "none",
                    textAlign: "initial",
                    direction: "rtl",
                  }}
                  name="inspector"
                  value={
                    formValues["inspector"] ||
                    letter.drugs["inspector"] ||
                    values["inspector"]
                  }
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </td>
            </tr>
            <tr style={{ borderTop: "1px solid" }}>
              <td
                style={{
                  textAlign: "initial",
                  marginTop: "15px",
                  direction: "rtl",
                  borderLeft: "1px solid",
                  borderTop: "1px solid",
                }}
              >
                <b>اعضاء اللجنة:</b>
              </td>
              <td
                style={{
                  textAlign: "initial",
                  marginTop: "15px",
                  direction: "rtl",
                  borderTop: "1px solid",
                }}
              >
                <textarea
                  rows="2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    direction: "rtl",
                    fontWeight: 500,
                    alignContent: "flex-start",
                    flexWrap: "wrap",
                    minWidth: "90%",
                    minHeight: "30px",
                    marginTop: "5px",
                    border: "none",
                    width: "100%",
                    fontFamily: ["Arial", "sans-serif"],
                    fontSize: "9.6 px",
                  }}
                  name="teams"
                  value={
                    formValues["teams"] ||
                    letter.drugs["teams"] ||
                    values["teams"]
                  }
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "15px",
          direction: "rtl",
          textAlign: "initial",
        }}
      >
        تحرير في :{" "}
        {new Date(group?.values?.start_date).toLocaleDateString("ar-EG", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
          marginTop: "15%",
        }}
      />
      <div
        style={{
          position: "relative",
          left: "0",
          bottom: "0",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <h6 style={{ textAlign: "left" }}>
            21 Abd-Elaziz Al So'od st. Manial El-Roda ,Cairo ,Egypt
          </h6>
          <h6 style={{ textAlign: "left" }}>
            Tel.& Fax:+202-23610497 / +202-23610498
          </h6>
        </div>
        <div>
          <h6 style={{ textAlign: "right" }}>
            <span>عبدالعزيز آل سعود -منيل الروضة - القاهرة - مصر</span>
            <span style={{ visibility: "hidden" }}>a</span>

            <span>٢١</span>
          </h6>
          <h6 style={{ textAlign: "right", display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <div>+</div>
                <div>٢٠٢&nbsp;</div>
                <div>٢٣٦١٠٤٩٨</div>
              </div>
              <div className="mx-2">/</div>
              <div style={{ display: "flex" }}>
                <div>+</div>
                <div>٢٠٢&nbsp;</div>
                <div>٢٣٦١٠٤٩٧ </div>
              </div>
            </div>
            <div className="mx-2">:</div>
            <div>تليفون وفاكس </div>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ProductDataReport;
