export const config = (mapper) => {
  return [
    {
      tabId: 2,
      sectionId: 1,
      attachments: [
        {
          label: "doctor_engagements_file",
          type: "document",
          equivalent_key: "doctor_engagements_file",
          majorReviewers: ["Unit Manager Naming"],
          minorReviewers: ["Junior Naming", "Senior Naming"],
          underRevision: true,
          passWithRejection: true,
          style: { gridSize: 4, display: true },
        },
      ],
    },

    {
      tabId: 3,
      sectionId: 1,
      attachments: [
        {
          label: "doctor_engagements_file",
          type: "document",
          equivalent_key: "doctor_engagements_file",
          majorReviewers: ["Unit Manager Naming"],
          minorReviewers: ["Junior Naming", "Senior Naming"],
          underRevision: true,
          passWithRejection: true,
          style: { gridSize: 4, display: true },
        },
      ],
    },

    {
      tabId: 4,
      sectionId: 1,
      attachments: [],
    },
    {
      tabId: 5,
      sectionId: 1,
      attachments: [],
    },

    {
      tabId: 6,
      sectionId: 1,
      attachments: [],
    },
    {
      tabId: 7,
      sectionId: 1,
      attachments: [],
    },
    {
      tabId: 8,
      sectionId: 1,
      attachments: [],
    },
  ];
};
export { config as documentsConfig };
