import Comments from "pages/EdaDetails/components/Comments";
import DetailsTabs from "pages/EdaDetails/components/DetailsTabs";
import SubmissionInfo from "pages/EdaDetails/components/SubmissionInfo";
import TaskTimeLine from "pages/EdaDetails/components/TaskTimeLine";
import GroupsTable from "pages/EdaDetails/components/GroupsTable/GroupsTable";

import CustomComps from "../components";

const detailsConfig = (mapper) => [
	{
		component: SubmissionInfo,
		componentProps: [
			"task",
			"setModalInfo",
			"openModal",
			"setConfirmationModal",
			"setSlotsModal",
			"takeActionFlag",
		],
		name: "basic information",
		style: {
			cardGridSize: 12,
			attributes: { GridSize: 6 },
			actions: { GridSize: 6 },
			classes: "",
		},
	},
	{
		component: Comments,
		componentProps: [],
		name: "Comments",
		style: {
			cardGridSize: 12,
			attributes: { GridSize: 6 },
			actions: { GridSize: 6 },
			classes: "",
		},
	},
	{
		component: DetailsTabs,
		componentProps: ["task", "setTakeActionFlag", "takeActionFlag"],
		title: "Details Tabs",
		style: {
			cardGridSize: 12,
			attributes: { GridSize: 6 },
			actions: { GridSize: 6 },
			classes: "edaDetails",
		},
	},
	{
		component: TaskTimeLine,
		componentProps: ["submission_id"],
		title: "Timeline",
	},
];

export { detailsConfig as Config };
