import React from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
const AssignModal = (props) => {
  const {
    openModal,
    setOpenModal,
    users,
    task,
    selectedUser,
    handleChange,
    handleAssign,
    loading,
    setLoading,
  } = props;
  return (
    <Grid item sm={6} container>
      <Dialog
        classes={{ paper: "modal-content" }}
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={() => {
          setLoading(false);
          setOpenModal(false);
        }}
        aria-labelledby="form-dialog-title2"
        style={{
          borderRadius: "6px",
          width: "60%",
          margin: "auto",
          height: "120%",
        }}
      >
        <DialogTitle>
          <span className="titleModal">
            {task?.is_assigned ? "Reassign" : "Assign"}
          </span>
        </DialogTitle>
        <DialogContent className="p-4">
          <Grid item md={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                {task?.is_assigned ? "Reassign" : "Assign"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedUser || (task?.is_assigned && task?.users[0].id)}
                onChange={handleChange}
                className="mt-0"
                label={task?.is_assigned ? "Reassign" : "Assign"}
              >
                {users?.map((user) => (
                  <MenuItem
                    value={user?.id}
                    key={user.id}
                    disabled={user.id === task?.users[0].id}
                    selected={user.id === task?.users[0].id}
                  >
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="assignButton pt-1 pb-1 pl-2 pl-2 pr-2"
            onClick={handleAssign}
            autoFocus
            size="small"
            disabled={selectedUser && !loading ? false : true}
          >
            {task?.is_assigned ? "Reassign" : "Assign"}
            {loading && <ClipLoader color="#fff" loading={true} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AssignModal;
