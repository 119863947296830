import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import Routes from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";
import "./assets/base.scss";
import ErrorModal from "components/error/ErrorModal";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import rtl from "jss-rtl";
import SpinnerLoader from "components/SpinnerLoader";
import { create } from "jss";
import { checkLogin } from "reducers/auth";
import { setErrorModalClicked } from "reducers/error";
import { createBrowserHistory } from "history";
import { registerEventsFromBackend } from "utils/we-flow/EventSystem";
import client from "api/apiAuth/guestClient";
import { GoogleOAuthProvider } from "@react-oauth/google";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const history = createBrowserHistory();
const App = ({ error, loginChecked, checkLogin, onErrorModalOkClick }) => {
  useEffect(async () => {
    checkLogin();
    const response = await client.get("generic/getFrontendEvents");
    registerEventsFromBackend(response.data);
  }, []);
  useEffect(() => {
    history.push(error.page.pathname);
  }, [error]);
  return loginChecked ? (
    <StylesProvider jss={jss}>
      <></>
      {/* Required for MUI datepickers */}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <GoogleOAuthProvider
          clientId={
            process.env.REACT_APP_GOOGLE_API ||
            "589841684778-1u1pcu4hp1250763vr1trk1aplh24nos.apps.googleusercontent.com"
          }
        >
          <Router history={history}>
            <ScrollToTop>
              <Routes />
              <ErrorModal
                error={error.message ? error : null}
                onModalOkClick={() => {
                  onErrorModalOkClick(!error.modalButtonClicked);
                }}
              />
            </ScrollToTop>
          </Router>
        </GoogleOAuthProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  ) : (
    <SpinnerLoader />
  );
};
function mapStateToProps(state) {
  const { error } = state;
  return { error, loginChecked: state.auth.loginChecked };
}
const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => dispatch(checkLogin()),
    onErrorModalOkClick: (flag) => dispatch(setErrorModalClicked(flag)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
