import { createReducer } from "@reduxjs/toolkit";

import {
  INCREASE_REQUESTS_NO,
  DECREASE_REQUESTS_NO,
  SET_REQUESTS_TOKEN,
} from "./generalActionTypes";

const initialState = {
  requestsNo: 0,
  requestsCancelToken: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(INCREASE_REQUESTS_NO, (state, action) => {
    state.requestsNo = state.requestsNo + 1;
  });
  builder.addCase(DECREASE_REQUESTS_NO, (state, action) => {
    state.requestsNo = state.requestsNo - 1;
  });
  builder.addCase(SET_REQUESTS_TOKEN, (state, action) => {
    let params = action.payload;
    state.requestsCancelToken = [
      ...state.requestsCancelToken,
      params,
    ];
  });
});
export default reducer;
