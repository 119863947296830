import client from "api/apiAuth/guestClient";
import _ from "lodash";
import { getActualName } from "utils/FormUtils/Events/EventsService";

export const fillCustomData = async (input, html, formik, isNew) => {
  let newHtml = html;
   if (isNew) {
			const { data } = await client.get("Submission/getNextSubmissionNumber");
			const serialNumberId = 'id="event_code">';
			const oldSerialNumber = getActualName(newHtml, serialNumberId);
			if (oldSerialNumber) {
				newHtml = newHtml.replaceAll(oldSerialNumber, data);
			}
		}
  if (input.id == 17) {
    if (formik.values.event_date_from) {
      const eventDateId = 'id="event_start_date">';
      const oldEventDate = getActualName(newHtml, eventDateId);
      if (oldEventDate) {
        newHtml = newHtml.replaceAll(
          oldEventDate,
          new Date(formik.values.event_date_from).toISOString().split("T")[0]
        );
      }
    }

    if (formik.values.event_date_to) {
      const eventDateId = 'id="event_end_date">';
      const oldEventDate = getActualName(newHtml, eventDateId);
      if (oldEventDate) {
        newHtml = newHtml.replaceAll(
          oldEventDate,
          new Date(formik.values.event_date_to).toISOString().split("T")[0]
        );
      }
    }
    if (formik.values.event_name) {
			const eventNameId = 'id="engegement_event_name">';
			const oldEventName = getActualName(newHtml, eventNameId);
			if (oldEventName) {
				newHtml = newHtml.replaceAll(oldEventName, formik.values.event_name);
			}

		// 	const { data } = await client.get("Submission/getNextSubmissionNumber");
		// 	const serialNumberId = 'id="event_code">';
		// 	const oldSerialNumber = getActualName(newHtml, serialNumberId);
		// 	if (oldSerialNumber) {
		// 	  newHtml = newHtml.replaceAll(oldSerialNumber, data);
		// 	}
		}

    if (formik.values.event_objective) {
      const eventObjId = 'id="event_objective">';
      const oldEventObj = getActualName(newHtml, eventObjId);
      if (oldEventObj) {
        newHtml = newHtml.replaceAll(
          oldEventObj,
          formik.values.event_objective
        );
      }
    }

    if (formik.values.hcp_country) {
      const hcpCountryId = 'id="hcp_country">';
      const oldHcpCountry = getActualName(newHtml, hcpCountryId);
      if (oldHcpCountry) {
        newHtml = newHtml.replaceAll(oldHcpCountry, formik.values.hcp_country);
      }
    }

    if (formik.values.venue) {
      const eventVenueId = 'id="engegement_venue">';
      const oldEventVenue = getActualName(newHtml, eventVenueId);
      if (oldEventVenue) {
        newHtml = newHtml.replaceAll(oldEventVenue, formik.values.venue);
      }
    }

    if (formik.values.first_approver) {
      const { data } = await client.get("generic/find", {
        params: {
          model: "User",
          filter: {
            id: formik.values.first_approver,
          },
        },
      });
      const approver1Id = 'id="approver1">';
      const oldapprover1 = getActualName(newHtml, approver1Id);
      if (oldapprover1) {
        newHtml = newHtml.replaceAll(
          oldapprover1,
          `${data.rows[0].first_name} ${data.rows[0].last_name}`
        );
      }
    }

    if (formik.values.second_approver) {
      const { data } = await client.get("generic/find", {
        params: {
          model: "User",
          filter: {
            id: formik.values.second_approver,
          },
        },
      });
      const approver2Id = 'id="approver2">';
      const oldapprover2 = getActualName(newHtml, approver2Id);
      if (oldapprover2) {
        newHtml = newHtml.replaceAll(
          oldapprover2,
          `${data.rows[0].first_name} ${data.rows[0].last_name}`
        );
      }
    }
    if (formik.values.modification) {
      let { values: formikValues } = formik;
      if (formikValues.values.event_date_to) {
				const eventDateId = 'id="event_end_date">';
				const oldEventDate = getActualName(newHtml, eventDateId);
				if (oldEventDate) {
					newHtml = newHtml.replaceAll(
						oldEventDate,
						new Date(formikValues.values.event_date_to)
							.toISOString()
							.split("T")[0]
					);
				}
			}

			if (formikValues.values.file_code) {
				const serialNumberId = 'id="event_code">';
				const oldSerialNumber = getActualName(newHtml, serialNumberId);
				if (oldSerialNumber) {
					newHtml = newHtml.replaceAll(
						oldSerialNumber,
						formikValues.values.file_code
					);
				}
			}
      if (formikValues.values.event_name) {
        const eventNameId = 'id="engegement_event_name">';
        const oldEventName = getActualName(newHtml, eventNameId);
        if (oldEventName) {
          newHtml = newHtml.replaceAll(oldEventName, formikValues.values.event_name);
        }
      }
  
      if (formikValues.values.event_objective) {
        const eventObjId = 'id="event_objective">';
        const oldEventObj = getActualName(newHtml, eventObjId);
        if (oldEventObj) {
          newHtml = newHtml.replaceAll(
            oldEventObj,
            formikValues.values.event_objective
          );
        }
      }
   if (formikValues.values.venue) {
        const eventVenueId = 'id="engegement_venue">';
        const oldEventVenue = getActualName(newHtml, eventVenueId);
        if (oldEventVenue) {
          newHtml = newHtml.replaceAll(oldEventVenue, formikValues.values.venue);
        }
      }
      if (formikValues.values.first_approver) {
        const { data } = await client.get("generic/find", {
          params: {
            model: "User",
            filter: {
              id: formikValues.values.first_approver,
            },
          },
        });

        const eventApprover1Id = 'id="approver1">';
        const oldEventApprover1 = getActualName(newHtml, eventApprover1Id);
        if (oldEventApprover1) {
          newHtml = newHtml.replaceAll(
            oldEventApprover1,
            `${data.rows[0].first_name} ${data.rows[0].last_name}`
          );
        }
      }
      if (formikValues.values.second_approver) {
        const { data } = await client.get("generic/find", {
          params: {
            model: "User",
            filter: {
              id: formikValues.values.second_approver,
            },
          },
        });

        const eventApprover2Id = 'id="approver2">';
        const oldEventApprover2 = getActualName(newHtml, eventApprover2Id);
        if (oldEventApprover2) {
          newHtml = newHtml.replaceAll(
            oldEventApprover2,
            `${data.rows[0].first_name} ${data.rows[0].last_name}`
          );
        }
      }
    }
  }

  return newHtml;
};
