import {
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import CreateQueue from "pages/SysSettings/components/CustomSettings/components/CreateQueue";
import React, { useState, useEffect } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Swal from "sweetalert2";

const QueueSetting = (props) => {
  const [queueList, setQueueList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});

  const [page, setPage] = useState(0);

  const getSystemQueues = async () => {
    let { data } = await client.get("/queue/getqueues");
    let { queues } = data;
    setQueueList(queues);
  };

  useEffect(() => {
    if (open == false) getSystemQueues();
  }, [open]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const deleteQueue = async (queueId) => {
    try {
      Swal.fire({
        title: "Are you sure you want to delete the Queue ? ",
        text: `Deleting this queue will move all the submissions inside to the not assigned tasks`,
        showConfirmButton: true,
        titleHeight: 50,
        showCancelButton: true,
        confirmButtonClass: "submit",
        cancelButtonClass: "cancel",
        titleClass: "fontSize",
        customClass: "swal-width",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        closeOnConfirm: false,
      }).then(function (isConfirm) {
        if (isConfirm.isConfirmed) {
          client.post(`/queue/delete`, { queueId }).then(() => {
            Swal.fire({
              title: "Succeeded",
              text: "Queue data deleted successfully",
              icon: "success",
              timer: 3000,
            }).then(() => {
              getSystemQueues();
            });
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const stopQueue = async (queueId) => {
    Swal.fire({
      title: "Are you sure you want to stop the Queue ? ",
      text: `Stopping this queue will move all the submissions inside to the not assigned tasks`,
      showConfirmButton: true,
      titleHeight: 50,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-width",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        client.post(`/queue/stopqueue`, { queueId }).then(() => {
          Swal.fire({
            title: "Succeeded",
            text: "Queue stopped successfully",
            icon: "success",
            timer: 3000,
          }).then(() => {
            getSystemQueues();
          });
        });
      }
    });
  };
  const resumeQueue = async (queueId) => {
    await client.post(`/queue/resumequeue`, { queueId });
    Swal.fire({
      title: "Succeeded",
      text: "Queue resumed successfully",
      icon: "success",
      timer: 3000,
    }).then(() => {
      getSystemQueues();
    });
  };
  const editQueue = (queue) => {
    setValues(queue);
    setIsEdit(true);
    setOpen(true);
  };
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            className="mx-1 px-5 py-2"
            size="large"
            color="primary"
            variant="contained"
            style={{ borderRadius: "5px", float: "right" }}
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
            }}
          >
            Create Queue
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card className="card-box">
            <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
              <TableHead className="font-size-sm">
                <TableRow>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Step
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Start Date
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    End Date
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Criteria
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Step Limit
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Queue Admin
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Queue Status
                  </TableCell>
                  <TableCell className="text-center bg-color-darkBlue pt-2">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {queueList?.length > 0 &&
                  (rowsPerPage > 0
                    ? queueList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : queueList
                  ).map((queue, index) => (
                    <TableRow>
                      <TableCell className="text-center">
                        <p>{queue?.step.name}</p>
                      </TableCell>
                      <TableCell className="text-center">
                        <p>
                          {new Date(queue?.start_date).toLocaleDateString(
                            "en-CA"
                          )}
                        </p>
                      </TableCell>
                      <TableCell className="text-center">
                        <p>
                          {new Date(queue?.end_date).toLocaleDateString(
                            "en-CA"
                          )}
                        </p>
                      </TableCell>
                      <TableCell className="text-center">
                        <p>{Object.keys(queue.criteria)[0]}</p>
                      </TableCell>
                      <TableCell className="text-center">
                        <p>
                          {queue?.criteria[Object.keys(queue?.criteria)[0]]}
                        </p>
                      </TableCell>
                      <TableCell className="text-center">
                        <p>{queue?.assignee?.role}</p>
                      </TableCell>
                      <TableCell className="text-center">
                        <p>{queue?.status}</p>
                      </TableCell>
                      <TableCell className="text-center">
                        <Button
                          onClick={() => {
                            deleteQueue(queue.id);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                        <Button
                          onClick={() => {
                            editQueue(queue);
                          }}
                        >
                          <EditIcon />
                        </Button>
                        {queue.status == "active" ? (
                          <Button
                            onClick={() => {
                              stopQueue(queue.id);
                            }}
                          >
                            <StopIcon />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              resumeQueue(queue.id);
                            }}
                          >
                            <PlayArrowIcon />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Card>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={queueList.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={""}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        {open && (
          <CreateQueue
            open={open}
            setOpen={setOpen}
            values={values}
            isEdit={isEdit}
          />
        )}
      </Grid>
    </div>
  );
};
export default QueueSetting;
