import { parse, format, add } from "date-fns";
import _ from "lodash";

export function parseWorkingDays(workingDays) {
  for (const day of workingDays) {
    day.startTime = parse(day.startTime, "HH:mm:ss", new Date());
    if (isNaN(day.startTime)) day.startTime = new Date();
    day.endTime = parse(day.endTime, "HH:mm:ss", new Date());
    if (isNaN(day.endTime)) day.endTime = new Date();
  }
  return workingDays;
}

export function formatWorkingDays(workingDays) {
  for (const day of workingDays) {
    day.startTime = format(day.startTime, "HH:mm:ss");
    day.endTime = format(day.endTime, "HH:mm:ss");
  }
  return workingDays;
}

export function validateWorkingDays(workingDays, duration) {
  const errors = {};
  for (let i = 0; i < workingDays.length; i++) {
    if (!workingDays[i].checked) continue;
    const day = workingDays[i];
    if (day.startTime.getHours() === 0 && day.startTime.getMinutes() === 0)
      _.set(errors, `workingDays.${i}.startTime`, "12:00 AM not allowed");
    if (day.endTime.getHours() === 0 && day.endTime.getMinutes() === 0)
      _.set(errors, `workingDays.${i}.endTime`, "12:00 AM not allowed");
    if (add(day.startTime, { minutes: duration }) > day.endTime)
      _.set(
        errors,
        `workingDays.${i}.startTime`,
        "From time + duration must be less than or equal than to time"
      );
  }
  return errors;
}
