import React, { Component } from "react";
import { IconButton, Popover } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import styles from "./index.module.css";

class AudioRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInMs: 0,
      recording: false,
      mediaNotFound: false,
      audioBlob: null,
      stream: null,
      audio: props.audio,
      anchorEl: null,
      message: null,
    };
    this.maxTimeLength = props.maxTimeLength ? props.maxTimeLength : 10;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.mimeType = props.mimeType ? props.mimeType : "audio/webm;codecs:vp9";
    this.browserSupported = MediaRecorder.isTypeSupported(this.mimeType);
    this.audioType = props.audioType ? props.audioType : "audio/webm";

    if (props.listenOnly && !props.audio)
      console.warn("AudioRecorder: Audio missing");
  }

  componentDidUpdate(prevProps) {
    if (this.props.audio !== prevProps.audio)
      this.setState({ audio: this.props.audio });
    if (
      this.state.timeInMs >= this.maxTimeLength * 1000 &&
      this.state.recording
    ) {
      this.stopRecording();
    }
  }

  startTimer() {
    this.timer = setInterval(this.countDown, 100);
  }

  countDown() {
    this.setState((prevState) => {
      const timeInMs = prevState.timeInMs + 100;
      return {
        timeInMs,
      };
    });
  }

  async initRecorder() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (this.props.mimeType) {
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: this.props.mimeType,
        });
      } else {
        this.mediaRecorder = new MediaRecorder(stream);
      }
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };

      this.stream = stream;
      return true;
    } else {
      this.setState({ mediaNotFound: true });
      return false;
    }
  }

  async startRecording(e) {
    if (!this.browserSupported) {
      this.setState({
        anchorEl: e.currentTarget,
        message: "Unsupported browser",
      });
      return;
    }

    this.setState({ timeInMs: 0 });
    this.chunks = [];
    const initSuccess = await this.initRecorder();
    if (!initSuccess) {
      this.setState({
        anchorEl: e.currentTarget,
        message: "No microphone found",
      });
      return;
    }
    this.mediaRecorder.start(10);
    this.startTimer();
    this.setState({ recording: true });
  }

  stopRecording(e) {
    clearInterval(this.timer);
    this.setState({ time: {} });

    if (this.stream.getAudioTracks) {
      const tracks = this.stream.getAudioTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }

    this.mediaRecorder.stop();
    this.setState({ recording: false });
    this.saveAudio();
  }

  saveAudio() {
    const blob = new Blob(this.chunks, { type: this.audioType });
    const audioURL = window.URL.createObjectURL(blob);
    this.setState({ audio: audioURL, audioBlob: blob });

    this.props.onAudioRecord({
      url: audioURL,
      blob: blob,
    });
  }

  playAudio(e) {
    if (!this.browserSupported) {
      this.setState({
        anchorEl: e.currentTarget,
        message: "Unsupported browser",
      });
      return;
    }
    const audio = new Audio(this.state.audio);
    audio.play();
  }

  render() {
    const mainContent = !this.props.listenOnly ? (
      <>
        {!this.state.recording && (
          <IconButton
            variant="contained"
            classes={{ root: styles.buttonBase }}
            onClick={(e) => this.startRecording(e)}
          >
            <MicIcon size="large" classes={{ root: styles.buttonText }} />
          </IconButton>
        )}

        {this.state.recording && (
          <IconButton
            variant="contained"
            classes={{ root: styles.buttonBase }}
            onClick={(e) => this.stopRecording(e)}
          >
            <StopIcon size="large" classes={{ root: styles.buttonText }} />
          </IconButton>
        )}

        {this.state.audio && (
          <IconButton
            variant="contained"
            classes={{ root: styles.buttonBase }}
            onClick={(e) => this.playAudio(e)}
            className="ml-2"
          >
            <PlayArrowIcon size="large" classes={{ root: styles.buttonText }} />
          </IconButton>
        )}
      </>
    ) : (
      <>
        {this.state.audio && (
          <IconButton
            variant="contained"
            classes={{ root: styles.buttonBase }}
            onClick={(e) => this.playAudio(e)}
            className="ml-2"
          >
            <PlayArrowIcon size="large" classes={{ root: styles.buttonText }} />
          </IconButton>
        )}
      </>
    );

    return (
      <div className="d-flex">
        {mainContent}
        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {this.state.message || "An unexpected error occured"}
        </Popover>
      </div>
    );
  }
}

export default AudioRecorder;
