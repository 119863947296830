import React from "react";
import Documents from "../Documents";
const {
  documentContentConfig,
} = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/documentContent`);

function DocumentContent({
  selectedTab,
  task,
  index,
  initialValues,
  setInitialValues,
  schemaInputs,
  setSchemaInputs,
  formik,
}) {
  let sections = documentContentConfig
    .config({})
    .find((tab) => tab.tabId == selectedTab.id).sections;
  return (
    <React.Fragment key={index}>
      {sections.map((section, sectionIndex) => {
        return (
          <React.Fragment key={sectionIndex}>
            {section.title && (
              <h3 className="details-header padding">
                {`${selectedTab?.id}.${section.id}${section.title}`}
              </h3>
            )}

            <div>
              <Documents
                task={task}
                selectedTab={selectedTab}
                sectionId={section.id}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                schemaInputs={schemaInputs}
                setSchemaInputs={setSchemaInputs}
                formik={formik}
              />
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default DocumentContent;
