//0 ---> My Assigned Task
//1 ----> Not assigned Task
//2 ----> Ongoing Task
//3 ----> Completed Task

const menuObj = {
  worklist: {
    shared: [],
    User: [
      {
        id: 0,
        name: "My Assigned Task",
        order: 1,
        key: "id",
      },
      {
        id: 2,
        name: "Ongoing Task",
        order: 3,
        key: "id",
      },

      {
        id: 3,
        name: "Completed Task",
        order: 5,
        key: "id",
      },
      {
        id: 4,
        name: "All Events",
        order: 6,
        key: "id",
      },
      {
        id: 8,
        name: "All Modifications",
        order: 7,
        key: "submission.id",
      },
      {
        id: 17,
        name: "Finished Events",
        order: 8,
        key: "id",
      },
    ],
    Financial: [
      // {
      //   id: 3,
      //   name: "Completed Task",
      //   order: 5,
      //   key: "id",
      // },
      {
        id: 4,
        name: "All Events",
        order: 6,
        key: "id",
      },
      {
        id: 17,
        name: "Finished Events",
        order: 7,
        key: "id",
      },
    ],
    Admin: [
      {
        id: 18,
        name: "My Assigned Task",
        order: 1,
        key: "id",
      },
      {
        id: 4,
        name: "All Events",
        order: 2,
        key: "id",
      },
      {
        id: 17,
        name: "Finished Events",
        order: 3,
        key: "id",
      },
    ],
    Approver: [
      {
        id: 0,
        name: "My Assigned Task",
        order: 1,
        key: "id",
      },
      {
        id: 16,
        name: "My Assigned Modifications",
        order: 2,
        key: "id",
      },

      {
        id: 2,
        name: "Ongoing Task",
        order: 3,
        key: "id",
      },

      {
        id: 3,
        name: "Completed Task",
        order: 5,
        key: "id",
      },
      {
        id: 4,
        name: "All Events",
        order: 6,
        key: "id",
      },
      {
        id: 17,
        name: "Finished Events",
        order: 7,
        key: "id",
      },
    ],
  },
};
export default menuObj;
