import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Popover,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TableCell,
} from "@material-ui/core";

import Swal from "sweetalert2";
import DocumentGeneration from "pages/SubmissionGroupDetails/Components/DocumentGeneration";
import GroupSectionModal from "pages/SubmissionGroupDetails/Components/GroupSectionModal";
import client from "api/apiAuth/guestClient";
import { handleError } from "api/utilities";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: "#0066cc",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
  },
  gold: {
    backgroundColor: "#0066cc",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  root: {
    "& MuiPaper-root MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded": {
      width: "500px",
    },
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D5DBE0",
      borderRadius: 5,
    },
  },
}));

const ActionsComponent = (props) => {
  const { row, group, onSingleSubmissionActionSelect, user } = props;
  const [anchorEl1, setAnchorEl1] = useState(null);

  const open1 = Boolean(anchorEl1);
  const classes = useStyles();
  const deleteSubmissionFromGroup = async (event, row) => {
    Swal.fire({
      title: "Are you sure you want to delete the Submission from this group? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        await client
          .post("submissiongroup/deleteSubmissionFromGroup", {
            group_id: row.GroupSubmissions.group_id,
            submission_id: row.GroupSubmissions.submission_id,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title: "Success",
                text: "submission deleted from group successfully",
                icon: "success",
                timer: 3000,
              }).then(() => window.location.reload());
            }
          })
          .catch((error) => {
            console.log(error);
            handleError(error);
          });
      }
    });
  };
  const handleClick1 = (event) => {
    event.stopPropagation();
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = (event) => {
    setAnchorEl1(null);
  };

  let allowedActions = [];
  if (row.id in group.availableActions.perSubmission) {
    allowedActions = group.availableActions.perSubmission[row.id].filter(
      (item) =>
        item.action.attributes.target === "submission" &&
        !item.action.attributes.multi
    );
  }

  return (
    <>
      <TableCell className="text-center">
        <div className="d-flex align-items-center popover-header-wrapper">
          <span className="pr-2">
            <IconButton onClick={handleClick1}>
              <MoreHorizIcon color="primary" />
            </IconButton>
          </span>
          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              position: "absolute",
              maxWidth: "700px",
            }}
            disableScrollLock={false}
          >
            <div className="popover-custom-lg">
              <Divider />
              <Paper
                className={classes.list}
                style={{
                  width: "380px",
                  overflow: "auto",
                }}
              >
                <List>
                  {allowedActions.map((action) => (
                    <ListItem
                      className="notificationItem"
                      button
                      key={action.action.id}
                      onClick={() =>
                        onSingleSubmissionActionSelect(row.id, action.action)
                      }
                    >
                      <ListItemText primary={action.action.title} />
                    </ListItem>
                  ))}
                  {user.roles.some((role) =>
                    group.groupType.config.rolesAllowedToCreate.includes(
                      role.name
                    )
                  ) &&
                    allowedActions.length > 0 && (
                      <ListItem
                        className="notificationItem"
                        button
                        onClick={(e) => deleteSubmissionFromGroup(e, row)}
                      >
                        <ListItemText primary={"Delete Submission"} />
                      </ListItem>
                    )}
                </List>
              </Paper>
            </div>
          </Popover>
        </div>
      </TableCell>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ActionsComponent);
