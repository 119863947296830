import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Button,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { handleDraftAssignment } from "reducers/form/formActions";
import { getUsers } from "reducers/details/detailsAction";
import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { handleTaskAssignment } from "../../reducers/details/detailsAction";
import { handleError } from "api/utilities";

const SEND_AR_STRING = "موافقة";
const DEQUEUE_EN_STRING = "Get out of the queue";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
function TableToolbar(props) {
  const classes = useToolbarStyles();
  const {
    selectedRows,
    history,
    setSubmitTaskLoader,
    user,
    currentTab,
    users,
    getUsers,
    handleTaskAssignment,
    handleDraftAssignment,
    checkTab,
  } = props;
  let numSelected = selectedRows.length;
  const [selectedUser, setSelectedUser] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  let userRoles = user && user.roles.map((role) => role.name);

  const submitMultipleTasks = (filter, responseMessage, responseIcon) => {
    client.post("workflow/submittask", filter).then((res) => {
      setSubmitTaskLoader(false);
      Swal.fire({
        text: responseMessage,
        icon: responseIcon,
      })
        .then(function (result) {
          history.push("/worklist");
        })
        .catch((error) => {
          handleError(error);
        });
    });
  };
  const reassingTasks = () => {
    const selectedTasks = selectedRows.map((row) => row.taskId);
    if ([5, 7, 8, 9, 13, 11, 12].includes(currentTab)) {
      //reassign tasks
      handleTaskAssignment({
        filter: {
          task_status: "Pending",
        },
        user_id: selectedUser,
        tasks: selectedTasks,
      })
        .then(() => {
          setSubmitTaskLoader(false);
          Swal.fire({
            title: "Done",
            text: "re-assigned successfully",
            icon: "success",
            timer: 3000,
          });
        })
        .catch((error) => {
          handleError(error);
        });
    } else if ([6].includes(currentTab)) {
      //reassign drafts
      handleDraftAssignment(
        {
          user_id: selectedUser,
        },
        { draft_ids: selectedTasks }
      )
        .then(() => {
          setSubmitTaskLoader(false);
          Swal.fire({
            title: "تم",
            text: "لقد تم إعادة التعيين بنجاح ",
            icon: "success",
            timer: 3000,
          });
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };
  const handleSendRequest = () => {
    setSubmitTaskLoader(true);
    if (userRoles.length > 0 && userRoles.includes("manager")) {
      reassingTasks();
    } else {
      submitMultipleTasks(
        {
          transition_id: 13,
          tasks: selectedRows,
          assignTasksBeforeSubmit: true,
        },
        "موافقه وإرسال إلى اﻻدارة المالية والارشيف",
        "success"
      );
    }
  };
  const handleDeQueue = () => {
    setSubmitTaskLoader(true);
    client
      .post("submission/dequeueSubmissions", {
        selectedRows: selectedRows,
      })
      .then((res) => {
        setSubmitTaskLoader(false);
        Swal.fire({
          text: "submissions are out of queue",
          icon: "success",
        });
        // TODO: Refactor this into a proper react state update
        window.location.reload();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getAllUsers = async (roleId) => {
    const res = await client.get("workflow/getMultiDistributedUsers", {
      params: {
        include: {
          association: "roles",
          where: {
            id: roleId,
          },
        },
        model: "User",
      },
    });
    setAllUsers(res.data.result);
  };
  useEffect(() => {
    if (currentTab === 8) {
      getAllUsers(2);
    } else if (currentTab === 9) {
      getAllUsers(4);
    } else if (currentTab === 10) {
      getAllUsers(5);
    } else if (currentTab === 12) {
      getAllUsers(9);
    } else if (currentTab === 13) {
      getAllUsers(8);
    } else if (currentTab === 11) {
      getAllUsers(8);
    }

    if (userRoles.length > 0 && userRoles.includes("manager")) {
      //get available users to be assigned for drafts
      if ([5, 6].includes(currentTab)) {
        getUsers({
          include: {
            association: "roles",
            where: {
              id: 3,
            },
          },
          model: "User",
        });
        // get available users(supervisors) to be assigned for revision
      } else if (currentTab == 7) {
        getUsers({
          include: {
            association: "roles",
            where: {
              id: 2,
            },
          },
          filter: { dept_id: user.dept_id },
          model: "User",
        });
      }
    }
  }, [user, currentTab]);

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {checkTab == currentTab && numSelected > 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                selected rows : {numSelected}
              </Typography>
            </Grid>
            {/* available users */}
            <Grid item md={7} style={{ textAlign: "left" }}>
              {[5, 6, 7, 8, 9, 10, 12, 13, 11].includes(currentTab) && (
                <FormControl
                  variant="outlined"
                  size="small"
                  className="ml-3"
                  style={{ width: "30%" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    re-assign
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    size="small"
                    fullWidth
                    value={selectedUser}
                    onChange={handleChange}
                    className="mt-0"
                    label="reassign"
                  >
                    {allUsers?.map((user) => (
                      <MenuItem value={user?.id} key={user?.id}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* send button */}
              {![10, 11, 8, 9, 10, 12, 13, 14, 11].includes(currentTab) && (
                <Button
                  className="btn btn-transition-none "
                  variant="contained"
                  color="primary"
                  disabled={[5, 6, 7].includes(currentTab) && !selectedUser}
                  onClick={handleSendRequest}
                >
                  {SEND_AR_STRING}
                </Button>
              )}
              {/* reject button */}
              {[8, 9, 10, 12, 13, 11].includes(currentTab) && (
                <Button
                  // style={{ backgroundColor: 'red' }}
                  className="btn btn-transition-none mr-2"
                  variant="contained"
                  color="secondary"
                  onClick={reassingTasks}
                >
                  re-assign
                </Button>
              )}
              {[14].includes(currentTab) && (
                <Button
                  // style={{ backgroundColor: 'red' }}
                  className="btn btn-transition-none mr-2"
                  variant="contained"
                  color="secondary"
                  onClick={handleDeQueue}
                >
                  {DEQUEUE_EN_STRING}
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Toolbar>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.details.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(getUsers(params)),
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
    handleDraftAssignment: (params, data) =>
      dispatch(handleDraftAssignment(data, params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableToolbar);
