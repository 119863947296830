import client from "api/apiAuth/guestClient";
import _ from "lodash";
import { getInitialEvents } from "utils/FormUtils/Events/EventsHelperFunctions";
import { getValue } from "utils/GetObjectValues";
const updateInputs = async (inputs, eventInputs, formik) => {
  return await Promise.all(
    inputs.map(async (input) => {
      const eventInput = eventInputs.find((ele) => ele.id === input.id);

      let result = await getInitialEvents(eventInput, formik);
      if (result) {
        return result;
      }
      return input;
    })
  );
};
export const setSections = async (
  form,
  mount,
  setSteps,
  eventInputs,
  formik
) => {
  let sections = _.cloneDeep(form);
  sections = await Promise.all(
    sections.map(async (section) => {
      if (section?.group) {
        section.group = await Promise.all(
          section.group.map(async (group) => {
            group.inputs = await updateInputs(
              group.inputs,
              eventInputs,
              formik
            );
            return group;
          })
        );
      }
      if (section?.step) {
        section.step = await Promise.all(
          section.step.map(async (step) => {
            step.inputs = await updateInputs(step.inputs, eventInputs, formik);
            return step;
          })
        );
      }
      section.inputs = await updateInputs(section.inputs, eventInputs, formik);
      return section;
    })
  );
  mount.current && setSteps(sections);
};

export function disableInputs(
  ev,
  mainInput,
  option,
  step,
  subActiveStepIndex,
  formik,
  SetInputKey,
  // setSubmission,
  submission
) {
  const isChildSection = step.child_sections.length > 0;
  const [changedInput, changedInputIndex] = findChangedInput(
    step,
    mainInput.id,
    subActiveStepIndex,
    isChildSection
  );
  if (!changedInput) return [null, null];
  if (
    !changedInput.validatedInputs.length &&
    !changedInput.validatedDocuments.length
  )
    return [null, null];
  clearValues(
    changedInput,
    formik,
    SetInputKey,
    isChildSection,
    // setSubmission,
    option,
    step,
    submission
  );
  /*return evaluateDisabled(
    changedInput,
    ev,
    step,
    subActiveStepIndex,
    isChildSection,
    changedInputIndex
  );*/
}

function findChangedInput(step, inputId, subActiveStepIndex, isChildSection) {
  if (isChildSection) {
    for (
      let i = 0;
      i < step.child_sections[subActiveStepIndex].inputs.length;
      i++
    ) {
      const input = step.child_sections[subActiveStepIndex].inputs[i];
      if (input.id === inputId) return [input, i];
    }
  } else {
    for (let i = 0; i < step.inputs.length; i++) {
      const input = step.inputs[i];
      if (input.id === inputId) return [input, i];
    }
  }
  return [null, null];
}

function clearValues(
  input,
  formik,
  SetInputKey,
  isChildSection,
  // setSubmission,
  option,
  step,
  submission
) {
  if (input.validatedInputs.length && isChildSection) {
    input.validatedInputs.forEach((validatedInput) => {
      if (
        input.input_layout.type == "checkbox" &&
        option.label !=
          validatedInput.InputValidation.condition?.value.replace(/[('')]/g, "")
      ) {
        return;
      }
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
    });
  }

  if (input.validatedDocuments.length && isChildSection) {
    input.validatedDocuments.forEach((validatedInput) => {
      if (
        input.input_layout.type == "checkbox" &&
        option.label !=
          validatedInput.DocumentValidation.condition?.value.replace(
            /[('')]/g,
            ""
          )
      ) {
        return;
      }
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
      // setSubmission({
      //   ...submission,
      //   attachments: submission.attachments.filter(
      //     (attachment) => attachment.name != inputKey
      //   ),
      // });
    });
  }

  if (input.validatedInputs.length && !isChildSection) {
    input.validatedInputs.forEach((validatedInput) => {
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
    });
  }

  if (input.validatedDocuments.length && !isChildSection) {
    input.validatedDocuments.forEach((validatedInput) => {
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
      // setSubmission({
      //   ...submission,
      //   attachments: submission.attachments.filter(
      //     (attachment) => attachment.name !== inputKey
      //   ),
      // });
    });
  }
}

// TODO: This function is not working as intended. Either remove it for fix it
function evaluateDisabled(
  input,
  ev,
  step,
  subActiveStepIndex,
  isChildSection,
  inputIndex
) {
  let toBeValidated = null;
  let validationKey = null;
  let validatedItemsName = null;
  let disabled = false;
  if (input.validatedInputs.length) {
    toBeValidated = input.validatedInputs;
    validationKey = "InputValidation";
    validatedItemsName = "validatedInputs";
  } else {
    toBeValidated = input.validatedDocuments;
    validationKey = "DocumentValidation";
    validatedItemsName = "validatedDocuments";
  }
  const operator = toBeValidated[0][validationKey].condition.operator;
  if (operator === "==") {
    if (input.itype === "enum") {
      // Values sometimes have character: "'" in them, these cause inequality between two similar values.
      // I am not going to bother and remove both characters
      if (
        ev.target.value.replace("'", "").replace("'", "") ===
        toBeValidated[0][validationKey].condition.value
          .replace("'", "")
          .replace("'", "")
      ) {
        disabled = false;
      } else {
        disabled = true;
      }
    } else if (input.itype === "boolean") {
      disabled = ev.target.value === "false";
    }
  }
  const newStep = _.cloneDeep(step);
  if (isChildSection) {
    for (let j = 0; j < input[validatedItemsName].length; j++) {
      newStep.child_sections[subActiveStepIndex].inputs[inputIndex][
        validatedItemsName
      ][j] = {
        ...newStep.child_sections[subActiveStepIndex].inputs[inputIndex][
          validatedItemsName
        ][j],
        disabled,
      };
    }
  } else {
    for (let j = 0; j < input[validatedItemsName].length; j++) {
      newStep.inputs[inputIndex][validatedItemsName][j] = {
        ...newStep.inputs[inputIndex][validatedItemsName][j],
        disabled,
      };
    }
  }
  return newStep;
}

export function uploadFile(
  input,
  file,
  formik,
  documentName,
  isMultiple,
  submission,
  uniqueNo
) {
  let formData = new FormData();
  formData.append("avatar", file);

  const url = `attachment/uploadFile?documentName=${
    input.equivalent_key
  }&submission_id=${
    submission?.file_code ? `${submission?.file_code}` : uniqueNo
  }`;
  client
    .post(url, formData)
    .then((result) => {
      let documentData = {
        ...result.data.results,
        input_id: input.id,
        original_file_name: file.name,
        fileObject: file,
      };
      let inputValue = getValue(formik.values, input.key, input.itype);
      if (inputValue?.id) {
        documentData.id = inputValue.id;
      }
      formik.setFieldValue(isMultiple ? documentName : input.key, documentData);
      setTimeout(() => {
        formik.setFieldTouched(documentName, true, true).then((err) => {});
      }, 0);
    })
    .catch((error) => {
      console.log(error);
      formik.setFieldValue(input.key, "");
    });
}

export function findBySectionId(id, steps) {
  for (const step of steps) {
    if (step.id === id) return step;
    if (step.group) {
      const result = findBySectionId(id, step.group);
      if (result) return result;
    }
  }
  return null;
}
export function setInputKey(input, numParts) {
  let key = input.key;
  if (typeof numParts != "undefined") {
    key = input.multi_key;
    numParts.forEach((element) => {
      key = key?.replace("[i]", element);
    });
  }
  return key;
}
export function addSiblingChild(section, manyEntities, steps, randomId) {
  function findSiblingSection(mainSection) {
    if (mainSection.group) {
      for (const childSection of mainSection.group) {
        if (childSection.main_section_id) return childSection;
        if (childSection.group) {
          const result = findSiblingSection(childSection);
          if (result) return result;
        }
      }
    }
    return null;
  }

  const siblingSection = findSiblingSection(section);

  if (!siblingSection) return;
  const otherSiblingSection = findBySectionId(
    siblingSection.main_section_id,
    steps
  );
  if (
    otherSiblingSection.name in manyEntities &&
    Array.isArray(manyEntities[otherSiblingSection.name])
  ) {
    const arrayLen = manyEntities[section.name].length;

    manyEntities[section.name][arrayLen - 1][siblingSection.name] = _.cloneDeep(
      manyEntities[section.name][0][siblingSection.name]
    );
  }
}
export function getMultiSectionKeyOfInput(input) {
  const parts = input.multi_key.split(".");
  const inputPartIdx = parts.indexOf(input.key);
  // As the previous part would be the [i] "multi instances indicator"
  const sectionPart = parts[inputPartIdx - 2];
  return sectionPart;
}

export function findInputFromSection(section, equivalent_key) {
  return section.inputs.find(
    (input) => input.equivalent_key === equivalent_key
  );
}

export function createRandomId() {
  const { v4: uuidv4 } = require("uuid");
  return uuidv4();
}

export function ConstructFormErrors(errorsObject) {
  const submissionsKeyRE = /submissions\[\d+\]/;
  const indexKey = /\d+/;
  const errors = [];
  for (let i = 0; i < errorsObject.inner.length; i++) {
    const result = errorsObject.inner[i].path.match(submissionsKeyRE);
    if (result) {
      const index = Number(result[0].match(indexKey));
      errors.push(`${errorsObject.errors[i]} in Submisison ${index + 1}`);
    } else errors.push(errorsObject.errors[i]);
  }
  return errors;
}
