import client from "api/apiAuth/guestClient";
import {
  ASSIGN_TASK,
  GET_TASK_DETAILS,
  GET_TIMELINE,
  SAVE_CARD_ATTACHMENTS,
  GET_TASK_USERS,
  HANDLE_TASK_ASSIGNMENT,
  SET_ALL_ATTACHMENTS,
  SET_ATTACHMENT_REVIEWED,
  GET_AVAILABLE_SLOTS,
  RESERVE_SLOT,
  SUBMIT_TASK,
  UPLOAD_ATTACHMENTS,
  SEND_REQUEST_TO_MANAGER,
  SAVE_SUBMISSION_VALUES,
  GET_ATTACHMENTS,
  SAVE_NAMING,
  SET_TASK_DETAILS
} from "./detailsActionTypes";

export const getTaskDetails = function (params) {
  return {
    type: GET_TASK_DETAILS,
    payload: client.get("tasks/taskdetails", {
      params,
    }),
  };
};
export const saveNamingValuesAndSubmit = function (data) {
  return {
    type: SAVE_NAMING,
    payload: client.post("submission/saveNamingValuesAndSubmit", data),
  };
};
export const assignTask = function (body) {
  return {
    type: ASSIGN_TASK,
    payload: client.post("workflow/handleDistrubution", body),
  };
};

export const submitTask = function (body) {
  return {
    type: SUBMIT_TASK,
    payload: client.post("workflow/submittask", body),
  };
};

export const getTimeline = function (params) {
  return {
    type: GET_TIMELINE,
    payload: client.get("tasks/getTimeline", { params }),
  };
};

export const saveCardAttachment = function (attachment) {
  return {
    type: SAVE_CARD_ATTACHMENTS,
    payload: { attachment },
  };
};

export const getUsers = function (params) {
  return {
    type: GET_TASK_USERS,
    payload: client.get("workflow/getDistributedUsers", { params }),
  };
};

export const handleTaskAssignment = function (params) {
  return {
    type: HANDLE_TASK_ASSIGNMENT,
    payload: client.get("tasks/handleTaskAssignment", { params }),
  };
};

export const setAllAttachments = function (attachments) {
  return {
    type: SET_ALL_ATTACHMENTS,
    payload: attachments,
  };
};

export const setAttachmentReviewed = function (params) {
  return {
    type: SET_ATTACHMENT_REVIEWED,
    payload: params,
  };
};

export const setUploadedAttachments = function (attachments) {
  return {
    type: UPLOAD_ATTACHMENTS,
    payload: attachments,
  };
};

export const getAvailableSlots = function (params) {
  return {
    type: GET_AVAILABLE_SLOTS,
    payload: client.get("timeSlots/getUnreservedSlots", { params }),
  };
};
export const sendNotification = function (body) {
  return {
    type: SEND_REQUEST_TO_MANAGER,
    payload: client.post("timeSlots/sendNotification", body),
  };
};
export const reserveSlot = function (body) {
  return {
    type: RESERVE_SLOT,
    payload: client.post("timeSlots/reserveSlot", body),
  };
};

export const saveSubmissionValues = function (body) {
  return {
    type: SAVE_SUBMISSION_VALUES,
    payload: client.post("submission/updateSubmissionValues", body),
  };
};
export const getAttachments = function (params) {
  return {
    type: GET_ATTACHMENTS,
    payload: client.get("attachment/getattachments", {
      params,
    }),
  };
};

export const setTaskDetails = function (task) {
  return {
    type: SET_TASK_DETAILS,
    payload: task,
  };
};
