const config = (mapper) => [
  {
    tabId: 2,
    sections: [
      {
        title: "",
        id: 1,
      },
    ],
  },
  {
    tabId: 4,
    sections: [
      {
        title: "",
        id: 1,
      },
    ],
  },

  {
    tabId: 5,
    sections: [
      {
        id: 1,
        actions: [
          {
            gridSize: 4,
            classes: "",
            onClick: {
              functionName: "saveNamingValues",
              // functionAttributes: [
              //   { field: "setModalInfo", source: "propsParameter" },
              //   { field: "setConfirmationModal", source: "propsParameter" },
              // ],
            },
            label: "Save Names Review",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.is_assigned,
                  },
                  {
                    checkValue: "User",
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles.map(
                      (role) => role.name
                    ),
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser?.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers.map((user) => user.id),
                  },
                ],
                // conditionsEquation: "$0 && $1",
                conditionsEquation: "$0 && !$1 && $2",
              },
            },
          },
        ],
      },
      {
        id: 2,
        title: "Enter the proposed list of names",
        tableHeader: [
          {
            name: "No",
          },
          {
            name: "Proposed Name",
          },
          {
            name: "Arabic Name",
          },
          {
            name: "Voice Note",
          },
          {
            name: "Decision",
            targetUsers: mapper.taskUsers,
            exceptRole: "User",
          },
          {
            name: "Similarity Score",
            exceptRole: "User",
          },
        ],
      },
    ],
  },
];

export default config;
