import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  TextField,
  Button,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";

const AddNewDrugName = (props) => {
  const [newDrug, setNewDrug] = useState(null);
  const handleGenericChange = (e) => {
    if (e.target.value == "generic") setIsGeneric(true);
    else setIsGeneric(false);
  };
  const [isGeneric, setIsGeneric] = useState(false);
  const [genericNamePosition, SetgenericNamePosition] = useState(null);

  // const [newDrugNameError, setNewDrugError] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  // useEffect(() => {
  //   setNewDrugError(
  //     /^[A-Za-z]+( [0-9]+)*$/.test(newDrugName)
  //       ? ""
  //       : "please enter a valid drug name"
  //   );
  // }, [newDrugName]);
  const addNewDrug = async () => {
    if (editFlag) {
      setEditFlag(false);
    } else {
      async function doNetworkRequest() {
        let suggestedObj = {
          name: newDrug.name,
          decision: true,
        };
        if (isGeneric) {
          suggestedObj.generic = true;
          suggestedObj.position = genericNamePosition;
        }
        await client.post("submission/updateSubmissionValues", {
          submission_id: props.task.submission.id,
          task_id: props.task.id,
          inputs: [],
          submitFlag: false,
          submission_edit: {
            naming: {
              drugs: [
                // ...props.task.submission.values.naming.drugs,
                { ...suggestedObj },
              ],
              allDrugNames: [
                ...props.task.submission.values.naming.allDrugNames,
                newDrug?.name,
              ],
            },
          },
        });
        window.location.reload();
      }
      if (!newDrug?.name) return;
      if (isGeneric && !genericNamePosition) {
        Swal.fire(`Generic Name Position is required`);
        return;
      }
      if (
        props.task.submission.values.naming.allDrugNames.includes(newDrug?.name)
      ) {
        Swal.fire(`Drug name: ${newDrug?.name} is already present in the list`);
        return;
      }
      Swal.fire({
        title: `Are you sure you want to submit <strong> ${newDrug?.name} </strong> as the final product name`,
        icon: "question",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
        confirmButtonColor: "#0066cc",
        denyButtonColor: "#25305338",
      }).then((result) => {
        if (result.isConfirmed) doNetworkRequest();
      });
    }
  };

  useEffect(() => {
    const { drugs } = props.task.submission.values.naming;
    if (drugs) {
      const acceptedDrug = drugs.find((drug) => drug.decision == true);
      if (acceptedDrug) {
        setNewDrug({ ...acceptedDrug });
        setEditFlag(true);
      }
    }
  }, []);
  useEffect(() => {
    setIsGeneric(newDrug?.generic);
    SetgenericNamePosition(newDrug?.position);
  }, [newDrug]);
  return (
    <Grid
      container
      item
      xs={props.part.style.attributes.GridSize}
      key={props.partIndex}
      className={props.part.style.classes}
    >
      <Card className="card-box w-100 p-4">
        <div className="d-flex flex-column">
          {/* <div>
            <Alert severity="warning">
              <span>
                The company has exceeded 4 trials. Please, take the final
                decision regarding the drug's name. You can add a new name in
                this section which will appear in the naming list tab if needed.
              </span>
            </Alert>
          </div> */}
          <div className="d-flex flex-wrap">
            <div>
              <p>Final Product Name: </p>
            </div>
            <div className="ml-5">
              <TextField
                placeholder="type new drug name"
                onChange={(ev) =>
                  setNewDrug({ ...newDrug, name: ev.target.value })
                }
                value={newDrug?.name}
                disabled={editFlag}
              />
              <Radio
                onChange={handleGenericChange}
                value={"generic"}
                checked={isGeneric}
              />
              <FormLabel>Generic</FormLabel>
              <Radio
                onChange={handleGenericChange}
                value={"trade"}
                checked={!isGeneric}
              />
              <FormLabel>Trade Name</FormLabel>
            </div>
            <div className="ml-5">
              <Button
                variant="contained"
                className="takeActionButton"
                onClick={addNewDrug}
                // disabled={newDrugNameError}
              >
                {editFlag ? "Edit" : "Confirm"}
              </Button>
            </div>
            {isGeneric && (
              <div className="mx-auto">
                <RadioGroup row value={genericNamePosition}>
                  <FormControlLabel
                    label={"Beside drug name"}
                    value={"Beside drug name"}
                    onChange={(ev) => SetgenericNamePosition(ev.target.value)}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    label={"Below drug name"}
                    value={"Below drug name"}
                    control={<Radio />}
                    onChange={(ev) => SetgenericNamePosition(ev.target.value)}
                  />
                </RadioGroup>
              </div>
            )}
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export default AddNewDrugName;
