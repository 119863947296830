import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormLabel,
  TextField,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
export default function AddDoctorModal(props) {
  const {
    open,
    setAddModalOpen,
    isEdit,
    currentDoctor,
    doctors,
    setDoctors,
    setCurrentDoctor,
  } = props;
  const [isDisable, setIsDisable] = useState(true);
  const [validationErrors, setValidationErrors] = useState({
    nameError: null,
    emailError: null,
  });
  const handleClose = () => {
    setAddModalOpen(false);
  };
  useEffect(() => {
    setValidationErrors({
      nameError: currentDoctor.name ? null : "Name is required",
      emailError: currentDoctor.email ? null : "Email is requried",
    });
    if (currentDoctor.name && currentDoctor.email) {
      setIsDisable(false);
    } else setIsDisable(true);
  }, [currentDoctor]);
  const handleSumit = () => {
    const body = {
      name: currentDoctor.name,
      email: currentDoctor.email,
    };
    if (isEdit) {
      client.put(`/doctor/update/${currentDoctor?.id}`, body).then((res) => {
        Swal.fire({
          text: "Doctor is Updated Successfully",
          icon: "success",
          confirmButtonColor: "#0000ff",
          confirmButton: "btn btn-success",
        });
        setDoctors(
          ...[
            doctors.filter((doctor) => {
              if (doctor.id == currentDoctor.id) {
                doctor.name = body.name;
                doctor.email = body.email;
              }
              return doctor;
            }),
          ]
        );
        doctors.find(
          (doc) =>
            doc.id == currentDoctor.id && {
              ...doc,
              ...body,
            }
        );
        setAddModalOpen(false);
      });
    } else {
      client.post("/doctor/create", body).then((res) => {
        setDoctors([...doctors, res.data.doctor]);
        Swal.fire({
          text: "Doctor is Added Successfully",
          icon: "success",
          confirmButtonColor: "#0000ff",
          confirmButton: "btn btn-success",
        });
        setAddModalOpen(false);
      });
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="text-center">
          {isEdit ? "update" : "Add"} Doctor
        </DialogTitle>

        <DialogContent>
          <FormLabel className="mt-2">Name</FormLabel>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            value={currentDoctor.name}
            onChange={(e) =>
              setCurrentDoctor({
                ...currentDoctor,
                name: e.target.value,
              })
            }
          />
          <p className="text-danger">{validationErrors.nameError}</p>
          <FormLabel className="mt-2">Email</FormLabel>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            type="text"
            fullWidth
            variant="standard"
            value={currentDoctor.email}
            onChange={(e) =>
              setCurrentDoctor({
                ...currentDoctor,
                email: e.target.value,
              })
            }
          />
          <p className="text-danger">{validationErrors.emailError}</p>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isDisable}
            className={
              isEdit ? "btn btn-primary fw-bold" : "btn btn-success fw-bold"
            }
            onClick={(e) => {
              handleSumit();
            }}
          >
            {isEdit ? "Update" : "Add"}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
