import React, { useEffect, useState } from "react";
import imag from "../../assets/images/bio-inn.png";
import _ from "lodash";
import "./index.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import config from "../index.js";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  form,
  user,
  setCheckListFormFlag,
}) => {
  useEffect(() => {
    setCheckListFormFlag(false);
    setValues(config(letter, formValues, "checklistSamples"));
  }, []);

  let [initialValues, setInitialValues] = useState({});

  const checkListSchema = {
    row1: Yup.string().required("Required"),
    row2: Yup.string().required("Required"),
    row3: Yup.string().required("Required"),
    row4: Yup.string().required("Required"),
    row5: Yup.string().required("Required"),
    row6: Yup.string().required("Required"),
    row7: Yup.string().required("Required"),
    row8: Yup.string().required("Required"),
    row9: Yup.string().required("Required"),
    row10: Yup.string().required("Required"),
    row11: Yup.string().required("Required"),
    row12: Yup.string().required("Required"),
    row13: Yup.string().required("Required"),
    row14: Yup.string().required("Required"),
    row15: Yup.string().required("Required"),
    row16: Yup.string().required("Required"),
    row17: Yup.string().required("Required"),
    row18: Yup.string().required("Required"),
    row19: Yup.string().required("Required"),
    row20: Yup.string().required("Required"),
    row21: Yup.string().required("Required"),
  };
  const checkListForm = useFormik({
    validationSchema: Yup.object().shape(checkListSchema),

    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    setCheckListFormFlag(checkListForm.dirty && checkListForm.isValid);
  }, [checkListForm]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    checkListForm.handleChange(event);
    setValues({
      ...values,
      [name]: value,
    });
    if (_.isEmpty(checkListForm.errors) && !_.isEmpty(values)) {
      setCheckListFormFlag(true);
    } else {
      setCheckListFormFlag(false);
    }
  };
  useEffect(() => {}, [values]);
  useEffect(() => {
    if (_.isEmpty(checkListForm.errors)) {
      setCheckListFormFlag(false);
    }
  }, []);
  useEffect(() => {}, [values, formValues]);
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }
  toDataURL(imag);
  useEffect(() => {
    const checklist_samples = document.getElementById("checklist_samples");
    const radioButtons = checklist_samples?.querySelectorAll(
      'input[type="radio"]:checked'
    );
    radioButtons.forEach((input) => {
      input.setAttribute("checked", "checked");
    });
    setDivRef((divRefs) => ({ ...divRefs, checklist_samples }));
  }, [values, formValues]);
  return (
    <div style={{ padding: "20px", zoom: 0.88 }} id="checklist_samples">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            CA of Biological and Innovative products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            and Clinical Studies
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            GA of Biological Product
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Lot Release Administration
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "200px" }} alt="bio-inn" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هـيـئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الحيوية والمبتكرة
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            والدراسات الإكلينيكية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات الحيوية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "10pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            إدارة الافراج عن التشغيلات
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <table style={{ width: "100%" }}>
        <thead
          style={{ border: "1px solid #BEBEBE", borderCollapse: "collapse" }}
        >
          <tr>
            <td className="table-border">
              <span>Lot Release administration</span>
            </td>

            <td className="table-border">
              <label htmlFor="scales">Unit:</label>
              <input
                value={values.unit}
                name="unit"
                placeholder="............................."
                style={{
                  minWidth: "30%",
                  minHeight: "30px",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onChange={handleChange}
              />
            </td>
          </tr>
        </thead>
      </table>

      <div
        style={{
          marginTop: "10px",
          border: "3px solid 	#101010",
          padding: "10px",
        }}
      >
        <div
          style={{
            border: "2px solid #BEBEBE",
            textAlign: "center",
          }}
        >
          <b style={{ color: "#101010" }}>
            Checklist for the receiving of samples
          </b>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <table style={{ width: "100%" }}>
          <tbody style={{ border: "none", borderCollapse: "collapse" }}>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Name of product:</span>
              </td>
              <td className="table-text-style">{letter.drugs.name}</td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Batch number(s): </span>
              </td>
              <td className="table-text-style">{letter.values.batch_number}</td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Manufacturer/Agent/Distributor:</span>
              </td>

              <td className="table-text-style">
                {letter.drugs.Manufacturer} / {letter.org.name} /
                {letter.user.username}
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Manufacture:</span>
              </td>
              <td className="table-text-style">
                {letter.drugs.Manufacturer ? (
                  letter.drugs.Manufacturer
                ) : (
                  <input
                    className="input-style"
                    type="text"
                    value={values.Manufacturer}
                    name="Manufacturer"
                    onChange={handleChange}
                  ></input>
                )}
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Registration status: </span>
              </td>
              <td className="table-text-style">
                {!letter.drugs.registration_status && (
                  <input
                    className="input-style"
                    type="text"
                    value={values.registration_status}
                    name="registration_status"
                    onChange={handleChange}
                  ></input>
                )}
                {letter.drugs.registration_status}
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Code number(s):</span>
              </td>
              <td className="table-text-style">
                {letter?.drugs.code_number ? (
                  <>{letter?.drugs.code_number}</>
                ) : (
                  <>
                    <input
                      style={{ border: "none", width: "100%" }}
                      type="text"
                      className="input-style"
                      value={values.code_number || letter?.drugs.code_number}
                      name="code_number"
                      placeholder="............................."
                      onChange={(e) => {
                        form.handleChange(e);
                        handleChange(e);
                      }}
                      onBlur={form.handleBlur}
                    ></input>
                    {form.errors["code_number"] ? (
                      <span style={{ float: "right" }} className="text-danger">
                        {form.errors["code_number"]}
                      </span>
                    ) : null}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Unique identifier: </span>
              </td>

              <td className="table-text-style">{letter.unique_identifier}</td>
            </tr>
            <tr>
              <td className="table-text-style" style={{ width: "30%" }}>
                <span>Product category: </span>
              </td>
              <td className="table-text-style">{letter.values.product_type}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div align="center">
            <table
              style={{
                textAlign: "left",
                marginTop: "15px",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <td className="table-header">S.N</td>
                  <td className="table-header">Item</td>
                  <td className="table-header">Present</td>
                  <td className="table-header">Missed</td>
                  <td className="table-header">N.A</td>
                  <td className="table-header">Remarks</td>
                </tr>
              </thead>
              <tbody>
                {/* <div className="col-md-12" onChange={handleChange}> */}
                <tr>
                  <td className="table-border">
                    <b>1.</b>
                  </td>
                  <td className="table-border">
                    <span>Samples submission Application form</span>
                  </td>

                  <td className="table-border">
                    <input
                      type="radio"
                      name="row1"
                      id="Present1"
                      value="Present1"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="row1"
                      id="Missed1"
                      value="Missed1"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      type="radio"
                      name="row1"
                      id="na"
                      value="na"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks1}
                      name="Remarks1"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>2.</b>
                  </td>
                  <td className="table-border">
                    <span>Proof of payment of the sample testing fee</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present2"
                      type="radio"
                      name="row2"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed2"
                      type="radio"
                      name="row2"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na2"
                      type="radio"
                      name="row2"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks2}
                      name="Remarks2"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>3.</b>
                  </td>
                  <td className="table-border">
                    <span>Cold chain assurance report</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present3"
                      type="radio"
                      name="row3"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed3"
                      type="radio"
                      name="row3"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na3"
                      type="radio"
                      name="row3"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks3}
                      name="Remarks3"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.</b>
                  </td>
                  <td className="table-border">
                    <span style={{ fontWeight: "bold" }}>
                      Sample screening:
                    </span>
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.1</b>
                  </td>
                  <td className="table-border">
                    <span>Seal</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present4"
                      type="radio"
                      name="row4"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed4"
                      type="radio"
                      name="row4"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na4"
                      type="radio"
                      name="row4"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks4}
                      name="Remarks4"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.2</b>
                  </td>
                  <td className="table-border">
                    <span>Cooling status at reception</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present5"
                      type="radio"
                      name="row5"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed5"
                      type="radio"
                      name="row5"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na5"
                      type="radio"
                      name="row5"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks5}
                      name="Remarks5"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.3</b>
                  </td>
                  <td className="table-border">
                    <span>No. of samples received</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present6"
                      type="radio"
                      name="row6"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed6"
                      type="radio"
                      name="row6"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na6"
                      type="radio"
                      name="row6"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks6}
                      name="Remarks6"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.5</b>
                  </td>
                  <td className="table-border">
                    <span>Solvent</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present7"
                      type="radio"
                      name="row7"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed7"
                      type="radio"
                      name="row7"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na7"
                      type="radio"
                      name="row7"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks7}
                      name="Remarks7"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.6</b>
                  </td>
                  <td className="table-border">
                    <span>Shape of the package</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present8"
                      type="radio"
                      name="row8"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed8"
                      type="radio"
                      name="row8"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na8"
                      type="radio"
                      name="row8"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks8}
                      name="Remarks8"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>4.7</b>
                  </td>
                  <td className="table-border">
                    <span>Internal leaflet</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present9"
                      type="radio"
                      name="row9"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed9"
                      type="radio"
                      name="row9"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na9"
                      type="radio"
                      name="row9"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks9}
                      name="Remarks9"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.</b>
                  </td>
                  <td className="table-border">
                    <span style={{ fontWeight: "bold" }}>
                      <b>Label screening:</b>
                    </span>
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.1</b>
                  </td>
                  <td className="table-border">
                    <span>Name of the product</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present10"
                      type="radio"
                      name="row10"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed10"
                      type="radio"
                      name="row10"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na10"
                      type="radio"
                      name="row10"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks10}
                      name="Remarks10"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.2</b>
                  </td>
                  <td className="table-border">
                    <span>Name of manufacturer</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present11"
                      type="radio"
                      name="row11"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed11"
                      type="radio"
                      name="row11"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na11"
                      type="radio"
                      name="row11"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks11}
                      name="Remarks11"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.3</b>
                  </td>
                  <td className="table-border">
                    <span>Batch number</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present12"
                      type="radio"
                      name="row12"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed12"
                      type="radio"
                      name="row12"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na12"
                      type="radio"
                      name="row12"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks12}
                      name="Remarks12"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.4</b>
                  </td>
                  <td className="table-border">
                    <span>Registration number</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present13"
                      type="radio"
                      name="row13"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed13"
                      type="radio"
                      name="row13"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na13"
                      type="radio"
                      name="row13"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks13}
                      name="Remarks13"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.5</b>
                  </td>
                  <td className="table-border">
                    <span>Man. date</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present14"
                      type="radio"
                      name="row14"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed14"
                      type="radio"
                      name="row14"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na14"
                      type="radio"
                      name="row14"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks14}
                      name="Remarks14"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.7</b>
                  </td>
                  <td className="table-border">
                    <span>Shelf life</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present15"
                      type="radio"
                      name="row15"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed15"
                      type="radio"
                      name="row15"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na15"
                      type="radio"
                      name="row15"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks15}
                      name="Remarks15"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.8</b>
                  </td>
                  <td className="table-border">
                    <span>Container type</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present16"
                      type="radio"
                      name="row16"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed16"
                      type="radio"
                      name="row16"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na16"
                      type="radio"
                      name="row16"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks16}
                      name="Remarks16"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.9</b>
                  </td>
                  <td className="table-border">
                    <span>Doses/ volume</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present17"
                      type="radio"
                      name="row17"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed17"
                      type="radio"
                      name="row17"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na17"
                      type="radio"
                      name="row17"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks17}
                      name="Remarks17"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.10</b>
                  </td>
                  <td className="table-border">
                    <span>Storage temperature</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present18"
                      type="radio"
                      name="row18"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed18"
                      type="radio"
                      name="row18"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na18"
                      type="radio"
                      name="row18"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks18}
                      name="Remarks18"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.11</b>
                  </td>
                  <td className="table-border">
                    <span>Route of administration</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present19"
                      type="radio"
                      name="row19"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed19"
                      type="radio"
                      name="row19"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na19"
                      type="radio"
                      name="row19"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks19}
                      name="Remarks19"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>5.12</b>
                  </td>
                  <td className="table-border">
                    <span>Active and inactive content</span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present20"
                      type="radio"
                      name="row20"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed20"
                      type="radio"
                      name="row20"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na20"
                      type="radio"
                      name="row20"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks20}
                      name="Remarks20"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr style={{ height: "20px" }}>
                  <td className="table-border">
                    <b>6.</b>
                  </td>
                  <td className="table-border">
                    <span style={{ fontWeight: "bold" }}>
                      VVM grade (If present)
                    </span>
                  </td>
                  <td className="table-border">
                    <input
                      value="Present21"
                      type="radio"
                      name="row21"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="Missed21"
                      type="radio"
                      name="row21"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value="na21"
                      type="radio"
                      name="row21"
                      style={{
                        accentColor: "#DDD",
                        height: "20px",
                        width: "20px",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                  <td className="table-border">
                    <input
                      value={values.Remarks21}
                      name="Remarks21"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "100%" }}>
              <thead
                style={{
                  border: "1px solid #BEBEBE",
                  borderCollapse: "collapse",
                }}
              >
                <tr>
                  <td className="table-text-style" style={{ width: "30%" }}>
                    <span>Remarks:</span>
                    <input
                      value={values.remarks}
                      name="remarks"
                      placeholder="............................."
                      style={{
                        minWidth: "30%",
                        minHeight: "30px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-border">
                    <span>Received By: </span>
                    <span>{user.username}</span>
                  </td>
                  <td className="table-border">
                    <span>Date: </span>
                    <span>{new Date().toLocaleString()}</span>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
