export const config = (mapper) => {
  return [
    {
      modification_id: 1,
      title: "Details",
      attributes: [
        {
          name: "HCP Info",
          type: "string",
          key: "doctors",
          isMany: true,
          style: {
            gridSize: 4,
            display: true,
          },

          attributes: [
            {
              label: "HCP Name",
              type: "string",
              key: "name",
              style: {
                gridSize: 4,
                display: true,
              },
            },
          ],
        },
        {
          name: "engagement",
          key: "engagementsData",
          style: { gridSize: 12, display: true },
          type: "string",
          isMany: true,
          attributes: [
            {
              label: "Symposium Date",
              type: "date1",
              key: "symposium_date",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Talk title ",
              type: "string",
              key: "talk_title",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Veeva Code",
              type: "string",
              key: "veeva_code",
              style: {
                gridSize: 4,
              },
            },

            {
              label: "Talk time ",
              type: "string",
              key: "talk_time",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Talk Objective",
              type: "string",
              key: "talk_objective",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Disease Area",
              type: "string",
              key: "disease_area",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Doctor Role",
              type: "string",
              key: "doctor_role",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Presentation Type",
              type: "string",
              key: "presentation_type",
              style: {
                gridSize: 4,
              },
            },
            {
              label: "Activity Type",
              type: "string",
              key: "name",
              name: "Activity Type",
              parent: "active_type",
              style: {
                gridSize: 4,
              },
            },
            {
              name: "Product Info",
              type: "string",
              key: "product",
              isMany: true,
              style: {
                gridSize: 4,
                display: true,
              },
              attributes: [
                {
                  label: "name",
                  type: "string",
                  key: "name",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
              ],
            },
          ],
        },
        {
          label: "Modification Comment",
          type: "string",
          key: "comment",
          style: {
            gridSize: 4,
          },
        },
      ],
    },
    {
      modification_id: 2,
      title: "Details",
      attributes: [
        {
          name: "HCP Info",
          type: "string",
          key: "doctors",
          isMany: true,
          style: {
            gridSize: 4,
            display: true,
          },

          attributes: [
            {
              label: "HCP Name",
              type: "string",
              key: "name",
              style: {
                gridSize: 4,
                display: true,
              },
            },
            {
              label: "HCP Email",
              type: "string",
              key: "email",
              style: {
                gridSize: 4,
                display: true,
              },
            },

            {
              label: "Travel Distance",
              type: "string",
              key: "travel_distance",
              style: {
                gridSize: 4,
                display: true,
              },
            },
            {
              label: "HCP FMV",
              type: "string",
              key: "fmv",
              style: {
                gridSize: 4,
                display: true,
              },
            },

            {
              label: "HCP Locality",
              type: "string",
              key: "hcp_locality",
              style: {
                gridSize: 4,
                display: true,
              },
            },
            {
              label: "HCP Country",
              type: "string",
              key: "hcp_country",
              style: {
                gridSize: 4,
                display: true,
              },
            },
            {
              label: "Total Package",
              type: "string",
              key: "doctor_package",
              style: {
                gridSize: 4,
                display: true,
              },
            },
            // {
            //   label: "",
            //   key: "id",
            //   filterKey: "dr_submission_id",
            //   style: {
            //     gridSize: 3,
            //   },
            //   type: "component",
            //   componentName: "AttrWithAttachment",
            // },
            {
              name: "Engagements Info",
              type: "string",
              key: "engagements",
              isMany: true,
              style: {
                gridSize: 4,
                display: true,
              },
              attributes: [
                {
                  label: "Veeva Code",
                  type: "string",
                  key: "veeva_code",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "HCP Role",
                  type: "string",
                  key: "doctor_role",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "Symposium Date",
                  type: "date1",
                  key: "symposium_date",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "Talk Title",
                  type: "string",
                  key: "talk_title",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "Talk Objective",
                  type: "string",
                  key: "talk_objective",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "Talk Time in Mins",
                  type: "string",
                  key: "talk_time",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "Presentation Type",
                  type: "string",
                  key: "presentation_type",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  label: "Disease Area",
                  type: "string",
                  key: "disease_area",
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                },
                {
                  name: "Product Info",
                  type: "string",
                  key: "product",
                  isMany: true,
                  style: {
                    gridSize: 4,
                    display: true,
                  },
                  attributes: [
                    {
                      label: "name",
                      type: "string",
                      key: "name",
                      style: {
                        gridSize: 4,
                        display: true,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        // {
        //   label: "HCPs",
        //   type: "string",
        //   key: "invitees",
        //   isMany: true,
        //   directKey: "invitees",
        //   style: {
        //     gridSize: 4,
        //     display: true,
        //   },
        //   attributes: [
        //     // {
        //     //   name: "HCP Info",
        //     //   type: "string",
        //     //   key: "doctor",
        //     //   style: {
        //     //     gridSize: 4,
        //     //     display: true,
        //     //   },
        //     //   attributes: [
        //     //     {
        //     //       label: "HCP Name",
        //     //       type: "string",
        //     //       key: "name",
        //     //       style: {
        //     //         gridSize: 4,
        //     //         display: true,
        //     //       },
        //     //     },
        //     //     {
        //     //       label: "HCP Email",
        //     //       type: "string",
        //     //       key: "email",
        //     //       style: {
        //     //         gridSize: 4,
        //     //         display: true,
        //     //       },
        //     //     },
        //     //   ],
        //     // },

        //     {
        //       label: "Travel Distance",
        //       type: "string",
        //       key: "travel_distance",
        //       style: {
        //         gridSize: 4,
        //         display: true,
        //       },
        //     },
        //     {
        //       label: "HCP FMV",
        //       type: "string",
        //       key: "fmv",
        //       style: {
        //         gridSize: 4,
        //         display: true,
        //       },
        //     },

        //     {
        //       label: "HCP Locality",
        //       type: "string",
        //       key: "hcp_locality",
        //       style: {
        //         gridSize: 4,
        //         display: true,
        //       },
        //     },
        //     {
        //       label: "HCP Country",
        //       type: "string",
        //       key: "hcp_country",
        //       style: {
        //         gridSize: 4,
        //         display: true,
        //       },
        //     },
        //     {
        //       label: "Total Package",
        //       type: "string",
        //       key: "doctor_package",
        //       style: {
        //         gridSize: 4,
        //         display: true,
        //       },
        //     },
        //     {
        //       label: "",
        //       key: "id",
        //       filterKey: "dr_submission_id",
        //       style: {
        //         gridSize: 3,
        //       },
        //       type: "component",
        //       componentName: "AttrWithAttachment",
        //     },
        //     {
        //       name: "Engagements Info",
        //       type: "string",
        //       key: "engagement",
        //       isMany: true,
        //       style: {
        //         gridSize: 4,
        //         display: true,
        //       },
        //       attributes: [
        //         {
        //           label: "Veeva Code",
        //           type: "string",
        //           key: "veeva_code",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //         {
        //           label: "HCP Role",
        //           type: "string",
        //           key: "doctor_role",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //         {
        //           label: "Talk Title",
        //           type: "string",
        //           key: "talk_title",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //         {
        //           label: "Talk Objective",
        //           type: "string",
        //           key: "talk_objective",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //         {
        //           label: "Talk Time in Mins",
        //           type: "string",
        //           key: "talk_time",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //         {
        //           label: "Presentation Type",
        //           type: "string",
        //           key: "presentation_type",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //         {
        //           label: "Disease Area",
        //           type: "string",
        //           key: "disease_area",
        //           style: {
        //             gridSize: 4,
        //             display: true,
        //           },
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
  ];
};
