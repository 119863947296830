import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Tooltip,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputLabel,
} from "@material-ui/core";

const BooleanCheckbox = ({ input, getValue, formik }) => {
  if (!(input.key in formik.values)) formik.setFieldValue(input.key, false);
  const actualValue = getValue(formik.values, input.key)
    ? getValue(formik.values, input.key) === "true"
    : false;
  return (
    <FormControl
      component="fieldset"
      className="formControl"
      style={input.input_layout?.labelStyle}
    >
      <FormGroup>
        <FormControlLabel
          className={`mb-3 ${actualValue ? "checkBox-checked" : ""}`}
          key={input.id}
          control={
            <Checkbox
              style={
                actualValue
                  ? {
                      color: "#C7A058",
                    }
                  : null
              }
              name={input.key}
              data-section={input.section}
              onChange={formik.handleChange}
              checked={actualValue}
              value={actualValue}
              disabled={input.disabled}
            />
          }
          label={input.name}
        />
      </FormGroup>
    </FormControl>
  );
};

export default BooleanCheckbox;
