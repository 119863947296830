import {
  FETCH_WORKLST_PENDING,
  FETCH_WORKLST_FULFILLED,
  FETCH_WORKLST_REJECTED,
} from "./listActionTypes";

const initialState = {
  error: null,
  tasks: [],
  tasksCount: 0,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKLST_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_WORKLST_FULFILLED: {
      return {
        ...state,
        loading: false,
        tasks: action.payload.data.result,
        tasksCount: action.payload.data.count,
      };
    }
    case FETCH_WORKLST_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
