import React, { useEffect, useState } from "react";
import client from "api/apiAuth/guestClient";
import SubmissionType from "./components/SubmissionType";
import SubmissionDate from "./components/SubmissionDate";
import { Button, Grid } from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import _ from "lodash";

export default function SubmissionLimit({ targetData, order }) {
  const [initialValues, setInitialValues] = useState({});
  const [savedData, setSavedData] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [activeType, setActiveType] = useState(null);
  let formik;
  let schema;
  formik = useFormik({
    validationSchema: yup.object().shape(validationSchema),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });
  useEffect(() => {
    if (!_.isEmpty(targetData)) {
      switch (targetData.period) {
        case "foreachDay":
          schema = {
            submissionLimit: yup
              .object()
              .shape({
                Saturday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                Sunday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                Monday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                Tuesday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                Wednesday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                Thursday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                Friday: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
              })
              .required(),
          };
          if (targetData.allowed_types.length) {
            schema.allowed_type = yup.string().required();
          }
          setValidationSchema(schema);
          break;
        case "foreachMonth":
          schema = {
            submissionLimit: yup
              .object()
              .shape({
                January: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                February: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                March: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                April: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                May: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                June: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                July: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                August: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                September: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                October: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                November: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
                December: yup.object().shape({
                  checked: yup.boolean().nullable(),
                  limit: yup.number().when("checked", {
                    is: true,
                    then: yup
                      .number()
                      .typeError("Limit must be a number")
                      .required("submission limit is required"),
                    otherwise: yup.number().notRequired().nullable(),
                  }),
                }),
              })
              .required(),
          };
          if (targetData.allowed_types.length) {
            schema.allowed_type = yup.string().required();
          }
          setValidationSchema(schema);
          break;
        case "daily":
        case "monthly":
        case "weekly":
          if (targetData.type) {
            setValidationSchema({
              allowed_type: yup.string().required(),
              submissionLimit: yup.number().required(),
            });
          } else {
            setValidationSchema({
              submissionLimit: yup.number().required(),
            });
          }
          break;
        default:
          break;
      }
    }

    if (!_.isEmpty(targetData)) {
      if (
        targetData.submissions_limit &&
        !_.isEmpty(targetData.submissions_limit)
      ) {
        let limit = targetData.submissions_limit[targetData.type];
        if (targetData.allowed_types?.types?.length > 0) {
          let allowed_type = Object.keys(limit)[0];
          setInitialValues({
            allowed_type: allowed_type,
            submissionLimit: limit[allowed_type],
          });
        } else {
          setInitialValues({
            submissionLimit: limit,
          });
        }
      }
    }
  }, [targetData]);

  const handleTypeChange = (type) => {
    setActiveType(type);
    let limit = targetData.submissions_limit[targetData.type];
    if (targetData.allowed_types?.types?.length > 0) {
      if (type in savedData) {
        setInitialValues({
          ...savedData[type],
        });
      } else {
        setInitialValues({
          allowed_type: type,
          submissionLimit: limit[type] || 0,
        });
      }
    }
  };

  const handleSubmit = async () => {
    const res = await formik.validateForm();
    if (!_.isEmpty(res) || _.isEmpty(formik.values)) {
      Swal.fire({
        title: "fields are either required or invalid",
        icon: "error",
        confirmButtonColor: "#d36467",
        confirmButtonText: "Try Again",
        width: "50%",
      });
    } else {
      client
        .post("/submissionslimit/save", {
          id: targetData.id,
          workflow_id: targetData.workflow_id,
          values: formik.values,
        })
        .then(() => {
          const oldData = { ...savedData };
          setSavedData({ ...oldData, [activeType]: { ...formik.values } });
          Swal.fire({
            title: "Your Settings has been saved successfully!",
            imageWidth: 250,
            imageHeight: 200,
            imageAlt: "Custom image",
            confirmButtonColor: "#0066cc",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <div style={{ padding: order == 1 ? "0" : "20px 0" }}>
          <span>Section : {order} (<b>{targetData.type}</b>)</span>
        </div>
        <Grid container style={{ padding: "20px 0" }} spacing={4}>
          {targetData.type ? (
            <Grid item xs={12} sm={6}>
              <SubmissionType
                data={targetData}
                formik={formik}
                handleTypeChange={handleTypeChange}
              />
            </Grid>
          ) : null}

          <SubmissionDate data={targetData} formik={formik} />
          <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="primary"
              className="mr-5"
              onClick={() => handleSubmit()}
            >
              submit
            </Button>
          </Grid>
        </Grid>
      </FormikProvider>
    </>
  );
}
