import * as yup from "yup";


 const validationSchema = yup.object().shape({
    signatureArabic: yup
      .mixed()
      .test("fileSize", "File too large", (value) => {
        let width, height;
        if (value) {
          width = value.width >= 50 && value.width <= 1000;
          height = value.height >= 50 && value.height <= 1000;
          return width && height;
        }
      })
      .test("fileType", "Unsupported File Format", function (value) {
        if (value) {
          const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
          return SUPPORTED_FORMATS.includes(value?.type);
        }
      }),
    Stamp: yup
      .mixed()
      .test("fileSize", "File too large", (value) => {
        let width, height;
        if (value) {
          width = value.width >= 50 && value.width <= 1000;
          height = value.height >= 50 && value.height <= 1000;
          return width && height;
        }
      })
      .test("fileType", "Unsupported File Format", function (value) {
        if (value) {
          const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
          return SUPPORTED_FORMATS.includes(value?.type);
        }
      }),

    email: yup.string().required('Required')
    .test(
      "Password has spaces",
      "Careful! Your Password Should not have spaces!",
      value => !/\s+/.test(value)
    ),
    newPassword: yup.string()
    // .min(8,  "Your Password is too short it must be at least 8 characters length!" )
    // .matches(/\d+/,"Password no number"  )
    // .matches(/[a-z]+/,"Password no lowercase"  )
    // .matches(/[A-Z]+/,"Password no uppercase"  )
    // .matches(/[!@#$%^&*()-+]+/,
    //   "Password no special char")
    .test(
      "Password has spaces",
      "Careful! Your Password Should not have spaces!",
      value => !/\s+/.test(value)
    ),
    newPasswordConfirmation: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    });
export default validationSchema