export const handleDocument = (
  formik,
  section,
  sectionName,
  documentName,
  file,
  allFiles,
  document_id
) => {
  let document = {
    data: file,
    name: documentName,
    original_file_name: file?.name,
    document_id,
    section,
    sectionName,
    type: file?.type,
    preview: file?.preview,
  };
  // if (allFiles && allFiles.length > 0) {
  //   formik.setFieldValue(documentName, file);
  //   return document;
  // }
};
