// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AudioRecorder_buttonBase__2KTuG {\n  background-color: #20354f !important;\n  color: white !important;\n  border-radius: 50% !important;\n  padding: 0.25rem !important;\n}\n\n.AudioRecorder_buttonText__e2U3Q {\n  font-size: 36px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AudioRecorder/index.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,6BAA6B;EAC7B,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".buttonBase {\n  background-color: #20354f !important;\n  color: white !important;\n  border-radius: 50% !important;\n  padding: 0.25rem !important;\n}\n\n.buttonText {\n  font-size: 36px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBase": "AudioRecorder_buttonBase__2KTuG",
	"buttonText": "AudioRecorder_buttonText__e2U3Q"
};
export default ___CSS_LOADER_EXPORT___;
