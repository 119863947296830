import React from 'react'
import { SvgIcon } from "@material-ui/core";

const Drug = () => {
    return (
        <SvgIcon viewBox="0 0 500 500">
            <path
                style={{ fill: "#e7eced" }}
                d="M459.334 311.464c43.939 43.939 43.939 115.18 0 159.119-43.939 43.951-115.191 43.951-159.131 0l159.131-159.119z"
            />
            <path
                style={{ fill: "#afb6bb" }}
                d="M300.204 311.464c43.939-43.951 115.191-43.951 159.131 0L300.204 470.583c-43.94-43.939-43.94-115.18 0-159.119z"
            />
            <path
                style={{ fill: "#e7eced" }}
                d="M267.244 120.974h225.051c0 62.144-50.387 112.52-112.52 112.52-62.144 0-112.531-50.376-112.531-112.52z"
            />
            <path
                style={{ fill: "#afb6bb" }}
                d="M492.294 120.974h-225.05c0-62.144 50.387-112.52 112.531-112.52 62.132 0 112.519 50.376 112.519 112.52z"
            />
            <path
                style={{ fill: "#e7eced" }}
                d="M210.984 255.993v225.051c0 12.422-10.066 22.499-22.499 22.499H42.204c-12.422 0-22.499-10.077-22.499-22.499V255.993h191.279z"
            />
            <path
                style={{ fill: "#31c0d8" }}
                d="M210.984 30.954v225.04H19.704V30.954c0-12.422 10.077-22.499 22.499-22.499h146.28c12.434-.001 22.501 10.077 22.501 22.499z"
            />
            <path d="M188.484 0H42.204C25.137 0 11.25 13.885 11.25 30.954v450.09c0 17.068 13.885 30.954 30.954 30.954h146.28c17.068 0 30.954-13.885 30.954-30.954V30.954C219.438 13.885 205.553 0 188.484 0zM42.204 16.908h146.28c7.745 0 14.045 6.3 14.045 14.045v216.585H28.159V30.954c0-7.746 6.301-14.046 14.045-14.046zm146.28 478.181H42.204c-7.745 0-14.045-6.3-14.045-14.045V264.447H202.53v216.597c-.001 7.745-6.301 14.045-14.046 14.045zM379.775 241.948c66.705 0 120.974-54.269 120.974-120.974C500.749 54.269 446.481 0 379.775 0 313.063 0 258.79 54.269 258.79 120.974c-.001 66.706 54.273 120.974 120.985 120.974zm0-16.908c-54.542 0-99.415-42.168-103.732-95.611h207.453c-4.318 53.442-49.185 95.611-103.721 95.611zm0-208.132c54.535 0 99.403 42.168 103.721 95.611H276.043c4.318-53.442 49.191-95.611 103.732-95.611zM379.769 270.046c-32.315 0-62.696 12.587-85.543 35.439-47.165 47.165-47.165 123.909 0 171.075 22.848 22.853 53.228 35.439 85.544 35.439s62.696-12.587 85.543-35.439c22.849-22.848 35.431-53.225 35.431-85.537s-12.583-62.69-35.43-85.537c-22.849-22.853-53.23-35.44-85.545-35.44zm-73.587 47.396c19.654-19.659 45.788-30.486 73.587-30.486 24.96 0 48.561 8.753 67.351 24.766L300.467 458.364c-34.729-40.84-32.844-102.363 5.715-140.922zm177.653 73.582c0 27.796-10.825 53.928-30.48 73.582-19.654 19.659-45.788 30.486-73.587 30.486-24.959 0-48.561-8.753-67.351-24.765l146.658-146.648c16.009 18.789 24.76 42.388 24.76 67.345z" />
        </SvgIcon>
    )
}

export default Drug