import React, { useState, useEffect } from "react";

import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import fontawesome from "@fortawesome/fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessModal from "components/SucessModal";
import TransitionForm from "./TransitionForm";
import _ from "lodash";
import { setSchema } from "utils/setValidationSchema";
import { apiConfig } from "api/utilities";
import client from "api/apiAuth/guestClient";

import "./style.css";
fontawesome.library.add(faCaretLeft, faCaretRight);

function FormModal(props) {
  const {
    open,
    setOpen,
    task_id,
    submission,
    attachmentsUnderReview,
    step_id,
    getTaskDetails,
    getTimeline,
    edit_form,
    runAferTransitionAction
  } = props;
  const [successModal, setSuccessModal] = useState(false);
  const [selectedTransition, setSelectedTransition] = useState({});
  const [validationSchema, setvalidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [transitions, setTransitions] = useState([]);
  const fetchTransitions = async () => {
    if (step_id && submission && task_id) {
      let { data } = await client.get("/tasks/stepTransitions", {
        params: {
          step_id,
          submission_id: submission?.id,
          task_id,
        },
      });
      setTransitions(data.transitions);
    }
  };
  useEffect(() => {
    fetchTransitions();
  }, [step_id, submission, task_id]);
  useEffect(() => {
    let values = {};
    if (selectedTransition?.sections?.length) {
      selectedTransition.sections.forEach((section) =>
        section.inputs.forEach((input) => {
          let attachment = submission.attachments.find(
            (attachment) => attachment.document.key == input.key
          );
          const hasInititalValue = "initialValue" in input;
          if (
            (submission.values[input.key] && input.initialValue) ||
            (attachment && hasInititalValue && input.initialValue) ||
            (attachment && !hasInititalValue)
          ) {
            if (input.itype === "document") {
              values = {
                ...values,
                [input.key]: {
                  ...attachment,
                  fileObject: `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  }`,
                },
              };
            } else {
              values = { ...values, [input.key]: submission.values[input.key] };
            }
          } else {
            values = { ...values };
          }
          setInitialValues(values);
        })
      );
      setvalidationSchema(
        setSchema(
          selectedTransition.sections.map((section) => section.inputs).flat()
        )
      );
    } else {
      values = {};
      setInitialValues(values);
      setvalidationSchema({});
    }
  }, [selectedTransition]);
  const handleChoose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let transition = transitions[parseInt(e.currentTarget.dataset.i)];
    setSelectedTransition(transition);
  };

  return (
    <>
      <Dialog
        classes={{ paper: "modal-content" }}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title2"
        style={{ borderRadius: "6px", width: "85%", margin: "auto" }}
      >
        <DialogContent className="p-0">
          <div>
            <div className="text-center pb-5 pl-5 pr-5 pb-4 pt-4">
              <div className="card-header d-block bg-white pt-4 pb-4">
                <div className="text-primary text-center h3 mb-5">
                  <p className="modal-title">
                    {/* fixMe: Localization */}
                    <strong> Choose your next action </strong>
                  </p>
                </div>
                <Grid container alignItems="center" justifyContent="center">
                  {transitions?.map(
                    (t, i) =>
                      !t.hidden && (
                        <Grid item xs={6} key={i}>
                          <Button
                            className="btn btn-transition-none mr-2 mb-2"
                            size="large"
                            color="primary"
                            hidden={t.hidden}
                            style={{
                              boxShadow: "none",
                              border: "2px solid",
                              borderRadius: "5px",
                              width: "86%",
                              height: "41px",
                            }}
                            disableFocusRipple
                            disableRipple
                            variant={
                              selectedTransition.id === t.id
                                ? "contained"
                                : "outlined"
                            }
                            key={t.id}
                            data-i={i}
                            onClick={handleChoose}
                          >
                            {t?.style?.icon?.position === "right" && (
                              <FontAwesomeIcon
                                icon={["fa", t?.style?.icon?.name]}
                                className="ml-1"
                              />
                            )}
                            <b>{t.name}</b>
                            {t?.style?.icon?.position == "left" && (
                              <FontAwesomeIcon
                                icon={["fa", t?.style?.icon?.name]}
                                className="mr-1"
                              />
                            )}
                          </Button>
                        </Grid>
                      )
                  )}
                </Grid>
              </div>

              <TransitionForm
                validationSchema={validationSchema}
                selectedTransition={selectedTransition}
                attachmentsUnderReview={attachmentsUnderReview}
                submission={submission}
                getTaskDetails={getTaskDetails}
                getTimeline={getTimeline}
                initialValues={
                  selectedTransition?.sections?.length ? initialValues : {}
                }
                setOpen={setOpen}
                task_id={task_id}
                edit_form={edit_form}
                runAferTransitionAction={runAferTransitionAction}
              ></TransitionForm>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}
      ></SuccessModal>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    attachmentsUnderReview: state.details.attachmentsUnderReview,
  };
};

export default connect(mapStateToProps)(FormModal);
