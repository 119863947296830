import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  Menu,
  Button,
  TextField,
} from "@material-ui/core";

import _ from "lodash";
import React, { useState } from "react";

import Chart from "react-apexcharts";
import FilterListTwoToneIcon from "@material-ui/icons/FilterListTwoTone";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import SaveTwoToneIcon from "@material-ui/icons/SaveTwoTone";
export default function ChartApex({
  xaxis,
  keyName,
  headerName,
  keyXaxis,
  endPointName,
  handleFilterData,
  filters,
  setFilters,
  stateName,
  field,
  handleResetFilterData,
}) {
  const options = {
    // dataLabels: {
    //   enabled: false,
    // },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%",
      },
    },
    xaxis: {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
      },
      categories: xaxis ? xaxis?.map((x) => _.get(x, keyXaxis)) : [],
    },
    yaxis: {
      floating: false,
      formatter: function (val, index) {
        return val.toFixed(2);
      },
    },
  };
  const series = [
    {
      name: keyName,
      data: xaxis ? xaxis?.map((x) => x[keyName]) : [],
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateFlag, setDateFlag] = useState(false);

  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [dateValues, setDateValues] = useState({
    from: "",
    to: "",
  });
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
    setFilters([]);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setDateValues({ from: "", to: "" });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let DatesValue = [];
  var obj = {};
  const addFilterData = (field, value, operator, key) => {
    let filterObject;
    let filterIndex;

    filterIndex = filters.findIndex((filter) => filter.field === field);
    filterObject = {
      field: field,
      value: typeof value === "string" ? value.toLowerCase() : value,
      operator: operator,
    };

    let newFilters = [...filters];
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObject;
    } else {
      newFilters.push(filterObject);
    }
    setFilters([...newFilters]);
  };
  const handleFilter = (field, filterValue, operator, endPointName) => {
    obj = { ...dateValues, [field]: filterValue };

    DatesValue = Object.values(obj);
    setDateValues(obj);
    filters.push({
      field: "event_date_from",
      value: DatesValue,
      operator: "between",
    });
    addFilterData("event_date_from", DatesValue, "between");

    //
  };

  const getFilterData = (e, endPointName, stateName) => {
    handleFilterData(filters, endPointName, stateName, field);
    setAnchorElFilter(null);
    setDateFlag(true);
  };
  const cancelFilter = (e, endPointName, stateName, field) => {
    e.preventDefault();
    setFilters([]);
    handleResetFilterData([], endPointName, stateName, field, null);
    setAnchorElFilter(null);
  };
  return (
    <>
      <Card className="card-box overflow-visible mb-spacing-6-x2">
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-0 text-black">
              {headerName}
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <Button
                onClick={handleClickFilter}
                variant="text"
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill"
              >
                <FilterListTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorElFilter}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElFilter)}
                classes={{ list: "p-0" }}
                onClose={handleCloseFilter}
              >
                <div className="dropdown-menu-xxl overflow-hidden p-0">
                  <div className="p-3">
                    {/* <Grid container spacing={6}> */}
                    {/* <Grid item md={6}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className="w-100"
                        >
                          <InputLabel id="type-select-label">From</InputLabel>
                        </FormControl>
                      </Grid> */}
                    <Grid
                      container
                      className="dateStyle"
                      style={{ width: "98%" }}
                    >
                      <Grid item xs={6}>
                        <span className="spanStyle">From</span>
                        <TextField
                          id="date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event, date) => {
                            event.persist();
                            handleFilter(
                              "from",
                              event.target.value,
                              "from",
                              endPointName
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <span className="spanStyle">To</span>
                        <TextField
                          id="date"
                          type="date"
                          defaultValue={new Date()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event, date) => {
                            event.persist();
                            let currentDate = new Date(event.target.value);
                            currentDate.setDate(currentDate.getDate());
                            handleFilter(
                              "to",
                              currentDate.toISOString(),
                              "to",
                              endPointName
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                  </div>
                  <div className="divider" />
                  <div className="p-3 text-center bg-secondary">
                    <Button
                      className="btn-primary"
                      size="small"
                      onClick={(e) =>
                        getFilterData(e, endPointName, stateName, field)
                      }
                    >
                      Filter results
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <List
                          component="div"
                          className="nav-neutral-danger flex-column p-0"
                        >
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            onClick={(e) =>
                              cancelFilter(e, endPointName, stateName, field)
                            }
                          >
                            <DeleteTwoToneIcon className="mr-2" />
                            <span>Cancel</span>
                          </ListItem>
                        </List>
                      </Grid>
                      {/* <Grid item md={6}>
                        <List
                          component="div"
                          className="nav-neutral-success flex-column p-0"
                        >
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                          >
                            <SaveTwoToneIcon className="mr-2" />
                            <span>Save filter</span>
                          </ListItem>
                        </List>
                      </Grid> */}
                    </Grid>
                  </div>
                </div>
              </Menu>
            </div>
          </div>
        </div>
        <CardContent>
          <Chart options={options} series={series} type="bar" />
        </CardContent>
      </Card>
    </>
  );
}
