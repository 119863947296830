import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import _ from "lodash";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { addSubmissionsToGroup } from "reducers/grouping";
import Swal from "sweetalert2";
import imag from "assets/images/Mailsent-rafiki.png";
import { connect } from "react-redux";
import client from "api/apiAuth/guestClient";
import { GroupSharp } from "@material-ui/icons";

const AddToGroup = (props) => {
  let { section, formik, loadMore, task } = props;
  const [groups, setgroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [open, setOpen] = useState(false);
  const [clickOutSide, setClickOutSide] = useState(false);
  const getGroups = async () => {
    let { data } = await client.get("generic/find", {
      params: {
        model: "GroupType",
        filter: { start_step: task.step.id },
        limit: 30,
        skip: 0,
        include: {
          association: "groups",
          where: { status: ["New"] },
          include: { association: "submissions" },
        },
      },
    });
    setgroups([...data.rows.map((row) => row.groups).flat()]);
  };
  useEffect(() => {
    task && getGroups();
  }, []);
  useEffect(() => {
    groups &&
      groups.length > 0 &&
      setSelectedGroup({
        selectedGroup,
        ...groups.find((group) => {
          if (group?.submissions) {
            return (
              group.submissions.map((sub) => {
                return task.submission.id === sub.id;
              })[0] && group
            );
          }
        }),
      });
  }, [groups]);
  const onLoadMore = async (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const onChange = (ev, value) => {
    formik.handleChange(ev, value);
    if (ev.target.value) {
      setOpen(false);
    }
  };
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickOutSide(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  const setSubmissionGroup = () => {
    const submissionsIds = [task.submission.id];
    props
      .addSubmissionsToGroup({ groupId: selectedGroup.id, submissionsIds })
      .then((res) =>
        Swal.fire({
          title:
            "The selected Submissions have been added successfully to the group!",
          imageUrl: imag,
          imageWidth: 250,
          imageHeight: 200,
          imageAlt: "Custom image",
          confirmButtonColor: "#0066cc",
          confirmButtonText: "Ok",
        }).then(() => {
          window.location.reload();
        })
      );
  };
  return (
    <Grid container>
      <Grid
        style={{
          display: "flex",
          alignContent: "flex-start",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "5%",
        }}
        item
        xs={12}
      >
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="demo-simple-select-outlined-label">
            {selectedGroup?.name || "Select Committe"}
          </InputLabel>
          {groups.length > 0 && (
            <Select
              labelId="demo-simple-select-outlined-label"
              label="Select Committe"
              className="input-border"
              name="groups"
              id="demo-simple-select-outlined"
              defaultValue={selectedGroup.name}
              open={open}
              onClose={() => clickOutSide && setOpen(false)}
              onOpen={() => setOpen(true)}
              onChange={(ev, value) => onChange(ev, value)}
              ref={ref}
              onBlur={formik.handleBlur}
              style={{ width: "100%" }}
            >
              {groups?.length &&
                groups?.map((option, index) => {
                  return (
                    <MenuItem
                      key={option.id || index}
                      value={option.label || option.id}
                      style={option.disabled ? { pointerEvents: "all" } : {}}
                      disabled={option.disabled ? option.disabled : false}
                      onClick={(e) => {
                        if (option.disabled) {
                          e.stopPropagation();
                          e.defaultPrevented();
                          setOpen(true);
                        }
                        setSelectedGroup({ selectedGroup, ...option });
                      }}
                    >
                      <span>{option.label || option.name}</span>
                    </MenuItem>
                  );
                })}
              {loadMore && (
                <MenuItem
                  value=""
                  onClick={(e) => onLoadMore(e)}
                  key="loadMore"
                >
                  <b>Load More</b>
                </MenuItem>
              )}
            </Select>
          )}
        </FormControl>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignContent: "flex-start",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          paddingBottom: "5%",
        }}
        item
        xs={12}
      >
        <Button
          className="mx-1 px-5 py-2 mt-4"
          size="large"
          color="primary"
          variant="contained"
          style={{ borderRadius: "5px" }}
          onClick={setSubmissionGroup}
          disabled={_.isEmpty(formik.values)}
        >
          <span className="btn-wrapper--label ml-2 font-size">
            Submit Committe
          </span>
        </Button>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSubmissionsToGroup: (body) => dispatch(addSubmissionsToGroup(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddToGroup);
