import { setAllAttachments, setAttachmentReviewed } from "reducers/details";
import { connect } from "react-redux";
import { useState } from "react";
import Document from "pages/EdaDetails/components/Document";
import ViewFile from "pages/EdaDetails/components/ViewFile";
const AttrWithAttachment = ({
  task,
  value,
  allAttachments,
  attachmentsUnderReview,
  allProps,
  currentUser,
  filterKey,
}) => {
  const { formik } = allProps;
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [attachments, setAttachments] = useState([]);

  let targetAttachment = allAttachments.find((att) => {
    return filterKey ? att[filterKey] == value : att.id == value;
  });
  let attachment = { ...targetAttachment };
  attachment.majorReviewers = ["User", "Technical Committee Rep"];
  attachment.minorReviewers = ["Junior HF", "Examination Unit Manager"];
  attachment.passWithRejection = false;
  return (
    <>
      <Document
        attachment={attachment}
        setFile={setFile}
        setOpen={setOpen}
        allAttachments={allAttachments}
        setAllAttachments={setAllAttachments}
        attachmentsUnderReview={attachmentsUnderReview}
        setAttachmentReviewed={setAttachmentReviewed}
        selectedTab={{
          id: 7,
        }}
        currentUser={currentUser}
        task={task}
        sectionId={1}
        formik={formik}
      />
      <ViewFile file={file} open={open} setOpen={setOpen} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allAttachments: state.details.allAttachments,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
    currentUser: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllAttachments: (attachments) =>
      dispatch(setAllAttachments(attachments)),
    setAttachmentReviewed: (attachments) =>
      dispatch(setAttachmentReviewed(attachments)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AttrWithAttachment);
