// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard_statcard__0Y7MS {\n  width: 100%;\n}\n\n.Dashboard_statcard__title__RnvHL {\n  font-size: 16px;\n}\n\n.Dashboard_statcard__number__WKRqy {\n  font-size: 24px;\n  font-weight: 700%;\n}\n\n.Dashboard_statcard__icon__LhK9S {\n  font-size: 75px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Dashboard/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".statcard {\n  width: 100%;\n}\n\n.statcard__title {\n  font-size: 16px;\n}\n\n.statcard__number {\n  font-size: 24px;\n  font-weight: 700%;\n}\n\n.statcard__icon {\n  font-size: 75px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statcard": "Dashboard_statcard__0Y7MS",
	"statcard__title": "Dashboard_statcard__title__RnvHL",
	"statcard__number": "Dashboard_statcard__number__WKRqy",
	"statcard__icon": "Dashboard_statcard__icon__LhK9S"
};
export default ___CSS_LOADER_EXPORT___;
