import React, { useEffect, useState } from "react";
import lodash from "lodash";
import imag from "../../assets/images/evers.png";
import footerImage from "../../assets/images/evers-letter-footer.png";
import NoSignatureImage from "../../assets/images/nosignature.jpg";
import config from "../index.js";
import client from "api/apiAuth/guestClient";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
}) => {
  const [groupData, setGroupData] = useState(null);
  const [generalManagerSignature, setGeneralManagerSignature] = useState(null);
  const [
    scientificCommitteeManagerSignature,
    setScientificCommitteeManagerSignature,
  ] = useState(null);
  const [
    technicalAffairsManagerSignature,
    setTechnicalAffairsManagerSignature,
  ] = useState(null);
  useEffect(async () => {
    const urlCreator = window.URL || window.webkitURL;
    setValues(config(letter, formValues, "refusalOfTechnicalCommittee"));
    const response = await client.post("generic/find", {
      model: "SubmissionGroup",
      include: {
        association: "submissions",
        where: { id: letter.id },
      },
      limit: 1,
      order: [["createdAt", "DESC"]],
    });
    setGroupData(response.data.rows[0]);
    const users = await client.post("generic/find", {
      model: "User",
      include: {
        association: "roles",
        where: {
          name: [
            "Scientific Evaluation Unit Manager",
            "General Manager",
            "Technical Affairs Soft Files",
          ],
        },
      },
    });

    const generalManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "General Manager" })
    );
    if (generalManager && generalManager.signatureEnglish) {
      const generalManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: generalManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([generalManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setGeneralManagerSignature);
    } else toDataURLWithCallback(NoSignatureImage, setGeneralManagerSignature);

    const scientificCommitteeManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Scientific Evaluation Unit Manager" })
    );
    if (
      scientificCommitteeManager &&
      scientificCommitteeManager.signatureEnglish
    ) {
      const scientificCommitteeManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: scientificCommitteeManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([scientificCommitteeManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setScientificCommitteeManagerSignature);
    } else
      toDataURLWithCallback(
        NoSignatureImage,
        setScientificCommitteeManagerSignature
      );

    const technicalAffairsManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Technical Affairs Soft Files" })
    );
    if (technicalAffairsManager && technicalAffairsManager.signatureEnglish) {
      const technicalAffairsManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: technicalAffairsManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([technicalAffairsManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setTechnicalAffairsManagerSignature);
    } else
      toDataURLWithCallback(
        NoSignatureImage,
        setTechnicalAffairsManagerSignature
      );
  }, []);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  function toDataURLWithCallback(src, callback) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  toDataURL(imag);
  useEffect(() => {
    const refusalOfTechnicalCommittee = document.getElementById(
      "refusalOfTechnicalCommittee"
    );
    setDivRef({
      ...divRef,
      refusalOfTechnicalCommittee,
      documentValues: values,
    });
  }, [values]);
  return (
    <div
      style={{ padding: "20px", zoom: 0.7, color: "black" }}
      id="refusalOfTechnicalCommittee"
    >
      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            General Administration
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "11pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Of Veterinary Pharmaceuticals
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات البيطرية
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
          marginTop: "30px",
        }}
      />
      <div
        className="row d-flex flex-column"
        style={{
          width: "100%",
        }}
      >
        <div style={{ display: "flex" }}>
          <h4 style={{ textDecoration: "underline", fontSize: "20px" }}>
            Code: {letter.values.reg_number}
          </h4>
          <h4 style={{ textDecoration: "underline", fontSize: "20px" }}>
            /{new Date().getFullYear()}
          </h4>
        </div>
        <h4
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            alignSelf: "self-start",
            margin: "15px",
          }}
        >
          (عدم موافقة )
        </h4>
      </div>
      <div
        className="row"
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          السيد الدكتور/ رئيـس مجلس إدارة شركة
        </span>
        <input
          type="text"
          style={{
            border: "none",
            width: "15%",
            direction: "rtl",
            fontSize: "20px",
          }}
          name="companyManagerName"
          placeholder="............."
          value={values.companyManagerName}
          onChange={handleChange}
          className="mr-2"
        ></input>
      </div>

      <div
        className="row mt-3"
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          تحية طيبــة وبعد ...
        </span>
      </div>

      <div className="row">
        <p
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
            marginBottom: "0px",
          }}
        >
          نتشرف بالإحاطة بأن اللجنة الفنية لمراقبة الأدوية قد قـررت بجلسة{" "}
          {groupData
            ? ` ${new Date(letter.values.committe_date).toLocaleDateString(
                "ar-EG"
              )} `
            : "??"}
          عدم المـوافقة على السير فى إجراءات تسجيل المستحضر البيطرى :
        </p>
      </div>

      <table
        className="sc-table"
        style={{
          width: "100%",
          cursor: "default",
          borderCollapse: "collapse",
          fontSize: "20px",
          margin: "40px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              {letter.values.trade_name}
            </td>
            <td
              style={{
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              {letter.drugs[0].dosage_form}
            </td>
          </tr>
          <tr>
            <td
              style={{
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              {letter.drugs[0].ingredients
                .map(
                  (ingredient) =>
                    `${ingredient.active_ingredient} ${ingredient.full_strength}`
                )
                .join(" , ")}
            </td>
          </tr>
          {(letter.values.registration_type === "Imported" ||
            letter.values.registration_type === "Imported Bulk") && (
            <tr>
              <td
                style={{
                  cursor: "default",
                  borderCollapse: "collapse",
                }}
              >
                "License Holder"
              </td>
              <td
                style={{
                  cursor: "default",
                  borderCollapse: "collapse",
                }}
              >
                {letter.values.license_holder}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="row" style={{ display: "flex", justifyContent: "end" }}>
        <p
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
            marginBottom: "0px",
          }}
        >
          و ذلك إستناداً لقرار اللجنة العلمية المتخصصة للأدوية البيطرية و إضافات
          الأعلاف بجلسة{" "}
          {groupData
            ? ` ${new Date(groupData.values.start_date).toLocaleDateString(
                "ar-EG"
              )} `
            : "??"}
          و الذي ينص على{" "}
        </p>
      </div>
      <p style={{ marginTop: "30px" }}>
        <textarea
          style={{
            width: "100%",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "500",
            fontSize: "16pt",
            direction: "rtl",
            border: "none",
          }}
          rows={3}
          value={values.tableComment}
          name="tableComment"
          autoCorrect="off"
          onChange={handleChange}
        />
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          marginTop: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 600,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginLeft: "20px",
            }}
          >
            مدير عام الإدارة العامة
            <br /> للمستحضرات البيطرية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName3}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "600",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير إدارة الشئون الفنية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName2}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير وحدة التقييم العلمى
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName1}
          </span>
        </div>
      </div>

      <div style={{ display: "d-flex", maginTop: "1.5rem" }}>
        <img
          src={generalManagerSignature}
          style={{ width: "33%", height: "100px", border: "none" }}
        />
        <img
          src={technicalAffairsManagerSignature}
          style={{ width: "33%", height: "100px", border: "none" }}
        />
        <img
          src={scientificCommitteeManagerSignature}
          style={{ width: "33%", height: "100px", border: "none" }}
        />
      </div>

      <ul
        style={{
          direction: "rtl",
          textAlign: "right",
          marginTop: "3rem",
        }}
        contentEditable="true"
        suppressContentEditableWarning={true}
      >
        {values &&
          values.firstList?.map((name, index) => (
            <li
              style={{ direction: "rtl" }}
              contentEditable="true"
              suppressContentEditableWarning={true}
            >
              {name}
            </li>
          ))}
      </ul>

      <img src={footerImage} className="w-100" />
    </div>
  );
};
