import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "reducers";
import "font-awesome/css/font-awesome.min.css";
import { Helmet } from "react-helmet";
const {
  title,
  projectLogo,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/general`);

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={projectLogo} />
    </Helmet>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
