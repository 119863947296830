import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import Info from "../Info";
import {
  getLoadMoreEvents,
  getOnchangeEvents,
} from "utils/FormUtils/Events/EventsHelperFunctions";
import { connect } from "react-redux";
import { getValue } from "utils/GetObjectValues";
import _ from "lodash";

const Selection = ({
  input,
  formik,
  inputValue,
  loadMore,
  onValueChange,
  inputEvents,
}) => {
  const [options, setOptions] = useState([]);
  const [uniqueOptions, setUniqueOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickOutSide, setClickOutSide] = useState(false);

  const findEventInput = () => inputEvents.find((e) => e.id === input.id);

  const onLoadMore = async (e) => {
    e.stopPropagation();
    setOpen(true);
    const eventInput = findEventInput();
    if (eventInput) await getLoadMoreEvents(eventInput);
  };
  const getInitialOptions = async () => {
    if (input?.itype === "enum" && input.options?.length === 0) {
      input.options = [
        { label: "Yes", value: "true", id: "Yes" },
        { label: "No", value: "false", id: "No" },
      ];
    }
    const eventInput = findEventInput();

    setOptions(eventInput ? eventInput.options : input?.options);
  };
  const onChange = (ev, value) => {
    formik.handleChange(ev, ev.target.value);
    const eventInput = findEventInput();

    if (eventInput)
      getOnchangeEvents(
        { ...eventInput, realKey: input?.key },
        formik,
        ev.target.value,
        input?.key
      ).then(() => {
        setOptions(eventInput?.options);
      });
    if (ev.target.value) {
      setOpen(false);
    }
    if (onValueChange) {
      onValueChange(input, ev.target.value, ev);
    }
  };
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickOutSide(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    getInitialOptions();
  }, []);

  useEffect(() => {
    const eventInput = findEventInput();
    setOptions(eventInput ? eventInput?.options : input?.options);
  }, [input, inputEvents]);

  useEffect(() => {
    setUniqueOptions(
      (options.some((o) => "id" in o) && _.uniqBy(options, "id")) || options
    );
  }, [options]);
  return (
    <>
      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel
          id="demo-simple-select-outlined-label"
          style={input.input_layout?.labelStyle}
        >
          {/* {input.name} */}
          {input.required? `${input.name} *`:input.name}

        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          label={input?.name}
          name={input?.key}
          id="demo-simple-select-outlined"
          value={options?.length && inputValue ? inputValue : ""}
          open={open}
          onClose={() => clickOutSide && setOpen(false)}
          onOpen={() => setOpen(true)}
          onChange={(ev, value) => onChange(ev, value)}
          ref={ref}
          onBlur={formik.handleBlur}
          disabled={input?.disabled}
          style={{ width: "100%", padding: "0px !important" }}
        >
          {uniqueOptions.length > 0 &&
            (input.input_layout?.validation?.key
              ? uniqueOptions.filter(
                  (opt) =>
                    opt.id !==
                    getValue(formik.values, input.input_layout?.validation?.key)
                )
              : uniqueOptions
            )?.map((option, index) => {
              return (
                <MenuItem
                  key={option.id || index}
                  value={option.label || option.id}
                  style={option.disabled ? { pointerEvents: "all" } : {}}
                  disabled={option.disabled ? option.disabled : false}
                  onClick={(e) => {
                    if (option.disabled) {
                      e.stopPropagation();
                      e.defaultPrevented();
                      setOpen(true);
                    }
                  }}
                >
                  {option.disabled ? (
                    <Tooltip title={option.message} arrow placement="right">
                      <span disabled>{option.label || option.name}</span>
                    </Tooltip>
                  ) : (
                    <span>{option.label || option.name}</span>
                  )}
                </MenuItem>
              );
            })}
          {loadMore && input?.optionsParams?.count > options?.length && (
            <MenuItem value="" onClick={(e) => onLoadMore(e)} key="loadMore">
              <b>Load More</b>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {input.input_layout.displaySelectedData === "true" && inputValue && (
        <Info value={inputValue} options={options} input={input} />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({ inputEvents: state.form.inputEvents });
export default connect(mapStateToProps)(Selection);
