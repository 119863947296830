// material-ui
import { Grid, Typography, Container } from "@mui/material";
import Tiles from "../../assets/images/tiles.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import RocheLogoWhite from "../../assets/images/Roche_Logo.svg-_1_.png";
import unifyLogo from "../../assets/images/unify.png";
import RocheLogoBlue from "../../assets/images/rocheLogo.png";
import { connect } from "react-redux";
import { connectSocket, send, receive } from "reducers/socket";
import { login, setIsLoggedIn } from "reducers/auth";

// project imports
import AuthWrapper2 from "./AuthWrapper2";
import AuthLogin from "./AuthLogin";

// assets

const Login = () => {
  return (
    <AuthWrapper2>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          minWidth: "100vw",
          maxWidth: "100vw",
          overflowX: "hidden",
          backgroundColor: { xs: "#0066CC", lg: "#fff" },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          lg={6}
          // direction="column"
          pl={{ xs: 5, lg: 15 }}
          alignItems="center"
          justifyContent="space-around"
          sx={{
            height: { xs: "100vh", lg: "30vh" },
            backgroundColor: { xs: "#fff", lg: "#fff" },
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            lg={12}
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <img alt="roche logo white" src={unifyLogo} width={"40%"} />
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={12} lg={12} direction="column">
            <Grid item>
              <Typography variant="h5">
                Logout the past, Login to the
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#0066CC" variant="h5">
                new!
              </Typography>
            </Grid>
            <Grid item pl={2} mt={5}>
              <AuthLogin />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            pr={5}
            display={{ xs: "flex", lg: "none" }}
          >
            <img
              alt="roche logo blue"
              src={RocheLogoBlue}
              height={50}
              width={100}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          sx={{ display: { xs: "none", lg: "block" } }}
        >
          <Container
            sx={{
              minHeight: "100vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0066CC",
              backgroundImage: `url(${Tiles})`,
              backgroundSize: "cover",
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item container xs={6} lg={7}>
                <Typography
                  color="white"
                  align="left"
                  variant="h5"
                  p={{ md: 10, lg: 10, xl: 15 }}
                >
                  Manage your Events, Engagements and Consents easily in just a
                  few clicks.{" "}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                lg={4}
                pr={5}
                justifyContent="flex-end"
              >
                <img
                  alt="roche logo white"
                  src={RocheLogoWhite}
                  width={100}
                  height={50}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </AuthWrapper2>
  );
};

export default Login;
