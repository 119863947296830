import React, { useState } from 'react';

import { Menu, Button } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { MenuItem } from 'react-contextmenu';

const HeaderMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="app-header-menu ">
        <div className=" d-flex align-items-center justify-content-center flex-wrap ">
          <Button
            aria-controls="simple-menu"
            justify="space-between"
            className="btn-light m-2 float-left"
            variant="contained"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreHorizIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            classes={{ list: 'p-0' }}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <div className="p-3">
              <MenuItem
                className="pr-5 px-3 text-primary"
                onClick={handleClose}>
                Profile
              </MenuItem>
              <MenuItem
                className="pr-5 px-3 text-primary"
                onClick={handleClose}>
                My account
              </MenuItem>
              <MenuItem
                className="pr-5 px-3 text-primary"
                onClick={handleClose}>
                Logout
              </MenuItem>
            </div>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default HeaderMenu;
