import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import client from "api/apiAuth/guestClient";
import { fetchWorklistLayout } from "reducers/list";
import { runActions } from "reducers/grouping";
import Swal from "sweetalert2";
import imag from "assets/images/Mailsent-rafiki.png";
function AssignDoctorModal({
  group,
  action,
  runActions,
  open,
  setOpen,
  submissionIds,
  onActionComplete,
}) {
  const [doctor, setDoctor] = useState({});
  const [doctors, setDoctors] = useState([]);
  useEffect(() => {
    client.get("/doctor").then((res) => setDoctors([...res.data.doctors]));
  }, []);
  const handleAssignDoctor = () => {
        runActions({
          groupId: group.groupId,
          submissionIds,
          actionId: action.id,
          data: doctor,
        }).then((res) =>
          Swal.fire({
            title:
              "Doctor is Assigned Successfully to submission",
            imageUrl: imag,
            imageWidth: 250,
            imageHeight: 200,
            imageAlt: "Custom image",
            confirmButtonColor: "#0066cc",
            confirmButtonText: "Ok",
          }).then(() => {
            setOpen(false);
            onActionComplete();
          })
        );
  };

  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className="titleModal"> {action.title} </span>
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} md={12}>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <Select
              value={doctor.id}
              onChange={(e) =>
                setDoctor({
                  ...doctor,
                  ...doctors.find((doctor) => doctor.id == e.target.value),
                })
              }
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              {doctors.map((doctor) => (
                <MenuItem key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            container
            xs={4}
            justifyContent="flex-end"
            className="mt-3"
          >
            <Grid item xs={5} justifyContent="flex-end">
              <Button
                variant="outlined"
                className="btn btn-secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5} justifyContent="flex-end">
              <Button
                variant="outlined"
                className="btn btn-success"
                onClick={handleAssignDoctor}
                disabled={!doctor.id}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
  return {
    columns: state.layout.columns,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(fetchWorklistLayout(params)),
    runActions: (data) => dispatch(runActions(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignDoctorModal);
