export const GET_SUBMISSION_LIMIT_PER_COMPANY =
  "GET_SUBMISSION_LIMIT_PER_COMPANY";
export const GET_SUBMISSION_LIMIT_PER_COMPANY_PENDING =
  "GET_SUBMISSION_LIMIT_PER_COMPANY_PENDING";
export const GET_SUBMISSION_LIMIT_PER_COMPANY_FULFILLED =
  "GET_SUBMISSION_LIMIT_PER_COMPANY_FULFILLED";
export const GET_SUBMISSION_LIMIT_PER_COMPANY_REJECTED =
  "GET_SUBMISSION_LIMIT_PER_COMPANY_REJECTED";

  export const GET_COMPANY_ALLOWED_SUBMISSION_COUNT =
  "GET_COMPANY_ALLOWED_SUBMISSION_COUNT";
export const GET_COMPANY_ALLOWED_SUBMISSION_COUNT_PENDING =
  "GET_COMPANY_ALLOWED_SUBMISSION_COUNT_PENDING";
export const GET_COMPANY_ALLOWED_SUBMISSION_COUNT_FULFILLED =
  "GET_COMPANY_ALLOWED_SUBMISSION_COUNT_FULFILLED";
export const GET_COMPANY_ALLOWED_SUBMISSION_COUNT_REJECTED =
  "GET_COMPANY_ALLOWED_SUBMISSION_COUNT_REJECTED";

export const SET_AVAILABLE_SUBMISSIONS_COUNT =
  "SET_AVAILABLE_SUBMISSIONS_COUNT";
export const GET_AVAILABLE_SUBMISSIONS_COUNT =
  "GET_AVAILABLE_SUBMISSIONS_COUNT";

