import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { setAllAttachments, setAttachmentReviewed } from "reducers/details";
import ViewFile from "../../ViewFile";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Document from "../../Document";
import ModificationDetails from "../ModificationDetails/ModificationDetails";
import { getValue } from "utils/GetObjectValues";
import { AccordionDetails } from "@material-ui/core";
const documentsConfig = require(`../../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/documents`);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const SimpleAccordion = (props) => {
  let {
    submissionModifications,
    attachmentReviewed,
    setAttachmentReviewed,
    allAttachments,
    setAllAttachments,
    selectedTab,
    sectionId,
    initialValues,
    setInitialValues,
    schemaInputs,
    setSchemaInputs,
    formik,
    attachmentsUnderReview,
    task,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [attachments, setAttachments] = React.useState([]);
  let documents = documentsConfig
    .config({})
    .find(
      (sectionContent) =>
        sectionContent.tabId === selectedTab.id &&
        sectionContent.sectionId === sectionId
    );
  React.useEffect(() => {
    if (allAttachments) {
      let filteredAttachments = [];
      let clonedAttachments = _.cloneDeep(allAttachments);
      clonedAttachments.forEach((attachment) => {
        let foundAttachment = documents.attachments.find(
          (configAttachment) =>
            configAttachment.equivalent_key ===
              attachment.document.equivalent_key &&
            attachment.submission_modification_id
        );
        if (foundAttachment) {
          filteredAttachments.push({
            ...attachment,
            underRevision: foundAttachment.underRevision,
            majorReviewers: foundAttachment.majorReviewers,
            minorReviewers: foundAttachment.minorReviewers,
            passWithRejection: foundAttachment.passWithRejection,
            uploadedBy: foundAttachment.uploadedBy,
            info: foundAttachment.info,
          });
        }
      });
      setAttachments(filteredAttachments || []);
    }
  }, [selectedTab, allAttachments]);

  const getAttachments = () => {
    return (
      <div className={classes.root}>
        {submissionModifications?.map((submissionModification, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.heading}>
                  {submissionModification.modification.name}{" "}
                  {new Date(
                    submissionModification.createdAt
                  ).toLocaleDateString("en-GB")}
                </Typography>
                <span>{submissionModification.status}</span>
              </div>
            </AccordionSummary>
            {/* <AccordionDetails> */}
            <AccordionDetails style={{ backgroundColor: "#FFF" }}>
              <Grid container>
                <Grid xs={12} item container>
                  <ModificationDetails
                    values={submissionModification.values}
                    modification_id={submissionModification.modification_id}
                    selectedTab={selectedTab}
                    task={task}
                  />
                </Grid>

                <Grid item container xs={12} style={{ marginTop: 20 }}>
                  {attachments
                    ?.filter(
                      (att) =>
                        att.submission_modification_id ==
                        submissionModification.id
                    )
                    .map((attachment, index) => (
                      <React.Fragment key={index}>
                        <Document
                          attachment={attachment}
                          setFile={setFile}
                          setOpen={setOpen}
                          allAttachments={allAttachments}
                          setAllAttachments={setAllAttachments}
                          attachmentsUnderReview={attachmentsUnderReview}
                          attachmentReviewed={attachmentReviewed}
                          setAttachmentReviewed={setAttachmentReviewed}
                          selectedTab={selectedTab}
                          sectionId={documents.sectionId}
                          props={props}
                          initialValues={initialValues}
                          setInitialValues={setInitialValues}
                          schemaInputs={schemaInputs}
                          setSchemaInputs={setSchemaInputs}
                          formik={formik}
                        />
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-start"
                          spacing={2}
                          columns={12}
                          className="mb-2"
                        >
                          {attachment.info &&
                            attachment.info.map((info, index) => {
                              let value = getValue(
                                attachment,
                                info.equivalent_key
                              );
                              return (
                                <Grid xs={6} item key={index}>
                                  <Grid
                                    item
                                    xs={info.style.gridSize}
                                    className="title-label"
                                  >
                                    {info.label}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12 - info.style.gridSize}
                                    className="data"
                                  >
                                    {value}
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </React.Fragment>
                    ))}
                  {/* {submissionModification.values.comment && (
                    <Grid xs={6} item key={index}>
                      <Grid item xs={12} className="title-label">
                        Modification comment:
                      </Grid>
                      <Grid item xs={12} className="data">
                        {submissionModification.values.comment}
                      </Grid>
                    </Grid>
                  )} */}
                  {submissionModification.reason && (
                    <Grid xs={6} item key={index}>
                      <Grid item xs={12} className="data">
                        reason to reject Modification:
                      </Grid>
                      <Grid item xs={12} className="title-label">
                        {submissionModification.reason}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };
  return (
    <>
      <ViewFile file={file} open={open} setOpen={setOpen} />
      {getAttachments()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allAttachments: state.details.allAttachments,
    attachmentReviewed: state.details.attachmentReviewed,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllAttachments: (attachments) =>
      dispatch(setAllAttachments(attachments)),
    setAttachmentReviewed: (attachments) =>
      dispatch(setAttachmentReviewed(attachments)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SimpleAccordion);
