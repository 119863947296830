import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./style.css";
import { renderCell, renderPagination, selectRows } from "./utils";

const SimpleTable = ({
  rows,
  columns,
  rowKey,
  onRowClick,
  selectableRows,
  paginationSettings,
  onRowSelectChange,
  isSubmission,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setSelectedRows([]);
  }, [selectableRows]);

  useEffect(() => {
    if (onRowSelectChange) onRowSelectChange(selectedRows);
  }, [selectedRows]);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  let modifiedColumns = [...columns];
  if (selectableRows) modifiedColumns = [{}, ...columns];
  return (
    <Card>
      <PerfectScrollbar
        options={{
          suppressScrollY: true,
          useBothWheelAxes: true,
        }}
        className="displayScroll"
      >
        <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
          <TableHead className="font-size-sm">
            <TableRow>
              {modifiedColumns.map((column, index) => {
                return (
                  <TableCell
                    className={`text-center bg-color-darkBlue pt-2 ${
                      column.className ? column.className : ""
                    }`}
                    key={index}
                  >
                    {column.label ? column.label : column.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectRows(rows, page, paginationSettings).map((row, rowIndex) => (
              <TableRow
                className={
                  isSubmission && row?.values?.take_decision_class
                    ? row?.values?.take_decision_class
                    : ""
                }
                key={rowKey ? row[rowKey] : rowIndex}
                onClick={() => {
                  if (onRowClick && typeof onRowClick === "function")
                    onRowClick({ row, rowIndex });
                }}
              >
                {modifiedColumns.map((column, cellIndex) =>
                  renderCell({
                    row,
                    column,
                    cellIndex,
                    selectableRows,
                    selectedRows,
                    setSelectedRows,
                    rowIndex,
                  })
                )}
              </TableRow>
            ))}
          </TableBody>
          {renderPagination(page, onPageChange, paginationSettings)}
        </Table>
      </PerfectScrollbar>
    </Card>
  );
};

export default SimpleTable;
