import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import _ from "lodash";
import {
  fetchWorklistLayout,
  fetchFilterData,
  fetchTimeSlotsShedule,
} from "reducers/list";
import { connect } from "react-redux";
import TableWithTabsAndFilters from "components/TableWithTabsAndFilters";
import './style.css'
const getDefaultTab = () => {
  return 0;
};
function WorkList(props) {
  let {
    fetchTimeSlotsShedule,
    schedule,
    scheduleTotalCount,
    getLayout,
    columns,
    getFilterData,
    filterData,
    departments
  } = props;
  const [layoutStructure, setLayoutStructure] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const [menus, setMenus] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [currentTab, setCurrentTab] = useState(getDefaultTab());

  useEffect(() => {
    getLayout({ table: "timeSlots" }).catch((err) => {});
    getFilterData({ table: "timeSlots" }).catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    let isMounted = true;
    const fetchCounts = async () => {
      let newMenus=_.cloneDeep(departments)
      for (const menu of newMenus) {
        if (isMounted) {
          try {
            const res = await client.get("timeSlots/getTimeSlotsShedule", {
              params: {
                page: 1,
                limit: 1,
                currentTab: menu.id,
              },
            });
            menu.badge = res.data.scheduleCount;
          } catch (error) {
            console.log(error);
            menu.badge = 0;
          }
        }
      }
      // if (!_.isEmpty(user)) {
        setMenus(newMenus);
        setCurrentTab(newMenus[0].id);
      // }
    };
    fetchCounts().catch((err) => {});
    return () => {
      isMounted = false;
    };
  }, [departments]);

  useEffect(() => {
    setLayoutStructure(columns);
    setLayoutData(schedule);
    setFilterColumns(filterData);
  }, [schedule, columns, filterData]);

  return (
    <>
    <p className="page-title mb-3">Sending Samples Schedule</p>
    <TableWithTabsAndFilters
      menus={menus}
      totalCount={scheduleTotalCount}
      layoutStructure={layoutStructure}
      setLayoutStructure={setLayoutStructure}
      layoutData={layoutData}
      setLayoutData={setLayoutData}
      filterColumns={filterColumns}
      getTableData={fetchTimeSlotsShedule}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      redirectKey={'submission.id'}
    />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    departments: state.auth.user.departments,
    schedule: state.timeSlotsSchedule.schedule,
    scheduleTotalCount: state.timeSlotsSchedule.scheduleCount,
    columns: state.layout.columns,
    filterData: state.filterData.filterData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchTimeSlotsShedule: (params) => dispatch(fetchTimeSlotsShedule(params)),
    getLayout: (params) => dispatch(fetchWorklistLayout(params)),
    getFilterData: (params) => dispatch(fetchFilterData(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkList);
