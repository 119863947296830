import React, { useEffect, useState } from "react";
import client from "api/apiAuth/guestClient";
import SubmissionLimit from "./SubmissionLimit";
import { Divider, Grid } from "@material-ui/core";

export default function AllSubmissionLimits({ id, title }) {
  const [allLimits, setAllLimits] = useState([]);

  const getSubmissionLimitData = async () => {
    let { data } = await client.get("/SubmissionsLimit/getSubmissionLimit");
    let limits = data.submissionLimits.filter((cur) => cur.workflow_id === id);
    setAllLimits(limits);
  };
  useEffect(() => {
    getSubmissionLimitData();
  }, []);

  return (
    <>
      {allLimits?.length > 0 && (
        <h6
          style={{
            paddingBottom: 20,
            textShadow: "2px 2px 8px rgba(0,0,0,.2)",
            color: "#0066cc",
          }}
        >
          Submission Limit :
        </h6>
      )}
      <Grid container style={{ padding: 20 }} spacing={4}>
        {allLimits?.map((limit, index) => {
          return (
            <React.Fragment key={index}>
              <SubmissionLimit targetData={limit} order={index + 1} />
              {index !== allLimits.length - 1 ? <Divider /> : null}
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}
