import {
  GET_SUBMISSION_LIMIT_PER_COMPANY,
  GET_COMPANY_ALLOWED_SUBMISSION_COUNT,
  SET_AVAILABLE_SUBMISSIONS_COUNT,
} from "./settingsActionTypes";
import client from "api/apiAuth/guestClient";

export const getLimitPerCompany = function (params) {
  return {
    type: GET_SUBMISSION_LIMIT_PER_COMPANY,
    payload: client.get(`/settings/getLimitPerCompany`),
  };
};
export const getAllowedSubmissionCount = function (params) {
  return {
    type: GET_COMPANY_ALLOWED_SUBMISSION_COUNT,

    payload: client.get("/settings/getCompanyAllowedSubmissionCount", {
      params,
    }),
  };
};

export const setAvailableSubmissionsCount = function (params) {
  return {
    type: SET_AVAILABLE_SUBMISSIONS_COUNT,

    payload: params,
  };
};

