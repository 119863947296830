import React, { useEffect, useState } from "react";
import imag from "../../assets/images/evers.png";
import config from "../index.js";
// eslint-disable-next-line import/no-anonymous-default-export

function getPackFromSubmission(submission) {
  for (const attachment of submission.attachments)
    if (
      attachment.document.key === "product_data_type" &&
      "Pack(volume)" in attachment.attachment_values.values
    )
      return attachment.attachment_values.values["Pack(volume)"];
  return null;
}

export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  groupValues,
}) => {
  let submissions = letter;
  useEffect(() => {
    setValues(config(letter, formValues, "minutesOfMeeting"));
  }, []);
  // useEffect(() => {
  //   console.log("VVV: ", values);
  //   setValues({
  //     ...config(letter, values, "minutesOfMeeting"),
  //   });
  // }, [values]);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  toDataURL(imag);
  useEffect(() => {
    const minutesOfMeeting = document.getElementById("minutesOfMeeting");
    setDivRef({ ...divRef, minutesOfMeeting, documentValues: values });
  }, [values]);
  return (
    <div
      style={{ padding: "20px", zoom: 0.7, color: "black" }}
      id="minutesOfMeeting"
    >
      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "16pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            General Directorate
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            Of Veterinary Products
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              textAlign: "center",
            }}
          >
            الإدارة العامة للمستحضرات البيطرية
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        className="row"
        style={{
          // backgroundColor: "lightgrey",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <h4 style={{ textDecoration: "underline" }}>
          <input type="text" defaultValue="Code: 0000 / 2021" />
        </h4>
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          محضر اجتماع
        </span>
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          اللجنة العلمية المتخصصة للأدوية البيطرية
        </span>
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          وإضافات الأعلاف
        </span>
        <h6 style={{ textDecoration: "underline" }}>
          <input
            type="text"
            style={{ border: "none", direction: "rtl" }}
            name="date"
            defaultValue={
              groupValues?.start_date &&
              new Date(groupValues.start_date).toLocaleDateString("ar-EG", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }
            onChange={handleChange}
          ></input>
          يوم
          <input
            type="text"
            style={{ border: "none", direction: "rtl", width: "20%" }}
            name="dateName"
            placeholder=".........."
            value={values.dateName}
            onChange={handleChange}
          ></input>{" "}
          الموافق
        </h6>
      </div>

      <p style={{ marginTop: "30px" }}>
        <textarea
          style={{
            width: "100%",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "500",
            fontSize: "18.5pt",
            direction: "rtl",
            border: "none",
          }}
          rows={3}
          value={values?.firstText}
          name="firstText"
          autoCorrect="off"
          onChange={handleChange}
        />
      </p>

      <div style={{ display: "flex", direction: "rtl" }}>
        <ul
          style={{
            direction: "rtl",
            display: "flex",
            listStyleType: "disc",
            flexDirection: "column",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {values &&
            values.firstList?.map((name, index) => (
              <li
                style={{
                  direction: "rtl",
                }}
              >
                <input
                  type="text"
                  style={{
                    border: "none",
                    direction: "rtl",
                    fontSize: "16pt",
                    fontFamily: ["Arial", "sans-serif"],
                    fontWeight: "500",
                  }}
                  name={name}
                  placeholder=".........."
                  value={name}
                  onChange={handleChange}
                ></input>
              </li>
            ))}
        </ul>
        <ul
          style={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {values &&
            values.secondList?.map((name, index) => (
              <li
                style={{
                  direction: "rtl",
                }}
              >
                <input
                  type="text"
                  style={{
                    border: "none",
                    direction: "rtl",
                    fontSize: "16pt",
                    fontFamily: ["Arial", "sans-serif"],
                    fontWeight: "500",
                  }}
                  name={name}
                  placeholder=".........."
                  value={name}
                  onChange={handleChange}
                ></input>
              </li>
            ))}
        </ul>
      </div>

      <p style={{ marginTop: "30px" }}>
        <textarea
          style={{
            width: "100%",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "500",
            fontSize: "16pt",
            direction: "rtl",
            border: "none",
          }}
          rows={1}
          value={values?.secText}
          name="secText"
          autoCorrect="off"
          onChange={handleChange}
        />
      </p>

      <ul
        style={{
          direction: "rtl",
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <li
          style={{
            direction: "rtl",
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "500",
            width: "100%",
          }}
        >
          <input
            type="text"
            style={{
              border: "none",
              direction: "rtl",
              fontSize: "16pt",
              fontFamily: ["Arial", "sans-serif"],
              fontWeight: "500",
              display: "list-item",
              width: "100%",
            }}
            name="secListItem"
            placeholder=".........."
            value={values.secListItem}
            onChange={handleChange}
          ></input>
        </li>
        {values &&
          values.secList?.map((name, index) => (
            <li
              style={{
                direction: "rtl",
                fontSize: "16pt",
                fontFamily: ["Arial", "sans-serif"],
                fontWeight: "500",
              }}
            >
              {name}
            </li>
          ))}
      </ul>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "50%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        style={{
          display: "flex",
          direction: "rtl",
          flexDirection: "column",
          alignContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "5%",
        }}
      >
        <h4
          style={{
            textDecoration: "underline",
            direction: "rtl",
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            alignItems: "flex-start",
            fontSize: "16pt",
          }}
        >
          ثانيا: عرض المستحضرات المحلية والمستوردة الجديدة الآتية :
        </h4>
        <br />
        {submissions &&
          submissions.map((submission, index) => {
            return (
              <div
                className="row"
                style={{
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <h5
                  style={{
                    direction: "rtl",
                    display: "flex",
                    marginLeft: "8%",
                    marginTop: "20px",
                    fontWeight: "600",
                    fontSize: "14pt",
                  }}
                >
                  {index + 1}. طلب شركة {submission.org.ArabicName} تسجيل
                  المستحضر الاتي:
                </h5>
                <div className="col-xs-12">
                  <div align="center">
                    <table
                      style={{
                        textAlign: "left",
                        marginTop: "15px",
                        borderCollapse: "collapse",
                        direction: "ltr",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontWeight: "600",
                              marginLeft: "20px",
                              padding: "10px",
                              textAlign: "left",
                              width: "70%",
                            }}
                          >
                            {submission.values.trade_name}
                          </td>
                          <td
                            style={{
                              fontWeight: "600",
                              marginLeft: "20px",
                              padding: "10px",
                              textAlign: "left",
                              width: "70%",
                            }}
                          >
                            {submission.drugs
                              .map((drug) => drug.dosage_form)
                              .join(",")}{" "}
                            {getPackFromSubmission(submission)
                              ? `(${getPackFromSubmission(submission)})`
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "600",
                              marginLeft: "20px",
                              padding: "10px",
                              textAlign: "left",
                              width: "70%",
                            }}
                          >
                            {submission.drugs
                              .map((drug) => {
                                return drug.ingredients.map(
                                  (ingredient) =>
                                    `${ingredient.active_ingredient} ${
                                      ingredient.full_strength
                                        ? ingredient.full_strength
                                        : ""
                                    } ${
                                      ingredient.salts &&
                                      ingredient.salts.length
                                        ? `(${ingredient.salts
                                            .map(
                                              (salt) =>
                                                `${salt.name} ${
                                                  salt.unit && salt.unit
                                                } ${
                                                  salt.strength && salt.strength
                                                }`
                                            )
                                            .join(", ")
                                            .trim()})`
                                        : ""
                                    }`
                                );
                              })
                              .join(",")}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <h6
                                style={{
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                }}
                              >
                                قرار اللجنة
                              </h6>
                              <p>{submission.values.take_decision}</p>
                              <p>
                                <textarea
                                  type="text"
                                  rows={2}
                                  defaultValue={
                                    submission?.values?.temp_comment &&
                                    submission?.values?.temp_comment
                                  }
                                />
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <hr
          style={{
            border: 0,
            clear: "both",
            display: "block",
            width: "70%",
            backgroundColor: "#000000",
            height: "1px",
          }}
        />
        <span
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "500",
          }}
        >
          <input
            type="text"
            style={{ border: "none", direction: "rtl" }}
            name="time"
            placeholder=".........."
            value={values.time}
            onChange={handleChange}
          ></input>
          و إنتهى اجتماع اللجنة بجلسة
          <input
            type="text"
            style={{ border: "none", direction: "rtl", width: "20%" }}
            name="dateEnd"
            placeholder="../../...."
            defaultValue={new Date().toLocaleDateString("ar-EG", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
            onChange={handleChange}
          ></input>{" "}
          في تمام الساعة
        </span>
        <hr
          style={{
            border: 0,
            clear: "both",
            display: "block",
            width: "70%",
            backgroundColor: "#000000",
            height: "1px",
          }}
        />
      </div>
      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
};
