export const SEND = "SEND";
export const SEND_SUCCESS = "SEND_SUCCESS";
export const SEND_FAIL = "SEND_FAIL";

export const CONNECT = "CONNECT";
export const CONNECT_SUCCESS = "CONNECT_SUCCESS";
export const CONNECT_FAIL = "CONNECT_FAIL";

export const RECEIVE = "RECEIVE";
export const RECEIVE_SUCCESS = "RECEIVE_SUCCESS";
export const RECEIVE_FAIL = "RECEIVE_FAIL";
export const EDIT = "EDIT";
export const EDIT_SUCCESS = "EDIT_SUCCESS";
export const EDIT_FAIL = "EDIT_FAIL";

export const NOTIFICATION_MASSEGE = "NOTIFICATION_MASSEGE";
export const IS_EDIT = "IS_EDIT";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_PENDING = "GET_NOTIFICATIONS_PENDING";
export const GET_NOTIFICATIONS_FULFILLED = "GET_NOTIFICATIONS_FULFILLED";
export const GET_NOTIFICATIONS_REJECTED = "GET_NOTIFICATIONS_REJECTED";
