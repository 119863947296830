import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import _ from "lodash";

const EditGroupModal = ({ group, attr, open, setOpen, setGroup }) => {
  const onSubmit = () => {
    client
      .post("submissionGroup/updateGroupDate", {
        id:group.groupId,
        values: { ...group.values, start_date: tempValue.toLocaleString() },
      })
      .then((res) => {
        setOpen(false);
        setGroup({
          ...group,
          values: { ...group.values, start_date: tempValue.toLocaleString() },
        });
      });
  };
  const [tempValue, setTempValue] = useState(null);
  const [validError, setValidError] = useState("Field is required");
  const [renderComponent, setRenderComponent] = useState(null);
  useEffect(() => {
    if (tempValue) setValidError(null);
  }, [tempValue]);
  useEffect(() => {
    if (group && attr) {
      if (attr.type == "date") {
        setRenderComponent(
          <TextField
            className="mb-2"
            id="datetime-local"
            type="datetime-local"
            defaultValue={new Date(tempValue)}
            onChange={(e) => setTempValue(new Date(e.target.value))}
          />
        );
      }
    }
  }, [open]);
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className="titleModal">Edit Group</span>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column align-items-center">
          {attr.label}
          {renderComponent}
          <Button
            variant="contained"
            color="primary"
            style={{ width: "200px", marginBottom: "60px" }}
            disabled={validError}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditGroupModal;
