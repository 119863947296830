import _ from "lodash";

export default (obj) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      if (value instanceof File) {
        value = {
          lastModified: value.lastModified,
          lastModifiedDate: value.lastModifiedDate,
          name: value.name,
          size: value.size,
          type: value.type,
        };
      }
      return value === "" ||
        typeof value === "undefined" ||
        (typeof value == "object" && _.isEmpty(value))
        ? undefined
        : value;
    })
  );
};
