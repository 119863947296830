import React, { useEffect } from "react";
import { Grid, Button, InputLabel, Chip } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import "../../style.scss";
import { getConditionalClasses, handleClick } from "pages/EdaDetails/utils";
import InputWidget from "components/FormSteps/InputWidget";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import { useState } from "react";
import FileHistory from "../FileHistory";
import EditDocument from "../editDocument";
import client from "api/apiAuth/guestClient";
import { getTaskDetails } from "reducers/details/detailsAction";
import { apiConfig } from "api/utilities";
import Swal from "sweetalert2";
const {
  documentConfig,
} = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/document`);
const documentsConfig = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/documents`);
function Document(props) {
  const {
    attachment,
    setFile,
    setOpen,
    allAttachments,
    setAllAttachments,
    attachmentsUnderReview,
    setAttachmentReviewed,
    selectedTab,
    currentUser,
    task,
    sectionId,
    formik,
    getTaskDetails,
  } = props;

  const [actions, setActions] = useState([]);
  const [openFileHistory, setOpenFileHistory] = useState(false);
  const [fileHistory, setFileHistory] = useState({});
  const [editFile, setEditFile] = useState({});
  const [openEditFile, setOpenEditFile] = useState(false);
  useEffect(() => {
    const mapper = {
      attachment: attachment,
      currentUser,
      taskUsers: task.users,
      task,
    };
    const tab = documentConfig
      .config(mapper)
      .find(
        (tab) => tab.tabId === selectedTab.id && tab.sectionId == sectionId
      );
    setActions(
      tab?.actions.map((action) => {
        if (action.constraints) {
          return {
            ...action,
            constraints: applyDetailsConstraints(action.constraints),
          };
        } else {
          return { ...action, constraints: { display: true, disable: false } };
        }
      })
    );
  }, [attachment]);
  const actionProperties = {
    ...props,
    viewFile: ({ file }) => {
      setOpen(true);
      setFile(file);
    },
    downloadFile: ({ file }) => {},
    viewHistory: ({ file }) => {
      setOpenFileHistory(true);
      setFileHistory(file);
    },
    deleteFile: async ({ file }) => {
      let avatarFd = file.avatarFd;
      if (attachment) {
        //remove attachment file from backend
        await client
          .post("attachment/deleteUnlinkedAttachment", { avatarFd })
          .then((res) => {
            if (res.status === 201) getTaskDetails(task.submission.id);
          });
      }
    },
    editDocument: ({ file }) => {
      setOpenEditFile(true);
      setEditFile(file);
    },
    handleReviewAttachments: ({ file, approve }) => {
      let submissionAttachments = _.cloneDeep(allAttachments) || [];
      let newReviewedAttachments = _.cloneDeep(attachmentsUnderReview) || [];
      submissionAttachments.forEach((attachment) => {
        if (file.id === attachment.id) {
          attachment.is_reviewed = approve;
          let attachmentIndex = attachmentsUnderReview.findIndex(
            (reviewedAttachment) => reviewedAttachment.id === file.id
          );
          setAllAttachments(
            submissionAttachments.map((uploadedFile) => {
              if (uploadedFile.id === file.id) {
                uploadedFile.is_reviewed = approve;
                uploadedFile.is_dirty = true;
                let newDecision = {
                  user_id: currentUser.id,
                  username: currentUser.username,
                  is_reviewed: approve,
                  roles: currentUser.roles,
                };
                const values = _.unionBy(
                  [newDecision],
                  attachment.attachment_values?.decisions || [],
                  "user_id"
                );
                uploadedFile.attachment_values = {
                  decisions: values,
                };
                if (
                  "attachment_values" in file &&
                  file.attachment_values !== null &&
                  "attachment_values" in file &&
                  "values" in file.attachment_values
                ) {
                  uploadedFile.attachment_values = {
                    values: file.attachment_values.values,
                    templateName: file.attachment_values.templateName,
                  };
                }
              }
              return uploadedFile;
            })
          );
          if (attachmentIndex > -1) {
            newReviewedAttachments[attachmentIndex] = {
              ...attachment,
              is_dirty: true,
            };
            setAttachmentReviewed([...newReviewedAttachments]);
          } else {
            setAttachmentReviewed([
              ...attachmentsUnderReview,
              { ...attachment, is_dirty: true },
            ]);
          }
          return;
        }
      });
    },
  };
  const renderActions = (action, ButtonIcon) => {
    let type = action.type;
    switch (type) {
      case "download":
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={async () => {
              const file = await client.get("attachment/preview", {
                params: {
                  filePath: attachment.avatarFd || attachment.logs[0].avatarFd,
                },
                responseType: "blob",
              });
              let type = "application/pdf";
              if (
                attachment.extension === "jpg" ||
                attachment.extension === "jpeg"
              )
                type = "image/jpeg";
              else if (attachment.extension === "png") type = "image/png";
              const blob = new Blob([file.data], { type });

              const fileURL = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = fileURL;
              link.setAttribute(
                "download",
                attachment.extension === "pdf"
                  ? attachment.original_file_name
                    ? attachment.original_file_name
                    : `${attachment.document.name_en}.pdf`
                  : attachment.extension === "jpg" ||
                    attachment.extension === "jpeg"
                  ? attachment.original_file_name
                    ? attachment.original_file_name
                    : `${attachment.document.name_en}.jpeg`
                  : attachment.original_file_name
                  ? attachment.original_file_name
                  : `${attachment.document.name_en}.png`
              ); //or any other extension
              document.body.appendChild(link);
              link.click();
              // window.open(fileURL);
            }}
          >
            <Button
              className={`${action.classes} ${getConditionalClasses(
                attachment,
                action.conditionalClasses
              )}`}
              disableFocusRipple
              disableRipple
              disabled={action.constraints.disable}
              onClick={(e) =>
                handleClick(e, action, actionProperties, {
                  file: attachment,
                })
              }
              style={{ height: "34px" }}
            >
              {action.icon && <ButtonIcon />}
              {action.label}
            </Button>
            {/* <Button
              className={`${action.classes} ${getConditionalClasses(
                attachment,
                action.conditionalClasses
              )}`}
              disableFocusRipple
              disableRipple
            >
              View file
            </Button> */}
            {/* <a
            href={attachment.avatarFd}
            download="Rishabh's Resume"
            target="_blank"
          >
            <button type="button" class="btn btn-success btn-lg btn-block">
              Download Resume
            </button>
          </a> */}
          </a>
        );
      case "form":
        return (
          <InputWidget
            formik={formik}
            input={{
              ...attachment.document,
              key: `${attachment.document.equivalent_key}${
                attachment.document.manyIndex !== undefined
                  ? ".[" + attachment.document.manyIndex + "]"
                  : ""
              }`,
              input_layout: {
                ...attachment.document.input_layout,
                type: "document",
              },
            }}
            onValueChange={() => {
              // setSavedLastUploadedAttachments(false);
            }}
            submissionValue={task.submission}
          />
        );
      case "regenerate_file":
        return (
          <Button
            className={`${action.classes} ${getConditionalClasses(
              attachment,
              action.conditionalClasses
            )}`}
            disableFocusRipple
            disableRipple
            disabled={action.constraints.disable}
            onClick={async () => {
              Swal.fire({
                title: "Please Wait",
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
              await client.post("attachment/regenerateFile", {
                letter: action.letter,
                id: attachment.id,
              });
              getTaskDetails(task.submission.id);
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Your file has been updated",
                showConfirmButton: false,
                timer: 2000,
              });
            }}
          >
            {action.icon && <ButtonIcon />}
            {action.label}
          </Button>
        );
      case undefined:
        return (
          <Button
            className={`${action.classes} ${getConditionalClasses(
              attachment,
              action.conditionalClasses
            )}`}
            disableFocusRipple
            disableRipple
            disabled={action.constraints.disable}
            onClick={
              (e) =>
                window.open(
                  `${apiConfig.baseUrl}${
                    attachment?.avatarFd?.split("assets/")?.[1] ||
                    attachment?.logs?.[0]?.avatarFd
                      .replace(/\\/g, "/")
                      .split("assets/")[1]
                  }`,
                  "_blank"
                )
              // handleClick(e, action, actionProperties, {
              //   file: attachment,
              // })
              //FIXME: open in windows
            }
          >
            {action.icon && <ButtonIcon />}
            {action.label}
          </Button>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <Grid container spacing={0} className="mb-4 ">
        {(attachment?.document?.name_en ||
          attachment?.original_file_name ||
          attachment?.logs?.[0]?.attachment?.original_file_name) && (
          <Grid item md={12} sx={6} className="documents">
            <div className={"doc-header"}>
              {attachment?.document?.name_en ||
                attachment?.logs?.[0]?.attachment?.original_file_name ||
                `Extra Attachment(${attachment?.original_file_name})`}
            </div>
          </Grid>
        )}
        <Grid item md={12} className="documents mt-2">
          {actions?.map((action, index) => {
            let ButtonIcon = action.icon;
            return (
              <React.Fragment key={index}>
                {action.constraints?.display &&
                  renderActions(action, ButtonIcon)}

                {/* {action.constraints?.display &&
                  (action.type !== "form" ? (
                    action.type !== "label" && (
                      <Button
                        className={`${action.classes} ${getConditionalClasses(
                          console.log("🚀 ~ file: index.jsx:316 ~ renderActions ~ `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  }:", `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  })
                          console.log("🚀 ~ file: index.jsx:316 ~ renderActions ~ `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  }:", `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  })
                          console.log("🚀 ~ file: index.jsx:316 ~ renderActions ~ `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  }:", `${apiConfig.baseUrl}${
                    attachment.avatarFd?.split("assets/")[1]
                  })
                          attachment,
                          action.conditionalClasses
                        )}`}
                        disableFocusRipple
                        disableRipple
                        disabled={action.constraints.disable}
                        onClick={(e) =>
                          handleClick(e, action, actionProperties, {
                            file: attachment,
                          })
                        }
                      >
                        {action.icon && <ButtonIcon />}
                        {action.label}
                      </Button>
                    )
                  ) : (
                    <InputWidget
                      formik={formik}
                      input={{
                        ...attachment.document,
                        key: attachment.document.equivalent_key,
                      }}
                      submissionValue={task.submission}
                    />
                  ))} */}
              </React.Fragment>
            );
          })}

          {openFileHistory && (
            <FileHistory
              openFileHistory={openFileHistory}
              setOpenFileHistory={setOpenFileHistory}
              fileHistory={fileHistory}
            />
          )}
          {openEditFile && (
            <EditDocument
              openEditFile={openEditFile}
              setOpenEditFile={setOpenEditFile}
              editFile={editFile}
              task={task}
            />
          )}
        </Grid>
        {actions.map(
          (action, index) =>
            action.constraints?.display &&
            action.constraints.disable &&
            action.type !== "form" &&
            action.type === "label" && (
              <Grid
                item
                sx={3}
                className="documents"
                style={{ display: "inline", marginRight: "10px" }}
                key={index}
              >
                <Chip
                  md={12}
                  className={`${action.classes} ${getConditionalClasses(
                    attachment,
                    action.conditionalClasses
                  )}`}
                  label={action.label}
                />
              </Grid>
            )
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    task: state.details.task,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTaskDetails: (submission_id) =>
      dispatch(getTaskDetails({ submission_id, task_status: "Pending" })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Document);
