import { Container, Button } from "@material-ui/core";
import SpinnerLoader from "../../components/SpinnerLoader";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Paper from "@material-ui/core/Paper";
import client from "api/apiAuth/guestClient";
import "./style.css";
import ModificationDetailsPop from "pages/EdaDetails/components/Modifications/ModificationDetails/ModificationDetailsPop";
const modificationDetailsConfig = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/ModificationDetailsConfig`);
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ModificationRequests() {
  const classes = useStyles();

  const [allData, setAllData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedModification, setSelectedModification] = useState();
  const getSubmissionModifications = async () => {
    setIsloading(true);

    const res = await client.get("/modification/findWaitingModifications");
    // const res = await client.get("/generic/find", {
    //   params: {
    //     model: "Submission",
    //     include: [
    //       {
    //         sameRole: "responsableRole",
    //         association: "modifications",
    //         through: {
    //           where: {
    //             status: "Waiting",
    //           },
    //         },
    //         required: true,
    //       },
    //     ],
    //     raw: true,
    //   },
    // });
    setIsloading(false);

    setAllData(res.data.result);
  };
  useEffect(() => {
    getSubmissionModifications();
  }, []);

  const startModification = async (id, submission_id, modification_id) => {
    try {
      const acceptedSubmissionMod = allData.find((subMod) => subMod.id == id);
      let message;

      if (acceptedSubmissionMod.workingUser) {
        message = `${acceptedSubmissionMod.workingUser.username} is working now on task ${acceptedSubmissionMod.pendingTask.id} in step ${acceptedSubmissionMod.pendingTask.step.name}`;
      } else {
        message = `Do You Want to Accept: ${acceptedSubmissionMod.submission.file_code}`;
      }

      Swal.fire({
        title: "You want to accept this request?",
        text: message,
        showConfirmButton: true,
        titleClass: "fontSize",
        showDenyButton: true,
        confirmButtonText: `submit`,
        denyButtonText: `cancel`,
        confirmButtonColor: "#0066cc",
        denyButtonColor: "#25305338",
      }).then(function (res) {
        if (res.isConfirmed) {
          client
            .post("/workflow/submitModificationDecision", {
              id,
              submission_id,
              modification_id,
              workingUser: acceptedSubmissionMod.workingUser,
            })
            .then(() => {
              setAllData(allData.filter((data) => data.id != id));
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const ViewModification = (id) => {
    setSelectedModification(allData.find((subMod) => subMod.id === id));
    setOpenModal(true);
  };

  const cancelModification = async (id) => {
    try {
      const rejectedMods = allData.find((subMod) => subMod.id == id);

      Swal.fire({
        title: `Do you want to reject  modification for event: ${rejectedMods.submission.file_code}?`,
        input: "textarea",
        inputPlaceholder: "Reason for reject ",
        showConfirmButton: true,
        titleClass: "fontSize",
        showDenyButton: true,
        confirmButtonText: `submit`,
        denyButtonText: `cancel`,
        confirmButtonColor: "#0066cc",
        denyButtonColor: "#25305338",
        preConfirm: (value) => {
          if (!value) {
            Swal.showValidationMessage("Please enter reason for rejected");
          } else {
            return { reason: value };
          }
        },
      }).then(function (res) {
        if (res.isConfirmed) {
          client
            .patch(`generic/update?model=SubmissionModification&id=${id}`, {
              attributes: { status: "Rejected", reason: res.value.reason },
              where: { id: id },
            })
            .then(() => {
              setAllData(allData.filter((data) => data.id != id));
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getWorkingUser = (subMod) => {
    if (subMod?.workingUser?.username) {
      return subMod.workingUser.username;
    }

    return "another modification is running";
  };

  const getPendingStep = (subMod) => {
    if (subMod?.pendingTask?.step?.name) {
      return subMod.pendingTask.step.name;
    }

    return "another modification is running";
  };

  return (
    <Container className="py-3 py-xl-5" style={{ maxWidth: "100%" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Submission</TableCell>
              <TableCell align="center">Request</TableCell>
              <TableCell align="center">Creator</TableCell>

              {/* <TableCell align="center">working user</TableCell>
              <TableCell align="center">Current Step</TableCell> */}

              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              <>
                {!isloading ? (
                  allData.length ? (
                    allData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" component="th" scope="row">
                          {row.submission.file_code}
                        </TableCell>
                        <TableCell align="center">
                          {row.modification.name}
                        </TableCell>

                        <TableCell align="center">
                          {row.values.createdBy.username}
                        </TableCell>

                        {/* <TableCell align="center">
                          {getWorkingUser(row)}
                        </TableCell> */}

                        {/* <TableCell align="center">
                          {getPendingStep(row)}
                        </TableCell> */}

                        <TableCell align="center">
                          <Button
                            onClick={() =>
                              startModification(
                                row.id,
                                row.submission.id,
                                row.modification.id
                              )
                            }
                            accept-btn
                            className="m-2 btn-transparent accept-btn btn-outlined"
                          >
                            Start Request
                          </Button>
                          <Button
                            onClick={() => cancelModification(row.id)}
                            className="m-2 btn-transparent cancel-btn btn-outlined"
                          >
                            Cancel Request
                          </Button>
                          <Button
                            className="m-2 btn-transparent search-btn btn-outlined"
                            onClick={() => ViewModification(row.id)}
                          >
                            <VisibilityIcon />
                          </Button>
                          {openModal && (
                            <ModificationDetailsPop
                              open={openModal}
                              setOpen={setOpenModal}
                              values={selectedModification.values}
                              modification_id={
                                selectedModification.modification.id
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: "center" }}>
                        There Is No Modifications
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <SpinnerLoader />
                    </TableCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          }
        </Table>
      </TableContainer>
    </Container>
  );
}
