// material-ui
import { FormHelperText, Grid } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
// import { useSelector } from "store";
// import { useNavigate } from "react-router-dom";
// import { login } from 'store/slices/user-data/actions';
import jwtDecode from "jwt-decode";
import { connect } from "react-redux";
import { connectSocket } from "reducers/socket";
import { googleLogin, setIsLoggedIn } from "reducers/auth";
import { setErrorMessage } from "reducers/error";
import Swal from "sweetalert2";
import SocketClient from "socket/socketClient";

// ==============================|| AUTH0 LOGIN ||============================== //

const Auth0Login = ({
  googleLogin,
  auth,
  history,
  connectSocket,
  ...others
}) => {
  const loginHandler = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log(
      "🚀 ~ file: AuthLogin.jsx ~ line 18 ~ loginHandler ~ decoded",
      decoded
    );

    if (decoded) {
      // allowed organization check
      // if (decoded.hd !== "everestminds.com") {
      //   Swal.fire({
      //     text: "You are not authorized to access this website",
      //     icon: "error",
      //     confirmButtonColor: "#d36467",
      //     // showCancelButton: false,
      //     // showConfirmButton: false,
      //     dangerMode: true,
      //   });
      //   return;
      // }

      googleLogin({
        name: decoded.name,
        token: decoded.jti,
        email: decoded.email,
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        hd: decoded.hd,
      })
        .then((response) => {
          const socketClient = new SocketClient(response.value.data.user);
          connectSocket(response.value.data.user);
          // socketClient.connect(
          //   process.env.REACT_APP_SERVER_URL
          //     ? process.env.REACT_APP_SERVER_URL
          //     : `${window.location.protocol}//${window.location.host}/api/`
          // );
          localStorage.setItem(
            "user",
            JSON.stringify(response.value.data.user)
          );

          history.push("/");
        })
        .catch((error) => {
          // if (error.response?.status === 401) {
          //   Swal.fire({
          //     text: error.response.data.message,
          //     icon: "warning",
          //     confirmButtonColor: "#d79862",
          //     dangerMode: true,
          //   });
          // }
          setErrorMessage(error.response && error.response.data.message);
        });
    }
  };

  return (
    <div {...others}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <GoogleLogin
          width="250px"
          onSuccess={loginHandler}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    googleLogin: (data) => dispatch(googleLogin(data)),
    connectSocket: (user) => dispatch(connectSocket(user)),
    setIsLoggedIn: (data) => dispatch(setIsLoggedIn(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Auth0Login);
