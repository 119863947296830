import { createReducer } from "@reduxjs/toolkit";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_PAGE,
  SET_ERROR_MODAL_CLICKED,
} from "./errorActionTypes";

const initialState = {
  message: "",
  page: "",
  random: Math.random(),
  modalButtonClicked: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(SET_ERROR_MESSAGE, (state, action) => {
    state.message = action.payload.errorMessage;
    state.page = "";
    state.random = Math.random();
  });
  builder.addCase(SET_ERROR_PAGE, (state, action) => {
    state.page = action.payload.errorPage;
    state.message = "";
    state.random = Math.random();
  });
  builder.addCase(SET_ERROR_MODAL_CLICKED, (state, action) => {
    state.modalButtonClicked = action.payload.flag;
  });
});
export default reducer;
