import React, { useEffect, useState } from "react";
import lodash from "lodash";
import imag from "../../assets/images/evers.png";
import footerImage from "../../assets/images/evers-letter-footer.png";
import NoSignatureImage from "../../assets/images/nosignature.jpg";
import config from "../index.js";
import client from "api/apiAuth/guestClient";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  drug,
  letter,
  divRef,
  setDivRef,
  values,
  setValues,
  formValues,
  editValues,
}) => {
  const [groupData, setGroupData] = useState(null);
  const [generalManagerSignature, setGeneralManagerSignature] = useState(null);
  const [
    scientificCommitteeManagerSignature,
    setScientificCommitteeManagerSignature,
  ] = useState(null);
  const [
    technicalAffairsManagerSignature,
    setTechnicalAffairsManagerSignature,
  ] = useState(null);
  const [pack, setPack] = useState(null);
  useEffect(async () => {
    const urlCreator = window.URL || window.webkitURL;
    setValues(
      config(
        letter,
        formValues,
        "scientificCommitteeApprovalLetter",
        editValues
      )
    );
    const response = await client.post("generic/find", {
      model: "SubmissionGroup",
      include: {
        association: "submissions",
        where: { id: letter.id },
      },
      limit: 1,
      order: [["createdAt", "DESC"]],
    });
    setGroupData(response.data.rows[0]);
    const users = await client.post("generic/find", {
      model: "User",
      include: {
        association: "roles",
        where: {
          name: [
            "Scientific Evaluation Unit Manager",
            "General Manager",
            "Technical Affairs Soft Files",
          ],
        },
      },
    });

    const generalManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "General Manager" })
    );
    if (generalManager && generalManager.signatureEnglish) {
      const generalManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: generalManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([generalManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setGeneralManagerSignature);
    } else toDataURLWithCallback(NoSignatureImage, setGeneralManagerSignature);

    const scientificCommitteeManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Scientific Evaluation Unit Manager" })
    );
    if (
      scientificCommitteeManager &&
      scientificCommitteeManager.signatureEnglish
    ) {
      const scientificCommitteeManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: scientificCommitteeManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([scientificCommitteeManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setScientificCommitteeManagerSignature);
    } else
      toDataURLWithCallback(
        NoSignatureImage,
        setScientificCommitteeManagerSignature
      );

    const technicalAffairsManager = users.data.rows.find((user) =>
      lodash.find(user.roles, { name: "Technical Affairs Soft Files" })
    );
    if (technicalAffairsManager && technicalAffairsManager.signatureEnglish) {
      const technicalAffairsManagerSignatureImageAsBlob = await client.get(
        "attachment/preview",
        {
          params: { filePath: technicalAffairsManager.signatureEnglish },
          responseType: "blob",
        }
      );
      const imageURL = urlCreator.createObjectURL(
        new Blob([technicalAffairsManagerSignatureImageAsBlob.data])
      );
      toDataURLWithCallback(imageURL, setTechnicalAffairsManagerSignature);
    } else
      toDataURLWithCallback(
        NoSignatureImage,
        setTechnicalAffairsManagerSignature
      );
    for (const attachment of letter.attachments)
      if (
        attachment.document.key === "product_data_type" &&
        "Pack(volume)" in attachment.attachment_values.values
      ) {
        setPack(attachment.attachment_values.values["Pack(volume)"]);
        break;
      }
  }, []);
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };
  const [imageSrc, setImageSrc] = useState("");
  function toDataURL(src) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      setImageSrc(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  function toDataURLWithCallback(src, callback) {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }

  toDataURL(imag);
  useEffect(() => {
    const scientificCommitteeApprovalLetter = document.getElementById(
      "scientificCommitteeApprovalLetter"
    );
    setDivRef({
      ...divRef,
      scientificCommitteeApprovalLetter,
      documentValues: values,
    });
  }, [values]);

  const manufacturersText = [];

  if (letter?.values.manufacturer_name)
    manufacturersText.push(letter.values.manufacturer_name);
  if (letter?.values.country_of_origin_manufacturer_name)
    manufacturersText.push(letter.values.country_of_origin_manufacturer_name);
  if (letter?.values.license_holder)
    manufacturersText.push(letter.values.license_holder);
  if (letter?.values.country_of_origin_license_holder)
    manufacturersText.push(letter?.values.country_of_origin_license_holder);

  return (
    <div
      style={{ padding: "20px", zoom: 0.7, color: "black" }}
      id="scientificCommitteeApprovalLetter"
    >
      <div
        id="header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 900,
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Arab Republic of Egypt
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Egyptian Drug Authority
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Central Administration for
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Pharmaceutical Products
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            General Administration of
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            Veterinary Pharmaceuticals
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <img src={imageSrc} style={{ width: "150px" }} alt="bio-inn" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "900",
          }}
        >
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            جمهورية مصر العربية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            هيئة الدواء المصرية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة المركزية للمستحضرات الصيدلية
          </span>
          <span
            lang="AR-SA"
            style={{ fontSize: "11pt", fontFamily: ["Arial", "sans-serif"] }}
          >
            الإدارة العامة للمستحضرات البيطرية
          </span>
        </div>
      </div>
      <hr
        style={{
          border: 0,
          clear: "both",
          display: "block",
          width: "100%",
          backgroundColor: "#000000",
          height: "1px",
        }}
      />
      <div
        className="row d-flex justify-content-between"
        style={{
          width: "100%",
        }}
      >
        <div style={{ display: "flex" }}>
          <h4 style={{ textDecoration: "underline", fontSize: "20px" }}>
            Code: {letter.values.reg_number}
          </h4>
          <h4 style={{ textDecoration: "underline", fontSize: "20px" }}>
            /{new Date().getFullYear()}
          </h4>
        </div>
        <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>(موافقة)</h4>
      </div>

      <div
        className="row"
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          السيد الدكتور/ رئيـس مجلس إدارة شركة
        </span>
        <input
          type="text"
          style={{
            border: "none",
            direction: "rtl",
            fontSize: "20px",
            width: "600px",
          }}
          name="companyManagerName"
          placeholder="............."
          value={values.companyManagerName}
          onChange={handleChange}
          className="mr-2"
        ></input>
        {letter?.values?.under_construction && (
          <span style={{ fontWeight: "bold", marginRight: "auto" }}>
            {"(مصنع تحت إلإنشاء)"}
          </span>
        )}
      </div>

      <div
        className="row mt-3"
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
          }}
        >
          تحية طيبــة وبعد ...
        </span>
      </div>

      <div className="row">
        <p
          lang="AR-SA"
          style={{
            fontSize: "16pt",
            fontFamily: ["Arial", "sans-serif"],
            fontWeight: "600",
            direction: "rtl",
            marginBottom: "2px",
            paddingBottom: "5px",
          }}
        >
          نتشرف بالإحاطة بأن اللجنة العلمية المتخصصة للأدوية البيطرية وإضافات
          الأعلاف قد قررت بجلسة
          {groupData
            ? ` ${new Date(groupData.values.start_date).toLocaleDateString(
                "ar-EG"
              )} `
            : "??"}
          الموافقة على السير فى إجراءات تسجيل المستحضر البيطرى :
        </p>
      </div>

      <table
        className="sc-table"
        style={{
          width: "100%",
          border: "none",
          cursor: "default",
          borderCollapse: "collapse",
          fontSize: "20px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                border: "none",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              {letter.values.trade_name}
            </td>
            <td
              style={{
                border: "none",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              {letter.drugs[0].dosage_form} {pack ? `(${pack})` : null}
            </td>
            {(letter.values.registration_type === "Imported" ||
              letter.values.registration_type === "Imported Bulk" ||
              letter.values.registration_type === "Under License") && (
              <td
                contentEditable="true"
                suppressContentEditableWarning={true}
                style={{
                  border: "none",
                  cursor: "default",
                  borderCollapse: "collapse",
                }}
              >
                {manufacturersText.join(", ")}
              </td>
            )}
          </tr>
          <tr>
            <td
              style={{
                border: "none",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              {letter.drugs[0].ingredients
                .map(
                  (ingredient) =>
                    `${ingredient.active_ingredient} ${
                      ingredient.full_strength ? ingredient.full_strength : ""
                    } ${
                      ingredient.salts && ingredient.salts.length
                        ? `(${ingredient.salts
                            .map(
                              (salt) =>
                                `${salt.name} ${salt.unit && salt.unit} ${
                                  salt.strength && salt.strength
                                }`
                            )
                            .join(", ")
                            .trim()})`
                        : ""
                    }`
                )
                .join(" , ")}
            </td>
          </tr>
          <tr>
            <td
              colSpan={
                letter.values.registration_type === "Imported" ||
                letter.values.registration_type === "Imported Bulk"
                  ? 3
                  : 2
              }
              style={{
                border: "none",
                cursor: "default",
                borderCollapse: "collapse",
              }}
            >
              <textarea
                style={{
                  width: "100%",
                  fontFamily: ["Arial", "sans-serif"],
                  fontWeight: "500",
                  fontSize: "16pt",
                  direction: "rtl",
                  border: "none",
                }}
                rows={3}
                value={values.tableComment}
                name="tableComment"
                autoCorrect="off"
                onChange={handleChange}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          marginTop: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            direction: "rtl",
            fontWeight: 600,
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginLeft: "20px",
            }}
          >
            مدير عام الإدارة العامة
            <br /> للمستحضرات البيطرية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName3}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: "600",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير إدارة الشئون الفنية
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName2}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "20px",
          }}
        >
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            مدير وحدة التقييم العلمى
          </span>
          <span
            lang="AR-SA"
            style={{
              fontSize: "14pt",
              fontFamily: ["Arial", "sans-serif"],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {values.segnName1}
          </span>
        </div>
      </div>

      <div style={{ display: "d-flex", maginTop: "1.5rem" }}>
        <img
          src={generalManagerSignature}
          style={{ width: "33%", height: "100px", border: "none" }}
        />
        <img
          src={technicalAffairsManagerSignature}
          style={{ width: "33%", height: "100px", border: "none" }}
        />
        <img
          src={scientificCommitteeManagerSignature}
          style={{ width: "33%", height: "100px", border: "none" }}
        />
      </div>

      <ul
        style={{
          direction: "rtl",
          textAlign: "right",
          marginTop: "3rem",
        }}
        contentEditable="true"
        suppressContentEditableWarning={true}
      >
        {letter?.values?.registration_type !== "Imported" &&
          letter?.values?.registration_type !== "Imported Bulk" &&
          letter?.values?.under_construction && (
            <li
              style={{ direction: "rtl" }}
              contentEditable="true"
              suppressContentEditableWarning={true}
            >
              {
                "ملحوظة : لا يتم إصدار الإخطار النهائى إلا بعد التأكد من توافر خط الإنتاج ."
              }
            </li>
          )}
        {letter?.values?.registration_type !== "Imported" &&
          letter?.values?.registration_type !== "Imported Bulk" &&
          !letter?.values?.under_construction && (
            <li
              style={{ direction: "rtl" }}
              contentEditable="true"
              suppressContentEditableWarning={true}
            >
              {
                "صورة مبلغة للإدارة المركزية للعمليات لمتابعة إنتاج التشغيلة التجريبية Pilot Batch و سحب عينات التحليل."
              }
            </li>
          )}
        {values &&
          values.firstList?.map((name, index) => (
            <li
              style={{ direction: "rtl" }}
              contentEditable="true"
              suppressContentEditableWarning={true}
            >
              {name}
            </li>
          ))}
      </ul>

      <img src={footerImage} className="w-100" />
    </div>
  );
};
