import { createReducer } from "@reduxjs/toolkit";

import {
  GET_SUBMISSION_LIMIT_PER_COMPANY_FULFILLED,
  GET_SUBMISSION_LIMIT_PER_COMPANY_PENDING,
  GET_COMPANY_ALLOWED_SUBMISSION_COUNT_FULFILLED,
  GET_COMPANY_ALLOWED_SUBMISSION_COUNT_PENDING,
  SET_AVAILABLE_SUBMISSIONS_COUNT,
} from "./settingsActionTypes";

const initialState = {
  settings: {
    submissionLimitPerCompany: null,
    companyAllowedSubmissionCount: 0,
    availableSubmissionsCount: [],
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(GET_SUBMISSION_LIMIT_PER_COMPANY_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(
    GET_SUBMISSION_LIMIT_PER_COMPANY_FULFILLED,
    (state, action) => {
      state.loading = false;
      state.settings.submissionLimitPerCompany =
        action.payload.data.settings?.submission_limit_per_company == null
          ? null
          : action.payload.data.settings?.submission_limit_per_company;
    }
  );
  builder.addCase(
    GET_COMPANY_ALLOWED_SUBMISSION_COUNT_PENDING,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    GET_COMPANY_ALLOWED_SUBMISSION_COUNT_FULFILLED,
    (state, action) => {
      state.loading = false;
      state.settings.companyAllowedSubmissionCount =
        action.payload.data.allowedSubmissionCount;
    }
  );
  builder.addCase(SET_AVAILABLE_SUBMISSIONS_COUNT, (state, action) => {
    state.loading = false;
    state.settings.availableSubmissionsCount = action.payload;
  });
});
export default reducer;
