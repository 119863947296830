import client from "api/apiAuth/guestClient";
import lodash from "lodash";
import {
  getActualName,
  setRoleInsidePdf,
  changeDoctorInfoPdf,
  getActualNameEnded,
  getActualNameWithOutClose,
} from "utils/FormUtils/Events/EventsService";

const PRODUCT_INFORMATION_SECTION_ID = 6;
const ACTIVE_INGREDIENT_SECTION_ID = 11;

// FORM 1

export const getActualSection = (html, id, endSection) => {
  const indexOfActualName = id && html.indexOf(id);
  if (indexOfActualName < 0) {
    return null;
  }
  const restOfTemp = html.slice(indexOfActualName + id.length);
  const indexOfStartName = indexOfActualName;
  const indexOfEndName = restOfTemp.indexOf(endSection);
  const oldSection = html.slice(
    indexOfStartName,
    indexOfStartName + indexOfEndName + 40
  );

  return oldSection;
};

function updateActiveIngredientLabel(input, newValue, event, formik) {
  if (!input.key.includes("active_ingredient")) return;
  const r = /\d+/;
  const index = parseInt(input.key.match(r)[0]);
  let drugs = formik.values.submissions;
  const len = drugs ? drugs.length : 1;
  for (let i = 0; i < len; i++) {
    formik.setFieldValue(
      `submissions[${i}].ingredient[${index}].active_ingredient_label`,
      newValue
    );
  }
}

function onProductAdd(params) {
  if (params.section.id !== PRODUCT_INFORMATION_SECTION_ID) return;
  const productIngredientCopy = [];
  let drugs = params.formik.values.submissions;
  // let choosedIngridients = params.formik.values?.ingredients;
  if (!drugs || !drugs[0]?.drug || !drugs[0]?.ingredient) {
    params.formik.setFieldValue("submissions[0].ingredient", []);
    params.formik.setFieldValue("submissions[1].ingredient", []);
    return;
  }
  for (const item of drugs[0].ingredient) {
    if (item.active_ingredient_label) {
      productIngredientCopy.push({
        active_ingredient_label: item.active_ingredient_label,
      });
    } else {
      productIngredientCopy.push({});
    }
  }
  params.formik.setFieldValue(
    `submissions[${drugs.length}].ingredient`,
    productIngredientCopy
  );
}

function onManyEntityChange(params) {
  if (!("Add Invitee" in params.manyEntities)) return;
  //   if (!params.manyEntities["Add Invitee"][0]) return;
  // const length =
  //   params.manyEntities["Add Invitee"][0]["Engagements details"].length;
  // for (let i = 1; i < params.manyEntities["Add Invitee"].length; i++) {
  //   if (params.manyEntities["Add Invitee"][i].length !== length) {
  //     break;
  //   }
  // }

  // if (!drugChanged) return;
  // for (let i = 1; i < params.manyEntities["Add Invitee"].length; i++) {
  //   engagements.push({ arrayIndexes: [0, i - 1] });

  //   params.manyEntities["Add Invitee"][i]["Engagements details"] = engagements;

  //   //     lodash.cloneDeep(
  //   //       params.manyEntities["Add Invitee"][0]["Engagements details"]
  //   //     );
  //   params.manyEntities["Add Invitee"][i]["Contract details"] = [
  //     { arrayIndexes: [0] },
  //   ];
  //   //     lodash.cloneDeep(
  //   //       params.manyEntities["Add Invitee"][0]["Contract details"]
  //   // );
  // }

  //   params.setManyEntities(params.manyEntities);
}

function onEngagementsRemove(params) {
  let onRemove = false;
  // if (params.section.id !== ACTIVE_INGREDIENT_SECTION_ID) return;
  for (let parentIndex in params.cloneManyEntities?.["Add Invitee"]) {
    let newHtml;
    const actualEngagementsLength =
      params.cloneManyEntities?.["Add Invitee"][parentIndex]?.[
        "Engagements details"
      ]?.length;
    let clone = lodash.cloneDeep(params.formik.values.invitees);
    if (clone && parseInt(parentIndex) === params.parentIndex) {
      const engagementHtml = clone[parentIndex].doctor_engagements_file;

      let section_id = `<section id="engagement_${params.index + 1}">`;
      let section_id_product = `<tr class="c26" id="product_${
        params.index + 1
      }">`;
      const sectionHtml = getActualSection(
        engagementHtml,
        section_id,
        "</section>"
      );
      const sectionHtmlProduct = getActualSection(
        engagementHtml,
        section_id_product,
        "</tr>"
      );

      if (sectionHtml) {
        newHtml =
          engagementHtml.slice(0, engagementHtml.indexOf(sectionHtml)) +
          engagementHtml.slice(
            engagementHtml.indexOf(sectionHtml) + sectionHtml.length
          );
        clone[parentIndex].doctor_engagements_file = newHtml;
        params.formik.setFieldValue(
          `invitees.[${parentIndex}].doctor_engagements_file`,
          newHtml
        );
      }
      if (sectionHtmlProduct) {
        newHtml =
          newHtml.slice(0, newHtml.indexOf(sectionHtmlProduct)) +
          newHtml.slice(
            newHtml.indexOf(sectionHtmlProduct) + sectionHtmlProduct.length
          );
        clone[parentIndex].doctor_engagements_file = newHtml;
        params.formik.setFieldValue(
          `invitees.[${parentIndex}].doctor_engagements_file`,
          newHtml
        );
      }
      let newEngagements;
      if (
        clone[parentIndex]?.["engagement"]?.length >= actualEngagementsLength ||
        params.index <= actualEngagementsLength
      ) {
        newEngagements = clone[parentIndex]?.["engagement"]?.filter(
          (ele, index) => index !== params.index
        );
        clone[parentIndex]["engagement"] = [...newEngagements];

        onRemove = true;

        params.formik.setFieldValue("invitees", clone);
      }

      for (
        let number = params.index;
        number < newEngagements.length;
        number++
      ) {
        const engagement = newEngagements[number];

        let engagementNumber = `id="engagement_${number + 2}_number">`;
        let oldEngagement = getActualNameEnded(
          newHtml,
          engagementNumber,
          "<br /><br />"
        );
        const dSectionId = `<section id="engagement_${number + 2}"`;
        const oldSection = getActualNameEnded(newHtml, dSectionId, " ");
        if (oldSection) {
          newHtml = newHtml.replaceAll(
            `<section id="engagement_${number + 2}"`,
            `<section id="engagement_${number + 1}"`
          );
        }
        const dRoleId = `id="engagement_${number + 2}_role">`;
        const oldDocRole = getActualName(newHtml, dRoleId);
        if (oldDocRole) {
          newHtml = newHtml.replaceAll(
            `id="engagement_${number + 2}_role">`,
            `id="engagement_${number + 1}_role">`
          );
        }
        const dTitleId = `id="engagement_${number + 2}_talk_title"`;
        const oldTitle = getActualNameWithOutClose(engagementHtml, dTitleId);
        if (oldTitle) {
          newHtml = newHtml.replaceAll(
            `id="engagement_${number + 2}_talk_title"`,
            `id="engagement_${number + 1}_talk_title"`
          );
        }
        if (oldEngagement) {
          newHtml = newHtml.replaceAll(
            `id="engagement_${number + 2}_number">`,
            `id="engagement_${number + 1}_number"> `
          );
          newHtml = newHtml.replaceAll(
            oldEngagement,
            `Engagement ${number + 1} `
          );
        }
      }
      params.formik.setFieldValue(
        `invitees.[${parentIndex}].doctor_engagements_file`,
        newHtml
      );
      clone[parentIndex].doctor_engagements_file = newHtml;
      getTotalPackage(null, null, null, params.formik, onRemove, clone);
    }
  }
}

function onEngagementsAdd(params) {
  if ([3, 6, 11].includes(params.section.id)) {
    for (let parentIndex in params.cloneManyEntities?.["Add Invitee"]) {
      const actualEngagementsLength =
        params.cloneManyEntities?.["Add Invitee"][parentIndex]?.[
          "Engagements details"
        ]?.length;

      let clone = lodash.cloneDeep(params.formik.values.invitees);
      if (clone && parseInt(parentIndex) === params.parentIndex) {
        const engagementHtml = clone[parentIndex].doctor_engagements_file;
        let section_id = `<section id="engagement_${actualEngagementsLength}">`;
        // let section_product_id = `<tr class="c26" id="product_${actualEngagementsLength}">`;
        const sectionHtml = getActualSection(
          engagementHtml,
          section_id,
          "</section>"
        );

        // const sectionHtmlProduct = getActualSection(
        //   engagementHtml,
        //   section_product_id,
        //   "</tr>"
        // );

        if (!sectionHtml) {
          continue;
        }

        let sectionIndex = engagementHtml.indexOf(sectionHtml);
        let newSction = sectionHtml.replace(
          `engagement_${actualEngagementsLength}`,
          `engagement_${actualEngagementsLength + 1}`
        );

        newSction = newSction.replace(
          `Engagement ${actualEngagementsLength}`,
          `Engagement ${actualEngagementsLength + 1}`
        );
        newSction = newSction.replace(
          `engagement_${actualEngagementsLength}_number`,
          `engagement_${actualEngagementsLength + 1}_number`
        );
        newSction = newSction.replace(
          `engagement_${actualEngagementsLength}_role`,
          `engagement_${actualEngagementsLength + 1}_role`
        );
        newSction = newSction.replace(
          `engagement_${actualEngagementsLength}_talk_title`,
          `engagement_${actualEngagementsLength + 1}_talk_title`
        );
        newSction = newSction.replace(
          `engagement_${actualEngagementsLength}_symposium_date`,
          `engagement_${actualEngagementsLength + 1}_symposium_date`
        );
        let newHtml = `${engagementHtml.slice(
          0,
          sectionIndex + sectionHtml.length
        )}${newSction} ${engagementHtml.slice(
          sectionIndex + sectionHtml.length
        )}`;
        // let sectionProductIndex = newHtml.indexOf(sectionHtmlProduct);
        // let newProductSection = sectionHtmlProduct.replace(
        //   `product_${actualEngagementsLength}`,
        //   `product_${actualEngagementsLength + 1}`
        // );
        // newProductSection = newProductSection.replace(
        //   `product_${actualEngagementsLength}_WBS_CODE`,
        //   `product_${actualEngagementsLength + 1}_WBS_CODE`
        // );
        // newProductSection = newProductSection.replace(
        //   `product_${actualEngagementsLength}_CC_CODE`,
        //   `product_${actualEngagementsLength + 1}_CC_CODE`
        // );
        // newProductSection = newProductSection.replace(
        //   `product_${actualEngagementsLength}_WBS_Name`,
        //   `product_${actualEngagementsLength + 1}_WBS_Name`
        // );
        // newHtml = `${newHtml.slice(
        //   0,
        //   sectionProductIndex + sectionHtmlProduct.length
        // )}${newProductSection} ${newHtml.slice(
        //   sectionProductIndex + sectionHtmlProduct.length
        // )}`;
        params.formik.setFieldValue(
          `invitees.[${parentIndex}].doctor_engagements_file`,
          newHtml
        );
      }
    }
  }
}

function setInputInObj(obj, value, key) {
  const inputPaths = key.split(/\.\d+\./);
  const startPath = inputPaths.shift();
  if (inputPaths.length > 0) {
  }
}

const speakerTalkTimeMapper = {
  "0,30": 3,
  "31,60": 3.5,
  "61,Infinity": 4,
};

const chairsPersonTalkTimeMapper = {
  "0,120": 2,
  "121,Infinity": 2.5,
};

const presentationTypeMapper = {
  "Newly Created": 1,
  Repeated: 0.8,
};

const eventTypeMapper = {
  RTD: 0.8,
  "Local Congress": 0.8,
  "International-Regional Congress": 0.9,
  "Stand-alone": 0.9,
  "Training Roche Staff": 1,
  "Advisory Board": 1,
  "Digital : 5 Papers": 1.6,
  "Digital : 10 Papers": 1.7,
  "Digital : > 10 Papers": 1.8,
  "Digital :15 Slides": 1.1,
  "Digital : 30 Slides": 1.2,
  "Digital : >30 Slides": 1.3,
  "1 Digital Question": 0.04,
  "2 Digital Questions": 0.08,
  "3 Digital Questions": 0.12,
  "4 Digital Questions": 0.16,
  "5 Digital Questions": 0.2,
  "6 Digital Questions": 0.24,
  "1 Digital Paragraph": 0.17,
  "2 Digital Paragraph": 0.33,
  "3 Digital Paragraph": 0.5,
  "4 Digital Paragraph": 0.67,
  "5 Digital Paragraph": 0.83,
};

const distanceMapper = {
  "0 km": 0,
  "1 - 130 km": 0.3,
  "131 - 400 km": 0.6,
  "401 - 1000 km": 0.9,
  "1001 - 2000 km": 1.2,
  "2001 - 4000 km": 1.8,
  "4001 - 6000 km": 2.4,
  "6001 - 8000 km": 3,
  "8001 - 10,000 km": 3.6,
  ">10,000 km": 4.5,
};

// const doctorType = {
// 	Hematology: {
// 		"International Tier": 4691,
// 		"Tier 1": 4146,
// 		"Tier 2": 3157,
// 		"Tier 3": 2489,
// 	},
// 	Oncology: {
// 		"International Tier": 4691,
// 		"Tier 1": 4146,
// 		"Tier 2": 3157,
// 		"Tier 3": 2489,
// 	},
// };

async function talkTimeMapper(doctorRole, talkTime) {
  if (
    doctorRole === "Speaker" ||
    doctorRole === "Moderator" ||
    doctorRole === "Consultant" ||
    doctorRole === "Advisor" ||
    doctorRole === "Digital Material Creation" ||
    doctorRole === "Panelist"
  ) {
    for (const speakerTalkTime in speakerTalkTimeMapper) {
      if (
        +talkTime >= +speakerTalkTime.split(",")[0] &&
        talkTime <= +speakerTalkTime.split(",")[1]
      ) {
        return speakerTalkTimeMapper[speakerTalkTime];
      }
    }
  } else if (doctorRole === "Chairperson") {
    for (const chairspersonTalkTime in chairsPersonTalkTimeMapper) {
      if (
        +talkTime >= +chairspersonTalkTime.split(",")[0] &&
        talkTime <= +chairspersonTalkTime.split(",")[1]
      ) {
        return chairsPersonTalkTimeMapper[chairspersonTalkTime];
      }
    }
  }
}

async function getBaseHourlyRate(specialty, segmentation) {
  const {
    data: { rows },
  } = await client.get("/generic/find", {
    params: {
      where: { specialty_id: specialty, segmentation_id: segmentation },
      model: "HourlyRate",
    },
  });
  return rows.length > 0 ? rows[0].base_hourly_rate : null;
}

async function getTotalPackage(
  input,
  newValue,
  event,
  formik,
  onRemove,
  clone
) {
  if ([2, 8, 11, 10, 9, 13, 15, 50].includes(input?.id) || onRemove) {
    const newValues = lodash.cloneDeep(formik.values);

    if (onRemove) {
      newValues.invitees = lodash.cloneDeep(clone);
    }

    event && lodash.set(newValues, event.target.name, newValue);

    if (
      newValues &&
      ("event_type" in newValues ||
        ("values" in newValues && "event_type" in newValues.values)) &&
      (newValues.event_type || newValues.values.event_type) &&
      newValues.invitees
    ) {
      let index = 0,
        finalPayTemp = {};
      const inputPaths = input?.key.split(".");
      const doctorindex = inputPaths && inputPaths[1] * 1;
      const engegementIndex = inputPaths && inputPaths[3] * 1;
      for (let doctor of newValues.invitees) {
        if (
          onRemove ||
          (doctor &&
            doctor.doctor &&
            doctor.doctor_fmv &&
            doctor.engagement &&
            input.multi_key &&
            doctorindex === index)
        ) {
          let checkEngagements = false;
          let prepTime = 0;
          let activityTime = 0;
          const {
            data: { rows },
          } = await client.get("/generic/find", {
            params: {
              where: {
                id: doctor.doctor,
              },
              model: "Doctor",
              include: [
                {
                  association: "specialty",
                },
                {
                  association: "segmentation",
                },
              ],
            },
          });

          for (let engagement of doctor.engagement) {
            if (
              engagement.doctor_role &&
              engagement.presentation_type &&
              engagement.talk_time
            ) {
              if (rows[0] && rows[0].segmentation.id && rows[0].specialty.id) {
                checkEngagements = true;

                activityTime = activityTime + engagement.talk_time / 60;
                prepTime =
                  prepTime +
                  (await talkTimeMapper(
                    engagement.doctor_role,
                    engagement.talk_time
                  )) *
                    presentationTypeMapper[engagement.presentation_type] *
                    eventTypeMapper[
                      newValues.event_type || newValues.values.event_type
                    ];
              }
            }
          }
          if (checkEngagements && doctor.doctor_fmv) {
            const travelTimeTo = distanceMapper[doctor.travel_distance_to] || 0;
            const travelTimeFrom =
              distanceMapper[doctor.travel_distance_from] || 0;

            const preFinal =
              (prepTime + activityTime + travelTimeTo + travelTimeFrom) *
              (await getBaseHourlyRate(
                rows[0].specialty.id,
                rows[0].segmentation.id
              ));

            // doctorType[rows[0].specialty][rows[0].segmentation];
            const finalPay =
              preFinal +
              preFinal * ((doctor.doctor_fmv.split("%")[0] * 1) / 100);
            finalPayTemp = { [`invitees.[${index}].doctor_package`]: finalPay };
            const html = newValues.invitees[index].doctor_engagements_file;

            const costId = 'id="doctor_total_cost">';
            const oldCost = getActualName(html, costId);

            if (oldCost) {
              let newHtml = html.replaceAll(
                oldCost,
                `${finalPay.toFixed(3)} EGP`
              );
              if (!onRemove) {
                if (input.id == 9) {
                  setRoleInsidePdf(
                    doctorindex,
                    engegementIndex,
                    formik,
                    newHtml,
                    { value: newValue }
                  );
                } else if (input.id == 8) {
                  await changeDoctorInfoPdf(doctorindex, formik, newHtml, {
                    value: newValue,
                  });
                } else {
                  formik.setFieldValue(
                    `invitees.[${index}].doctor_engagements_file`,
                    newHtml
                  );
                }
              } else {
                formik.setFieldValue(
                  `invitees.[${index}].doctor_engagements_file`,
                  newHtml
                );
              }
            }
            formik.setFieldValue(
              `invitees.[${index}].doctor_package`,
              finalPay.toFixed(3)
            );
          }
        } else {
          if (!onRemove) {
            if (input.id === 2) {
              const {
                data: { rows },
              } = await client.get("/generic/find", {
                params: {
                  where: {
                    id: doctor.doctor,
                  },
                  model: "Doctor",
                  include: [
                    {
                      association: "specialty",
                    },
                    {
                      association: "segmentation",
                    },
                  ],
                },
              });
              let checkEngagements = false;
              let prepTime = 0;
              let activityTime = 0;
              for (let engagement of doctor.engagement) {
                if (
                  engagement.doctor_role &&
                  engagement.presentation_type &&
                  engagement.talk_time
                ) {
                  if (
                    rows[0] &&
                    rows[0].segmentation.id &&
                    rows[0].specialty.id
                  ) {
                    checkEngagements = true;

                    activityTime = activityTime + engagement.talk_time / 60;
                    prepTime =
                      prepTime +
                      (await talkTimeMapper(
                        engagement.doctor_role,
                        engagement.talk_time
                      )) *
                        presentationTypeMapper[engagement.presentation_type] *
                        eventTypeMapper[
                          newValues.event_type || newValues.values.event_type
                        ];
                  }
                }
              }
              if (checkEngagements) {
                const travelTimeTo =
                  distanceMapper[doctor.travel_distance_to] || 0;
                const travelTimeFrom =
                  distanceMapper[doctor.travel_distance_from] || 0;

                const preFinal =
                  (prepTime + activityTime + travelTimeTo + travelTimeFrom) *
                  (await getBaseHourlyRate(
                    rows[0].specialty.id,
                    rows[0].segmentation.id
                  ));

                // doctorType[rows[0].specialty][rows[0].segmentation];
                const finalPay =
                  preFinal +
                  preFinal * ((doctor.doctor_fmv.split("%")[0] * 1) / 100);
                finalPayTemp = {
                  [`invitees.[${index}].doctor_package`]: finalPay,
                };
                const html = newValues.invitees[index].doctor_engagements_file;

                const costId = 'id="doctor_total_cost">';
                const oldCost = getActualName(html, costId);

                if (oldCost) {
                  let newHtml = html.replaceAll(
                    oldCost,
                    `${finalPay.toFixed(3)} EGP`
                  );

                  formik.setFieldValue(
                    `invitees.[${index}].doctor_engagements_file`,
                    newHtml
                  );
                }
                formik.setFieldValue(
                  `invitees.[${index}].doctor_package`,
                  finalPay.toFixed(3)
                );
              }
            }
          }
        }
        index++;
      }
    }
  }
}

// FORM 7

function updateActiveIngredientLabelSingle(input, newValue, event, formik) {
  if (!input.key.includes("active_ingredient")) return;
  const r = /\d+/;
  const index = parseInt(input.key.match(r)[0]);
  let drugs = formik.values.submissions;
  const len = drugs ? drugs.length : 1;
  for (let i = 0; i < len; i++) {
    formik.setFieldValue(
      `ingredients[${index}].active_ingredient_label`,
      newValue
    );
  }
}

const config = {
  1: {
    onValueChangeEvents: [getTotalPackage],
    onAddSectionEvents: [onEngagementsAdd],
    onRemoveSectionEvents: [onEngagementsRemove],
    onManyEntityChangeEvents: [onManyEntityChange],
    showDraftButton: true,
  },
  2: {
    onValueChangeEvents: [],
    onAddSectionEvents: [],
    onRemoveSectionEvents: [onEngagementsRemove],
    onManyEntityChangeEvents: [],
    showDraftButton: false,
  },
  3: {
    onValueChangeEvents: [getTotalPackage],
    onAddSectionEvents: [onEngagementsAdd],
    onRemoveSectionEvents: [onEngagementsRemove],
    onManyEntityChangeEvents: [onManyEntityChange],
    showDraftButton: false,
  },
  7: {
    onValueChangeEvents: [updateActiveIngredientLabelSingle],
    onAddSectionEvents: [],
    onRemoveSectionEvents: [],
    onManyEntityChangeEvents: [],
    showDraftButton: false,
  },
  601: {
    onValueChangeEvents: [updateActiveIngredientLabelSingle],
    onAddSectionEvents: [],
    onRemoveSectionEvents: [],
    onManyEntityChangeEvents: [],
    showDraftButton: false,
  },
};

export function tryGetConfig(formId) {
  if (formId in config) return config[formId];
  return {};
}
