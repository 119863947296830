import { deleteUserData,deleteEntityData } from "reducers/list";
import Swal from "sweetalert2";


export const deleteEntity = (index,Entities,entity,model,onEntityDeleted) => async (dispatch) => {

  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Yes, delete it!'
  })
    .then((result) => {
      if (result.isConfirmed) {
        if (entity === "users"){
        dispatch(deleteUserData(Entities[index].id)).then((response) => {
          console.log(response)
          if (response.value.status === 201) {
            onEntityDeleted(Entities[index].id)
            Swal.fire({
              title: 'Deleted!',
              text: `User has been deleted.`,
              icon: 'success',
              confirmButtonColor: 'green'
            })
          }
        })
      }else{
        dispatch(deleteEntityData({ model:model, id:Entities[index].id})).then((response) => {
          console.log(response)
          if (response.value.status === 200) {
            onEntityDeleted(Entities[index].id)
            Swal.fire({
              title: 'Deleted!',
              text: `${model} has been deleted.`,
              icon: 'success',
              confirmButtonColor: 'green'
            })
          }
        })
      }
      }
    })
};