import React, { useEffect, useState } from "react";
import * as certificates from "certificates/index";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Card,
  Button,
  DialogActions,
} from "@material-ui/core";
import { useFormik } from "formik";
import { runActions } from "reducers/grouping";
import clsx from "clsx";
import { connect } from "react-redux";
import _ from "lodash";
import { ClipLoader } from "react-spinners";
const EditDocument = (props) => {
  let { editFile, openEditFile, setOpenEditFile, runActions, user, task } =
    props;
  const [values, setValues] = useState({});
  const [certificateActiveTab, setCertificateActiveTab] = useState(1);
  let [divRefs, setDivRefs] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [validationSchema, setValidationSchema] = useState(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    let meta;
    let documentValues, documentDom;
    if (divRefs && "documentValues" in divRefs) {
      ({ documentValues, ...documentDom } = divRefs);
    } else if (divRefs) {
      ({ ...documentDom } = divRefs);
    }
    if (documentDom && !_.isEmpty(documentDom)) {
      Object.values(documentDom).forEach((divRef) => {
        const el = divRef?.querySelectorAll("textarea");
        const radioButtons = divRef?.querySelectorAll(
          'input[type="radio"][name="sampling"]:checked'
        );

        let selectInput = divRef?.querySelector("select");
        if (selectInput) {
          selectInput.options[selectInput.selectedIndex].setAttribute(
            "selected",
            "selected"
          );
        }
        if (radioButtons) {
          divRef
            ?.querySelectorAll('input[type="radio"][name="sampling"]:checked')
            .forEach((input) => {
              input.setAttribute("checked", "checked");
            });
        }
        if (el) {
          el.forEach((item) => {
            item.style.borderColor = "Transparent";
            item.style.borderStyle = "none";
            item.style.resize = " none";
          });
        }
      });
      meta = Object.fromEntries(
        Object.entries(documentDom).map(([key, value]) => [
          key,
          value.innerHTML,
        ])
      );
    }
    setSubmitFlag(true);
    setLoading(true);
    runActions({
      submissionIds: [task.submission.id],
      groupId: editFile.attachment_values.values.groupId,
      actionId: editFile.attachment_values.values.actionId,
      data: {
        meta,
        user,
        values: {
          ...documentValues,
          actionId: editFile.attachment_values.values.actionId,
          groupId: editFile.attachment_values.values.groupId,
        },
      },
      task,
      stepId: 30,
    })
      .then((res) => {
        if (res.value.data.message === "Success") {
          setLoading(false);
          setOpenEditFile(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        setSubmitFlag(false);
      });
  };
  const formik = useFormik({
    validationSchema,
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues: {},
    onSubmit,
  });
  const renderDocumentGeneration = (templateName) => {
    const Template = certificates[editFile.attachment_values.templateName];
    return (
      <div
        className={clsx("tab-item-wrapper", {
          active: certificateActiveTab === 1,
        })}
        index={0}
      >
        <Template
          drug={null}
          letter={task.submission}
          values={values}
          group={null}
          setValues={setValues}
          formValues={formik}
          divRef={(element) => {
            return (divRefs[editFile.attachment_values.templateName] = element);
          }}
          form={formik}
          setDivRef={setDivRefs}
          editValues={editFile.attachment_values.values}
        />
      </div>
    );
  };
  useEffect(() => {
    if (!editFile.attachment_values) setOpenEditFile(false);
  }, [editFile]);
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="lg"
      open={openEditFile}
      onClose={() => setOpenEditFile(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "50%",
        margin: "auto",
        height: "90%",
      }}
    >
      <DialogTitle>
        <span className=" font-weight-bold titleModal">{`Edit ${editFile?.document?.name_en}`}</span>
      </DialogTitle>
      <DialogContent style={{ height: "50%" }}>
        {editFile.attachment_values &&
          renderDocumentGeneration(editFile.attachment_values.templateName)}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "200px" }}
          disabled={!formik.isValid || submitFlag || loading}
          onClick={formik.submitForm}
        >
          Submit
          {loading && <ClipLoader color={"var(--primary)"} loading={true} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runActions: (data) => dispatch(runActions(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDocument);
