import { Grid, FormControl, TextField } from "@material-ui/core";
import { getValue } from "../../../../utils/GetObjectValues";
import ErrorMessage from "../../../../components/ErrorMessage";
export default function SysInputs({ formik, input }) {
  const inputValue = getValue(formik.values, input.key, input.itype);
  const inputError = getValue(formik.errors, input.key);
  const inputTouched = getValue(formik.touched, input.key);

  const allInputs = {
    string: (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            fullWidth
            name={input.key}
            id={input.key}
            value={inputValue || ""}
            onChange={(ev) => {
              formik.handleChange(ev);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            label={input.name}
            variant="outlined"
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={true}
            errorMessage={inputError}
          />
        </FormControl>
      </>
    ),
    number: (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            fullWidth
            name={input.key}
            id={input.key}
            value={inputValue || ""}
            onChange={(ev) => {
              formik.handleChange(ev);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            label={input.name}
            variant="outlined"
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={true}
            errorMessage={inputError}
          />
        </FormControl>
      </>
    ),
  };
  return (
    <>
      <Grid item xs={12} sm={5}>
        {allInputs[input.itype]}
      </Grid>
    </>
  );
}
