import {
  FETCH_FILTER_DATA_PENDING,
  FETCH_FILTER_DATA_FULFILLED,
  FETCH_FILTER_DATA_REJECTED,
  FETCH_FILTER_DATA_MODEL_PENDING,
  FETCH_FILTER_DATA_MODEL_FULFILLED,
  FETCH_FILTER_DATA_MODEL_REJECTED,
} from "./listActionTypes";

const initialState = {
  error: null,
  filterData: [],
  modelData: [],
  tasks: [],
  tasksCount: 0,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FILTER_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_FILTER_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        filterData: action.payload.data.columns,
      };
    }
    case FETCH_FILTER_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case FETCH_FILTER_DATA_MODEL_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_FILTER_DATA_MODEL_FULFILLED: {
      return {
        ...state,
        loading: false,
        modelData: action.payload.data,
      };
    }
    case FETCH_FILTER_DATA_MODEL_REJECTED: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
}
