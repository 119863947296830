import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import imag from "assets/images/Mailsent-rafiki.png";
import { fetchWorklistLayout, fetchFilterData } from "reducers/list";
import { connect } from "react-redux";
import ExportToExelFile from "components/ExportToExcel";
import "./style.css";
import { get } from "lodash";
import { runActions } from "reducers/grouping";
import { getTaskDetails, getTimeline } from "reducers/details/detailsAction";
import EditGroupModal from "./editGroupValuesModal";
import {
  Card,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TableCell,
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardInfo from "components/CardInfo";
import AddSubmissionsModal from "./AddSubmissionsModal";
import { addSubmissionsToGroup } from "reducers/grouping";
import Swal from "sweetalert2";
import ViewFile from "pages/EdaDetails/components/ViewFile";
import SimpleTable from "components/SimpleTable";
import SpinnerLoader from "components/SpinnerLoader";
import ActionsComponent from "./ActionsComponent";
import CustomActionRenderer from "./CustomActionRenderer";
import { useHistory } from "react-router-dom";
import WorklistFilter from "components/WorklistFilter";

const useStyles = makeStyles({
  root: {
    "& .MuiButtonBase-root": {
      transform: "TranslateY(0px) !important",
    },
  },
});
const GroupDetails = (props) => {
  let {
    match,
    user,
    runActions,
    getFilterData,
    filterData,
    groupSubmission,
    loading,
    getTaskDetails,
    task,
    getTimeline,
  } = props;
  const { id } = match.params;
  const classes = useStyles();
  const [group, setGroup] = useState();
  const [addSubmissionModalOpen, setAddSubmissionModalOpen] = useState(false);
  const [layout, setLayout] = useState(null);
  const [addedSubmissions, setAddedSubmissions] = useState([]);
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [groupColumns, setGroupColumns] = useState([]);
  const [layoutColumns, setLayoutColumns] = useState(null);
  const [editGroupModalShow, setEditGroupModalShow] = useState(false);
  const handleEditClick = () => {
    setEditGroupModalShow(true);
  };
  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedSubmissionIds, setSelectedSubmissionIds] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [shouldSelectRows, setShouldSelectRows] = useState(false);
  const [enableDelete, setEnableDelete] = useState(true);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filterLayout, setFilterLayout] = useState([]);
  const [filters, setFilters] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const [applyFilter, setApplyFilter] = useState(false);
  const history = useHistory();
  useEffect(() => {
    selectedSubmissionIds &&
      selectedSubmissionIds[0] &&
      getTaskDetails({
        submission_id: selectedSubmissionIds[0],
        task_status: "Pending",
      });
  }, [selectedSubmissionIds]);
  const addToGroup = (submission) => {
    setAddedSubmissions([...addedSubmissions, submission]);
  };

  const removeFromGroup = (submission) => {
    setAddedSubmissions(
      addedSubmissions.filter((sub) => sub.id !== submission.id)
    );
  };

  useEffect(() => {
    if (group) {
      setLayoutData(
        shouldSelectRows
          ? group.submissions.filter(
              (item) =>
                item.id in group.availableActions.perSubmission &&
                group.availableActions.perSubmission[item.id].find(
                  (action) => action.action_id === selectedAction.id
                )
            )
          : group.submissions
      );
    }
  }, [group]);

  const removeAllSubmissionsFromGroup = async () => {
    Swal.fire({
      title: `Are you sure you want to delete all submissions from this ${group?.groupType?.config?.title}?`,
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then(async function ({ isConfirmed }) {
      if (isConfirmed) {
        await client
          .post("submissiongroup/deleteAllSubmissionsFromGroup", {
            group_id: group.groupId,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title: "Success",
                text: `All submissions deleted from ${group?.groupType?.config?.title} successfully`,
                icon: "success",
                timer: 3000,
              }).then(() => window.location.reload());
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const onSingleSubmissionActionSelect = (submissionId, action) => {
    setSelectedAction(action);
    setSelectedSubmissionIds([submissionId]);
    setShowActionModal(true);
  };

  const onActionComplete = () => {
    client
      .get("SubmissionGroup/getSubmissionsAndGroups", {
        params: { groupId: id },
      })
      .then((res) => {
        setGroup({
          ...res.data.group,
          submissionCount: res.data.group.submissions.length,
        });
      });
  };

  const addSubmissionsToGroup = () => {
    const submissionsIds = addedSubmissions.map((sub) => sub.id);
    props
      .addSubmissionsToGroup({ groupId: group.groupId, submissionsIds })
      .then((res) =>
        Swal.fire({
          title: `The selected Submissions have been added successfully to the ${group?.groupType?.config?.title}!`,
          imageUrl: imag,
          imageWidth: 250,
          imageHeight: 200,
          imageAlt: "Custom image",
          confirmButtonColor: "#0066cc",
          confirmButtonText: "Ok",
        }).then(() => {
          getSubmissionsGroup();
          setAddSubmissionModalOpen(false);
          setAddedSubmissions([]);
        })
      );
  };

  const submitGroup = async () => {
    try {
      Swal.fire({
        title: `Are you sure you Submit ${group?.groupType?.config?.title} ? `,
        showConfirmButton: true,
        titleHeight: 200,
        showCancelButton: true,
        confirmButtonClass: "submit",
        cancelButtonClass: "cancel",
        titleClass: "fontSize",
        customClass: "swal-wide",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        closeOnConfirm: false,
      }).then(async function ({ isConfirmed }) {
        if (isConfirmed) {
          const response = await client
            .post("SubmissionGroup/submitGroup", {
              groupId: group.groupId,
            })
            .then((res) => {
              Swal.fire({
                title: "Success",
                text: "Submitted successfully",
                icon: "success",
                confirmButtonColor: "#0066cc",
              }).then(() => {
                setEnableDelete(false);
                window.location.reload();
              });
            });
        }
      });
    } catch (error) {}
  };
  const renderAttributes = () => {
    if (!group) return null;
    const renderedItems = [];

    if (group.values) {
      for (const attribute of group.groupType.config.render.attributes)
        renderedItems.push(
          <Grid item xs={4}>
            {attribute.label}:
          </Grid>,
          <Grid item xs={8} className="data">
            {new Date(get(group.values, attribute.key)).toLocaleTimeString(
              "en-US",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            )}
            {attribute.edit && (
              <Button
                onClick={handleEditClick}
                className="m-2 btn-neutral-primary file-color"
              >
                Edit
              </Button>
            )}
            <EditGroupModal
              open={editGroupModalShow}
              setOpen={setEditGroupModalShow}
              group={group}
              setGroup={setGroup}
              attr={attribute}
              onActionComplete={onActionComplete}
            />
          </Grid>
        );
    }

    for (const attachment of group.attachments)
      renderedItems.push(
        <Grid item xs={4} className=" align-items-center d-flex">
          <span>{attachment.document.name_en}</span>
        </Grid>,
        <Grid item xs={8} className="data">
          <Button
            className="m-2 btn-neutral-primary file-color"
            onClick={() => {
              setSelectedFile(attachment);
              setShowFileModal(true);
            }}
          >
            View File
          </Button>
        </Grid>
      );
    return renderedItems;
  };
  const getSubmissionsGroup = () => {
    client
      .get("SubmissionGroup/getSubmissionsAndGroups", {
        params: { groupId: id },
      })
      .then((res) => {
        setGroup({
          ...res.data.group,
          submissionCount: res.data.group.submissions.length,
        });
      })
      .catch((err) => console.error(err));
  };
  useEffect(async () => {
    getSubmissionsGroup();
    getFilterData({ table: "groupSubmission" }).catch((err) =>
      console.error(err)
    );
    const result = await client.get("app/getTableData", {
      params: {
        table: "groupSubmission",
      },
    });
    setLayoutColumns(result.data.columns);
  }, []);

  useEffect(() => {
    setFilterColumns(filterData);
  }, [filterData]);
  useEffect(() => {
    if (!_.isEmpty(layoutColumns)) {
      let layout = [];
      let filter = [];
      if (layoutColumns[group?.groupType?.name]) {
        filterColumns &&
          filterColumns["shared"] &&
          filter.push(...filterColumns["shared"]);
      }
      filterColumns?.length > 0 && filter.push(...filterColumns["shared"]);
      setFilterLayout(filter);
    }
  }, [filterColumns, layoutColumns]);
  useEffect(() => {
    if (applyFilter && groupSubmission && groupSubmission[0]?.group_type_id) {
      setLayoutData(groupSubmission.map((group) => group.submissions).flat());
    } else {
      setLayoutData([]);
    }
  }, [applyFilter, groupSubmission]);
  useEffect(() => {
    if (
      group &&
      !group.submissions
        .map((submission) => submission.process.active_step_id)
        .every((e) => e === group.groupType.start_step)
    )
      setEnableDelete(false);
  }, [group]);
  useEffect(() => {
    if (group && layoutColumns) {
      columnsFromLayout();
    }
  }, [group, layoutColumns]);
  const columnsFromLayout = () => {
    setGroupColumns([
      ...layoutColumns[group?.groupType?.name]["Columns"]?.map((itemLayout) => {
        return {
          name: itemLayout.name,
          className: itemLayout.className,
          renderCell: (params) => {
            const { row, cellIndex } = params;
            if (itemLayout.itype === "boolean") {
              let result, statusClass;
              if (get(row, itemLayout.key)) {
                result = "Yes";
                statusClass = "Completed";
              } else {
                result = "No";
                statusClass = "Rejected";
              }
              return (
                <TableCell
                  key={cellIndex}
                  className="text-center"
                  onClick={() => history.push(`/details/${row.id}`)}
                >
                  <span className={`${statusClass} p-2`}>{result}</span>
                </TableCell>
              );
            } else if (itemLayout.itype === "date") {
              return (
                <TableCell
                  key={cellIndex}
                  className="text-center"
                  onClick={() => history.push(`/details/${row.id}`)}
                >
                  <span>
                    {new Date(get(row, itemLayout.key)).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </span>
                </TableCell>
              );
            }
            return (
              <TableCell
                key={cellIndex}
                className={`text-center ${
                  itemLayout.className ? itemLayout.className : ""
                }`}
                onClick={() => history.push(`/details/${row.id}`)}
              >
                <span>{get(row, itemLayout.key)}</span>
              </TableCell>
            );
          },
        };
      }),
      {
        name: "Actions",
        renderCell: (params) => {
          const { row, cellIndex } = params;
          return (
            <ActionsComponent
              key={cellIndex}
              row={row}
              group={group}
              onSingleSubmissionActionSelect={onSingleSubmissionActionSelect}
              enableDelete={enableDelete}
            />
          );
        },
      },
    ]);
  };

  let GroupHeader = {
    attributes: [
      {
        name: "ID",
        key: "groupId",
        type: "integer",
        style: { gridSize: 1, display: true },
      },
      {
        name: `${group?.groupType?.config?.title} Name`,
        key: "name",
        type: "string",
        style: { gridSize: 3, display: true },
      },
      {
        name: "Submission no",
        key: "submissionCount",
        type: "integer",
        style: { gridSize: 2, display: true },
      },
      {
        name: "Status",
        key: "status",
        type: "string",
        style: { gridSize: 2, display: true },
      },
      {
        name: `${group?.groupType?.config?.title} Date`,
        key: "values.start_date",
        type: "date",
        style: { gridSize: 4, display: true },
      },
    ],
  };

  if (!layoutColumns || Array.isArray(layout) || !group) return null;

  const allowedMultipleSubmissionsActions = [];
  for (const subId of Object.keys(group.availableActions.perSubmission)) {
    for (const submissionAction of group.availableActions.perSubmission[subId])
      if (
        submissionAction.action.attributes.multi &&
        !allowedMultipleSubmissionsActions.find(
          (item) => item.action_id === submissionAction.action_id
        )
      )
        allowedMultipleSubmissionsActions.push(submissionAction);
  }

  return (
    <>
      <Grid container item xs={12} md={12}>
        <Card key="header" className="card-box" style={{ width: "inherit" }}>
          <Grid item xs={12} className="contentInfo">
            <Grid container className="containerStyle p-2" spacing={2}>
              <Grid container item xs={7}>
                <CardInfo attributes={GroupHeader?.attributes} data={group} />
              </Grid>

              <Grid container item xs={5} className="justify-content-end p-2">
                <Grid
                  item
                  container
                  key={"takeaction"}
                  style={{
                    paddingTop: "15px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {group.availableActions.group.map((action) => (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setSelectedAction(action.action);
                        setShowActionModal(true);
                      }}
                    >
                      {action.action.title}
                    </Button>
                  ))}
                  {user.roles.find(
                    (role) =>
                      role.name ===
                      group?.groupType?.config?.allowSubmit[
                        group?.submissions[0]?.process?.active_step_id
                      ]?.role
                  ) && (
                    <Button
                      className="btn btn-primary mx-1"
                      disabled={!group.shouldEnableSubmitButton}
                      onClick={submitGroup}
                    >
                      Submit {group?.groupType?.config?.title}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Card
        key="header1"
        className="card-box"
        style={{ width: "inherit", marginTop: "20px", borderRadius: "20px" }}
      >
        <Accordion
          className="w-100"
          style={{ color: "#171c30" }}
          classes={{ root: classes.root }}
          expanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="customSummary"
            style={{ backgroundColor: "#FFF", height: "30px" }}
          >
            <span className="tab-card-title">
              <b>{group?.groupType?.config?.title} Submission</b>
            </span>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#FFF" }}>
            <Grid container justifyContent="flex-end">
              <Grid
                container
                item
                xs={10}
                justifyContent="flex-end"
                className="mb-2"
              >
                {group.status === "New" &&
                  user.roles.find(
                    (role) =>
                      role.name == "Scientific Evaluation Unit Manager" ||
                      "Technical Committee Rep HF"
                  ) && (
                    <Grid item xs={2}>
                      <div className="d-flex align-end flex-wrap">
                        <Button
                          onClick={() => setAddSubmissionModalOpen(true)}
                          variant="contained"
                          className="bg-color-darkBlue"
                        >
                          <span>Add Submissions</span>
                        </Button>
                      </div>
                    </Grid>
                  )}
                {group.submissions.length > 0 &&
                  group.status === "New" &&
                  user.roles.find(
                    (role) =>
                      role.name === "Scientific Evaluation Unit Manager" ||
                      "Technical Committee Rep HF"
                  ) && (
                    <Grid item xs={2}>
                      <div className="d-flex align-end flex-wrap">
                        <Button
                          onClick={removeAllSubmissionsFromGroup}
                          variant="contained"
                          className="bg-color-darkBlue"
                        >
                          <span>Remove All Submissions</span>
                        </Button>
                      </div>
                    </Grid>
                  )}
                {user.roles.find(
                  (role) =>
                    role.name === "Scientific Evaluation Unit Junior" ||
                    role.name == "Scientific Evaluation Unit Manager" ||
                    "Technical Committee Rep HF"
                ) &&
                  allowedMultipleSubmissionsActions.map((action) => (
                    <Grid item xs={2}>
                      <div className="d-flex align-end flex-wrap">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShouldSelectRows(true);
                            setSelectedAction(action.action);
                          }}
                        >
                          <span>{action.action.title}</span>
                        </Button>
                      </div>
                    </Grid>
                  ))}
              </Grid>
              {filterColumns && (
                <WorklistFilter
                  filterLayout={filterLayout}
                  currentTab={group?.groupType?.name}
                  formId={0}
                  setApplyFilter={setApplyFilter}
                />
              )}
              <ExportToExelFile
                columns={
                  layoutColumns &&
                  layoutColumns[group?.groupType?.name]?.Columns
                }
                data={layoutData}
                exportedSheetName={`Committe ${group?.name} submissions`}
              />
              {loading ? (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    overflowX: "auto",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <SpinnerLoader />
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-4">
                  {layoutColumns && layoutData && layoutData.length > 0 ? (
                    <SimpleTable
                      columns={groupColumns}
                      rows={layoutData}
                      isSubmission={true}
                      selectableRows={shouldSelectRows}
                      onRowSelectChange={(rows) => {
                        if (shouldSelectRows)
                          setSelectedSubmissionIds(
                            rows.map((row) => row.row.id)
                          );
                      }}
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <h5>No Results</h5>
                    </Grid>
                  )}
                </Grid>
              )}

              {shouldSelectRows && (
                <Grid item xs={12} className="mt-4">
                  <div className="d-flex align-end flex-wrap">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShouldSelectRows(false);
                        if (selectedSubmissionIds.length)
                          setShowActionModal(true);
                      }}
                    >
                      <span>Select</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShouldSelectRows(false);
                      }}
                      className="ml-5"
                    >
                      <span>Reset</span>
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
          <AddSubmissionsModal
            open={addSubmissionModalOpen}
            setOpen={setAddSubmissionModalOpen}
            group={group}
            addToGroup={addToGroup}
            removeFromGroup={removeFromGroup}
            setAddedSubmissions={setAddedSubmissions}
            addSubmissionsToGroup={addSubmissionsToGroup}
            addedSubmissions={addedSubmissions}
          />
          <CustomActionRenderer
            group={group}
            action={selectedAction}
            submissionIds={selectedSubmissionIds}
            open={showActionModal}
            setOpen={setShowActionModal}
            onActionComplete={onActionComplete}
            getTaskDetails={getTaskDetails}
            getTimeline={getTimeline}
            task_id={task?.id}
            step_id={task?.step.id}
            submission={task?.submission}
            edit_form={task?.step.edit_form}
            transitions={task?.step.transitions}
            runActions={runActions}
          />
        </Accordion>
      </Card>
      <Card
        key="header2"
        className="card-box"
        style={{ width: "inherit", marginTop: "20px", borderRadius: "20px" }}
      >
        <Accordion
          className="w-100"
          style={{ color: "#171c30" }}
          classes={{ root: classes.root }}
          expanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="customSummary"
            style={{ backgroundColor: "#FFF", height: "30px" }}
          >
            <span className="tab-card-title" style={{ color: "#171c30" }}>
              <b>{group?.groupType?.config?.title} Details</b>
            </span>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#FFF" }}>
            <Grid container>{renderAttributes()}</Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
      {showFileModal && (
        <ViewFile
          file={selectedFile}
          open={showFileModal}
          setOpen={setShowFileModal}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    filterData: state.filterData.filterData,
    groupSubmission: state.list?.tasks,
    loading: state.list.loading,
    task: state.details.task,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSubmissionsToGroup: (body) => dispatch(addSubmissionsToGroup(body)),
    getFilterData: (params) => dispatch(fetchFilterData(params)),
    getTaskDetails: (params) => dispatch(getTaskDetails(params)),
    getTimeline: (params) => dispatch(getTimeline(params)),
    runActions: (data) => dispatch(runActions(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
