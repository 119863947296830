import React, { useState, useEffect } from "react";
import client from "api/apiAuth/guestClient";
import "./style.css";
import ExportToExel from "../../../components/ExportToExcel";
import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PerfectScrollbar from "react-perfect-scrollbar";
const useStyles = makeStyles({
  root: {
    "& .MuiButtonBase-root": {
      transform: "TranslateY(0px) !important",
    },
  },
});

const groups = [
  // 8A
  [
    "Aerosol",
    "Concentrated solution for cutaneous emulsion",
    "Cutaneous Emulsion",
    "Cutaneous Solution",
    "cutaneous spray solution",
    "Ointment For Cutaneous and Nasal Application",
    "Pour-on Liquid",
    "Pour-on solution",
    "Spot On Solution",
    "Shampoo",
    "Spray Solution",
    "Spray suspension for cutaneous use",
    "Suspension for cutaneous spray",
    "Topical Cream",
    "Topical Ointment",
    "Topical Gel",
    "Topical Paste",
    "Topical Shampoo",
    "Topical Solution",
    "Topical Spray",
    "Udder cream",
    "wipes",
  ],
  // 1A
  [
    "Capsule",
    "Bolus",
    "Chewable Tablets",
    "Coated Tablet",
    "Compressed Tablet",
    "Continous release Intraruminal device",
    "Film Coated Tablets",
    "Hard Gelatin Capsules",
    "Oral cross-snap tablets",
    "Orally dissolving film coated tablet",
    "SCORED TABLET",
    "Tablets",
  ],
  // 4A
  [
    "Absorbable Sponge",
    "INTRAVAGINAL DEVICE",
    "Rectal Suppositories",
    "Sponge",
    "Vaginal Insert",
    "Vaginal Pessary",
  ],
  // 2A
  [
    "Concentrate for oral solution",
    "Concentrate for oral solution for use in drinking water / milk replacer",
    "Effervescent Tablet",
    "Granules for extended release oral suspension",
    "Granules for Oral Solution",
    "Granules for use in drinking water",
    "granules in sachet for oral solution",
    "Liquid Concentrate",
    "Micronized Liquid concentrate",
    "Oral Drench",
    "Oral Drops",
    "Oral Emulsion",
    "Oral Gel",
    "Oral Granules",
    "Oral Granules for drenching",
    "Oral Liquid",
    "Oral Paste",
    "Oral Powder",
    "Oral Solution",
    "Oral Spray",
    "Oral Suspension",
    "Powder for oral solution",
    "Powder for Oral Suspension",
    "powder for reconstitution for oral suspension",
    "Powder for use in calf milk ,drinking water",
    "Powder for use in drinking water",
    "Powder for use in drinking water ,milk replacer ,milk",
    "Powder for use in drinking water / milk replacer",
    "Powder for use in drinking water or milk",
    "Powder to be dissolved exclusively in milk/milk replacer",
    "Soluble Powder for Oral Solution",
    "Solution",
    "Solution for use in drinking water",
    "Solution for use in drinking water / milk replacer",
    "Suspension for use in drinking water",
    "Syrup",
    "Water Dispersible Powder",
    "water soluble concentrate",
    "Water Soluble Granules",
    "Water Soluble Microgranules",
    "Water Soluble Powder",
    "Water Soluble Tablet",
    "Water Suspendable Powder",
  ],
  // 7A
  [
    "Ear Drops",
    "Ear Drops Suspension",
    "Eye Drops,Solution",
    "Eye Ointment",
    "Otic Suspension",
  ],
  // 3A
  [
    "Emulsion For Injection",
    "Extended release solution for injection",
    "Granules for Injection",
    "Infusion",
    "Liquid dilution for injection",
    "Lyophilisate for injection",
    "Lyophilized Powder for Solution for Injection",
    "Oil For Injection",
    "powder and solvent for injection",
    "Powder and Solvent for Solution for injection",
    "Powder and Solvent for Suspension for Injection",
    "Powder for injectable suspension",
    "Powder for injection",
    "Powder for reconstitution for I.M. injection",
    "Powder for Solution for Injection",
    "Ready to use solution for injection",
    "Solution for I.M Injection",
    "Solution For I.M or I.V. Injection",
    "Solution For I.V Infusion",
    "Solution for Infusion",
    "Solution for injection",
    "solution for injection or concentrate for solution for infusion",
    "Solution for Injection/Infusion",
    "Solution for S.C injection",
    "Sterile Aqueous Solution",
    "Sterile powder and solvent for solution for injection",
    "Sterile powder for solution for I.M. Injection and slow I.V. Injection or Infusion .",
    "Sterile soluble powder for injection",
    "Suspension for injection",
  ],
  // BOX 1B
  [
    "Feed additive granules",
    "Feed additive premix",
    "Feed Premix",
    "Granular Powder to be mixed with feed",
    "Granules to be mixed with feed",
    "Medicated Feed",
    "Medicated premix (Powder)",
    "Microgranulate Premix",
    "Microgranulate to be mixed with feed",
    "Microgranules to be mixed with feed",
    "Oral Premix",
    "Oral Premix (Granulated powder)",
    "Powder to be mixed with feed",
    "Premix",
    "Premix for medicated feeding stuff",
    "Premix for medicated feeding stuff (Granular)",
    "Premix for medicated feeding stuff (Powder)",
  ],
  // Inhalations
  [
    "Inhalation liquid",
    "Pre-dispensed Inhalation Powder",
    "Solution for Inhalation",
  ],
  // 6B
  ["intra uterine Device", "Intrauterine Tablet"],
  // 5A
  [
    "Intramammary",
    "Intramammary Infusion",
    "Intramammary Injection",
    "Intramammary Suspension",
  ],
  // intrauterine spray
  ["intrauterine spray"],
  // 6A & 6B
  [
    "Intrauterine solution for Injection",
    "Intrauterine sterile aqueous solution",
  ],
];

const SimilarDrugsTable = (props) => {
  const classes = useStyles();
  const [similarDrugs, setSimilarDrugs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    async function fetchSimilarDrugs() {
      let genericId = [];
      for (const drug of props.task.submission.drugs) {
        for (const ingredient of drug.ingredients) {
          let { data } = await client.post("generic/find", {
            model: "GenericsView",
            where: {
              GenericName: {
                operator: "equal",
                value: ingredient.active_ingredient,
              },
            },
          });
          genericId.push(data.rows[0].GenericName_ID);
        }
        genericId = genericId.sort((a, b) => a - b);
        genericId = genericId.join(",");

        let foundGroup = [];
        for (const group of groups)
          if (group.includes(drug.dosage_form))
            foundGroup = [...foundGroup, ...group];
        if (!foundGroup.length) foundGroup = [drug.dosage_form];

        const drugSimilar = await client.post("generic/find", {
          model: "AllDrugsData",
          where: {
            dosage_form: {
              operator: "in",
              value: foundGroup,
            },
            Type_of_license: {
              operator: "in",
              value: ["Imported", "Imported Bulk"],
            },
            genericid: {
              operator: "equal",
              value: genericId,
            },
          },
        });
        setSimilarDrugs(drugSimilar.data.rows);
      }
    }

    fetchSimilarDrugs();
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid
      container
      item
      xs={props.part.style.attributes.GridSize}
      key={props.partIndex}
      className={props.part.style.classes}
    >
      <Accordion
        className="w-100"
        style={{ color: "#0066cc" }}
        classes={{ root: classes.root }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="customSummary"
          style={{ backgroundColor: "#FAF0E0" }}
        >
          <span className="tab-card-title" style={{ color: "#0066cc" }}>
            {similarDrugs.length
              ? `Similar Drugs (${similarDrugs.length})`
              : "Similar Drugs"}
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#FAF0E0" }}>
          <Grid container>
            <Grid item xs={12} className="my-1" style={{ direction: "rtl" }}>
              <ExportToExel
                data={similarDrugs}
                columns={[]}
                exportedSheetName={`similarDrugsTableFor_${props?.task?.submission?.file_code}`}
              />
            </Grid>
            <Grid item xs={12}>
              <PerfectScrollbar
                options={{
                  suppressScrollY: true,
                  useBothWheelAxes: true,
                }}
                className="displayScroll"
              >
                <Card className="card-box">
                  <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
                    <TableHead className="font-size-sm">
                      <TableRow>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Trade Name
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Generics
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Status
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Dosage Form
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Registration
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Company Name
                        </TableCell>
                        <TableCell className="text-center bg-color-darkBlue pt-2">
                          Drug License Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {similarDrugs?.length > 0 &&
                        (rowsPerPage > 0
                          ? similarDrugs.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : similarDrugs
                        ).map((drug, index) => (
                          <TableRow key={drug.id}>
                            <TableCell className="text-center">
                              <p>{drug?.Trade_name}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug?.Generics}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug?.regstatus}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Dosage_form}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Type_of_license}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Applicant_name}</p>
                            </TableCell>
                            <TableCell className="text-center">
                              <p>{drug.Drug_license_number}</p>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Card>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={similarDrugs.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={""}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </PerfectScrollbar>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SimilarDrugsTable;
